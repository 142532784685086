import Pseudo from 'i18next-pseudo'
import { Intl } from '@toasttab/banquet-types'
import { checkLocale, Locale } from '@toasttab/buffet-pui-locale-utilities'
import {
  i18next,
  initI18Next
} from '@toasttab/buffet-pui-translation-utilities'
import { InitAuthResult } from '@toasttab/user-authentication-js'

type RestaurantInfo = InitAuthResult['restaurantInfo']

function getLocaleFromProps(restaurantInfo: RestaurantInfo) {
  const country = restaurantInfo?.restaurant?.country
  const language = restaurantInfo?.restaurant?.language

  return checkLocale(`${language}-${country}` as Locale)
}

function getCurrency(restaurantInfo: RestaurantInfo): Intl['currency'] {
  return restaurantInfo?.restaurant?.currency as Intl['currency']
}

export function getI18nProps(
  { restaurantInfo }: InitAuthResult,
  userPreferredLocale?: string
) {
  const restaurantLocale = getLocaleFromProps(restaurantInfo)
  const currency = getCurrency(restaurantInfo)

  // @deprecated
  const cdnUrl =
    '//cdn.toasttab.com/static/34d9bc8b2e7d99006ecfcd8a8ba5e3dc5c75d126/projects/intgs/translations'

  // Load any overrides from local storage
  const localLocaleOverrideRawValue = localStorage.getItem(
    'TOAST-selectedLanguage'
  )

  const localLocaleOverrideValue =
    localLocaleOverrideRawValue && JSON.parse(localLocaleOverrideRawValue)

  const localPseudoL10n = localStorage.getItem('TOAST-pseudoL10n')
  const DEFAULT_LOCALE = 'en-US'

  const localeToUse =
    localLocaleOverrideValue ||
    userPreferredLocale ||
    restaurantLocale ||
    DEFAULT_LOCALE

  initI18Next(localeToUse)

  if (localPseudoL10n === 'true') {
    i18next.use(new Pseudo({ languageToPseudo: localeToUse }))
    i18next.options.postProcess = ['pseudo']
  }

  return {
    language: localeToUse,
    currency,
    i18next,
    cdnUrl
  }
}
