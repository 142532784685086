import { LDClient } from 'launchdarkly-js-client-sdk'
export const GLOBAL_SUB_NAV = 'nav-global-subnav'

export const getIsReportsUsingGlobalSubNav = (ldClient?: LDClient) => {
  // this feature flag is a string array
  //where the values match category ids that we want to display the global subanv
  const globalSubNavFlag = ldClient?.variation(GLOBAL_SUB_NAV, [])
  if (!Array.isArray(globalSubNavFlag)) return false

  return globalSubNavFlag.includes('reports')
}
