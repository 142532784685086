import { AuthClient, initAuthClient } from '@toasttab/user-authentication-js'
import {
  FFLookup,
  GlobalRestaurantInfo,
  Intl,
  ToastwebGlobalUserInfo,
  Features
} from '@toasttab/banquet-types'
import { isNextBuffetTheme } from '../isNextBuffetTheme'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import { LDClient } from 'launchdarkly-js-client-sdk'
import { getI18nProps } from '../getI18nProps'

type InitAuthClientResult = Awaited<ReturnType<typeof initAuthClient>>

const INTL_LOCALE_SELECTOR_POC = 'intl-locale-selector-poc'

export interface BanquetInitialData {
  featureFlags?: FFLookup
  restaurantInfo?: InitAuthClientResult['restaurantInfo'] & GlobalRestaurantInfo
  userInfo?: ToastwebGlobalUserInfo
  features?: Features
  isNextBuffetTheme?: boolean
}
export interface GlobalCustomProps extends BanquetInitialData, ExtraProps {
  auth?: AuthClient
}

interface ExtraProps {
  i18n?: Intl
  // timestamp of banquet instantiation
  banquetStartTime: string
  apolloClient: ApolloClient<NormalizedCacheObject>
  restaurantAdminApolloClient: ApolloClient<NormalizedCacheObject>
  ldClient?: LDClient
  userPreferredLocale?: string
}

declare var __TOAST_BANQUET_INITIAL_DATA__: GlobalCustomProps

/**
 * getGlobalCustomProps
 * Builds the props object available to every SPA
 */
export function getGlobalCustomProps(
  { auth, restaurantInfo }: InitAuthClientResult,
  extraProps: ExtraProps
): GlobalCustomProps {
  const globalData: GlobalCustomProps = __TOAST_BANQUET_INITIAL_DATA__
  return {
    auth,
    ...globalData,
    ...extraProps,
    i18n: getI18nProps(
      { auth, restaurantInfo } as InitAuthClientResult,
      globalData.featureFlags?.[INTL_LOCALE_SELECTOR_POC]
        ? extraProps.userPreferredLocale
        : undefined
    ),
    isNextBuffetTheme: isNextBuffetTheme(globalData),
    restaurantInfo: {
      ...globalData?.restaurantInfo!,
      ...restaurantInfo
    }
  }
}
