/* eslint-disable */
import {
  GraphQLResolveInfo,
  GraphQLScalarType,
  GraphQLScalarTypeConfig
} from 'graphql'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> }
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> =
  { [_ in K]?: never }
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never
    }
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>
export type RequireFields<T, K extends keyof T> = Omit<T, K> &
  { [P in K]-?: NonNullable<T[P]> }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  /** An `ISO-8601` compliant Full Date Scalar - e.g. 2022-06-01 */
  Date: { input: any; output: any }
  /** An `ISO-8601` compliant DateTime Scalar, e.g. 2022-06-01T10:15:00.000 */
  DateTime: { input: any; output: any }
  /**  A signed decimal number, which supports arbitrary precision and is serialized as a string. Example values: "29.99", "29.999".  */
  Decimal: { input: any; output: any }
  /** A length of time serialized as an integer in seconds. */
  Duration: { input: any; output: any }
  JSON: { input: any; output: any }
  /** An `ISO-8601` compliant Partial Time Scalar - e.g. 14:25:06 */
  LocalTime: { input: any; output: any }
  /** A 64-bit signed integer */
  Long: { input: any; output: any }
  MarkdownText: { input: any; output: any }
  /** The bit position of a legacy customer user permission */
  PermissionBit: { input: any; output: any }
  /** A Url scalar */
  Url: { input: any; output: any }
}

export type AiInsightFeedbackType = 'THUMBS_DOWN' | 'THUMBS_UP'

export type AccessibleRestaurantLocation = {
  __typename: 'AccessibleRestaurantLocation'
  /** The description of the restaurant, if available */
  description?: Maybe<Scalars['String']['output']>
  /** The identifier provided by Toast */
  id: Scalars['ID']['output']
  /** The location name of the restaurant, if available */
  locationName?: Maybe<Scalars['String']['output']>
  /** The management-group for this restaurant. */
  managementGroup?: Maybe<ManagementGroup>
  /**
   * The management-set leaf for this restaurant.
   * @deprecated Use `managementGroup` instead
   */
  managementSet?: Maybe<ManagementSet>
  /** The name of the restaurant. */
  restaurantName: Scalars['String']['output']
  /** The restaurant-set leaf for this restaurant. */
  restaurantSet: RestaurantSet
}

export type AccessibleRestaurantSet = {
  __typename: 'AccessibleRestaurantSet'
  isAccessible: Scalars['Boolean']['output']
  restaurantSet: RestaurantSet
}

export type AccessibleRestaurantSetCheckType = 'ALL_OF' | 'ANY_OF'

export type AccessibleRestaurantSetsInput = {
  checkType: AccessibleRestaurantSetCheckType
  filterGroupsByPermissions?: Scalars['Boolean']['input']
  includeLoyaltyGroups?: Scalars['Boolean']['input']
  permissionBits: Array<Scalars['PermissionBit']['input']>
}

export type Action = 'CREATE' | 'DELETE' | 'UPDATE'

export type ActiveEmploymentStatus = EmploymentStatus & {
  __typename: 'ActiveEmploymentStatus'
  adjustedHireDate?: Maybe<Scalars['Date']['output']>
  /** @deprecated use employee.hireDate at the top level instead */
  hireDate?: Maybe<Scalars['String']['output']>
}

export type AdditionalModifierGroups = {
  __typename: 'AdditionalModifierGroups'
  course?: Maybe<AdditionalModifierGroupsCourse>
  diningOption?: Maybe<AdditionalModifierGroupsDiningOption>
  seatNumberQuickOrder?: Maybe<AdditionalModifierGroupsSeatNumberQuickOrder>
  seatNumberTableService?: Maybe<AdditionalModifierGroupsSeatNumberTableService>
  specialRequest?: Maybe<AdditionalModifierGroupsSpecialRequest>
  split?: Maybe<AdditionalModifierGroupsSplit>
}

export type AdditionalModifierGroupsCourse = {
  __typename: 'AdditionalModifierGroupsCourse'
  enabled?: Maybe<Scalars['Boolean']['output']>
}

export type AdditionalModifierGroupsCourseInput = {
  enabled?: InputMaybe<Scalars['Boolean']['input']>
}

export type AdditionalModifierGroupsDiningOption = {
  __typename: 'AdditionalModifierGroupsDiningOption'
  enabled?: Maybe<Scalars['Boolean']['output']>
}

export type AdditionalModifierGroupsDiningOptionInput = {
  enabled?: InputMaybe<Scalars['Boolean']['input']>
}

export type AdditionalModifierGroupsInput = {
  course?: InputMaybe<AdditionalModifierGroupsCourseInput>
  diningOption?: InputMaybe<AdditionalModifierGroupsDiningOptionInput>
  seatNumberQuickOrder?: InputMaybe<AdditionalModifierGroupsSeatNumberQuickOrderInput>
  seatNumberTableService?: InputMaybe<AdditionalModifierGroupsSeatNumberTableServiceInput>
  specialRequest?: InputMaybe<AdditionalModifierGroupsSpecialRequestInput>
  split?: InputMaybe<AdditionalModifierGroupsSplitInput>
}

export type AdditionalModifierGroupsSeatNumberQuickOrder = {
  __typename: 'AdditionalModifierGroupsSeatNumberQuickOrder'
  enabled?: Maybe<Scalars['Boolean']['output']>
  required?: Maybe<Scalars['Boolean']['output']>
}

export type AdditionalModifierGroupsSeatNumberQuickOrderInput = {
  enabled?: InputMaybe<Scalars['Boolean']['input']>
  required?: InputMaybe<Scalars['Boolean']['input']>
}

export type AdditionalModifierGroupsSeatNumberTableService = {
  __typename: 'AdditionalModifierGroupsSeatNumberTableService'
  enabled?: Maybe<Scalars['Boolean']['output']>
  required?: Maybe<Scalars['Boolean']['output']>
}

export type AdditionalModifierGroupsSeatNumberTableServiceInput = {
  enabled?: InputMaybe<Scalars['Boolean']['input']>
  required?: InputMaybe<Scalars['Boolean']['input']>
}

export type AdditionalModifierGroupsSpecialRequest = {
  __typename: 'AdditionalModifierGroupsSpecialRequest'
  enabled?: Maybe<Scalars['Boolean']['output']>
}

export type AdditionalModifierGroupsSpecialRequestInput = {
  enabled?: InputMaybe<Scalars['Boolean']['input']>
}

export type AdditionalModifierGroupsSplit = {
  __typename: 'AdditionalModifierGroupsSplit'
  enabled?: Maybe<Scalars['Boolean']['output']>
}

export type AdditionalModifierGroupsSplitInput = {
  enabled?: InputMaybe<Scalars['Boolean']['input']>
}

export type AiChatTermsOfUseResponse = {
  __typename: 'AiChatTermsOfUseResponse'
  acceptedDate?: Maybe<Scalars['String']['output']>
  isAccepted: Scalars['Boolean']['output']
}

export type AiTermsOfUseDefaultResponse = {
  __typename: 'AiTermsOfUseDefaultResponse'
  message?: Maybe<Scalars['String']['output']>
  success: Scalars['Boolean']['output']
}

export type AllBookingsResponse =
  | AllBookingsResponseSuccess
  | InternalError
  | RestaurantNotFound
  | UnableToFetchGuests

export type AllBookingsResponseSuccess = {
  __typename: 'AllBookingsResponseSuccess'
  bookingResponse: Array<Booking>
}

export type AllEmploymentStatuses =
  | ActiveEmploymentStatus
  | ApplicantEmploymentStatus
  | DeletedEmploymentStatus
  | DemoEmploymentStatus
  | ImplementationEmploymentStatus
  | LeaveOfAbsenceEmploymentStatus
  | NeverEmployedEmploymentStatus
  | PendingEmploymentStatus
  | RehiredEmploymentStatus
  | SeasonalEmploymentStatus
  | TerminatedEmploymentStatus

export type AlternatePaymentDataExtension = DataExtension & {
  __typename: 'AlternatePaymentDataExtension'
  alternatePaymentType: SavedAlternatePaymentType
  type: DataExtensionType
  value: Scalars['String']['output']
}

/** Analysis of the comparison. */
export type Analysis =
  /** A disimprovement versus the comparison date */
  | 'NEGATIVE'
  /** No change; either because the values are identical, or because a value judgment about improvement or disimprovement cannot be made */
  | 'NEUTRAL'
  /** An improvement versus the comparison date */
  | 'POSITIVE'

export type ApplicantEmploymentStatus = EmploymentStatus & {
  __typename: 'ApplicantEmploymentStatus'
  adjustedHireDate?: Maybe<Scalars['Date']['output']>
  /** @deprecated use employee.hireDate at the top level instead */
  hireDate?: Maybe<Scalars['String']['output']>
}

export type ApplicationMetadata = {
  __typename: 'ApplicationMetadata'
  isPreapproval?: Maybe<Scalars['Boolean']['output']>
}

export type ArchiveCustomMenuItemUpsellInput = {
  /** ID of the upsell to archive */
  id: Scalars['ID']['input']
}

export type ArchiveEntityInput = {
  versionId: Scalars['ID']['input']
}

export type ArchiveMembershipResponse = {
  __typename: 'ArchiveMembershipResponse'
  code: Scalars['Int']['output']
  message?: Maybe<Scalars['String']['output']>
  success: Scalars['Boolean']['output']
}

export type AuthConfigurationMutation = {
  __typename: 'AuthConfigurationMutation'
  applyJobBasedAccessConfig: JobBasedAccessConfig
}

export type AuthConfigurationMutationApplyJobBasedAccessConfigArgs = {
  jobBasedAccessConfig: JobBasedAccessConfigInput
}

export type AuthConfigurationQuery = {
  __typename: 'AuthConfigurationQuery'
  jobBasedAccessConfig?: Maybe<JobBasedAccessConfig>
}

export type AuthenticationFailure = ShiftError & {
  __typename: 'AuthenticationFailure'
  message?: Maybe<Scalars['String']['output']>
}

/**  The result of evaluating either ABAC rules or legacy authorization criteria  */
export type AuthorizationDecision = 'DENY' | 'GRANT' | 'NO_DECISION'

/**  The reason an AuthorizationDecision was made. Should be used to display to the end user why they may/may not have access to an AuthorizationTarget TODO - in the future this object should be expanded to include the conditions met that give the user access i.e. what permission bits they have and which ones the rule was looking for  */
export type AuthorizationDecisionReason = {
  __typename: 'AuthorizationDecisionReason'
  result: AuthorizationDecision
  /** Will be populated if ABAC engine was used to render a GRANT / DENY decision */
  ruleGuid?: Maybe<Scalars['String']['output']>
}

export type AuthorizationPage = {
  __typename: 'AuthorizationPage'
  lastEvaluatedUserId?: Maybe<Scalars['ID']['output']>
  limit: Scalars['Int']['output']
}

/**  Input that controls the paging of an endpoint. Note that this paging is representative of DynamoDB paging which cannot guarantee a page size since it is based on memory size lastEvaluatedUserId represents the last user evaluated targetCount represents the approximate page size. NOTE: the return is not guaranteed to equal this size. This is simply a target used to determine when to stop querying pages  */
export type AuthorizationPageInput = {
  lastEvaluatedUserId?: InputMaybe<Scalars['ID']['input']>
  limit: Scalars['Int']['input']
}

/** A permission-able feature provided by Toast */
export type AuthorizationTarget = {
  __typename: 'AuthorizationTarget'
  objectName: Scalars['String']['output']
  operationName: Scalars['String']['output']
}

/** A permission-able feature provided by Toast */
export type AuthorizationTargetInput = {
  objectName: Scalars['String']['input']
  operationName: Scalars['String']['input']
}

export type AvailabilitiesResponse =
  | AvailabilitiesResponseSuccess
  | BookingNotFound
  | RestaurantNotFound

export type AvailabilitiesResponseSuccess = {
  __typename: 'AvailabilitiesResponseSuccess'
  restaurantAvailability?: Maybe<RestaurantAvailabilityInfo>
}

export type BackfillStatus =
  | 'BACKFILL_STATUS_FAILED'
  | 'BACKFILL_STATUS_SUCCESSFUL'
  | 'BACKFILL_STATUS_UNSPECIFIED'

export type BankingConnection = {
  __typename: 'BankingConnection'
  connectionId: Scalars['String']['output']
}

export type Basis =
  | 'BASIS_HOUR'
  | 'BASIS_UNSPECIFIED'
  | 'BASIS_YEAR'
  | 'HOUR'
  | 'UNSPECIFIED'
  | 'YEAR'

export type BlockConfig = {
  __typename: 'BlockConfig'
  blockedReason: Scalars['String']['output']
  hostReservationsBlocked: Scalars['Boolean']['output']
  onlineReservationsBlocked: Scalars['Boolean']['output']
  onlineWaitlistBlocked: Scalars['Boolean']['output']
}

export type BlockConfigsResponse = BlockConfigsSuccess

export type BlockConfigsSuccess = {
  __typename: 'BlockConfigsSuccess'
  blockConfigs: Array<BlockedDate>
}

export type BlockedDate = {
  __typename: 'BlockedDate'
  block: BlockConfig
  date: Scalars['Date']['output']
}

export type Booking = {
  __typename: 'Booking'
  actualEndTime?: Maybe<Scalars['DateTime']['output']>
  actualStartTime?: Maybe<Scalars['DateTime']['output']>
  arrivedTime?: Maybe<Scalars['DateTime']['output']>
  bookingNotes?: Maybe<Scalars['String']['output']>
  bookingSource?: Maybe<BookingSource>
  bookingStatus: BookingStatus
  bookingTables: Array<BookingTable>
  bookingType: BookingType
  cancellationSource?: Maybe<CancellationSource>
  cancelledTime?: Maybe<Scalars['DateTime']['output']>
  createdAt: Scalars['DateTime']['output']
  createdByAuthenticatedUser: Scalars['Boolean']['output']
  depositAmount?: Maybe<Scalars['Float']['output']>
  depositBookableConfigSnapshot?: Maybe<DepositBookableConfigSnapshot>
  depositCheckId?: Maybe<Scalars['ID']['output']>
  depositOrderId?: Maybe<Scalars['ID']['output']>
  depositPaymentExpirationDatetime?: Maybe<Scalars['DateTime']['output']>
  depositRefundableCancellationDatetime?: Maybe<Scalars['DateTime']['output']>
  depositStatus?: Maybe<DepositStatus>
  dismissToHistory: Scalars['Boolean']['output']
  expectedEndTime: Scalars['DateTime']['output']
  expectedStartTime: Scalars['DateTime']['output']
  externalServiceArea?: Maybe<Scalars['String']['output']>
  externalServiceAreas?: Maybe<Array<Scalars['String']['output']>>
  firstNotified?: Maybe<Scalars['DateTime']['output']>
  hasPinnedMessageThread?: Maybe<Scalars['Boolean']['output']>
  id: Scalars['ID']['output']
  lastNotified?: Maybe<Scalars['DateTime']['output']>
  mainGuest?: Maybe<Guest>
  noShowTime?: Maybe<Scalars['DateTime']['output']>
  notificationCount?: Maybe<Scalars['Int']['output']>
  partySize?: Maybe<Scalars['Int']['output']>
  requestedServiceAreaGroups: Array<BookingServiceAreaGroup>
  restaurant: Scalars['ID']['output']
  server?: Maybe<BookingsEmployee>
  serviceAreaGroup?: Maybe<BookingServiceAreaGroup>
  serviceAreas: Array<BookingServiceArea>
  specialOccasion: SpecialOccasion
  unreadSmsMessageCount?: Maybe<Scalars['Int']['output']>
  updatedAt: Scalars['DateTime']['output']
}

export type BookingMutation = {
  __typename: 'BookingMutation'
  cancelBooking: CancelReservationResponse
  createBlock: CreateBlockResponse
  createBooking: CreateReservationResponse
  createGuest: GuestCreationResponse
  updateBooking: UpdateReservationResponse
  updateGuest: GuestUpdateResponse
}

export type BookingMutationCancelBookingArgs = {
  bookingGuid: Scalars['ID']['input']
  dismissToHistory?: InputMaybe<Scalars['Boolean']['input']>
}

export type BookingMutationCreateBlockArgs = {
  businessDate: Scalars['Date']['input']
  request: CreateBlockRequest
}

export type BookingMutationCreateBookingArgs = {
  request: CreateReservationRequest
}

export type BookingMutationCreateGuestArgs = {
  request: CreateGuestRequest
}

export type BookingMutationUpdateBookingArgs = {
  bookingGuid: Scalars['ID']['input']
  request: UpdateReservationRequest
}

export type BookingMutationUpdateGuestArgs = {
  guestGuid: Scalars['ID']['input']
  request: UpdateGuestRequest
}

export type BookingNotFound = Error & {
  __typename: 'BookingNotFound'
  message: Scalars['String']['output']
  status: ErrorCode
}

export type BookingPlan = 'FREE' | 'TABLES' | 'TABLES_PLUS' | 'UNKNOWN'

export type BookingQuery = {
  __typename: 'BookingQuery'
  availabilities: AvailabilitiesResponse
  blocks: BlockConfigsResponse
  bookingLimit: CurrentBookingUsageResponse
  bookings: AllBookingsResponse
  guest: GuestListResponse
  mostRecentImportGuid: MostRecentImportGuidResponse
  restaurant: MgmtRestaurantInformationResponse
  serviceAreas: ServiceAreaResponse
}

export type BookingQueryAvailabilitiesArgs = {
  bookingGuid?: InputMaybe<Scalars['ID']['input']>
  endTime: Scalars['DateTime']['input']
  partySize: Scalars['Int']['input']
  startTime: Scalars['DateTime']['input']
}

export type BookingQueryBookingsArgs = {
  searchDate: Scalars['Date']['input']
}

export type BookingQueryGuestArgs = {
  phoneNumber: Scalars['String']['input']
}

export type BookingRequiresDepositData = Error & {
  __typename: 'BookingRequiresDepositData'
  message: Scalars['String']['output']
  status: ErrorCode
}

export type BookingServiceArea = {
  __typename: 'BookingServiceArea'
  externalServiceArea?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type BookingServiceAreaGroup = {
  __typename: 'BookingServiceAreaGroup'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type BookingSource =
  | 'GOOGLE'
  | 'HOST_APP'
  | 'IMPORTED'
  | 'MANAGER_APP'
  | 'UNKNOWN'
  | 'WEB_CLIENT'

export type BookingStatus =
  | 'R_ARRIVED'
  | 'R_CANCELLED'
  | 'R_CHECKED_IN'
  | 'R_CONFIRMED'
  | 'R_CREATED'
  | 'R_DONE'
  | 'R_LATE_ARRIVAL'
  | 'R_NO_SHOW'
  | 'R_PARTIALLY_ARRIVED'
  | 'R_SEATED'
  | 'W_ARRIVED'
  | 'W_CANCELLED'
  | 'W_CHECKED_IN'
  | 'W_CONFIRMED'
  | 'W_DONE'
  | 'W_LATE_ARRIVAL'
  | 'W_NOTIFIED'
  | 'W_NO_SHOW'
  | 'W_PARTIALLY_ARRIVED'
  | 'W_SEATED'
  | 'W_WAITING'

export type BookingTable = {
  __typename: 'BookingTable'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type BookingType = 'RESERVATION' | 'WAITLIST'

export type BookingsEmployee = {
  __typename: 'BookingsEmployee'
  firstName?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['ID']['output']>
  lastName?: Maybe<Scalars['String']['output']>
  serverColor?: Maybe<ServerColor>
}

export type BreakConfig = {
  __typename: 'BreakConfig'
  id: Scalars['ID']['output']
  multiLocationId?: Maybe<Scalars['String']['output']>
  name?: Maybe<Scalars['String']['output']>
}

export type BreakConfigDependencyFailure = ShiftError & {
  __typename: 'BreakConfigDependencyFailure'
  message?: Maybe<Scalars['String']['output']>
}

export type BreakConfigInput = {
  id: Scalars['ID']['input']
}

export type BreakInput = {
  breakTimes: TimeRangeInput
  config: BreakConfigInput
}

export type BuildShiftFailure = ShiftError & {
  __typename: 'BuildShiftFailure'
  message?: Maybe<Scalars['String']['output']>
}

export type ByArchiveStatusSearchInput = {
  isArchived?: InputMaybe<TextFilter>
}

export type ByEmailSearchInput = {
  email?: InputMaybe<TextFilter>
}

export type ByEmployeeIdInputSearchInput = {
  employeeId?: InputMaybe<TextFilter>
}

export type ByJobIdInput = {
  id?: InputMaybe<TextFilter>
  ids?: InputMaybe<TextArrayFilter>
}

export type ByNameSearchInput = {
  chosenName?: InputMaybe<TextFilter>
  firstName?: InputMaybe<TextFilter>
  lastName?: InputMaybe<TextFilter>
}

export type ByPhoneNumberSearchInput = {
  phoneNumber?: InputMaybe<TextFilter>
}

/** Canada invoice */
export type CanCustomerBillingInvoice = CustomerBillingInvoice & {
  __typename: 'CANCustomerBillingInvoice'
  billingAddress?: Maybe<Scalars['String']['output']>
  createdDate: Scalars['Date']['output']
  /**  Deduction from invoice  */
  deductions: CustomerBillingInvoiceDeductions
  dueDate: Scalars['Date']['output']
  id: Scalars['ID']['output']
  items: Array<CanCustomerBillingInvoiceItem>
  netsuiteId: Scalars['ID']['output']
  orderType?: Maybe<Scalars['String']['output']>
  restaurant?: Maybe<Restaurant>
  salesforceId: Scalars['ID']['output']
  /**  Shipping totals  */
  shipping: CustomerBillingInvoiceShipping
  shippingAddress?: Maybe<Scalars['String']['output']>
  status: CustomerBillingInvoiceStatus
  terms?: Maybe<Scalars['String']['output']>
  /**  Tax details  */
  totalTaxDetails: CanCustomerBillingInvoiceTaxDetails
  totals: CustomerBillingInvoiceTotals
  transactionDate: Scalars['Date']['output']
  type: CustomerBillingInvoiceType
}

/** Canada invoice item */
export type CanCustomerBillingInvoiceItem = CustomerBillingInvoiceItem & {
  __typename: 'CANCustomerBillingInvoiceItem'
  /**  GST/HST, Goods and Services Tax (GST) / Harmonized Sales Tax  */
  GSTHST: Money
  /**  PST, Provincial Sales Taxes  */
  PST: Money
  amount: Money
  children: Array<CanCustomerBillingInvoiceItem>
  endDate: Scalars['Date']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  parentProduct?: Maybe<Scalars['String']['output']>
  quantity: Scalars['Int']['output']
  rate: Money
  startDate: Scalars['Date']['output']
}

/** Canada invoice tax details */
export type CanCustomerBillingInvoiceTaxDetails = {
  __typename: 'CANCustomerBillingInvoiceTaxDetails'
  /**  GST/HST, Goods and Services Tax (GST) / Harmonized Sales Tax  */
  GSTHST: Money
  /**  PST, Provincial Sales Taxes  */
  PST: Money
}

export type CacheControlScope = 'PRIVATE' | 'PUBLIC'

export type CancelReservationResponse =
  | BookingNotFound
  | CancelReservationResponseSuccess
  | ErrorRefundingDeposit
  | InternalError
  | RestaurantNotFound
  | UnsupportedTransition

export type CancelReservationResponseSuccess = {
  __typename: 'CancelReservationResponseSuccess'
  booking: Booking
}

export type CancelTrialRequest = {
  ecommSku: Scalars['String']['input']
  featureGroupId: Scalars['String']['input']
}

export type CancellationRequestState =
  | 'DISMISSED'
  | 'FULFILLED'
  | 'PENDING'
  | 'REQUESTED'

export type CancellationRequestType = 'AMENDMENT' | 'CANCELLATION'

export type CancellationSource =
  | 'GOOGLE'
  | 'HOST_APP'
  | 'IMPORTED'
  | 'MANAGER_APP'
  | 'SMS'
  | 'TOAST'
  | 'UNKNOWN'
  | 'WEB_CLIENT'

export type CapitalQuery = {
  __typename: 'CapitalQuery'
  applications: Array<LoanApplicationForCustomer>
  financialProducts: Array<FinancialProductForCustomer>
  prequalifiedOffers: Array<PrequalifiedOfferForCustomer>
}

export type CashDrawer = {
  __typename: 'CashDrawer'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  primaryPrinterId?: Maybe<Scalars['ID']['output']>
  resetType: ResetType
  secondaryPrinterId?: Maybe<Scalars['ID']['output']>
  startingBalance: Money
}

export type CashDrawerConfig = {
  __typename: 'CashDrawerConfig'
  closeOutVarianceMax?: Maybe<Money>
  closeOutVarianceWarning?: Maybe<Money>
  id: Scalars['ID']['output']
}

export type CashDrawerConfigInput = {
  closeOutVarianceMax?: InputMaybe<MoneyInput>
  closeOutVarianceWarning?: InputMaybe<MoneyInput>
}

export type CashDrawerInput = {
  resetType?: InputMaybe<ResetType>
  startingBalance?: InputMaybe<MoneyInput>
}

export type CashFlowAccount = {
  __typename: 'CashFlowAccount'
  account_id: Scalars['String']['output']
  account_last_four?: Maybe<Scalars['String']['output']>
}

export type CashFlowAccountTransactions = {
  __typename: 'CashFlowAccountTransactions'
  accountId: Scalars['String']['output']
  transactions: Array<CashFlowTransaction>
}

export type CashFlowSummary = {
  __typename: 'CashFlowSummary'
  availableBalance?: Maybe<Money>
  connectionCompanyId: Scalars['String']['output']
  connectionStatus: Scalars['String']['output']
  estimatedAvailableBalance?: Maybe<Money>
  expenseForecast?: Maybe<Money>
  revenueForecast: Money
  yyyymmdd: Scalars['Int']['output']
}

export type CashFlowTransaction = {
  __typename: 'CashFlowTransaction'
  amount: Money
  category: CashFlowTransactionCategory
  description?: Maybe<Scalars['String']['output']>
  isExcludedFromPnl: Scalars['Boolean']['output']
  isInternalTransfer: Scalars['Boolean']['output']
  isReviewed: Scalars['Boolean']['output']
  merchantName?: Maybe<Scalars['String']['output']>
  transactionDateYyyymmdd: Scalars['Int']['output']
  transactionId: Scalars['String']['output']
  transactionType: CashFlowTransactionType
  version: Scalars['Int']['output']
}

export type CashFlowTransactionCategory =
  | 'FOOD_AND_BEVERAGE_COGS'
  | 'FOOD_AND_BEVERAGE_SALES'
  | 'GENERAL_AND_ADMINISTRATIVE'
  | 'LOAN_DISBURSEMENTS'
  | 'LOAN_REPAYMENTS'
  | 'MARKETING'
  | 'NON_OPERATING'
  | 'OCCUPANCY'
  | 'OPERATING'
  | 'OTHER_INCOME'
  | 'PAYROLL'
  | 'PURCHASE_OF_ASSETS'
  | 'REPAIR_AND_MAINTENANCE'
  | 'SALES_TAX_COLLECTED'
  | 'SALES_TAX_PAID'
  | 'SALE_OF_ASSETS'
  | 'SHAREHOLDER_CONTRIBUTIONS'
  | 'SHAREHOLDER_DISTRIBUTIONS'
  | 'UNCATEGORIZED'
  | 'UTILITIES'

export type CashFlowTransactionSortingFields = 'AMOUNT' | 'TRANSACTION_DATE'

export type CashFlowTransactionSortingOrder = 'ASC' | 'DESC'

export type CashFlowTransactionType = 'INFLOW' | 'OUTFLOW'

export type Category = {
  __typename: 'Category'
  categorySpa?: Maybe<Scalars['String']['output']>
  categoryType: CategoryType
  description: Scalars['String']['output']
  groupId: Scalars['String']['output']
  headerSpa?: Maybe<Scalars['String']['output']>
  icon: MenuItemIcon
  iconHighlightOverridePaths: Array<Scalars['String']['output']>
  id: Scalars['ID']['output']
  longName: Scalars['String']['output']
  name: Scalars['String']['output']
  path?: Maybe<Scalars['String']['output']>
  sections: Array<Section>
  taskPaths: Array<Scalars['String']['output']>
}

export type CategoryType =
  | 'BANQUET_LINK_CATEGORY'
  | 'CATEGORY_PAGE'
  | 'EXTERNAL_LINK_CATEGORY'
  | 'SPA_CATEGORY'

export type ChannelAvailability = {
  __typename: 'ChannelAvailability'
  enabled?: Maybe<Scalars['Boolean']['output']>
  id?: Maybe<Scalars['ID']['output']>
  multiLocationId?: Maybe<Scalars['String']['output']>
  snoozedUntil?: Maybe<Scalars['DateTime']['output']>
}

export type ChannelMenuEntity = {
  __typename: 'ChannelMenuEntity'
  channel?: Maybe<SavedChannel>
  channelMultiLocationId?: Maybe<Scalars['String']['output']>
  deleted?: Maybe<Scalars['Boolean']['output']>
  id: Scalars['ID']['output']
  menu?: Maybe<Menu>
  menuEntityMultiLocationId?: Maybe<Scalars['String']['output']>
  menuEntityType?: Maybe<Scalars['String']['output']>
  menuPriceIncreaseType?: Maybe<ChannelMenuPriceIncreaseType>
  menuPriceIncreaseValue?: Maybe<Scalars['Float']['output']>
  multiLocationId?: Maybe<Scalars['String']['output']>
  owner?: Maybe<Scalars['ID']['output']>
  ownerRestaurantSet?: Maybe<RestaurantSet>
  target?: Maybe<Scalars['ID']['output']>
  targetRestaurantSet?: Maybe<RestaurantSet>
  visible?: Maybe<Scalars['Boolean']['output']>
}

export type ChannelMenuEntityInput = {
  channelMultiLocationId?: InputMaybe<Scalars['String']['input']>
  guid?: InputMaybe<Scalars['ID']['input']>
  menuEntityMultiLocationId?: InputMaybe<Scalars['String']['input']>
  menuEntityType?: InputMaybe<Scalars['String']['input']>
  menuPriceIncreaseType?: InputMaybe<ChannelMenuPriceIncreaseType>
  menuPriceIncreaseValue?: InputMaybe<Scalars['Float']['input']>
  multiLocationId?: InputMaybe<Scalars['String']['input']>
  owner?: InputMaybe<Scalars['ID']['input']>
  target?: InputMaybe<Scalars['ID']['input']>
  visible?: InputMaybe<Scalars['Boolean']['input']>
}

export type ChannelMenuPriceAdjustment = {
  __typename: 'ChannelMenuPriceAdjustment'
  amount?: Maybe<Money>
  channel?: Maybe<SavedChannel>
  channelMultiLocationId: Scalars['String']['output']
  id: Scalars['ID']['output']
  multiLocationId?: Maybe<Scalars['String']['output']>
  owner?: Maybe<Scalars['ID']['output']>
  ownerRestaurantSet?: Maybe<RestaurantSet>
  percentage?: Maybe<Scalars['Float']['output']>
  revision: Scalars['Int']['output']
  target?: Maybe<Scalars['ID']['output']>
  targetRestaurantSet?: Maybe<RestaurantSet>
  type: ChannelMenuPriceIncreaseType
}

export type ChannelMenuPriceAdjustmentInput = {
  amount?: InputMaybe<MenusMoneyInput>
  channelMultiLocationId: Scalars['String']['input']
  percentage?: InputMaybe<Scalars['Float']['input']>
  type: ChannelMenuPriceIncreaseType
}

export type ChannelMenuPriceIncreaseType = 'AMOUNT' | 'NONE' | 'PERCENTAGE'

export type ChannelMutation = {
  __typename: 'ChannelMutation'
  createSavedChannelAvailabilities: Array<SavedChannelAvailability>
  createSavedChannelAvailabilityForChannel: SavedChannel
  createSavedChannels: Array<SavedChannel>
  deleteSavedChannel: Scalars['ID']['output']
  deleteSavedChannelAvailability: Scalars['ID']['output']
  initializeSavedChannel: SavedChannel
  updateSavedChannel: SavedChannel
  updateSavedChannelAvailability: SavedChannelAvailability
}

export type ChannelMutationCreateSavedChannelAvailabilitiesArgs = {
  savedChannelAvailabilities: Array<SavedChannelAvailabilityInput>
}

export type ChannelMutationCreateSavedChannelAvailabilityForChannelArgs = {
  savedChannel: SavedChannelInput
}

export type ChannelMutationCreateSavedChannelsArgs = {
  savedChannels: Array<SavedChannelInput>
}

export type ChannelMutationDeleteSavedChannelArgs = {
  channelMultilocationId: Scalars['String']['input']
  versionId: Scalars['ID']['input']
}

export type ChannelMutationDeleteSavedChannelAvailabilityArgs = {
  channelAvailabilityMultilocationId: Scalars['String']['input']
  versionId: Scalars['ID']['input']
}

export type ChannelMutationInitializeSavedChannelArgs = {
  channelTypeId: Scalars['ID']['input']
  shouldPublish?: InputMaybe<Scalars['Boolean']['input']>
}

export type ChannelMutationUpdateSavedChannelArgs = {
  savedChannel: SavedChannelInput
}

export type ChannelMutationUpdateSavedChannelAvailabilityArgs = {
  savedChannelAvailability: SavedChannelAvailabilityInput
}

export type ChannelQuery = {
  __typename: 'ChannelQuery'
  channelTypes: Array<ChannelType>
  publishedChannels: Array<PublishedChannel>
  savedChannelAvailabilities: PagedSavedChannelAvailability
  savedChannelAvailabilityById: SavedChannelAvailability
  savedChannelAvailabilityByMultiLocationId: SavedChannelAvailability
  savedChannelByChannelType: SavedChannel
  savedChannelByMultiLocationId: SavedChannel
  savedChannels?: Maybe<Array<SavedChannel>>
  searchSavedChannels: PagedSavedChannel
}

export type ChannelQuerySavedChannelAvailabilitiesArgs = {
  input?: InputMaybe<SavedChannelAvailabilitySearchInput>
}

export type ChannelQuerySavedChannelAvailabilityByIdArgs = {
  availabilityId: Scalars['String']['input']
}

export type ChannelQuerySavedChannelAvailabilityByMultiLocationIdArgs = {
  multiLocationId: Scalars['String']['input']
}

export type ChannelQuerySavedChannelByChannelTypeArgs = {
  channelTypeId: Scalars['ID']['input']
}

export type ChannelQuerySavedChannelByMultiLocationIdArgs = {
  channelMultiLocationId: Scalars['String']['input']
}

export type ChannelQuerySearchSavedChannelsArgs = {
  input?: InputMaybe<SavedChannelSearchInput>
}

export type ChannelType = {
  __typename: 'ChannelType'
  category: Scalars['String']['output']
  clientId?: Maybe<Scalars['String']['output']>
  created?: Maybe<Scalars['DateTime']['output']>
  deleted: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  partner?: Maybe<Partner>
  partnerId?: Maybe<Scalars['ID']['output']>
  requestIdentifier: Scalars['String']['output']
  savedChannels: Array<SavedChannel>
  updated?: Maybe<Scalars['DateTime']['output']>
}

export type ChannelTypeInput = {
  category: Scalars['String']['input']
  clientId?: InputMaybe<Scalars['String']['input']>
  created?: InputMaybe<Scalars['DateTime']['input']>
  deleted: Scalars['Boolean']['input']
  id: Scalars['ID']['input']
  name: Scalars['String']['input']
  partnerId?: InputMaybe<Scalars['ID']['input']>
  requestIdentifier: Scalars['String']['input']
  updated?: InputMaybe<Scalars['DateTime']['input']>
}

export type ChatMessage = {
  __typename: 'ChatMessage'
  content: Scalars['String']['output']
  filters?: Maybe<ChatMessageFilters>
  metadata?: Maybe<Scalars['String']['output']>
  role: ChatRole
  sequence: Scalars['Int']['output']
  type: ChatMessageType
}

export type ChatMessageFeedback = {
  comments?: InputMaybe<Scalars['String']['input']>
  reason?: InputMaybe<Scalars['String']['input']>
  sequence: Scalars['Int']['input']
  type: AiInsightFeedbackType
}

export type ChatMessageFilters = {
  __typename: 'ChatMessageFilters'
  dateRange: ChatMessageFiltersDateRange
  daysOfWeek?: Maybe<Array<Scalars['String']['output']>>
  definedHours?: Maybe<ChatMessageFiltersDefinedHours>
}

export type ChatMessageFiltersDateRange = {
  __typename: 'ChatMessageFiltersDateRange'
  end: Scalars['String']['output']
  start: Scalars['String']['output']
}

export type ChatMessageFiltersDefinedHours = {
  __typename: 'ChatMessageFiltersDefinedHours'
  end: Scalars['Int']['output']
  start: Scalars['Int']['output']
}

export type ChatMessageInput = {
  content: Scalars['String']['input']
  metadata?: InputMaybe<Scalars['String']['input']>
  role: ChatRole
  sequence: Scalars['Int']['input']
  type: ChatMessageType
}

export type ChatMessageType =
  | 'AI_INSIGHT'
  | 'QUESTION'
  | 'QUESTION_NOT_RELEVANT'

export type ChatResponse = {
  __typename: 'ChatResponse'
  messages: Array<ChatMessage>
  sessionId: Scalars['ID']['output']
  timestamp: Scalars['DateTime']['output']
}

export type ChatRole = 'ASSISTANT' | 'USER'

/** Reference to a Check entity */
export type Check = {
  __typename: 'Check'
  amount?: Maybe<Money>
  amountDue?: Maybe<Money>
  appliedDiscounts: Array<OrdersAppliedDiscount>
  appliedLoyaltyInfo?: Maybe<OrdersAppliedLoyaltyInfo>
  appliedPreauthInfo?: Maybe<OrdersAppliedPreauthInfo>
  closedDate?: Maybe<Scalars['DateTime']['output']>
  deleted?: Maybe<Scalars['Boolean']['output']>
  displayNumber: Scalars['String']['output']
  guestFeedback: Array<GuestFeedbackInfo>
  id: Scalars['ID']['output']
  openedDate?: Maybe<Scalars['DateTime']['output']>
  /**  The order that this check is associated with. Required backreference for check resolution, as the Orders REST API does not provide an endpoint for fetching a Check.  */
  orderId: Scalars['ID']['output']
  paymentStatus?: Maybe<CheckPaymentStatus>
  payments: Array<OrdersPayment>
  selections: Array<OrdersSelection>
  tabName: Scalars['String']['output']
  taxAmount?: Maybe<Money>
  tipAmount?: Maybe<Money>
  tipPercentage?: Maybe<Scalars['Float']['output']>
  totalAmount?: Maybe<Money>
  voidedDate?: Maybe<Scalars['DateTime']['output']>
}

export type CheckPaymentStatus = 'CLOSED' | 'OPEN' | 'PAID' | 'UNRECOGNIZED'

/** Count of checks with refunds */
export type ChecksWithRefundsCount = {
  __typename: 'ChecksWithRefundsCount'
  /** Total count of checks with refunds, grouped by specified dimension */
  grouped?: Maybe<Array<GroupedChecksWithRefundsCount>>
  /** Total count of checks with refunds */
  totals: IntegerMetric
}

/** Count of checks with refunds */
export type ChecksWithRefundsCountGroupedArgs = {
  by: RefundsGroupingDimension
}

/** Total count of checks with voids, i.e., the number of checks that have at least one void associated with them */
export type ChecksWithVoidsCount = {
  __typename: 'ChecksWithVoidsCount'
  /** Total count of checks with voids, grouped by specified dimension */
  grouped?: Maybe<Array<GroupedChecksWithVoidsCount>>
  /** Total count of checks with voids */
  totals: IntegerMetric
}

/** Total count of checks with voids, i.e., the number of checks that have at least one void associated with them */
export type ChecksWithVoidsCountGroupedArgs = {
  by: VoidGroupingDimension
}

export type ClockOutFailure = ShiftError & {
  __typename: 'ClockOutFailure'
  message?: Maybe<Scalars['String']['output']>
}

export type CloseOutReportingConfig = {
  __typename: 'CloseOutReportingConfig'
  id: Scalars['ID']['output']
  shouldAuditRemovedItems: Scalars['Boolean']['output']
  shouldAutoPrintClosedCashDrawerReportOnClose: Scalars['Boolean']['output']
  shouldShowTipsBySalesCategory: Scalars['Boolean']['output']
  tipSharingConfig: TipSharingConfig
}

export type CloseOutReportingContent = {
  __typename: 'CloseOutReportingContent'
  closedCashDrawerReportSections: Array<ReportSection>
  id: Scalars['ID']['output']
  shiftReviewReportSections: Array<ReportSection>
  zReportSections: Array<ReportSection>
}

export type CompareTo =
  /** Compare with the same day last week. When comparing ranges, this will compare each day in the range with the same day of the week before it. */
  | 'SAME_DAY_LAST_WEEK'
  /** Compare with the same day last year. When comparing ranges, this will compare each day in the range with the same day of the year before it. */
  | 'SAME_DAY_LAST_YEAR'
  /** Compare with the same day two years ago. When comparing ranges, this will compare each day in the range with the same day two years before it. */
  | 'SAME_DAY_TWO_YEARS_BEFORE'
  /** Compare with yesterday. When comparing ranges, this will compare each day in the range with the day before it. */
  | 'YESTERDAY'

/** Details of the comparison */
export type Comparison = {
  __typename: 'Comparison'
  /** Analysis of the comparison */
  analysis: Analysis
  /** Percentage change versus the comparison value */
  percentageChange: Scalars['Decimal']['output']
}

export type ConferencingPlatform =
  | 'GOOGLE_MEET'
  | 'MICROSOFT_TEAMS'
  /** The meeting is in person */
  | 'ONSITE'
  | 'OTHER'
  | 'WEBEX'
  | 'ZOOM'

export type ConfigCopyQuery = {
  __typename: 'ConfigCopyQuery'
  configEntities: Array<SavedConfig>
  configTypes: Array<ConfigType>
  copyConfigTask?: Maybe<CopyConfigTask>
  copyConfigTasks: CopyConfigTaskConnection
}

export type ConfigCopyQueryConfigEntitiesArgs = {
  restaurantSetId: Scalars['ID']['input']
}

export type ConfigCopyQueryCopyConfigTaskArgs = {
  id: Scalars['ID']['input']
}

export type ConfigCopyQueryCopyConfigTasksArgs = {
  input: CopyConfigTasksPaginationInput
}

export type ConfigEntityTag = 'NOT_USER_CONTROLLED'

export type ConfigField = {
  __typename: 'ConfigField'
  configType?: Maybe<ConfigType>
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  /** @deprecated Use navigationPages */
  navigationPage?: Maybe<NavigationPage>
  navigationPages: Array<NavigationPage>
  url?: Maybe<Scalars['String']['output']>
}

export type ConfigFieldInput = {
  id: Scalars['ID']['input']
}

export type ConfigLocation = {
  __typename: 'ConfigLocation'
  categoryName: Scalars['String']['output']
  icon: MenuItemIcon
  sectionName: Scalars['String']['output']
  taskName: Scalars['String']['output']
  taskPath: Scalars['String']['output']
}

export type ConfigMutation = {
  __typename: 'ConfigMutation'
  archiveRestaurantGroup: RestaurantGroup
}

export type ConfigMutationArchiveRestaurantGroupArgs = {
  id: Scalars['ID']['input']
}

export type ConfigQuery = {
  __typename: 'ConfigQuery'
  /** @deprecated Use configCopy.configTypes instead */
  availableConfigTypes: Array<ConfigType>
  /** @deprecated Use configCopy.copyConfigTasks instead */
  copyConfigTasks: CopyConfigTaskConnection
  referencesCount: Array<ConfigReferencesCount>
}

export type ConfigQueryCopyConfigTasksArgs = {
  input: CopyConfigTasksPaginationInput
}

export type ConfigQueryReferencesCountArgs = {
  restaurantSetId: Scalars['ID']['input']
}

export type ConfigReferencesCount = {
  __typename: 'ConfigReferencesCount'
  configType: ConfigType
  count: Scalars['Int']['output']
}

export type ConfigType = {
  __typename: 'ConfigType'
  displayName: Scalars['String']['output']
  fields: Array<ConfigField>
  id: Scalars['ID']['output']
  navigationPage?: Maybe<NavigationPage>
  partial: Scalars['Boolean']['output']
  tags: Array<ConfigEntityTag>
}

export type ConfigTypeInput = {
  fields?: InputMaybe<Array<ConfigFieldInput>>
  id: Scalars['ID']['input']
}

export type ConfigValueDiff = {
  __typename: 'ConfigValueDiff'
  current: Array<ConfigValueDiffInformation>
  previous: Array<ConfigValueDiffInformation>
  type: PublishedConfigDiffType
}

export type ConfigValueDiffInformation = {
  __typename: 'ConfigValueDiffInformation'
  displayValue: Scalars['String']['output']
  referenceId?: Maybe<Scalars['String']['output']>
  url?: Maybe<Scalars['String']['output']>
}

export type ConnectionPaginationInput = {
  after?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
}

/** Indicates whether or not the item contains alcohol. UNSPECIFIED indicates that the user has not made a selection */
export type ContainsAlcohol = 'NO' | 'UNSPECIFIED' | 'YES'

export type CopyConfigMutation = {
  __typename: 'CopyConfigMutation'
  createCopyConfigTask: CopyConfigTask
}

export type CopyConfigMutationCreateCopyConfigTaskArgs = {
  copyConfigTask: CopyConfigTaskInput
}

export type CopyConfigStatus =
  | 'COMPLETED'
  | 'FAILED'
  | 'IN_PROGRESS'
  | 'NOT_STARTED'

export type CopyConfigTask = {
  __typename: 'CopyConfigTask'
  configTypes: Array<ConfigType>
  destinations: Array<RestaurantSet>
  id: Scalars['ID']['output']
  message?: Maybe<Scalars['String']['output']>
  requestedAt: Scalars['DateTime']['output']
  source: SavedRestaurantLocation
  startedBy: User
  status: CopyConfigStatus
}

export type CopyConfigTaskConnection = {
  __typename: 'CopyConfigTaskConnection'
  edges: Array<Maybe<CopyConfigTaskEdge>>
  pageInfo: PageInfo
}

export type CopyConfigTaskEdge = {
  __typename: 'CopyConfigTaskEdge'
  cursor: Scalars['String']['output']
  node: CopyConfigTask
}

export type CopyConfigTaskInput = {
  configTypes: Array<ConfigTypeInput>
  destinationRestaurantSets: Array<Scalars['ID']['input']>
  sourceRestaurantSet: Scalars['ID']['input']
}

export type CopyConfigTasksPaginationInput = {
  after?: InputMaybe<Scalars['String']['input']>
  first: Scalars['Int']['input']
}

export type CorruptPageTokenError = Errors & {
  __typename: 'CorruptPageTokenError'
  message: Scalars['String']['output']
}

export type Course = {
  __typename: 'Course'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  visible: Scalars['Boolean']['output']
}

export type CreateBlockRequest = {
  blockedReason: Scalars['String']['input']
  hostReservationsBlocked: Scalars['Boolean']['input']
  onlineReservationsBlocked: Scalars['Boolean']['input']
  onlineWaitlistBlocked: Scalars['Boolean']['input']
}

export type CreateBlockResponse = CreateBlockSuccess | RestaurantNotFound

export type CreateBlockSuccess = {
  __typename: 'CreateBlockSuccess'
  blockConfigs: Array<BlockedDate>
}

export type CreateDataExtensionSetInput = {
  name?: InputMaybe<Scalars['String']['input']>
  overrides: Array<DataExtensionSetOverrideInput>
  payments: Array<PaymentDataExtensionInput>
  revenueCenters: Array<DataExtensionInput>
  salesCategories: Array<DataExtensionInput>
  services: Array<DataExtensionInput>
  target?: InputMaybe<Scalars['ID']['input']>
  taxRates: Array<DataExtensionInput>
  type: DataExtensionSetType
}

export type CreateGfdConfigInput = {
  /** The display mode on the GFD. */
  displayMode: DisplayTheme
  /** Whether GFD is enabled or not. */
  enabled: Scalars['Boolean']['input']
  /** Whether to hide price from modifier item. */
  hideModifierPrices: Scalars['Boolean']['input']
  /** To enable/disable early loyalty check-in option for loyalty customers */
  isEarlyLoyaltyCheckIn: Scalars['Boolean']['input']
  /** User defined name for this config. */
  name?: InputMaybe<Scalars['String']['input']>
  orderInProgressImage?: InputMaybe<GfdImageInput>
  /** Config owner represented as restaurant set guid */
  owner: Scalars['ID']['input']
  standByImage?: InputMaybe<GfdImageInput>
  /** Config target represented as restaurant set guid */
  target: Scalars['ID']['input']
}

export type CreateGuestRequest = {
  email: Scalars['String']['input']
  firstName: Scalars['String']['input']
  guestNotes: Scalars['String']['input']
  guestTags: Array<Scalars['String']['input']>
  lastName: Scalars['String']['input']
  phoneNumber: Scalars['String']['input']
}

export type CreateItemFeedbackConfigError = {
  __typename: 'CreateItemFeedbackConfigError'
  code: CreateItemFeedbackConfigErrorCode
  message?: Maybe<Scalars['String']['output']>
}

export type CreateItemFeedbackConfigErrorCode =
  | 'FailedToCreateConfig'
  | 'ManagementSetGuidNotFound'

export type CreateItemFeedbackConfigResponse =
  | CreateItemFeedbackConfigError
  | ItemFeedbackConfig
  | ItemFeedbackConfigAlreadyExists

export type CreateLogbookEntryReply = {
  content: Scalars['String']['input']
  entryID: Scalars['ID']['input']
  locationID: Scalars['ID']['input']
}

export type CreateLogbookEntryWithBusinessDay = {
  businessDay: Scalars['Date']['input']
  categoryID: Scalars['ID']['input']
  content: Scalars['String']['input']
  locationID: Scalars['ID']['input']
  priority: LogbookEntryPriority
}

export type CreateMembershipInput = {
  address?: InputMaybe<MembershipAddressInput>
  customerNumber?: InputMaybe<Scalars['String']['input']>
  email: Scalars['String']['input']
  firstName: Scalars['String']['input']
  lastName?: InputMaybe<Scalars['String']['input']>
  phone: Scalars['String']['input']
}

export type CreateMembershipResult = {
  __typename: 'CreateMembershipResult'
  cardGuid?: Maybe<Scalars['String']['output']>
  message?: Maybe<Scalars['String']['output']>
  status: Scalars['String']['output']
}

export type CreateMenuItemPlateCost = {
  cost: MoneyInput
  itemMultiLocationID: Scalars['ID']['input']
  source?: PlateCostSource
}

export type CreateReasonInput = {
  description?: InputMaybe<Scalars['String']['input']>
  isActive: Scalars['Boolean']['input']
  name: Scalars['String']['input']
  ordinal: Scalars['Int']['input']
}

export type CreateReservationRequest = {
  bookingNotes: Scalars['String']['input']
  dateTime: Scalars['DateTime']['input']
  deposit?: InputMaybe<DepositRequest>
  endTime?: InputMaybe<Scalars['DateTime']['input']>
  guest: Scalars['ID']['input']
  partySize: Scalars['Int']['input']
  requestedServiceAreaGroups?: InputMaybe<Array<Scalars['ID']['input']>>
  sendText: Scalars['Boolean']['input']
  serviceAreaGuids: Array<Scalars['ID']['input']>
  specialOccasion?: SpecialOccasion
  tableGuids: Array<Scalars['ID']['input']>
}

export type CreateReservationResponse =
  | BookingRequiresDepositData
  | CreateReservationResponseSuccess
  | DepositAmountChanged
  | DepositRuleNotMatching
  | InvalidEmail
  | InvalidGuest
  | InvalidPhoneNumber
  | RestaurantNotFound
  | ServiceAreaNotFound
  | TableBlocked
  | TableNotFound
  | UnableToCreateDepositOrder
  | UnableToCreateGuest
  | UnableToSendSms

export type CreateReservationResponseSuccess = {
  __typename: 'CreateReservationResponseSuccess'
  booking: Booking
}

export type CreateRestaurantGroupInput = {
  description?: InputMaybe<Scalars['String']['input']>
  name: Scalars['String']['input']
}

export type CreateShiftInput = {
  employee: RestaurantUserInput
  job?: InputMaybe<JobInput>
  restaurant: RestaurantInput
  timeClock: TimeClockInput
  tips?: InputMaybe<TipInput>
}

export type CreateShiftResult =
  | BreakConfigDependencyFailure
  | BuildShiftFailure
  | EmployeeRepositoryDependencyFailure
  | EntitlementsRepositoryDependencyFailure
  | FinishedEmployeeShift
  | InProgressEmployeeShift
  | InvalidBreakConfig
  | InvalidBreaks
  | InvalidJob
  | InvalidUpdatedTime
  | JobsRepositoryDependencyFailure
  | LoadShiftsFailure
  | LocateShiftFailure
  | MissingJob
  | MissingTimeClock
  | OverlappingBreaks
  | OverlappingShiftExists
  | PublishRepositoryDependencyFailure
  | ReadFailure
  | RestaurantRepositoryDependencyFailure
  | SaveFailure
  | ShiftCheckedResultRepositoryDependencyFailure
  | ShiftNotFound
  | Unauthorized
  | UnknownError

export type CreateToastPayConfigError = {
  __typename: 'CreateToastPayConfigError'
  code: CreateToastPayConfigErrorCode
  message?: Maybe<Scalars['String']['output']>
}

export type CreateToastPayConfigErrorCode =
  | 'ConfigAlreadyExists'
  | 'FailedToSaveConfig'
  | 'ManagementSetGuidNotFound'
  | 'ManagementSetRetrievalFailed'
  | 'RestaurantSetNotFound'

export type CreateToastPayConfigResponse =
  | CreateToastPayConfigError
  | ToastPayConfig

export type CreateTrialRequest = {
  ecommSku: Scalars['String']['input']
  featureGroupId: Scalars['String']['input']
}

export type CreateUserQuickActionInput = {
  entityId: Scalars['String']['input']
  entityType: UserQuickActionEntityType
  order?: InputMaybe<Scalars['Int']['input']>
  params?: InputMaybe<Array<UserQuickActionParamInput>>
  title?: InputMaybe<Scalars['String']['input']>
}

/** An `ISO-4217` compliant Currency. */
export type Currency =
  /** Canadian Dollars (CAD). */
  | 'CAD'
  /** Euro (EUR). */
  | 'EUR'
  /** United Kingdom Pounds (GBP). */
  | 'GBP'
  /** United States Dollars (USD). */
  | 'USD'

export type CurrentBookingUsageResponse =
  | CurrentBookingUsageResponseSuccess
  | RestaurantNotFound

export type CurrentBookingUsageResponseSuccess = {
  __typename: 'CurrentBookingUsageResponseSuccess'
  result: RestaurantBookingLimit
}

/** A manually configured upsell for a menu item. */
export type CustomMenuItemUpsell = {
  __typename: 'CustomMenuItemUpsell'
  /** The stored config ID */
  id: Scalars['ID']['output']
  /** The management group that this upsell is configured for */
  managementGroup: ManagementGroup
  /**  The guids of the menu groups that will trigger the upsell if an item from them is in the order. In the future, this will be deprecated and menuGroupMlids will be used.  */
  menuGroupGuids: Array<Scalars['ID']['output']>
  /** The multi-location IDs of the menu groups that will trigger the upsell if an item from them is in the order */
  menuGroupMlids: Array<Scalars['ID']['output']>
  /**  The guid of the menu item that is being upsold. In the future, this will be deprecated and menuItemMlid will be used.  */
  menuItemGuid: Scalars['ID']['output']
  /** The multi-location ID of the menu item that is being upsold */
  menuItemMlid: Scalars['ID']['output']
  /** The priority of the upsell */
  ordinal: Scalars['Int']['output']
  /** The restaurant sets that the upsell targets */
  targets: Array<RestaurantSet>
}

/** Credit memo */
export type CustomerBillingCreditMemo = {
  __typename: 'CustomerBillingCreditMemo'
  /**  All the invoices current credit memo is applied to  */
  appliedTo: Array<CustomerBillingCreditMemoApplication>
  /**  Also known as TransactionId (e.g. CM0000001)  */
  id: Scalars['ID']['output']
  /**  Last modified date  */
  lastModifiedDate: Scalars['Date']['output']
  /**  Netsuite id  */
  netsuiteId: Scalars['ID']['output']
  /**  The reason of issuing this credit memo  */
  reason?: Maybe<Scalars['String']['output']>
  /**  Restaurant this credit memo is issued for  */
  restaurant: Restaurant
  /**  Status of credit memo  */
  status: CustomerBillingCreditMemoStatus
  /**  Total sum of credit memo, which could be partially applied to different invoices  */
  total: Money
  /**  Date of issuing this credit memo  */
  transactionDate: Scalars['Date']['output']
}

/** Credit memo applied to invoice */
export type CustomerBillingCreditMemoApplication = {
  __typename: 'CustomerBillingCreditMemoApplication'
  /**  Amount of credit memo applied to specified invoice  */
  appliedAmount: Money
  /**  Credit memo applied to an invoice  */
  creditMemo: CustomerBillingCreditMemo
  /**  Invoice, which credit memo is applied to  */
  invoice: CustomerBillingInvoice
}

/** Credit memo status */
export type CustomerBillingCreditMemoStatus =
  | 'FULLY_APPLIED'
  | 'OPEN'
  | 'UNKNOWN'
  | 'VOIDED'

/**  Common interface for invoice, have different implementations for different countries or types  */
export type CustomerBillingInvoice = {
  /**  Billing address  */
  billingAddress?: Maybe<Scalars['String']['output']>
  /**  Date of creation  */
  createdDate?: Maybe<Scalars['Date']['output']>
  /**  Invoice due date  */
  dueDate?: Maybe<Scalars['Date']['output']>
  /**  Also known as invoice name (e.g. INV0001)  */
  id: Scalars['ID']['output']
  /**  Invoice items breakdown  */
  items?: Maybe<Array<CustomerBillingInvoiceItem>>
  /**  Netsuite id  */
  netsuiteId?: Maybe<Scalars['ID']['output']>
  /**  Type of order (e.g. Install hardware, Client demo, Third party seller)  */
  orderType?: Maybe<Scalars['String']['output']>
  /**  Restaurant, which is invoice issued to  */
  restaurant?: Maybe<Restaurant>
  /**  Salesforce id  */
  salesforceId: Scalars['ID']['output']
  /**  Shipping address  */
  shippingAddress?: Maybe<Scalars['String']['output']>
  /**  Invoice status  */
  status?: Maybe<CustomerBillingInvoiceStatus>
  /**  Agreed terms for an invoice (e.g. 12 Month Payment Plan, ACH on Shipping, Third-party Financing)  */
  terms?: Maybe<Scalars['String']['output']>
  /**  Invoice totals  */
  totals?: Maybe<CustomerBillingInvoiceTotals>
  /**  Transaction date  */
  transactionDate?: Maybe<Scalars['Date']['output']>
  /**  Type of invoice  */
  type?: Maybe<CustomerBillingInvoiceType>
}

/** Invoice deductions */
export type CustomerBillingInvoiceDeductions = {
  __typename: 'CustomerBillingInvoiceDeductions'
  creditMemosApplied: Array<CustomerBillingCreditMemoApplication>
  creditsApplied: Money
  depositsApplied: Money
  paymentsApplied: Money
}

/** Common interface for invoice item */
export type CustomerBillingInvoiceItem = {
  /**  Total sum of this items (rate * quantity)  */
  amount: Money
  /**  Child items. On bundle purchasing, children will contain all of the items included  */
  children: Array<CustomerBillingInvoiceItem>
  /**  Service end date  */
  endDate: Scalars['Date']['output']
  id: Scalars['ID']['output']
  /**  Item name or a Package name if it's a package  */
  name: Scalars['String']['output']
  /**  Quantity if items purchased  */
  quantity: Scalars['Int']['output']
  /**  Rate amount, price for single item  */
  rate: Money
  /**  Service start date  */
  startDate: Scalars['Date']['output']
}

/** Total shipping cost */
export type CustomerBillingInvoiceShipping = {
  __typename: 'CustomerBillingInvoiceShipping'
  /**  Tax amount  */
  taxAmount: Money
  /**  Tax rate  */
  taxRate: Money
  /**  Total sum, including taxes  */
  total: Money
}

/** Invoice status */
export type CustomerBillingInvoiceStatus =
  | 'OPEN'
  | 'PAID'
  | 'UNKNOWN'
  | 'VOIDED'

/**  Totals and subtotals. Use this numbers and do not calculate totals from invoice items by yourself  */
export type CustomerBillingInvoiceTotals = {
  __typename: 'CustomerBillingInvoiceTotals'
  /**  Sum of all invoice items. Shipping cost and taxes are not applied  */
  subtotal: Money
  /**  Total sum of taxes for this invoice  */
  taxAmount: Money
  /**  Sum of all invoices items with shipping cost and taxes applies  */
  total: Money
  /**  Total sum to be paid with all deductions applied  */
  totalDue: Money
}

/** Invoice type */
export type CustomerBillingInvoiceType = 'HARDWARE' | 'OTHER' | 'SOFTWARE'

/**  Paginated response for invoices list request See 'CustomerBillingPaginatedRequestInput' interface description  */
export type CustomerBillingInvoicesPaginatedResponse =
  CustomerBillingPaginatedResponse & {
    __typename: 'CustomerBillingInvoicesPaginatedResponse'
    /**  Current page number. Starts from 1  */
    currentPage: Scalars['Int']['output']
    /**  Next page flag  */
    hasNextPage: Scalars['Boolean']['output']
    /**  List of invoices on a page  */
    invoices: Array<CustomerBillingInvoice>
    /**  Token to request next page with. Will be NULL if current page is last  */
    nextPageToken?: Maybe<Scalars['String']['output']>
  }

/**  Use parameters to initialize your paginated request. For all subsequent next page requests pass only nextPageToken, which will be supplied on every response (except last page). If token is present on request, other params will be ignored  */
export type CustomerBillingPaginatedRequestInput = {
  /**  From date (-1 year from now by default)  */
  from?: InputMaybe<Scalars['Date']['input']>
  /**  Next page token  */
  nextPageToken?: InputMaybe<Scalars['String']['input']>
  /**  Page size (20 by default)  */
  pageSize?: InputMaybe<Scalars['Int']['input']>
  /**  Sort order (DESC by default)  */
  sortOrder?: InputMaybe<CustomerBillingRequestSortOrder>
  /**  Which field is used for sorting (BY_DATE by default)  */
  sortType?: InputMaybe<CustomerBillingRequestSortType>
  /**  To date (current date by default) */
  to?: InputMaybe<Scalars['Date']['input']>
}

/** Paginated response */
export type CustomerBillingPaginatedResponse = {
  /**  Current page number. Starts from 1  */
  currentPage: Scalars['Int']['output']
  /**  Next page flag  */
  hasNextPage: Scalars['Boolean']['output']
  /**  Token to request next page with. Will be NULL if current page is last  */
  nextPageToken?: Maybe<Scalars['String']['output']>
}

export type CustomerBillingQuery = {
  __typename: 'CustomerBillingQuery'
  /**  Single invoice details  */
  invoice?: Maybe<CustomerBillingInvoice>
  /**  Invoices list for a current restaurant  */
  invoices: CustomerBillingInvoicesPaginatedResponse
}

export type CustomerBillingQueryInvoiceArgs = {
  id: Scalars['ID']['input']
}

export type CustomerBillingQueryInvoicesArgs = {
  request?: InputMaybe<CustomerBillingPaginatedRequestInput>
}

/** Sort order */
export type CustomerBillingRequestSortOrder =
  /**  Ascending order  */
  | 'ASC'
  /**  Descending order  */
  | 'DESC'

/** Sort type */
export type CustomerBillingRequestSortType = 'BY_TRANSACTION_DATE'

/** Invoice detailed report response */
export type CustomerInvoiceDetailsReport = {
  __typename: 'CustomerInvoiceDetailsReport'
  billingAddress?: Maybe<Scalars['String']['output']>
  creditMemos: Array<CustomerInvoiceDetailsReportCreditMemo>
  creditMemosApplied: Money
  depositsApplied: Money
  downSellItems: Array<CustomerInvoiceDetailsReportServicePeriodItems>
  hasJsonData: Scalars['Boolean']['output']
  invoiceCreatedDate: Scalars['DateTime']['output']
  invoiceDueDate: Scalars['DateTime']['output']
  invoiceName: Scalars['ID']['output']
  invoiceTotal: Money
  invoiceType?: Maybe<Scalars['String']['output']>
  items: Array<CustomerInvoiceDetailsReportServicePeriodItems>
  netsuiteInvoiceId: Scalars['ID']['output']
  /** @deprecated Use depositsApplied */
  otherInvoiceDeductions: Money
  paymentsApplied: Money
  salesforceInvoiceId: Scalars['ID']['output']
  shippingAddress?: Maybe<Scalars['String']['output']>
  shippingTotal: Money
  subtotal: Money
  taxTotal: Money
  terms?: Maybe<Scalars['String']['output']>
  toastGuid: Scalars['String']['output']
  totalDue: Money
  transactionDate: Scalars['DateTime']['output']
}

/** Invoice detailed report credit memo info */
export type CustomerInvoiceDetailsReportCreditMemo = {
  __typename: 'CustomerInvoiceDetailsReportCreditMemo'
  amount: Money
  createdDate: Scalars['DateTime']['output']
  name: Scalars['String']['output']
}

/** Invoice detailed report period item */
export type CustomerInvoiceDetailsReportServicePeriodItem = {
  __typename: 'CustomerInvoiceDetailsReportServicePeriodItem'
  amount: Money
  children: Array<CustomerInvoiceDetailsReportServicePeriodItem>
  itemType: Scalars['String']['output']
  productFamily: Scalars['String']['output']
  productName: Scalars['String']['output']
  quantity: Scalars['Int']['output']
  rate: Money
}

/** Invoice detailed report period items */
export type CustomerInvoiceDetailsReportServicePeriodItems = {
  __typename: 'CustomerInvoiceDetailsReportServicePeriodItems'
  items: Array<CustomerInvoiceDetailsReportServicePeriodItem>
  serviceDateEnd?: Maybe<Scalars['DateTime']['output']>
  serviceDateStart: Scalars['DateTime']['output']
}

/** Invoices list report response */
export type CustomerInvoiceListReport = {
  __typename: 'CustomerInvoiceListReport'
  endDateInCurrentPeriod: Scalars['DateTime']['output']
  invoicesInCurrentPeriod?: Maybe<Array<CustomerInvoiceListReportItem>>
  previousInvoices?: Maybe<Array<CustomerInvoiceListReportItem>>
  startDateInCurrentPeriod: Scalars['DateTime']['output']
  totalAmountsInCurrentPeriod?: Maybe<CustomerInvoiceListTotalAmounts>
}

/** Single invoice details in a invoices list request */
export type CustomerInvoiceListReportItem = {
  __typename: 'CustomerInvoiceListReportItem'
  invoiceCreatedDate: Scalars['DateTime']['output']
  invoiceDetailsAreAvailable: Scalars['Boolean']['output']
  invoiceDueDate: Scalars['DateTime']['output']
  invoiceName: Scalars['ID']['output']
  invoiceStatus: Scalars['String']['output']
  invoiceTotal: Money
  invoiceTotalPaid: Money
  invoiceTransactionDate: Scalars['DateTime']['output']
  invoiceType: Scalars['String']['output']
  netsuiteTransactionId: Scalars['ID']['output']
  salesforceInvoiceId: Scalars['ID']['output']
  toastGuid: Scalars['String']['output']
}

/** Total amounts of requested list of invoices */
export type CustomerInvoiceListTotalAmounts = {
  __typename: 'CustomerInvoiceListTotalAmounts'
  totalCreditsApplied: Money
  totalHardwarePurchased: Money
  totalSoftwareFeesCharged: Money
}

/** Available invoice retrieval queries */
export type CustomerInvoiceQuery = {
  __typename: 'CustomerInvoiceQuery'
  invoiceDetailsReport?: Maybe<CustomerInvoiceDetailsReport>
  invoiceListReport?: Maybe<CustomerInvoiceListReport>
}

/** Available invoice retrieval queries */
export type CustomerInvoiceQueryInvoiceDetailsReportArgs = {
  invoiceName: Scalars['ID']['input']
  restaurantId: Scalars['ID']['input']
}

/** Available invoice retrieval queries */
export type CustomerInvoiceQueryInvoiceListReportArgs = {
  restaurantId: Scalars['ID']['input']
}

export type CustomerTasksCreateWorkflowTemplate = {
  key: Scalars['String']['input']
  scope: CustomerTasksWorkflowScope
  taskKeys: Array<Scalars['String']['input']>
}

export type CustomerTasksMutation = {
  __typename: 'CustomerTasksMutation'
  activateTaskTemplate: CustomerTasksTask
  activateWorkflowTemplate: CustomerTasksWorkflow
  /** Returns a list of external entity ids that were activated */
  bulkActivateWorkflows: Array<Scalars['ID']['output']>
  /** Returns a list of external entity ids that were activated */
  bulkDeactivateWorkflows: Array<Scalars['ID']['output']>
  createWorkflowTemplate: CustomerTasksWorkflowTemplate
  resetTask: CustomerTasksTask
  updateTask: CustomerTasksTask
  updateWorkflowTemplate: CustomerTasksWorkflowTemplate
}

export type CustomerTasksMutationActivateTaskTemplateArgs = {
  entityIdentifier: Scalars['ID']['input']
  metadata?: InputMaybe<Scalars['String']['input']>
  taskKey: Scalars['String']['input']
}

export type CustomerTasksMutationActivateWorkflowTemplateArgs = {
  entityIdentifier: Scalars['ID']['input']
  workflowKey: Scalars['String']['input']
}

export type CustomerTasksMutationBulkActivateWorkflowsArgs = {
  entityIdentifiers: Array<Scalars['ID']['input']>
  workflowKey: Scalars['String']['input']
}

export type CustomerTasksMutationBulkDeactivateWorkflowsArgs = {
  entityIdentifiers: Array<Scalars['ID']['input']>
  workflowKey: Scalars['String']['input']
}

export type CustomerTasksMutationCreateWorkflowTemplateArgs = {
  template: CustomerTasksCreateWorkflowTemplate
  workflowKey: Scalars['String']['input']
}

export type CustomerTasksMutationResetTaskArgs = {
  metadata?: InputMaybe<Scalars['String']['input']>
  taskKey: Scalars['String']['input']
}

export type CustomerTasksMutationUpdateTaskArgs = {
  metadata?: InputMaybe<Scalars['String']['input']>
  status: CustomerTasksStatus
  taskKey: Scalars['String']['input']
  workflowKey?: InputMaybe<Scalars['String']['input']>
}

export type CustomerTasksMutationUpdateWorkflowTemplateArgs = {
  taskKeys: Array<Scalars['String']['input']>
  workflowKey: Scalars['String']['input']
}

export type CustomerTasksQuery = {
  __typename: 'CustomerTasksQuery'
  task: CustomerTasksTask
  taskProgress: CustomerTasksTaskTimeline
  taskTemplates: Array<CustomerTasksTaskTemplate>
  workflow?: Maybe<CustomerTasksWorkflow>
  workflowTemplate: CustomerTasksWorkflowTemplate
  workflowTemplates: Array<CustomerTasksWorkflowTemplate>
}

export type CustomerTasksQueryTaskArgs = {
  taskKey: Scalars['String']['input']
}

export type CustomerTasksQueryTaskProgressArgs = {
  taskKey: Scalars['String']['input']
}

export type CustomerTasksQueryWorkflowArgs = {
  workflowKey: Scalars['String']['input']
}

export type CustomerTasksQueryWorkflowTemplateArgs = {
  workflowKey: Scalars['String']['input']
}

export type CustomerTasksStatus =
  | 'BLOCKED'
  | 'CANCELED'
  | 'COMPLETED'
  | 'IN_PROGRESS'
  | 'NOT_STARTED'
  | 'ON_HOLD'
  | 'SKIPPED'

export type CustomerTasksTask = {
  __typename: 'CustomerTasksTask'
  dateCreated: Scalars['String']['output']
  externalEntityId: Scalars['ID']['output']
  history: Array<CustomerTasksTaskEvent>
  id: Scalars['String']['output']
  key: Scalars['String']['output']
  lastUpdated: Scalars['String']['output']
  status: CustomerTasksStatus
}

export type CustomerTasksTaskEvent = {
  __typename: 'CustomerTasksTaskEvent'
  dateOccurred: Scalars['String']['output']
  entityId: Scalars['String']['output']
  eventKey: Scalars['String']['output']
  updatedStatus?: Maybe<Scalars['String']['output']>
  user?: Maybe<User>
  userId: Scalars['String']['output']
}

export type CustomerTasksTaskTemplate = {
  __typename: 'CustomerTasksTaskTemplate'
  description: Scalars['String']['output']
  id: Scalars['String']['output']
  key: Scalars['String']['output']
}

export type CustomerTasksTaskTimeline = {
  __typename: 'CustomerTasksTaskTimeline'
  history: Array<CustomerTasksTaskEvent>
  key: Scalars['String']['output']
}

export type CustomerTasksWorkflow = {
  __typename: 'CustomerTasksWorkflow'
  active: Scalars['Boolean']['output']
  externalEntityId: Scalars['String']['output']
  id: Scalars['String']['output']
  key: Scalars['String']['output']
  scope?: Maybe<CustomerTasksWorkflowScope>
  tasks: Array<CustomerTasksTask>
  workflowTemplateId: Scalars['String']['output']
}

export type CustomerTasksWorkflowScope =
  | 'BUSINESS'
  | 'COMPANY'
  | 'LOCATION'
  | 'PERSON'
  | 'TENENT'
  | 'UNKNOWN'

export type CustomerTasksWorkflowTemplate = {
  __typename: 'CustomerTasksWorkflowTemplate'
  dateCreated: Scalars['String']['output']
  id: Scalars['String']['output']
  key: Scalars['String']['output']
  scope: CustomerTasksWorkflowScope
  tasks: Array<CustomerTasksTaskTemplate>
}

export type CustomerUserAuthorizationQuery = {
  __typename: 'CustomerUserAuthorizationQuery'
  /**
   *  Finds all users who have access to a given target within the restaurant set defined in the Toast-Restaurant-Set header The `legacyCustomerAuthorization` object may be provided until ABAC is rolled out to our customers Once full ABAC is rolled out to our customers the `legacyCustomerAuthorization` object will do nothing and become deprecated
   * @deprecated use customerUsersWithAccessPaged
   */
  customerUsersWithAccess?: Maybe<Array<RenderedAuthorizationDecision>>
  /**  Finds all users who have access to a given target within the restaurant set defined in the Toast-Restaurant-Set header The `legacyCustomerAuthorization` object may be provided until ABAC is rolled out to our customers Once full ABAC is rolled out to our customers the `legacyCustomerAuthorization` object will do nothing and become deprecated  */
  customerUsersWithAccessPaged: PagedRenderedAuthorizationDecision
}

export type CustomerUserAuthorizationQueryCustomerUsersWithAccessArgs = {
  legacyCustomerAuthorization?: InputMaybe<LegacyCustomerAuthorizationInput>
  target: AuthorizationTargetInput
}

export type CustomerUserAuthorizationQueryCustomerUsersWithAccessPagedArgs = {
  legacyCustomerAuthorization?: InputMaybe<LegacyCustomerAuthorizationInput>
  page?: InputMaybe<AuthorizationPageInput>
  target: AuthorizationTargetInput
}

export type DsModel = {
  __typename: 'DSModel'
  active: Scalars['Boolean']['output']
  currentPrepTime?: Maybe<Scalars['String']['output']>
}

/** Daily metrics for a restaurant location */
export type DailyMetrics = {
  __typename: 'DailyMetrics'
  /** Labor cost as a percentage of net sales, i.e., the total labor cost as a percentage of the total net sales */
  laborCostAsPercentageOfNetSales?: Maybe<PercentageMetric>
  /** Net sales */
  netSales: MoneyMetric
  /** Net sales per labor hour, i.e., the total net sales divided by the total labor hours worked */
  netSalesPerLaborHour?: Maybe<MoneyMetric>
  /** The restaurant for which the metrics are calculated */
  restaurant: SavedRestaurantLocation
}

export type DailyNetSalesMetric = {
  __typename: 'DailyNetSalesMetric'
  /** Business day for this value */
  businessDay: Scalars['Date']['output']
  /** Comparison business day for this value, if applicable */
  comparisonBusinessDay?: Maybe<Scalars['Date']['output']>
  /** The value of the net sales */
  netSales: MoneyMetric
}

export type DataExtension = {
  type: DataExtensionType
  value: Scalars['String']['output']
}

export type DataExtensionInput = {
  id: Scalars['ID']['input']
  value: Scalars['String']['input']
}

export type DataExtensionMutation = {
  __typename: 'DataExtensionMutation'
  createDataExtensionSet: SavedDataExtensionSet
  updateDataExtensionSet: SavedDataExtensionSet
}

export type DataExtensionMutationCreateDataExtensionSetArgs = {
  input: CreateDataExtensionSetInput
}

export type DataExtensionMutationUpdateDataExtensionSetArgs = {
  input: UpdateDataExtensionSetInput
}

export type DataExtensionQuery = {
  __typename: 'DataExtensionQuery'
  /** fallbacks to Toast-Restaurant-External-ID */
  publishedDataExtensionSet: PublishedDataExtensionSet
  /** fallbacks to Toast-Restaurant-External-ID */
  resolvedDataExtensionSet: ResolvedDataExtensionSet
  /** Fetch a single DataExtensionSet */
  savedDataExtensionSet: SavedDataExtensionSet
  /** Fetch list of all DataExtensionSets */
  savedDataExtensionSets: Array<SavedDataExtensionSet>
}

export type DataExtensionQueryPublishedDataExtensionSetArgs = {
  id: Scalars['ID']['input']
  restaurantLocationId?: InputMaybe<Scalars['ID']['input']>
}

export type DataExtensionQueryResolvedDataExtensionSetArgs = {
  id: Scalars['ID']['input']
  restaurantLocationId?: InputMaybe<Scalars['ID']['input']>
}

export type DataExtensionQuerySavedDataExtensionSetArgs = {
  id: Scalars['ID']['input']
}

export type DataExtensionSetOverride = SavedConfigOverrideI & {
  __typename: 'DataExtensionSetOverride'
  fields: Array<ConfigField>
  id: Scalars['ID']['output']
  payments?: Maybe<Array<PaymentDataExtension>>
  revenueCenters?: Maybe<Array<RevenueCenterDataExtension>>
  revision: Scalars['Int']['output']
  salesCategories?: Maybe<Array<SalesCategoryDataExtension>>
  services?: Maybe<Array<ServiceDataExtension>>
  target: RestaurantSet
  taxRates?: Maybe<Array<TaxRateDataExtension>>
}

export type DataExtensionSetOverrideInput = {
  fields?: InputMaybe<Array<Scalars['String']['input']>>
  payments?: InputMaybe<Array<PaymentDataExtensionInput>>
  revenueCenters?: InputMaybe<Array<DataExtensionInput>>
  revision: Scalars['Int']['input']
  salesCategories?: InputMaybe<Array<DataExtensionInput>>
  services?: InputMaybe<Array<DataExtensionInput>>
  target: Scalars['ID']['input']
  taxRates?: InputMaybe<Array<DataExtensionInput>>
}

export type DataExtensionSetType = 'LSPMS' | 'ONQ'

export type DataExtensionType = 'ITEMIZER' | 'PAYMENT_TYPE'

export type DateRange = {
  __typename: 'DateRange'
  endDate?: Maybe<Scalars['Date']['output']>
  startDate: Scalars['Date']['output']
}

export type DateRangeInput = {
  end: Scalars['Date']['input']
  start: Scalars['Date']['input']
}

export type DateRangeMetrics = {
  __typename: 'DateRangeMetrics'
  /** Net sales metrics for this date range */
  netSales?: Maybe<NetSalesDateRangeMetrics>
}

export type DayOfWeek =
  | 'FRIDAY'
  | 'MONDAY'
  | 'SATURDAY'
  | 'SUNDAY'
  | 'THURSDAY'
  | 'TUESDAY'
  | 'WEDNESDAY'

export type DayOperatingSchedule = {
  __typename: 'DayOperatingSchedule'
  closeTime?: Maybe<Scalars['LocalTime']['output']>
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  openTime?: Maybe<Scalars['LocalTime']['output']>
  servicePeriods?: Maybe<Array<Maybe<OperatingScheduleServicePeriod>>>
}

export type DayPeriod = {
  __typename: 'DayPeriod'
  /** @deprecated Use dateRange */
  day: Scalars['String']['output']
  timeRanges: Array<LocalTimeRange>
}

export type DayPeriodInput = {
  day: DayOfWeek
  timeRanges: Array<LocalTimeRangeInput>
}

/** Metric for decimal values */
export type DecimalMetric = {
  __typename: 'DecimalMetric'
  /** Comparison decimal value */
  comparison?: Maybe<Scalars['Decimal']['output']>
  /** Current decimal value */
  current: Scalars['Decimal']['output']
  /** End time for the metric; inclusive */
  endTime: Scalars['DateTime']['output']
  /** Start time for the metric; inclusive */
  startTime: Scalars['DateTime']['output']
  /** Variation in comparison */
  variation?: Maybe<Comparison>
}

export type DeclareCashTipsConfig = {
  __typename: 'DeclareCashTipsConfig'
  minimumTipPercentage: Scalars['Float']['output']
  shouldAllowNegativeDeclaredTips: Scalars['Boolean']['output']
  shouldRequireDeclareCashTips: Scalars['Boolean']['output']
}

export type DeclareCashTipsConfigInput = {
  minimumTipPercentage?: InputMaybe<Scalars['Float']['input']>
  shouldAllowNegativeDeclaredTips?: InputMaybe<Scalars['Boolean']['input']>
  shouldRequireDeclareCashTips?: InputMaybe<Scalars['Boolean']['input']>
}

/** A default rate type with a basis type and a monetary type. */
export type DefaultRate = {
  __typename: 'DefaultRate'
  basis: Basis
  money: Money
}

export type DeleteLogbookEntry = {
  entryID: Scalars['ID']['input']
  locationID: Scalars['ID']['input']
}

export type DeleteLogbookEntryInput = {
  entryID: Scalars['ID']['input']
  locationID: Scalars['ID']['input']
}

export type DeleteLogbookEntryReply = {
  entryID: Scalars['ID']['input']
  locationID: Scalars['ID']['input']
  replyID: Scalars['ID']['input']
}

export type DeleteLogbookEntryReplyInput = {
  entryID: Scalars['ID']['input']
  locationID: Scalars['ID']['input']
  replyID: Scalars['ID']['input']
}

export type DeleteUserQuickActionResponse = {
  __typename: 'DeleteUserQuickActionResponse'
  message?: Maybe<Scalars['String']['output']>
  success: Scalars['Boolean']['output']
}

export type DeletedEmploymentStatus = EmploymentStatus & {
  __typename: 'DeletedEmploymentStatus'
  adjustedHireDate?: Maybe<Scalars['Date']['output']>
  /** @deprecated use employee.hireDate at the top level instead */
  hireDate?: Maybe<Scalars['String']['output']>
}

export type DeletedShiftResult =
  | FinishedEmployeeShift
  | InProgressEmployeeShift
  | LoadShiftsFailure
  | LocateShiftFailure
  | SaveFailure
  | ShiftNotFound
  | Unauthorized

export type DeliveryToggleUiConfiguration =
  | 'DELIVERY_NOT_ENABLED'
  | 'DELIVERY_NOT_SHOWN'
  | 'FPD'
  | 'OO_NOT_ENABLED'
  | 'TDS'

export type DemoEmploymentStatus = EmploymentStatus & {
  __typename: 'DemoEmploymentStatus'
  adjustedHireDate?: Maybe<Scalars['Date']['output']>
  /** @deprecated use employee.hireDate at the top level instead */
  hireDate?: Maybe<Scalars['String']['output']>
}

export type DepositAmountChanged = Error & {
  __typename: 'DepositAmountChanged'
  message: Scalars['String']['output']
  status: ErrorCode
}

export type DepositAvailabilityInfo = {
  __typename: 'DepositAvailabilityInfo'
  autoCancelUnpaidDeposit: Scalars['Boolean']['output']
  autoCancelUnpaidDepositTimeframe: Scalars['Int']['output']
  cancellationRefundableTimeframe: Scalars['Int']['output']
  depositPolicy?: Maybe<Scalars['String']['output']>
  enabled: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  strategy: DepositStrategyAvailabilityInfo
}

export type DepositBookableConfigSnapshot = {
  __typename: 'DepositBookableConfigSnapshot'
  cancellationRefundableTimeframe: Scalars['Int']['output']
  depositPolicy?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  strategy: DepositBookableConfigSnapshotStrategy
}

export type DepositBookableConfigSnapshotStrategy = {
  __typename: 'DepositBookableConfigSnapshotStrategy'
  amount: Scalars['Float']['output']
  type: DepositType
}

export type DepositRequest = {
  depositAmount: Scalars['Float']['input']
  depositBookableConfigGuid: Scalars['ID']['input']
  depositWaived?: Scalars['Boolean']['input']
}

export type DepositRuleNotMatching = Error & {
  __typename: 'DepositRuleNotMatching'
  message: Scalars['String']['output']
  status: ErrorCode
}

export type DepositStatus =
  | 'NOT_PAID'
  | 'NOT_REFUNDED'
  | 'PAID'
  | 'PENDING_REFUND'
  | 'REFUNDED'
  | 'WAIVED'

export type DepositStrategy =
  | DepositStrategyByBooking
  | DepositStrategyByPartySize

export type DepositStrategyAvailabilityInfo =
  | DepositStrategyAvailabilityInfoByBooking
  | DepositStrategyAvailabilityInfoByPartySize

export type DepositStrategyAvailabilityInfoByBooking = {
  __typename: 'DepositStrategyAvailabilityInfoByBooking'
  actualAmount: Scalars['Float']['output']
  amountsByParty: Array<Scalars['Float']['output']>
  defaultAmount: Scalars['Float']['output']
}

export type DepositStrategyAvailabilityInfoByPartySize = {
  __typename: 'DepositStrategyAvailabilityInfoByPartySize'
  actualAmount: Scalars['Float']['output']
  amountPerGuest: Scalars['Float']['output']
  maxPartySize?: Maybe<Scalars['Int']['output']>
  minPartySize: Scalars['Int']['output']
}

export type DepositStrategyByBooking = {
  __typename: 'DepositStrategyByBooking'
  amountsByParty: Array<Scalars['Float']['output']>
  defaultAmount: Scalars['Float']['output']
}

export type DepositStrategyByPartySize = {
  __typename: 'DepositStrategyByPartySize'
  amountPerGuest: Scalars['Float']['output']
  maxPartySize?: Maybe<Scalars['Int']['output']>
  minPartySize: Scalars['Int']['output']
}

export type DepositType = 'ByBooking' | 'ByPartySize'

/** Shared fields for devices of all types */
export type Device = {
  deviceDetails?: Maybe<DeviceDetails>
  deviceType: DeviceType
  firmwareVersion?: Maybe<Scalars['String']['output']>
  lastUpdate?: Maybe<Scalars['DateTime']['output']>
  model?: Maybe<Scalars['String']['output']>
  modelName?: Maybe<Scalars['String']['output']>
  name: Scalars['String']['output']
  networks?: Maybe<Array<DeviceNetworkDetails>>
  photo?: Maybe<Scalars['String']['output']>
  restaurant: SavedRestaurantLocation
  serial: Scalars['ID']['output']
}

/** Consistent alert format for issues sourced from multiple services/platforms (including splunk) */
export type DeviceAlert = {
  __typename: 'DeviceAlert'
  message: Scalars['String']['output']
  reportingDeviceSerial?: Maybe<Scalars['String']['output']>
  time: Scalars['DateTime']['output']
  type: DeviceAlertType
}

/** The possible severity levels of a device alert */
export type DeviceAlertType = 'ERROR' | 'INFO' | 'WARNING'

export type DeviceConnectionType = 'ETHERNET' | 'MOBILE' | 'NONE' | 'WIFI'

/** Device and networking details for a change event in a device's connectivity status */
export type DeviceConnectivityEvent = {
  __typename: 'DeviceConnectivityEvent'
  data?: Maybe<DeviceConnectivityEventData>
  deviceId: Scalars['String']['output']
  deviceTime: Scalars['DateTime']['output']
  serial: Scalars['String']['output']
  time: Scalars['DateTime']['output']
}

/** Details of the network associated with a specific connectivity event */
export type DeviceConnectivityEventData = {
  __typename: 'DeviceConnectivityEventData'
  ipAddress?: Maybe<Scalars['String']['output']>
  lastConnectedType?: Maybe<DeviceConnectionType>
  macAddress?: Maybe<Scalars['String']['output']>
  networkType?: Maybe<DeviceConnectionType>
  /** @deprecated use enum-derived status field */
  newStatus?: Maybe<Scalars['String']['output']>
  ssid?: Maybe<Scalars['String']['output']>
  status?: Maybe<DeviceConnectivityEventStatus>
}

/** The possibles statuses of a devices connectivity Event */
export type DeviceConnectivityEventStatus =
  | 'LOCAL_NETWORK'
  | 'OFFLINE'
  | 'ONLINE'

/**  Includes a list of current alerts for an android device. Additional service layer fields not currently included  */
export type DeviceDetails = {
  __typename: 'DeviceDetails'
  alerts: Array<DeviceAlert>
}

/**  A local type for a printer's associated prep station(s) as defined by toastweb config. This should be migrated to a federated PrepStation type in future  */
export type DeviceInfoPublishedPrepStation = {
  __typename: 'DeviceInfoPublishedPrepStation'
  alwaysPrint: Scalars['Boolean']['output']
  expediter: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  multiLocationId: Scalars['String']['output']
  name?: Maybe<Scalars['String']['output']>
}

/** args for querying the details of a single device */
export type DeviceInput = {
  deviceType?: InputMaybe<DeviceType>
  restaurantId?: InputMaybe<Scalars['String']['input']>
  serial: Scalars['String']['input']
}

/** Whether a network supports dhcp. Null values return NONE */
export type DeviceIpType = 'DHCP' | 'STATIC' | 'UNKNOWN'

/** The time and details of a device's most recent status report */
export type DeviceLastSeenInfo = {
  __typename: 'DeviceLastSeenInfo'
  heartbeatStatus: DeviceOnlineStatus
  lastSeenTime?: Maybe<Scalars['DateTime']['output']>
  offlineBarStatus?: Maybe<Scalars['String']['output']>
  serial?: Maybe<Scalars['String']['output']>
}

/** Network information relevant to all device types */
export type DeviceNetworkDetails = {
  __typename: 'DeviceNetworkDetails'
  /** @deprecated Use ipType field */
  dhcp: Scalars['Boolean']['output']
  gatewayIp?: Maybe<Scalars['String']['output']>
  iccid?: Maybe<Scalars['String']['output']>
  imei?: Maybe<Scalars['String']['output']>
  /** @deprecated Use enum-driven networkInterface field instead */
  interfaceType?: Maybe<Scalars['String']['output']>
  ipAddress?: Maybe<Scalars['String']['output']>
  ipType: DeviceIpType
  isDefaultRoute: Scalars['Boolean']['output']
  mac?: Maybe<Scalars['String']['output']>
  netmaskIp?: Maybe<Scalars['String']['output']>
  networkInterface?: Maybe<DeviceNetworkInterface>
  port?: Maybe<Scalars['String']['output']>
  ssid?: Maybe<Scalars['String']['output']>
  /** returns an empty string for a null db value */
  wifiBand?: Maybe<Scalars['String']['output']>
}

export type DeviceNetworkInterface =
  | 'ETHERNET'
  | 'MOBILE'
  | 'RJ45'
  | 'USBC'
  | 'WIFI'

/** The possible connection status of a device */
export type DeviceOnlineStatus =
  | 'INACTIVE'
  | 'NOT_AVAILABLE'
  | 'OFFLINE'
  | 'ONLINE'

/** Name-spaced queries used in Device Hub */
export type DeviceQueries = {
  __typename: 'DeviceQueries'
  connectivityEvents?: Maybe<Array<DeviceConnectivityEvent>>
  device?: Maybe<Device>
  devices?: Maybe<Array<Device>>
  lastSeenInfo?: Maybe<Array<DeviceLastSeenInfo>>
  printerIssues?: Maybe<Array<DeviceAlert>>
  publishedPrinter?: Maybe<Printer>
}

/** Name-spaced queries used in Device Hub */
export type DeviceQueriesConnectivityEventsArgs = {
  restaurantId?: InputMaybe<Scalars['String']['input']>
  serial: Scalars['String']['input']
}

/** Name-spaced queries used in Device Hub */
export type DeviceQueriesDeviceArgs = {
  input: DeviceInput
}

/** Name-spaced queries used in Device Hub */
export type DeviceQueriesDevicesArgs = {
  input?: InputMaybe<DevicesInput>
}

/** Name-spaced queries used in Device Hub */
export type DeviceQueriesLastSeenInfoArgs = {
  restaurantIds?: InputMaybe<Array<Scalars['String']['input']>>
}

/** Name-spaced queries used in Device Hub */
export type DeviceQueriesPrinterIssuesArgs = {
  restaurantId?: InputMaybe<Scalars['String']['input']>
  serial: Scalars['String']['input']
}

/** Name-spaced queries used in Device Hub */
export type DeviceQueriesPublishedPrinterArgs = {
  restaurantId?: InputMaybe<Scalars['String']['input']>
  serial: Scalars['String']['input']
}

export type DeviceSyncDetails = {
  __typename: 'DeviceSyncDetails'
  isRunningHubServices: Scalars['Boolean']['output']
}

/** The supported device types in Device Hub */
export type DeviceType = 'HANDHELD' | 'PRINTER' | 'TERMINAL'

/** args for querying a full device list for a location */
export type DevicesInput = {
  deviceType?: InputMaybe<DeviceType>
  filterToastDevices?: Scalars['Boolean']['input']
  maxSize?: InputMaybe<Scalars['Int']['input']>
  paginationToken?: InputMaybe<Scalars['String']['input']>
  /**  Will take precedence over current location guid from header context if included. Currently only accepts an array with a maximum of one entry  */
  restaurantIds?: InputMaybe<Array<Scalars['String']['input']>>
}

export type DiningOption = {
  __typename: 'DiningOption'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type DiningOptionBehavior = 'DELIVERY' | 'TAKE_OUT'

export type DiningOptionMode = 'BOTH' | 'DINE_IN' | 'TAKE_OUT'

/** Total discount amount, i.e., the total amount of money discounted */
export type DiscountAmount = {
  __typename: 'DiscountAmount'
  /** Total discount amount, grouped by specified dimension */
  grouped?: Maybe<Array<GroupedDiscountAmount>>
  /** Total discount amount */
  totals: MoneyMetric
}

/** Total discount amount, i.e., the total amount of money discounted */
export type DiscountAmountGroupedArgs = {
  by: DiscountsGroupingDimension
}

/** Total discount count, i.e., the number of discounts applied */
export type DiscountCount = {
  __typename: 'DiscountCount'
  /** Total discount count, grouped by specified dimension */
  grouped?: Maybe<Array<GroupedDiscountCount>>
  /** Total discount count */
  totals: IntegerMetric
}

/** Total discount count, i.e., the number of discounts applied */
export type DiscountCountGroupedArgs = {
  by: DiscountsGroupingDimension
}

/** A filter for Discount metrics */
export type DiscountFilter = {
  /** The dimension to filter by */
  dimension: DiscountsGroupingDimension
  /** The resolved DISCOUNT, EMPLOYEE, or APPROVER name to filter by */
  groupKey: Scalars['String']['input']
}

export type DiscountGroupEntity = Check | RestaurantUser

/** The grouping dimensions available for discounts metrics */
export type DiscountsGroupingDimension =
  | 'APPROVER'
  | 'CHECK'
  | 'DISCOUNT'
  | 'EMPLOYEE'

export type DisplayTheme = 'DARK' | 'LIGHT'

export type DoorDashMockOnboardingConfiguration = {
  __typename: 'DoorDashMockOnboardingConfiguration'
  doordashStoreGuid?: Maybe<Scalars['ID']['output']>
  exchangeCode: Scalars['String']['output']
  hasBegunOnboarding?: Maybe<Scalars['Boolean']['output']>
  id: Scalars['ID']['output']
  integrationIsActivated?: Maybe<Scalars['Boolean']['output']>
  menus?: Maybe<Array<DoorDashOnboardingMenu>>
  netNewSignupUrl?: Maybe<Scalars['String']['output']>
  onboardingId: Scalars['String']['output']
  onboardingStatus?: Maybe<Scalars['String']['output']>
  onboardingStatusExclusionCode?: Maybe<Scalars['String']['output']>
  onboardingStatusExclusionCodeOverride?: Maybe<Scalars['String']['output']>
  onboardingStatusOverride?: Maybe<Scalars['String']['output']>
  restaurant: SavedRestaurantLocation
  storeCandidates?: Maybe<Array<DoorDashStoreCandidate>>
  throwActivateIntegrationError?: Maybe<Scalars['Boolean']['output']>
  throwAuthError?: Maybe<Scalars['Boolean']['output']>
  throwHttpError?: Maybe<Scalars['Int']['output']>
  throwStoreInProcessOfOnboardingError?: Maybe<Scalars['Boolean']['output']>
}

export type DoorDashMockOnboardingConfigurationInput = {
  doordashStoreGuid?: InputMaybe<Scalars['ID']['input']>
  exchangeCode?: InputMaybe<Scalars['String']['input']>
  hasBegunOnboarding?: InputMaybe<Scalars['Boolean']['input']>
  integrationIsActivated?: InputMaybe<Scalars['Boolean']['input']>
  menus?: InputMaybe<Array<DoorDashOnboardingMenuInput>>
  netNewSignupUrl?: InputMaybe<Scalars['String']['input']>
  onboardingId?: InputMaybe<Scalars['String']['input']>
  onboardingStatus?: InputMaybe<Scalars['String']['input']>
  onboardingStatusExclusionCode?: InputMaybe<Scalars['String']['input']>
  onboardingStatusExclusionCodeOverride?: InputMaybe<Scalars['String']['input']>
  onboardingStatusOverride?: InputMaybe<Scalars['String']['input']>
  restaurantGuid?: InputMaybe<Scalars['ID']['input']>
  storeCandidates?: InputMaybe<Array<DoorDashStoreCandidateInput>>
  throwActivateIntegrationError?: InputMaybe<Scalars['Boolean']['input']>
  throwAuthError?: InputMaybe<Scalars['Boolean']['input']>
  throwHttpError?: InputMaybe<Scalars['Int']['input']>
  throwStoreInProcessOfOnboardingError?: InputMaybe<Scalars['Boolean']['input']>
}

export type DoorDashNetNewOnboardingRequest = {
  address?: InputMaybe<DoorDashOnboardingAddress>
  business_phone: Scalars['String']['input']
  email: Scalars['String']['input']
  first_name: Scalars['String']['input']
  last_name: Scalars['String']['input']
  locale: Scalars['String']['input']
  location_id: Scalars['String']['input']
  store_name: Scalars['String']['input']
}

export type DoorDashNetNewOnboardingResponse = {
  __typename: 'DoorDashNetNewOnboardingResponse'
  signupUrl: Scalars['String']['output']
}

export type DoorDashOnboardingActivateIntegrationResponse = {
  __typename: 'DoorDashOnboardingActivateIntegrationResponse'
  body?: Maybe<Scalars['String']['output']>
  isSuccessful: Scalars['Boolean']['output']
}

export type DoorDashOnboardingAddress = {
  city: Scalars['String']['input']
  country: Scalars['String']['input']
  state: Scalars['String']['input']
  street_address: Scalars['String']['input']
  zip: Scalars['String']['input']
}

export type DoorDashOnboardingIdResponse = {
  __typename: 'DoorDashOnboardingIdResponse'
  message?: Maybe<Scalars['String']['output']>
  onboardingId?: Maybe<Scalars['ID']['output']>
}

export type DoorDashOnboardingMenu = {
  __typename: 'DoorDashOnboardingMenu'
  menuError?: Maybe<Scalars['String']['output']>
  menuId: Scalars['String']['output']
  menuPreviewLink?: Maybe<Scalars['String']['output']>
}

export type DoorDashOnboardingMenuCandidate = {
  __typename: 'DoorDashOnboardingMenuCandidate'
  menuError?: Maybe<Scalars['String']['output']>
  menuPreviewLink: Scalars['String']['output']
  menuUuid: Scalars['String']['output']
}

export type DoorDashOnboardingMenuInput = {
  menuError?: InputMaybe<Scalars['String']['input']>
  menuId: Scalars['String']['input']
  menuPreviewLink?: InputMaybe<Scalars['String']['input']>
}

export type DoorDashOnboardingStatusResponse = {
  __typename: 'DoorDashOnboardingStatusResponse'
  details?: Maybe<Scalars['String']['output']>
  exclusionCode?: Maybe<Scalars['String']['output']>
  locationGuid: Scalars['ID']['output']
  locationName?: Maybe<Scalars['String']['output']>
  menus: Array<DoorDashOnboardingMenuCandidate>
  onboardingId: Scalars['ID']['output']
  onboardingStatus: Scalars['String']['output']
  providerRestaurantGuid: Scalars['ID']['output']
}

export type DoorDashOnboardingStoreCandidate = {
  __typename: 'DoorDashOnboardingStoreCandidate'
  address: Scalars['String']['output']
  isActivated?: Maybe<Scalars['Boolean']['output']>
  locationGuid: Scalars['ID']['output']
  locationName: Scalars['String']['output']
  onboardingEligible: Scalars['Boolean']['output']
  providerRestaurantGuid: Scalars['ID']['output']
}

export type DoorDashOnboardingStores = {
  __typename: 'DoorDashOnboardingStores'
  storeCandidates: Array<DoorDashOnboardingStoreCandidate>
}

export type DoorDashStoreCandidate = {
  __typename: 'DoorDashStoreCandidate'
  address: Scalars['String']['output']
  businessUUID: Scalars['ID']['output']
  doordashStoreUUID: Scalars['ID']['output']
  isActiveOnIntegration?: Maybe<Scalars['Boolean']['output']>
  isEligibleToOnboard: Scalars['Boolean']['output']
  locationName: Scalars['String']['output']
}

export type DoorDashStoreCandidateInput = {
  address: Scalars['String']['input']
  businessUUID: Scalars['ID']['input']
  doordashStoreUUID: Scalars['ID']['input']
  isActiveOnIntegration?: InputMaybe<Scalars['Boolean']['input']>
  isEligibleToOnboard: Scalars['Boolean']['input']
  locationName: Scalars['String']['input']
}

export type DriveThruMutation = {
  __typename: 'DriveThruMutation'
  deleteOrderConfirmationScreenConfigOverrides: SavedOrderConfirmationScreenConfig
  deleteStockDataConfigOverrides: SavedStockDataConfig
  deleteStockOutConfigOverrides: SavedStockOutConfig
  saveOrderConfirmationScreenConfigBase: QlOrderConfirmationScreenConfig
  saveOrderConfirmationScreenConfigOverrides: SavedOrderConfirmationScreenConfig
  saveStockDataConfigBase: QlStockDataConfig
  saveStockDataConfigOverrides: SavedStockDataConfig
  saveStockOutConfigBase: QlStockOutConfig
  saveStockOutConfigOverrides: SavedStockOutConfig
}

export type DriveThruMutationDeleteOrderConfirmationScreenConfigOverridesArgs =
  {
    targets: Array<RestaurantSetInput>
  }

export type DriveThruMutationDeleteStockDataConfigOverridesArgs = {
  targets: Array<RestaurantSetInput>
}

export type DriveThruMutationDeleteStockOutConfigOverridesArgs = {
  targets: Array<RestaurantSetInput>
}

export type DriveThruMutationSaveOrderConfirmationScreenConfigBaseArgs = {
  input: OrderConfirmationScreenConfigInput
}

export type DriveThruMutationSaveOrderConfirmationScreenConfigOverridesArgs = {
  input: Array<OrderConfirmationScreenConfigOverrideInput>
}

export type DriveThruMutationSaveStockDataConfigBaseArgs = {
  input: StockDataConfigInput
}

export type DriveThruMutationSaveStockDataConfigOverridesArgs = {
  input: Array<StockDataConfigOverrideInput>
}

export type DriveThruMutationSaveStockOutConfigBaseArgs = {
  input: StockOutConfigInput
}

export type DriveThruMutationSaveStockOutConfigOverridesArgs = {
  input: Array<StockOutConfigOverrideInput>
}

export type DriveThruQuery = {
  __typename: 'DriveThruQuery'
  resolvedOrderConfirmationScreenConfig: QlOrderConfirmationScreenConfig
  resolvedStockDataConfig: QlStockDataConfig
  resolvedStockOutConfig: QlStockOutConfig
  savedOrderConfirmationScreenConfig: SavedOrderConfirmationScreenConfig
  savedStockDataConfig: SavedStockDataConfig
  savedStockOutConfig: SavedStockOutConfig
}

export type DsModelHealthStatus = {
  __typename: 'DsModelHealthStatus'
  isHealthy?: Maybe<Scalars['Boolean']['output']>
}

export type DurationBreakdown = {
  __typename: 'DurationBreakdown'
  /** Overtime for a shift in seconds */
  overtimeDuration?: Maybe<Scalars['Duration']['output']>
  /** Sum of durations of paid breaks for a shift in seconds */
  paidBreakTime?: Maybe<Scalars['Duration']['output']>
  /** Total shift time minus unpaid break time in seconds */
  payableTime?: Maybe<Scalars['Duration']['output']>
  /** Duration between start and end of a shift in seconds excluding overtime */
  regularDuration?: Maybe<Scalars['Duration']['output']>
  /** Total duration of the shift in seconds */
  totalTime?: Maybe<Scalars['Duration']['output']>
  /** Sum of durations of unpaid breaks for a shift in seconds */
  unpaidBreakTime?: Maybe<Scalars['Duration']['output']>
}

/** EasyPay invoice */
export type EpCustomerBillingInvoice = CustomerBillingInvoice & {
  __typename: 'EPCustomerBillingInvoice'
  billingAddress?: Maybe<Scalars['String']['output']>
  createdDate: Scalars['Date']['output']
  deductions: EpCustomerBillingInvoiceDeductions
  dueDate: Scalars['Date']['output']
  id: Scalars['ID']['output']
  items: Array<EpCustomerBillingInvoiceItem>
  netsuiteId: Scalars['ID']['output']
  orderType?: Maybe<Scalars['String']['output']>
  restaurant?: Maybe<Restaurant>
  salesforceId: Scalars['ID']['output']
  shippingAddress?: Maybe<Scalars['String']['output']>
  status: CustomerBillingInvoiceStatus
  terms?: Maybe<Scalars['String']['output']>
  totals: CustomerBillingInvoiceTotals
  transactionDate: Scalars['Date']['output']
  type: CustomerBillingInvoiceType
}

/** EasyPay invoice deductions */
export type EpCustomerBillingInvoiceDeductions = {
  __typename: 'EPCustomerBillingInvoiceDeductions'
  /**  Total - security deposit / 180 days. Only gets updated after Security deposit is set  */
  dailyRepaymentRate?: Maybe<Money>
  securityDeposit: Money
}

/** EasyPay invoice item */
export type EpCustomerBillingInvoiceItem = CustomerBillingInvoiceItem & {
  __typename: 'EPCustomerBillingInvoiceItem'
  amount: Money
  children: Array<EpCustomerBillingInvoiceItem>
  endDate: Scalars['Date']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  parentProduct?: Maybe<Scalars['String']['output']>
  quantity: Scalars['Int']['output']
  rate: Money
  startDate: Scalars['Date']['output']
  /**  Taxes amount for purchased items (amount * rate) */
  taxAmount: Money
  /**  Taxes amount for a single item  */
  taxRate: Money
}

export type EditGroupsAndLocationsInput = {
  groups: Array<Scalars['ID']['input']>
  guid: Scalars['ID']['input']
  locations: Array<Scalars['ID']['input']>
}

export type EditRestaurantGroupInfoInput = {
  description?: InputMaybe<Scalars['String']['input']>
  guid: Scalars['ID']['input']
  name?: InputMaybe<Scalars['String']['input']>
}

export type EmailMarketingBlastCampaign = EmailMarketingCampaign & {
  __typename: 'EmailMarketingBlastCampaign'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  recommendationName?: Maybe<Scalars['String']['output']>
  sendDate?: Maybe<Scalars['DateTime']['output']>
  status: EmailMarketingCampaignStatus
  theme?: Maybe<Scalars['String']['output']>
}

export type EmailMarketingCampaign = {
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  status: EmailMarketingCampaignStatus
}

export type EmailMarketingCampaignFilterInput = {
  campaignStatus?: InputMaybe<Array<InputMaybe<EmailMarketingCampaignStatus>>>
  endDate?: InputMaybe<Scalars['DateTime']['input']>
  startDate?: InputMaybe<Scalars['DateTime']['input']>
}

export type EmailMarketingCampaignPaginationInput = {
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
}

export type EmailMarketingCampaignStatus =
  | 'ACTIVE'
  | 'ARCHIVED'
  | 'AX_CANCELLED'
  | 'AX_SELECTED'
  | 'AX_WAITING'
  | 'COMPLETED'
  | 'DELETED'
  | 'DRAFT'
  | 'SCHEDULED'
  | 'SENT'
  | 'UNDEFINED'

/**  Employee will eventually be replaced by EmployeeV2  */
export type Employee = {
  __typename: 'Employee'
  chosenName?: Maybe<Scalars['String']['output']>
  email?: Maybe<Scalars['String']['output']>
  firstName: Scalars['String']['output']
  /**  The hire date of when the employee was hired in Toast Payroll. This will be null if the employee is not linked to a payroll customer.  */
  hireDate?: Maybe<Scalars['Date']['output']>
  lastName: Scalars['String']['output']
  payroll?: Maybe<PayrollEmployeeInfo>
  phoneNumber?: Maybe<Scalars['String']['output']>
  pos: Array<PosEmployeeInfo>
  user?: Maybe<User>
}

export type EmployeeAddress = {
  __typename: 'EmployeeAddress'
  addressLines: Array<Scalars['String']['output']>
  administrativeArea: Scalars['String']['output']
  locality: Scalars['String']['output']
  postalCode: Scalars['String']['output']
  regionCode: EmployeeCountry
}

export type EmployeeBreakConfirmationType =
  /** The employee confirmed that the manager asked to take the break */
  | 'MANAGER_DID_ASK'
  /** The employee confirmed that the manager did not ask to take the break */
  | 'MANAGER_DID_NOT_ASK'
  /** There was no need for a confirmation */
  | 'NOT_APPLICABLE'
  /** The confirmation dialog was never presented to the employee */
  | 'NOT_PRESENTED'

export type EmployeeConnection = {
  __typename: 'EmployeeConnection'
  edges: Array<EmployeeEdge>
  paginationInfo: EmployeePageInfo
}

export type EmployeeCountry = {
  __typename: 'EmployeeCountry'
  alpha2: Scalars['String']['output']
  alpha3: Scalars['String']['output']
  enCountryName: Scalars['String']['output']
}

export type EmployeeCursorPaginationInput = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
}

export type EmployeeDeleted = ShiftError & {
  __typename: 'EmployeeDeleted'
  message?: Maybe<Scalars['String']['output']>
}

export type EmployeeEdge = {
  __typename: 'EmployeeEdge'
  cursor: Scalars['String']['output']
  node: EmployeeV2
}

export type EmployeeEmail = {
  __typename: 'EmployeeEmail'
  address: Scalars['String']['output']
  isVerified: Scalars['Boolean']['output']
}

export type EmployeeEmployment = {
  __typename: 'EmployeeEmployment'
  currentEmployment: EmployeeEmploymentPeriod
  employmentHistory: Array<EmployeeHistoricalEmploymentPeriod>
  employmentType: EmployeeEmploymentType
  overtime: EmployeeOvertimeEligibility
  pendingEmployment?: Maybe<EmployeeEmploymentPeriod>
  totalLengthOfEmployment: Scalars['String']['output']
  workType: EmployeeWorkType
}

export type EmployeeEmploymentPeriod = EmployeeEmploymentPeriodInterface & {
  __typename: 'EmployeeEmploymentPeriod'
  employeeNumber: Scalars['String']['output']
  startDate: Scalars['Date']['output']
  status?: Maybe<EmployeeEmploymentStatus>
}

export type EmployeeEmploymentPeriodInterface = {
  employeeNumber: Scalars['String']['output']
  startDate: Scalars['Date']['output']
  status?: Maybe<EmployeeEmploymentStatus>
}

export type EmployeeEmploymentStatus =
  | 'ACTIVE'
  | 'LEAVE_OF_ABSENCE'
  | 'PENDING'
  | 'TERMINATED'

export type EmployeeEmploymentType = 'CONTRACTOR_1099' | 'EMPLOYEE_W2'

export type EmployeeHistoricalEmploymentPeriod =
  EmployeeEmploymentPeriodInterface & {
    __typename: 'EmployeeHistoricalEmploymentPeriod'
    employeeNumber: Scalars['String']['output']
    endDate: Scalars['Date']['output']
    startDate: Scalars['Date']['output']
    status?: Maybe<EmployeeEmploymentStatus>
  }

export type EmployeeJobAssignment = {
  __typename: 'EmployeeJobAssignment'
  jobAssignmentId: Scalars['ID']['output']
  jobId: Scalars['ID']['output']
  pay?: Maybe<EmployeePay>
}

export type EmployeeLocationAssignments = {
  __typename: 'EmployeeLocationAssignments'
  isEmployeeArchived?: Maybe<Scalars['Boolean']['output']>
  isPrimaryLocation?: Maybe<Scalars['Boolean']['output']>
  jobAssignments?: Maybe<Array<EmployeeJobAssignment>>
  locationId?: Maybe<SavedRestaurantLocation>
  posAccessCode?: Maybe<Scalars['String']['output']>
}

/** Represents a break that should have been taken, but did not occur */
export type EmployeeMissedBreak = {
  __typename: 'EmployeeMissedBreak'
  /** Represents the configuration used for this break */
  config: BreakConfig
  /** The type of confirmation for this break */
  confirmationType: EmployeeBreakConfirmationType
  /** The expected start time for this break */
  expectedStartTime: Scalars['DateTime']['output']
  /** The identifier provided by Toast */
  id: Scalars['ID']['output']
  /** Represents if this break will be paid out to the employee. This is copied from the config */
  isPaid: Scalars['Boolean']['output']
}

export type EmployeeName = {
  __typename: 'EmployeeName'
  firstName: Scalars['String']['output']
  lastName?: Maybe<Scalars['String']['output']>
  middleName?: Maybe<Scalars['String']['output']>
}

export type EmployeeOffsetPaginationInput = {
  offset: Scalars['Int']['input']
}

export type EmployeeOrderByDirection = 'ASC' | 'DESC'

export type EmployeeOrderByField =
  | 'CHOSEN_NAME'
  | 'FIRST_NAME'
  | 'ID'
  | 'LAST_NAME'

export type EmployeeOrderByInput = {
  direction: EmployeeOrderByDirection
  field: EmployeeOrderByField
}

export type EmployeeOrderTips = {
  __typename: 'EmployeeOrderTips'
  cashGratuity?: Maybe<Money>
  creditCardGratuity?: Maybe<Money>
  creditCardTips?: Maybe<Money>
  order: Order
  otherNonCashGratuity?: Maybe<Money>
  otherNonCashTips?: Maybe<Money>
  tipRefund?: Maybe<Money>
}

export type EmployeeOvertimeEligibility = 'EXEMPT' | 'NONEXEMPT'

export type EmployeePageInfo = {
  __typename: 'EmployeePageInfo'
  endCursor?: Maybe<Scalars['String']['output']>
  first: Scalars['Int']['output']
  hasNextPage: Scalars['Boolean']['output']
  hasPreviousPage: Scalars['Boolean']['output']
  offset?: Maybe<Scalars['Int']['output']>
  startCursor?: Maybe<Scalars['String']['output']>
}

export type EmployeePaginationInput = {
  cursorPagination?: InputMaybe<EmployeeCursorPaginationInput>
  first: Scalars['Int']['input']
  offsetPagination?: InputMaybe<EmployeeOffsetPaginationInput>
}

export type EmployeePay = {
  __typename: 'EmployeePay'
  basis?: Maybe<EmployeePayBasis>
  rate?: Maybe<Money>
}

export type EmployeePayBasis = 'HOURLY'

export type EmployeePhoneNumber = {
  __typename: 'EmployeePhoneNumber'
  countryCode: Scalars['String']['output']
  nationalNumber: Scalars['String']['output']
}

export type EmployeeRepositoryDependencyFailure = ShiftError & {
  __typename: 'EmployeeRepositoryDependencyFailure'
  message?: Maybe<Scalars['String']['output']>
}

export type EmployeeSchedule = {
  __typename: 'EmployeeSchedule'
  clockInWithinGracePeriod?: Maybe<Scalars['Boolean']['output']>
  id: Scalars['ID']['output']
  inDate?: Maybe<Scalars['DateTime']['output']>
  outDate?: Maybe<Scalars['DateTime']['output']>
}

export type EmployeeScheduleV2 = {
  __typename: 'EmployeeScheduleV2'
  id: Scalars['ID']['output']
  inDate?: Maybe<Scalars['DateTime']['output']>
  outDate?: Maybe<Scalars['DateTime']['output']>
}

export type EmployeeSearchInput = {
  byArchiveStatus?: InputMaybe<ByArchiveStatusSearchInput>
  byEmail?: InputMaybe<ByEmailSearchInput>
  byEmployeeId?: InputMaybe<ByEmployeeIdInputSearchInput>
  byJobId?: InputMaybe<ByJobIdInput>
  byName?: InputMaybe<ByNameSearchInput>
  byPhoneNumber?: InputMaybe<ByPhoneNumberSearchInput>
}

/** Abstract representation of a shift */
export type EmployeeShift = {
  /** The business date that this shift occurs in. */
  businessDate: Scalars['Date']['output']
  /** Represents the restaurant user for this shift */
  employee: RestaurantUser
  /** The identifier provided by Toast */
  id: Scalars['ID']['output']
  /** Represents whether this shift has been marked as deleted */
  isDeleted: Scalars['Boolean']['output']
  /** Represents the job for this shift. If the employee does not have any assigned jobs, this will be null. */
  job?: Maybe<Job>
  /** Represents the restaurant for this shift */
  restaurant: Restaurant
  /** Optional, represents the scheduled shift details */
  schedule?: Maybe<EmployeeSchedule>
  /** Represents the status of the shift */
  status?: Maybe<EmployeeShiftStatus>
  /** Represents the time clock information for this shift. If the employee did not clock in for this shift, this will be null. */
  timeClock?: Maybe<EmployeeTime>
  /** Represents the tips that were earned during this shift. If the employee did not receive any tips, this will be null */
  tips?: Maybe<EmployeeTips>
}

export type EmployeeShiftConnection = {
  __typename: 'EmployeeShiftConnection'
  /**  Note that hasPreviousPage will always be false and startCursor will always be null  */
  pageInfo: PageInfo
  shifts: Array<EmployeeShift>
}

export type EmployeeShiftConnectionV2 = {
  __typename: 'EmployeeShiftConnectionV2'
  edges: Array<EmployeeShiftConnectionV2Edge>
  pageInfo: ShiftsV2PageInfo
  totalItemCount: Scalars['Int']['output']
}

export type EmployeeShiftConnectionV2Edge = {
  __typename: 'EmployeeShiftConnectionV2Edge'
  cursor: Scalars['String']['output']
  node: EmployeeShiftV2
}

export type EmployeeShiftStatus =
  /** The shift is finalized and can no longer be edited */
  | 'FINALIZED'
  /** The shift was finished by a system, like auto-clockout service */
  | 'FINISHED_BY_SYSTEM'
  /** The shift was finished by a user, like the employee themselves, their manager... */
  | 'FINISHED_BY_USER'
  /** The shift is in actively working this shift */
  | 'IN_PROGRESS'
  /** The shift was scheduled, but the employee hasn't started it yet */
  | 'SCHEDULED'

export type EmployeeShiftTip = {
  __typename: 'EmployeeShiftTip'
  cashAmount: Money
}

/** Abstract representation of a shift */
export type EmployeeShiftV2 = {
  /** The business date that this shift occurs in. */
  businessDate: Scalars['Date']['output']
  /** Represents the restaurant user for this shift */
  employee: RestaurantUser
  /** The identifier provided by Toast */
  id: Scalars['ID']['output']
  /** Represents whether this shift has been marked as deleted */
  isDeleted: Scalars['Boolean']['output']
  /** Represents the job for this shift. If the employee does not have any assigned jobs, this will be null. */
  job?: Maybe<Job>
  /** Represents the restaurant for this shift */
  restaurant: Restaurant
  /** Optional, represents the scheduled shift details */
  schedule?: Maybe<EmployeeScheduleV2>
  /** Represents the status of the shift */
  status?: Maybe<EmployeeShiftStatus>
  /** Represents the time clock information for this shift. If the employee did not clock in for this shift, this will be null. */
  timeClock?: Maybe<EmployeeTime>
  /** Represents the tips that were earned during this shift. If the employee did not receive any tips, this will be null */
  tips?: Maybe<EmployeeTipsV2>
}

/** Represents a break that was actually taken during the shift */
export type EmployeeTakenBreak = {
  /** Represents the configuration used for this break */
  config: BreakConfig
  /** The identifier provided by Toast */
  id: Scalars['ID']['output']
  /** Represents if this break will be paid out to the employee. This is copied from the config */
  isPaid: Scalars['Boolean']['output']
  /** The time of the start of this break */
  startTime: Scalars['DateTime']['output']
}

export type EmployeeTime = {
  inTime: Scalars['DateTime']['output']
  missedBreaks: Array<EmployeeMissedBreak>
  takenBreaks: Array<EmployeeTakenBreak>
}

export type EmployeeTips = {
  __typename: 'EmployeeTips'
  /** Total gratuity for a shift that was cash */
  cashGratuity?: Maybe<Money>
  /** Total gratuity for a shift that was not cash */
  nonCashGratuity?: Maybe<Money>
  /** Total tips for a shift that were not cash */
  nonCashTips?: Maybe<Money>
  orderTips: Array<EmployeeOrderTips>
  shiftTip?: Maybe<EmployeeShiftTip>
  /** Amount of credit card tips withheld */
  tipsWithHeld?: Maybe<Money>
  /** Total gratuity for a shift */
  totalGratuity?: Maybe<Money>
  /** Total tips for a shift */
  totalTips?: Maybe<Money>
}

export type EmployeeTipsV2 = {
  __typename: 'EmployeeTipsV2'
  /** Total gratuity for a shift that was cash */
  cashGratuity?: Maybe<Money>
  /** Total gratuity for a shift that was not cash */
  nonCashGratuity?: Maybe<Money>
  /** Total tips for a shift that were not cash */
  nonCashTips?: Maybe<Money>
  shiftTip?: Maybe<EmployeeShiftTip>
  /** Amount of credit card tips withheld */
  tipsWithHeld?: Maybe<Money>
  /** Total gratuity for a shift */
  totalGratuity?: Maybe<Money>
  /** Total tips for a shift */
  totalTips?: Maybe<Money>
}

export type EmployeeV2 = {
  __typename: 'EmployeeV2'
  email: EmployeeEmail
  id: Scalars['ID']['output']
  locationAssignments?: Maybe<Array<EmployeeLocationAssignments>>
  name: EmployeeName
  primaryPhoneNumber?: Maybe<EmployeePhoneNumber>
  /** user references the ToastWeb User */
  user?: Maybe<User>
  version: Scalars['Int']['output']
}

export type EmployeeWorkType = 'FULL_TIME' | 'PART_TIME'

export type EmploymentStatus = {
  adjustedHireDate?: Maybe<Scalars['Date']['output']>
  /** @deprecated use employee.hireDate at the top level instead */
  hireDate?: Maybe<Scalars['String']['output']>
}

/**  Invoice that exist, but couldn't be fetched by some reason We will return some basic info to be able to download PDF  */
export type EmptyCustomerBillingInvoice = CustomerBillingInvoice & {
  __typename: 'EmptyCustomerBillingInvoice'
  billingAddress?: Maybe<Scalars['String']['output']>
  createdDate?: Maybe<Scalars['Date']['output']>
  dueDate?: Maybe<Scalars['Date']['output']>
  id: Scalars['ID']['output']
  items?: Maybe<Array<CustomerBillingInvoiceItem>>
  netsuiteId?: Maybe<Scalars['ID']['output']>
  orderType?: Maybe<Scalars['String']['output']>
  restaurant?: Maybe<Restaurant>
  salesforceId: Scalars['ID']['output']
  shippingAddress?: Maybe<Scalars['String']['output']>
  status?: Maybe<CustomerBillingInvoiceStatus>
  terms?: Maybe<Scalars['String']['output']>
  totals?: Maybe<CustomerBillingInvoiceTotals>
  transactionDate?: Maybe<Scalars['Date']['output']>
  type?: Maybe<CustomerBillingInvoiceType>
}

export type EntitlementsRepositoryDependencyFailure = ShiftError & {
  __typename: 'EntitlementsRepositoryDependencyFailure'
  message?: Maybe<Scalars['String']['output']>
}

/** Container type for a single ancestor path of a menu entity. */
export type EntityAncestorPath = {
  __typename: 'EntityAncestorPath'
  ancestors: Array<MenuEntity>
}

/** Response mapping an entity by versionId to the list of its ancestor branches */
export type EntityAncestorResponse = {
  __typename: 'EntityAncestorResponse'
  /** Entity type */
  entityType: MenuEntityType
  /** List of ancestor paths for that entity */
  paths: Array<EntityAncestorPath>
  /** Version ID of the entity */
  versionId: Scalars['ID']['output']
}

export type Error = {
  message: Scalars['String']['output']
}

export type ErrorCode =
  | 'BAD_REQUEST'
  | 'CONFLICT'
  | 'EXPECTATION_FAILED'
  | 'INTERNAL_SERVER_ERROR'
  | 'NOT_FOUND'

export type ErrorRefundingDeposit = Error & {
  __typename: 'ErrorRefundingDeposit'
  message: Scalars['String']['output']
  status: ErrorCode
}

export type ErrorResponse = {
  __typename: 'ErrorResponse'
  code?: Maybe<Scalars['Int']['output']>
  message?: Maybe<Scalars['String']['output']>
}

export type Errors = {
  message: Scalars['String']['output']
}

export type ExternalPartner = {
  __typename: 'ExternalPartner'
  google: ExternalPartnerToggle
}

export type ExternalPartnerToggle = {
  __typename: 'ExternalPartnerToggle'
  reservationsEnabled: Scalars['Boolean']['output']
  waitlistEnabled: Scalars['Boolean']['output']
}

export type FetchCashFlowTransactionsRequest = {
  accountIdFilter?: InputMaybe<Array<Scalars['String']['input']>>
  categoryFilter?: InputMaybe<Array<CashFlowTransactionCategory>>
  descriptionFilter?: InputMaybe<Scalars['String']['input']>
  fromDateYyyymmdd: Scalars['Int']['input']
  pageNumber?: InputMaybe<Scalars['Int']['input']>
  pageSize?: InputMaybe<Scalars['Int']['input']>
  showExcludedTransactions?: InputMaybe<Scalars['Boolean']['input']>
  showInternalTransfers?: InputMaybe<Scalars['Boolean']['input']>
  showReviewedTransactions?: InputMaybe<Scalars['Boolean']['input']>
  sortBy?: InputMaybe<CashFlowTransactionSortingFields>
  sortOrder?: InputMaybe<CashFlowTransactionSortingOrder>
  toDateYyyymmdd: Scalars['Int']['input']
  transactionTypeFilter?: InputMaybe<Array<CashFlowTransactionType>>
}

export type FetchCashFlowTransactionsResponse = {
  __typename: 'FetchCashFlowTransactionsResponse'
  accountTransactions: Array<CashFlowAccountTransactions>
  currentPage: Scalars['Int']['output']
  pageSize: Scalars['Int']['output']
  totalResultCount: Scalars['Int']['output']
}

export type FilterType = 'ALL' | 'CUSTOM' | 'LOYALTY'

export type FinancialInsightsMutation = {
  __typename: 'FinancialInsightsMutation'
  updateCashFlowTransactions: Array<CashFlowAccountTransactions>
}

export type FinancialInsightsMutationUpdateCashFlowTransactionsArgs = {
  request: Array<UpdateCashFlowTransactionRequest>
}

export type FinancialInsightsQuery = {
  __typename: 'FinancialInsightsQuery'
  fetchCashFlowTransactions: FetchCashFlowTransactionsResponse
  getBankingConnection?: Maybe<BankingConnection>
  getCashFlowAccounts: Array<CashFlowAccount>
  getCashFlowSummary?: Maybe<CashFlowSummary>
  getPnLReport: PnLReport
  getPnLReportAISummary?: Maybe<PnLaiSummary>
}

export type FinancialInsightsQueryFetchCashFlowTransactionsArgs = {
  request: FetchCashFlowTransactionsRequest
}

export type FinancialInsightsQueryGetPnLReportArgs = {
  request: PnLReportRequest
}

export type FinancialInsightsQueryGetPnLReportAiSummaryArgs = {
  request: PnLReportRequest
}

export type FinancialProductForCustomer = {
  __typename: 'FinancialProductForCustomer'
  cashAdvanceDate?: Maybe<Scalars['Int']['output']>
  externalID: Scalars['String']['output']
  fundingPartner: FundingPartner
  id: Scalars['ID']['output']
  minimumRepayment?: Maybe<MinimumRepayment>
  originationAmount?: Maybe<Money>
  productType: Scalars['String']['output']
  repaidDate?: Maybe<Scalars['Int']['output']>
  repaymentMilestones?: Maybe<Array<RepaymentMilestone>>
  repaymentRate: Scalars['Float']['output']
  startRecollectionDate?: Maybe<Scalars['Int']['output']>
  status: Scalars['String']['output']
  termInDays: Scalars['Int']['output']
  totalRepaymentAmountOwed: Money
  totalRepaymentAmountOwedAfterForgiveness: Money
  totalRepaymentAmountSettled: Money
}

/** Represents a shift that was finalized */
export type FinishedEmployeeShift = EmployeeShift & {
  __typename: 'FinishedEmployeeShift'
  /** The business date that this shift occurs in. */
  businessDate: Scalars['Date']['output']
  /** Represents the user for this shift */
  employee: RestaurantUser
  /** The identifier provided by Toast */
  id: Scalars['ID']['output']
  /** Represents whether this shift has been marked as deleted */
  isDeleted: Scalars['Boolean']['output']
  /** Represents the job for this shift. If the employee does not have any assigned jobs, this will be null. */
  job?: Maybe<Job>
  /** Represents the restaurant for this shift */
  restaurant: Restaurant
  /** Optional, represents the scheduled shift details */
  schedule?: Maybe<EmployeeSchedule>
  /** Represents the status of the shift */
  status?: Maybe<EmployeeShiftStatus>
  /** Represents the time clock information for this shift. If the employee did not clock in for this shift, this will be null. */
  timeClock?: Maybe<EmployeeTime>
  /** Represents the tips that were earned during this shift. If the employee did not receive any tips, this will be null */
  tips?: Maybe<EmployeeTips>
}

/** Represents a shift that was finalized */
export type FinishedEmployeeShiftV2 = EmployeeShiftV2 & {
  __typename: 'FinishedEmployeeShiftV2'
  /** The business date that this shift occurs in. */
  businessDate: Scalars['Date']['output']
  /** Represents the user for this shift */
  employee: RestaurantUser
  /** The identifier provided by Toast */
  id: Scalars['ID']['output']
  /** Represents whether this shift has been marked as deleted */
  isDeleted: Scalars['Boolean']['output']
  /** Represents the job for this shift. If the employee does not have any assigned jobs, this will be null. */
  job?: Maybe<Job>
  /** Represents the restaurant for this shift */
  restaurant: Restaurant
  /** Optional, represents the scheduled shift details */
  schedule?: Maybe<EmployeeScheduleV2>
  /** Represents the status of the shift */
  status?: Maybe<EmployeeShiftStatus>
  /** Represents the time clock information for this shift. If the employee did not clock in for this shift, this will be null. */
  timeClock?: Maybe<EmployeeTime>
  /** Represents the tips that were earned during this shift. If the employee did not receive any tips, this will be null */
  tips?: Maybe<EmployeeTipsV2>
}

export type FinishedEmployeeTakenBreak = EmployeeTakenBreak & {
  __typename: 'FinishedEmployeeTakenBreak'
  /** Represents the configuration used for this break */
  config: BreakConfig
  /** The type of confirmation for this break */
  confirmationType: EmployeeBreakConfirmationType
  /** The time of the end of this break */
  endTime: Scalars['DateTime']['output']
  /** The identifier provided by Toast */
  id: Scalars['ID']['output']
  /** Represents if this break will be paid out to the employee. This is copied from the config */
  isPaid: Scalars['Boolean']['output']
  /** The time of the start of this break */
  startTime: Scalars['DateTime']['output']
}

export type FinishedEmployeeTime = EmployeeTime & {
  __typename: 'FinishedEmployeeTime'
  estimatedDurationBreakdown?: Maybe<DurationBreakdown>
  inTime: Scalars['DateTime']['output']
  missedBreaks: Array<EmployeeMissedBreak>
  outTime: Scalars['DateTime']['output']
  takenBreaks: Array<EmployeeTakenBreak>
}

export type FixedFeeLoan = {
  __typename: 'FixedFeeLoan'
  balance: Money
  fixedFee: Money
  holdbackRate: Scalars['Decimal']['output']
  id: Scalars['ID']['output']
  originationAmount: Money
  startDate?: Maybe<Scalars['Date']['output']>
  termInDays: Scalars['Int']['output']
}

export type FixedFeePqo = {
  __typename: 'FixedFeePQO'
  fixedFee: Money
  holdbackRate: Scalars['Decimal']['output']
  id: Scalars['ID']['output']
  isMinimumPaymentEligible: Scalars['Boolean']['output']
  minOriginationAmount: Money
  originationAmount: Money
  promotionalOffers: Array<FixedFeePromotionalOffer>
  refinanceLoans: Array<FixedFeeLoan>
  termInDays: Scalars['Int']['output']
}

export type FixedFeePromotionalOffer = {
  __typename: 'FixedFeePromotionalOffer'
  amount?: Maybe<Money>
  percent?: Maybe<Scalars['Decimal']['output']>
  progress?: Maybe<Scalars['Decimal']['output']>
  status?: Maybe<Scalars['String']['output']>
  targetDateTime?: Maybe<Scalars['DateTime']['output']>
  type: FixedFeePromotionalOfferType
}

export type FixedFeePromotionalOfferType =
  | 'BOOSTED_OFFER'
  | 'FACTOR_DISCOUNT'
  | 'ORIGINATION_INCREASE'
  | 'PRICE_DROP'
  | 'REFI_FACTOR_DISCOUNT'

export type FoodWasteConfig = {
  __typename: 'FoodWasteConfig'
  enableTracking: Scalars['Boolean']['output']
  managementSetGuid: Scalars['String']['output']
  overrides: Array<FoodWasteConfigOverride>
  requireReason: Scalars['Boolean']['output']
  revision: Scalars['Int']['output']
}

export type FoodWasteConfigFields = 'ENABLE_TRACKING' | 'REQUIRE_REASON'

export type FoodWasteConfigInput = {
  enableTracking: Scalars['Boolean']['input']
  overrides: Array<FoodWasteConfigOverrideInput>
  requireReason: Scalars['Boolean']['input']
  revision: Scalars['Int']['input']
}

export type FoodWasteConfigOverride = {
  __typename: 'FoodWasteConfigOverride'
  enableTracking: Scalars['Boolean']['output']
  overriddenFields: Array<Maybe<FoodWasteConfigFields>>
  requireReason: Scalars['Boolean']['output']
  revision: Scalars['Int']['output']
  target: Scalars['String']['output']
}

export type FoodWasteConfigOverrideInput = {
  enableTracking: Scalars['Boolean']['input']
  overriddenFields: Array<FoodWasteConfigFields>
  requireReason: Scalars['Boolean']['input']
  revision: Scalars['Int']['input']
  target: Scalars['String']['input']
}

export type FoodWasteEducationDashboardData = {
  __typename: 'FoodWasteEducationDashboardData'
  localFoodOrganizations: Array<FoodWasteLocalOrganizationDetails>
  wasteEstimationInputs: FoodWasteEstimationInputs
}

export type FoodWasteEstimationInputs = {
  __typename: 'FoodWasteEstimationInputs'
  serviceType?: Maybe<FoodWasteEstimationServiceType>
  weeklyGuestCount: Scalars['Int']['output']
}

export type FoodWasteEstimationServiceType = 'FSR' | 'QSR'

/** Insights about the restaurant's food waste */
export type FoodWasteInsights = {
  __typename: 'FoodWasteInsights'
  /** The top reason for food waste */
  topWasteReason: Scalars['String']['output']
  /** The top item wasted */
  topWastedItem: Scalars['String']['output']
  /** The total quantity of wasted items */
  totalWastedItemQuantity: Scalars['Decimal']['output']
  /** The total retail value of wasted items */
  totalWastedRetailValue: Money
}

export type FoodWasteLocalOrganizationDetails = {
  __typename: 'FoodWasteLocalOrganizationDetails'
  description?: Maybe<Scalars['String']['output']>
  logoUrl?: Maybe<Scalars['String']['output']>
  name: Scalars['String']['output']
}

export type FoodWasteMutation = {
  __typename: 'FoodWasteMutation'
  createConfig?: Maybe<FoodWasteConfig>
  updateConfig?: Maybe<FoodWasteConfig>
}

export type FoodWasteMutationCreateConfigArgs = {
  input: FoodWasteConfigInput
}

export type FoodWasteMutationUpdateConfigArgs = {
  input: FoodWasteConfigInput
}

/** An opportunity to reduce food waste */
export type FoodWasteOpportunity = {
  __typename: 'FoodWasteOpportunity'
  /** A description of the opportunity */
  description: Scalars['String']['output']
  /** The likelihood of the opportunity having an impact, a higher number indicates a higher likelihood */
  impactLikelihood?: Maybe<Scalars['Int']['output']>
  /** The estimated savings if the opportunity is implemented */
  savingsEstimate?: Maybe<Money>
  /** The title of the opportunity */
  title: Scalars['String']['output']
}

export type FoodWasteQuery = {
  __typename: 'FoodWasteQuery'
  educationDashboard: FoodWasteEducationDashboardData
  /** Opportunities for the restaurant to reduce food waste */
  opportunities: Array<FoodWasteOpportunity>
  savedConfig?: Maybe<FoodWasteConfig>
  /** Insights into the restaurant's food waste over the last 30 days */
  thirtyDayInsights?: Maybe<FoodWasteInsights>
}

export type FullPublishStartInput = {
  originID?: InputMaybe<Scalars['ID']['input']>
  restaurantIds: Array<Scalars['ID']['input']>
}

export type FundingPartner = {
  __typename: 'FundingPartner'
  externalID: Scalars['String']['output']
  fundingPartnerUID: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type GenerateGuestFeedbackResponseInput = {
  id: Scalars['ID']['input']
}

export type GeneratedGuestFeedbackAiResponse = {
  __typename: 'GeneratedGuestFeedbackAIResponse'
  response?: Maybe<Scalars['String']['output']>
}

export type GeoCoordinates = {
  __typename: 'GeoCoordinates'
  latitude: Scalars['Float']['output']
  longitude: Scalars['Float']['output']
}

export type GetTipsResponse = {
  __typename: 'GetTipsResponse'
  tipsList: Array<TipDetails>
}

export type GetTrialsResponse = {
  __typename: 'GetTrialsResponse'
  trialList: Array<TrialEntity>
}

export type GfdAddOnStatus = {
  __typename: 'GfdAddOnStatus'
  /** Whether 1P loyalty is actively in use. */
  isFirstPartyLoyaltyActive: Scalars['Boolean']['output']
  /** Whether a fundraising campaign is actively in use. */
  isFundraisingActive: Scalars['Boolean']['output']
  /** Whether an upsell promo is actively in use. */
  isGfdUpsellActive: Scalars['Boolean']['output']
}

export type GfdConfig = {
  __typename: 'GfdConfig'
  /** The display mode on the GFD. */
  displayMode: DisplayTheme
  /** Whether GFD is enabled or not. */
  enabled: Scalars['Boolean']['output']
  /** Whether to hide price from modifier item. */
  hideModifierPrices: Scalars['Boolean']['output']
  /** To enable/disable early loyalty check-in option for loyalty customers */
  isEarlyLoyaltyCheckIn: Scalars['Boolean']['output']
  /** Config id also known as masterId */
  multiLocationId: Scalars['ID']['output']
  /** User defined name for this config. */
  name?: Maybe<Scalars['String']['output']>
  orderInProgressImage?: Maybe<GfdImage>
  /**
   * Config owner represented as restaurant set guid
   * @deprecated Replaced by ownerRestaurantSet
   */
  owner: Scalars['ID']['output']
  /** Config owner represented as restaurant set */
  ownerRestaurantSet: RestaurantSet
  standByImage?: Maybe<GfdImage>
  /**
   * Config target represented as restaurant set guid
   * @deprecated Replaced by targetRestaurantSet
   */
  target: Scalars['ID']['output']
  /** Config target represented as restaurant set */
  targetRestaurantSet: RestaurantSet
  /** Config entity identifier guid */
  versionId: Scalars['ID']['output']
}

export type GfdImage = {
  __typename: 'GfdImage'
  heightWidthRatio?: Maybe<Scalars['Float']['output']>
  path?: Maybe<Scalars['String']['output']>
}

export type GfdImageInput = {
  heightWidthRatio?: InputMaybe<Scalars['Float']['input']>
  path?: InputMaybe<Scalars['String']['input']>
}

export type GiftCardMutation = {
  __typename: 'GiftCardMutation'
  publishReceiptConfig?: Maybe<Scalars['String']['output']>
  updateReceiptConfig?: Maybe<SavedGiftCardReceiptConfig>
}

export type GiftCardMutationUpdateReceiptConfigArgs = {
  receiptConfig: GiftCardReceiptConfigUpdate
}

export type GiftCardQuery = {
  __typename: 'GiftCardQuery'
  receiptConfig?: Maybe<PublishedGiftCardReceiptConfig>
}

export type GiftCardReceiptConfigUpdate = {
  balanceInquiryConfig?: InputMaybe<GiftReceiptPrintStrategy>
  giftActivationConfig?: InputMaybe<GiftReceiptPrintStrategy>
  promoArea?: InputMaybe<Scalars['String']['input']>
}

export type GiftReceiptPrintStrategy =
  | 'ALWAYS_ASK'
  | 'ALWAYS_PRINT'
  | 'DO_NOT_PRINT'
  | 'UNKNOWN'

export type GlobalNavigationGroup = {
  __typename: 'GlobalNavigationGroup'
  globalNavigationItems: Array<Category>
  name: Scalars['String']['output']
}

/** Represents the new left rail menu */
export type GlobalNavigationMenu = {
  __typename: 'GlobalNavigationMenu'
  groups: Array<GlobalNavigationGroup>
  homeDescription: Scalars['String']['output']
  homeName: Scalars['String']['output']
  homePath: Scalars['String']['output']
}

export type Group = {
  __typename: 'Group'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  tasks: Array<Task>
}

/** A union of the group entities that can be returned for grouped net sales */
export type GroupEntity = RestaurantUser

export type GroupType = 'CUSTOM' | 'LOYALTY' | 'MANAGEMENT'

/** Count of checks with refunds for a specific grouping */
export type GroupedChecksWithRefundsCount = {
  __typename: 'GroupedChecksWithRefundsCount'
  /** The group key for display purposes */
  groupKey?: Maybe<Scalars['String']['output']>
  /** Total count of checks with refunds */
  totals: IntegerMetric
}

/** Total count of checks with voids for a specific grouping */
export type GroupedChecksWithVoidsCount = {
  __typename: 'GroupedChecksWithVoidsCount'
  /** The entity that the group key represents */
  entity?: Maybe<VoidGroupEntity>
  /** The group key for display purposes */
  groupKey?: Maybe<Scalars['String']['output']>
  /** Total count of checks with voids */
  totals: IntegerMetric
}

/** Total discount amount for a specific grouping */
export type GroupedDiscountAmount = {
  __typename: 'GroupedDiscountAmount'
  /** The entity that the group key represents */
  entity?: Maybe<DiscountGroupEntity>
  /** The group key for display purposes */
  groupKey?: Maybe<Scalars['String']['output']>
  /** Total discount amount */
  totals: MoneyMetric
}

/** Total discount count for a specific grouping */
export type GroupedDiscountCount = {
  __typename: 'GroupedDiscountCount'
  /** The entity that the group key represents */
  entity?: Maybe<DiscountGroupEntity>
  /** The group key for display purposes */
  groupKey?: Maybe<Scalars['String']['output']>
  /** Total discount count */
  totals: IntegerMetric
}

/** Labor cost for a specific grouping */
export type GroupedLaborCost = {
  __typename: 'GroupedLaborCost'
  /** The entity that the group key represents */
  entity?: Maybe<LaborCostGroupEntity>
  /** The group key for display purposes */
  groupKey?: Maybe<Scalars['String']['output']>
  /** Hourly labor cost */
  hourly: Array<MoneyMetric>
  /** Total labor cost */
  totals: MoneyMetric
}

/** Net sales for a specific grouping */
export type GroupedNetSales = {
  __typename: 'GroupedNetSales'
  /** The entity that the group represents */
  entity?: Maybe<GroupEntity>
  /** The group key for display purposes */
  groupKey?: Maybe<Scalars['String']['output']>
  /** Hourly grouped net sales */
  hourly: Array<MoneyMetric>
  /** Total grouped net sales */
  totals: MoneyMetric
}

/** Total refund amount for a specific grouping */
export type GroupedTotalRefundAmount = {
  __typename: 'GroupedTotalRefundAmount'
  /** The group key for display purposes */
  groupKey?: Maybe<Scalars['String']['output']>
  /** Total refund amount */
  totals: MoneyMetric
}

/** Total void amount for a specific grouping */
export type GroupedVoidAmount = {
  __typename: 'GroupedVoidAmount'
  /** The entity that the group key represents */
  entity?: Maybe<VoidGroupEntity>
  /** The group key for display purposes */
  groupKey?: Maybe<Scalars['String']['output']>
  /** Total void amount */
  totals: MoneyMetric
}

/** Total void amount as percentage of net sales for a specific grouping */
export type GroupedVoidAmountAsPercentageOfNetSales = {
  __typename: 'GroupedVoidAmountAsPercentageOfNetSales'
  /** The entity that the group key represents */
  entity?: Maybe<VoidGroupEntity>
  /** The group key for display purposes */
  groupKey?: Maybe<Scalars['String']['output']>
  /** Total void amount as percentage of net sales */
  totals: PercentageMetric
}

/** Total count of voided items for a specific grouping */
export type GroupedVoidedItemsCount = {
  __typename: 'GroupedVoidedItemsCount'
  /** The entity that the group key represents */
  entity?: Maybe<VoidGroupEntity>
  /** The group key for display purposes */
  groupKey?: Maybe<Scalars['String']['output']>
  /** Total count of voided items */
  totals: DecimalMetric
}

/** The grouping dimensions available for net sales metrics */
export type GroupingDimension =
  | 'DINING_OPTION'
  | 'EMPLOYEE'
  | 'RESTAURANT_SERVICE'
  | 'REVENUE_CENTER'
  | 'SALES_CATEGORY'

export type GroupsFilterInput = {
  isArchived: Scalars['Boolean']['input']
  showType: FilterType
}

export type Guest = {
  __typename: 'Guest'
  acceptedTos?: Maybe<Scalars['Boolean']['output']>
  bookingCount?: Maybe<Scalars['Int']['output']>
  coverCount?: Maybe<Scalars['Int']['output']>
  createdAt?: Maybe<Scalars['DateTime']['output']>
  deleted: Scalars['Boolean']['output']
  dobDay?: Maybe<Scalars['Int']['output']>
  dobMonth?: Maybe<Scalars['Int']['output']>
  dobYear?: Maybe<Scalars['Int']['output']>
  email?: Maybe<Scalars['String']['output']>
  firstName?: Maybe<Scalars['String']['output']>
  guestBookGuid?: Maybe<Scalars['ID']['output']>
  guestNotes?: Maybe<Scalars['String']['output']>
  guestProfilesGuid?: Maybe<Scalars['ID']['output']>
  guestTags?: Maybe<Array<Scalars['String']['output']>>
  id: Scalars['ID']['output']
  lastName?: Maybe<Scalars['String']['output']>
  lastVisitDate?: Maybe<Scalars['DateTime']['output']>
  phoneNumber?: Maybe<Scalars['String']['output']>
  piServicePhoneNumberToken?: Maybe<Scalars['String']['output']>
  restaurant?: Maybe<Scalars['ID']['output']>
  rotationPhoneNumber?: Maybe<Scalars['String']['output']>
  toastMarketable?: Maybe<Scalars['Boolean']['output']>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
}

export type GuestAlreadyExists = Error & {
  __typename: 'GuestAlreadyExists'
  guest: Guest
  message: Scalars['String']['output']
  status: ErrorCode
}

/** Count of guests */
export type GuestCount = {
  __typename: 'GuestCount'
  /** Hourly guest count */
  hourly: Array<IntegerMetric>
  /** Total guest count */
  totals: IntegerMetric
}

export type GuestCreationResponse =
  | GuestAlreadyExists
  | GuestCreationResponseSuccess
  | InvalidPhoneNumber
  | RestaurantNotFound
  | UnableToCreateGuest

export type GuestCreationResponseSuccess = {
  __typename: 'GuestCreationResponseSuccess'
  guest: Guest
}

export type GuestFeedbackAiSummary = {
  __typename: 'GuestFeedbackAiSummary'
  createdDate?: Maybe<Scalars['String']['output']>
  managementGroup: ManagementGroup
  modifiedDate?: Maybe<Scalars['String']['output']>
  restaurant?: Maybe<SavedRestaurantLocation>
  summary?: Maybe<Scalars['String']['output']>
  topNegativeKeywords: Array<Scalars['String']['output']>
  topNeutralKeywords: Array<Scalars['String']['output']>
  topPositiveKeywords: Array<Scalars['String']['output']>
}

export type GuestFeedbackContactInformation = {
  __typename: 'GuestFeedbackContactInformation'
  email?: Maybe<Scalars['String']['output']>
  firstName?: Maybe<Scalars['String']['output']>
  lastName?: Maybe<Scalars['String']['output']>
  optIn?: Maybe<Scalars['Boolean']['output']>
  phone?: Maybe<Scalars['String']['output']>
}

export type GuestFeedbackCountByWeek = {
  __typename: 'GuestFeedbackCountByWeek'
  negativeFeedbackCount: Scalars['Int']['output']
  positiveFeedbackCount: Scalars['Int']['output']
  weekStartDate: Scalars['String']['output']
}

export type GuestFeedbackFilterInput = {
  managementSetId: Scalars['ID']['input']
  priorityFeedbacksOnly?: InputMaybe<Scalars['Boolean']['input']>
  restaurantIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type GuestFeedbackGenerateResponse =
  | GeneratedGuestFeedbackAiResponse
  | GuestFeedbackGenerateResponseError

export type GuestFeedbackGenerateResponseError = {
  __typename: 'GuestFeedbackGenerateResponseError'
  code: GuestFeedbackGenerateResponseErrorCode
  message?: Maybe<Scalars['String']['output']>
}

export type GuestFeedbackGenerateResponseErrorCode = 'GuestFeedbackNotFound'

export type GuestFeedbackInfo = {
  __typename: 'GuestFeedbackInfo'
  check?: Maybe<Check>
  contactInformation?: Maybe<GuestFeedbackContactInformation>
  createdDate?: Maybe<Scalars['String']['output']>
  feedbackText?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  managementGroup?: Maybe<ManagementGroup>
  menuItem?: Maybe<MenuItem>
  menuItemFeedbacks: Array<MenuItemFeedback>
  order?: Maybe<Order>
  orderCheckInfo?: Maybe<GuestFeedbackOrderCheckInfo>
  payment?: Maybe<Scalars['ID']['output']>
  rating?: Maybe<GuestFeedbackRatingType>
  rawRating?: Maybe<GuestFeedbackRawRatingType>
  reasons: Array<Scalars['String']['output']>
  responses: Array<GuestFeedbackResponse>
  restaurant?: Maybe<SavedRestaurantLocation>
  source?: Maybe<Scalars['String']['output']>
  status?: Maybe<GuestFeedbackStatus>
}

export type GuestFeedbackMutation = {
  __typename: 'GuestFeedbackMutation'
  feedbackStatus: GuestFeedbackInfo
  generateFeedbackResponse: GuestFeedbackGenerateResponse
  sendFeedbackResponse: GuestFeedbackInfo
}

export type GuestFeedbackMutationFeedbackStatusArgs = {
  id: Scalars['ID']['input']
  status: GuestFeedbackStatus
}

export type GuestFeedbackMutationGenerateFeedbackResponseArgs = {
  data: GenerateGuestFeedbackResponseInput
}

export type GuestFeedbackMutationSendFeedbackResponseArgs = {
  data: SendGuestFeedbackResponseInput
}

export type GuestFeedbackOrderCheckInfo = {
  __typename: 'GuestFeedbackOrderCheckInfo'
  checkNumber?: Maybe<Scalars['String']['output']>
  serverName?: Maybe<Scalars['String']['output']>
  totalAmount?: Maybe<Scalars['Float']['output']>
}

export type GuestFeedbackPaginationInput = {
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
}

export type GuestFeedbackQuery = {
  __typename: 'GuestFeedbackQuery'
  aiSummary: GuestFeedbackAiSummary
  guestFeedbackDetails?: Maybe<GuestFeedbackInfo>
  guestFeedbacks: Array<GuestFeedbackInfo>
  hasManagementSetPermission?: Maybe<Scalars['Boolean']['output']>
  statsSummary?: Maybe<GuestFeedbackStatsSummaryResponse>
}

export type GuestFeedbackQueryAiSummaryArgs = {
  filter: GuestFeedbackFilterInput
}

export type GuestFeedbackQueryGuestFeedbackDetailsArgs = {
  id: Scalars['ID']['input']
}

export type GuestFeedbackQueryGuestFeedbacksArgs = {
  filter: GuestFeedbackFilterInput
  pagination: GuestFeedbackPaginationInput
}

export type GuestFeedbackQueryStatsSummaryArgs = {
  filter: GuestFeedbackFilterInput
}

export type GuestFeedbackRatingType =
  | 'NEGATIVE'
  | 'PASSIVE'
  | 'POSITIVE'
  | 'SOMEWHAT_NEGATIVE'
  | 'SOMEWHAT_POSITIVE'

export type GuestFeedbackRawRatingType = 'FACES' | 'STARS' | 'THUMBS'

export type GuestFeedbackResponse = {
  __typename: 'GuestFeedbackResponse'
  createdDate: Scalars['String']['output']
  response: Scalars['String']['output']
  type: GuestFeedbackResponseType
  userID?: Maybe<Scalars['ID']['output']>
}

export type GuestFeedbackResponseType = 'EMAIL' | 'MANUAL_STATUS_UPDATE'

export type GuestFeedbackSentimentByMenuItem = {
  __typename: 'GuestFeedbackSentimentByMenuItem'
  menuItem?: Maybe<MenuItem>
  noOfFeedbacks: Scalars['Int']['output']
  percentageSentiment: Scalars['Float']['output']
}

export type GuestFeedbackSentimentOverview = {
  __typename: 'GuestFeedbackSentimentOverview'
  feedbackCountByWeek: Array<GuestFeedbackCountByWeek>
  sentimentScore: Scalars['Float']['output']
  sentimentScoreDelta: Scalars['Float']['output']
}

export type GuestFeedbackStatsSummaryResponse = {
  __typename: 'GuestFeedbackStatsSummaryResponse'
  createdDate: Scalars['String']['output']
  managementGroup: ManagementGroup
  modifiedDate: Scalars['String']['output']
  numberOfClosedFeedback: Scalars['Int']['output']
  numberOfOpenFeedback: Scalars['Int']['output']
  numberOfOpenFeedbackDelta: Scalars['Float']['output']
  numberOfPriorityFeedback: Scalars['Int']['output']
  restaurant?: Maybe<SavedRestaurantLocation>
  sentimentOverview: GuestFeedbackSentimentOverview
  topNegativeMenuItems: Array<GuestFeedbackSentimentByMenuItem>
  topPositiveMenuItems: Array<GuestFeedbackSentimentByMenuItem>
  totalNumberOfFeedbackDelta: Scalars['Float']['output']
  totalNumberOfFeedbacks: Scalars['Int']['output']
}

export type GuestFeedbackStatus = 'CLOSED' | 'OPEN'

export type GuestListResponse = GuestListResponseSuccess | GuestNotFound

export type GuestListResponseSuccess = {
  __typename: 'GuestListResponseSuccess'
  guests: Array<Guest>
}

export type GuestNotFound = Error & {
  __typename: 'GuestNotFound'
  message: Scalars['String']['output']
  status: ErrorCode
}

export type GuestQuoteTimes = {
  __typename: 'GuestQuoteTimes'
  deliveryEnabled?: Maybe<Scalars['Boolean']['output']>
  deliveryThrottlingTimeMinutes?: Maybe<Scalars['Int']['output']>
  deliveryTimeMinutes?: Maybe<Scalars['Int']['output']>
  id: Scalars['ID']['output']
  itemsPerSlot?: Maybe<Scalars['Int']['output']>
  kitchenCapacity?: Maybe<Scalars['String']['output']>
  maximumAutomatedPrepTimeMinutes?: Maybe<Scalars['Int']['output']>
  minDeliveryHours?: Maybe<Scalars['Int']['output']>
  minDeliveryMinutes?: Maybe<Scalars['Int']['output']>
  minTakeoutHours?: Maybe<Scalars['Int']['output']>
  minTakeoutMinutes?: Maybe<Scalars['Int']['output']>
  minimumAutomatedPrepTimeMinutes?: Maybe<Scalars['Int']['output']>
  orderPerSlot?: Maybe<Scalars['Int']['output']>
  quoteTimes?: Maybe<Array<Maybe<QuoteTime>>>
  strategy?: Maybe<Scalars['String']['output']>
  takeoutEnabled?: Maybe<Scalars['Boolean']['output']>
  takeoutThrottlingTimeMinutes?: Maybe<Scalars['Int']['output']>
}

export type GuestUpdateResponse =
  | GuestAlreadyExists
  | GuestNotFound
  | GuestUpdateResponseSuccess
  | InvalidPhoneNumber
  | RestaurantNotFound
  | UnableToUpdateGuest

export type GuestUpdateResponseSuccess = {
  __typename: 'GuestUpdateResponseSuccess'
  guest: Guest
}

export type HardwareItemOrdered = {
  __typename: 'HardwareItemOrdered'
  /** Image URL retrieved from eComm Magento */
  image?: Maybe<Scalars['String']['output']>
  itemId: Scalars['String']['output']
  name: Scalars['String']['output']
  quantity: Scalars['Int']['output']
}

export type HardwareOrder = {
  __typename: 'HardwareOrder'
  deliveryInfo: HardwareOrderDeliveryInfo
  itemsOrdered?: Maybe<Array<Maybe<HardwareItemOrdered>>>
  orderConfirmedDate?: Maybe<Scalars['String']['output']>
  status: HardwareOrderStatus
  /** Ordered items could be shipped separately, so there could be multiple tracking info */
  trackingInfo?: Maybe<Array<Maybe<HardwareOrderTrackingInfo>>>
}

export type HardwareOrderDeliveryAddress = {
  __typename: 'HardwareOrderDeliveryAddress'
  city: Scalars['String']['output']
  country: Scalars['String']['output']
  /** The formatted address that shows name, street, and remaining info in different rows. */
  formattedAddress: Scalars['String']['output']
  name: Scalars['String']['output']
  state: Scalars['String']['output']
  street: Scalars['String']['output']
  zipCode: Scalars['String']['output']
}

export type HardwareOrderDeliveryInfo = {
  __typename: 'HardwareOrderDeliveryInfo'
  actualDeliveryDate?: Maybe<Scalars['String']['output']>
  actualDeliveryTime?: Maybe<Scalars['String']['output']>
  deliveryAddress: HardwareOrderDeliveryAddress
  estimatedDeliveryDate?: Maybe<Scalars['String']['output']>
  estimatedDeliveryWindow?: Maybe<Scalars['String']['output']>
}

export type HardwareOrderStatus =
  | 'Canceled'
  | 'Delivered'
  | 'Opened'
  /** Hardware API lacks of a out-for-delivery status now. */
  | 'OutForDelivery'
  | 'Shipped'
  | 'Waved'

export type HardwareOrderTrackingInfo = {
  __typename: 'HardwareOrderTrackingInfo'
  carrier: Scalars['String']['output']
  shipMethod?: Maybe<Scalars['String']['output']>
  trackingLink: Scalars['String']['output']
  trackingNumber: Scalars['String']['output']
}

export type Idm_Identity_Type = 'EMAIL' | 'GOV_ID' | 'PHONE' | 'SSO'

/** Ireland invoice */
export type IrlCustomerBillingInvoice = CustomerBillingInvoice & {
  __typename: 'IRLCustomerBillingInvoice'
  billingAddress?: Maybe<Scalars['String']['output']>
  createdDate: Scalars['Date']['output']
  /**  Deduction from invoice  */
  deductions: CustomerBillingInvoiceDeductions
  dueDate: Scalars['Date']['output']
  id: Scalars['ID']['output']
  items: Array<IrlCustomerBillingInvoiceItem>
  netsuiteId: Scalars['ID']['output']
  orderType?: Maybe<Scalars['String']['output']>
  restaurant?: Maybe<Restaurant>
  salesforceId: Scalars['ID']['output']
  /**  Shipping totals  */
  shipping: CustomerBillingInvoiceShipping
  shippingAddress?: Maybe<Scalars['String']['output']>
  status: CustomerBillingInvoiceStatus
  terms?: Maybe<Scalars['String']['output']>
  totals: CustomerBillingInvoiceTotals
  transactionDate: Scalars['Date']['output']
  type: CustomerBillingInvoiceType
}

/** Ireland invoice item */
export type IrlCustomerBillingInvoiceItem = CustomerBillingInvoiceItem & {
  __typename: 'IRLCustomerBillingInvoiceItem'
  amount: Money
  children: Array<IrlCustomerBillingInvoiceItem>
  endDate: Scalars['Date']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  parentProduct?: Maybe<Scalars['String']['output']>
  quantity: Scalars['Int']['output']
  rate: Money
  startDate: Scalars['Date']['output']
  /**  Taxes amount for purchased items (amount * rate) */
  taxAmount: Money
  /**  Taxes amount for a single item  */
  taxRate: Money
}

export type Identity = {
  id: Scalars['String']['output']
}

export type IdentityInterface = {
  id: Scalars['ID']['output']
  type: Idm_Identity_Type
  value: Scalars['String']['output']
}

export type IdmAdminUser = UserInterface & {
  __typename: 'IdmAdminUser'
  createdDate: Scalars['Date']['output']
  deletedDate?: Maybe<Scalars['Date']['output']>
  id: Scalars['ID']['output']
  identities: Array<IdmIdentity>
  name: IdmName
  updatedDate?: Maybe<Scalars['Date']['output']>
  username: IdmUsername
  version: Scalars['Int']['output']
}

export type IdmCustomerUser = UserInterface & {
  __typename: 'IdmCustomerUser'
  /**  Denotes whether the user has activated their account for backend access and can access Toast Web  */
  canLogin: Scalars['Boolean']['output']
  createdDate: Scalars['Date']['output']
  deletedDate?: Maybe<Scalars['Date']['output']>
  id: Scalars['ID']['output']
  identities: Array<IdmIdentity>
  name: IdmName
  updatedDate?: Maybe<Scalars['Date']['output']>
  username: IdmUsername
  version: Scalars['Int']['output']
}

export type IdmExternalDeveloperUser = UserInterface & {
  __typename: 'IdmExternalDeveloperUser'
  createdDate: Scalars['Date']['output']
  deletedDate?: Maybe<Scalars['Date']['output']>
  id: Scalars['ID']['output']
  identities: Array<IdmIdentity>
  name: IdmName
  updatedDate?: Maybe<Scalars['Date']['output']>
  username: IdmUsername
  version: Scalars['Int']['output']
}

export type IdmGuestAppMetadata = {
  __typename: 'IdmGuestAppMetadata'
  isProfileCreated?: Maybe<Scalars['Boolean']['output']>
}

export type IdmGuestUser = UserInterface & {
  __typename: 'IdmGuestUser'
  appMetadata?: Maybe<IdmGuestAppMetadata>
  createdDate: Scalars['Date']['output']
  deletedDate?: Maybe<Scalars['Date']['output']>
  id: Scalars['ID']['output']
  identities: Array<IdmIdentity>
  name: IdmName
  updatedDate?: Maybe<Scalars['Date']['output']>
  username: IdmUsername
  version: Scalars['Int']['output']
}

export type IdmIdentity = IdentityInterface & {
  __typename: 'IdmIdentity'
  id: Scalars['ID']['output']
  isPrimary: Scalars['Boolean']['output']
  issuer?: Maybe<Scalars['String']['output']>
  type: Idm_Identity_Type
  value: Scalars['String']['output']
  verification: IdmIdentityVerification
}

export type IdmIdentityVerification = {
  __typename: 'IdmIdentityVerification'
  date?: Maybe<Scalars['Date']['output']>
  status: Scalars['String']['output']
}

export type IdmName = {
  __typename: 'IdmName'
  chosen?: Maybe<Scalars['String']['output']>
  first: Scalars['String']['output']
  last: Scalars['String']['output']
  middle?: Maybe<Scalars['String']['output']>
}

export type IdmUserEmailFilter = {
  email: Scalars['String']['input']
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>
}

export type IdmUserFilter = {
  emailFilter?: InputMaybe<IdmUserEmailFilter>
  idFilter?: InputMaybe<IdmUserIdFilter>
  phoneFilter?: InputMaybe<IdmUserPhoneFilter>
}

export type IdmUserIdFilter = {
  id: Scalars['ID']['input']
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>
}

export type IdmUserPhoneFilter = {
  countryCode: Scalars['String']['input']
  phoneNumber: Scalars['String']['input']
}

/**  The username is the primary identity for the user. This is the identity that they will use for logging in.  */
export type IdmUsername = IdentityInterface & {
  __typename: 'IdmUsername'
  id: Scalars['ID']['output']
  type: Idm_Identity_Type
  value: Scalars['String']['output']
}

export type IdmUsersQuery = {
  __typename: 'IdmUsersQuery'
  /**  Fetch an admin user WARNING - NOT IMPLEMENTED  */
  adminUser?: Maybe<IdmAdminUser>
  /**  Fetch a customer user WARNING - NOT IMPLEMENTED  */
  customerUser?: Maybe<IdmCustomerUser>
  /**  Fetch an external developer user WARNING - NOT IMPLEMENTED  */
  externalDeveloperUser?: Maybe<IdmExternalDeveloperUser>
  /**  Fetch a guest user WARNING - NOT IMPLEMENTED  */
  guestUser?: Maybe<IdmGuestUser>
}

export type IdmUsersQueryAdminUserArgs = {
  filters: IdmUserFilter
}

export type IdmUsersQueryCustomerUserArgs = {
  filters: IdmUserFilter
}

export type IdmUsersQueryExternalDeveloperUserArgs = {
  filters: IdmUserFilter
}

export type IdmUsersQueryGuestUserArgs = {
  filters: IdmUserFilter
}

export type ImageEntityType = 'MENU_ITEM' | 'RESTAURANT'

export type ImageSetType = 'BACKGROUND' | 'BANNER' | 'THUMBNAIL'

export type ImageUploadInput = {
  imageBytesBase64: Scalars['String']['input']
  imageEntityType: ImageEntityType
  imageSetType?: InputMaybe<ImageSetType>
}

export type ImplementationEmploymentStatus = EmploymentStatus & {
  __typename: 'ImplementationEmploymentStatus'
  adjustedHireDate?: Maybe<Scalars['Date']['output']>
  /** @deprecated use employee.hireDate at the top level instead */
  hireDate?: Maybe<Scalars['String']['output']>
}

/** Represents a shift that is currently in progress */
export type InProgressEmployeeShift = EmployeeShift & {
  __typename: 'InProgressEmployeeShift'
  /** The business date that this shift occurs in. */
  businessDate: Scalars['Date']['output']
  /** Represents the user for this shift */
  employee: RestaurantUser
  /** The identifier provided by Toast */
  id: Scalars['ID']['output']
  /** Represents whether this shift has been marked as deleted */
  isDeleted: Scalars['Boolean']['output']
  /** Represents the job for this shift. If the employee does not have any assigned jobs, this will be null. */
  job?: Maybe<Job>
  /** Represents the restaurant for this shift */
  restaurant: Restaurant
  /** Optional, represents the scheduled shift details */
  schedule?: Maybe<EmployeeSchedule>
  /** Represents if the in progress shift can be clocked out based on whether shift review is required from UI Options (PosUxConfig) */
  shiftCanBeClockedOut?: Maybe<Scalars['Boolean']['output']>
  /** Represents the status of the shift */
  status?: Maybe<EmployeeShiftStatus>
  /** Represents the time clock information for this shift. If the employee did not clock in for this shift, this will be null. */
  timeClock?: Maybe<EmployeeTime>
  /** Represents the tips that were earned during this shift. If the employee did not receive any tips, this will be null */
  tips?: Maybe<EmployeeTips>
}

/** Represents a shift that is currently in progress */
export type InProgressEmployeeShiftV2 = EmployeeShiftV2 & {
  __typename: 'InProgressEmployeeShiftV2'
  /** The business date that this shift occurs in. */
  businessDate: Scalars['Date']['output']
  /** Represents the user for this shift */
  employee: RestaurantUser
  /** The identifier provided by Toast */
  id: Scalars['ID']['output']
  /** Represents whether this shift has been marked as deleted */
  isDeleted: Scalars['Boolean']['output']
  /** Represents the job for this shift. If the employee does not have any assigned jobs, this will be null. */
  job?: Maybe<Job>
  /** Represents the restaurant for this shift */
  restaurant: Restaurant
  /** Optional, represents the scheduled shift details */
  schedule?: Maybe<EmployeeScheduleV2>
  /** Represents the status of the shift */
  status?: Maybe<EmployeeShiftStatus>
  /** Represents the time clock information for this shift. If the employee did not clock in for this shift, this will be null. */
  timeClock?: Maybe<EmployeeTime>
  /** Represents the tips that were earned during this shift. If the employee did not receive any tips, this will be null */
  tips?: Maybe<EmployeeTipsV2>
}

export type InProgressEmployeeTakenBreak = EmployeeTakenBreak & {
  __typename: 'InProgressEmployeeTakenBreak'
  /** Represents the configuration used for this break */
  config: BreakConfig
  /** The identifier provided by Toast */
  id: Scalars['ID']['output']
  /** Represents if this break will be paid out to the employee. This is copied from the config */
  isPaid: Scalars['Boolean']['output']
  /** The time of the start of this break */
  startTime: Scalars['DateTime']['output']
}

export type InProgressEmployeeTime = EmployeeTime & {
  __typename: 'InProgressEmployeeTime'
  inTime: Scalars['DateTime']['output']
  missedBreaks: Array<EmployeeMissedBreak>
  takenBreaks: Array<EmployeeTakenBreak>
}

/** Metric for integer values */
export type IntegerMetric = {
  __typename: 'IntegerMetric'
  /** Comparison integer value */
  comparison?: Maybe<Scalars['Int']['output']>
  /** Current integer value */
  current: Scalars['Int']['output']
  /** End time for the metric; inclusive */
  endTime: Scalars['DateTime']['output']
  /** Start time for the metric; inclusive */
  startTime: Scalars['DateTime']['output']
  /** Variation in comparison */
  variation?: Maybe<Comparison>
}

export type InteractionType = 'ResultClick' | 'WidgetAction'

export type IntercomClientType = 'ANDROID' | 'IOS' | 'WEB'

export type IntercomConversation = {
  __typename: 'IntercomConversation'
  conversationParts: Array<IntercomConversationPart>
  /** A number between 1 and 5 given by the customer */
  conversationRating?: Maybe<Scalars['Int']['output']>
  createdAt: Scalars['DateTime']['output']
  finInvolved: Scalars['Boolean']['output']
  finOutcome?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  originUrl?: Maybe<Scalars['String']['output']>
  restaurantLocation?: Maybe<SavedRestaurantLocation>
  state: IntercomConversationState
  updatedAt: Scalars['DateTime']['output']
  user?: Maybe<User>
}

export type IntercomConversationPart = {
  __typename: 'IntercomConversationPart'
  author: IntercomConversationPartAuthor
  body?: Maybe<Scalars['String']['output']>
  /** In UTC */
  timeStamp: Scalars['DateTime']['output']
  /** Comment, note or conversation summary */
  type: IntercomConversationPartType
}

export type IntercomConversationPartAuthor = {
  __typename: 'IntercomConversationPartAuthor'
  email: Scalars['String']['output']
  name: Scalars['String']['output']
  /** Customer, agent, Butter (Toast AI) or Fin (Intercom AI) */
  type: IntercomConversationPartAuthorType
}

export type IntercomConversationPartAuthorType =
  | 'AGENT'
  | 'BUTTER'
  | 'CUSTOMER'
  | 'FIN'

export type IntercomConversationPartType =
  | 'COMMENT'
  | 'CONVERSATION_SUMMARY'
  | 'NOTE'

export type IntercomConversationState = 'CLOSED' | 'OPEN' | 'SNOOZED'

export type IntercomOptions = {
  __typename: 'IntercomOptions'
  isIntercomMessengerEnabled: Scalars['Boolean']['output']
  /** The support service model this location is eligible for */
  serviceModel: ServiceModel
  /**  The user hash to be used in Intercom. This will be null if isIntercomMessengerEnabled is false  */
  userHash?: Maybe<IntercomUserHashResult>
}

export type IntercomUserHashResult = {
  __typename: 'IntercomUserHashResult'
  /** The HMAC digest to use as the User Hash */
  hmacDigest: Scalars['String']['output']
  /** The value the hmac digest is based off */
  value: Scalars['String']['output']
}

export type InternalError = Error & {
  __typename: 'InternalError'
  message: Scalars['String']['output']
  status: ErrorCode
}

export type IntraModifierGroupSortOrder =
  | 'INSERTION_ORDER'
  | 'ORDER_IN_MODIFIER_GROUP'

export type InvalidBreakConfig = ShiftError & {
  __typename: 'InvalidBreakConfig'
  message?: Maybe<Scalars['String']['output']>
}

export type InvalidBreaks = ShiftError & {
  __typename: 'InvalidBreaks'
  message?: Maybe<Scalars['String']['output']>
}

export type InvalidCustomerUuidError = Errors & {
  __typename: 'InvalidCustomerUuidError'
  message: Scalars['String']['output']
}

export type InvalidEmail = Error & {
  __typename: 'InvalidEmail'
  message: Scalars['String']['output']
  status: ErrorCode
}

export type InvalidGuest = Error & {
  __typename: 'InvalidGuest'
  message: Scalars['String']['output']
  status: ErrorCode
}

export type InvalidIdempotencyKeyError = Errors & {
  __typename: 'InvalidIdempotencyKeyError'
  message: Scalars['String']['output']
}

export type InvalidJob = ShiftError & {
  __typename: 'InvalidJob'
  message?: Maybe<Scalars['String']['output']>
}

export type InvalidLocation = {
  __typename: 'InvalidLocation'
  locationId: Scalars['String']['output']
  reason: InvalidLocationReason
}

export type InvalidLocationReason =
  | 'INSUFFICIENT_PERMISSIONS'
  | 'NO_ACH_AVAILABLE'
  | 'NO_MANAGEMENT_GROUP'
  | 'NO_SOFTWARE_DEPENDENCIES'
  | 'SALESFORCE_ACCOUNT_IN_INVALID_STATE'

export type InvalidPhoneNumber = Error & {
  __typename: 'InvalidPhoneNumber'
  message: Scalars['String']['output']
  status: ErrorCode
}

export type InvalidRestaurantIdError = Errors & {
  __typename: 'InvalidRestaurantIDError'
  message: Scalars['String']['output']
}

export type InvalidUiOption = {
  __typename: 'InvalidUIOption'
  smartTax?: Maybe<UiOptionInvalidSmartTax>
}

export type InvalidUpdatedTime = ShiftError & {
  __typename: 'InvalidUpdatedTime'
  message?: Maybe<Scalars['String']['output']>
  subType?: Maybe<Scalars['String']['output']>
}

export type InvalidUuidError = Errors & {
  __typename: 'InvalidUuidError'
  message: Scalars['String']['output']
}

export type ItemFeedbackConfig = {
  __typename: 'ItemFeedbackConfig'
  itemFeedbackEnabled: Scalars['Boolean']['output']
  notificationContacts: Array<NotificationContact>
  unsavedNotificationContacts: Array<NotificationContact>
}

export type ItemFeedbackConfigAlreadyExists = {
  __typename: 'ItemFeedbackConfigAlreadyExists'
  existingConfig: ItemFeedbackConfig
}

export type ItemFeedbackConfigError = {
  __typename: 'ItemFeedbackConfigError'
  code: ItemFeedbackConfigErrorCode
  message?: Maybe<Scalars['String']['output']>
}

export type ItemFeedbackConfigErrorCode =
  | 'ConfigNotFound'
  | 'FailedToRetrieveConfig'
  | 'ManagementSetGuidNotFound'

export type ItemFeedbackConfigInput = {
  itemFeedbackEnabled: Scalars['Boolean']['input']
  notificationContacts: Array<NotificationContactInput>
}

export type ItemFeedbackConfigResponse =
  | ItemFeedbackConfig
  | ItemFeedbackConfigError

/** Item-level metrics */
export type ItemMetrics = {
  __typename: 'ItemMetrics'
  /** Reference to the menu item entity */
  item: MenuItem
  /** Name of the item */
  name: Scalars['String']['output']
  /** Quantity of the item sold */
  salesQuantity: DecimalMetric
  /** Total net sales of the item */
  totalNetSales: MoneyMetric
}

export type ItemReference = {
  __typename: 'ItemReference'
  name?: Maybe<Scalars['String']['output']>
}

/** Reference to a Job entity */
export type Job = {
  __typename: 'Job'
  /** Indicates the rate for which a job is paid */
  basis?: Maybe<Basis>
  cashier: Scalars['Boolean']['output']
  code?: Maybe<Scalars['String']['output']>
  /** The identifier provided by Toast */
  id: Scalars['ID']['output']
  /** If the employee is deleted in Toast. */
  isDeleted: Scalars['Boolean']['output']
  /** Indicates whether the job receives gratuities (tips). */
  isTipped?: Maybe<Scalars['Boolean']['output']>
  /** The id of the restaurant owning this job */
  restaurant: Restaurant
  title?: Maybe<Scalars['String']['output']>
  wageFrequency: WageFrequency
}

/**  A JobAssignmentDefaults type. This type includes a defaultRate type for the job, extended properties about the job and default permissions.  */
export type JobAssignmentDefaults = {
  __typename: 'JobAssignmentDefaults'
  defaultRate: DefaultRate
  defaultToastPermissions: Array<ToastPermission>
  extendedProperties: JobAssignmentDefaultsExtendedProperties
}

/**  A JobAssignmentDefaultsExtendedProperties type with booleans to control cash draw access and driver permissions.  */
export type JobAssignmentDefaultsExtendedProperties = {
  __typename: 'JobAssignmentDefaultsExtendedProperties'
  isCashier?: Maybe<Scalars['Boolean']['output']>
  isDriver?: Maybe<Scalars['Boolean']['output']>
}

export type JobAssignmentInfo = {
  __typename: 'JobAssignmentInfo'
  locationName: Scalars['String']['output']
  name: Scalars['String']['output']
}

export type JobBasedAccessConfig = {
  __typename: 'JobBasedAccessConfig'
  enabled: Scalars['Boolean']['output']
  managementGroup: ManagementGroup
}

export type JobBasedAccessConfigInput = {
  enabled: Scalars['Boolean']['input']
}

export type JobError = Errors & {
  __typename: 'JobError'
  message: Scalars['String']['output']
}

/**  A JobExtendedProperties type. This type includes booleans to control schedule visibility, labor reporting and tipping permissions.  */
export type JobExtendedProperties = {
  __typename: 'JobExtendedProperties'
  excludeFromLaborReporting?: Maybe<Scalars['Boolean']['output']>
  hideFromSchedule?: Maybe<Scalars['Boolean']['output']>
  isTipped?: Maybe<Scalars['Boolean']['output']>
}

export type JobInput = {
  /** The job identifier */
  id: Scalars['ID']['input']
}

export type JobMapping = {
  __typename: 'JobMapping'
  job: JobV2
  legacyJobId: Scalars['ID']['output']
}

export type JobMappings = {
  __typename: 'JobMappings'
  jobMappings: Array<JobMapping>
}

export type JobNotFoundError = Errors & {
  __typename: 'JobNotFoundError'
  message: Scalars['String']['output']
}

export type JobRequest = {
  id: Scalars['ID']['input']
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>
}

export type JobResponse =
  | InvalidUuidError
  | JobError
  | JobNotFoundError
  | JobV2
  | UnauthorizedAccessError

/**  A JobTagAssignment type, this type includes a job tag type and job tag value.  */
export type JobTagAssignment = {
  __typename: 'JobTagAssignment'
  jobTagType: JobTagType
  jobTagValue: JobTagValue
}

export type JobTagType =
  | 'JOB_TAG_TYPE_DEPARTMENT'
  | 'JOB_TAG_TYPE_JOB_TYPE'
  | 'JOB_TAG_TYPE_UNSPECIFIED'

/** A JobTagValue type, this type includes an ID and a value. */
export type JobTagValue = {
  __typename: 'JobTagValue'
  id: Scalars['ID']['output']
  value: Scalars['String']['output']
}

/**  A JobV2 federated type. This is the new unified Job object for use across Team Management.  */
export type JobV2 = {
  __typename: 'JobV2'
  assignableLocationGroups: Array<LocationGroup>
  createdTimestamp: Scalars['DateTime']['output']
  deletedTimestamp?: Maybe<Scalars['DateTime']['output']>
  extendedProperties: JobExtendedProperties
  externalIdentifier?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  jobAssignmentDefaults: JobAssignmentDefaults
  jobColor: Scalars['String']['output']
  jobTagAssignments: Array<JobTagAssignment>
  modifiedTimestamp?: Maybe<Scalars['DateTime']['output']>
  title: Scalars['String']['output']
  version: Scalars['Int']['output']
}

export type JobsBatchRequest = {
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>
  jobIds: Array<Scalars['ID']['input']>
}

export type JobsBatchResponse =
  | JobError
  | JobsBatchSizeError
  | JobsNotFoundBatchError
  | JobsV2
  | NoJobIdsProvidedBatchError
  | UnauthorizedAccessError

export type JobsBatchSizeError = Errors & {
  __typename: 'JobsBatchSizeError'
  message: Scalars['String']['output']
}

export type JobsFilterInput = {
  locationGroupIds?: InputMaybe<Array<Scalars['ID']['input']>>
  title?: InputMaybe<Scalars['String']['input']>
}

export type JobsFilterMissingError = Errors & {
  __typename: 'JobsFilterMissingError'
  message: Scalars['String']['output']
}

export type JobsLegacyBatchResponse =
  | JobError
  | JobMappings
  | JobsBatchSizeError
  | JobsNotFoundBatchError
  | NoJobIdsProvidedBatchError
  | UnauthorizedAccessError

export type JobsNotFoundBatchError = Errors & {
  __typename: 'JobsNotFoundBatchError'
  message: Scalars['String']['output']
}

export type JobsPaginatedResult = {
  __typename: 'JobsPaginatedResult'
  currentPageToken?: Maybe<Scalars['String']['output']>
  jobs: Array<JobV2>
  nextPageToken?: Maybe<Scalars['String']['output']>
  totalCount: Scalars['Int']['output']
}

export type JobsPaginationInput = {
  pageSize?: InputMaybe<Scalars['Int']['input']>
  pageToken?: InputMaybe<Scalars['String']['input']>
}

export type JobsRepositoryDependencyFailure = ShiftError & {
  __typename: 'JobsRepositoryDependencyFailure'
  message?: Maybe<Scalars['String']['output']>
}

export type JobsResponse =
  | CorruptPageTokenError
  | JobError
  | JobsFilterMissingError
  | JobsPaginatedResult
  | UnauthorizedAccessError

/** A LocationGroup with an associated ID. */
export type JobsV2 = {
  __typename: 'JobsV2'
  jobs: Array<JobV2>
}

export type KioskConfig = {
  __typename: 'KioskConfig'
  brandingColor: Scalars['String']['output']
  cashPaymentEnabled: Scalars['Boolean']['output']
  cashPaymentFireBeforePaymentPreference: Scalars['Boolean']['output']
  cashPaymentInstructionCopy?: Maybe<Scalars['String']['output']>
  dineInDiningOptionId?: Maybe<Scalars['ID']['output']>
  dineInKioskCustomerMessageSettingsId?: Maybe<Scalars['ID']['output']>
  diningOptionMode?: Maybe<DiningOptionMode>
  displayTheme: DisplayTheme
  id: Scalars['ID']['output']
  imagesEnabled: Scalars['Boolean']['output']
  kioskGiftCardsEnabled: Scalars['Boolean']['output']
  kioskServerGuid?: Maybe<Scalars['ID']['output']>
  lastUpdated: Scalars['String']['output']
  logoMode: LogoMode
  loyaltyPromptMode: LoyaltyPromptMode
  orderBackgroundImageHeightWidthRatio?: Maybe<Scalars['Float']['output']>
  orderBackgroundImageImagePath?: Maybe<Scalars['String']['output']>
  serviceChargesEnabled: Scalars['Boolean']['output']
  shouldHideLogo: Scalars['Boolean']['output']
  shouldHideOutOfStockItems: Scalars['Boolean']['output']
  shouldShowItemDescription: Scalars['Boolean']['output']
  shouldShowNewKioskExperience: Scalars['Boolean']['output']
  shouldShowOrderNumberOnConfirmationScreen: Scalars['Boolean']['output']
  shouldShowTippingButtons?: Maybe<Scalars['Boolean']['output']>
  shouldUseMenuItemName: Scalars['Boolean']['output']
  smallMenuCardsEnabled: Scalars['Boolean']['output']
  specialRequestsEnabled: Scalars['Boolean']['output']
  specialRequestsInstructionCopy?: Maybe<Scalars['String']['output']>
  takeOutDiningOptionId?: Maybe<Scalars['ID']['output']>
  takeOutKioskCustomerMessageSettingsId?: Maybe<Scalars['ID']['output']>
  textColor: Scalars['String']['output']
  tipPercentage1?: Maybe<Scalars['Int']['output']>
  tipPercentage2?: Maybe<Scalars['Int']['output']>
  tipPercentage3?: Maybe<Scalars['Int']['output']>
  upsellsEnabled: Scalars['Boolean']['output']
  voidReasonId?: Maybe<Scalars['ID']['output']>
  welcomeImageHeightWidthRatio?: Maybe<Scalars['Float']['output']>
  welcomeImageImagePath?: Maybe<Scalars['String']['output']>
  welcomeScreenMode: WelcomeScreenMode
}

export type KioskConfigServiceCharge = {
  __typename: 'KioskConfigServiceCharge'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  selected: Scalars['Boolean']['output']
}

export type KioskCustomerMessageSettings = {
  __typename: 'KioskCustomerMessageSettings'
  enableCustomMessage: Scalars['Boolean']['output']
  fulfillmentText?: Maybe<Scalars['String']['output']>
  fulfillmentTextEnabled: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  inputNumberText?: Maybe<Scalars['String']['output']>
  inputNumberTextEnabled: Scalars['Boolean']['output']
  mode: MessageOptionMode
  noInputText?: Maybe<Scalars['String']['output']>
  noInputTextEnabled: Scalars['Boolean']['output']
  requiredGuestInformation: Scalars['Boolean']['output']
  requiredGuestPhoneNumber: Scalars['Boolean']['output']
}

export type KioskDiningOption = {
  __typename: 'KioskDiningOption'
  behavior: Scalars['String']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type KioskLoyalty = {
  __typename: 'KioskLoyalty'
  id: Scalars['ID']['output']
  isEnabled: Scalars['Boolean']['output']
}

export type KioskMutation = {
  __typename: 'KioskMutation'
  updateConfig: KioskConfig
  updateCustomerMessageSettings: Array<KioskCustomerMessageSettings>
  updateServiceCharges: Array<KioskConfigServiceCharge>
  updateUpsellMenuItems: Array<KioskUpsellMenuItem>
}

export type KioskMutationUpdateConfigArgs = {
  input: UpdateKioskConfigInput
}

export type KioskMutationUpdateCustomerMessageSettingsArgs = {
  input: Array<UpdateKioskCustomerMessageSettingsInput>
}

export type KioskMutationUpdateServiceChargesArgs = {
  input: Array<UpdateKioskConfigServiceChargeInput>
}

export type KioskMutationUpdateUpsellMenuItemsArgs = {
  input: Array<UpdateKioskUpsellMenuItemInput>
}

export type KioskQuery = {
  __typename: 'KioskQuery'
  config: KioskConfig
  customerMessageSettings?: Maybe<Array<Maybe<KioskCustomerMessageSettings>>>
  diningOptions: Array<KioskDiningOption>
  loyalty: KioskLoyalty
  searchMenuItems: Array<KioskUpsellMenuItem>
  serviceCharges: Array<KioskConfigServiceCharge>
  upsellMenuItems: Array<KioskUpsellMenuItem>
  voidReasons: Array<KioskVoidReason>
}

export type KioskQuerySearchMenuItemsArgs = {
  input: KioskUpsellMenuItemSearchQuery
}

export type KioskUpsellMenuItem = {
  __typename: 'KioskUpsellMenuItem'
  groupGuid: Scalars['ID']['output']
  groupName: Scalars['String']['output']
  guid: Scalars['String']['output']
  name: Scalars['String']['output']
}

export type KioskUpsellMenuItemSearchQuery = {
  searchQuery: Scalars['String']['input']
}

export type KioskVoidReason = {
  __typename: 'KioskVoidReason'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type KitchenCapacityHealthStatus = {
  __typename: 'KitchenCapacityHealthStatus'
  details?: Maybe<Scalars['String']['output']>
  duration?: Maybe<Scalars['Int']['output']>
  error?: Maybe<Scalars['String']['output']>
  healthy?: Maybe<Scalars['Boolean']['output']>
  message?: Maybe<Scalars['String']['output']>
  time?: Maybe<Scalars['String']['output']>
  timestamp?: Maybe<Scalars['String']['output']>
}

export type KitchenVersionResolution =
  /** Returns all versions of the config */
  | 'ALL'
  /** Returns the highest resolved version of the config that is a descendant or ancestor of targetRestaurantSet */
  | 'HIGHEST_RESTAURANT_SET'

/** Labor cost */
export type LaborCost = {
  __typename: 'LaborCost'
  /** Labor cost metrics grouped by specified dimension */
  grouped: Array<GroupedLaborCost>
  /** Hourly labor cost */
  hourly: Array<MoneyMetric>
  /** Total labor cost */
  totals: MoneyMetric
}

/** Labor cost */
export type LaborCostGroupedArgs = {
  by: LaborGroupingDimension
}

/** Labor cost as percentage of net sales */
export type LaborCostAsPercentageOfNetSales = {
  __typename: 'LaborCostAsPercentageOfNetSales'
  /** Hourly labor cost as percentage of net sales */
  hourly: Array<PercentageMetric>
  /** Total labor cost as percentage of net sales */
  totals: PercentageMetric
}

/** A union of the labor cost group entities that can be returned */
export type LaborCostGroupEntity = Job | RestaurantUser

/** A filter for Labor metrics */
export type LaborFilter = {
  dimension: LaborGroupingDimension
  /** The identifier of the entity to filter by; either a RestaurantUser or Job guid */
  identifier: Scalars['ID']['input']
}

/** The grouping dimensions available for labor metrics */
export type LaborGroupingDimension = 'EMPLOYEE' | 'JOB_TITLE'

/** Metrics for labor */
export type LaborMetrics = {
  __typename: 'LaborMetrics'
  /** Labor cost with optional filter, i.e., the total amount of money spent on labor, excluding salaried employees */
  laborCost?: Maybe<LaborCost>
  /** Labor cost as percentage of net sales, i.e., the total amount of money spent on labor as a percentage of net sales */
  laborCostAsPercentageOfNetSales?: Maybe<LaborCostAsPercentageOfNetSales>
  /** Net sales per labor hour, i.e., the total net sales divided by the total labor hours worked */
  netSalesPerLaborHour?: Maybe<NetSalesPerLaborHour>
}

/** Metrics for labor */
export type LaborMetricsLaborCostArgs = {
  filter?: InputMaybe<LaborFilter>
}

export type LeaveOfAbsenceEmploymentStatus = EmploymentStatus & {
  __typename: 'LeaveOfAbsenceEmploymentStatus'
  adjustedHireDate?: Maybe<Scalars['Date']['output']>
  /** @deprecated use employee.hireDate at the top level instead */
  hireDate?: Maybe<Scalars['String']['output']>
  leaveType?: Maybe<Scalars['String']['output']>
  scheduledLeave?: Maybe<DateRange>
}

/**  Legacy customer user authorization criteria which needs to be met in order to evaluate if a customer user has access to a target  */
export type LegacyCustomerAuthorizationInput = {
  checkType: PermissionsCheckType
  permissionBits: Array<Scalars['PermissionBit']['input']>
}

export type LegacyJobRequest = {
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>
  legacyJobId: Scalars['ID']['input']
  productType: ProductType
}

export type LegacyJobsBatchRequest = {
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>
  /**  Legacy Job IDs has a hard limit of 100  */
  legacyJobIds: Array<Scalars['ID']['input']>
  productType: ProductType
}

export type LoadShiftsFailure = ShiftError & {
  __typename: 'LoadShiftsFailure'
  message?: Maybe<Scalars['String']['output']>
}

export type LoanApplicationForCustomer = {
  __typename: 'LoanApplicationForCustomer'
  applicationStage: Scalars['String']['output']
  ccHoldbackRate: Scalars['Float']['output']
  id: Scalars['ID']['output']
  loanAmount: Money
  metadata?: Maybe<ApplicationMetadata>
  preapprovedLoanAmount?: Maybe<Money>
  repaymentAmount: Money
  targetMaturityInDays: Scalars['Int']['output']
}

export type LocalTimeRange = {
  __typename: 'LocalTimeRange'
  end: Scalars['LocalTime']['output']
  start: Scalars['LocalTime']['output']
}

export type LocalTimeRangeInput = {
  end: Scalars['LocalTime']['input']
  start: Scalars['LocalTime']['input']
}

export type LocateShiftFailure = ShiftError & {
  __typename: 'LocateShiftFailure'
  message?: Maybe<Scalars['String']['output']>
}

export type LocationGroup = {
  __typename: 'LocationGroup'
  id: Scalars['ID']['output']
}

export type LocationGroupsInput = {
  groupIds: Array<Scalars['ID']['input']>
  locationId: Scalars['ID']['input']
}

export type LocationSetupInformation = {
  __typename: 'LocationSetupInformation'
  /** The date the location intends to begin processing orders (ISO 8601 format) */
  anticipatedPosGoLiveDate?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
}

export type LocationSpecificPrice = {
  __typename: 'LocationSpecificPrice'
  /** @deprecated use pricing.basePrice */
  basePrice?: Maybe<Money>
  pricing?: Maybe<MenuItemPricing>
  /** @deprecated use pricing.pricingStrategy */
  pricingStrategy: MenuItemPricingStrategy
  target: Scalars['ID']['output']
  versionId: Scalars['ID']['output']
}

export type LogbookCategory = {
  __typename: 'LogbookCategory'
  color: Scalars['String']['output']
  colorType: LogbookCategoryColorType
  id: Scalars['ID']['output']
  isDefault: Scalars['Boolean']['output']
  name: Scalars['String']['output']
}

export type LogbookCategoryColorType =
  | 'BRAND_0'
  | 'ERROR_0'
  | 'GRASS_0'
  | 'GRAY_25'
  | 'LAVENDER_0'
  | 'ORCHID_0'
  | 'PRIMARY_BLUE_0'
  | 'SKY_0'
  | 'WARNING_0'

export type LogbookContent = {
  __typename: 'LogbookContent'
  contentAsMarkdown: Scalars['String']['output']
  /**  The content as sequential blocks that allows clients to construct the message dynamically  */
  contentBlocks: Array<LogbookContentBlock>
  isEdited: Scalars['Boolean']['output']
  /** Tags that appear in the `contentAsMarkdown` field */
  tags?: Maybe<Array<LogbookContentTag>>
}

export type LogbookContentBlock = {
  /**  A string to display should the client not understand the type of `LogbookContentTag` returned  */
  content: Scalars['String']['output']
}

export type LogbookContentBlockInput = {
  content: Scalars['String']['input']
  /** Optional reference to the type */
  id?: InputMaybe<Scalars['ID']['input']>
  type: LogbookContentBlockType
}

export type LogbookContentBlockType = 'TEXT' | 'USER'

export type LogbookContentInput = {
  contentBlocks: Array<LogbookContentBlockInput>
}

export type LogbookContentTag = {
  /**  A string to display should the client not understand the type of `LogbookContentTag` returned  */
  content: Scalars['String']['output']
  tag: Scalars['String']['output']
}

export type LogbookEntry = {
  __typename: 'LogbookEntry'
  businessDay: Scalars['Date']['output']
  category: LogbookCategory
  content: LogbookContent
  entryTime: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  numberOfReplies: Scalars['Int']['output']
  priority: LogbookEntryPriority
  replies: Array<LogbookEntryReply>
  source: LogbookEntrySource
}

export type LogbookEntryPriority = 'HIGH' | 'LOW'

export type LogbookEntryReply = {
  __typename: 'LogbookEntryReply'
  content: LogbookContent
  entryTime: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  source: LogbookEntrySource
}

export type LogbookEntrySource = User

export type LogbookMutation = {
  __typename: 'LogbookMutation'
  deleteLogbookEntry?: Maybe<LogbookEntry>
  deleteLogbookEntryReply?: Maybe<LogbookEntryReply>
  submitLogbookEntry?: Maybe<LogbookEntry>
  submitLogbookEntryReply?: Maybe<LogbookEntryReply>
  updateLogbookEntry?: Maybe<LogbookEntry>
  updateLogbookEntryReply?: Maybe<LogbookEntryReply>
}

export type LogbookMutationDeleteLogbookEntryArgs = {
  entry: DeleteLogbookEntryInput
}

export type LogbookMutationDeleteLogbookEntryReplyArgs = {
  reply: DeleteLogbookEntryReplyInput
}

export type LogbookMutationSubmitLogbookEntryArgs = {
  entry: SubmitLogbookEntryInput
}

export type LogbookMutationSubmitLogbookEntryReplyArgs = {
  reply: SubmitLogbookEntryReplyInput
}

export type LogbookMutationUpdateLogbookEntryArgs = {
  entry: UpdateLogbookEntryInput
}

export type LogbookMutationUpdateLogbookEntryReplyArgs = {
  reply: UpdateLogbookEntryReplyInput
}

export type LogbookQuery = {
  __typename: 'LogbookQuery'
  fetchLogbookCategories?: Maybe<Array<LogbookCategory>>
  fetchLogbookEntriesByBusinessDay?: Maybe<Array<LogbookEntry>>
  fetchLogbookEntry?: Maybe<LogbookEntry>
}

export type LogbookQueryFetchLogbookCategoriesArgs = {
  managementSetId: Scalars['ID']['input']
}

export type LogbookQueryFetchLogbookEntriesByBusinessDayArgs = {
  businessDay: Scalars['Date']['input']
  locationID: Scalars['ID']['input']
}

export type LogbookQueryFetchLogbookEntryArgs = {
  id: Scalars['ID']['input']
  locationID: Scalars['ID']['input']
}

export type LogbookTextContentBlock = LogbookContentBlock & {
  __typename: 'LogbookTextContentBlock'
  content: Scalars['String']['output']
}

export type LogbookUserContentBlock = LogbookContentBlock & {
  __typename: 'LogbookUserContentBlock'
  content: Scalars['String']['output']
  user: User
}

export type LogbookUserTag = LogbookContentTag & {
  __typename: 'LogbookUserTag'
  content: Scalars['String']['output']
  tag: Scalars['String']['output']
  user: User
}

export type LogoMode = 'CIRCLE' | 'RECTANGLE'

export type LoyaltyPromptMode = 'ON_CHECKOUT' | 'ON_START_ORDER'

export type ManagementGroup = {
  __typename: 'ManagementGroup'
  /**  Descendant Locations (all locations all the way down) with pagination and count support  */
  descendantLocations: SavedRestaurantLocationConnection
  description?: Maybe<Scalars['String']['output']>
  /** The identifier provided by Toast */
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type ManagementGroupDescendantLocationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
}

export type ManagementSet = {
  __typename: 'ManagementSet'
  /** The identifier provided by Toast */
  id: Scalars['ID']['output']
}

export type ManagerClockOutResult =
  | ClockOutFailure
  | FinishedEmployeeShift
  | InProgressEmployeeShift
  | InvalidUpdatedTime
  | LocateShiftFailure
  | MissingTimeClock
  | RestaurantRepositoryDependencyFailure
  | SaveFailure
  | ShiftAlreadyClockedOut
  | ShiftNotFound
  | Unauthorized

/**  Business market segment  */
export type MarketSegment =
  /**  Enterprise  */
  | 'ENT'
  /**  Mid Market  */
  | 'MM'
  /**  Regional Mid Market  */
  | 'RMM'
  /**  Small and Medium Business  */
  | 'SMB'

/**  A marketing campaign  */
export type MarketingCampaign = {
  __typename: 'MarketingCampaign'
  channel: MarketingCampaignChannel
  customProperties?: Maybe<MarketingCampaignCustomProperties>
  externalReferenceUrl?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  status: MarketingCampaignStatus
  trigger?: Maybe<MarketingCampaignTrigger>
}

/**  Available channels for a marketing campaign  */
export type MarketingCampaignChannel =
  | 'EMAIL'
  | 'FACEBOOK'
  | 'INSTAGRAM'
  | 'SMS'

/**  Custom properties for a marketing campaign  */
export type MarketingCampaignCustomProperties = {
  __typename: 'MarketingCampaignCustomProperties'
  recommendationName?: Maybe<Scalars['String']['output']>
}

/**  a filter for marketing campaigns  */
export type MarketingCampaignFilterInput = {
  campaignStatus?: InputMaybe<Array<MarketingCampaignStatus>>
  channel?: InputMaybe<Array<MarketingCampaignChannel>>
  endDate?: InputMaybe<Scalars['DateTime']['input']>
  startDate?: InputMaybe<Scalars['DateTime']['input']>
}

/**  pagination for marketing campaigns  */
export type MarketingCampaignPaginationInput = {
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
}

/**  Possible statuses for a marketing campaign  */
export type MarketingCampaignStatus =
  | 'CANCELLED'
  | 'DRAFT'
  | 'SCHEDULED'
  | 'SENT'

/**  The trigger for this campaign to be sent  */
export type MarketingCampaignTrigger = {
  __typename: 'MarketingCampaignTrigger'
  name: Scalars['String']['output']
  scheduledFor?: Maybe<Scalars['DateTime']['output']>
  type: MarketingCampaignTriggerType
}

/**  Possible trigger types for a marketing campaign  */
export type MarketingCampaignTriggerType = 'EVENT' | 'SCHEDULE'

/**  Namespace for marketing related queries, shared with toast-marketing  */
export type MarketingQuery = {
  __typename: 'MarketingQuery'
  emailCampaign: EmailMarketingCampaign
  emailCampaigns: Array<EmailMarketingCampaign>
  marketingCampaign: MarketingCampaign
  marketingCampaigns: Array<MarketingCampaign>
}

/**  Namespace for marketing related queries, shared with toast-marketing  */
export type MarketingQueryEmailCampaignArgs = {
  id: Scalars['ID']['input']
}

/**  Namespace for marketing related queries, shared with toast-marketing  */
export type MarketingQueryEmailCampaignsArgs = {
  filter?: InputMaybe<EmailMarketingCampaignFilterInput>
  pagination?: InputMaybe<EmailMarketingCampaignPaginationInput>
}

/**  Namespace for marketing related queries, shared with toast-marketing  */
export type MarketingQueryMarketingCampaignArgs = {
  id: Scalars['ID']['input']
}

/**  Namespace for marketing related queries, shared with toast-marketing  */
export type MarketingQueryMarketingCampaignsArgs = {
  filter?: InputMaybe<MarketingCampaignFilterInput>
  pagination?: InputMaybe<MarketingCampaignPaginationInput>
}

export type MemberGuestBookData = {
  __typename: 'MemberGuestBookData'
  averageSpend: Scalars['Float']['output']
  averageTip: Scalars['Float']['output']
  averageTipPercentage: Scalars['Float']['output']
  firstVisitDate?: Maybe<Scalars['Date']['output']>
  guestGuid: Scalars['String']['output']
  lastVisitDate?: Maybe<Scalars['Date']['output']>
  sumTotalCheckSpend: Scalars['Float']['output']
  sumTotalTipSpend: Scalars['Float']['output']
  topMenuItems: Array<Scalars['String']['output']>
  totalVisits: Scalars['Int']['output']
}

export type Membership = {
  __typename: 'Membership'
  accountNumber: Scalars['String']['output']
  address?: Maybe<MembershipAddress>
  archived: Scalars['Boolean']['output']
  createdDate?: Maybe<Scalars['Date']['output']>
  customerNumber?: Maybe<Scalars['String']['output']>
  email: Scalars['String']['output']
  firstName: Scalars['String']['output']
  guestbook?: Maybe<MemberGuestBookData>
  guid: Scalars['String']['output']
  lastName?: Maybe<Scalars['String']['output']>
  lastTransactionDate?: Maybe<Scalars['Date']['output']>
  outstandingBalance: Scalars['String']['output']
  phone?: Maybe<Scalars['String']['output']>
}

export type MembershipAddress = {
  __typename: 'MembershipAddress'
  address1?: Maybe<Scalars['String']['output']>
  address2?: Maybe<Scalars['String']['output']>
  city?: Maybe<Scalars['String']['output']>
  notes?: Maybe<Scalars['String']['output']>
  state?: Maybe<Scalars['String']['output']>
  zip?: Maybe<Scalars['String']['output']>
}

export type MembershipAddressInput = {
  address1: Scalars['String']['input']
  address2?: InputMaybe<Scalars['String']['input']>
  city: Scalars['String']['input']
  notes?: InputMaybe<Scalars['String']['input']>
  state: Scalars['String']['input']
  zip: Scalars['String']['input']
}

export type MembershipInvoice = {
  __typename: 'MembershipInvoice'
  createdDate: Scalars['DateTime']['output']
  dueDate?: Maybe<Scalars['Date']['output']>
  guid: Scalars['String']['output']
  number?: Maybe<Scalars['String']['output']>
  status: MembershipInvoiceStatus
}

export type MembershipInvoiceStatus =
  | 'DELETED'
  | 'DRAFT'
  | 'FORCE_PAID'
  | 'OPEN'
  | 'PAID'
  | 'REFUNDED'
  | 'UNCOLLECTIBLE'
  | 'VOID'

export type MembershipInvoicesInput = {
  ascending?: InputMaybe<Scalars['Boolean']['input']>
  guid: Scalars['String']['input']
  offset?: InputMaybe<Scalars['Int']['input']>
  size?: InputMaybe<Scalars['Int']['input']>
  sortBy?: InputMaybe<MembershipInvoicesSortColumn>
}

export type MembershipInvoicesResponse = {
  __typename: 'MembershipInvoicesResponse'
  invoices: Array<MembershipInvoice>
  totalCount: Scalars['Int']['output']
}

export type MembershipInvoicesSortColumn =
  | 'CREATED_DATE'
  | 'INVOICE_NUMBER'
  | 'PAYMENT_DUE_DATE'
  | 'TOTAL'

export type MembershipTransaction = {
  __typename: 'MembershipTransaction'
  amount: Scalars['Float']['output']
  balance: Scalars['Float']['output']
  transactionDate: Scalars['DateTime']['output']
  transactionId: Scalars['String']['output']
  transactionType: MembershipTransactionType
}

export type MembershipTransactionType =
  | 'ADD_VALUE'
  | 'ADJUST_BALANCE'
  | 'OTHER'
  | 'REDEMPTION'
  | 'VOID_ADD_VALUE'
  | 'VOID_REDEMPTION'

export type MembershipTransactionsInput = {
  ascending?: InputMaybe<Scalars['Boolean']['input']>
  guid: Scalars['String']['input']
  offset?: InputMaybe<Scalars['Int']['input']>
  size?: InputMaybe<Scalars['Int']['input']>
  sortBy?: InputMaybe<MembershipTransactionsSortColumn>
}

export type MembershipTransactionsResponse = {
  __typename: 'MembershipTransactionsResponse'
  totalCount: Scalars['Int']['output']
  transactions: Array<MembershipTransaction>
}

export type MembershipTransactionsSortColumn = 'AMOUNT' | 'BALANCE' | 'DATE'

export type MembershipsFilters = {
  maxLastTransactionDate?: InputMaybe<Scalars['DateTime']['input']>
  maxOutstandingBalance?: InputMaybe<Scalars['Float']['input']>
  minLastTransactionDate?: InputMaybe<Scalars['DateTime']['input']>
  minOutstandingBalance?: InputMaybe<Scalars['Float']['input']>
}

export type MembershipsInput = {
  archived?: InputMaybe<Scalars['Boolean']['input']>
  ascending?: InputMaybe<Scalars['Boolean']['input']>
  filters?: InputMaybe<MembershipsFilters>
  offset?: InputMaybe<Scalars['Int']['input']>
  search?: InputMaybe<Scalars['String']['input']>
  size?: InputMaybe<Scalars['Int']['input']>
  sortBy?: InputMaybe<MembershipsSortColumn>
}

export type MembershipsMutation = {
  __typename: 'MembershipsMutation'
  archiveMembership: ArchiveMembershipResponse
  createMembership: CreateMembershipResult
  restoreMembership: RestoreMembershipResponse
  updateMembership: UpdateMembershipResult
  updateSettings: MembershipsSettingsResponse
}

export type MembershipsMutationArchiveMembershipArgs = {
  guid: Scalars['String']['input']
}

export type MembershipsMutationCreateMembershipArgs = {
  createMembershipInput: CreateMembershipInput
}

export type MembershipsMutationRestoreMembershipArgs = {
  guid: Scalars['String']['input']
}

export type MembershipsMutationUpdateMembershipArgs = {
  updateMembershipInput: UpdateMembershipInput
}

export type MembershipsMutationUpdateSettingsArgs = {
  membershipsSettingsInput: MembershipsSettingsInput
}

export type MembershipsQuery = {
  __typename: 'MembershipsQuery'
  invoices: MembershipInvoicesResponse
  membership?: Maybe<Membership>
  memberships: MembershipsResponse
  membershipsSummary: MembershipsSummary
  settings?: Maybe<MembershipsSettings>
  transactions: MembershipTransactionsResponse
}

export type MembershipsQueryInvoicesArgs = {
  membershipInvoicesInput: MembershipInvoicesInput
}

export type MembershipsQueryMembershipArgs = {
  guid: Scalars['String']['input']
}

export type MembershipsQueryMembershipsArgs = {
  membershipsInput: MembershipsInput
}

export type MembershipsQueryTransactionsArgs = {
  membershipTransactionsInput: MembershipTransactionsInput
}

export type MembershipsResponse = {
  __typename: 'MembershipsResponse'
  memberships: Array<Membership>
  totalCount: Scalars['Int']['output']
}

export type MembershipsSettings = {
  __typename: 'MembershipsSettings'
  houseAccountEnabled: Scalars['Boolean']['output']
  invoiceAutoPay: Scalars['Boolean']['output']
}

export type MembershipsSettingsInput = {
  houseAccountEnabled: Scalars['Boolean']['input']
  invoiceAutoPay: Scalars['Boolean']['input']
}

export type MembershipsSettingsResponse = {
  __typename: 'MembershipsSettingsResponse'
  code: Scalars['Int']['output']
  message?: Maybe<Scalars['String']['output']>
  success: Scalars['Boolean']['output']
}

export type MembershipsSortColumn =
  | 'ACCOUNT_NUMBER'
  | 'CUSTOMER_NUMBER'
  | 'EMAIL'
  | 'GUID'
  | 'LAST_TRANSACTION_DATE'
  | 'NAME'
  | 'OUTSTANDING_BALANCE'
  | 'PHONE'

export type MembershipsSummary = {
  __typename: 'MembershipsSummary'
  totalMemberships: Scalars['Int']['output']
  totalOutstandingBalance: Scalars['String']['output']
}

export type Menu = MenuEntity & {
  __typename: 'Menu'
  channelMenuEntities?: Maybe<Array<ChannelMenuEntity>>
  /** Shared MenuEntity fields */
  id: Scalars['ID']['output']
  menuGroupMultiLocationIds?: Maybe<Array<Scalars['ID']['output']>>
  menuGroups?: Maybe<Array<MenuGroup>>
  multiLocationId: Scalars['ID']['output']
  name: Scalars['String']['output']
  ordinal?: Maybe<Scalars['Int']['output']>
  owner: Scalars['ID']['output']
  ownerRestaurantSet?: Maybe<RestaurantSet>
  pointOfSale: MenuEntityPointOfSale
  preparation: SavedMenuPreparation
  salesCategoryMultiLocationId?: Maybe<Scalars['ID']['output']>
  target: Scalars['ID']['output']
  targetRestaurantSet: RestaurantSet
  taxes: MenuEntityTaxes
  versionId: Scalars['ID']['output']
  /** Menu-specific fields */
  visibility: MenuEntityVisibility
}

export type MenuAvailabilitySchedule = {
  availableAllDays?: InputMaybe<Scalars['Boolean']['input']>
  availableAllTimes?: InputMaybe<Scalars['Boolean']['input']>
  days?: InputMaybe<Array<DayOfWeek>>
  timeRanges?: InputMaybe<Array<MenuAvailabilityTimeRange>>
}

export type MenuAvailabilityTimeRange = {
  end?: InputMaybe<Scalars['String']['input']>
  start?: InputMaybe<Scalars['String']['input']>
}

export type MenuEntitiesInput = {
  entityType: MenuEntityType
  multiLocationIds?: InputMaybe<Array<Scalars['ID']['input']>>
  targets?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type MenuEntity = {
  /** id is the same as versionId, but id is a standard field in Toast GraphQL schemas */
  id: Scalars['ID']['output']
  /** id representing the entity across all versions */
  multiLocationId: Scalars['ID']['output']
  /** the name of the entity */
  name: Scalars['String']['output']
  /**  guid representing the restaurant set that owns this entity ("editable by")  */
  owner: Scalars['ID']['output']
  /** restaurant set that owns this entity */
  ownerRestaurantSet?: Maybe<RestaurantSet>
  /**
   *  guid representing the restaurant set at which this entity is targeted ("appears on my menu")
   * @deprecated Use restaurantSet.id instead
   */
  target: Scalars['ID']['output']
  /** restaurant set this entity is targeted */
  targetRestaurantSet: RestaurantSet
  /** guid representing the specific version of this entity */
  versionId: Scalars['ID']['output']
}

export type MenuEntityAlcohol = {
  __typename: 'MenuEntityAlcohol'
  containsAlcohol?: Maybe<ContainsAlcohol>
}

export type MenuEntityAlcoholInput = {
  containsAlcohol?: InputMaybe<ContainsAlcohol>
}

export type MenuEntityAncestorsQuery = {
  /**  Optionally provide a list of selected restaurant sets  */
  targets?: InputMaybe<Array<Scalars['ID']['input']>>
  /**  Must provide versionIds of entities  */
  versionIds: Array<Scalars['ID']['input']>
}

export type MenuEntityContentAdvisories = {
  __typename: 'MenuEntityContentAdvisories'
  alcohol: MenuEntityAlcohol
}

/** Indicates whether or not the item is tax exempt when ordered for takeout. UNSPECIFIED indicates no option has been selected */
export type MenuEntityDiningOptionTax =
  | 'NO_EFFECT'
  | 'TAX_EXEMPT_TAKEOUT'
  | 'UNSPECIFIED'

export type MenuEntityImage = {
  __typename: 'MenuEntityImage'
  /** url path where this image is stored */
  imagePath?: Maybe<Scalars['String']['output']>
}

export type MenuEntityPointOfSale = {
  __typename: 'MenuEntityPointOfSale'
  barcodeEmbeddedAmountType?: Maybe<Scalars['String']['output']>
  color?: Maybe<Scalars['Int']['output']>
  kdsColor?: Maybe<Scalars['Int']['output']>
  kitchenName?: Maybe<Scalars['String']['output']>
  shortName?: Maybe<Scalars['String']['output']>
  sku?: Maybe<Scalars['String']['output']>
}

export type MenuEntityQuery = {
  includeArchived?: InputMaybe<Scalars['Boolean']['input']>
  includeOptionOnly?: InputMaybe<Scalars['Boolean']['input']>
  multiLocationIds?: InputMaybe<Array<Scalars['ID']['input']>>
  name?: InputMaybe<Scalars['String']['input']>
  pageSize?: InputMaybe<Scalars['Int']['input']>
  pageToken?: InputMaybe<Scalars['ID']['input']>
  targets?: InputMaybe<Array<Scalars['ID']['input']>>
  versionResolution?: InputMaybe<MenusVersionResolution>
  versionResolutionTarget?: InputMaybe<Scalars['String']['input']>
}

export type MenuEntityReorder = {
  /** The entity we are moving */
  entityMultiLocationId: Scalars['ID']['input']
  /** The parent entity versionId */
  parentEntityVersionId: Scalars['ID']['input']
  /** The entity that it is being moved after, or null if it is being moved to the beginning of the list */
  previousEntityMultiLocationId?: InputMaybe<Scalars['ID']['input']>
}

export type MenuEntityReports = {
  __typename: 'MenuEntityReports'
  inheritSalesCategory: Scalars['Boolean']['output']
  plu?: Maybe<Scalars['String']['output']>
  salesCategory?: Maybe<Scalars['String']['output']>
}

/** Indicates whether tax is included in the items price. UNSPECIFIED indicates no option has been selected */
export type MenuEntityTaxInclusionOption =
  | 'SMART_TAX'
  | 'TAX_INCLUDED'
  | 'TAX_NOT_INCLUDED'
  | 'UNSPECIFIED'

export type MenuEntityTaxes = {
  __typename: 'MenuEntityTaxes'
  diningOptionTaxation: MenuEntityDiningOptionTax
  inheritDiningOptionTax: Scalars['Boolean']['output']
  inheritTaxInclusive: Scalars['Boolean']['output']
  inheritTaxRates: Scalars['Boolean']['output']
  taxInclusionOption: MenuEntityTaxInclusionOption
  taxRateMultiLocationIds: Array<Scalars['ID']['output']>
}

export type MenuEntityType =
  | 'MENU'
  | 'MENU_GROUP'
  | 'MENU_ITEM'
  | 'MODIFIER'
  | 'MODIFIER_GROUP'

export type MenuEntityVisibility = {
  __typename: 'MenuEntityVisibility'
  kioskVisible: Scalars['Boolean']['output']
  onlineOrderingVisible: Scalars['Boolean']['output']
  orderingPartnerVisible: Scalars['Boolean']['output']
  posVisible: Scalars['Boolean']['output']
}

export type MenuGroup = MenuEntity & {
  __typename: 'MenuGroup'
  /** Entity version GUID */
  id: Scalars['ID']['output']
  inheritModifierGroups: Scalars['Boolean']['output']
  menuGroupMultiLocationIds?: Maybe<Array<Scalars['ID']['output']>>
  menuGroups?: Maybe<Array<MenuGroup>>
  menuItemMultiLocationIds?: Maybe<Array<Scalars['ID']['output']>>
  menuItems: Array<MenuItem>
  modifierGroupMultiLocationIds: Array<Scalars['ID']['output']>
  modifierGroups: Array<ModifierGroup>
  /** Multi-location Long ID */
  multiLocationId: Scalars['ID']['output']
  name: Scalars['String']['output']
  owner: Scalars['ID']['output']
  ownerRestaurantSet?: Maybe<RestaurantSet>
  pointOfSale: MenuEntityPointOfSale
  preparation: SavedMenuGroupPreparation
  reports: MenuEntityReports
  target: Scalars['ID']['output']
  targetRestaurantSet: RestaurantSet
  taxes: MenuEntityTaxes
  /** Entity version GUID */
  versionId: Scalars['ID']['output']
  visibility: MenuEntityVisibility
}

export type MenuGroupModifierGroupsArgs = {
  versionResolution?: Scalars['String']['input']
  versionResolutionTarget?: InputMaybe<Scalars['String']['input']>
}

/** Reference to a MenuItem entity */
export type MenuItem = MenuEntity & {
  __typename: 'MenuItem'
  ancestors: Array<EntityAncestorResponse>
  calories?: Maybe<Scalars['Int']['output']>
  contentAdvisories: MenuEntityContentAdvisories
  description?: Maybe<Scalars['String']['output']>
  guestCount?: Maybe<Scalars['Float']['output']>
  /** Shared MenuEntity fields */
  id: Scalars['ID']['output']
  image: MenuEntityImage
  inheritModifierGroups: Scalars['Boolean']['output']
  modifierGroupMultiLocationIds?: Maybe<Array<Scalars['ID']['output']>>
  modifierGroups: Array<ModifierGroup>
  /** Flag that identifies that this menu item is only used as a modifier */
  modifierOnly: Scalars['Boolean']['output']
  multiLocationId: Scalars['ID']['output']
  name: Scalars['String']['output']
  owner: Scalars['ID']['output']
  ownerRestaurantSet?: Maybe<RestaurantSet>
  /** Version IDs for parents of item */
  parentVersionIds?: Maybe<Array<Scalars['String']['output']>>
  pointOfSale: MenuEntityPointOfSale
  preparation: SavedMenuItemPreparation
  pricing?: Maybe<MenuItemPricing>
  reports: MenuEntityReports
  stock: Stock
  target: Scalars['ID']['output']
  targetRestaurantSet: RestaurantSet
  /** MenuItem-specific fields */
  taxes: MenuEntityTaxes
  versionId: Scalars['ID']['output']
  visibility: MenuEntityVisibility
}

/** Reference to a MenuItem entity */
export type MenuItemModifierGroupsArgs = {
  versionResolution?: Scalars['String']['input']
  versionResolutionTarget?: InputMaybe<Scalars['String']['input']>
}

export type MenuItemFeedback = {
  __typename: 'MenuItemFeedback'
  createdDate?: Maybe<Scalars['String']['output']>
  feedbackText?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  menuItem: MenuItem
  rating?: Maybe<GuestFeedbackRatingType>
  rawRating?: Maybe<GuestFeedbackRawRatingType>
  reasons: Array<Scalars['String']['output']>
  source?: Maybe<Scalars['String']['output']>
}

export type MenuItemIcon =
  | 'AllProductsIcon'
  | 'AnnouncementIcon'
  | 'BankIcon'
  | 'CartIcon'
  | 'ChefsHatIcon'
  | 'ComputerBrushIcon'
  | 'DiningIcon'
  | 'EmployeesIcon'
  | 'HardwareIcon'
  | 'HomeIcon'
  | 'IntegrationsIcon'
  | 'LookupChecksIcon'
  | 'LoyaltyIcon'
  | 'MenusIcon'
  | 'PaymentsIcon'
  | 'PayrollEmployeeIcon'
  | 'PercentageIcon'
  | 'PickUpIcon'
  | 'PlaceSettingIcon'
  | 'ReportsIcon'
  | 'ScanBarcodeIcon'
  | 'ServingTrayIcon'
  | 'SettingsIcon'
  | 'SlingLogoIcon'
  | 'SpecialRequestIcon'
  | 'ToastLogoIcon'
  | 'XtrachefLogoIcon'

export type MenuItemInventoryConnection = {
  __typename: 'MenuItemInventoryConnection'
  edges: Array<RestaurantMenuItemEdge>
  pageInfo: PageInfo
  /** @deprecated totalCount will return the total count including orphaned items. This will be removed in future versions */
  totalCount: Scalars['Int']['output']
}

export type MenuItemPlateCost = {
  __typename: 'MenuItemPlateCost'
  cost: Money
  id: Scalars['ID']['output']
  itemMultiLocationID: Scalars['ID']['output']
  source: PlateCostSource
}

export type MenuItemPricing = {
  __typename: 'MenuItemPricing'
  basePrice?: Maybe<Money>
  inheritPricing?: Maybe<Scalars['Boolean']['output']>
  locationSpecificPrices?: Maybe<Array<LocationSpecificPrice>>
  maxPrice?: Maybe<Money>
  menuSpecificPrices?: Maybe<Array<MenuSpecificPrice>>
  minPrice?: Maybe<Money>
  priceLevel?: Maybe<PriceLevel>
  priceLevels?: Maybe<Array<PriceLevelEntity>>
  pricingStrategy: MenuItemPricingStrategy
  sizePrices?: Maybe<Array<SizePrice>>
}

export type MenuItemPricingStrategy =
  | 'DEFAULT'
  | 'LOCATION_SPECIFIC_PRICE'
  | 'MENU_SPECIFIC_PRICE'
  | 'OPEN_PRICE'
  | 'PRICE_LEVEL'
  /** Base price, Fixed price (mod group only) */
  | 'SIZE_PRICE'
  | 'TIME_SPECIFIC_PRICE'
  | 'UNSPECIFIED'

export type MenuItemStockUpdate = {
  id: Scalars['ID']['input']
  quantity?: InputMaybe<Scalars['Float']['input']>
  status: StockStatus
}

export type MenuPathsQuery = {
  menuGroups: Array<Scalars['ID']['input']>
}

export type MenuReorder = {
  /** The entity we are moving */
  menuMultiLocationId: Scalars['ID']['input']
  /** The entity that it is being moved after, or null if it is being moved to the beginning of the list */
  previousMenuMultiLocationId?: InputMaybe<Scalars['ID']['input']>
  /** We will use these targets to search menus to be reordered */
  targets: Array<Scalars['ID']['input']>
  /** We will use this version resolution to search menus to be reordered */
  versionResolution: Scalars['String']['input']
}

export type MenuSpecificPrice = {
  __typename: 'MenuSpecificPrice'
  menuMultiLocationId: Scalars['ID']['output']
  menuName: Scalars['String']['output']
  pricing?: Maybe<MenuItemPricing>
}

export type MenuUpsellConfigMutation = {
  __typename: 'MenuUpsellConfigMutation'
  /** Archive a menu item upsell */
  archiveCustomMenuItemUpsell: CustomMenuItemUpsell
  /** Save a new or existing menu item upsell */
  saveCustomMenuItemUpsell: CustomMenuItemUpsell
  /** Save a batch of new or existing menu item upsells */
  saveCustomMenuItemUpsells: Array<CustomMenuItemUpsell>
}

export type MenuUpsellConfigMutationArchiveCustomMenuItemUpsellArgs = {
  input: ArchiveCustomMenuItemUpsellInput
}

export type MenuUpsellConfigMutationSaveCustomMenuItemUpsellArgs = {
  input: SaveCustomMenuItemUpsellInput
}

export type MenuUpsellConfigMutationSaveCustomMenuItemUpsellsArgs = {
  input: Array<SaveCustomMenuItemUpsellInput>
}

export type MenuUpsellConfigQuery = {
  __typename: 'MenuUpsellConfigQuery'
  customMenuItemUpsells: Array<CustomMenuItemUpsell>
}

export type MenusAncestorEntityInput = {
  entityType: MenuEntityType
  versionId: Scalars['ID']['input']
}

export type MenusAncestorQuery = {
  /**  A list of entities to query ancestors for  */
  entities: Array<MenusAncestorEntityInput>
  /**  Optionally provide a list of selected restaurant sets  */
  targets?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type MenusMoneyInput = {
  /** The amount of money */
  amount: Scalars['Decimal']['input']
  /** The currency the money is paid in */
  currency?: InputMaybe<Currency>
}

export type MenusVersionResolution =
  | 'ALL'
  | 'HIGHEST_RESTAURANT_SET'
  | 'RESTAURANT'
  | 'RESTAURANT_SETS'
  | 'RESTAURANT_SETS_AND_ANCESTORS'

export type MessageOptionMode = 'DINE_IN' | 'TAKE_OUT'

export type MgmtDailySummaryConfig = {
  __typename: 'MgmtDailySummaryConfig'
  emails: Array<Scalars['String']['output']>
  enabled: Scalars['Boolean']['output']
  scheduledTime: Scalars['String']['output']
}

export type MgmtDepositBookableConfig = {
  __typename: 'MgmtDepositBookableConfig'
  autoCancelUnpaidDeposit: Scalars['Boolean']['output']
  autoCancelUnpaidDepositTimeframe: Scalars['Int']['output']
  cancellationRefundableTimeframe: Scalars['Int']['output']
  depositPolicy?: Maybe<Scalars['String']['output']>
  enabled: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  strategy: DepositStrategy
}

export type MgmtEmailConfig = {
  __typename: 'MgmtEmailConfig'
  reservationCancel?: Maybe<MgmtEmailTemplate>
  reservationChange?: Maybe<MgmtEmailTemplate>
  reservationConfirmation?: Maybe<MgmtEmailTemplate>
}

export type MgmtEmailTemplate = {
  __typename: 'MgmtEmailTemplate'
  enabled: Scalars['Boolean']['output']
}

export type MgmtRestaurantInformation = {
  __typename: 'MgmtRestaurantInformation'
  allowJoinOnlineNoWait: Scalars['Boolean']['output']
  bookingMaxHoursInAdvance: Scalars['Int']['output']
  bookingMaxMinutesInAdvance: Scalars['Int']['output']
  bookingMinHoursInAdvance: Scalars['Int']['output']
  bookingMinMinutesInAdvance: Scalars['Int']['output']
  closeOutHour: Scalars['Int']['output']
  courses: Array<Course>
  coursingEnabled: Scalars['Boolean']['output']
  dailySummaryConfig?: Maybe<MgmtDailySummaryConfig>
  defaultServicePeriods: Array<Scalars['ID']['output']>
  depositAlternatePaymentTypeGuid?: Maybe<Scalars['ID']['output']>
  depositBookableConfigs: Array<MgmtDepositBookableConfig>
  description: Scalars['String']['output']
  diningOptions: Array<DiningOption>
  email: Scalars['String']['output']
  emailConfig?: Maybe<MgmtEmailConfig>
  externalPartners?: Maybe<ExternalPartner>
  id: Scalars['ID']['output']
  locationName: Scalars['String']['output']
  loyaltyEnabled: Scalars['Boolean']['output']
  maxPartySize: Scalars['Int']['output']
  minPartySize: Scalars['Int']['output']
  name: Scalars['String']['output']
  onlineReservationsEnabled: Scalars['Boolean']['output']
  onlineWaitlistDisableThreshold: Scalars['Int']['output']
  onlineWaitlistEnabled: Scalars['Boolean']['output']
  orderCreationEnabled: Scalars['Boolean']['output']
  phoneNumber: Scalars['String']['output']
  profilePicUrl: Scalars['String']['output']
  readOnlyMenuUrl: Scalars['String']['output']
  reservationPolicy: Scalars['String']['output']
  reservationsEnabled: Scalars['Boolean']['output']
  selectedDiningOption?: Maybe<Scalars['ID']['output']>
  sendOptLink: Scalars['Boolean']['output']
  sendStpLink: Scalars['Boolean']['output']
  serviceAreaGroups: Array<MgmtServiceAreaGroup>
  serviceAreas: Array<MgmtServiceArea>
  servicePeriodOverrides: Array<Scalars['ID']['output']>
  showWaitlistEstimate: Scalars['Boolean']['output']
  smsConfig: MgmtSmsConfig
  timeZone: Scalars['String']['output']
  toastRestaurantShortName: Scalars['String']['output']
  twoWaySmsEnabled: Scalars['Boolean']['output']
  waitlistAlgorithmType: WaitlistAlgorithmType
  waitlistAutoRemoveConfig: MgmtWaitlistAutoRemoveConfig
  waitlistEnabled: Scalars['Boolean']['output']
  waitlistEtaBuffer: Scalars['Int']['output']
  waitlistEtaTableCount: Scalars['Int']['output']
  waitlistEtaTurnTime: Scalars['Int']['output']
  waitlistMaxPartySize: Scalars['Int']['output']
  waitlistMinPartySize: Scalars['Int']['output']
  waitlistPolicy: Scalars['String']['output']
  websiteUrl: Scalars['String']['output']
}

export type MgmtRestaurantInformationResponse =
  | RestaurantInformationResponseSuccess
  | RestaurantNotFound

export type MgmtServiceArea = {
  __typename: 'MgmtServiceArea'
  defaultTurnTime: Scalars['Int']['output']
  externalName: Scalars['String']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  serviceAreaGroup?: Maybe<Scalars['ID']['output']>
  tables: Array<MgmtTable>
  turnTimes: Array<Scalars['Int']['output']>
}

export type MgmtServiceAreaGroup = {
  __typename: 'MgmtServiceAreaGroup'
  enabled: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  serviceAreas: Array<Scalars['ID']['output']>
}

export type MgmtSmsConfig = {
  __typename: 'MgmtSmsConfig'
  reservationCancellation: MgmtSmsTemplate
  reservationConfirm: MgmtSmsTemplate
  reservationNotify: MgmtSmsTemplate
  reservationReminder: MgmtSmsTemplate
  sendDeposit: MgmtSmsTemplate
  sendOpt: MgmtSmsTemplate
  sendStp: MgmtSmsTemplate
  waitlistCancellation: MgmtSmsTemplate
  waitlistConfirm: MgmtSmsTemplate
  waitlistNoShow: MgmtSmsTemplate
  waitlistNotify: MgmtSmsTemplate
}

export type MgmtSmsTemplate = {
  __typename: 'MgmtSmsTemplate'
  enabled: Scalars['Boolean']['output']
  message: Scalars['String']['output']
  receiveAutomatedMessageEnabled: Scalars['Boolean']['output']
}

export type MgmtTable = {
  __typename: 'MgmtTable'
  id: Scalars['ID']['output']
  maxCapacity: Scalars['Int']['output']
  minCapacity: Scalars['Int']['output']
  name: Scalars['String']['output']
}

export type MgmtWaitlistAutoRemoveConfig = {
  __typename: 'MgmtWaitlistAutoRemoveConfig'
  enabled: Scalars['Boolean']['output']
  gracePeriodMinutes: Scalars['Int']['output']
}

export type MinimumRepayment = {
  __typename: 'MinimumRepayment'
  amount: Money
  initiateDate: Scalars['Int']['output']
  intervalDay: Scalars['Int']['output']
  isInitiated: Scalars['Boolean']['output']
  isScheduled: Scalars['Boolean']['output']
  milestoneAmount: Money
  milestoneDate: Scalars['Int']['output']
}

export type MissingJob = ShiftError & {
  __typename: 'MissingJob'
  message?: Maybe<Scalars['String']['output']>
}

export type MissingTimeClock = ShiftError & {
  __typename: 'MissingTimeClock'
  message?: Maybe<Scalars['String']['output']>
}

export type MlmSavedConfig = {
  /** MLM ID of the saved config */
  id: Scalars['ID']['output']
  managementGroup: ManagementGroup
  variants: Array<MlmSavedConfigVariant>
}

export type MlmSavedConfigVariant = {
  field: ConfigField
  versions: Array<MlmSavedVersion>
}

export type MlmSavedVersion = {
  __typename: 'MlmSavedVersion'
  /** The version ID of the entity */
  id: Scalars['ID']['output']
  owner: RestaurantSet
  target: RestaurantSet
}

export type MlmUiOption = MlmSavedConfig & {
  __typename: 'MlmUIOption'
  /** MLM ID of the saved config */
  id: Scalars['ID']['output']
  managementGroup: ManagementGroup
  variants: Array<MlmUiOptionVariant>
}

export type MlmUiOptionVariant = MlmSavedConfigVariant & {
  __typename: 'MlmUIOptionVariant'
  additionalModifierGroups?: Maybe<AdditionalModifierGroups>
  allowDeclareNegativeTips?: Maybe<Scalars['Boolean']['output']>
  allowScheduling?: Maybe<Scalars['Boolean']['output']>
  askForPartySize?: Maybe<Scalars['Boolean']['output']>
  autoSwitchUser?: Maybe<Scalars['Boolean']['output']>
  cashCloseoutDiscrepancyMax?: Maybe<Money>
  cashCloseoutDiscrepancyWarning?: Maybe<Money>
  cashDrawerLockdown?: Maybe<Scalars['Boolean']['output']>
  closeOnPrint?: Maybe<Scalars['Boolean']['output']>
  collapseModifierPrices?: Maybe<Scalars['Boolean']['output']>
  collectCashInDrawer?: Maybe<Scalars['Boolean']['output']>
  combineItems?: Maybe<Scalars['Boolean']['output']>
  consolidateDiscounts?: Maybe<Scalars['Boolean']['output']>
  consolidateModifiers?: Maybe<Scalars['Boolean']['output']>
  declaredCashTipsMinimum?: Maybe<Scalars['Float']['output']>
  defaultsDisplayMode?: Maybe<Scalars['Boolean']['output']>
  enableModifierOrderingPriority?: Maybe<Scalars['Boolean']['output']>
  fastCash?: Maybe<Scalars['Boolean']['output']>
  field: ConfigField
  intraModifierGroupSortOrder?: Maybe<IntraModifierGroupSortOrder>
  loginAdditionalText?: Maybe<Scalars['String']['output']>
  manualEntryForScaleEnabled?: Maybe<Scalars['Boolean']['output']>
  maskPasscode?: Maybe<Scalars['Boolean']['output']>
  modifierDisplayMode?: Maybe<ModifierDisplayMode>
  mustDeclareCashTips?: Maybe<Scalars['Boolean']['output']>
  paymentUserType?: Maybe<PaymentUserTypeMode>
  printClockSlip?: Maybe<Scalars['Boolean']['output']>
  promptForDiningOption?: Maybe<Scalars['Boolean']['output']>
  promptForEditOthersTable?: Maybe<Scalars['Boolean']['output']>
  promptForTab?: Maybe<Scalars['Boolean']['output']>
  scheduledOrderMaxDays?: Maybe<Scalars['Int']['output']>
  searchPromoCode?: Maybe<Scalars['Boolean']['output']>
  sendButtons?: Maybe<Array<PosButton>>
  sendChecksAfterPaymentOnly?: Maybe<Scalars['Boolean']['output']>
  shiftReviewCollectAndTipOutMode?: Maybe<ShiftReviewCollectAndTipOutMode>
  shiftReviewRequired?: Maybe<Scalars['Boolean']['output']>
  showSentTimestamp?: Maybe<Scalars['Boolean']['output']>
  smartTax?: Maybe<Scalars['Boolean']['output']>
  ssids?: Maybe<Array<Scalars['String']['output']>>
  strictMode?: Maybe<Scalars['Boolean']['output']>
  taxOptions?: Maybe<UiOptionsTaxOptions>
  timeClockDisplayForClockInScreenEnabled?: Maybe<Scalars['Boolean']['output']>
  timeEntryRoundingScheme?: Maybe<TimeEntryRoundingScheme>
  tipOutGratuity?: Maybe<Scalars['Boolean']['output']>
  tipOutNonCash?: Maybe<Scalars['Boolean']['output']>
  tipPreTax?: Maybe<Scalars['Boolean']['output']>
  tipSetup?: Maybe<UiOptionsTipSetup>
  tippingRequireApproval?: Maybe<UiOptionsTippingRequireApproval>
  tomorrowModeEnabled?: Maybe<Scalars['Boolean']['output']>
  tomorrowModeScheduledTime?: Maybe<Scalars['String']['output']>
  trackErrorCorrection?: Maybe<Scalars['Boolean']['output']>
  versions: Array<MlmSavedVersion>
}

export type MlmUiOptionVariantFilterInput = {
  /** Omitting the targets will fetch all the variants for the MLM ID */
  targets?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type Modifier = MenuEntity & {
  __typename: 'Modifier'
  guestCount?: Maybe<Scalars['Float']['output']>
  /** Shared MenuEntity fields */
  id: Scalars['ID']['output']
  itemReference?: Maybe<ItemReference>
  multiLocationId: Scalars['ID']['output']
  name: Scalars['String']['output']
  nameOverride?: Maybe<Scalars['String']['output']>
  owner: Scalars['ID']['output']
  ownerRestaurantSet?: Maybe<RestaurantSet>
  pricing?: Maybe<MenuItemPricing>
  pricingOverride?: Maybe<OverridePricing>
  target: Scalars['ID']['output']
  targetRestaurantSet: RestaurantSet
  versionId: Scalars['ID']['output']
  /** Modifier-specific fields */
  visibility: MenuEntityVisibility
}

export type ModifierDisplayMode =
  | 'HORIZONTAL'
  | 'LEGACY'
  | 'LEGACY_FLATTEN'
  | 'VERTICAL'

export type ModifierGroup = MenuEntity & {
  __typename: 'ModifierGroup'
  /** Shared MenuEntity fields */
  id: Scalars['ID']['output']
  modifierMultiLocationIds?: Maybe<Array<Scalars['ID']['output']>>
  modifiers?: Maybe<Array<Modifier>>
  multiLocationId: Scalars['ID']['output']
  name: Scalars['String']['output']
  owner: Scalars['ID']['output']
  ownerRestaurantSet?: Maybe<RestaurantSet>
  parents: Array<ParentResponse>
  pointOfSale: ModifierGroupPointOfSale
  pricing: ModifierGroupPricing
  requiredMode: RequiredMode
  selections: ModifierGroupSelections
  target: Scalars['ID']['output']
  targetRestaurantSet: RestaurantSet
  type: ModifierGroupType
  versionId: Scalars['ID']['output']
  /** ModifierGroup-specific fields */
  visibility: MenuEntityVisibility
}

export type ModifierGroupModifiersArgs = {
  versionResolution?: Scalars['String']['input']
  versionResolutionTarget?: InputMaybe<Scalars['String']['input']>
}

export type ModifierGroupPointOfSale = {
  __typename: 'ModifierGroupPointOfSale'
  color: Scalars['Int']['output']
  orderingPriority?: Maybe<Scalars['Int']['output']>
  shortName?: Maybe<Scalars['String']['output']>
}

export type ModifierGroupPricing = {
  __typename: 'ModifierGroupPricing'
  fixedPrice?: Maybe<Money>
  pricingMode: PricingMode
  pricingStrategy: ModifierGroupPricingStrategy
}

export type ModifierGroupPricingStrategy =
  | 'DEFAULT'
  | 'SEQUENCE_PRICE'
  | 'SIZE_PRICE'
  | 'SIZE_SEQUENCE_PRICE'
  | 'UNSPECIFIED'

export type ModifierGroupSelections = {
  __typename: 'ModifierGroupSelections'
  duplicateModifiersEnabled?: Maybe<Scalars['Boolean']['output']>
  maxSelections?: Maybe<Scalars['Int']['output']>
  minSelection?: Maybe<Scalars['Int']['output']>
  multiSelect: Scalars['Boolean']['output']
}

export type ModifierGroupType =
  | 'EXPLICIT_OPTIONS'
  | 'MENU_GROUP_REFERENCE'
  | 'UNSPECIFIED'

/** Modifier-level metrics */
export type ModifierMetrics = {
  __typename: 'ModifierMetrics'
  /** Reference to the menu item entity for this modifier */
  modifier: MenuItem
  /** Name of the modifier */
  name: Scalars['String']['output']
  /** Reference to the parent menu item entity that this modifier applies to */
  parent: MenuItem
  /** Quantity of the modifier sold */
  salesQuantity: DecimalMetric
  /** Total net sales of the modifier */
  totalNetSales: MoneyMetric
}

/** A monetary value with currency. */
export type Money = {
  __typename: 'Money'
  /** The amount of money */
  amount: Scalars['Decimal']['output']
  /** The currency the money is paid in */
  currency: Currency
}

export type MoneyInput = {
  /** The amount of money */
  amount: Scalars['Decimal']['input']
  /** The currency the money is paid in */
  currency: Currency
}

/** Metric for monetary values */
export type MoneyMetric = {
  __typename: 'MoneyMetric'
  /** Comparison monetary value */
  comparison?: Maybe<Money>
  /** Current monetary value */
  current: Money
  /** End time for the metric; inclusive */
  endTime: Scalars['DateTime']['output']
  /** Start time for the metric; inclusive */
  startTime: Scalars['DateTime']['output']
  /** Variation in comparison */
  variation?: Maybe<Comparison>
}

export type MostRecentImportGuidResponse =
  | InternalError
  | MostRecentImportGuidResponseSuccess
  | NoPreviousImports

export type MostRecentImportGuidResponseSuccess = {
  __typename: 'MostRecentImportGuidResponseSuccess'
  id: Scalars['ID']['output']
}

export type Mutation = {
  __typename: 'Mutation'
  /** Adds a new adhoc shift for an employee */
  addAdhocShift: EmployeeShift
  addGroupsAndLocationsToGroup: RestaurantGroup
  addLocationToGroups: RestaurantLocation
  archiveChannelMenuEntities: Array<Scalars['ID']['output']>
  archiveGroup: RestaurantGroup
  archiveMenus: Array<Scalars['ID']['output']>
  archiveNoSaleReason: Array<OpenCashDrawerReason>
  archivePayoutReason: Array<OpenCashDrawerReason>
  /** @deprecated Use `configMutation.archiveRestaurantGroup` instead. */
  archiveRestaurantGroup: RestaurantGroup
  attachMenuItemToMenuGroups: Array<MenuGroup>
  authConfiguration: AuthConfigurationMutation
  booking: BookingMutation
  channel?: Maybe<ChannelMutation>
  chatMessageFeedback?: Maybe<Scalars['ID']['output']>
  configCopyMutation: CopyConfigMutation
  configMutation: ConfigMutation
  createChannelMenuEntities: Array<ChannelMenuEntity>
  /** @deprecated Use `configCopyMutation.createCopyConfigTask` instead. */
  createCopyConfigTaskAsync: CopyConfigTask
  createGroup: RestaurantGroup
  createItemFeedbackConfig: CreateItemFeedbackConfigResponse
  createMenuGroupsOnMenu: Array<MenuGroup>
  createMenuGroupsOnMenuGroup: Array<MenuGroup>
  createMenuItemPlateCost: MenuItemPlateCost
  /** Create orphaned items */
  createMenuItems: Array<MenuItem>
  createMenus: Array<Menu>
  createNoSaleReason: Array<OpenCashDrawerReason>
  createOrUpdateChannelMenuEntities: Array<ChannelMenuEntity>
  createPayoutReason: Array<OpenCashDrawerReason>
  /** Create a new shift */
  createShift: CreateShiftResult
  createToastPayConfig: CreateToastPayConfigResponse
  customerTasks: CustomerTasksMutation
  dataExtensions: DataExtensionMutation
  /** @deprecated Use logbook.deleteLogbookEntry */
  deleteLogbookEntry?: Maybe<LogbookEntry>
  /** @deprecated Use logbook.deleteLogbookEntryReply */
  deleteLogbookEntryReply?: Maybe<LogbookEntryReply>
  /** Soft Delete an existing shift */
  deleteShift: DeletedShiftResult
  driveThru: DriveThruMutation
  editGroupInfo: RestaurantGroup
  financialInsights: FinancialInsightsMutation
  foodWaste: FoodWasteMutation
  giftCardConfig: GiftCardMutation
  guestFeedback?: Maybe<GuestFeedbackMutation>
  kiosk?: Maybe<KioskMutation>
  logbook: LogbookMutation
  /** Clocks out a shift */
  managerClockOutShift: ManagerClockOutResult
  memberships: MembershipsMutation
  menuUpsellConfig: MenuUpsellConfigMutation
  onboarding?: Maybe<OnboardingMutation>
  opt: OptMutation
  orders: OrdersMutation
  ordersConfig?: Maybe<OrdersConfigMutation>
  packaging: PackagingMutation
  paymentsConfig?: Maybe<PaymentsConfigMutation>
  processLegacyJobs?: Maybe<ProcessLegacyJobResponse>
  productSubscriptionsMutation: ProductSubscriptionsMutation
  publishing?: Maybe<PublishMutation>
  removeGroupsAndLocationsFromGroup: RestaurantGroup
  removeLocationFromGroups: RestaurantLocation
  removeMenuGroupFromMenu: Menu
  removeMenuGroupFromParentGroup: MenuGroup
  removeMenuItemFromGroup: MenuGroup
  reorderMenuGroupsOnMenu: Menu
  reorderMenuGroupsOnMenuGroup: MenuGroup
  reorderMenuItemsOnMenuGroup: MenuGroup
  reorderMenus: Array<Menu>
  runRestaurantLeafJob?: Maybe<RestaurantLeafDataJobResult>
  setAutoChangeStrategy: SmartQuoteAutoChangeStrategyResponse
  setAutomatedPrepTimeThresholds?: Maybe<GuestQuoteTimes>
  setClosedAllDayToggle?: Maybe<OrderingSchedule>
  setDeliveryEnabled?: Maybe<TakeoutDeliveryConfig>
  setDeliveryTimeMinutes?: Maybe<GuestQuoteTimes>
  setKitchenPacingItems?: Maybe<GuestQuoteTimes>
  setKitchenPacingOrders?: Maybe<GuestQuoteTimes>
  setLastOrderConfiguration?: Maybe<OrderingSchedule>
  setMinTakeoutDeliveryTime?: Maybe<GuestQuoteTimes>
  setOnlineOrderingHoursSchedule?: Maybe<OrderingSchedule>
  setOrderingSchedule?: Maybe<OrderingSchedule>
  setPartnerChannelConfig?: Maybe<PartnerChannelConfig>
  setPartnerChannelsConfig?: Maybe<PartnerChannelConfig>
  setQuoteTimes?: Maybe<GuestQuoteTimes>
  setScheduleOverrides?: Maybe<OrderingSchedule>
  setSnooze?: Maybe<TakeoutDeliveryConfig>
  setStrategy?: Maybe<GuestQuoteTimes>
  setTakeoutAndDeliveryEnabled?: Maybe<TakeoutDeliveryConfig>
  setTakeoutEnabled?: Maybe<TakeoutDeliveryConfig>
  setThrottlingTimes?: Maybe<GuestQuoteTimes>
  smartQuote?: Maybe<SmartQuoteMutation>
  sousChef: SousChefMutation
  sousChefAdmin: SousChefAdminMutation
  /** @deprecated Use logbook.submitLogbookEntryReply */
  submitLogbookEntryReply?: Maybe<LogbookEntryReply>
  /** @deprecated Use logbook.submitLogbookEntry */
  submitLogbookEntryWithBusinessDay?: Maybe<LogbookEntry>
  thirdPartyMock?: Maybe<ThirdPartyMockMutation>
  thirdPartyOrderingOnboarding: ThirdPartyOrderingOnboardingMutation
  toastNowDeviceRegistry: ToastNowDeviceRegistryMutation
  toastSearch: SearchMutation
  trial: TrialMutation
  uiOptions?: Maybe<UiOptionsMutation>
  unarchiveGroups: Array<RestaurantGroup>
  unarchiveNoSaleReason: Array<OpenCashDrawerReason>
  unarchivePayoutReason: Array<OpenCashDrawerReason>
  updateCashDrawer?: Maybe<CashDrawer>
  updateCashDrawerConfig?: Maybe<CashDrawerConfig>
  /** @deprecated Use financialInsights.updateCashFlowTransactions */
  updateCashFlowTransactions: Array<CashFlowAccountTransactions>
  updateChannelMenuEntities: Array<ChannelMenuEntity>
  updateClosedCashDrawerReportSections?: Maybe<CloseOutReportingContent>
  updateDeclareCashTipsConfig?: Maybe<ShiftReviewConfig>
  updateItemFeedbackConfig: UpdateItemFeedbackConfigResponse
  /** @deprecated Use logbook.updateLogbookEntry */
  updateLogbookEntry?: Maybe<LogbookEntry>
  /** @deprecated Use logbook.updateLogbookEntryReply */
  updateLogbookEntryReply?: Maybe<LogbookEntryReply>
  updateMenuItemPlateCost: MenuItemPlateCost
  updateMenuItems: Array<MenuItem>
  updateMenus: Array<Menu>
  updateNoSaleReason: Array<OpenCashDrawerReason>
  updateNoSaleReasonOrdinals: Array<OpenCashDrawerReason>
  updatePayoutReason: Array<OpenCashDrawerReason>
  updatePayoutReasonOrdinals: Array<OpenCashDrawerReason>
  updateReconciliationConfig?: Maybe<ShiftReviewConfig>
  /** Updates an existing shift */
  updateShift: UpdateShiftResult
  updateShiftReviewReportSections?: Maybe<CloseOutReportingContent>
  /** Update the clock in/out times for an existing shift */
  updateShiftTimes: UpdateShiftTimesResult
  updateShouldAuditRemovedItems?: Maybe<CloseOutReportingConfig>
  updateShouldAutoPrintClosedCashDrawerReportOnClose?: Maybe<CloseOutReportingConfig>
  updateShouldRequireCashDrawerLockdown?: Maybe<ShiftReviewConfig>
  updateShouldRequireShiftReview?: Maybe<ShiftReviewConfig>
  updateShouldShowTipsBySalesCategory?: Maybe<CloseOutReportingConfig>
  updateStockForMenuItems?: Maybe<Array<MenuItem>>
  updateTipOutConfig?: Maybe<ShiftReviewConfig>
  updateTipSharingPercentages?: Maybe<CloseOutReportingConfig>
  updateToastPayConfig: UpdateToastPayConfigResponse
  updateZReportSections?: Maybe<CloseOutReportingContent>
  upgradeItemFeedbackConfig: UpgradeItemFeedbackConfigResponse
  /** @deprecated restaurant-admin-graphql has payload size limits that result in image upload failing. Use REST endpoint /menus/v1/saved/image instead. */
  uploadImage?: Maybe<UploadImageResponse>
  upsertChannelMenuPriceAdjustments: Array<ChannelMenuPriceAdjustment>
  userPreferences: UserPreferencesMutation
  /** Test-only version mutations */
  versionMenu: Array<Menu>
  versionMenuGroup: Array<MenuGroup>
  versionMenuItem: Array<MenuItem>
  versionModifierGroup: Array<ModifierGroup>
  versionPriceLevel: Array<PriceLevelEntity>
}

export type MutationAddAdhocShiftArgs = {
  businessDate: Scalars['Date']['input']
  declaredCashTips?: InputMaybe<MoneyInput>
  employee: Scalars['ID']['input']
  inDate: Scalars['DateTime']['input']
  job?: InputMaybe<Scalars['ID']['input']>
  outDate?: InputMaybe<Scalars['DateTime']['input']>
  restaurant: Scalars['ID']['input']
}

export type MutationAddGroupsAndLocationsToGroupArgs = {
  input: EditGroupsAndLocationsInput
}

export type MutationAddLocationToGroupsArgs = {
  input: LocationGroupsInput
}

export type MutationArchiveChannelMenuEntitiesArgs = {
  channelMenuEntities: Array<ArchiveEntityInput>
}

export type MutationArchiveGroupArgs = {
  guid: Scalars['ID']['input']
}

export type MutationArchiveMenusArgs = {
  input: Array<ArchiveEntityInput>
}

export type MutationArchiveNoSaleReasonArgs = {
  id: Scalars['ID']['input']
}

export type MutationArchivePayoutReasonArgs = {
  id: Scalars['ID']['input']
}

export type MutationArchiveRestaurantGroupArgs = {
  id: Scalars['ID']['input']
}

export type MutationAttachMenuItemToMenuGroupsArgs = {
  itemMultiLocationId: Scalars['ID']['input']
  menuGroupVersionIDs: Array<Scalars['ID']['input']>
}

export type MutationChatMessageFeedbackArgs = {
  feedback: ChatMessageFeedback
  sessionId: Scalars['ID']['input']
}

export type MutationCreateChannelMenuEntitiesArgs = {
  channelMenuEntities: Array<ChannelMenuEntityInput>
}

export type MutationCreateCopyConfigTaskAsyncArgs = {
  copyConfigTask: CopyConfigTaskInput
}

export type MutationCreateGroupArgs = {
  input: CreateRestaurantGroupInput
}

export type MutationCreateItemFeedbackConfigArgs = {
  itemFeedbackConfig: ItemFeedbackConfigInput
}

export type MutationCreateMenuGroupsOnMenuArgs = {
  input: Array<SavedMenuGroupCreate>
  parentMenuVersionId: Scalars['ID']['input']
}

export type MutationCreateMenuGroupsOnMenuGroupArgs = {
  input: Array<SavedMenuGroupCreate>
  parentGroupVersionId: Scalars['ID']['input']
}

export type MutationCreateMenuItemPlateCostArgs = {
  plateCost: CreateMenuItemPlateCost
}

export type MutationCreateMenuItemsArgs = {
  input: Array<SavedMenuItemCreate>
}

export type MutationCreateMenusArgs = {
  input: Array<SavedMenuCreate>
}

export type MutationCreateNoSaleReasonArgs = {
  createNoSaleReasonInput: CreateReasonInput
}

export type MutationCreateOrUpdateChannelMenuEntitiesArgs = {
  channelMenuEntities: Array<ChannelMenuEntityInput>
}

export type MutationCreatePayoutReasonArgs = {
  createPayoutReasonInput: CreateReasonInput
}

export type MutationCreateShiftArgs = {
  createShiftInput: CreateShiftInput
}

export type MutationCreateToastPayConfigArgs = {
  config: ToastPayConfigInput
}

export type MutationDeleteLogbookEntryArgs = {
  entry: DeleteLogbookEntry
}

export type MutationDeleteLogbookEntryReplyArgs = {
  reply: DeleteLogbookEntryReply
}

export type MutationDeleteShiftArgs = {
  shift: Scalars['ID']['input']
}

export type MutationEditGroupInfoArgs = {
  input: EditRestaurantGroupInfoInput
}

export type MutationManagerClockOutShiftArgs = {
  clockOut?: InputMaybe<Scalars['DateTime']['input']>
  shift: Scalars['ID']['input']
}

export type MutationProcessLegacyJobsArgs = {
  processLegacyJobRequest: ProcessLegacyJobRequest
}

export type MutationRemoveGroupsAndLocationsFromGroupArgs = {
  input: EditGroupsAndLocationsInput
}

export type MutationRemoveLocationFromGroupsArgs = {
  input: LocationGroupsInput
}

export type MutationRemoveMenuGroupFromMenuArgs = {
  input: RemoveMenuGroupInput
}

export type MutationRemoveMenuGroupFromParentGroupArgs = {
  input: RemoveMenuGroupInput
}

export type MutationRemoveMenuItemFromGroupArgs = {
  input: RemoveMenuItemInput
}

export type MutationReorderMenuGroupsOnMenuArgs = {
  input: MenuEntityReorder
}

export type MutationReorderMenuGroupsOnMenuGroupArgs = {
  input: MenuEntityReorder
}

export type MutationReorderMenuItemsOnMenuGroupArgs = {
  input: MenuEntityReorder
}

export type MutationReorderMenusArgs = {
  input: MenuReorder
}

export type MutationRunRestaurantLeafJobArgs = {
  jobInput: RestaurantLeafDataJobInput
}

export type MutationSetAutoChangeStrategyArgs = {
  strategy: SmartQuoteAutoChangeStrategy
}

export type MutationSetAutomatedPrepTimeThresholdsArgs = {
  maximumValue: Scalars['Int']['input']
  minimumValue: Scalars['Int']['input']
  publish?: Scalars['Boolean']['input']
}

export type MutationSetClosedAllDayToggleArgs = {
  day: Scalars['String']['input']
  diningOptionBehavior: DiningOptionBehavior
}

export type MutationSetDeliveryEnabledArgs = {
  publish?: Scalars['Boolean']['input']
  value: Scalars['Boolean']['input']
}

export type MutationSetDeliveryTimeMinutesArgs = {
  publish?: Scalars['Boolean']['input']
  value: Scalars['Int']['input']
}

export type MutationSetKitchenPacingItemsArgs = {
  publish?: Scalars['Boolean']['input']
  value: Scalars['Int']['input']
}

export type MutationSetKitchenPacingOrdersArgs = {
  publish?: Scalars['Boolean']['input']
  value: Scalars['Int']['input']
}

export type MutationSetLastOrderConfigurationArgs = {
  lastOrderConfiguration: Scalars['String']['input']
}

export type MutationSetMinTakeoutDeliveryTimeArgs = {
  deliveryHours: Scalars['Int']['input']
  deliveryMinutes: Scalars['Int']['input']
  publish?: Scalars['Boolean']['input']
  takeoutHours: Scalars['Int']['input']
  takeoutMinutes: Scalars['Int']['input']
}

export type MutationSetOnlineOrderingHoursScheduleArgs = {
  schedule: Array<ScheduleInput>
}

export type MutationSetOrderingScheduleArgs = {
  overrides: Array<OverridesInput>
  schedule: Array<ScheduleInput>
}

export type MutationSetPartnerChannelConfigArgs = {
  partnerChannel: PartnerChannelInput
  publish?: Scalars['Boolean']['input']
}

export type MutationSetPartnerChannelsConfigArgs = {
  partnerChannels: PartnerChannelsInput
  publish?: Scalars['Boolean']['input']
}

export type MutationSetQuoteTimesArgs = {
  publish?: Scalars['Boolean']['input']
  quoteTimes: Array<QuoteTimeInput>
}

export type MutationSetScheduleOverridesArgs = {
  overrides: Array<OverridesInput>
}

export type MutationSetSnoozeArgs = {
  onlineOrderingAvailability: OnlineOrderingAvailability
  publish?: Scalars['Boolean']['input']
  snoozeValue?: InputMaybe<SnoozeValue>
}

export type MutationSetStrategyArgs = {
  publish?: Scalars['Boolean']['input']
  value: Scalars['String']['input']
}

export type MutationSetTakeoutAndDeliveryEnabledArgs = {
  deliveryEnabled: Scalars['Boolean']['input']
  publish?: Scalars['Boolean']['input']
  takeoutEnabled: Scalars['Boolean']['input']
}

export type MutationSetTakeoutEnabledArgs = {
  value: Scalars['Boolean']['input']
}

export type MutationSetThrottlingTimesArgs = {
  deliveryThrottlingTimeMinutes: Scalars['Int']['input']
  publish?: Scalars['Boolean']['input']
  takeoutThrottlingTimeMinutes: Scalars['Int']['input']
}

export type MutationSubmitLogbookEntryReplyArgs = {
  reply: CreateLogbookEntryReply
}

export type MutationSubmitLogbookEntryWithBusinessDayArgs = {
  entry: CreateLogbookEntryWithBusinessDay
}

export type MutationUnarchiveGroupsArgs = {
  guids: Array<Scalars['ID']['input']>
}

export type MutationUnarchiveNoSaleReasonArgs = {
  id: Scalars['ID']['input']
}

export type MutationUnarchivePayoutReasonArgs = {
  id: Scalars['ID']['input']
}

export type MutationUpdateCashDrawerArgs = {
  cashDrawerInput: CashDrawerInput
  id: Scalars['ID']['input']
}

export type MutationUpdateCashDrawerConfigArgs = {
  cashDrawerConfigInput: CashDrawerConfigInput
}

export type MutationUpdateCashFlowTransactionsArgs = {
  request: Array<UpdateCashFlowTransactionRequest>
}

export type MutationUpdateChannelMenuEntitiesArgs = {
  channelMenuEntities: Array<ChannelMenuEntityInput>
}

export type MutationUpdateClosedCashDrawerReportSectionsArgs = {
  closedCashDrawerReportSectionInputs: Array<ReportSectionInput>
}

export type MutationUpdateDeclareCashTipsConfigArgs = {
  declareCashTipsConfigInput: DeclareCashTipsConfigInput
}

export type MutationUpdateItemFeedbackConfigArgs = {
  itemFeedbackConfig: ItemFeedbackConfigInput
}

export type MutationUpdateLogbookEntryArgs = {
  entry: UpdateLogbookEntry
}

export type MutationUpdateLogbookEntryReplyArgs = {
  reply: UpdateLogbookEntryReply
}

export type MutationUpdateMenuItemPlateCostArgs = {
  plateCost: UpdateMenuItemPlateCost
}

export type MutationUpdateMenuItemsArgs = {
  input: Array<SavedMenuItemUpdate>
}

export type MutationUpdateMenusArgs = {
  input: Array<SavedMenuUpdate>
}

export type MutationUpdateNoSaleReasonArgs = {
  id: Scalars['ID']['input']
  updateNoSaleReasonInput: UpdateReasonInput
}

export type MutationUpdateNoSaleReasonOrdinalsArgs = {
  noSaleReasonOrdinalInputs: Array<UpdateReasonOrdinalInput>
}

export type MutationUpdatePayoutReasonArgs = {
  id: Scalars['ID']['input']
  updatePayoutReasonInput: UpdateReasonInput
}

export type MutationUpdatePayoutReasonOrdinalsArgs = {
  payoutReasonOrdinalInputs: Array<UpdateReasonOrdinalInput>
}

export type MutationUpdateReconciliationConfigArgs = {
  reconciliationConfigInput: ReconciliationConfigInput
}

export type MutationUpdateShiftArgs = {
  shift: Scalars['ID']['input']
  updateShiftInput: UpdateShiftInput
}

export type MutationUpdateShiftReviewReportSectionsArgs = {
  shiftReviewReportSectionInputs: Array<ReportSectionInput>
}

export type MutationUpdateShiftTimesArgs = {
  shift: Scalars['ID']['input']
  shiftUpdateInput: UpdateShiftTimesInput
}

export type MutationUpdateShouldAuditRemovedItemsArgs = {
  shouldAuditRemovedItems: Scalars['Boolean']['input']
}

export type MutationUpdateShouldAutoPrintClosedCashDrawerReportOnCloseArgs = {
  shouldAutoPrintClosedCashDrawerReportOnClose: Scalars['Boolean']['input']
}

export type MutationUpdateShouldRequireCashDrawerLockdownArgs = {
  shouldRequireCashDrawerLockdown: Scalars['Boolean']['input']
}

export type MutationUpdateShouldRequireShiftReviewArgs = {
  shouldRequireShiftReview: Scalars['Boolean']['input']
}

export type MutationUpdateShouldShowTipsBySalesCategoryArgs = {
  shouldShowTipsBySalesCategory: Scalars['Boolean']['input']
}

export type MutationUpdateStockForMenuItemsArgs = {
  items: Array<MenuItemStockUpdate>
}

export type MutationUpdateTipOutConfigArgs = {
  tipOutConfigInput: TipOutConfigInput
}

export type MutationUpdateTipSharingPercentagesArgs = {
  allTipSharingPercentages: Array<TipSharingPercentageInput>
}

export type MutationUpdateToastPayConfigArgs = {
  config: ToastPayConfigInput
}

export type MutationUpdateZReportSectionsArgs = {
  zReportSectionInputs: Array<ReportSectionInput>
}

export type MutationUploadImageArgs = {
  input: ImageUploadInput
}

export type MutationUpsertChannelMenuPriceAdjustmentsArgs = {
  channelMenuPriceAdjustments: Array<ChannelMenuPriceAdjustmentInput>
}

export type MutationVersionMenuArgs = {
  input?: InputMaybe<VersionInput>
}

export type MutationVersionMenuGroupArgs = {
  input?: InputMaybe<VersionInput>
}

export type MutationVersionMenuItemArgs = {
  input?: InputMaybe<VersionInput>
}

export type MutationVersionModifierGroupArgs = {
  input?: InputMaybe<VersionInput>
}

export type MutationVersionPriceLevelArgs = {
  input?: InputMaybe<VersionInput>
}

/** Represents top level menu items in the original left rail */
export type NavigationCategory = {
  __typename: 'NavigationCategory'
  categorySpa?: Maybe<Scalars['String']['output']>
  categoryType: NavigationCategoryType
  description: Scalars['String']['output']
  groupId: Scalars['String']['output']
  headerSpa?: Maybe<Scalars['String']['output']>
  icon: NavigationMenuItemIcon
  iconHighlightOverridePaths: Array<Scalars['String']['output']>
  id: Scalars['ID']['output']
  longName: Scalars['String']['output']
  name: Scalars['String']['output']
  path?: Maybe<Scalars['String']['output']>
  sections: Array<NavigationSection>
  taskPaths: Array<Scalars['String']['output']>
}

/**  Represents different types of category. BANQUET_LINK_CATEGORY allows for client side routing to links within Toast Web  */
export type NavigationCategoryType =
  | 'BANQUET_LINK_CATEGORY'
  | 'CATEGORY_PAGE'
  | 'EXTERNAL_LINK_CATEGORY'
  | 'SPA_CATEGORY'

/**  Represents a page that contains a config that holds a reference to a restaurant set  */
export type NavigationConfigLocation = {
  __typename: 'NavigationConfigLocation'
  location: ConfigLocation
  type: Scalars['String']['output']
}

export type NavigationGroup = {
  __typename: 'NavigationGroup'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  tasks: Array<NavigationTask>
}

export type NavigationHeader = {
  __typename: 'NavigationHeader'
  links?: Maybe<Array<NavigationLink>>
  title: Scalars['String']['output']
}

export type NavigationLink = {
  __typename: 'NavigationLink'
  link: Scalars['String']['output']
  spaName: Scalars['String']['output']
  title: Scalars['String']['output']
}

/** Represents top level menu items in the Toast Admin left rail menu */
export type NavigationMenuItem = NavigationHeader | NavigationLink

/** Icons which appear in the global navigation menu */
export type NavigationMenuItemIcon =
  | 'AllProductsIcon'
  | 'AnnouncementIcon'
  | 'BankIcon'
  | 'CartIcon'
  | 'ChefsHatIcon'
  | 'ComputerBrushIcon'
  | 'DiningIcon'
  | 'EmployeesIcon'
  | 'HardwareIcon'
  | 'HomeIcon'
  | 'IntegrationsIcon'
  | 'LookupChecksIcon'
  | 'LoyaltyIcon'
  | 'MenusIcon'
  | 'PaymentsIcon'
  | 'PayrollEmployeeIcon'
  | 'PercentageIcon'
  | 'PickUpIcon'
  | 'PlaceSettingIcon'
  | 'ReportsIcon'
  | 'ScanBarcodeIcon'
  | 'ServingTrayIcon'
  | 'SettingsIcon'
  | 'SlingLogoIcon'
  | 'SpecialRequestIcon'
  | 'ToastLogoIcon'
  | 'XtrachefLogoIcon'

/** Represents a page that can be navigated to within Toast Web */
export type NavigationPage = {
  __typename: 'NavigationPage'
  id: Scalars['ID']['output']
  keywords: Array<Scalars['String']['output']>
  name: Scalars['String']['output']
  path: Scalars['String']['output']
  routingStrategy: NavigationPageRoutingStrategy
}

/** Describes how a NavigationPage should be routed to from Toast Web */
export type NavigationPageRoutingStrategy =
  /** Routes using react-router, avoids full page reload */
  | 'BANQUET_PAGE'
  /** Downloads a file */
  | 'DOWNLOAD_LINK'
  /** Opens page in a new tab */
  | 'EXTERNAL_PAGE'
  /** Routes using full page reload */
  | 'LEGACY_TOASTWEB_PAGE'

/** Link to be shown in profile link dropdown in toastweb header */
export type NavigationProfileLink = {
  __typename: 'NavigationProfileLink'
  href: Scalars['String']['output']
  name: Scalars['String']['output']
  type: NavigationProfileLinkType
}

export type NavigationProfileLinkType =
  | 'LINK'
  | 'LOGOUT'
  | 'QUICK_RESTAURANT_ACCESS'
  | 'SHARE_YOUR_SCREEN'

/** Nested query containing queries from service-navigation G2 service */
export type NavigationQuery = {
  __typename: 'NavigationQuery'
  adminMenu: Array<NavigationMenuItem>
  categories: Array<NavigationCategory>
  configLocations: Array<NavigationConfigLocation>
  globalNavigationMenu: GlobalNavigationMenu
  pages: Array<NavigationPage>
  profileLinks: Array<NavigationProfileLink>
  quickActions: Array<NavigationQuickAction>
}

/** Nested query containing queries from service-navigation G2 service */
export type NavigationQueryCategoriesArgs = {
  cacheKey?: InputMaybe<Scalars['String']['input']>
  restaurantGuid?: InputMaybe<Scalars['String']['input']>
}

/** Nested query containing queries from service-navigation G2 service */
export type NavigationQueryGlobalNavigationMenuArgs = {
  cacheKey?: InputMaybe<Scalars['String']['input']>
  restaurantGuid?: InputMaybe<Scalars['String']['input']>
}

/** Nested query containing queries from service-navigation G2 service */
export type NavigationQueryProfileLinksArgs = {
  authenticityToken: Scalars['String']['input']
}

/** Represents quick action links on the Toast Web home page */
export type NavigationQuickAction = {
  __typename: 'NavigationQuickAction'
  hasPermission: Scalars['Boolean']['output']
  href?: Maybe<Scalars['String']['output']>
  id: Scalars['String']['output']
  label: Scalars['String']['output']
  showAsNew: Scalars['Boolean']['output']
}

export type NavigationSection = {
  __typename: 'NavigationSection'
  groups: Array<NavigationGroup>
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  sectionSpa?: Maybe<Scalars['String']['output']>
}

export type NavigationTask = {
  __typename: 'NavigationTask'
  availableActions: Array<TaskAction>
  badgeText: Array<Scalars['String']['output']>
  description: Scalars['String']['output']
  id: Scalars['ID']['output']
  keywords: Array<Scalars['String']['output']>
  name: Scalars['String']['output']
  path: Scalars['String']['output']
  pendoId?: Maybe<Scalars['String']['output']>
  taskType: NavigationTaskType
}

export type NavigationTaskAction = {
  __typename: 'NavigationTaskAction'
  action: Scalars['String']['output']
  description: Scalars['String']['output']
}

export type NavigationTaskType =
  | 'BANQUET_LINK'
  | 'DOWNLOAD'
  | 'LINK'
  | 'NEW_TAB_LINK'
  | 'UPSELL'

/** Net sales; i.e., gross sales minus discounts and refunds */
export type NetSales = {
  __typename: 'NetSales'
  /** Grouped net sales by specified dimension */
  grouped: Array<GroupedNetSales>
  /** Hourly net sales */
  hourly: Array<MoneyMetric>
  /** Total net sales */
  totals: MoneyMetric
}

/** Net sales; i.e., gross sales minus discounts and refunds */
export type NetSalesGroupedArgs = {
  by: GroupingDimension
}

export type NetSalesDateRangeMetrics = {
  __typename: 'NetSalesDateRangeMetrics'
  /** Net sales aggregated by business day within the date range */
  daily: Array<DailyNetSalesMetric>
  /** Total value of net sales for the date range */
  totals: MoneyMetric
}

/** Net sales per labor hour */
export type NetSalesPerLaborHour = {
  __typename: 'NetSalesPerLaborHour'
  /** Hourly net sales per labor hour */
  hourly: Array<MoneyMetric>
  /** Total net sales per labor hour */
  totals: MoneyMetric
}

/**  Specifically a networking device Does not implement device-info's Device interface  */
export type NetworkDevice = {
  __typename: 'NetworkDevice'
  lastUpdate?: Maybe<Scalars['DateTime']['output']>
  mac: Scalars['ID']['output']
  modelName?: Maybe<Scalars['String']['output']>
  name?: Maybe<Scalars['String']['output']>
  photo?: Maybe<Scalars['String']['output']>
  serial: Scalars['ID']['output']
  status: NetworkDeviceStatus
  /** Optional context for a status' root cause (useful for PARTIALLY_AVAILABLE state) */
  statusMessage?: Maybe<Scalars['String']['output']>
  /** _network_ device variant. Distinct from Device.deviceType */
  type?: Maybe<NetworkDeviceType>
  vendor?: Maybe<NetworkDeviceVendor>
}

export type NetworkDeviceInput = {
  /** a restaurant guid */
  id: Scalars['String']['input']
  serial: Scalars['String']['input']
}

/** Device-specific network status */
export type NetworkDeviceStatus = 'NOT_AVAILABLE' | 'OFFLINE' | 'ONLINE'

/** What kind of networking device is this? */
export type NetworkDeviceType = 'ACCESS_POINT' | 'ROUTER' | 'SWITCH' | 'UNKNOWN'

/** The manufacturer of a controlled network device */
export type NetworkDeviceVendor = 'MERAKI' | 'PRONTO' | 'UNIFI'

export type NetworkDevicesInput = {
  /** a restaurant guid */
  id: Scalars['String']['input']
}

/** Definition for whether a network is managed by Toast or the customer */
export type NetworkManagementType =
  /** Self-Managed Network */
  | 'SELF'
  /** A Toast-managed network */
  | 'TOAST'

export type NetworkQueries = {
  __typename: 'NetworkQueries'
  getDevice: NetworkDevice
  getDevices: Array<NetworkDevice>
  getStatus: NetworkStatusResponse
}

export type NetworkQueriesGetDeviceArgs = {
  input: NetworkDeviceInput
}

export type NetworkQueriesGetDevicesArgs = {
  input: NetworkDevicesInput
}

export type NetworkQueriesGetStatusArgs = {
  input: NetworkStatusInput
}

/** Whole-location network status */
export type NetworkStatus =
  | 'NOT_AVAILABLE'
  | 'OFFLINE'
  | 'ONLINE'
  | 'PARTIALLY_AVAILABLE'

export type NetworkStatusData = {
  __typename: 'NetworkStatusData'
  devices: Array<NetworkDevice>
  /** corresponds to toastNetworkId or rx guid? */
  id: Scalars['ID']['output']
  managementType: NetworkManagementType
  /** A round-up of all network device statuses */
  status: NetworkStatus
}

export type NetworkStatusError = {
  __typename: 'NetworkStatusError'
  code: NetworkStatusErrorCode
  message: Scalars['String']['output']
}

export type NetworkStatusErrorCode = 'NETWORK_NOT_FOUND'

export type NetworkStatusInput = {
  /** a restaurant guid */
  id: Scalars['String']['input']
}

export type NetworkStatusResponse = NetworkStatusData | NetworkStatusError

export type NeverEmployedEmploymentStatus = EmploymentStatus & {
  __typename: 'NeverEmployedEmploymentStatus'
  adjustedHireDate?: Maybe<Scalars['Date']['output']>
  /** @deprecated use employee.hireDate at the top level instead */
  hireDate?: Maybe<Scalars['String']['output']>
}

export type NoJobIdsProvidedBatchError = Errors & {
  __typename: 'NoJobIdsProvidedBatchError'
  message: Scalars['String']['output']
}

export type NoPreviousImports = Error & {
  __typename: 'NoPreviousImports'
  message: Scalars['String']['output']
  status: ErrorCode
}

export type NotificationContact = {
  __typename: 'NotificationContact'
  chosenName?: Maybe<Scalars['String']['output']>
  email?: Maybe<Scalars['String']['output']>
  firstName?: Maybe<Scalars['String']['output']>
  lastName?: Maybe<Scalars['String']['output']>
  userGuid: Scalars['ID']['output']
}

export type NotificationContactInput = {
  chosenName?: InputMaybe<Scalars['String']['input']>
  email?: InputMaybe<Scalars['String']['input']>
  firstName?: InputMaybe<Scalars['String']['input']>
  lastName?: InputMaybe<Scalars['String']['input']>
  userGuid: Scalars['ID']['input']
}

export type OnboardingAction = {
  id?: Maybe<Scalars['ID']['output']>
  title: Scalars['String']['output']
  /** Denotes what should be done when the action is taken */
  type: OnboardingActionType
}

export type OnboardingActionType =
  | 'JOIN_MEETING'
  | 'LAUNCH_FLOW'
  | 'LEGACY_DYNAMIC_LINK'
  | 'LINK'
  | 'LINK_AND_COMPLETE'
  | 'PREPARE_FOR_MEETING'
  | 'RESCHEDULE_MEETING'
  | 'SCHEDULE_MEETING'
  | 'SHARE_INFO'
  | 'SUPPORT_CHAT'
  | 'VIEW_MEETING_RECAP'

export type OnboardingDefaultAction = OnboardingAction & {
  __typename: 'OnboardingDefaultAction'
  associatedTask?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['ID']['output']>
  title: Scalars['String']['output']
  type: OnboardingActionType
  updatedTaskStatus?: Maybe<OnboardingStatus>
}

export type OnboardingDefaultInfo = OnboardingInfo & {
  __typename: 'OnboardingDefaultInfo'
  action?: Maybe<OnboardingAction>
  icon?: Maybe<OnboardingIcon>
  id: Scalars['ID']['output']
  subtext?: Maybe<Scalars['String']['output']>
  title: Scalars['String']['output']
}

export type OnboardingDefaultItem = OnboardingItem & {
  __typename: 'OnboardingDefaultItem'
  action?: Maybe<OnboardingStepAction>
  id: Scalars['ID']['output']
  metadata: OnboardingItemMetadata
  status: OnboardingStatus
  title: Scalars['String']['output']
}

export type OnboardingDefaultItemMetadataV2 = OnboardingItemMetadataV2 & {
  __typename: 'OnboardingDefaultItemMetadataV2'
  aboveItemMessage?: Maybe<Scalars['String']['output']>
  belowItemMessage?: Maybe<Scalars['String']['output']>
}

export type OnboardingDefaultItemV2 = OnboardingItemV2 & {
  __typename: 'OnboardingDefaultItemV2'
  action?: Maybe<OnboardingAction>
  id: Scalars['ID']['output']
  metadata?: Maybe<OnboardingItemMetadataV2>
  status: OnboardingStatus
  title: Scalars['String']['output']
}

export type OnboardingDefaultStepDetails = OnboardingSetupStepDetails & {
  __typename: 'OnboardingDefaultStepDetails'
  actions: Array<Maybe<OnboardingAction>>
  description?: Maybe<Scalars['MarkdownText']['output']>
  id: Scalars['ID']['output']
  info: Array<Maybe<OnboardingStepInfo>>
  title: Scalars['String']['output']
}

export type OnboardingDefaultStepInfo = OnboardingStepInfo & {
  __typename: 'OnboardingDefaultStepInfo'
  action?: Maybe<OnboardingAction>
  description?: Maybe<Scalars['MarkdownText']['output']>
  icon?: Maybe<OnboardingIcon>
  id?: Maybe<Scalars['ID']['output']>
  title?: Maybe<Scalars['String']['output']>
}

export type OnboardingIcon =
  | 'ARROW_CIRCLE_RIGHT'
  | 'BANK'
  | 'BANK_CHECK'
  | 'CABLING'
  | 'CALENDAR_TODAY'
  | 'CALL_SUPPORT'
  | 'CHECKLIST'
  | 'DOCUMENT'
  | 'DOWNLOAD'
  | 'EMPLOYEES'
  | 'GUEST'
  | 'HARDWARE'
  | 'HELP'
  | 'HISTORY'
  | 'ID_NUMBER'
  | 'KITCHEN_DINING'
  | 'LAPTOP'
  | 'LINK'
  | 'MENUS'
  | 'ORDERS'
  | 'PERSON_ADD'
  | 'PERSON_DONE'
  | 'PHOTO'
  | 'RESTAURANT'
  | 'RESTAURANT_SETUP'
  | 'SALES_TAX'
  | 'SEND_ARROW'
  | 'SERVER'
  | 'SETTINGS'
  | 'SHARE_ARROW'
  | 'TABLE'
  | 'TOAST_CENTRAL'
  | 'TOAST_LOGO'
  | 'UNIVERSITY'
  | 'WIFI_OFFLINE'

/** A step with actionable items */
export type OnboardingImplementationStep = OnboardingSetupStep & {
  __typename: 'OnboardingImplementationStep'
  details?: Maybe<OnboardingSetupStepDetails>
  /** A key that UIs can use to map to icon files */
  icon?: Maybe<OnboardingIcon>
  id: Scalars['ID']['output']
  items?: Maybe<Array<OnboardingItem>>
  module: OnboardingModule
  status: OnboardingStatus
  title: Scalars['String']['output']
}

export type OnboardingImplementationStepV2 = OnboardingSetupStepV2 & {
  __typename: 'OnboardingImplementationStepV2'
  /** A key that UIs can use to map to icon files */
  icon?: Maybe<OnboardingIcon>
  id: Scalars['ID']['output']
  items: Array<OnboardingItemV2>
  status: OnboardingStatus
  subtext?: Maybe<Scalars['String']['output']>
  title: Scalars['String']['output']
}

export type OnboardingInfo = {
  /** An optional action associated with the information (like following a link) */
  action?: Maybe<OnboardingAction>
  icon?: Maybe<OnboardingIcon>
  id: Scalars['ID']['output']
  subtext?: Maybe<Scalars['String']['output']>
  title: Scalars['String']['output']
}

/** A step that is primarily informational */
export type OnboardingInfoStep = OnboardingSetupStep & {
  __typename: 'OnboardingInfoStep'
  details?: Maybe<OnboardingSetupStepDetails>
  /** A key that UIs can use to map to icon files */
  icon?: Maybe<OnboardingIcon>
  id: Scalars['ID']['output']
  module: OnboardingModule
  status: OnboardingStatus
  title: Scalars['String']['output']
}

export type OnboardingItem = {
  action?: Maybe<OnboardingStepAction>
  id: Scalars['ID']['output']
  metadata: OnboardingItemMetadata
  status: OnboardingStatus
  title: Scalars['String']['output']
}

export type OnboardingItemMetadata = {
  __typename: 'OnboardingItemMetadata'
  aboveItemMessage?: Maybe<Scalars['String']['output']>
  belowItemMessage?: Maybe<Scalars['String']['output']>
  /** A key that UIs can use to map to icon files */
  icon?: Maybe<OnboardingIcon>
  statusBadge?: Maybe<OnboardingLegacyStatusBadge>
  timeCreated?: Maybe<Scalars['DateTime']['output']>
  timeUpdated?: Maybe<Scalars['DateTime']['output']>
}

export type OnboardingItemMetadataV2 = {
  aboveItemMessage?: Maybe<Scalars['String']['output']>
  belowItemMessage?: Maybe<Scalars['String']['output']>
}

export type OnboardingItemV2 = {
  action?: Maybe<OnboardingAction>
  id: Scalars['ID']['output']
  metadata?: Maybe<OnboardingItemMetadataV2>
  status: OnboardingStatus
  title: Scalars['String']['output']
}

export type OnboardingLegacyBadgeType =
  | 'ERROR'
  | 'INFO'
  | 'NEUTRAL'
  | 'NEUTRAL3'
  | 'SUCCESS'
  | 'WARNING'

/**  In the future, dynamic links should be resolved before being sent to the client  */
export type OnboardingLegacyDynamicLinkAction = {
  __typename: 'OnboardingLegacyDynamicLinkAction'
  desktopUrl: Scalars['String']['output']
  isDynamicUrl: Scalars['Boolean']['output']
  mobileUrl: Scalars['String']['output']
}

export type OnboardingLegacyItem = {
  __typename: 'OnboardingLegacyItem'
  aboveItemMessage?: Maybe<Scalars['String']['output']>
  action?: Maybe<OnboardingStepAction>
  belowItemMessage?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  status: OnboardingStatus
  statusBadge?: Maybe<OnboardingLegacyStatusBadge>
  timeCreated?: Maybe<Scalars['DateTime']['output']>
  timeUpdated?: Maybe<Scalars['DateTime']['output']>
  title: Scalars['String']['output']
}

export type OnboardingLegacyItemDetails = {
  __typename: 'OnboardingLegacyItemDetails'
  /** Often used when there are no items */
  description?: Maybe<Scalars['String']['output']>
  legacyItems: Array<OnboardingLegacyItem>
}

export type OnboardingLegacyStatusBadge = {
  __typename: 'OnboardingLegacyStatusBadge'
  text: Scalars['String']['output']
  tooltipText?: Maybe<Scalars['String']['output']>
  type: OnboardingLegacyBadgeType
}

export type OnboardingLinkAction = {
  __typename: 'OnboardingLinkAction'
  /** @deprecated use OnboardingNavigationAction */
  url: Scalars['String']['output']
}

export type OnboardingMeetingItem = OnboardingItemV2 & {
  __typename: 'OnboardingMeetingItem'
  action?: Maybe<OnboardingAction>
  id: Scalars['ID']['output']
  metadata?: Maybe<OnboardingMeetingItemMetadata>
  status: OnboardingStatus
  title: Scalars['String']['output']
}

export type OnboardingMeetingItemMetadata = OnboardingItemMetadataV2 & {
  __typename: 'OnboardingMeetingItemMetadata'
  aboveItemMessage?: Maybe<Scalars['String']['output']>
  actionSheet: Array<OnboardingInfo>
  agenda: Array<OnboardingInfo>
  belowItemMessage?: Maybe<Scalars['String']['output']>
  /** Identifies the conferencing tool (Zoom) */
  conferencing?: Maybe<ConferencingPlatform>
  /** Duration of the meeting in minutes */
  duration?: Maybe<Scalars['Int']['output']>
  joinLink?: Maybe<Scalars['String']['output']>
  /** The date and time of the meeting */
  meetingDateTime?: Maybe<Scalars['String']['output']>
  /** The Toast rep who will host the meeting */
  meetingHost?: Maybe<Scalars['String']['output']>
  /** The timezone of the meeting */
  meetingTimezone?: Maybe<Scalars['String']['output']>
  preparationSteps: Array<OnboardingInfo>
  rescheduleUrl?: Maybe<Scalars['String']['output']>
}

/** A step representing a scheduled meeting */
export type OnboardingMeetingStep = OnboardingSetupStep & {
  __typename: 'OnboardingMeetingStep'
  details?: Maybe<OnboardingMeetingStepDetails>
  /** A key that UIs can use to map to icon files */
  icon?: Maybe<OnboardingIcon>
  id: Scalars['ID']['output']
  module: OnboardingModule
  status: OnboardingStatus
  title: Scalars['String']['output']
}

export type OnboardingMeetingStepDetails = OnboardingSetupStepDetails & {
  __typename: 'OnboardingMeetingStepDetails'
  actions: Array<Maybe<OnboardingAction>>
  /** Identifies the conferencing tool (Zoom) */
  conferencing?: Maybe<Scalars['String']['output']>
  description?: Maybe<Scalars['MarkdownText']['output']>
  id: Scalars['ID']['output']
  info: Array<Maybe<OnboardingStepInfo>>
  /** The date and time of the meeting */
  meetingDateTime?: Maybe<Scalars['String']['output']>
  /** Duration of the meeting in minutes */
  meetingDuration?: Maybe<Scalars['Int']['output']>
  /** The Toast rep who will host the meeting */
  meetingHost?: Maybe<Scalars['String']['output']>
  /** The timezone of the meeting */
  meetingTimezone?: Maybe<Scalars['String']['output']>
  title: Scalars['String']['output']
}

export type OnboardingModule =
  | 'BUSINESS_VERIFICATION'
  /** The core POS onboarding experience */
  | 'CORE'
  /** A set of onboarding steps that are mostly informational and only allow a limited set of actions */
  | 'LITE'
  /** Steps related to onboarding meetings */
  | 'MEETING'
  /** Additional meetings that are not a core part of the onboarding experience */
  | 'NONSTANDARD_MEETING'
  /** Additional product modules */
  | 'PRODUCT'

export type OnboardingMutation = {
  __typename: 'OnboardingMutation'
  updateAnticipatedPosGoLiveDate: LocationSetupInformation
}

export type OnboardingMutationUpdateAnticipatedPosGoLiveDateArgs = {
  date: Scalars['String']['input']
  locationId: Scalars['ID']['input']
}

export type OnboardingNavigationAction = OnboardingAction & {
  __typename: 'OnboardingNavigationAction'
  /** When the type is LINK_AND_COMPLETE this task is completed on click */
  associatedTask?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['ID']['output']>
  title: Scalars['String']['output']
  type: OnboardingActionType
  /** Where to navigate when the action is taken */
  url: Scalars['String']['output']
}

export type OnboardingQuery = {
  __typename: 'OnboardingQuery'
  /** Get hardware order details and shipping information for a location */
  hardwareOrder?: Maybe<HardwareOrder>
  /** Get general onboarding information for a location */
  locationSetupInformation: LocationSetupInformation
  /** @deprecated Use locationSetupStepsV2 */
  locationSetupSteps?: Maybe<Array<OnboardingSetupStep>>
  locationSetupStepsV2?: Maybe<Array<OnboardingSetupStepV2>>
  /** @deprecated Use locationSetupStepsV2 */
  onboardingSteps?: Maybe<Array<OnboardingStep>>
}

export type OnboardingQueryHardwareOrderArgs = {
  locationId: Scalars['ID']['input']
}

export type OnboardingQueryLocationSetupInformationArgs = {
  locationId: Scalars['ID']['input']
}

export type OnboardingQueryLocationSetupStepsArgs = {
  filter: OnboardingStepsFilter
  locationId: Scalars['ID']['input']
}

export type OnboardingQueryLocationSetupStepsV2Args = {
  filter?: InputMaybe<OnboardingStepsFilterV2>
  locationId: Scalars['ID']['input']
}

export type OnboardingQueryOnboardingStepsArgs = {
  filter?: InputMaybe<OnboardingStepsFilter>
  locationId: Scalars['ID']['input']
}

export type OnboardingScheduleAppointmentLinkAction = {
  __typename: 'OnboardingScheduleAppointmentLinkAction'
  /** @deprecated no longer in use */
  scheduleUrl: Scalars['String']['output']
}

export type OnboardingSetupStep = {
  details?: Maybe<OnboardingSetupStepDetails>
  /** A key that UIs can use to map to icon files */
  icon?: Maybe<OnboardingIcon>
  id: Scalars['ID']['output']
  module: OnboardingModule
  status: OnboardingStatus
  title: Scalars['String']['output']
}

export type OnboardingSetupStepDetails = {
  actions: Array<Maybe<OnboardingAction>>
  description?: Maybe<Scalars['MarkdownText']['output']>
  id: Scalars['ID']['output']
  info: Array<Maybe<OnboardingStepInfo>>
  title: Scalars['String']['output']
}

export type OnboardingSetupStepV2 = {
  /** A key that UIs can use to map to icon files */
  icon?: Maybe<OnboardingIcon>
  id: Scalars['ID']['output']
  status: OnboardingStatus
  subtext?: Maybe<Scalars['String']['output']>
  title: Scalars['String']['output']
}

export type OnboardingShareAction = OnboardingAction & {
  __typename: 'OnboardingShareAction'
  id?: Maybe<Scalars['ID']['output']>
  message?: Maybe<Scalars['String']['output']>
  subject: Scalars['String']['output']
  title: Scalars['String']['output']
  type: OnboardingActionType
  url: Scalars['String']['output']
}

export type OnboardingStatus =
  | 'CANCELED'
  | 'COMPLETED'
  | 'CONFIRMED'
  | 'FAILED'
  | 'IN_PROGRESS'
  | 'LOCKED'
  | 'MISSED'
  | 'NOT_STARTED'
  | 'ON_HOLD'
  | 'SCHEDULED'
  | 'SHIPPED'
  | 'SKIPPED'

export type OnboardingStep = {
  __typename: 'OnboardingStep'
  description?: Maybe<Scalars['String']['output']>
  details?: Maybe<OnboardingStepDetails>
  /** A key that UIs can use to map to icon files */
  icon?: Maybe<OnboardingIcon>
  id: Scalars['ID']['output']
  /** @deprecated Use Step instead */
  itemDetails?: Maybe<OnboardingLegacyItemDetails>
  module: OnboardingModule
  status: OnboardingStatus
  title: Scalars['String']['output']
}

export type OnboardingStepAction =
  | OnboardingLegacyDynamicLinkAction
  | OnboardingLinkAction
  | OnboardingScheduleAppointmentLinkAction

export type OnboardingStepDetails = {
  __typename: 'OnboardingStepDetails'
  action?: Maybe<OnboardingStepAction>
  description?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  substeps: Array<OnboardingStep>
  title: Scalars['String']['output']
}

/** Information about the step that can be found in the step details object */
export type OnboardingStepInfo = {
  /** An optional action associated with the information (like following a link) */
  action?: Maybe<OnboardingAction>
  description?: Maybe<Scalars['MarkdownText']['output']>
  icon?: Maybe<OnboardingIcon>
  /** ID is nullable to preserve backwards compatibility */
  id?: Maybe<Scalars['ID']['output']>
  title?: Maybe<Scalars['String']['output']>
}

export type OnboardingStepTypes =
  | 'ACCOUNT'
  | 'GO_LIVE'
  | 'HARDWARE'
  | 'MEETING'
  | 'MENU'
  | 'SETTINGS'
  | 'TEAM'

/**  Optional filters for fetching onboarding steps. Return null to not filter by that field.  */
export type OnboardingStepsFilter = {
  /** Only return steps associated with a given module */
  modules?: InputMaybe<Array<OnboardingModule>>
  /** Only return steps with a given status */
  status?: InputMaybe<Array<OnboardingStatus>>
}

export type OnboardingStepsFilterV2 = {
  stepTypes: Array<OnboardingStepTypes>
}

export type OnlineOrderingAvailability = 'OFF' | 'ON' | 'SNOOZE'

export type OnlineOrderingStatusLabel = {
  __typename: 'OnlineOrderingStatusLabel'
  state: OnlineOrderingStatusLabelState
  textContent: Scalars['String']['output']
}

export type OnlineOrderingStatusLabelState =
  | 'CLOSED'
  | 'DISABLED'
  | 'OPEN'
  | 'SNOOZED'

export type OpenCashDrawerReason = {
  __typename: 'OpenCashDrawerReason'
  description: Scalars['String']['output']
  id: Scalars['ID']['output']
  isActive: Scalars['Boolean']['output']
  isArchived: Scalars['Boolean']['output']
  name: Scalars['String']['output']
  ordinal: Scalars['Int']['output']
}

export type OperatingSchedule = {
  __typename: 'OperatingSchedule'
  daySchedules?: Maybe<Array<Maybe<DayOperatingSchedule>>>
  services?: Maybe<Array<Maybe<OperatingScheduleService>>>
  weekSchedule?: Maybe<WeekOperatingSchedule>
}

export type OperatingScheduleService = {
  __typename: 'OperatingScheduleService'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  order?: Maybe<Scalars['Int']['output']>
}

export type OperatingScheduleServicePeriod = {
  __typename: 'OperatingScheduleServicePeriod'
  endTime: Scalars['LocalTime']['output']
  overnight: Scalars['Boolean']['output']
  service: OperatingScheduleService
  startTime: Scalars['LocalTime']['output']
}

export type OptMenuConfig = {
  __typename: 'OptMenuConfig'
  menusEnabled: Scalars['Boolean']['output']
  showFeaturedItems: Scalars['Boolean']['output']
  showPopularItems: Scalars['Boolean']['output']
}

export type OptMenuConfigInput = {
  menusEnabled: Scalars['Boolean']['input']
  showFeaturedItems: Scalars['Boolean']['input']
  showPopularItems: Scalars['Boolean']['input']
}

export type OptMenuConfigOverride = {
  __typename: 'OptMenuConfigOverride'
  fields: Array<ConfigField>
  menusEnabled?: Maybe<Scalars['Boolean']['output']>
  showFeaturedItems?: Maybe<Scalars['Boolean']['output']>
  showPopularItems?: Maybe<Scalars['Boolean']['output']>
  target: RestaurantSet
}

export type OptMenuConfigOverrideInput = {
  fields: Array<ConfigFieldInput>
  menusEnabled?: InputMaybe<Scalars['Boolean']['input']>
  showFeaturedItems?: InputMaybe<Scalars['Boolean']['input']>
  showPopularItems?: InputMaybe<Scalars['Boolean']['input']>
  target: RestaurantSetInput
}

export type OptMutation = {
  __typename: 'OptMutation'
  deleteOptMenuConfigOverrides: OptSavedMenuConfig
  deleteOptOrderingConfigOverrides: OptSavedOrderingConfig
  deleteOptPaymentConfigOverrides: OptSavedPaymentConfig
  deleteOptSpotlightConfigOverrides: OptSavedSpotlightConfig
  saveOptMenuConfigBase: OptMenuConfig
  saveOptMenuConfigOverrides: OptSavedMenuConfig
  saveOptOrderingConfigBase: OptOrderingConfig
  saveOptOrderingConfigOverrides: OptSavedOrderingConfig
  saveOptPaymentConfigBase: OptPaymentConfig
  saveOptPaymentConfigOverrides: OptSavedPaymentConfig
  saveOptSpotlightConfigBase: OptSpotlightConfig
  saveOptSpotlightConfigOverrides: OptSavedSpotlightConfig
}

export type OptMutationDeleteOptMenuConfigOverridesArgs = {
  targets: Array<RestaurantSetInput>
}

export type OptMutationDeleteOptOrderingConfigOverridesArgs = {
  targets: Array<RestaurantSetInput>
}

export type OptMutationDeleteOptPaymentConfigOverridesArgs = {
  targets: Array<RestaurantSetInput>
}

export type OptMutationDeleteOptSpotlightConfigOverridesArgs = {
  targets: Array<RestaurantSetInput>
}

export type OptMutationSaveOptMenuConfigBaseArgs = {
  input: OptMenuConfigInput
}

export type OptMutationSaveOptMenuConfigOverridesArgs = {
  input: Array<OptMenuConfigOverrideInput>
}

export type OptMutationSaveOptOrderingConfigBaseArgs = {
  input: OptOrderingConfigInput
}

export type OptMutationSaveOptOrderingConfigOverridesArgs = {
  input: Array<OptOrderingConfigOverrideInput>
}

export type OptMutationSaveOptPaymentConfigBaseArgs = {
  input: OptPaymentConfigInput
}

export type OptMutationSaveOptPaymentConfigOverridesArgs = {
  input: Array<OptPaymentConfigOverrideInput>
}

export type OptMutationSaveOptSpotlightConfigBaseArgs = {
  input: OptSpotlightConfigInput
}

export type OptMutationSaveOptSpotlightConfigOverridesArgs = {
  input: Array<OptSpotlightConfigOverrideInput>
}

export type OptOrderingConfig = {
  __typename: 'OptOrderingConfig'
  orderingEnabled: Scalars['Boolean']['output']
}

export type OptOrderingConfigInput = {
  orderingEnabled: Scalars['Boolean']['input']
}

export type OptOrderingConfigOverride = {
  __typename: 'OptOrderingConfigOverride'
  fields: Array<ConfigField>
  orderingEnabled?: Maybe<Scalars['Boolean']['output']>
  target: RestaurantSet
}

export type OptOrderingConfigOverrideInput = {
  fields: Array<ConfigFieldInput>
  orderingEnabled?: InputMaybe<Scalars['Boolean']['input']>
  target: RestaurantSetInput
}

export type OptPaymentConfig = {
  __typename: 'OptPaymentConfig'
  paymentEnabled: Scalars['Boolean']['output']
}

export type OptPaymentConfigInput = {
  paymentEnabled: Scalars['Boolean']['input']
}

export type OptPaymentConfigOverride = {
  __typename: 'OptPaymentConfigOverride'
  fields: Array<ConfigField>
  paymentEnabled?: Maybe<Scalars['Boolean']['output']>
  target: RestaurantSet
}

export type OptPaymentConfigOverrideInput = {
  fields: Array<ConfigFieldInput>
  paymentEnabled?: InputMaybe<Scalars['Boolean']['input']>
  target: RestaurantSetInput
}

export type OptQuery = {
  __typename: 'OptQuery'
  resolvedOptMenuConfig: OptMenuConfig
  resolvedOptOrderingConfig: OptOrderingConfig
  resolvedOptPaymentConfig: OptPaymentConfig
  resolvedOptSpotlightConfig: OptSpotlightConfig
  savedOptMenuConfig: OptSavedMenuConfig
  savedOptOrderingConfig: OptSavedOrderingConfig
  savedOptPaymentConfig: OptSavedPaymentConfig
  savedOptSpotlightConfig: OptSavedSpotlightConfig
}

export type OptSavedMenuConfig = {
  __typename: 'OptSavedMenuConfig'
  baseConfig: OptMenuConfig
  overrides?: Maybe<Array<OptMenuConfigOverride>>
}

export type OptSavedOrderingConfig = {
  __typename: 'OptSavedOrderingConfig'
  baseConfig: OptOrderingConfig
  overrides?: Maybe<Array<OptOrderingConfigOverride>>
}

export type OptSavedPaymentConfig = {
  __typename: 'OptSavedPaymentConfig'
  baseConfig: OptPaymentConfig
  overrides?: Maybe<Array<OptPaymentConfigOverride>>
}

export type OptSavedSpotlightConfig = {
  __typename: 'OptSavedSpotlightConfig'
  baseConfig: OptSpotlightConfig
  overrides?: Maybe<Array<OptSpotlightConfigOverride>>
}

export type OptSpotlightConfig = {
  __typename: 'OptSpotlightConfig'
  body: Scalars['String']['output']
  header: Scalars['String']['output']
  visible: Scalars['Boolean']['output']
}

export type OptSpotlightConfigInput = {
  body: Scalars['String']['input']
  header: Scalars['String']['input']
  visible: Scalars['Boolean']['input']
}

export type OptSpotlightConfigOverride = {
  __typename: 'OptSpotlightConfigOverride'
  body?: Maybe<Scalars['String']['output']>
  fields: Array<ConfigField>
  header?: Maybe<Scalars['String']['output']>
  target: RestaurantSet
  visible?: Maybe<Scalars['Boolean']['output']>
}

export type OptSpotlightConfigOverrideInput = {
  body?: InputMaybe<Scalars['String']['input']>
  fields: Array<ConfigFieldInput>
  header?: InputMaybe<Scalars['String']['input']>
  target: RestaurantSetInput
  visible?: InputMaybe<Scalars['Boolean']['input']>
}

/**  Wrapper around a nullable value. If the object is null, use the default/existing value. If the object is not null, overwrite the existing value with the object's value  */
export type OptionalFloat = {
  value?: InputMaybe<Scalars['Float']['input']>
}

/**  Wrapper around a nullable value. If the object is null, use the default/existing value. If the object is not null, overwrite the existing value with the object's value  */
export type OptionalId = {
  value?: InputMaybe<Scalars['ID']['input']>
}

/**  Wrapper around a nullable value. If the object is null, use the default/existing value. If the object is not null, overwrite the existing value with the object's value  */
export type OptionalInt = {
  value?: InputMaybe<Scalars['Int']['input']>
}

/**  See: https://doc.toasttab.com/openapi/orders/tag/Data-definitions/schema/Order/  */
export type Order = {
  __typename: 'Order'
  channel?: Maybe<PublishedChannel>
  /**  Provides optional filters: paymentStatus  */
  checks: Array<Check>
  curbsidePickupInfo?: Maybe<OrdersCurbsidePickupInfo>
  deleted?: Maybe<Scalars['Boolean']['output']>
  diningOption?: Maybe<SavedDiningOption>
  displayNumber?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  numberOfGuests?: Maybe<Scalars['Int']['output']>
  promisedDate?: Maybe<Scalars['DateTime']['output']>
  server?: Maybe<RestaurantUser>
  table?: Maybe<SavedRestaurantTable>
}

/**  See: https://doc.toasttab.com/openapi/orders/tag/Data-definitions/schema/Order/  */
export type OrderChecksArgs = {
  checkPaymentStatus?: InputMaybe<CheckPaymentStatus>
}

export type OrderConfigEditabilityLevel =
  | 'EDITABLE'
  | 'HIDDEN'
  | 'UNARCHIVABLE'
  | 'UNEDITABLE'

export type OrderConfirmationScreenConfigInput = {
  backgroundColor?: InputMaybe<Scalars['String']['input']>
  fontPrimaryColor?: InputMaybe<Scalars['String']['input']>
  fontSecondaryColor?: InputMaybe<Scalars['String']['input']>
  fontSize?: InputMaybe<OrderConfirmationScreenFontSize>
  fontUrl?: InputMaybe<Scalars['String']['input']>
  idleBackgroundImageDurationMs?: InputMaybe<Scalars['Int']['input']>
  idleBackgroundImageUrls?: InputMaybe<Array<Scalars['String']['input']>>
  orderInProgressBackgroundImageUrl?: InputMaybe<Scalars['String']['input']>
  orderInProgressForegroundColor?: InputMaybe<Scalars['String']['input']>
  thankYouMessage?: InputMaybe<Scalars['String']['input']>
  thankYouTitle?: InputMaybe<Scalars['String']['input']>
}

export type OrderConfirmationScreenConfigOverride = {
  __typename: 'OrderConfirmationScreenConfigOverride'
  backgroundColor?: Maybe<Scalars['String']['output']>
  fields: Array<ConfigField>
  fontPrimaryColor?: Maybe<Scalars['String']['output']>
  fontSecondaryColor?: Maybe<Scalars['String']['output']>
  fontSize?: Maybe<OrderConfirmationScreenFontSize>
  fontUrl?: Maybe<Scalars['String']['output']>
  idleBackgroundImageDurationMs?: Maybe<Scalars['Int']['output']>
  idleBackgroundImageUrls?: Maybe<Array<Scalars['String']['output']>>
  orderInProgressBackgroundImageUrl?: Maybe<Scalars['String']['output']>
  orderInProgressForegroundColor?: Maybe<Scalars['String']['output']>
  target: RestaurantSet
  thankYouMessage?: Maybe<Scalars['String']['output']>
  thankYouTitle?: Maybe<Scalars['String']['output']>
}

export type OrderConfirmationScreenConfigOverrideInput = {
  backgroundColor?: InputMaybe<Scalars['String']['input']>
  fields: Array<ConfigFieldInput>
  fontPrimaryColor?: InputMaybe<Scalars['String']['input']>
  fontSecondaryColor?: InputMaybe<Scalars['String']['input']>
  fontSize?: InputMaybe<OrderConfirmationScreenFontSize>
  fontUrl?: InputMaybe<Scalars['String']['input']>
  idleBackgroundImageDurationMs?: InputMaybe<Scalars['Int']['input']>
  idleBackgroundImageUrls?: InputMaybe<Array<Scalars['String']['input']>>
  orderInProgressBackgroundImageUrl?: InputMaybe<Scalars['String']['input']>
  orderInProgressForegroundColor?: InputMaybe<Scalars['String']['input']>
  target: RestaurantSetInput
  thankYouMessage?: InputMaybe<Scalars['String']['input']>
  thankYouTitle?: InputMaybe<Scalars['String']['input']>
}

export type OrderConfirmationScreenFontSize = 'Large' | 'Medium' | 'Small'

/** Count of orders */
export type OrderCount = {
  __typename: 'OrderCount'
  /** Hourly order count */
  hourly: Array<IntegerMetric>
  /** Total order count */
  totals: IntegerMetric
}

export type OrderDeliveryServicesProvider =
  | 'DOORDASH'
  | 'TOAST_DELIVERY_SERVICES'
  | 'UBER'

export type OrderDiningOptionBehavior = 'DELIVERY' | 'DINE_IN' | 'TAKE_OUT'

/** Order-level metrics */
export type OrderMetrics = {
  __typename: 'OrderMetrics'
  /** Checks with refunds count, i.e., the number of checks that have at least one refund associated with them */
  checksWithRefundsCount?: Maybe<ChecksWithRefundsCount>
  /** Checks with voids count with optional filter; i.e., the number of checks that have at least one void associated with them */
  checksWithVoidsCount?: Maybe<ChecksWithVoidsCount>
  /** Discount amount with optional filter, i.e., the total amount of money discounted */
  discountAmount?: Maybe<DiscountAmount>
  /** Discount count with optional filter, i.e., the number of discounts applied */
  discountCount?: Maybe<DiscountCount>
  /** Guest count */
  guestCount?: Maybe<GuestCount>
  /** Net sales */
  netSales?: Maybe<NetSales>
  /** Order count */
  orderCount?: Maybe<OrderCount>
  /** Total refund amount, i.e., the total amount of money refunded, including refunded tax and gratuities */
  totalRefundAmount?: Maybe<TotalRefundAmount>
  /** Void amount with optional filter, i.e., the total amount of money voided */
  voidAmount?: Maybe<VoidAmount>
  /** Void amount as percentage of net sales, i.e., the total amount of money voided as a percentage of net sales */
  voidAmountAsPercentageOfNetSales?: Maybe<VoidAmountAsPercentageOfNetSales>
  /** Voided items count with optional filter, i.e., the number of items that have been voided */
  voidedItemsCount?: Maybe<VoidedItemsCount>
}

/** Order-level metrics */
export type OrderMetricsChecksWithVoidsCountArgs = {
  filter?: InputMaybe<VoidFilter>
}

/** Order-level metrics */
export type OrderMetricsDiscountAmountArgs = {
  filter?: InputMaybe<DiscountFilter>
}

/** Order-level metrics */
export type OrderMetricsDiscountCountArgs = {
  filter?: InputMaybe<DiscountFilter>
}

/** Order-level metrics */
export type OrderMetricsVoidAmountArgs = {
  filter?: InputMaybe<VoidFilter>
}

/** Order-level metrics */
export type OrderMetricsVoidedItemsCountArgs = {
  filter?: InputMaybe<VoidFilter>
}

export type OrderValidityCheck = {
  __typename: 'OrderValidityCheck'
  entityId: Scalars['String']['output']
  featureGroupId: Scalars['String']['output']
  invalidLocations?: Maybe<Array<InvalidLocation>>
  validLocations?: Maybe<Array<Scalars['String']['output']>>
}

export type OrderValidityCheckResponse = {
  __typename: 'OrderValidityCheckResponse'
  orderValidityCheck: OrderValidityCheck
  status: TrialOperationStatus
}

export type OrderingSchedule = {
  __typename: 'OrderingSchedule'
  closeoutHour: Scalars['Int']['output']
  lastOrderConfiguration: Scalars['String']['output']
  maxDayPeriodTimeRange: Scalars['Int']['output']
  overrides: Array<Overrides>
  schedule: Array<Schedule>
  scheduledOrderMaxDays: Scalars['Int']['output']
}

export type OrderingScheduleInput = {
  overrides: Array<OverridesInput>
  schedule: Array<ScheduleInput>
}

export type OrdersAppliedDiscount = {
  __typename: 'OrdersAppliedDiscount'
  discountAmount?: Maybe<Money>
  id: Scalars['ID']['output']
  name?: Maybe<Scalars['String']['output']>
}

export type OrdersAppliedLoyaltyInfo = {
  __typename: 'OrdersAppliedLoyaltyInfo'
  accrualText?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  /**  A masked representation of the identifier of the loyalty program account that can be displayed securely. Surfaces the Orders API's maskedLoyaltyIdentifier field, masking the raw identifier with asterisks in the event that the field is missing in the Orders API's response.  */
  maskedLoyaltyIdentifier?: Maybe<Scalars['String']['output']>
}

export type OrdersAppliedPreauthInfo = {
  __typename: 'OrdersAppliedPreauthInfo'
  cardHolderFirstName?: Maybe<Scalars['String']['output']>
  cardHolderLastName?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  last4CardDigits?: Maybe<Scalars['String']['output']>
}

export type OrdersConfigMutation = {
  __typename: 'OrdersConfigMutation'
  /** Update an existing dining option */
  updateDiningOption: SavedDiningOption
}

export type OrdersConfigMutationUpdateDiningOptionArgs = {
  input: UpdateDiningOptionInput
}

export type OrdersConfigQuery = {
  __typename: 'OrdersConfigQuery'
  /** Saved dining option for a given id (guid) */
  savedDiningOption?: Maybe<SavedDiningOption>
  /** All saved dining options for a given restaurant */
  savedDiningOptions: Array<SavedDiningOption>
  /** Saved revenue centers given a search criteria */
  savedRevenueCenters: Array<SavedRevenueCenter>
}

export type OrdersConfigQuerySavedDiningOptionArgs = {
  id: Scalars['ID']['input']
}

export type OrdersConfigQuerySavedDiningOptionsArgs = {
  includeArchived?: Scalars['Boolean']['input']
}

export type OrdersConfigQuerySavedRevenueCentersArgs = {
  search: SavedRevenueCenterSearch
}

export type OrdersCurbsidePickupInfo = {
  __typename: 'OrdersCurbsidePickupInfo'
  id: Scalars['ID']['output']
  notes?: Maybe<Scalars['String']['output']>
  transportColor?: Maybe<Scalars['String']['output']>
  transportDescription?: Maybe<Scalars['String']['output']>
}

export type OrdersFulfillmentStatus =
  | 'HOLD'
  | 'NEW'
  | 'READY'
  | 'SENT'
  | 'UNRECOGNIZED'

export type OrdersGiftCardInfo = {
  __typename: 'OrdersGiftCardInfo'
  last4CardDigits?: Maybe<Scalars['String']['output']>
}

export type OrdersMutation = {
  __typename: 'OrdersMutation'
  /**  Updates the tip amount in an existing payment for a check in an order. Returns the Order with its updated tip. Requires the MANAGER_BIT  */
  updateTipAmount?: Maybe<Order>
}

export type OrdersMutationUpdateTipAmountArgs = {
  input: UpdateTipAmountInput
}

export type OrdersPayment = {
  __typename: 'OrdersPayment'
  amount?: Maybe<Money>
  canBeTipAdjusted?: Maybe<Scalars['Boolean']['output']>
  cardType?: Maybe<OrdersPaymentCardType>
  giftCardInfo?: Maybe<OrdersGiftCardInfo>
  id: Scalars['ID']['output']
  last4Digits?: Maybe<Scalars['String']['output']>
  paymentStatus?: Maybe<OrdersPaymentStatus>
  /** The total amount for this payment after refunds are applied. */
  postRefundTotal?: Maybe<Money>
  refund?: Maybe<OrdersRefund>
  refundStatus?: Maybe<OrdersPaymentRefundStatus>
  tipAmount?: Maybe<Money>
  /**  Convenience field that calculates the total amount of the payment, including the tip amount.  */
  totalAmount?: Maybe<Money>
  type?: Maybe<OrdersPaymentType>
}

export type OrdersPaymentCardType =
  | 'AMEX'
  | 'CIT'
  | 'DINERS'
  | 'DISCOVER'
  | 'INTERAC'
  | 'JCB'
  | 'LASER'
  | 'MAESTRO'
  | 'MASTERCARD'
  | 'SOLO'
  | 'UNRECOGNIZED'
  | 'VISA'

export type OrdersPaymentRefundStatus =
  | 'FULL'
  | 'NONE'
  | 'PARTIAL'
  | 'UNRECOGNIZED'

export type OrdersPaymentStatus =
  | 'DECLINED'
  | 'FAILED'
  | 'NONE'
  | 'PENDING'
  | 'REFUNDED'
  | 'UNRECOGNIZED'
  | 'VOIDED'
  | 'VOIDING'

export type OrdersPaymentType =
  | 'CASH'
  | 'CREDIT'
  | 'GIFTCARD'
  | 'OTHER'
  | 'UNRECOGNIZED'

export type OrdersQuery = {
  __typename: 'OrdersQuery'
  check?: Maybe<Check>
  ordersSummary?: Maybe<OrdersSummary>
}

export type OrdersQueryCheckArgs = {
  checkId: Scalars['ID']['input']
  orderId: Scalars['ID']['input']
}

export type OrdersQueryOrdersSummaryArgs = {
  businessDate: Scalars['Date']['input']
  checkPaymentStatus?: InputMaybe<CheckPaymentStatus>
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>
}

/** Refund details for a OrdersPayment */
export type OrdersRefund = {
  __typename: 'OrdersRefund'
  refundAmount?: Maybe<Money>
  tipRefundAmount?: Maybe<Money>
  totalRefundAmount?: Maybe<Money>
}

/** Refund details for an OrdersSelection */
export type OrdersRefundDetails = {
  __typename: 'OrdersRefundDetails'
  refundAmount?: Maybe<Money>
  taxRefundAmount?: Maybe<Money>
}

export type OrdersSelection = {
  __typename: 'OrdersSelection'
  appliedDiscounts: Array<OrdersAppliedDiscount>
  createdDate?: Maybe<Scalars['DateTime']['output']>
  diningOption?: Maybe<SavedDiningOption>
  displayName?: Maybe<Scalars['String']['output']>
  fulfillmentStatus?: Maybe<OrdersFulfillmentStatus>
  id: Scalars['ID']['output']
  modifiedDate?: Maybe<Scalars['DateTime']['output']>
  modifiers: Array<OrdersSelection>
  preDiscountPrice?: Maybe<Money>
  price?: Maybe<Money>
  quantity?: Maybe<Scalars['Float']['output']>
  receiptLinePrice?: Maybe<Money>
  refundDetails?: Maybe<OrdersRefundDetails>
  seatNumber?: Maybe<Scalars['Int']['output']>
  selectionType?: Maybe<OrdersSelectionType>
  tax?: Maybe<Money>
  taxInclusion?: Maybe<OrdersTaxInclusion>
  unitOfMeasure?: Maybe<OrdersUnitOfMeasure>
  voidBusinessDate?: Maybe<Scalars['Int']['output']>
  voidDate?: Maybe<Scalars['DateTime']['output']>
  voided?: Maybe<Scalars['Boolean']['output']>
}

export type OrdersSelectionType =
  | 'HOUSE_ACCOUNT_PAY_BALANCE'
  | 'NONE'
  | 'OPEN_ITEM'
  | 'PORTION'
  | 'SPECIAL_REQUEST'
  | 'TOAST_CARD_RELOAD'
  | 'TOAST_CARD_SELL'
  | 'UNRECOGNIZED'

export type OrdersSummary = {
  __typename: 'OrdersSummary'
  /** The count of orders used for summarization */
  count: Scalars['Int']['output']
  /** The total number of checks on all orders summarized */
  countOfChecks: Scalars['Int']['output']
  /** The orders summarized */
  orders?: Maybe<Array<Order>>
  /**  The sum of the total price amount of all checks on all orders summarized  */
  sumOfChecksTotalAmount: Money
}

export type OrdersTaxInclusion =
  | 'INCLUDED'
  | 'INHERITED'
  | 'NOT_INCLUDED'
  | 'UNRECOGNIZED'

export type OrdersUnitOfMeasure =
  | 'G'
  | 'KG'
  | 'LB'
  | 'NONE'
  | 'OZ'
  | 'UNRECOGNIZED'

export type OutdatedPackagingConfigError = {
  __typename: 'OutdatedPackagingConfigError'
  currentConfig: SavedPackagingConfig
}

export type OverlappingBreaks = ShiftError & {
  __typename: 'OverlappingBreaks'
  message?: Maybe<Scalars['String']['output']>
}

export type OverlappingShiftExists = ShiftError & {
  __typename: 'OverlappingShiftExists'
  message?: Maybe<Scalars['String']['output']>
}

export type OverrideDateRange = {
  __typename: 'OverrideDateRange'
  endBusinessDate: Scalars['Int']['output']
  startBusinessDate: Scalars['Int']['output']
}

export type OverridePricing = {
  __typename: 'OverridePricing'
  basePrice?: Maybe<Money>
  hasOverridePrice?: Maybe<Scalars['Boolean']['output']>
}

export type Overrides = {
  __typename: 'Overrides'
  /** @deprecated Use dateRange */
  businessDate: Scalars['Int']['output']
  dateRange: OverrideDateRange
  description?: Maybe<Scalars['String']['output']>
  diningOptionBehavior?: Maybe<Array<DiningOptionBehavior>>
  timeRanges?: Maybe<Array<LocalTimeRange>>
}

export type OverridesDateRangeInput = {
  endBusinessDate: Scalars['Int']['input']
  startBusinessDate: Scalars['Int']['input']
}

export type OverridesInput = {
  /** @deprecated Use dateRange */
  businessDate?: InputMaybe<Scalars['Int']['input']>
  dateRange?: InputMaybe<OverridesDateRangeInput>
  description?: InputMaybe<Scalars['String']['input']>
  diningOptionBehavior: Array<DiningOptionBehavior>
  timeRanges: Array<LocalTimeRangeInput>
}

export type PackagingComplianceData = {
  __typename: 'PackagingComplianceData'
  /**  Indicates if the compliance management feature of packaging is turned on yet for this location/group. If false, mgmtGroupSummary and locationDetails will always be empty  */
  featureEnabled: Scalars['Boolean']['output']
  locationDetails: PackagingComplianceDetails
  mgmtGroupSummary: PackagingComplianceSummary
}

export type PackagingComplianceDetails = {
  __typename: 'PackagingComplianceDetails'
  /**  Regulations applicable at any location in the management group  */
  applicableRegulations: Array<PackagingRegulation>
  /**  Location-level packaging information. Locations with no applicable regulations are not returned in this list  */
  locations: Array<PackagingLocationCompliance>
}

export type PackagingComplianceError = {
  __typename: 'PackagingComplianceError'
  nonCompliantLocations: Array<PackagingComplianceLocationError>
}

export type PackagingComplianceLocationError = {
  __typename: 'PackagingComplianceLocationError'
  location: SavedRestaurantLocation
  nonCompliantRegulations: Array<PackagingComplianceRegulationError>
}

export type PackagingComplianceRegulationError = {
  __typename: 'PackagingComplianceRegulationError'
  message: Scalars['String']['output']
  regulationId: Scalars['ID']['output']
}

export type PackagingComplianceSummary = {
  __typename: 'PackagingComplianceSummary'
  applicableRegulations: Array<PackagingRegulation>
  compliant: Scalars['Boolean']['output']
  compliantCount: Scalars['Int']['output']
  nonCompliantCount: Scalars['Int']['output']
}

export type PackagingConfigOverride = {
  __typename: 'PackagingConfigOverride'
  disabledItemTypes: Array<PackagingItemType>
  enabled: Scalars['Boolean']['output']
  guestMessage?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  items: Array<PackagingItem>
  overriddenFields: Array<PackagingField>
  owner: RestaurantSet
  posShowDefaults: Scalars['Boolean']['output']
  revision?: Maybe<Scalars['Int']['output']>
  target: RestaurantSet
}

export type PackagingConfigOverrideUpdate = {
  disabledItemTypes?: InputMaybe<Array<PackagingItemType>>
  enabled: Scalars['Boolean']['input']
  guestMessage?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  items: Array<PackagingItemUpdate>
  overriddenFields: Array<PackagingField>
  owner: RestaurantSetInput
  posShowDefaults: Scalars['Boolean']['input']
  revision?: InputMaybe<Scalars['Int']['input']>
  target: RestaurantSetInput
}

export type PackagingConfigUpdate = {
  disabledItemTypes?: InputMaybe<Array<PackagingItemType>>
  enabled: Scalars['Boolean']['input']
  guestMessage?: InputMaybe<Scalars['String']['input']>
  items: Array<PackagingItemUpdate>
  overrides: Array<PackagingConfigOverrideUpdate>
  posShowDefaults: Scalars['Boolean']['input']
  revision?: InputMaybe<Scalars['Int']['input']>
  toastInternal?: InputMaybe<PackagingUpdateToastInternalOptions>
}

export type PackagingField =
  | 'DISABLED_ITEM_TYPES'
  | 'ENABLED'
  | 'GUEST_MESSAGE'
  | 'ITEMS'
  | 'POS_SHOW_DEFAULTS'

export type PackagingGuestInclusion = 'OPT_IN' | 'OPT_OUT'

export type PackagingItem = {
  __typename: 'PackagingItem'
  guestDescription?: Maybe<Scalars['String']['output']>
  guestInclusionType: PackagingGuestInclusion
  guestName: Scalars['String']['output']
  id?: Maybe<Scalars['ID']['output']>
  itemTypes: Array<PackagingItemType>
  posName?: Maybe<Scalars['String']['output']>
}

export type PackagingItemType =
  | 'BAGS'
  | 'CONDIMENTS'
  | 'NAPKINS'
  | 'STRAWS'
  | 'UTENSILS'

export type PackagingItemUpdate = {
  guestDescription?: InputMaybe<Scalars['String']['input']>
  guestInclusionType: PackagingGuestInclusion
  guestName: Scalars['String']['input']
  id?: InputMaybe<Scalars['ID']['input']>
  itemTypes: Array<PackagingItemType>
  posName?: InputMaybe<Scalars['String']['input']>
}

export type PackagingLocationCompliance = {
  __typename: 'PackagingLocationCompliance'
  /**  True if this location is compliant with all regulations applicable to it. Note that this is also true if 0 regulations are applicable  */
  compliant: Scalars['Boolean']['output']
  location: SavedRestaurantLocation
  /**  Regulation compliance details for this location  */
  locationRegulations: Array<PackagingLocationRegulationCompliance>
  /**  Usually true, this is false in special cases like customer lab locations, where compliance can be ignored  */
  needsCompliance: Scalars['Boolean']['output']
}

export type PackagingLocationRegulationCompliance = {
  __typename: 'PackagingLocationRegulationCompliance'
  /**  Usually false, this is true if the compliance status was overridden manually by Toast due to some issue  */
  complianceOverridden: Scalars['Boolean']['output']
  /**  True if the location is compliant with the single regulation corresponding to the regulationId below  */
  compliant: Scalars['Boolean']['output']
  /**  ID referencing the relevant regulation, which can be found in the applicableRegulations array higher up. Full data is not here so we don't duplicate this potentially hundreds or thousands of time in the response  */
  regulationId: Scalars['ID']['output']
}

export type PackagingMutation = {
  __typename: 'PackagingMutation'
  saveConfig: SavePackagingConfigResponse
}

export type PackagingMutationSaveConfigArgs = {
  input: PackagingConfigUpdate
}

export type PackagingQuery = {
  __typename: 'PackagingQuery'
  compliance: PackagingComplianceData
  config: SavedPackagingConfig
  mgmtGroupLocationCount: Scalars['Int']['output']
  restrictions: PackagingRestrictions
}

export type PackagingRegulation = {
  __typename: 'PackagingRegulation'
  description: Scalars['String']['output']
  effectiveDate: Scalars['Date']['output']
  fullName: Scalars['String']['output']
  id: Scalars['ID']['output']
  officialLink: Scalars['String']['output']
  shortName: Scalars['String']['output']
  toastEnforced: Scalars['Boolean']['output']
}

export type PackagingRestrictions = {
  __typename: 'PackagingRestrictions'
  /**  If true, the user cannot edit the base config. Base config fields can still be submitted as normal as part of a PackagingConfigUpdate, they will just be ignored  */
  baseConfigReadOnly: Scalars['Boolean']['output']
  /**  Specific locations the user cannot edit, empty if baseConfigReadOnly is false  */
  inaccessibleRestaurants: Array<SavedRestaurantLocation>
}

export type PackagingUpdateToastInternalOptions = {
  complianceValidationRegulationIds?: InputMaybe<
    Array<Scalars['String']['input']>
  >
  /**  If true, both the config and the compliance information will be stored even if the compliance validation comes back as noncompliant  */
  saveNonCompliantResults?: InputMaybe<Scalars['Boolean']['input']>
}

/** PageInfo type as per the Connection spec, see https://relay.dev/graphql/connections.htm */
export type PageInfo = {
  __typename: 'PageInfo'
  /** "The cursor that can be used to continue querying with 'after' */
  endCursor?: Maybe<Scalars['String']['output']>
  /** Indicates whether or not the next page exists */
  hasNextPage: Scalars['Boolean']['output']
  /** Indicates whether or not a previous page exists */
  hasPreviousPage: Scalars['Boolean']['output']
  /** The cursor that can be used to continue querying with 'before' */
  startCursor?: Maybe<Scalars['String']['output']>
}

export type PagedMenuItemResponse = {
  __typename: 'PagedMenuItemResponse'
  entities: Array<MenuItem>
  nextPageToken?: Maybe<Scalars['String']['output']>
}

export type PagedModifierGroupsResponse = {
  __typename: 'PagedModifierGroupsResponse'
  entities: Array<ModifierGroup>
  nextPageToken?: Maybe<Scalars['String']['output']>
}

export type PagedModifierResponse = {
  __typename: 'PagedModifierResponse'
  entities: Array<Modifier>
  nextPageToken?: Maybe<Scalars['String']['output']>
}

export type PagedPriceLevelResponse = {
  __typename: 'PagedPriceLevelResponse'
  entities: Array<PriceLevelEntity>
  nextPageToken?: Maybe<Scalars['String']['output']>
}

/**  Details on what access was checked, who that access was checked against, and what attributes were evaluated, and why the decision was rendered  */
export type PagedRenderedAuthorizationDecision = {
  __typename: 'PagedRenderedAuthorizationDecision'
  page: AuthorizationPage
  results: Array<RenderedAuthorizationDecision>
}

export type PagedSavedChannel = {
  __typename: 'PagedSavedChannel'
  currentPageToken?: Maybe<Scalars['String']['output']>
  nextPageToken?: Maybe<Scalars['String']['output']>
  pageSize?: Maybe<Scalars['Int']['output']>
  results: Array<SavedChannel>
  totalResultCount?: Maybe<Scalars['Int']['output']>
}

export type PagedSavedChannelAvailability = {
  __typename: 'PagedSavedChannelAvailability'
  currentPageToken?: Maybe<Scalars['String']['output']>
  nextPageToken?: Maybe<Scalars['String']['output']>
  pageSize?: Maybe<Scalars['Int']['output']>
  results: Array<SavedChannelAvailability>
  totalResultCount?: Maybe<Scalars['Int']['output']>
}

export type PagedSavedMenuItemTagsResponse = {
  __typename: 'PagedSavedMenuItemTagsResponse'
  entities: Array<SavedMenuItemTag>
  nextPageToken?: Maybe<Scalars['String']['output']>
}

export type PaginationInput = {
  after?: InputMaybe<Scalars['String']['input']>
  first: Scalars['Int']['input']
}

export type ParentResponse = {
  __typename: 'ParentResponse'
  multiLocationId: Scalars['ID']['output']
  name?: Maybe<Scalars['String']['output']>
  owner: Scalars['ID']['output']
  target: Scalars['ID']['output']
  type: Scalars['String']['output']
  versionId: Scalars['ID']['output']
}

export type Partner = {
  __typename: 'Partner'
  additionalFee?: Maybe<Scalars['String']['output']>
  adminRemoveOnly: Scalars['Boolean']['output']
  applicationName: Scalars['String']['output']
  availableForToastNow?: Maybe<Scalars['Boolean']['output']>
  behaviour?: Maybe<Scalars['String']['output']>
  categories?: Maybe<Array<Maybe<PartnerCategory>>>
  channel?: Maybe<Scalars['String']['output']>
  clientId?: Maybe<Scalars['String']['output']>
  connectRequired?: Maybe<Scalars['Boolean']['output']>
  countries?: Maybe<Array<Scalars['String']['output']>>
  createdBy: User
  createdDate: Scalars['String']['output']
  deleted?: Maybe<Scalars['Boolean']['output']>
  deletedDate?: Maybe<Scalars['String']['output']>
  description: Scalars['String']['output']
  featureId?: Maybe<Scalars['String']['output']>
  iconUrl?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  infoUrl?: Maybe<Scalars['String']['output']>
  isThirdPartyOrderPartner: Scalars['Boolean']['output']
  mainUrl?: Maybe<Scalars['String']['output']>
  marketImageUrl?: Maybe<Scalars['String']['output']>
  modifiedBy?: Maybe<User>
  modifiedDate?: Maybe<Scalars['String']['output']>
  name: Scalars['String']['output']
  needsAccountVerification?: Maybe<Scalars['Boolean']['output']>
  onboardingEmail: Scalars['String']['output']
  originalIconUrl?: Maybe<Scalars['String']['output']>
  preferred?: Maybe<Scalars['Boolean']['output']>
  primaryAction?: Maybe<Scalars['String']['output']>
  primaryActionReference?: Maybe<Scalars['String']['output']>
  primaryCustomCtaLabel?: Maybe<Scalars['String']['output']>
  requiredFeatures?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  scopes?: Maybe<Array<Maybe<PartnerScope>>>
  secondaryAction?: Maybe<Scalars['String']['output']>
  secondaryActionReference?: Maybe<Scalars['String']['output']>
  secondaryCustomCtaLabel?: Maybe<Scalars['String']['output']>
  signUpUrl?: Maybe<Scalars['String']['output']>
  supportEmail?: Maybe<Scalars['String']['output']>
  supportPhone?: Maybe<Scalars['String']['output']>
  type?: Maybe<Scalars['String']['output']>
  version?: Maybe<Scalars['String']['output']>
  visible: Scalars['Boolean']['output']
}

export type PartnerApiAccess = {
  __typename: 'PartnerApiAccess'
  accessorId: Scalars['ID']['output']
  createdBy?: Maybe<User>
  createdDate?: Maybe<Scalars['String']['output']>
  deleted?: Maybe<Scalars['Boolean']['output']>
  deletedBy?: Maybe<User>
  deletedDate?: Maybe<Scalars['String']['output']>
  externalGroupRef?: Maybe<Scalars['String']['output']>
  externalRestaurantRef?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  modifiedBy?: Maybe<User>
  modifiedDate?: Maybe<Scalars['String']['output']>
  partner: Partner
  restaurantId: Scalars['ID']['output']
  restaurantLocation: SavedRestaurantLocation
  version: Scalars['Int']['output']
}

export type PartnerCategory = {
  __typename: 'PartnerCategory'
  description?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  label?: Maybe<Scalars['String']['output']>
}

export type PartnerChannel = {
  __typename: 'PartnerChannel'
  channel?: Maybe<SavedChannel>
  guid: Scalars['String']['output']
  iconUrl?: Maybe<Scalars['String']['output']>
  isEnabled: Scalars['Boolean']['output']
  name: Scalars['String']['output']
  snoozeUntil?: Maybe<Scalars['DateTime']['output']>
  statusLabel: PartnerStatusLabel
  thirdPartyOrderingIntegrationStatus?: Maybe<Scalars['String']['output']>
  thirdPartyOrderingOnboardingFlow?: Maybe<ThirdPartyOrderingOnboardingFlow>
  toggleVisible: Scalars['Boolean']['output']
}

export type PartnerChannelConfig = {
  __typename: 'PartnerChannelConfig'
  partnerChannels: Array<PartnerChannel>
}

export type PartnerChannelInput = {
  guid: Scalars['String']['input']
  isEnabled: Scalars['Boolean']['input']
  snoozeUntil?: InputMaybe<SnoozeValue>
}

export type PartnerChannelsInput = {
  guids: Array<Scalars['String']['input']>
  isEnabled: Scalars['Boolean']['input']
  snoozeUntil?: InputMaybe<SnoozeValue>
}

export type PartnerQuery = {
  __typename: 'PartnerQuery'
  apiAccessForAccessor: Array<PartnerApiAccess>
  categories: Array<PartnerCategory>
  partner?: Maybe<Partner>
}

export type PartnerQueryApiAccessForAccessorArgs = {
  accessorId: Scalars['ID']['input']
}

export type PartnerQueryPartnerArgs = {
  id: Scalars['ID']['input']
}

export type PartnerScope = {
  __typename: 'PartnerScope'
  name?: Maybe<Scalars['String']['output']>
}

export type PartnerStatusLabel = {
  __typename: 'PartnerStatusLabel'
  state: PartnerStatusLabelState
  textContent: Scalars['String']['output']
}

export type PartnerStatusLabelState = 'DISABLED' | 'ENABLED' | 'SNOOZED'

export type PaymentAssistanceProgram = 'SNAP'

export type PaymentDataExtension =
  | AlternatePaymentDataExtension
  | StaticPaymentDataExtension

export type PaymentDataExtensionInput = {
  alternatePaymentType?: InputMaybe<Scalars['ID']['input']>
  staticPaymentType?: InputMaybe<StaticPaymentType>
  value: Scalars['String']['input']
}

export type PaymentUserTypeMode = 'ORDER_OWNER' | 'PAYMENT_SERVER'

export type PaymentsConfigMutation = {
  __typename: 'PaymentsConfigMutation'
  archiveGfdConfigs: Scalars['Boolean']['output']
  createGfdConfig: GfdConfig
  updateGfdConfigs: Array<GfdConfig>
}

export type PaymentsConfigMutationArchiveGfdConfigsArgs = {
  authorId: Scalars['ID']['input']
  restaurantId: Scalars['ID']['input']
  versionIds: Array<Scalars['ID']['input']>
}

export type PaymentsConfigMutationCreateGfdConfigArgs = {
  authorId: Scalars['ID']['input']
  newGfdConfig: CreateGfdConfigInput
  restaurantId: Scalars['ID']['input']
}

export type PaymentsConfigMutationUpdateGfdConfigsArgs = {
  authorId: Scalars['ID']['input']
  configs: Array<UpdateGfdConfigInput>
  restaurantId: Scalars['ID']['input']
}

export type PaymentsConfigQuery = {
  __typename: 'PaymentsConfigQuery'
  alternatePaymentTypes: Array<SavedAlternatePaymentType>
  gfdAddOnStatuses: GfdAddOnStatus
  gfdConfig?: Maybe<GfdConfig>
  gfdConfigByEntityId?: Maybe<GfdConfig>
  gfdConfigs: Array<GfdConfig>
}

export type PaymentsConfigQueryAlternatePaymentTypesArgs = {
  restaurantSetIds: Array<Scalars['ID']['input']>
}

export type PaymentsConfigQueryGfdConfigArgs = {
  authorId: Scalars['ID']['input']
  restaurantId: Scalars['ID']['input']
}

export type PaymentsConfigQueryGfdConfigByEntityIdArgs = {
  versionId: Scalars['ID']['input']
}

export type PaymentsConfigQueryGfdConfigsArgs = {
  restaurantId: Scalars['ID']['input']
  selectedRestaurantSetIds: Array<Scalars['ID']['input']>
}

export type PayrollEmployeeInfo = {
  __typename: 'PayrollEmployeeInfo'
  employeeNumber?: Maybe<Scalars['String']['output']>
  employmentStatus: AllEmploymentStatuses
  payrollEmployeeId: Scalars['ID']['output']
}

export type PendingEmploymentStatus = EmploymentStatus & {
  __typename: 'PendingEmploymentStatus'
  adjustedHireDate?: Maybe<Scalars['Date']['output']>
  /** @deprecated use employee.hireDate at the top level instead */
  hireDate?: Maybe<Scalars['String']['output']>
}

/** Metric for percentage values */
export type PercentageMetric = {
  __typename: 'PercentageMetric'
  /** Comparison percentage value */
  comparison?: Maybe<Scalars['Decimal']['output']>
  /** Current percentage value */
  current?: Maybe<Scalars['Decimal']['output']>
  /** End time for the metric; inclusive */
  endTime: Scalars['DateTime']['output']
  /** Start time for the metric; inclusive */
  startTime: Scalars['DateTime']['output']
  /** Variation in comparison */
  variation?: Maybe<Comparison>
}

export type Permission =
  | 'PERMISSION_ACCOUNT_ADMIN'
  | 'PERMISSION_ADJUST_CASH_DRAWER_START_BALANCE'
  | 'PERMISSION_ADVANCED_TERMINAL_SETUP'
  | 'PERMISSION_ADVERTISING_MANAGER_BILLING'
  | 'PERMISSION_ALTERNATE_PAYMENT_TYPES'
  | 'PERMISSION_APPLY_CASH_PAYMENTS'
  | 'PERMISSION_BENCHMARKING_INSIGHTS'
  | 'PERMISSION_BLIND_CASH_DRAWERS'
  | 'PERMISSION_BOOKING_APP'
  | 'PERMISSION_BOOKING_SETUP'
  | 'PERMISSION_BULK_TRANSFER_CHECKS'
  | 'PERMISSION_CANCEL_DISPATCH'
  | 'PERMISSION_CASH_CLOSEOUT_OVER_MAX_DISCREPANCY'
  | 'PERMISSION_CASH_DRAWERS'
  | 'PERMISSION_CASH_DRAWER_ACCESS'
  | 'PERMISSION_CASH_DRAWER_LOCKDOWN'
  | 'PERMISSION_CHANGE_SERVER'
  | 'PERMISSION_CHANGE_TABLE'
  | 'PERMISSION_CHECK_AUDIT'
  | 'PERMISSION_CLOSE_MULTIPLE_PAID_CHECKS'
  | 'PERMISSION_CLOSE_OUT_DAY'
  | 'PERMISSION_COMPLETE_DELIVERY'
  | 'PERMISSION_CRM_CUSTOMER'
  | 'PERMISSION_DATA_EXPORT_CONFIG'
  | 'PERMISSION_DATA_EXTENSIONS_EDIT'
  | 'PERMISSION_DATA_EXTENSIONS_VIEW'
  | 'PERMISSION_DELIVERY_ACCESS'
  | 'PERMISSION_DELIVERY_MODE'
  | 'PERMISSION_DEVICE_SETUP'
  | 'PERMISSION_DISCOUNTS'
  | 'PERMISSION_DISCOUNTS_SETUP'
  | 'PERMISSION_DISPATCH_DRIVER'
  | 'PERMISSION_EDIT_HISTORICAL_DATA'
  | 'PERMISSION_EDIT_OTHER_USERS_ORDERS'
  | 'PERMISSION_EDIT_SENT_ITEMS'
  | 'PERMISSION_EDIT_TIME_ENTRIES'
  | 'PERMISSION_EMPLOYEE_INFO'
  | 'PERMISSION_EMPLOYEE_JOBS_AND_WAGES'
  | 'PERMISSION_END_BREAKS_EARLY'
  | 'PERMISSION_FINANCIAL_ACCOUNTS'
  | 'PERMISSION_FIND_CHECKS'
  | 'PERMISSION_FULL_MENU_EDIT'
  | 'PERMISSION_HOUSE_ACCOUNT'
  | 'PERMISSION_INSTANT_DEPOSIT'
  | 'PERMISSION_INVOICING_CATERING_AND_EVENTS_CONFIGURE'
  | 'PERMISSION_INVOICING_CATERING_AND_EVENTS_EDIT'
  | 'PERMISSION_INVOICING_CATERING_AND_EVENTS_VIEW'
  | 'PERMISSION_KDS_SETUP'
  | 'PERMISSION_KEY_IN_CREDIT_CARDS'
  | 'PERMISSION_KITCHEN_AND_DINING_ROOM_SETUP'
  | 'PERMISSION_LABOR_REPORTING'
  | 'PERMISSION_LOCAL_MENU_EDIT'
  | 'PERMISSION_LOG_BOOK'
  | 'PERMISSION_MANAGER'
  | 'PERMISSION_MANAGE_INTEGRATIONS'
  | 'PERMISSION_MARKETING_INFO'
  | 'PERMISSION_MENU_AUDIT_AND_CLEANUP'
  | 'PERMISSION_MENU_REPORTING'
  | 'PERMISSION_MY_REPORTS'
  | 'PERMISSION_NEGATIVE_DECLARED_TIPS'
  | 'PERMISSION_NO_SALE'
  | 'PERMISSION_OFFLINE_BACKGROUND_CREDIT_CARD_PROCESSING'
  | 'PERMISSION_OPEN_ITEMS'
  | 'PERMISSION_ORDER_READY_BOARD_ACCESS'
  | 'PERMISSION_PAYMENTS_SETUP'
  | 'PERMISSION_PAYMENT_TERMINAL_MODE'
  | 'PERMISSION_PAY_OUTS'
  | 'PERMISSION_PENDING_ORDERS_MODE'
  | 'PERMISSION_POS'
  | 'PERMISSION_PRINTER_AND_CASH_DRAWER_SETUP'
  | 'PERMISSION_PUBLISHING'
  | 'PERMISSION_QUICK_EDIT'
  | 'PERMISSION_QUICK_EDIT_BUTTON_ADD_EXISTING'
  | 'PERMISSION_QUICK_EDIT_BUTTON_ADD_NEW'
  | 'PERMISSION_QUICK_EDIT_BUTTON_COLOUR'
  | 'PERMISSION_QUICK_EDIT_BUTTON_REARRANGE'
  | 'PERMISSION_QUICK_EDIT_BUTTON_REMOVE'
  | 'PERMISSION_QUICK_EDIT_CATEGORY'
  | 'PERMISSION_QUICK_EDIT_INVENTORY_QUANTITY'
  | 'PERMISSION_QUICK_EDIT_NAME'
  | 'PERMISSION_QUICK_EDIT_POS_NAME'
  | 'PERMISSION_QUICK_EDIT_PRICE'
  | 'PERMISSION_QUICK_EDIT_SKU'
  | 'PERMISSION_QUICK_ORDER_MODE'
  | 'PERMISSION_REFUNDS'
  | 'PERMISSION_REFUNDS_LIMITED'
  | 'PERMISSION_REFUNDS_UNLINKED'
  | 'PERMISSION_REGISTER_SWIPE_CARD'
  | 'PERMISSION_RESTAURANT_ADMIN'
  | 'PERMISSION_RESTAURANT_CARD'
  | 'PERMISSION_RESTAURANT_GROUPS_SETUP'
  | 'PERMISSION_RESTAURANT_OPERATIONS_SETUP'
  | 'PERMISSION_SALES_REPORTING'
  | 'PERMISSION_SCHEDULED_PUBLISHING'
  | 'PERMISSION_SEND_NOTIFICATIONS'
  | 'PERMISSION_SETTINGS_COPY_TOOL'
  | 'PERMISSION_SHIFT_REVIEW'
  | 'PERMISSION_SHIFT_REVIEW_SALES_DATA'
  | 'PERMISSION_SHOP_ACCESS'
  | 'PERMISSION_SPLIT_CHECKS'
  | 'PERMISSION_SVC_CHARGES'
  | 'PERMISSION_TABLES'
  | 'PERMISSION_TABLE_SERVICE_MODE'
  | 'PERMISSION_TAX_EXEMPT'
  | 'PERMISSION_TAX_RATES_SETUP'
  | 'PERMISSION_TEAM_CHAT'
  | 'PERMISSION_TEAM_CHAT_ADVANCED'
  | 'PERMISSION_TEAM_CHAT_CREATE_CHATS'
  | 'PERMISSION_TERMINAL_SETUP'
  | 'PERMISSION_THROTTLE_ONLINE_ORDERS'
  | 'PERMISSION_TICKET_DISPLAY_SCREEN_MODE'
  | 'PERMISSION_TOAST_CARD_ADD_POINTS'
  | 'PERMISSION_TOAST_CARD_ADD_VALUE'
  | 'PERMISSION_TOAST_CARD_REPORTING'
  | 'PERMISSION_TOGGLE_POS_EXPERIENCE'
  | 'PERMISSION_UNSPECIFIED'
  | 'PERMISSION_UPDATE_ALL_DELIVERY_ORDERS'
  | 'PERMISSION_UPDATE_DRIVER'
  | 'PERMISSION_USER_PERMISSIONS'
  | 'PERMISSION_VIEW_ALL_CHECKS'
  | 'PERMISSION_VOIDS'
  | 'PERMISSION_VOID_MULTIPLE_OPEN_CHECKS'
  | 'PERMISSION_WASTE_TRACKING_MODE'
  | 'PERMISSION_WEBSITE_SETUP'
  | 'PERMISSION_WEB_SETUP'

export type Permissions = {
  __typename: 'Permissions'
  accountAdmin: Scalars['Boolean']['output']
  adjustCashDrawerStartBalance: Scalars['Boolean']['output']
  advancedTerminalSetup: Scalars['Boolean']['output']
  alternatePaymentTypes: Scalars['Boolean']['output']
  applyCashPayments: Scalars['Boolean']['output']
  blindCashDrawers: Scalars['Boolean']['output']
  bookingApp: Scalars['Boolean']['output']
  bookingSetup: Scalars['Boolean']['output']
  bulkTransferChecks: Scalars['Boolean']['output']
  cancelDispatch: Scalars['Boolean']['output']
  cashCloseoutOverMaxDiscrepancy: Scalars['Boolean']['output']
  cashDrawerAccess: Scalars['Boolean']['output']
  cashDrawerLockdown: Scalars['Boolean']['output']
  cashDrawers: Scalars['Boolean']['output']
  changeServer: Scalars['Boolean']['output']
  changeTable: Scalars['Boolean']['output']
  checkAudit: Scalars['Boolean']['output']
  closeMultiplePaidChecks: Scalars['Boolean']['output']
  closeOutDay: Scalars['Boolean']['output']
  completeDelivery: Scalars['Boolean']['output']
  crmCustomer: Scalars['Boolean']['output']
  dataExportConfig: Scalars['Boolean']['output']
  deliveryAccess: Scalars['Boolean']['output']
  deliveryMode: Scalars['Boolean']['output']
  deviceSetup: Scalars['Boolean']['output']
  discounts: Scalars['Boolean']['output']
  discountsSetup: Scalars['Boolean']['output']
  dispatchDriver: Scalars['Boolean']['output']
  editHistoricalData: Scalars['Boolean']['output']
  editOtherUsersOrders: Scalars['Boolean']['output']
  editSentItems: Scalars['Boolean']['output']
  editTimeEntries: Scalars['Boolean']['output']
  employeeInfo: Scalars['Boolean']['output']
  employeeJobsAndWages: Scalars['Boolean']['output']
  endBreaksEarly: Scalars['Boolean']['output']
  financialAccounts: Scalars['Boolean']['output']
  findChecks: Scalars['Boolean']['output']
  fullMenuEdit: Scalars['Boolean']['output']
  houseAccount: Scalars['Boolean']['output']
  instantDeposit: Scalars['Boolean']['output']
  kdsSetup: Scalars['Boolean']['output']
  keyInCreditCards: Scalars['Boolean']['output']
  kitchenAndDiningRoomSetup: Scalars['Boolean']['output']
  laborReporting: Scalars['Boolean']['output']
  localMenuEdit: Scalars['Boolean']['output']
  logBook: Scalars['Boolean']['output']
  manageIntegrations: Scalars['Boolean']['output']
  manager: Scalars['Boolean']['output']
  marketingInfo: Scalars['Boolean']['output']
  menuAuditAndCleanup: Scalars['Boolean']['output']
  menuReporting: Scalars['Boolean']['output']
  myReports: Scalars['Boolean']['output']
  negativeDeclaredTips: Scalars['Boolean']['output']
  noSale: Scalars['Boolean']['output']
  offlineBackgroundCreditCardProcessing: Scalars['Boolean']['output']
  openItems: Scalars['Boolean']['output']
  orderReadyBoardAccess: Scalars['Boolean']['output']
  payOuts: Scalars['Boolean']['output']
  paymentTerminalMode: Scalars['Boolean']['output']
  paymentsSetup: Scalars['Boolean']['output']
  pendingOrdersMode: Scalars['Boolean']['output']
  pos: Scalars['Boolean']['output']
  publishing: Scalars['Boolean']['output']
  quickEdit: Scalars['Boolean']['output']
  quickEditButtonAddExisting: Scalars['Boolean']['output']
  quickEditButtonAddNew: Scalars['Boolean']['output']
  quickEditButtonColour: Scalars['Boolean']['output']
  quickEditButtonRearrange: Scalars['Boolean']['output']
  quickEditButtonRemove: Scalars['Boolean']['output']
  quickEditCategory: Scalars['Boolean']['output']
  quickEditInventoryQuantity: Scalars['Boolean']['output']
  quickEditName: Scalars['Boolean']['output']
  quickEditPosName: Scalars['Boolean']['output']
  quickEditPrice: Scalars['Boolean']['output']
  quickEditSku: Scalars['Boolean']['output']
  quickOrderMode: Scalars['Boolean']['output']
  refunds: Scalars['Boolean']['output']
  refundsUnlinked: Scalars['Boolean']['output']
  registerSwipeCard: Scalars['Boolean']['output']
  restaurantAdmin: Scalars['Boolean']['output']
  restaurantCard: Scalars['Boolean']['output']
  restaurantGroupsSetup: Scalars['Boolean']['output']
  restaurantOperationsSetup: Scalars['Boolean']['output']
  salesReporting: Scalars['Boolean']['output']
  scheduledPublishing: Scalars['Boolean']['output']
  sendNotifications: Scalars['Boolean']['output']
  shiftReview: Scalars['Boolean']['output']
  shiftReviewSalesData: Scalars['Boolean']['output']
  shopAccess: Scalars['Boolean']['output']
  svcCharges: Scalars['Boolean']['output']
  tableServiceMode: Scalars['Boolean']['output']
  tables: Scalars['Boolean']['output']
  taxExempt: Scalars['Boolean']['output']
  taxRatesSetup: Scalars['Boolean']['output']
  terminalSetup: Scalars['Boolean']['output']
  throttleOnlineOrders: Scalars['Boolean']['output']
  ticketDisplayScreenMode: Scalars['Boolean']['output']
  toastCardAddPoints: Scalars['Boolean']['output']
  toastCardAddValue: Scalars['Boolean']['output']
  toastCardReporting: Scalars['Boolean']['output']
  togglePosExperience: Scalars['Boolean']['output']
  updateAllDeliveryOrders: Scalars['Boolean']['output']
  updateDriver: Scalars['Boolean']['output']
  userPermissions: Scalars['Boolean']['output']
  viewAllChecks: Scalars['Boolean']['output']
  voidMultipleOpenChecks: Scalars['Boolean']['output']
  voids: Scalars['Boolean']['output']
  wasteTrackingMode: Scalars['Boolean']['output']
  webSetup: Scalars['Boolean']['output']
  websitesSetup: Scalars['Boolean']['output']
}

/**  How legacy customer user permission bits should be combined together in order to check if a customer user has access  */
export type PermissionsCheckType = 'ALL_OF' | 'ANY_OF'

export type PlaceThirdPartyMockOrderResponse = {
  __typename: 'PlaceThirdPartyMockOrderResponse'
  body: Scalars['String']['output']
  id: Scalars['ID']['output']
}

/** The source of the plate cost information */
export type PlateCostSource =
  /** Manually entered by a user */
  | 'MANUAL'
  /** Calculated from a recipe based on the ingredients */
  | 'RECIPE'
  /** The source of the plate cost is unknown (this is a fallback value) */
  | 'UNKNOWN'

export type PnLaiSummary = {
  __typename: 'PnLAISummary'
  summary?: Maybe<Scalars['String']['output']>
}

export type PnLLineItemMetrics = {
  __typename: 'PnLLineItemMetrics'
  amountChange?: Maybe<Money>
  inflow?: Maybe<Money>
  inflowComparisonPeriod?: Maybe<Money>
  outflow?: Maybe<Money>
  outflowComparisonPeriod?: Maybe<Money>
  percentChange?: Maybe<Scalars['Float']['output']>
  percentOfSales?: Maybe<Scalars['Float']['output']>
  percentOfSalesComparisonPeriod?: Maybe<Scalars['Float']['output']>
  total?: Maybe<Money>
  totalComparisonPeriod?: Maybe<Money>
}

export type PnLNetProfit = {
  __typename: 'PnLNetProfit'
  controllableProfit: PnLSubProfit
  metrics: PnLLineItemMetrics
  nonControllableProfit: PnLSubProfit
}

export type PnLReport = {
  __typename: 'PnLReport'
  netProfit: PnLNetProfit
}

export type PnLReportRequest = {
  comparisonPeriodFromDateYyyymmdd: Scalars['Int']['input']
  comparisonPeriodToDateYyyymmdd: Scalars['Int']['input']
  fromDateYyyymmdd: Scalars['Int']['input']
  toDateYyyymmdd: Scalars['Int']['input']
}

export type PnLSubCategory = {
  __typename: 'PnLSubCategory'
  category: CashFlowTransactionCategory
  metrics: PnLLineItemMetrics
  subCategoryDetails: Array<PnLSubCategoryDetail>
}

export type PnLSubCategoryDetail = {
  __typename: 'PnLSubCategoryDetail'
  metrics: PnLLineItemMetrics
  name: Scalars['String']['output']
}

export type PnLSubProfit = {
  __typename: 'PnLSubProfit'
  subProfit: PnLLineItemMetrics
  subProfitTopLevels: Array<PnLTopLevel>
}

export type PnLTopLevel = {
  __typename: 'PnLTopLevel'
  category: PnLTopLevelCategory
  metrics: PnLLineItemMetrics
  subCategories: Array<PnLSubCategory>
}

export type PnLTopLevelCategory =
  | 'CONTROLLABLE_EXPENSES'
  | 'NON_CONTROLLABLE_EXPENSES'
  | 'PRIME_COST'
  | 'REVENUE'

export type PopularSearch = {
  __typename: 'PopularSearch'
  docCount: Scalars['Int']['output']
  key: Scalars['String']['output']
}

export type PopularSearchResponse = {
  __typename: 'PopularSearchResponse'
  items: Array<PopularSearch>
  totalHits: Scalars['Int']['output']
}

export type PosButton = 'HOLD' | 'SEND' | 'STAY'

export type PosEmployeeInfo = {
  __typename: 'PosEmployeeInfo'
  archived?: Maybe<Scalars['Boolean']['output']>
  restaurantLocation?: Maybe<SavedRestaurantLocation>
  restaurantUserGuid: Scalars['ID']['output']
}

export type PrequalifiedOfferForCustomer = {
  __typename: 'PrequalifiedOfferForCustomer'
  ccHoldbackRate: Scalars['Float']['output']
  id: Scalars['ID']['output']
  offerMetadata: PrequalifiedOfferMetadata
  originationAmount: Money
  refinanceEligibleLoans: Array<Scalars['ID']['output']>
  termInDays: Scalars['Int']['output']
  totalExpectedRepayment: Money
}

export type PrequalifiedOfferMetadata = {
  __typename: 'PrequalifiedOfferMetadata'
  appliedFactorDiscountPercent?: Maybe<Scalars['Float']['output']>
  appliedRefinanceFactorDiscountPercent?: Maybe<Scalars['Float']['output']>
  boostedOfferDiscount?: Maybe<Scalars['Float']['output']>
  boostedOfferPendingProgress?: Maybe<Scalars['Float']['output']>
  boostedOfferStatus?: Maybe<Scalars['String']['output']>
  gmvLoanSizeIncreaseAmount?: Maybe<Money>
  increaseInOriginationAmount?: Maybe<Money>
  increaseInOriginationAmountBaselineDate?: Maybe<Scalars['String']['output']>
  isInMultiLoanTest?: Maybe<Scalars['Boolean']['output']>
  isMinimumPaymentEligible?: Maybe<Scalars['Boolean']['output']>
  minOriginationAmount: Money
  multiLoanOutstandingPrincipalBalance: Money
  preapprovedApplicationExpirationDate?: Maybe<Scalars['String']['output']>
  preapprovedApplicationId?: Maybe<Scalars['String']['output']>
  preapprovedApplicationVersion?: Maybe<Scalars['Int']['output']>
  priceDropBaselineDate?: Maybe<Scalars['String']['output']>
  priceDropPercent?: Maybe<Scalars['Float']['output']>
  promotionExpirationDateTime?: Maybe<Scalars['String']['output']>
  requireCodatConnection?: Maybe<Scalars['Boolean']['output']>
  timeOnPlatformCategory?: Maybe<Scalars['String']['output']>
}

export type PriceLevel = {
  __typename: 'PriceLevel'
  multiLocationId: Scalars['ID']['output']
  pricingStrategy: MenuItemPricingStrategy
}

export type PriceLevelEntity = MenuEntity & {
  __typename: 'PriceLevelEntity'
  id: Scalars['ID']['output']
  multiLocationId: Scalars['ID']['output']
  name: Scalars['String']['output']
  owner: Scalars['ID']['output']
  ownerRestaurantSet?: Maybe<RestaurantSet>
  /** PriceLevel-Specific fields */
  pricing?: Maybe<MenuItemPricing>
  target: Scalars['ID']['output']
  targetRestaurantSet: RestaurantSet
  versionId: Scalars['ID']['output']
}

export type PricingMode =
  | 'ADJUSTS_PRICE'
  | 'FIXED_PRICE'
  | 'INCLUDED'
  | 'LOCATION_SPECIFIC_PRICE'
  | 'REPLACES_PRICE'
  | 'UNSPECIFIED'

/** A peripheral device used for ticket and/or receipt printing */
export type Printer = Device & {
  __typename: 'Printer'
  deviceDetails?: Maybe<DeviceDetails>
  deviceType: DeviceType
  firmwareVersion?: Maybe<Scalars['String']['output']>
  lastUpdate?: Maybe<Scalars['DateTime']['output']>
  maxPrintWidth?: Maybe<Scalars['String']['output']>
  model?: Maybe<Scalars['String']['output']>
  modelName?: Maybe<Scalars['String']['output']>
  name: Scalars['String']['output']
  networks?: Maybe<Array<DeviceNetworkDetails>>
  photo?: Maybe<Scalars['String']['output']>
  printerConfig?: Maybe<PublishedPrinterConfig>
  restaurant: SavedRestaurantLocation
  serial: Scalars['ID']['output']
}

export type ProcessLegacyJob = {
  __typename: 'ProcessLegacyJob'
  processed: Scalars['Int']['output']
  status: BackfillStatus
}

export type ProcessLegacyJobRequest = {
  fileData: Scalars['String']['input']
  fileName: Scalars['String']['input']
  productType: ProductType
}

export type ProcessLegacyJobResponse =
  | InvalidCustomerUuidError
  | InvalidRestaurantIdError
  | JobError
  | ProcessLegacyJob

export type ProductRecommendation =
  | 'BRANDED_NATIVE_APP'
  | 'PRO_ONLINE_ORDERING'
  | 'SMART_QUOTE'

export type ProductSubscription = ProductSubscriptionInterface & {
  __typename: 'ProductSubscription'
  billingFrequency: ProductSubscriptionBillingFrequency
  billingType: ProductSubscriptionBillingType
  contractId: Scalars['ID']['output']
  /** Contract length in whole months */
  contractLength?: Maybe<Scalars['Int']['output']>
  /** Amount defaults to 0 */
  customerPrice: Money
  endDate?: Maybe<Scalars['Date']['output']>
  listPrice: Money
  /** @deprecated Field is no longer supplied. Use 'nextSubscriptionDate' instead */
  nextBillDate?: Maybe<Scalars['Date']['output']>
  nextSubscriptionDate?: Maybe<Scalars['Date']['output']>
  /** Previously purchased same subscription */
  previousPurchases: Array<ProductSubscription>
  productDescription?: Maybe<Scalars['String']['output']>
  productId: Scalars['ID']['output']
  productName?: Maybe<Scalars['String']['output']>
  productType: ProductSubscriptionProductType
  /** Defaults to 0 */
  quantity: Scalars['Float']['output']
  startDate?: Maybe<Scalars['Date']['output']>
  subscriptionSalesforceId: Scalars['ID']['output']
}

export type ProductSubscriptionBillingFrequency = 'ANNUAL' | 'MONTHLY'

export type ProductSubscriptionBillingType = 'ADVANCE' | 'ARREARS'

export type ProductSubscriptionBundle = ProductSubscriptionInterface & {
  __typename: 'ProductSubscriptionBundle'
  billingFrequency: ProductSubscriptionBillingFrequency
  billingType: ProductSubscriptionBillingType
  bundledProducts: Array<ProductSubscriptionSalesforceProduct>
  contractId: Scalars['ID']['output']
  /** Contract length is rounded to nearest number of months */
  contractLength?: Maybe<Scalars['Int']['output']>
  /** Amount defaults to 0 */
  customerPrice: Money
  endDate?: Maybe<Scalars['Date']['output']>
  listPrice: Money
  /** @deprecated Field is no longer supplied. Use 'nextSubscriptionDate' instead */
  nextBillDate?: Maybe<Scalars['Date']['output']>
  nextSubscriptionDate?: Maybe<Scalars['Date']['output']>
  /** Previously purchased same subscription (bundle) */
  previousPurchases: Array<ProductSubscriptionBundle>
  productDescription?: Maybe<Scalars['String']['output']>
  productId: Scalars['ID']['output']
  productName?: Maybe<Scalars['String']['output']>
  productType: ProductSubscriptionProductType
  /** Defaults to 0 */
  quantity: Scalars['Float']['output']
  startDate?: Maybe<Scalars['Date']['output']>
  subscriptionSalesforceId: Scalars['ID']['output']
}

export type ProductSubscriptionInterface = {
  billingFrequency: ProductSubscriptionBillingFrequency
  billingType: ProductSubscriptionBillingType
  contractId: Scalars['ID']['output']
  /** Contract length is rounded to nearest number of months */
  contractLength?: Maybe<Scalars['Int']['output']>
  /** Amount defaults to 0 */
  customerPrice: Money
  endDate?: Maybe<Scalars['Date']['output']>
  /** Defaults to 0 */
  listPrice: Money
  /** @deprecated Field is no longer supplied. Use 'nextSubscriptionDate' instead */
  nextBillDate?: Maybe<Scalars['Date']['output']>
  nextSubscriptionDate?: Maybe<Scalars['Date']['output']>
  /** Previously purchased same subscription */
  previousPurchases: Array<ProductSubscriptionInterface>
  productDescription?: Maybe<Scalars['String']['output']>
  productId: Scalars['ID']['output']
  productName?: Maybe<Scalars['String']['output']>
  productType: ProductSubscriptionProductType
  /** Defaults to 0 */
  quantity: Scalars['Float']['output']
  startDate?: Maybe<Scalars['Date']['output']>
  subscriptionSalesforceId: Scalars['ID']['output']
}

export type ProductSubscriptionProductType =
  | 'BUNDLE'
  | 'HARDWARE'
  | 'HARDWARE_BUNDLE'
  | 'SOFTWARE'

export type ProductSubscriptionSalesforceProduct = {
  __typename: 'ProductSubscriptionSalesforceProduct'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  productDescription: Scalars['String']['output']
}

export type ProductSubscriptionsMutation = {
  __typename: 'ProductSubscriptionsMutation'
  createCancellationRequest: Array<SubscriptionsCancellationRequest>
  updateSubscriptionAutoRenewal: SubscriptionsCancellationRequest
}

export type ProductSubscriptionsMutationCreateCancellationRequestArgs = {
  request: SubscriptionsCancellationRequestInput
}

export type ProductSubscriptionsMutationUpdateSubscriptionAutoRenewalArgs = {
  request: UpdateSubscriptionAutoRenewalInput
}

export type ProductSubscriptionsQuery = {
  __typename: 'ProductSubscriptionsQuery'
  cancellationRequestsList: Array<SubscriptionsCancellationRequest>
  list: Array<ProductSubscriptionInterface>
  location?: Maybe<SavedRestaurantLocation>
}

export type ProductSubscriptionsQueryCancellationRequestsListArgs = {
  filter?: InputMaybe<SubscriptionsCancellationRequestFilter>
}

export type ProductType =
  | 'PRODUCT_TYPE_PAYROLL'
  | 'PRODUCT_TYPE_POS'
  | 'PRODUCT_TYPE_SLING'

export type PublishJob = {
  __typename: 'PublishJob'
  author?: Maybe<User>
  date: Scalars['String']['output']
  entities: PublishedConfigConnection
  entityInformation: Array<PublishJobEntityInfo>
  id: Scalars['ID']['output']
  restaurant: SavedRestaurantLocation
  source: PublishJobSource
  type: PublishJobType
  updateCount: Scalars['Int']['output']
}

export type PublishJobEntitiesArgs = {
  pagination?: InputMaybe<ConnectionPaginationInput>
}

export type PublishJobConnection = {
  __typename: 'PublishJobConnection'
  edges: Array<PublishJobEdge>
  pageInfo: PageInfo
}

export type PublishJobEdge = {
  __typename: 'PublishJobEdge'
  cursor: Scalars['String']['output']
  node: PublishJob
}

export type PublishJobEntityInfo = {
  __typename: 'PublishJobEntityInfo'
  count: Scalars['Int']['output']
  entityType: Scalars['String']['output']
}

export type PublishJobSource = 'HUB' | 'SCHEDULED'

export type PublishJobType = 'FULL' | 'PARTIAL'

export type PublishJobsFilterInput = {
  restaurantSetIds: Array<Scalars['ID']['input']>
}

export type PublishMutation = {
  __typename: 'PublishMutation'
  fullPublishRestaurants: PublishStatus
}

export type PublishMutationFullPublishRestaurantsArgs = {
  input: FullPublishStartInput
}

export type PublishQuery = {
  __typename: 'PublishQuery'
  checkUnpublishedChanges: UnpublishedStatusConnection
  publishJob: PublishJob
  publishJobs: PublishJobConnection
  publishedConfig: PublishedConfig
  publishedConfigDiff?: Maybe<PublishedConfigDiff>
}

export type PublishQueryCheckUnpublishedChangesArgs = {
  filter?: InputMaybe<UnpublishedChangesFilterInput>
  pagination?: InputMaybe<ConnectionPaginationInput>
}

export type PublishQueryPublishJobArgs = {
  id: Scalars['ID']['input']
}

export type PublishQueryPublishJobsArgs = {
  filter: PublishJobsFilterInput
  pagination?: InputMaybe<ConnectionPaginationInput>
}

export type PublishQueryPublishedConfigArgs = {
  entityType: Scalars['String']['input']
  id?: InputMaybe<Scalars['ID']['input']>
  publishJobId: Scalars['ID']['input']
}

export type PublishQueryPublishedConfigDiffArgs = {
  configId?: InputMaybe<Scalars['ID']['input']>
  currentPublishJobId: Scalars['ID']['input']
  entityType: Scalars['String']['input']
  lastPublishJobId?: InputMaybe<Scalars['ID']['input']>
}

export type PublishRepositoryDependencyFailure = ShiftError & {
  __typename: 'PublishRepositoryDependencyFailure'
  message?: Maybe<Scalars['String']['output']>
}

export type PublishRestaurantStatus = {
  __typename: 'PublishRestaurantStatus'
  restaurantId: Scalars['ID']['output']
}

export type PublishStatus = {
  __typename: 'PublishStatus'
  originID: Scalars['ID']['output']
  publishType: PublishJobType
  restaurants: Array<PublishRestaurantStatus>
}

export type PublishedChannel = {
  __typename: 'PublishedChannel'
  channelAvailability?: Maybe<PublishedChannelAvailability>
  channelType?: Maybe<ChannelType>
  channelTypeId: Scalars['ID']['output']
  id: Scalars['ID']['output']
  name?: Maybe<Scalars['String']['output']>
}

export type PublishedChannelAvailability = {
  __typename: 'PublishedChannelAvailability'
  enabled?: Maybe<Scalars['Boolean']['output']>
  id: Scalars['ID']['output']
  multiLocationId?: Maybe<Scalars['String']['output']>
  snoozedUntil?: Maybe<Scalars['DateTime']['output']>
}

export type PublishedConfig = {
  __typename: 'PublishedConfig'
  action?: Maybe<Action>
  entityType?: Maybe<ConfigType>
  id?: Maybe<Scalars['ID']['output']>
  lastModifiedBy?: Maybe<User>
  lastPublishJob?: Maybe<PublishJob>
  lastSavedTime?: Maybe<Scalars['String']['output']>
  managementSet?: Maybe<ManagementGroup>
  name?: Maybe<Scalars['String']['output']>
  publishJob?: Maybe<PublishJob>
  restaurant?: Maybe<SavedRestaurantLocation>
  targets: Array<RestaurantSet>
  url?: Maybe<Scalars['String']['output']>
}

export type PublishedConfigConnection = {
  __typename: 'PublishedConfigConnection'
  edges: Array<PublishedConfigEdge>
  pageInfo: PageInfo
}

export type PublishedConfigDiff = {
  __typename: 'PublishedConfigDiff'
  differences: Array<PublishedConfigFieldDiff>
}

export type PublishedConfigDiffType = 'DEFAULT' | 'IMAGE' | 'LIST'

export type PublishedConfigEdge = {
  __typename: 'PublishedConfigEdge'
  cursor: Scalars['String']['output']
  node: PublishedConfig
}

export type PublishedConfigEntityI = {
  id: Scalars['ID']['output']
  managementGroup: ManagementGroup
  name?: Maybe<Scalars['String']['output']>
  restaurantLocation: PublishedRestaurantLocation
}

export type PublishedConfigFieldDiff = {
  __typename: 'PublishedConfigFieldDiff'
  diff: ConfigValueDiff
  field: ConfigField
}

export type PublishedDataExtensionSet = PublishedConfigEntityI & {
  __typename: 'PublishedDataExtensionSet'
  id: Scalars['ID']['output']
  managementGroup: ManagementGroup
  name?: Maybe<Scalars['String']['output']>
  payments: Array<PaymentDataExtension>
  restaurantLocation: PublishedRestaurantLocation
  revenueCenters: Array<RevenueCenterDataExtension>
  salesCategories: Array<SalesCategoryDataExtension>
  services: Array<ServiceDataExtension>
  taxRates: Array<TaxRateDataExtension>
  type: DataExtensionSetType
}

export type PublishedGiftCardReceiptConfig = {
  __typename: 'PublishedGiftCardReceiptConfig'
  balanceInquiryConfig: GiftReceiptPrintStrategy
  giftActivationConfig: GiftReceiptPrintStrategy
  name?: Maybe<Scalars['String']['output']>
  promoArea?: Maybe<Scalars['String']['output']>
  restaurantLocation: PublishedRestaurantLocation
  revision: Scalars['Int']['output']
}

export type PublishedItemFeedbackConfig = {
  __typename: 'PublishedItemFeedbackConfig'
  itemFeedbackEnabled: Scalars['Boolean']['output']
  notificationContacts: Array<Maybe<NotificationContact>>
}

export type PublishedItemFeedbackConfigError = {
  __typename: 'PublishedItemFeedbackConfigError'
  code: PublishedItemFeedbackConfigErrorCode
  message?: Maybe<Scalars['String']['output']>
}

export type PublishedItemFeedbackConfigErrorCode =
  | 'ConfigNotFound'
  | 'FailedToRetrieveConfig'
  | 'ManagementSetGuidNotFound'

export type PublishedItemFeedbackConfigResponse =
  | PublishedItemFeedbackConfig
  | PublishedItemFeedbackConfigError

export type PublishedMenu = PublishedMenuEntity & {
  __typename: 'PublishedMenu'
  /** Shared PublishedMenuEntity fields */
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type PublishedMenuEntity = {
  /** id is the same as versionId, but id is a standard field in Toast GraphQL schemas */
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type PublishedMenuGroup = PublishedMenuEntity & {
  __typename: 'PublishedMenuGroup'
  /** Shared PublishedMenuEntity fields */
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

/**  This PublishedMenuItem type does not include any properties that resolve based on inheritance. A "PublishedMenuItemInstance" type will be added for resolved fields  */
export type PublishedMenuItem = PublishedMenuEntity & {
  __typename: 'PublishedMenuItem'
  /** PublishedMenuItem-specific fields */
  ancestors: Array<PublishedMenuItemAncestor>
  /** Shared PublishedMenuEntity fields */
  id: Scalars['ID']['output']
  /** Flag that identifies that this menu item is only used as a modifier */
  modifierOnly: Scalars['Boolean']['output']
  name: Scalars['String']['output']
  /** @deprecated use modifierOnly instead (will be removed with MENU-1796) */
  optionOnly: Scalars['Boolean']['output']
  stock: Stock
}

export type PublishedMenuItemAncestor = {
  __typename: 'PublishedMenuItemAncestor'
  id: Scalars['ID']['output']
  paths: PublishedMenuItemAncestorPath
}

export type PublishedMenuItemAncestorPath = {
  __typename: 'PublishedMenuItemAncestorPath'
  ancestors: Array<PublishedMenuEntity>
}

/** published toastweb config data for a Printer */
export type PublishedPrinterConfig = {
  __typename: 'PublishedPrinterConfig'
  id?: Maybe<Scalars['ID']['output']>
  ipAddress?: Maybe<Scalars['String']['output']>
  modelName?: Maybe<Scalars['String']['output']>
  multiLocationId?: Maybe<Scalars['String']['output']>
  name?: Maybe<Scalars['String']['output']>
  paperWidth?: Maybe<Scalars['String']['output']>
  prepStations?: Maybe<Array<DeviceInfoPublishedPrepStation>>
  serial?: Maybe<Scalars['String']['output']>
}

export type PublishedQueries = {
  __typename: 'PublishedQueries'
  restaurantLocation?: Maybe<PublishedRestaurantLocation>
}

export type PublishedQueriesRestaurantLocationArgs = {
  id: Scalars['ID']['input']
}

export type PublishedRestaurantLocation = RestaurantLocationInterface & {
  __typename: 'PublishedRestaurantLocation'
  address: RestaurantLocationAddress
  closeoutHour: Scalars['LocalTime']['output']
  currencyCode: Scalars['String']['output']
  description?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  images: RestaurantLocationImages
  isTestMode: Scalars['Boolean']['output']
  language?: Maybe<Scalars['String']['output']>
  locationCode?: Maybe<Scalars['String']['output']>
  locationName?: Maybe<Scalars['String']['output']>
  name: Scalars['String']['output']
  operatingSchedule: OperatingSchedule
  phone?: Maybe<Scalars['String']['output']>
  restaurantName: Scalars['String']['output']
  status: RestaurantLocationStatus
  timeZone: Scalars['String']['output']
}

export type PublishedToastPayConfigError = {
  __typename: 'PublishedToastPayConfigError'
  code: PublishedToastPayConfigErrorCode
  message?: Maybe<Scalars['String']['output']>
}

export type PublishedToastPayConfigErrorCode = 'FailedToRetrieveConfig'

export type PublishedToastPayConfigResponse =
  | PublishedToastPayConfigError
  | ToastPayConfig

export type QlOrderConfirmationScreenConfig = {
  __typename: 'QLOrderConfirmationScreenConfig'
  backgroundColor?: Maybe<Scalars['String']['output']>
  fontPrimaryColor?: Maybe<Scalars['String']['output']>
  fontSecondaryColor?: Maybe<Scalars['String']['output']>
  fontSize?: Maybe<OrderConfirmationScreenFontSize>
  fontUrl?: Maybe<Scalars['String']['output']>
  idleBackgroundImageDurationMs?: Maybe<Scalars['Int']['output']>
  idleBackgroundImageUrls?: Maybe<Array<Scalars['String']['output']>>
  orderInProgressBackgroundImageUrl?: Maybe<Scalars['String']['output']>
  orderInProgressForegroundColor?: Maybe<Scalars['String']['output']>
  thankYouMessage?: Maybe<Scalars['String']['output']>
  thankYouTitle?: Maybe<Scalars['String']['output']>
}

export type QlStockDataConfig = {
  __typename: 'QLStockDataConfig'
  stockdataEnabled: Scalars['Boolean']['output']
}

export type QlStockOutConfig = {
  __typename: 'QLStockOutConfig'
  stockImageUrl: Scalars['String']['output']
  stockoutEnabled: Scalars['Boolean']['output']
}

/**  Overview: This schema defines the Orders API's GraphQL schema, which provides access to orders and checks. Most fields are directly mapped from the Orders API's response fields, with some calculated fields added for convenience. Newly added fields that do not have an OAPI analog have been documented as such. The Orders API's OpenAPI specification can be found here: https://github.toasttab.com/toasttab/toast-orders/blob/main/toast-orders-api/src/main/resources/toast-orders-api.yaml  */
export type Query = {
  __typename: 'Query'
  accessibleRestaurantSets?: Maybe<Array<AccessibleRestaurantSet>>
  allGroups: Array<RestaurantGroup>
  allLocations: Array<RestaurantLocation>
  allPermissionsForUser?: Maybe<Array<RestaurantSet>>
  /** Audit Saved Menus given a channel or menus and a list of audit settings */
  auditSavedMenusByChannel: Array<SavedMenuAuditSetting>
  authConfiguration: AuthConfigurationQuery
  autoComplete: SearchResponse
  booking: BookingQuery
  capital: CapitalQuery
  /** @deprecated Temporary query until MLX supports allLocations */
  capitalAllLocations: Array<SavedRestaurantLocation>
  cashDrawerConfig: CashDrawerConfig
  cashDrawers: Array<CashDrawer>
  /** @deprecated Please use the navigation query */
  categories: Array<Category>
  channel?: Maybe<ChannelQuery>
  /** Get all ChannelMenuEntities that belong to a channel */
  channelMenuEntities: Array<ChannelMenuEntity>
  channelMenuEntitiesByMenu: Array<ChannelMenuEntity>
  channelMenuPriceAdjustment?: Maybe<ChannelMenuPriceAdjustment>
  chat?: Maybe<ChatResponse>
  closeOutReportingConfig: CloseOutReportingConfig
  closeOutReportingContent: CloseOutReportingContent
  configCopy: ConfigCopyQuery
  configs: ConfigQuery
  customerBilling?: Maybe<CustomerBillingQuery>
  customerInvoices: CustomerInvoiceQuery
  customerTasks: CustomerTasksQuery
  customerUserAuthorization?: Maybe<CustomerUserAuthorizationQuery>
  dataExtensions: DataExtensionQuery
  /** Get metrics for a specific restaurant and date range, with optional comparison */
  dateRangeMetrics: DateRangeMetrics
  deviceHub: DeviceQueries
  driveThru: DriveThruQuery
  dsModel?: Maybe<DsModel>
  dsModelHealthStatus?: Maybe<DsModelHealthStatus>
  /**  employee() is in the deprecation process. Please use employeeByRestaurantUserGuid() instead  */
  employee: Employee
  employeeById?: Maybe<EmployeeV2>
  /**  employeeByRestaurantUserGuid takes in a ToastWeb RestaurantUserGuid and returns a corresponding EmployeeV2 object  */
  employeeByRestaurantUserGuid?: Maybe<EmployeeV2>
  /** Fetch employees for logged in restaurant */
  employees: Array<RestaurantUser>
  /**  employeesByLocation returns all employees that work at a given restaurant location. Pass in the restaurant location guid with the request header Toast-Restaurant-External-Id  */
  employeesByLocation: Array<Employee>
  /** Fetch employees for requested restaurants */
  employeesByRestaurants: Array<RestaurantUser>
  /** @deprecated Use financialInsights.fetchCashFlowTransactions */
  fetchCashFlowTransactions: FetchCashFlowTransactionsResponse
  /** @deprecated Use logbook.fetchLogbookCategories */
  fetchLogbookCategories?: Maybe<Array<LogbookCategory>>
  /** @deprecated Use logbook.fetchLogbookEntriesByBusinessDay */
  fetchLogbookEntriesByBusinessDay?: Maybe<Array<LogbookEntry>>
  /** @deprecated Use logbook.fetchLogbookEntry */
  fetchLogbookEntry?: Maybe<LogbookEntry>
  financialInsights: FinancialInsightsQuery
  foodWaste: FoodWasteQuery
  /** Generates a user hash for the intercom iOS client */
  generateIntercomUserHash: IntercomUserHashResult
  /** @deprecated Use financialInsights.getCashFlowAccounts */
  getCashFlowAccounts: Array<CashFlowAccount>
  /** @deprecated Use financialInsights.getCashFlowSummary */
  getCashFlowSummary?: Maybe<CashFlowSummary>
  /** Get daily metrics for specific restaurants and business day with optional comparison */
  getDailyMetrics: Array<DailyMetrics>
  /** Get the ancestor paths of menu groups */
  getParentMenuGroupAncestors: Array<EntityAncestorResponse>
  /** @deprecated Use financialInsights.getPnLReport */
  getPnLReport: PnLReport
  /** @deprecated Use financialInsights.getPnLReportAISummary */
  getPnLReportAISummary?: Maybe<PnLaiSummary>
  /** Get restaurant metrics for a specific restaurant and business day with optional comparison */
  getRestaurantMetrics: RestaurantMetrics
  /** Get top selling item metrics */
  getTopSellingItemMetrics?: Maybe<TopSellingItemConnection>
  giftCardConfig: GiftCardQuery
  /** @deprecated Please use the navigation query */
  globalNavigationMenu: GlobalNavigationMenu
  group: RestaurantGroup
  guestFeedback?: Maybe<GuestFeedbackQuery>
  guestQuoteTimes?: Maybe<GuestQuoteTimes>
  idmUsers?: Maybe<IdmUsersQuery>
  itemFeedbackConfig: ItemFeedbackConfigResponse
  jobV2?: Maybe<JobResponse>
  /** Fetch jobs for logged in restaurant */
  jobs: Array<Job>
  /** Fetch jobs for requested restaurants */
  jobsByRestaurants: Array<Job>
  jobsV2?: Maybe<JobsResponse>
  jobsV2Batch?: Maybe<JobsBatchResponse>
  kiosk?: Maybe<KioskQuery>
  kitchenCapacityHealthStatus: KitchenCapacityHealthStatus
  /** @deprecated Please use the `job` query with the unified job ID to retrieve Job information. */
  legacyJob?: Maybe<JobResponse>
  /** @deprecated Please use the `jobsBatch` query with the unified job IDs to retrieve Job information. */
  legacyJobsBatch?: Maybe<JobsLegacyBatchResponse>
  location: RestaurantLocation
  logbook: LogbookQuery
  /**  Returns the list of management groups that the current user has access to  */
  managementGroupAccess?: Maybe<Array<Maybe<ManagementGroup>>>
  marketing: MarketingQuery
  memberships: MembershipsQuery
  /** Search indexed menu entities via OpenSearch */
  menuEntities: Array<MenuEntity>
  /** Load ancestors of menu entities  */
  menuEntityAncestors: Array<EntityAncestorResponse>
  /** Fetch menu groups */
  menuGroups: Array<MenuGroup>
  /**
   * Load ancestors of menu items
   * @deprecated use menuEntityAncestors instead
   */
  menuItemAncestors: Array<EntityAncestorResponse>
  menuItemByVersionId: MenuItem
  menuItemPlateCost?: Maybe<MenuItemPlateCost>
  menuItemPlateCostByItemMLID?: Maybe<MenuItemPlateCost>
  menuItemPlateCostsByItemMLID?: Maybe<Array<Maybe<MenuItemPlateCost>>>
  menuUpsellConfig?: Maybe<MenuUpsellConfigQuery>
  /** Fetch all menus */
  menus: Array<Menu>
  /**
   * Load ancestors of modifiers
   * @deprecated use menuEntityAncestors instead
   */
  modifierAncestors: Array<EntityAncestorResponse>
  /** Find a specific modifierGroup by its versionId */
  modifierGroupByVersionId?: Maybe<ModifierGroup>
  /** Search through indexed modifier groups (uses open search) */
  modifierGroups: PagedModifierGroupsResponse
  /**  myEmployees returns all Employee data related to the user that made the request. For example, if a user works at 3 different restaurant locations the query will return a list of 3 employee objects.  */
  myEmployees: Array<Employee>
  navigation: NavigationQuery
  /** @deprecated Please use the navigation query */
  navigationConfigLocations: Array<NavigationConfigLocation>
  networks?: Maybe<NetworkQueries>
  noSaleReasons: Array<OpenCashDrawerReason>
  /** Fetch the steps needed for a location to complete onboarding */
  onboarding?: Maybe<OnboardingQuery>
  opt: OptQuery
  orderingSchedule?: Maybe<OrderingSchedule>
  orders: OrdersQuery
  ordersConfig?: Maybe<OrdersConfigQuery>
  outOfStockInventory?: Maybe<MenuItemInventoryConnection>
  packaging: PackagingQuery
  /** Search menu items by name and multiLocationId */
  pagedMenuItems: PagedMenuItemResponse
  /** Search modifiers by name and multiLocationId */
  pagedModifiers: PagedModifierResponse
  /** Search price levels by name and multiLocationId */
  pagedPriceLevels: PagedPriceLevelResponse
  partnerChannelConfig?: Maybe<PartnerChannelConfig>
  partners: PartnerQuery
  paymentsConfig: PaymentsConfigQuery
  payoutReasons: Array<OpenCashDrawerReason>
  permissions: Permissions
  popular: PopularSearchResponse
  productSubscriptions: ProductSubscriptionsQuery
  publish?: Maybe<PublishQuery>
  published: PublishedQueries
  publishedItemFeedbackConfig: PublishedItemFeedbackConfigResponse
  publishedToastPayConfig: PublishedToastPayConfigResponse
  /** @deprecated Please use the navigation query */
  quickActions: Array<QuickAction>
  restaurant: Restaurant
  restaurantAccess: Array<RestaurantAccess>
  restaurantLocations: RestaurantLocationsQuery
  restaurantLocationsDiff: RestaurantLocationsDiff
  restaurantSet: RestaurantSet
  restaurantSetByLegacyId: RestaurantSet
  salesCategories: Array<SavedSalesCategory>
  saved: SavedQueries
  /** Get a list of all tags created */
  savedMenuItemTags: PagedSavedMenuItemTagsResponse
  savedPrepSequences: Array<SavedPrepSequence>
  savedPrepStations: Array<SavedPrepStation>
  savedToastPayConfig: SavedToastPayConfigResponse
  search: SearchResponse
  searchEmployees: Array<EmployeeV2>
  searchEmployeesPaginated: EmployeeConnection
  searchSuggestion: SuggestionResponse
  shiftReviewConfig: ShiftReviewConfig
  /** Fetch shifts for a restaurant */
  shiftsByRestaurant: Array<EmployeeShift>
  /** Fetch shifts for a restaurant with pagination */
  shiftsByRestaurantPaged: EmployeeShiftConnection
  /** Fetch shifts for multiple restaurants */
  shiftsByRestaurants: Array<EmployeeShift>
  /** Fetch shifts for multiple restaurants with pagination */
  shiftsByRestaurantsPaged: EmployeeShiftConnection
  /** Fetch shifts */
  shiftsPagedV2: EmployeeShiftConnectionV2
  /** Fetch employees based on filtered for shifts */
  shiftsV2Employees: ShiftV2EmployeesResponse
  smartQuote?: Maybe<SmartQuoteQuery>
  smartQuoteSimpleComparison?: Maybe<SmartQuoteSimpleComparisonQuery>
  sousChef: SousChefQuery
  sousChefAdmin: SousChefAdminQuery
  stockForMenuItemByVersionId?: Maybe<Stock>
  support: SupportQuery
  takeoutDeliveryCategoryPage?: Maybe<TakeoutDeliveryCategoryPage>
  takeoutDeliveryConfig?: Maybe<TakeoutDeliveryConfig>
  taxRates: Array<SavedTaxRate>
  thirdPartyMock: ThirdPartyMockQuery
  thirdPartyOrderingOnboarding: ThirdPartyOrderingOnboardingQuery
  tips: TipsQuery
  toastNowDeviceRegistry: ToastNowDeviceRegistryQuery
  toastPayRelatedConfiguration: ToastPayRelatedConfiguration
  toastSearch: SearchQuery
  /** Top selling modifier metrics */
  topSellingModifiers?: Maybe<TopSellingModifierConnection>
  trials: TrialsQuery
  uiOptions?: Maybe<UiOptionsQuery>
  userPreferences: UserPreferencesQuery
  userRecentSearches: RecentSearchesResponse
}

/**  Overview: This schema defines the Orders API's GraphQL schema, which provides access to orders and checks. Most fields are directly mapped from the Orders API's response fields, with some calculated fields added for convenience. Newly added fields that do not have an OAPI analog have been documented as such. The Orders API's OpenAPI specification can be found here: https://github.toasttab.com/toasttab/toast-orders/blob/main/toast-orders-api/src/main/resources/toast-orders-api.yaml  */
export type QueryAccessibleRestaurantSetsArgs = {
  input: AccessibleRestaurantSetsInput
}

/**  Overview: This schema defines the Orders API's GraphQL schema, which provides access to orders and checks. Most fields are directly mapped from the Orders API's response fields, with some calculated fields added for convenience. Newly added fields that do not have an OAPI analog have been documented as such. The Orders API's OpenAPI specification can be found here: https://github.toasttab.com/toasttab/toast-orders/blob/main/toast-orders-api/src/main/resources/toast-orders-api.yaml  */
export type QueryAllGroupsArgs = {
  filter?: InputMaybe<GroupsFilterInput>
}

/**  Overview: This schema defines the Orders API's GraphQL schema, which provides access to orders and checks. Most fields are directly mapped from the Orders API's response fields, with some calculated fields added for convenience. Newly added fields that do not have an OAPI analog have been documented as such. The Orders API's OpenAPI specification can be found here: https://github.toasttab.com/toasttab/toast-orders/blob/main/toast-orders-api/src/main/resources/toast-orders-api.yaml  */
export type QueryAuditSavedMenusByChannelArgs = {
  channelMultiLocationId: Scalars['ID']['input']
  settings: Array<SavedMenuAuditSettingInput>
}

/**  Overview: This schema defines the Orders API's GraphQL schema, which provides access to orders and checks. Most fields are directly mapped from the Orders API's response fields, with some calculated fields added for convenience. Newly added fields that do not have an OAPI analog have been documented as such. The Orders API's OpenAPI specification can be found here: https://github.toasttab.com/toasttab/toast-orders/blob/main/toast-orders-api/src/main/resources/toast-orders-api.yaml  */
export type QueryAutoCompleteArgs = {
  q: Scalars['String']['input']
  size: Scalars['Int']['input']
  sources: Array<SearchDataSource>
}

/**  Overview: This schema defines the Orders API's GraphQL schema, which provides access to orders and checks. Most fields are directly mapped from the Orders API's response fields, with some calculated fields added for convenience. Newly added fields that do not have an OAPI analog have been documented as such. The Orders API's OpenAPI specification can be found here: https://github.toasttab.com/toasttab/toast-orders/blob/main/toast-orders-api/src/main/resources/toast-orders-api.yaml  */
export type QueryCategoriesArgs = {
  cacheKey?: InputMaybe<Scalars['String']['input']>
  restaurantGuid?: InputMaybe<Scalars['String']['input']>
}

/**  Overview: This schema defines the Orders API's GraphQL schema, which provides access to orders and checks. Most fields are directly mapped from the Orders API's response fields, with some calculated fields added for convenience. Newly added fields that do not have an OAPI analog have been documented as such. The Orders API's OpenAPI specification can be found here: https://github.toasttab.com/toasttab/toast-orders/blob/main/toast-orders-api/src/main/resources/toast-orders-api.yaml  */
export type QueryChannelMenuEntitiesArgs = {
  channelMultiLocationId: Scalars['ID']['input']
  includeArchived?: Scalars['Boolean']['input']
}

/**  Overview: This schema defines the Orders API's GraphQL schema, which provides access to orders and checks. Most fields are directly mapped from the Orders API's response fields, with some calculated fields added for convenience. Newly added fields that do not have an OAPI analog have been documented as such. The Orders API's OpenAPI specification can be found here: https://github.toasttab.com/toasttab/toast-orders/blob/main/toast-orders-api/src/main/resources/toast-orders-api.yaml  */
export type QueryChannelMenuEntitiesByMenuArgs = {
  includeAllInManagementSet?: Scalars['Boolean']['input']
  includeArchived?: Scalars['Boolean']['input']
  menuMultiLocationId: Scalars['ID']['input']
}

/**  Overview: This schema defines the Orders API's GraphQL schema, which provides access to orders and checks. Most fields are directly mapped from the Orders API's response fields, with some calculated fields added for convenience. Newly added fields that do not have an OAPI analog have been documented as such. The Orders API's OpenAPI specification can be found here: https://github.toasttab.com/toasttab/toast-orders/blob/main/toast-orders-api/src/main/resources/toast-orders-api.yaml  */
export type QueryChannelMenuPriceAdjustmentArgs = {
  channelMultiLocationId: Scalars['ID']['input']
}

/**  Overview: This schema defines the Orders API's GraphQL schema, which provides access to orders and checks. Most fields are directly mapped from the Orders API's response fields, with some calculated fields added for convenience. Newly added fields that do not have an OAPI analog have been documented as such. The Orders API's OpenAPI specification can be found here: https://github.toasttab.com/toasttab/toast-orders/blob/main/toast-orders-api/src/main/resources/toast-orders-api.yaml  */
export type QueryChatArgs = {
  messages: Array<ChatMessageInput>
  sessionId: Scalars['ID']['input']
}

/**  Overview: This schema defines the Orders API's GraphQL schema, which provides access to orders and checks. Most fields are directly mapped from the Orders API's response fields, with some calculated fields added for convenience. Newly added fields that do not have an OAPI analog have been documented as such. The Orders API's OpenAPI specification can be found here: https://github.toasttab.com/toasttab/toast-orders/blob/main/toast-orders-api/src/main/resources/toast-orders-api.yaml  */
export type QueryDateRangeMetricsArgs = {
  compareTo?: InputMaybe<CompareTo>
  endOfRange: Scalars['Date']['input']
  restaurantGuid: Scalars['ID']['input']
  startOfRange: Scalars['Date']['input']
}

/**  Overview: This schema defines the Orders API's GraphQL schema, which provides access to orders and checks. Most fields are directly mapped from the Orders API's response fields, with some calculated fields added for convenience. Newly added fields that do not have an OAPI analog have been documented as such. The Orders API's OpenAPI specification can be found here: https://github.toasttab.com/toasttab/toast-orders/blob/main/toast-orders-api/src/main/resources/toast-orders-api.yaml  */
export type QueryEmployeeArgs = {
  restaurantUserGuid: Scalars['ID']['input']
}

/**  Overview: This schema defines the Orders API's GraphQL schema, which provides access to orders and checks. Most fields are directly mapped from the Orders API's response fields, with some calculated fields added for convenience. Newly added fields that do not have an OAPI analog have been documented as such. The Orders API's OpenAPI specification can be found here: https://github.toasttab.com/toasttab/toast-orders/blob/main/toast-orders-api/src/main/resources/toast-orders-api.yaml  */
export type QueryEmployeeByIdArgs = {
  id: Scalars['ID']['input']
}

/**  Overview: This schema defines the Orders API's GraphQL schema, which provides access to orders and checks. Most fields are directly mapped from the Orders API's response fields, with some calculated fields added for convenience. Newly added fields that do not have an OAPI analog have been documented as such. The Orders API's OpenAPI specification can be found here: https://github.toasttab.com/toasttab/toast-orders/blob/main/toast-orders-api/src/main/resources/toast-orders-api.yaml  */
export type QueryEmployeeByRestaurantUserGuidArgs = {
  restaurantUserGuid: Scalars['ID']['input']
}

/**  Overview: This schema defines the Orders API's GraphQL schema, which provides access to orders and checks. Most fields are directly mapped from the Orders API's response fields, with some calculated fields added for convenience. Newly added fields that do not have an OAPI analog have been documented as such. The Orders API's OpenAPI specification can be found here: https://github.toasttab.com/toasttab/toast-orders/blob/main/toast-orders-api/src/main/resources/toast-orders-api.yaml  */
export type QueryEmployeesByRestaurantsArgs = {
  restaurants: Array<Scalars['ID']['input']>
}

/**  Overview: This schema defines the Orders API's GraphQL schema, which provides access to orders and checks. Most fields are directly mapped from the Orders API's response fields, with some calculated fields added for convenience. Newly added fields that do not have an OAPI analog have been documented as such. The Orders API's OpenAPI specification can be found here: https://github.toasttab.com/toasttab/toast-orders/blob/main/toast-orders-api/src/main/resources/toast-orders-api.yaml  */
export type QueryFetchCashFlowTransactionsArgs = {
  request: FetchCashFlowTransactionsRequest
}

/**  Overview: This schema defines the Orders API's GraphQL schema, which provides access to orders and checks. Most fields are directly mapped from the Orders API's response fields, with some calculated fields added for convenience. Newly added fields that do not have an OAPI analog have been documented as such. The Orders API's OpenAPI specification can be found here: https://github.toasttab.com/toasttab/toast-orders/blob/main/toast-orders-api/src/main/resources/toast-orders-api.yaml  */
export type QueryFetchLogbookCategoriesArgs = {
  managementSetId: Scalars['ID']['input']
}

/**  Overview: This schema defines the Orders API's GraphQL schema, which provides access to orders and checks. Most fields are directly mapped from the Orders API's response fields, with some calculated fields added for convenience. Newly added fields that do not have an OAPI analog have been documented as such. The Orders API's OpenAPI specification can be found here: https://github.toasttab.com/toasttab/toast-orders/blob/main/toast-orders-api/src/main/resources/toast-orders-api.yaml  */
export type QueryFetchLogbookEntriesByBusinessDayArgs = {
  businessDay: Scalars['Date']['input']
  locationID: Scalars['ID']['input']
}

/**  Overview: This schema defines the Orders API's GraphQL schema, which provides access to orders and checks. Most fields are directly mapped from the Orders API's response fields, with some calculated fields added for convenience. Newly added fields that do not have an OAPI analog have been documented as such. The Orders API's OpenAPI specification can be found here: https://github.toasttab.com/toasttab/toast-orders/blob/main/toast-orders-api/src/main/resources/toast-orders-api.yaml  */
export type QueryFetchLogbookEntryArgs = {
  id: Scalars['ID']['input']
  locationID: Scalars['ID']['input']
}

/**  Overview: This schema defines the Orders API's GraphQL schema, which provides access to orders and checks. Most fields are directly mapped from the Orders API's response fields, with some calculated fields added for convenience. Newly added fields that do not have an OAPI analog have been documented as such. The Orders API's OpenAPI specification can be found here: https://github.toasttab.com/toasttab/toast-orders/blob/main/toast-orders-api/src/main/resources/toast-orders-api.yaml  */
export type QueryGenerateIntercomUserHashArgs = {
  clientType: IntercomClientType
}

/**  Overview: This schema defines the Orders API's GraphQL schema, which provides access to orders and checks. Most fields are directly mapped from the Orders API's response fields, with some calculated fields added for convenience. Newly added fields that do not have an OAPI analog have been documented as such. The Orders API's OpenAPI specification can be found here: https://github.toasttab.com/toasttab/toast-orders/blob/main/toast-orders-api/src/main/resources/toast-orders-api.yaml  */
export type QueryGetDailyMetricsArgs = {
  businessDay: Scalars['Date']['input']
  compareTo?: InputMaybe<CompareTo>
  cutoffTimestamp?: InputMaybe<Scalars['DateTime']['input']>
  restaurantGuids: Array<Scalars['ID']['input']>
}

/**  Overview: This schema defines the Orders API's GraphQL schema, which provides access to orders and checks. Most fields are directly mapped from the Orders API's response fields, with some calculated fields added for convenience. Newly added fields that do not have an OAPI analog have been documented as such. The Orders API's OpenAPI specification can be found here: https://github.toasttab.com/toasttab/toast-orders/blob/main/toast-orders-api/src/main/resources/toast-orders-api.yaml  */
export type QueryGetParentMenuGroupAncestorsArgs = {
  query: MenuPathsQuery
}

/**  Overview: This schema defines the Orders API's GraphQL schema, which provides access to orders and checks. Most fields are directly mapped from the Orders API's response fields, with some calculated fields added for convenience. Newly added fields that do not have an OAPI analog have been documented as such. The Orders API's OpenAPI specification can be found here: https://github.toasttab.com/toasttab/toast-orders/blob/main/toast-orders-api/src/main/resources/toast-orders-api.yaml  */
export type QueryGetPnLReportArgs = {
  request: PnLReportRequest
}

/**  Overview: This schema defines the Orders API's GraphQL schema, which provides access to orders and checks. Most fields are directly mapped from the Orders API's response fields, with some calculated fields added for convenience. Newly added fields that do not have an OAPI analog have been documented as such. The Orders API's OpenAPI specification can be found here: https://github.toasttab.com/toasttab/toast-orders/blob/main/toast-orders-api/src/main/resources/toast-orders-api.yaml  */
export type QueryGetPnLReportAiSummaryArgs = {
  request: PnLReportRequest
}

/**  Overview: This schema defines the Orders API's GraphQL schema, which provides access to orders and checks. Most fields are directly mapped from the Orders API's response fields, with some calculated fields added for convenience. Newly added fields that do not have an OAPI analog have been documented as such. The Orders API's OpenAPI specification can be found here: https://github.toasttab.com/toasttab/toast-orders/blob/main/toast-orders-api/src/main/resources/toast-orders-api.yaml  */
export type QueryGetRestaurantMetricsArgs = {
  businessDay: Scalars['Date']['input']
  compareTo?: InputMaybe<CompareTo>
  restaurantGuid: Scalars['ID']['input']
}

/**  Overview: This schema defines the Orders API's GraphQL schema, which provides access to orders and checks. Most fields are directly mapped from the Orders API's response fields, with some calculated fields added for convenience. Newly added fields that do not have an OAPI analog have been documented as such. The Orders API's OpenAPI specification can be found here: https://github.toasttab.com/toasttab/toast-orders/blob/main/toast-orders-api/src/main/resources/toast-orders-api.yaml  */
export type QueryGetTopSellingItemMetricsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  filter: TopSellingItemFilter
  first: Scalars['Int']['input']
}

/**  Overview: This schema defines the Orders API's GraphQL schema, which provides access to orders and checks. Most fields are directly mapped from the Orders API's response fields, with some calculated fields added for convenience. Newly added fields that do not have an OAPI analog have been documented as such. The Orders API's OpenAPI specification can be found here: https://github.toasttab.com/toasttab/toast-orders/blob/main/toast-orders-api/src/main/resources/toast-orders-api.yaml  */
export type QueryGlobalNavigationMenuArgs = {
  cacheKey?: InputMaybe<Scalars['String']['input']>
  restaurantGuid?: InputMaybe<Scalars['String']['input']>
}

/**  Overview: This schema defines the Orders API's GraphQL schema, which provides access to orders and checks. Most fields are directly mapped from the Orders API's response fields, with some calculated fields added for convenience. Newly added fields that do not have an OAPI analog have been documented as such. The Orders API's OpenAPI specification can be found here: https://github.toasttab.com/toasttab/toast-orders/blob/main/toast-orders-api/src/main/resources/toast-orders-api.yaml  */
export type QueryGroupArgs = {
  guid: Scalars['ID']['input']
}

/**  Overview: This schema defines the Orders API's GraphQL schema, which provides access to orders and checks. Most fields are directly mapped from the Orders API's response fields, with some calculated fields added for convenience. Newly added fields that do not have an OAPI analog have been documented as such. The Orders API's OpenAPI specification can be found here: https://github.toasttab.com/toasttab/toast-orders/blob/main/toast-orders-api/src/main/resources/toast-orders-api.yaml  */
export type QueryJobV2Args = {
  jobRequest: JobRequest
}

/**  Overview: This schema defines the Orders API's GraphQL schema, which provides access to orders and checks. Most fields are directly mapped from the Orders API's response fields, with some calculated fields added for convenience. Newly added fields that do not have an OAPI analog have been documented as such. The Orders API's OpenAPI specification can be found here: https://github.toasttab.com/toasttab/toast-orders/blob/main/toast-orders-api/src/main/resources/toast-orders-api.yaml  */
export type QueryJobsByRestaurantsArgs = {
  restaurants: Array<Scalars['ID']['input']>
}

/**  Overview: This schema defines the Orders API's GraphQL schema, which provides access to orders and checks. Most fields are directly mapped from the Orders API's response fields, with some calculated fields added for convenience. Newly added fields that do not have an OAPI analog have been documented as such. The Orders API's OpenAPI specification can be found here: https://github.toasttab.com/toasttab/toast-orders/blob/main/toast-orders-api/src/main/resources/toast-orders-api.yaml  */
export type QueryJobsV2Args = {
  filter?: InputMaybe<JobsFilterInput>
  pagination?: InputMaybe<JobsPaginationInput>
}

/**  Overview: This schema defines the Orders API's GraphQL schema, which provides access to orders and checks. Most fields are directly mapped from the Orders API's response fields, with some calculated fields added for convenience. Newly added fields that do not have an OAPI analog have been documented as such. The Orders API's OpenAPI specification can be found here: https://github.toasttab.com/toasttab/toast-orders/blob/main/toast-orders-api/src/main/resources/toast-orders-api.yaml  */
export type QueryJobsV2BatchArgs = {
  jobsBatchRequest: JobsBatchRequest
}

/**  Overview: This schema defines the Orders API's GraphQL schema, which provides access to orders and checks. Most fields are directly mapped from the Orders API's response fields, with some calculated fields added for convenience. Newly added fields that do not have an OAPI analog have been documented as such. The Orders API's OpenAPI specification can be found here: https://github.toasttab.com/toasttab/toast-orders/blob/main/toast-orders-api/src/main/resources/toast-orders-api.yaml  */
export type QueryLegacyJobArgs = {
  legacyJobRequest: LegacyJobRequest
}

/**  Overview: This schema defines the Orders API's GraphQL schema, which provides access to orders and checks. Most fields are directly mapped from the Orders API's response fields, with some calculated fields added for convenience. Newly added fields that do not have an OAPI analog have been documented as such. The Orders API's OpenAPI specification can be found here: https://github.toasttab.com/toasttab/toast-orders/blob/main/toast-orders-api/src/main/resources/toast-orders-api.yaml  */
export type QueryLegacyJobsBatchArgs = {
  legacyJobsBatchRequest: LegacyJobsBatchRequest
}

/**  Overview: This schema defines the Orders API's GraphQL schema, which provides access to orders and checks. Most fields are directly mapped from the Orders API's response fields, with some calculated fields added for convenience. Newly added fields that do not have an OAPI analog have been documented as such. The Orders API's OpenAPI specification can be found here: https://github.toasttab.com/toasttab/toast-orders/blob/main/toast-orders-api/src/main/resources/toast-orders-api.yaml  */
export type QueryLocationArgs = {
  guid: Scalars['ID']['input']
}

/**  Overview: This schema defines the Orders API's GraphQL schema, which provides access to orders and checks. Most fields are directly mapped from the Orders API's response fields, with some calculated fields added for convenience. Newly added fields that do not have an OAPI analog have been documented as such. The Orders API's OpenAPI specification can be found here: https://github.toasttab.com/toasttab/toast-orders/blob/main/toast-orders-api/src/main/resources/toast-orders-api.yaml  */
export type QueryManagementGroupAccessArgs = {
  includeArchived?: InputMaybe<Scalars['Boolean']['input']>
  includeInactive?: InputMaybe<Scalars['Boolean']['input']>
}

/**  Overview: This schema defines the Orders API's GraphQL schema, which provides access to orders and checks. Most fields are directly mapped from the Orders API's response fields, with some calculated fields added for convenience. Newly added fields that do not have an OAPI analog have been documented as such. The Orders API's OpenAPI specification can be found here: https://github.toasttab.com/toasttab/toast-orders/blob/main/toast-orders-api/src/main/resources/toast-orders-api.yaml  */
export type QueryMenuEntitiesArgs = {
  options: MenuEntitiesInput
}

/**  Overview: This schema defines the Orders API's GraphQL schema, which provides access to orders and checks. Most fields are directly mapped from the Orders API's response fields, with some calculated fields added for convenience. Newly added fields that do not have an OAPI analog have been documented as such. The Orders API's OpenAPI specification can be found here: https://github.toasttab.com/toasttab/toast-orders/blob/main/toast-orders-api/src/main/resources/toast-orders-api.yaml  */
export type QueryMenuEntityAncestorsArgs = {
  query: MenusAncestorQuery
}

/**  Overview: This schema defines the Orders API's GraphQL schema, which provides access to orders and checks. Most fields are directly mapped from the Orders API's response fields, with some calculated fields added for convenience. Newly added fields that do not have an OAPI analog have been documented as such. The Orders API's OpenAPI specification can be found here: https://github.toasttab.com/toasttab/toast-orders/blob/main/toast-orders-api/src/main/resources/toast-orders-api.yaml  */
export type QueryMenuGroupsArgs = {
  query: MenuEntityQuery
}

/**  Overview: This schema defines the Orders API's GraphQL schema, which provides access to orders and checks. Most fields are directly mapped from the Orders API's response fields, with some calculated fields added for convenience. Newly added fields that do not have an OAPI analog have been documented as such. The Orders API's OpenAPI specification can be found here: https://github.toasttab.com/toasttab/toast-orders/blob/main/toast-orders-api/src/main/resources/toast-orders-api.yaml  */
export type QueryMenuItemAncestorsArgs = {
  query: MenuEntityAncestorsQuery
}

/**  Overview: This schema defines the Orders API's GraphQL schema, which provides access to orders and checks. Most fields are directly mapped from the Orders API's response fields, with some calculated fields added for convenience. Newly added fields that do not have an OAPI analog have been documented as such. The Orders API's OpenAPI specification can be found here: https://github.toasttab.com/toasttab/toast-orders/blob/main/toast-orders-api/src/main/resources/toast-orders-api.yaml  */
export type QueryMenuItemByVersionIdArgs = {
  versionId: Scalars['ID']['input']
}

/**  Overview: This schema defines the Orders API's GraphQL schema, which provides access to orders and checks. Most fields are directly mapped from the Orders API's response fields, with some calculated fields added for convenience. Newly added fields that do not have an OAPI analog have been documented as such. The Orders API's OpenAPI specification can be found here: https://github.toasttab.com/toasttab/toast-orders/blob/main/toast-orders-api/src/main/resources/toast-orders-api.yaml  */
export type QueryMenuItemPlateCostArgs = {
  id: Scalars['ID']['input']
}

/**  Overview: This schema defines the Orders API's GraphQL schema, which provides access to orders and checks. Most fields are directly mapped from the Orders API's response fields, with some calculated fields added for convenience. Newly added fields that do not have an OAPI analog have been documented as such. The Orders API's OpenAPI specification can be found here: https://github.toasttab.com/toasttab/toast-orders/blob/main/toast-orders-api/src/main/resources/toast-orders-api.yaml  */
export type QueryMenuItemPlateCostByItemMlidArgs = {
  itemMLID: Scalars['String']['input']
  source?: InputMaybe<PlateCostSource>
}

/**  Overview: This schema defines the Orders API's GraphQL schema, which provides access to orders and checks. Most fields are directly mapped from the Orders API's response fields, with some calculated fields added for convenience. Newly added fields that do not have an OAPI analog have been documented as such. The Orders API's OpenAPI specification can be found here: https://github.toasttab.com/toasttab/toast-orders/blob/main/toast-orders-api/src/main/resources/toast-orders-api.yaml  */
export type QueryMenuItemPlateCostsByItemMlidArgs = {
  itemMLID: Scalars['String']['input']
}

/**  Overview: This schema defines the Orders API's GraphQL schema, which provides access to orders and checks. Most fields are directly mapped from the Orders API's response fields, with some calculated fields added for convenience. Newly added fields that do not have an OAPI analog have been documented as such. The Orders API's OpenAPI specification can be found here: https://github.toasttab.com/toasttab/toast-orders/blob/main/toast-orders-api/src/main/resources/toast-orders-api.yaml  */
export type QueryMenusArgs = {
  query?: InputMaybe<MenuEntityQuery>
}

/**  Overview: This schema defines the Orders API's GraphQL schema, which provides access to orders and checks. Most fields are directly mapped from the Orders API's response fields, with some calculated fields added for convenience. Newly added fields that do not have an OAPI analog have been documented as such. The Orders API's OpenAPI specification can be found here: https://github.toasttab.com/toasttab/toast-orders/blob/main/toast-orders-api/src/main/resources/toast-orders-api.yaml  */
export type QueryModifierAncestorsArgs = {
  query: MenuEntityAncestorsQuery
}

/**  Overview: This schema defines the Orders API's GraphQL schema, which provides access to orders and checks. Most fields are directly mapped from the Orders API's response fields, with some calculated fields added for convenience. Newly added fields that do not have an OAPI analog have been documented as such. The Orders API's OpenAPI specification can be found here: https://github.toasttab.com/toasttab/toast-orders/blob/main/toast-orders-api/src/main/resources/toast-orders-api.yaml  */
export type QueryModifierGroupByVersionIdArgs = {
  versionId: Scalars['ID']['input']
}

/**  Overview: This schema defines the Orders API's GraphQL schema, which provides access to orders and checks. Most fields are directly mapped from the Orders API's response fields, with some calculated fields added for convenience. Newly added fields that do not have an OAPI analog have been documented as such. The Orders API's OpenAPI specification can be found here: https://github.toasttab.com/toasttab/toast-orders/blob/main/toast-orders-api/src/main/resources/toast-orders-api.yaml  */
export type QueryModifierGroupsArgs = {
  query: MenuEntityQuery
}

/**  Overview: This schema defines the Orders API's GraphQL schema, which provides access to orders and checks. Most fields are directly mapped from the Orders API's response fields, with some calculated fields added for convenience. Newly added fields that do not have an OAPI analog have been documented as such. The Orders API's OpenAPI specification can be found here: https://github.toasttab.com/toasttab/toast-orders/blob/main/toast-orders-api/src/main/resources/toast-orders-api.yaml  */
export type QueryOutOfStockInventoryArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  first: Scalars['Int']['input']
  includeOptionOnly?: Scalars['Boolean']['input']
}

/**  Overview: This schema defines the Orders API's GraphQL schema, which provides access to orders and checks. Most fields are directly mapped from the Orders API's response fields, with some calculated fields added for convenience. Newly added fields that do not have an OAPI analog have been documented as such. The Orders API's OpenAPI specification can be found here: https://github.toasttab.com/toasttab/toast-orders/blob/main/toast-orders-api/src/main/resources/toast-orders-api.yaml  */
export type QueryPagedMenuItemsArgs = {
  query: MenuEntityQuery
}

/**  Overview: This schema defines the Orders API's GraphQL schema, which provides access to orders and checks. Most fields are directly mapped from the Orders API's response fields, with some calculated fields added for convenience. Newly added fields that do not have an OAPI analog have been documented as such. The Orders API's OpenAPI specification can be found here: https://github.toasttab.com/toasttab/toast-orders/blob/main/toast-orders-api/src/main/resources/toast-orders-api.yaml  */
export type QueryPagedModifiersArgs = {
  query: MenuEntityQuery
}

/**  Overview: This schema defines the Orders API's GraphQL schema, which provides access to orders and checks. Most fields are directly mapped from the Orders API's response fields, with some calculated fields added for convenience. Newly added fields that do not have an OAPI analog have been documented as such. The Orders API's OpenAPI specification can be found here: https://github.toasttab.com/toasttab/toast-orders/blob/main/toast-orders-api/src/main/resources/toast-orders-api.yaml  */
export type QueryPagedPriceLevelsArgs = {
  query: MenuEntityQuery
}

/**  Overview: This schema defines the Orders API's GraphQL schema, which provides access to orders and checks. Most fields are directly mapped from the Orders API's response fields, with some calculated fields added for convenience. Newly added fields that do not have an OAPI analog have been documented as such. The Orders API's OpenAPI specification can be found here: https://github.toasttab.com/toasttab/toast-orders/blob/main/toast-orders-api/src/main/resources/toast-orders-api.yaml  */
export type QueryPopularArgs = {
  size: Scalars['Int']['input']
}

/**  Overview: This schema defines the Orders API's GraphQL schema, which provides access to orders and checks. Most fields are directly mapped from the Orders API's response fields, with some calculated fields added for convenience. Newly added fields that do not have an OAPI analog have been documented as such. The Orders API's OpenAPI specification can be found here: https://github.toasttab.com/toasttab/toast-orders/blob/main/toast-orders-api/src/main/resources/toast-orders-api.yaml  */
export type QueryRestaurantAccessArgs = {
  afterRestaurantID?: InputMaybe<Scalars['ID']['input']>
  includeArchived?: InputMaybe<Scalars['Boolean']['input']>
  includeInactive?: InputMaybe<Scalars['Boolean']['input']>
  pageSize?: Scalars['Int']['input']
}

/**  Overview: This schema defines the Orders API's GraphQL schema, which provides access to orders and checks. Most fields are directly mapped from the Orders API's response fields, with some calculated fields added for convenience. Newly added fields that do not have an OAPI analog have been documented as such. The Orders API's OpenAPI specification can be found here: https://github.toasttab.com/toasttab/toast-orders/blob/main/toast-orders-api/src/main/resources/toast-orders-api.yaml  */
export type QueryRestaurantLocationsDiffArgs = {
  leftId: Scalars['ID']['input']
  rightId: Scalars['ID']['input']
}

/**  Overview: This schema defines the Orders API's GraphQL schema, which provides access to orders and checks. Most fields are directly mapped from the Orders API's response fields, with some calculated fields added for convenience. Newly added fields that do not have an OAPI analog have been documented as such. The Orders API's OpenAPI specification can be found here: https://github.toasttab.com/toasttab/toast-orders/blob/main/toast-orders-api/src/main/resources/toast-orders-api.yaml  */
export type QueryRestaurantSetArgs = {
  id: Scalars['ID']['input']
}

/**  Overview: This schema defines the Orders API's GraphQL schema, which provides access to orders and checks. Most fields are directly mapped from the Orders API's response fields, with some calculated fields added for convenience. Newly added fields that do not have an OAPI analog have been documented as such. The Orders API's OpenAPI specification can be found here: https://github.toasttab.com/toasttab/toast-orders/blob/main/toast-orders-api/src/main/resources/toast-orders-api.yaml  */
export type QueryRestaurantSetByLegacyIdArgs = {
  legacyId: Scalars['Long']['input']
}

/**  Overview: This schema defines the Orders API's GraphQL schema, which provides access to orders and checks. Most fields are directly mapped from the Orders API's response fields, with some calculated fields added for convenience. Newly added fields that do not have an OAPI analog have been documented as such. The Orders API's OpenAPI specification can be found here: https://github.toasttab.com/toasttab/toast-orders/blob/main/toast-orders-api/src/main/resources/toast-orders-api.yaml  */
export type QuerySalesCategoriesArgs = {
  search: SavedSalesCategoriesSearch
}

/**  Overview: This schema defines the Orders API's GraphQL schema, which provides access to orders and checks. Most fields are directly mapped from the Orders API's response fields, with some calculated fields added for convenience. Newly added fields that do not have an OAPI analog have been documented as such. The Orders API's OpenAPI specification can be found here: https://github.toasttab.com/toasttab/toast-orders/blob/main/toast-orders-api/src/main/resources/toast-orders-api.yaml  */
export type QuerySavedMenuItemTagsArgs = {
  input: SavedMenuItemTagsInput
}

/**  Overview: This schema defines the Orders API's GraphQL schema, which provides access to orders and checks. Most fields are directly mapped from the Orders API's response fields, with some calculated fields added for convenience. Newly added fields that do not have an OAPI analog have been documented as such. The Orders API's OpenAPI specification can be found here: https://github.toasttab.com/toasttab/toast-orders/blob/main/toast-orders-api/src/main/resources/toast-orders-api.yaml  */
export type QuerySavedPrepSequencesArgs = {
  search: SavedPrepSequencesSearch
}

/**  Overview: This schema defines the Orders API's GraphQL schema, which provides access to orders and checks. Most fields are directly mapped from the Orders API's response fields, with some calculated fields added for convenience. Newly added fields that do not have an OAPI analog have been documented as such. The Orders API's OpenAPI specification can be found here: https://github.toasttab.com/toasttab/toast-orders/blob/main/toast-orders-api/src/main/resources/toast-orders-api.yaml  */
export type QuerySavedPrepStationsArgs = {
  search: SavedPrepStationsSearch
}

/**  Overview: This schema defines the Orders API's GraphQL schema, which provides access to orders and checks. Most fields are directly mapped from the Orders API's response fields, with some calculated fields added for convenience. Newly added fields that do not have an OAPI analog have been documented as such. The Orders API's OpenAPI specification can be found here: https://github.toasttab.com/toasttab/toast-orders/blob/main/toast-orders-api/src/main/resources/toast-orders-api.yaml  */
export type QuerySearchArgs = {
  ignoreForMetrics?: InputMaybe<Scalars['Boolean']['input']>
  page?: InputMaybe<Scalars['Int']['input']>
  pageSize: Scalars['Int']['input']
  q: Scalars['String']['input']
  sources: Array<SearchDataSource>
  startIndex?: InputMaybe<Scalars['Int']['input']>
}

/**  Overview: This schema defines the Orders API's GraphQL schema, which provides access to orders and checks. Most fields are directly mapped from the Orders API's response fields, with some calculated fields added for convenience. Newly added fields that do not have an OAPI analog have been documented as such. The Orders API's OpenAPI specification can be found here: https://github.toasttab.com/toasttab/toast-orders/blob/main/toast-orders-api/src/main/resources/toast-orders-api.yaml  */
export type QuerySearchEmployeesArgs = {
  employeeSearchInput: EmployeeSearchInput
}

/**  Overview: This schema defines the Orders API's GraphQL schema, which provides access to orders and checks. Most fields are directly mapped from the Orders API's response fields, with some calculated fields added for convenience. Newly added fields that do not have an OAPI analog have been documented as such. The Orders API's OpenAPI specification can be found here: https://github.toasttab.com/toasttab/toast-orders/blob/main/toast-orders-api/src/main/resources/toast-orders-api.yaml  */
export type QuerySearchEmployeesPaginatedArgs = {
  employeeSearchInput: EmployeeSearchInput
  orderByInput: Array<EmployeeOrderByInput>
  paginationInput: EmployeePaginationInput
}

/**  Overview: This schema defines the Orders API's GraphQL schema, which provides access to orders and checks. Most fields are directly mapped from the Orders API's response fields, with some calculated fields added for convenience. Newly added fields that do not have an OAPI analog have been documented as such. The Orders API's OpenAPI specification can be found here: https://github.toasttab.com/toasttab/toast-orders/blob/main/toast-orders-api/src/main/resources/toast-orders-api.yaml  */
export type QuerySearchSuggestionArgs = {
  q: Scalars['String']['input']
  size: Scalars['Int']['input']
}

/**  Overview: This schema defines the Orders API's GraphQL schema, which provides access to orders and checks. Most fields are directly mapped from the Orders API's response fields, with some calculated fields added for convenience. Newly added fields that do not have an OAPI analog have been documented as such. The Orders API's OpenAPI specification can be found here: https://github.toasttab.com/toasttab/toast-orders/blob/main/toast-orders-api/src/main/resources/toast-orders-api.yaml  */
export type QueryShiftsByRestaurantArgs = {
  filter: ShiftFilterInput
  restaurant: Scalars['ID']['input']
}

/**  Overview: This schema defines the Orders API's GraphQL schema, which provides access to orders and checks. Most fields are directly mapped from the Orders API's response fields, with some calculated fields added for convenience. Newly added fields that do not have an OAPI analog have been documented as such. The Orders API's OpenAPI specification can be found here: https://github.toasttab.com/toasttab/toast-orders/blob/main/toast-orders-api/src/main/resources/toast-orders-api.yaml  */
export type QueryShiftsByRestaurantPagedArgs = {
  filter: ShiftFilterInput
  pagination: PaginationInput
  restaurant: Scalars['ID']['input']
}

/**  Overview: This schema defines the Orders API's GraphQL schema, which provides access to orders and checks. Most fields are directly mapped from the Orders API's response fields, with some calculated fields added for convenience. Newly added fields that do not have an OAPI analog have been documented as such. The Orders API's OpenAPI specification can be found here: https://github.toasttab.com/toasttab/toast-orders/blob/main/toast-orders-api/src/main/resources/toast-orders-api.yaml  */
export type QueryShiftsByRestaurantsArgs = {
  filter: ShiftFilterInput
  restaurants: Array<Scalars['ID']['input']>
}

/**  Overview: This schema defines the Orders API's GraphQL schema, which provides access to orders and checks. Most fields are directly mapped from the Orders API's response fields, with some calculated fields added for convenience. Newly added fields that do not have an OAPI analog have been documented as such. The Orders API's OpenAPI specification can be found here: https://github.toasttab.com/toasttab/toast-orders/blob/main/toast-orders-api/src/main/resources/toast-orders-api.yaml  */
export type QueryShiftsByRestaurantsPagedArgs = {
  filter: ShiftFilterInput
  pagination: PaginationInput
  restaurants: Array<Scalars['ID']['input']>
}

/**  Overview: This schema defines the Orders API's GraphQL schema, which provides access to orders and checks. Most fields are directly mapped from the Orders API's response fields, with some calculated fields added for convenience. Newly added fields that do not have an OAPI analog have been documented as such. The Orders API's OpenAPI specification can be found here: https://github.toasttab.com/toasttab/toast-orders/blob/main/toast-orders-api/src/main/resources/toast-orders-api.yaml  */
export type QueryShiftsPagedV2Args = {
  filter: ShiftFilterInputV2
  orderBy?: InputMaybe<Array<ShiftOrderByInput>>
  pagination: ShiftsV2PaginationInput
  quickSearch?: InputMaybe<Scalars['String']['input']>
}

/**  Overview: This schema defines the Orders API's GraphQL schema, which provides access to orders and checks. Most fields are directly mapped from the Orders API's response fields, with some calculated fields added for convenience. Newly added fields that do not have an OAPI analog have been documented as such. The Orders API's OpenAPI specification can be found here: https://github.toasttab.com/toasttab/toast-orders/blob/main/toast-orders-api/src/main/resources/toast-orders-api.yaml  */
export type QueryShiftsV2EmployeesArgs = {
  name?: InputMaybe<Scalars['String']['input']>
  shiftFilter: ShiftFilterInputV2
  size?: Scalars['Int']['input']
}

/**  Overview: This schema defines the Orders API's GraphQL schema, which provides access to orders and checks. Most fields are directly mapped from the Orders API's response fields, with some calculated fields added for convenience. Newly added fields that do not have an OAPI analog have been documented as such. The Orders API's OpenAPI specification can be found here: https://github.toasttab.com/toasttab/toast-orders/blob/main/toast-orders-api/src/main/resources/toast-orders-api.yaml  */
export type QueryStockForMenuItemByVersionIdArgs = {
  versionId: Scalars['ID']['input']
}

/**  Overview: This schema defines the Orders API's GraphQL schema, which provides access to orders and checks. Most fields are directly mapped from the Orders API's response fields, with some calculated fields added for convenience. Newly added fields that do not have an OAPI analog have been documented as such. The Orders API's OpenAPI specification can be found here: https://github.toasttab.com/toasttab/toast-orders/blob/main/toast-orders-api/src/main/resources/toast-orders-api.yaml  */
export type QueryTaxRatesArgs = {
  search: SavedTaxRatesSearch
}

/**  Overview: This schema defines the Orders API's GraphQL schema, which provides access to orders and checks. Most fields are directly mapped from the Orders API's response fields, with some calculated fields added for convenience. Newly added fields that do not have an OAPI analog have been documented as such. The Orders API's OpenAPI specification can be found here: https://github.toasttab.com/toasttab/toast-orders/blob/main/toast-orders-api/src/main/resources/toast-orders-api.yaml  */
export type QueryTopSellingModifiersArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  businessDay: Scalars['Date']['input']
  first: Scalars['Int']['input']
  modifierFilters?: InputMaybe<Array<TopSellingModifierFilter>>
  parentItemFilters?: InputMaybe<Array<TopSellingItemFilterBy>>
  restaurantGuid: Scalars['ID']['input']
  sortBy: TopSellingSortBy
}

/**  Overview: This schema defines the Orders API's GraphQL schema, which provides access to orders and checks. Most fields are directly mapped from the Orders API's response fields, with some calculated fields added for convenience. Newly added fields that do not have an OAPI analog have been documented as such. The Orders API's OpenAPI specification can be found here: https://github.toasttab.com/toasttab/toast-orders/blob/main/toast-orders-api/src/main/resources/toast-orders-api.yaml  */
export type QueryUserRecentSearchesArgs = {
  size: Scalars['Int']['input']
}

export type QuickAction = {
  __typename: 'QuickAction'
  hasPermission: Scalars['Boolean']['output']
  href?: Maybe<Scalars['String']['output']>
  id: Scalars['String']['output']
  label: Scalars['String']['output']
  showAsNew: Scalars['Boolean']['output']
}

export type QuoteTime = {
  __typename: 'QuoteTime'
  deliveryHours?: Maybe<Scalars['Int']['output']>
  deliveryMinutes?: Maybe<Scalars['Int']['output']>
  id: Scalars['ID']['output']
  price?: Maybe<Scalars['String']['output']>
  takeoutHours?: Maybe<Scalars['Int']['output']>
  takeoutMinutes?: Maybe<Scalars['Int']['output']>
}

export type QuoteTimeInput = {
  deliveryHours: Scalars['Int']['input']
  deliveryMinutes: Scalars['Int']['input']
  id: Scalars['ID']['input']
  price: Scalars['String']['input']
  takeoutHours: Scalars['Int']['input']
  takeoutMinutes: Scalars['Int']['input']
}

export type ReadFailure = ShiftError & {
  __typename: 'ReadFailure'
  message?: Maybe<Scalars['String']['output']>
}

export type RecentSearchesResponse = {
  __typename: 'RecentSearchesResponse'
  items: Array<UserSearch>
}

export type ReconciliationConfig = {
  __typename: 'ReconciliationConfig'
  isOrderOwner: Scalars['Boolean']['output']
  shouldCollectCashInDrawer: Scalars['Boolean']['output']
  shouldRequireReconciliation: Scalars['Boolean']['output']
}

export type ReconciliationConfigInput = {
  isOrderOwner?: InputMaybe<Scalars['Boolean']['input']>
  shouldCollectCashInDrawer?: InputMaybe<Scalars['Boolean']['input']>
  shouldRequireReconciliation?: InputMaybe<Scalars['Boolean']['input']>
}

/** The grouping dimensions available for refunds metrics */
export type RefundsGroupingDimension = 'APPROVER' | 'EMPLOYEE'

export type RehiredEmploymentStatus = EmploymentStatus & {
  __typename: 'RehiredEmploymentStatus'
  adjustedHireDate?: Maybe<Scalars['Date']['output']>
  dismissalPeriod?: Maybe<DateRange>
  /** @deprecated use employee.hireDate at the top level instead */
  hireDate?: Maybe<Scalars['String']['output']>
  rehireDate?: Maybe<Scalars['String']['output']>
}

export type RemoveMenuGroupInput = {
  entityMultiLocationId: Scalars['ID']['input']
  parentVersionId: Scalars['ID']['input']
}

export type RemoveMenuItemInput = {
  entityMultiLocationId: Scalars['ID']['input']
  parentVersionId: Scalars['ID']['input']
}

/**  Details on what access was checked, who that access was checked against, and what attributes were evaluated, and why the decision was rendered  */
export type RenderedAuthorizationDecision = {
  __typename: 'RenderedAuthorizationDecision'
  decisions: Array<AuthorizationDecisionReason>
  result: AuthorizationDecision
  target: AuthorizationTarget
  /**  The identity platform needs to re-define the federated user objects to be more appropriate across all user bases Currently the "User" type is only for customer users. But we will support graphql for external developers and guests in the near future The authorization subgraph should union the new User types with a "Subject" type so that we may append authorization details to the federated object Until that work is done by MU we will use the current User type Once the new type is defined and can be resolved, we will switch over to that type and deprecate this field  */
  user: User
}

export type RepaymentMilestone = {
  __typename: 'RepaymentMilestone'
  amount: Money
  daysFromStartDate: Scalars['Int']['output']
}

export type ReportSection = {
  __typename: 'ReportSection'
  isDisplayed: Scalars['Boolean']['output']
  ordinal: Scalars['Int']['output']
  type: Scalars['String']['output']
}

export type ReportSectionInput = {
  isDisplayed?: InputMaybe<Scalars['Boolean']['input']>
  ordinal?: InputMaybe<Scalars['Int']['input']>
  type: Scalars['String']['input']
}

export type RequiredMode =
  | 'OPTIONAL'
  | 'OPTIONAL_FORCE_SHOW'
  | 'REQUIRED'
  | 'UNSPECIFIED'

export type ResetType = 'AUTOMATIC' | 'MANUAL' | 'UNSPECIFIED'

export type ResolvedConfigEntityI = {
  id: Scalars['ID']['output']
  managementGroup: ManagementGroup
  name?: Maybe<Scalars['String']['output']>
  restaurantLocation: SavedRestaurantLocation
}

export type ResolvedDataExtensionSet = ResolvedConfigEntityI & {
  __typename: 'ResolvedDataExtensionSet'
  id: Scalars['ID']['output']
  isArchived: Scalars['Boolean']['output']
  managementGroup: ManagementGroup
  name?: Maybe<Scalars['String']['output']>
  payments: Array<PaymentDataExtension>
  restaurantLocation: SavedRestaurantLocation
  revenueCenters: Array<RevenueCenterDataExtension>
  revision: Scalars['Int']['output']
  salesCategories: Array<SalesCategoryDataExtension>
  services: Array<ServiceDataExtension>
  target?: Maybe<RestaurantSet>
  taxRates: Array<TaxRateDataExtension>
  type: DataExtensionSetType
}

export type Response = {
  __typename: 'Response'
  response?: Maybe<Scalars['String']['output']>
}

/** Restaurant (Location) */
export type Restaurant = {
  __typename: 'Restaurant'
  /** break configurations for this restaurant */
  breakConfigs?: Maybe<Array<RestaurantBreakConfig>>
  /** The first day of the week for this restaurant (1-7 starting Sunday, default 2) */
  firstDayOfWeek: Scalars['Int']['output']
  /** The identifier provided by Toast */
  id: Scalars['ID']['output']
  /** The Location Code for this restaurant */
  locationCode?: Maybe<Scalars['String']['output']>
  /** The Location name for this restaurant */
  locationName?: Maybe<Scalars['String']['output']>
  /** The alternate name field for this restaurant */
  name?: Maybe<Scalars['String']['output']>
  /** The TimeZone for this restaurant */
  timeZone: Scalars['String']['output']
}

export type RestaurantAccess = {
  __typename: 'RestaurantAccess'
  /** A representation of a restaurant. */
  restaurantLocation: AccessibleRestaurantLocation
}

export type RestaurantAvailabilityInfo = {
  __typename: 'RestaurantAvailabilityInfo'
  invalidPartySize: Scalars['Boolean']['output']
  onlineReservationsDisabled: Scalars['Boolean']['output']
  reservationsDisabled: Scalars['Boolean']['output']
  slots: Array<SlotAvailabilityInfo>
}

export type RestaurantBookingLimit = {
  __typename: 'RestaurantBookingLimit'
  dateExceededReservations?: Maybe<Scalars['DateTime']['output']>
  dateExceededWaitlists?: Maybe<Scalars['DateTime']['output']>
  hasExceededReservation: Scalars['Boolean']['output']
  hasExceededWaitlist: Scalars['Boolean']['output']
  monthlyReservations: Scalars['Int']['output']
  monthlyWaitlists: Scalars['Int']['output']
  plan: BookingPlan
  reservationLimit?: Maybe<Scalars['Int']['output']>
  waitlistLimit?: Maybe<Scalars['Int']['output']>
}

export type RestaurantBreakConfig = {
  __typename: 'RestaurantBreakConfig'
  active: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  isPaid: Scalars['Boolean']['output']
  name: Scalars['String']['output']
}

export type RestaurantGroup = {
  __typename: 'RestaurantGroup'
  /** Archived (a.k.a deleted) */
  archived: Scalars['Boolean']['output']
  createdAt: Scalars['DateTime']['output']
  createdBy?: Maybe<User>
  /**
   * Descendant Locations (all locations all the way down)
   * @deprecated Use descendantRestaurantLocations instead
   */
  descendantLocations: Array<RestaurantLocation>
  /**  Descendant Locations (all locations all the way down) with pagination and count support  */
  descendantRestaurantLocations: SavedRestaurantLocationConnection
  /** Group description */
  description?: Maybe<Scalars['String']['output']>
  /** Direct child groups */
  groups: Array<RestaurantGroup>
  /** @deprecated Use id */
  guid: Scalars['ID']['output']
  id: Scalars['ID']['output']
  legacyId: Scalars['String']['output']
  /** Direct child locations */
  locations: Array<RestaurantLocation>
  /** Group name */
  name: Scalars['String']['output']
  /** "Direct parent groups */
  parentGroups: Array<RestaurantGroup>
  permissionBits: Scalars['String']['output']
  /** @deprecated Use permissionBits instead */
  permissionStatus?: Maybe<RestaurantSetPermissionStatus>
  /**
   * Logged user permission bits on this group
   * @deprecated use permissionBits instead
   */
  permissions: Scalars['String']['output']
  /** Restaurant Group Type */
  type: GroupType
}

export type RestaurantGroupDescendantRestaurantLocationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
}

export type RestaurantGroupOrLocation =
  | RestaurantGroup
  | RestaurantLocation
  | SavedRestaurantLocation

export type RestaurantInformationResponseSuccess = {
  __typename: 'RestaurantInformationResponseSuccess'
  response: MgmtRestaurantInformation
}

export type RestaurantInput = {
  /** The restaurant identifier */
  id: Scalars['ID']['input']
}

export type RestaurantLeafDataJobInput = {
  lastEvaluatedKey?: InputMaybe<RestaurantLeafDataJobLastEvaluatedKeyInput>
  limit: Scalars['Int']['input']
}

export type RestaurantLeafDataJobLastEvaluatedKey = {
  __typename: 'RestaurantLeafDataJobLastEvaluatedKey'
  partitionKey: Scalars['String']['output']
  sortKey: Scalars['String']['output']
}

export type RestaurantLeafDataJobLastEvaluatedKeyInput = {
  partitionKey: Scalars['String']['input']
  sortKey: Scalars['String']['input']
}

export type RestaurantLeafDataJobResult = {
  __typename: 'RestaurantLeafDataJobResult'
  lastEvaluatedKey?: Maybe<RestaurantLeafDataJobLastEvaluatedKey>
  pageSize: Scalars['Int']['output']
}

/**  Add resolver for restaurant location that belongs to same Management Set as the Restaurant ID passed in the headers. It is not intended to be use outside a single management group context. Intended to be a temporary solution until toast-restaurants is resolving SavedRestaurantLocation and PublishedRestaurantLocation  */
export type RestaurantLocation = {
  __typename: 'RestaurantLocation'
  /** Restaurant Description */
  description?: Maybe<Scalars['String']['output']>
  /** @deprecated Use id */
  guid: Scalars['ID']['output']
  id: Scalars['ID']['output']
  /** @deprecated Use restaurantSetId */
  leafGuid: Scalars['ID']['output']
  /** Location code */
  locationCode?: Maybe<Scalars['String']['output']>
  /** Location name */
  locationName?: Maybe<Scalars['String']['output']>
  /** Restaurant Name */
  name: Scalars['String']['output']
  /** Direct parent groups */
  parentGroups: Array<RestaurantGroup>
  /** Permission bits */
  permissionBits: Scalars['String']['output']
  /** @deprecated use permissionBits instead */
  permissions: Scalars['String']['output']
  restaurantName: Scalars['String']['output']
  /** Restaurant Set Leaf identifier */
  restaurantSetId: Scalars['ID']['output']
}

export type RestaurantLocationAddress = {
  __typename: 'RestaurantLocationAddress'
  address1: Scalars['String']['output']
  address2?: Maybe<Scalars['String']['output']>
  city: Scalars['String']['output']
  coordinates?: Maybe<GeoCoordinates>
  country: Scalars['String']['output']
  state: Scalars['String']['output']
  zip: Scalars['String']['output']
}

export type RestaurantLocationImages = {
  __typename: 'RestaurantLocationImages'
  backgroundImageUrl?: Maybe<Scalars['Url']['output']>
  bannerImageUrl?: Maybe<Scalars['Url']['output']>
  imageUrl?: Maybe<Scalars['Url']['output']>
}

export type RestaurantLocationInterface = {
  /** Address of the location */
  address: RestaurantLocationAddress
  /** The hour of the day that separates one business day from the next, also known as the 'business day cutoff time' */
  closeoutHour: Scalars['LocalTime']['output']
  /** Currency */
  currencyCode: Scalars['String']['output']
  /** Location description */
  description?: Maybe<Scalars['String']['output']>
  /** Location identifier */
  id: Scalars['ID']['output']
  /** Images related to this location */
  images: RestaurantLocationImages
  /** Indicates that this location is not fully operational with the Toast Platform */
  isTestMode: Scalars['Boolean']['output']
  /** Language/Locale */
  language?: Maybe<Scalars['String']['output']>
  /** Location code. Normally the code used by the customer */
  locationCode?: Maybe<Scalars['String']['output']>
  /** Location name */
  locationName?: Maybe<Scalars['String']['output']>
  /** Brand + location name */
  name: Scalars['String']['output']
  /** Location phone */
  phone?: Maybe<Scalars['String']['output']>
  /** The name of the brand/restaurant */
  restaurantName: Scalars['String']['output']
  /** Active/Inactive */
  status: RestaurantLocationStatus
  /** Time Zone */
  timeZone: Scalars['String']['output']
}

export type RestaurantLocationStatus = 'ACTIVE' | 'INACTIVE'

/**  This type represents a Venn diagram of the descendant Locations of two Restaurant Sets. If a left or right is a Restaurant Location then its descendant Locations are just itself.  */
export type RestaurantLocationsDiff = {
  __typename: 'RestaurantLocationsDiff'
  /** Descendant Locations that belong to both Restaurant Group or Locations */
  intersection: Array<RestaurantLocation>
  /** Restaurant Group or Location on the left side of the Venn diagram */
  left: RestaurantGroupOrLocation
  /**  Descendant Locations that belong only to the left Restaurant Group or Location  */
  onlyLeft: Array<RestaurantLocation>
  /**  Descendant Locations that belong only to the right Restaurant Group or Location  */
  onlyRight: Array<RestaurantLocation>
  /** Restaurant Group or Location on the right side of the Venn diagram */
  right: RestaurantGroupOrLocation
}

export type RestaurantLocationsQuery = {
  __typename: 'RestaurantLocationsQuery'
  sessionLocation: SavedRestaurantLocation
}

export type RestaurantMenuItemEdge = {
  __typename: 'RestaurantMenuItemEdge'
  cursor: Scalars['String']['output']
  node: PublishedMenuItem
}

/** Top-level type for restaurant metrics */
export type RestaurantMetrics = {
  __typename: 'RestaurantMetrics'
  /** Business day for the metrics */
  businessDay: Scalars['Date']['output']
  /** Comparison business day for the metrics */
  comparisonBusinessDay?: Maybe<Scalars['Date']['output']>
  /** Labor related metrics */
  laborMetrics: LaborMetrics
  /** Order related metrics */
  orderMetrics: OrderMetrics
}

export type RestaurantNotFound = Error & {
  __typename: 'RestaurantNotFound'
  message: Scalars['String']['output']
  status: ErrorCode
}

export type RestaurantRepositoryDependencyFailure = ShiftError & {
  __typename: 'RestaurantRepositoryDependencyFailure'
  message?: Maybe<Scalars['String']['output']>
}

export type RestaurantSet = {
  __typename: 'RestaurantSet'
  /** @deprecated Use 'description' in 'set' field */
  description?: Maybe<Scalars['String']['output']>
  /** @deprecated Use 'groups' in 'set' field */
  groups?: Maybe<Array<RestaurantGroup>>
  /** The identifier provided by Toast */
  id: Scalars['ID']['output']
  /** @deprecated Determined by the type of 'set' */
  isLocation: Scalars['Boolean']['output']
  legacyId: Scalars['String']['output']
  /** @deprecated Use 'locations' in 'set' field */
  locations?: Maybe<Array<RestaurantLocation>>
  /** @deprecated Use 'name' in 'set' field */
  name: Scalars['String']['output']
  permissions?: Maybe<Permissions>
  set: RestaurantGroupOrLocation
}

export type RestaurantSetInput = {
  /** ID of the restaurant set */
  id: Scalars['ID']['input']
}

export type RestaurantSetPermissionStatus = 'GRANTED' | 'PROCESSING'

/** Reference to a RestaurantUser entity */
export type RestaurantUser = {
  __typename: 'RestaurantUser'
  /** The employee's external ID */
  externalEmployeeId?: Maybe<Scalars['ID']['output']>
  /** The identifier provided by Toast */
  id: Scalars['ID']['output']
  /** If the user is deleted in Toast. */
  isDeleted: Scalars['Boolean']['output']
  /** The jobs that this restaurant user has at this restaurant */
  jobs: Array<Job>
  restaurant: Restaurant
  /** The user that this restaurant user represents */
  user?: Maybe<User>
}

export type RestaurantUserInput = {
  /** The restaurant employee identifier */
  id: Scalars['ID']['input']
}

export type RestoreMembershipResponse = {
  __typename: 'RestoreMembershipResponse'
  code: Scalars['Int']['output']
  message?: Maybe<Scalars['String']['output']>
  success: Scalars['Boolean']['output']
}

export type RevenueCenterDataExtension = DataExtension & {
  __typename: 'RevenueCenterDataExtension'
  revenueCenter: SavedRevenueCenter
  type: DataExtensionType
  value: Scalars['String']['output']
}

export type SalesCategory = {
  __typename: 'SalesCategory'
  guid: Scalars['ID']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type SalesCategoryDataExtension = DataExtension & {
  __typename: 'SalesCategoryDataExtension'
  salesCategory: SavedSalesCategory
  type: DataExtensionType
  value: Scalars['String']['output']
}

export type SalesCategoryInput = {
  guid: Scalars['ID']['input']
  id: Scalars['ID']['input']
  name: Scalars['String']['input']
}

export type SaveCustomMenuItemUpsellInput = {
  /** ID of the upsell, if it exists */
  id?: InputMaybe<Scalars['ID']['input']>
  /**  The guids of the menu groups that will trigger the upsell if an item from them is in the order. In the future, this will be deprecated and menuGroupMlids will be used.  */
  menuGroupGuids: Array<Scalars['ID']['input']>
  /** The multi-location IDs of the menu groups that will trigger the upsell if an item from them is in the order */
  menuGroupMlids: Array<Scalars['ID']['input']>
  /**  The guid of the menu item that is being upsold. In the future, this will be deprecated and menuItemMlid will be used.  */
  menuItemGuid: Scalars['ID']['input']
  /** The multi-location ID of the menu item that is being upsold */
  menuItemMlid: Scalars['ID']['input']
  /** The priority of the upsell */
  ordinal?: InputMaybe<Scalars['Int']['input']>
  /** The restaurant sets the upsell will apply to */
  targets: Array<RestaurantSetInput>
}

export type SaveFailure = ShiftError & {
  __typename: 'SaveFailure'
  message?: Maybe<Scalars['String']['output']>
}

export type SavePackagingConfigResponse =
  | OutdatedPackagingConfigError
  | PackagingComplianceError
  | SavedPackagingConfig

export type SavedAlternatePaymentType = {
  __typename: 'SavedAlternatePaymentType'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  target: RestaurantSet
}

export type SavedChannel = {
  __typename: 'SavedChannel'
  channelAvailability?: Maybe<SavedChannelAvailability>
  channelAvailabilityId?: Maybe<Scalars['String']['output']>
  channelMenuEntities?: Maybe<Array<ChannelMenuEntity>>
  channelMenuPriceAdjustment?: Maybe<ChannelMenuPriceAdjustment>
  channelType?: Maybe<ChannelType>
  channelTypeGuid?: Maybe<Scalars['ID']['output']>
  deleted?: Maybe<Scalars['Boolean']['output']>
  id?: Maybe<Scalars['String']['output']>
  idempotencyKey?: Maybe<Scalars['String']['output']>
  lastModifiedUser?: Maybe<User>
  lastModifiedUserGuid?: Maybe<Scalars['ID']['output']>
  multiLocationId?: Maybe<Scalars['String']['output']>
  name?: Maybe<Scalars['String']['output']>
  owningRestaurantSetGuid?: Maybe<Scalars['ID']['output']>
  owningRestaurantSetId?: Maybe<Scalars['String']['output']>
  serviceAreaIds?: Maybe<Array<Scalars['String']['output']>>
  targetRestaurantSetGuid?: Maybe<Scalars['ID']['output']>
  targetRestaurantSetId?: Maybe<Scalars['String']['output']>
  versionId?: Maybe<Scalars['ID']['output']>
}

export type SavedChannelAvailability = {
  __typename: 'SavedChannelAvailability'
  deleted?: Maybe<Scalars['Boolean']['output']>
  enabled?: Maybe<Scalars['Boolean']['output']>
  id?: Maybe<Scalars['ID']['output']>
  lastModifiedUserGuid?: Maybe<Scalars['ID']['output']>
  multiLocationId?: Maybe<Scalars['String']['output']>
  owningRestaurantSetId?: Maybe<Scalars['String']['output']>
  snoozedUntil?: Maybe<Scalars['DateTime']['output']>
  targetRestaurantSetId?: Maybe<Scalars['String']['output']>
  versionId?: Maybe<Scalars['ID']['output']>
}

export type SavedChannelAvailabilityInput = {
  deleted?: InputMaybe<Scalars['Boolean']['input']>
  enabled?: InputMaybe<Scalars['Boolean']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  lastModifiedUserGuid?: InputMaybe<Scalars['ID']['input']>
  multiLocationId: Scalars['String']['input']
  owningRestaurantSetId?: InputMaybe<Scalars['String']['input']>
  snoozedUntil?: InputMaybe<Scalars['DateTime']['input']>
  targetRestaurantSetId?: InputMaybe<Scalars['String']['input']>
  versionId: Scalars['ID']['input']
}

export type SavedChannelAvailabilitySearchInput = {
  includeArchived?: InputMaybe<Scalars['Boolean']['input']>
  pageToken?: InputMaybe<Scalars['String']['input']>
}

export type SavedChannelInput = {
  channelAvailabilityId?: InputMaybe<Scalars['String']['input']>
  channelType?: InputMaybe<ChannelTypeInput>
  channelTypeGuid?: InputMaybe<Scalars['ID']['input']>
  id?: InputMaybe<Scalars['String']['input']>
  idempotencyKey?: InputMaybe<Scalars['String']['input']>
  lastModifiedUserGuid?: InputMaybe<Scalars['ID']['input']>
  multiLocationId?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  owningRestaurantSetGuid?: InputMaybe<Scalars['ID']['input']>
  owningRestaurantSetId?: InputMaybe<Scalars['String']['input']>
  serviceAreaIds?: InputMaybe<Array<Scalars['String']['input']>>
  targetRestaurantSetGuid?: InputMaybe<Scalars['ID']['input']>
  targetRestaurantSetId?: InputMaybe<Scalars['String']['input']>
  versionId?: InputMaybe<Scalars['ID']['input']>
}

export type SavedChannelSearchInput = {
  includeAllInManagementSet?: InputMaybe<Scalars['Boolean']['input']>
  includeArchived?: InputMaybe<Scalars['Boolean']['input']>
  includeChannelType?: InputMaybe<Scalars['Boolean']['input']>
  pageToken?: InputMaybe<Scalars['String']['input']>
}

export type SavedConfig = {
  __typename: 'SavedConfig'
  configType: ConfigType
  id: Scalars['ID']['output']
  name?: Maybe<Scalars['String']['output']>
}

export type SavedConfigEntityI = {
  id: Scalars['ID']['output']
  isArchived: Scalars['Boolean']['output']
  managementGroup: ManagementGroup
  name?: Maybe<Scalars['String']['output']>
  overrides: Array<SavedConfigOverrideI>
  revision: Scalars['Int']['output']
  target?: Maybe<RestaurantSet>
}

export type SavedConfigOverrideI = {
  fields: Array<ConfigField>
  id: Scalars['ID']['output']
  revision: Scalars['Int']['output']
  target: RestaurantSet
}

export type SavedDataExtensionSet = SavedConfigEntityI & {
  __typename: 'SavedDataExtensionSet'
  /** Composite id from type + management set id */
  id: Scalars['ID']['output']
  isArchived: Scalars['Boolean']['output']
  managementGroup: ManagementGroup
  name?: Maybe<Scalars['String']['output']>
  overrides: Array<DataExtensionSetOverride>
  payments: Array<PaymentDataExtension>
  revenueCenters: Array<RevenueCenterDataExtension>
  revision: Scalars['Int']['output']
  salesCategories: Array<SalesCategoryDataExtension>
  services: Array<ServiceDataExtension>
  target?: Maybe<RestaurantSet>
  taxRates: Array<TaxRateDataExtension>
  type: DataExtensionSetType
}

export type SavedDiningOption = {
  __typename: 'SavedDiningOption'
  archived: Scalars['Boolean']['output']
  behavior?: Maybe<OrderDiningOptionBehavior>
  copiedFromId?: Maybe<Scalars['String']['output']>
  curbside?: Maybe<Scalars['Boolean']['output']>
  editabilityLevel?: Maybe<OrderConfigEditabilityLevel>
  editabilityReasonCode?: Maybe<Scalars['String']['output']>
  glCodeId?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  importedId?: Maybe<Scalars['String']['output']>
  isDefault?: Maybe<Scalars['Boolean']['output']>
  isEnabledForApi?: Maybe<Scalars['Boolean']['output']>
  multiLocationId: Scalars['String']['output']
  name: Scalars['String']['output']
  ordinal?: Maybe<Scalars['Int']['output']>
  ownerRestaurantSet?: Maybe<RestaurantSet>
  requiresSchedule?: Maybe<Scalars['Boolean']['output']>
  targetRestaurantSet?: Maybe<RestaurantSet>
  toastDeliveryServiceProvider?: Maybe<OrderDeliveryServicesProvider>
  version?: Maybe<Scalars['Int']['output']>
}

export type SavedGiftCardReceiptConfig = {
  __typename: 'SavedGiftCardReceiptConfig'
  balanceInquiryConfig: GiftReceiptPrintStrategy
  giftActivationConfig: GiftReceiptPrintStrategy
  managementGroup: ManagementGroup
  name?: Maybe<Scalars['String']['output']>
  promoArea?: Maybe<Scalars['String']['output']>
  revision: Scalars['Int']['output']
  target?: Maybe<RestaurantSet>
}

export type SavedMenuAuditEntityType =
  | 'Menu'
  | 'MenuGroup'
  | 'MenuItem'
  | 'Modifier'
  | 'ModifierGroup'
  | 'PreModifierGroup'

export type SavedMenuAuditSetting = {
  __typename: 'SavedMenuAuditSetting'
  entityType: SavedMenuAuditEntityType
  multiLocationID: Scalars['String']['output']
  name: Scalars['String']['output']
  settingType: SavedMenuAuditSettingType
  versionId: Scalars['String']['output']
}

export type SavedMenuAuditSettingInput = {
  entityType: SavedMenuAuditEntityType
  settingType: SavedMenuAuditSettingType
}

export type SavedMenuAuditSettingType =
  | 'DEFAULT_PRE_MODIFIER_GROUP'
  | 'MENU_ITEM_PORTION'
  | 'MODIFIER_GROUP_SEQUENCE_PRICE'
  | 'MODIFIER_GROUP_SIZE_PRICE'
  | 'MODIFIER_GROUP_SIZE_SEQUENCE_PRICE'
  | 'MODIFIER_GROUP_SUBSTITUTION_PRICE'
  | 'MODIFIER_SIZE_PRICE'
  | 'NESTED_SUBGROUP'
  | 'TIME_SPECIFIC_PRICE'
  | 'USE_PRE_MODIFIER_GROUP'
  | 'VISIBLE_LIST'

export type SavedMenuCreate = {
  availability?: InputMaybe<SavedMenuEntityAvailabilityInput>
  collapseModifierPrices?: InputMaybe<SavedMenuEntityCollapseModifierPricesInput>
  description?: InputMaybe<Scalars['String']['input']>
  discountable?: InputMaybe<Scalars['Boolean']['input']>
  eligiblePaymentAssistancePrograms?: InputMaybe<
    Array<PaymentAssistanceProgram>
  >
  excludedFromRewards?: InputMaybe<Scalars['Boolean']['input']>
  image?: InputMaybe<SavedMenuEntityImageInput>
  inventory?: InputMaybe<SavedMenuEntityInventoryInput>
  name: Scalars['String']['input']
  /** Menu-specific fields */
  ordinal?: InputMaybe<Scalars['Int']['input']>
  owner: Scalars['ID']['input']
  pointOfSale?: InputMaybe<SavedMenuEntityPointOfSaleInput>
  prepSequenceMultiLocationId?: InputMaybe<Scalars['ID']['input']>
  prepStationsMultiLocationIds?: InputMaybe<Array<Scalars['ID']['input']>>
  salesCategoryMultiLocationId?: InputMaybe<Scalars['ID']['input']>
  target: Scalars['ID']['input']
  taxes?: InputMaybe<SavedMenuEntityTaxesInput>
  visibility?: InputMaybe<SavedMenuEntityVisibilityInput>
}

export type SavedMenuEntityAvailabilityInput = {
  schedules?: InputMaybe<Array<MenuAvailabilitySchedule>>
}

export type SavedMenuEntityCollapseModifierPricesInput = {
  collapseModifierPrices?: InputMaybe<Scalars['Boolean']['input']>
  inheritCollapseModifierPrices?: InputMaybe<Scalars['Boolean']['input']>
}

export type SavedMenuEntityContentAdvisoriesInput = {
  alcohol?: InputMaybe<MenuEntityAlcoholInput>
}

export type SavedMenuEntityImageInput = {
  /** url path where this image is stored */
  imagePath?: InputMaybe<Scalars['String']['input']>
}

export type SavedMenuEntityInventoryInput = {
  tareWeight?: InputMaybe<Scalars['Float']['input']>
  unitOfMeasure?: InputMaybe<UnitOfMeasure>
}

export type SavedMenuEntityPointOfSaleInput = {
  barcodeEmbeddedAmountType?: InputMaybe<Scalars['String']['input']>
  color?: InputMaybe<Scalars['Int']['input']>
  kdsColor?: InputMaybe<Scalars['Int']['input']>
  kitchenName?: InputMaybe<Scalars['String']['input']>
  shortName?: InputMaybe<Scalars['String']['input']>
  sku?: InputMaybe<Scalars['String']['input']>
}

export type SavedMenuEntityReportsInput = {
  inheritSalesCategory?: InputMaybe<Scalars['Boolean']['input']>
  plu?: InputMaybe<Scalars['String']['input']>
  salesCategory?: InputMaybe<OptionalId>
}

export type SavedMenuEntityTaxesInput = {
  diningOptionTaxation?: InputMaybe<MenuEntityDiningOptionTax>
  inheritDiningOptionTax?: InputMaybe<Scalars['Boolean']['input']>
  inheritTaxInclusive?: InputMaybe<Scalars['Boolean']['input']>
  inheritTaxRates?: InputMaybe<Scalars['Boolean']['input']>
  taxInclusionOption?: InputMaybe<MenuEntityTaxInclusionOption>
  taxRateMultiLocationIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type SavedMenuEntityVisibilityInput = {
  kioskVisible?: InputMaybe<Scalars['Boolean']['input']>
  onlineOrderingVisible?: InputMaybe<Scalars['Boolean']['input']>
  orderingPartnerVisible?: InputMaybe<Scalars['Boolean']['input']>
  posVisible?: InputMaybe<Scalars['Boolean']['input']>
}

export type SavedMenuGroupCreate = {
  description?: InputMaybe<Scalars['String']['input']>
  menuItemMultiLocationIds?: InputMaybe<Array<Scalars['ID']['input']>>
  modifiers?: InputMaybe<SavedMenuItemModifiers>
  name: Scalars['String']['input']
  owner: Scalars['ID']['input']
  pointOfSale?: InputMaybe<SavedMenuEntityPointOfSaleInput>
  preparation?: InputMaybe<SavedMenuGroupPreparationInput>
  reports?: InputMaybe<SavedMenuEntityReportsInput>
  target: Scalars['ID']['input']
  taxes?: InputMaybe<SavedMenuEntityTaxesInput>
  /** MenuGroup-specific fields */
  visibility?: InputMaybe<SavedMenuEntityVisibilityInput>
}

export type SavedMenuGroupPreparation = {
  __typename: 'SavedMenuGroupPreparation'
  inheritPrepSequence: Scalars['Boolean']['output']
  inheritPrepStations: Scalars['Boolean']['output']
  prepSequenceMultiLocationId?: Maybe<Scalars['ID']['output']>
  prepStationMultiLocationIds: Array<Scalars['ID']['output']>
}

export type SavedMenuGroupPreparationInput = {
  inheritPrepSequence?: InputMaybe<Scalars['Boolean']['input']>
  inheritPrepStations?: InputMaybe<Scalars['Boolean']['input']>
  prepSequenceMultiLocationId?: InputMaybe<OptionalId>
  prepStationMultiLocationIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type SavedMenuItemCreate = {
  calories?: InputMaybe<OptionalInt>
  contentAdvisories?: InputMaybe<SavedMenuEntityContentAdvisoriesInput>
  description?: InputMaybe<Scalars['String']['input']>
  guestCount?: InputMaybe<OptionalFloat>
  image?: InputMaybe<SavedMenuEntityImageInput>
  modifiers?: InputMaybe<SavedMenuItemModifiers>
  name: Scalars['String']['input']
  owner?: InputMaybe<Scalars['ID']['input']>
  pointOfSale?: InputMaybe<SavedMenuEntityPointOfSaleInput>
  preparation?: InputMaybe<SavedMenuItemPreparationInput>
  pricing?: InputMaybe<SavedMenuItemPricingInput>
  reports?: InputMaybe<SavedMenuEntityReportsInput>
  target?: InputMaybe<Scalars['ID']['input']>
  /** MenuItem-specific fields */
  taxes?: InputMaybe<SavedMenuEntityTaxesInput>
  visibility?: InputMaybe<SavedMenuEntityVisibilityInput>
}

export type SavedMenuItemModifiers = {
  inheritModifierGroups?: InputMaybe<Scalars['Boolean']['input']>
  modifierGroupMultiLocationIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type SavedMenuItemPreparation = {
  __typename: 'SavedMenuItemPreparation'
  inheritPrepSequence: Scalars['Boolean']['output']
  inheritPrepStations: Scalars['Boolean']['output']
  orderInTicket?: Maybe<Scalars['Int']['output']>
  prepSequenceMultiLocationId?: Maybe<Scalars['ID']['output']>
  prepStationMultiLocationIds: Array<Scalars['ID']['output']>
  prepTimeSeconds?: Maybe<Scalars['Int']['output']>
}

export type SavedMenuItemPreparationInput = {
  inheritPrepSequence?: InputMaybe<Scalars['Boolean']['input']>
  inheritPrepStations?: InputMaybe<Scalars['Boolean']['input']>
  orderInTicket?: InputMaybe<Scalars['Int']['input']>
  prepSequenceMultiLocationId?: InputMaybe<OptionalId>
  prepStationMultiLocationIds?: InputMaybe<Array<Scalars['ID']['input']>>
  prepTimeSeconds?: InputMaybe<Scalars['Int']['input']>
}

export type SavedMenuItemPricingInput = {
  basePrice?: InputMaybe<MenusMoneyInput>
  inheritPricing?: InputMaybe<Scalars['Boolean']['input']>
  menuSpecificPrices?: InputMaybe<Array<SavedMenuSpecificPriceInput>>
  priceLevel?: InputMaybe<OptionalId>
  pricingStrategy?: InputMaybe<MenuItemPricingStrategy>
  sizePrices?: InputMaybe<Array<SavedSizePriceInput>>
}

/**  Saved menu item tags are distinguished from other tags by the fact that they are resolved differently and are not yet published so should not show up in a published menu item.  */
export type SavedMenuItemTag = {
  __typename: 'SavedMenuItemTag'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type SavedMenuItemTagsInput = {
  pageToken?: InputMaybe<Scalars['ID']['input']>
  /** Optional restaurant set to resolve versions to */
  target?: InputMaybe<Scalars['String']['input']>
  versionResolution?: InputMaybe<Scalars['String']['input']>
}

export type SavedMenuItemUpdate = {
  calories?: InputMaybe<OptionalInt>
  contentAdvisories?: InputMaybe<SavedMenuEntityContentAdvisoriesInput>
  description?: InputMaybe<Scalars['String']['input']>
  guestCount?: InputMaybe<OptionalFloat>
  image?: InputMaybe<SavedMenuEntityImageInput>
  modifiers?: InputMaybe<SavedMenuItemModifiers>
  multiLocationId?: InputMaybe<Scalars['ID']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  owner?: InputMaybe<Scalars['ID']['input']>
  pointOfSale?: InputMaybe<SavedMenuEntityPointOfSaleInput>
  preparation?: InputMaybe<SavedMenuItemPreparationInput>
  pricing?: InputMaybe<SavedMenuItemPricingInput>
  reports?: InputMaybe<SavedMenuEntityReportsInput>
  target?: InputMaybe<Scalars['ID']['input']>
  /** MenuItem-specific fields */
  taxes?: InputMaybe<SavedMenuEntityTaxesInput>
  versionId: Scalars['ID']['input']
  visibility?: InputMaybe<SavedMenuEntityVisibilityInput>
}

export type SavedMenuPreparation = {
  __typename: 'SavedMenuPreparation'
  prepSequenceMultiLocationId?: Maybe<Scalars['ID']['output']>
  prepStationMultiLocationIds: Array<Scalars['ID']['output']>
}

export type SavedMenuSpecificPriceInput = {
  basePrice?: InputMaybe<MenusMoneyInput>
  menuMultiLocationId: Scalars['ID']['input']
}

export type SavedMenuUpdate = {
  availability?: InputMaybe<SavedMenuEntityAvailabilityInput>
  collapseModifierPrices?: InputMaybe<SavedMenuEntityCollapseModifierPricesInput>
  description?: InputMaybe<Scalars['String']['input']>
  discountable?: InputMaybe<Scalars['Boolean']['input']>
  eligiblePaymentAssistancePrograms?: InputMaybe<
    Array<PaymentAssistanceProgram>
  >
  excludedFromRewards?: InputMaybe<Scalars['Boolean']['input']>
  image?: InputMaybe<SavedMenuEntityImageInput>
  inventory?: InputMaybe<SavedMenuEntityInventoryInput>
  name?: InputMaybe<Scalars['String']['input']>
  /** Menu-specific fields */
  ordinal?: InputMaybe<Scalars['Int']['input']>
  owner?: InputMaybe<Scalars['ID']['input']>
  pointOfSale?: InputMaybe<SavedMenuEntityPointOfSaleInput>
  prepSequenceMultiLocationId?: InputMaybe<OptionalId>
  prepStationsMultiLocationIds?: InputMaybe<Array<Scalars['ID']['input']>>
  salesCategoryMultiLocationId?: InputMaybe<OptionalId>
  target?: InputMaybe<Scalars['ID']['input']>
  taxes?: InputMaybe<SavedMenuEntityTaxesInput>
  versionId: Scalars['ID']['input']
  visibility?: InputMaybe<SavedMenuEntityVisibilityInput>
}

export type SavedOrderConfirmationScreenConfig = {
  __typename: 'SavedOrderConfirmationScreenConfig'
  baseConfig: QlOrderConfirmationScreenConfig
  overrides?: Maybe<Array<OrderConfirmationScreenConfigOverride>>
}

export type SavedPackagingConfig = {
  __typename: 'SavedPackagingConfig'
  disabledItemTypes: Array<PackagingItemType>
  enabled: Scalars['Boolean']['output']
  guestMessage?: Maybe<Scalars['String']['output']>
  /**  ID to facilitate front-end caching, not used for anything else  */
  id: Scalars['ID']['output']
  items: Array<PackagingItem>
  overrides: Array<PackagingConfigOverride>
  posShowDefaults: Scalars['Boolean']['output']
  revision?: Maybe<Scalars['Int']['output']>
}

export type SavedPrepSequence = {
  __typename: 'SavedPrepSequence'
  id: Scalars['ID']['output']
  multiLocationId: Scalars['ID']['output']
  name: Scalars['String']['output']
  ordinal?: Maybe<Scalars['Int']['output']>
  ownerRestaurantSet: RestaurantSet
  targetRestaurantSet: RestaurantSet
}

export type SavedPrepSequencesSearch = {
  includeArchived?: InputMaybe<Scalars['Boolean']['input']>
  targetRestaurantSet?: InputMaybe<Scalars['ID']['input']>
  versionResolution: KitchenVersionResolution
}

export type SavedPrepStation = {
  __typename: 'SavedPrepStation'
  deleted: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  multiLocationId: Scalars['ID']['output']
  name: Scalars['String']['output']
  ordinal?: Maybe<Scalars['Int']['output']>
  ownerRestaurantSet: RestaurantSet
  targetRestaurantSet: RestaurantSet
}

export type SavedPrepStationsSearch = {
  includeArchived?: InputMaybe<Scalars['Boolean']['input']>
  targetRestaurantSet?: InputMaybe<Scalars['ID']['input']>
  versionResolution: KitchenVersionResolution
}

export type SavedQueries = {
  __typename: 'SavedQueries'
  operatingScheduleServices: Array<OperatingScheduleService>
  restaurantLocation?: Maybe<SavedRestaurantLocation>
}

export type SavedQueriesOperatingScheduleServicesArgs = {
  restaurantSetIds: Array<Scalars['ID']['input']>
}

export type SavedQueriesRestaurantLocationArgs = {
  id: Scalars['ID']['input']
}

/** Reference to a saved restaurant location */
export type SavedRestaurantLocation = RestaurantLocationInterface & {
  __typename: 'SavedRestaurantLocation'
  address: RestaurantLocationAddress
  closeoutHour: Scalars['LocalTime']['output']
  connectedPartners?: Maybe<Array<Partner>>
  currencyCode: Scalars['String']['output']
  description?: Maybe<Scalars['String']['output']>
  /**  Enabled features for the given restaurant location.  */
  enabledFeatures?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  fixedFeePQOs: Array<FixedFeePqo>
  /** Unique identifier of the saved restaurant location */
  id: Scalars['ID']['output']
  images: RestaurantLocationImages
  isTestMode: Scalars['Boolean']['output']
  language?: Maybe<Scalars['String']['output']>
  /** Restaurant Set Leaf legacy id (Long) */
  legacyRestaurantSetId: Scalars['String']['output']
  locationCode?: Maybe<Scalars['String']['output']>
  locationName?: Maybe<Scalars['String']['output']>
  marketSegment?: Maybe<MarketSegment>
  name: Scalars['String']['output']
  operatingSchedule: OperatingSchedule
  /** Direct parent groups */
  parentGroups: Array<RestaurantGroup>
  /** Logged user permission bits on this location */
  permissionBits: Scalars['String']['output']
  phone?: Maybe<Scalars['String']['output']>
  restaurantName: Scalars['String']['output']
  /** Restaurant Set Leaf identifier */
  restaurantSetId: Scalars['ID']['output']
  serviceModel: ServiceModel
  status: RestaurantLocationStatus
  timeZone: Scalars['String']['output']
}

/** Reference to a saved restaurant location */
export type SavedRestaurantLocationEnabledFeaturesArgs = {
  features?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type SavedRestaurantLocationConnection = {
  __typename: 'SavedRestaurantLocationConnection'
  edges: Array<SavedRestaurantLocationEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']['output']
}

export type SavedRestaurantLocationEdge = {
  __typename: 'SavedRestaurantLocationEdge'
  cursor: Scalars['String']['output']
  node: SavedRestaurantLocation
}

export type SavedRestaurantTable = {
  __typename: 'SavedRestaurantTable'
  id: Scalars['ID']['output']
  name?: Maybe<Scalars['String']['output']>
}

export type SavedRevenueCenter = {
  __typename: 'SavedRevenueCenter'
  /** Whether or not the Revenue Center is archived */
  archived: Scalars['Boolean']['output']
  /** Description of the Revenue Center */
  description?: Maybe<Scalars['String']['output']>
  /** The id of the revenue center, equivalent to the versionId of the config */
  id: Scalars['ID']['output']
  /** MultiLocationId of the Revenue Center */
  multiLocationId: Scalars['ID']['output']
  /** Name of the Revenue Center */
  name: Scalars['String']['output']
  /** Owner restaurant set for the Revenue Center */
  ownerRestaurantSet: RestaurantSet
  /** Targeted restaurant set for the Revenue Center */
  targetRestaurantSet: RestaurantSet
}

export type SavedRevenueCenterSearch = {
  includeArchived?: InputMaybe<Scalars['Boolean']['input']>
  multiLocationIds?: InputMaybe<Array<Scalars['ID']['input']>>
  page: Scalars['Int']['input']
  pageSize: Scalars['Int']['input']
  restaurantSetGuids?: InputMaybe<Array<Scalars['ID']['input']>>
  targetRestaurantSet?: InputMaybe<Scalars['ID']['input']>
  versionResolution?: InputMaybe<VersionResolution>
}

export type SavedSalesCategoriesSearch = {
  includeArchived?: InputMaybe<Scalars['Boolean']['input']>
  multiLocationIds?: InputMaybe<Array<Scalars['ID']['input']>>
  restaurantSetGuids?: InputMaybe<Array<Scalars['ID']['input']>>
  targetRestaurantSet?: InputMaybe<Scalars['ID']['input']>
  versionResolution?: InputMaybe<VersionResolution>
}

export type SavedSalesCategory = {
  __typename: 'SavedSalesCategory'
  /** whether or not the sales category is archived */
  archived: Scalars['Boolean']['output']
  /** Timestamp for when the config was created */
  createdDate?: Maybe<Scalars['DateTime']['output']>
  /** the description of the sales category */
  description?: Maybe<Scalars['String']['output']>
  /** the id of the sales category. By convention of toast-graphql - this is equivalent to the versionId of the config */
  id: Scalars['ID']['output']
  /** Timestamp for when the config was last modified */
  modifiedDate?: Maybe<Scalars['DateTime']['output']>
  /** multiLocationId of the sales category */
  multiLocationId: Scalars['ID']['output']
  /** The name of the sales category */
  name: Scalars['String']['output']
  /** the ordinal of the sales category */
  ordinal?: Maybe<Scalars['Int']['output']>
  /** Owner restaurant set for the sales category. Determines who can edit the config. */
  ownerRestaurantSet: RestaurantSet
  /** revision number for the sales category */
  revision?: Maybe<Scalars['Int']['output']>
  /** Targeted restaurant set for the sales category. Determines where this config applies */
  targetRestaurantSet: RestaurantSet
  /** the versionId of the sales category */
  versionId: Scalars['ID']['output']
}

export type SavedSizePriceInput = {
  basePrice?: InputMaybe<MenusMoneyInput>
  name?: InputMaybe<Scalars['String']['input']>
  versionId?: InputMaybe<Scalars['ID']['input']>
}

export type SavedStockDataConfig = {
  __typename: 'SavedStockDataConfig'
  baseConfig: QlStockDataConfig
  overrides?: Maybe<Array<StockDataConfigOverride>>
}

export type SavedStockOutConfig = {
  __typename: 'SavedStockOutConfig'
  baseConfig: QlStockOutConfig
  overrides?: Maybe<Array<StockOutConfigOverride>>
}

export type SavedTaxRate = {
  __typename: 'SavedTaxRate'
  /** whether or not the tax rate is archived */
  archived: Scalars['Boolean']['output']
  /** whether the tax rate is default */
  default: Scalars['Boolean']['output']
  /** the id of the tax rate. By convention of toast-graphql - this is equivalent to the versionId */
  id: Scalars['ID']['output']
  /** multiLocationId of the tax rate */
  multiLocationId: Scalars['ID']['output']
  /** the name of the tax rate */
  name?: Maybe<Scalars['String']['output']>
  /** Owning restaurant set for the tax rate. Determines who can edit the tax rate */
  ownerRestaurantSet: RestaurantSet
  /** the fixed rate for the tax - use this if rateType is FIXED */
  rateFixed?: Maybe<Scalars['String']['output']>
  /** the percent rate for the tax config - use this if rateType is PERCENT */
  ratePercent?: Maybe<Scalars['String']['output']>
  /** enum defining the type of tax rate. Rate is only available for FIXED (rateFixed) or PERCENT (ratePercent) */
  rateType: TaxRateType
  /** Targeted restaurant set for the tax rate. Determines where this tax rate applies */
  targetRestaurantSet: RestaurantSet
  /** versionId of the tax rate */
  versionId: Scalars['ID']['output']
}

export type SavedTaxRatesSearch = {
  includeArchived?: InputMaybe<Scalars['Boolean']['input']>
  multiLocationIds?: InputMaybe<Array<Scalars['ID']['input']>>
  restaurantSetGuids?: InputMaybe<Array<Scalars['ID']['input']>>
  targetRestaurantSet?: InputMaybe<Scalars['ID']['input']>
  versionResolution?: InputMaybe<VersionResolution>
}

export type SavedToastPayConfigError = {
  __typename: 'SavedToastPayConfigError'
  code: SavedToastPayConfigErrorCode
  message?: Maybe<Scalars['String']['output']>
}

export type SavedToastPayConfigErrorCode =
  | 'ConfigNotFound'
  | 'FailedToRetrieveConfig'
  | 'ManagementSetGuidNotFound'
  | 'ManagementSetRetrievalFailed'
  | 'RestaurantSetNotFound'

export type SavedToastPayConfigResponse =
  | SavedToastPayConfigError
  | ToastPayConfig

export type Schedule = {
  __typename: 'Schedule'
  dayPeriods: Array<DayPeriod>
  diningOptionBehavior: DiningOptionBehavior
}

export type ScheduleInput = {
  dayPeriods: Array<DayPeriodInput>
  diningOptionBehavior: DiningOptionBehavior
}

export type SearchDataSource =
  | 'Employee'
  | 'MenuItem'
  | 'StaticSearchItem'
  | 'ToastCentralArticle'
  | 'ToastNavigationConfig'

export type SearchEntity =
  | MenuItem
  | ToastCentralArticle
  | ToastNavigationConfig
  | User

export type SearchItem = {
  __typename: 'SearchItem'
  description: Scalars['String']['output']
  displayText?: Maybe<Scalars['String']['output']>
  entity?: Maybe<SearchEntity>
  id: Scalars['ID']['output']
  keywords: Array<Scalars['String']['output']>
  path: Scalars['String']['output']
  score: Scalars['Float']['output']
  searchDataSource: SearchDataSource
  /** @deprecated Use keywords field instead. */
  subTopics: Array<Scalars['String']['output']>
  title: Scalars['String']['output']
  topic: Scalars['String']['output']
}

export type SearchMutation = {
  __typename: 'SearchMutation'
  createUserInteractions: UserIteractionMutationResponse
}

export type SearchMutationCreateUserInteractionsArgs = {
  interactionType: InteractionType
  itemId: Scalars['String']['input']
  searchDataSource: SearchDataSource
  searchTerm: Scalars['String']['input']
  title?: InputMaybe<Scalars['String']['input']>
}

export type SearchQuery = {
  __typename: 'SearchQuery'
  autoComplete: SearchResponse
  popular: PopularSearchResponse
  search: SearchResponse
  searchSuggestion: SuggestionResponse
  userInteractions: UserInteractionResponse
  userRecentSearches: RecentSearchesResponse
}

export type SearchQueryAutoCompleteArgs = {
  q: Scalars['String']['input']
  size: Scalars['Int']['input']
  sources: Array<SearchDataSource>
}

export type SearchQueryPopularArgs = {
  size: Scalars['Int']['input']
}

export type SearchQuerySearchArgs = {
  ignoreForMetrics?: InputMaybe<Scalars['Boolean']['input']>
  page?: InputMaybe<Scalars['Int']['input']>
  pageSize: Scalars['Int']['input']
  q: Scalars['String']['input']
  sources: Array<SearchDataSource>
  startIndex?: InputMaybe<Scalars['Int']['input']>
}

export type SearchQuerySearchSuggestionArgs = {
  q: Scalars['String']['input']
  size: Scalars['Int']['input']
}

export type SearchQueryUserInteractionsArgs = {
  interactionType: Array<InteractionType>
  size: Scalars['Int']['input']
  sources: Array<SearchDataSource>
}

export type SearchQueryUserRecentSearchesArgs = {
  size: Scalars['Int']['input']
}

export type SearchResponse = {
  __typename: 'SearchResponse'
  items: Array<SearchItem>
  totalHits: Scalars['Int']['output']
}

export type SearchSuggestion = {
  __typename: 'SearchSuggestion'
  score: Scalars['Float']['output']
  value: Scalars['String']['output']
}

export type SeasonalEmploymentStatus = EmploymentStatus & {
  __typename: 'SeasonalEmploymentStatus'
  adjustedHireDate?: Maybe<Scalars['Date']['output']>
  /** @deprecated use employee.hireDate at the top level instead */
  hireDate?: Maybe<Scalars['String']['output']>
}

export type Section = {
  __typename: 'Section'
  groups: Array<Group>
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  sectionSpa?: Maybe<Scalars['String']['output']>
}

export type SendGuestFeedbackResponseInput = {
  id: Scalars['ID']['input']
  response: Scalars['String']['input']
  userID: Scalars['ID']['input']
}

export type ServerColor = {
  __typename: 'ServerColor'
  serverColor: Scalars['String']['output']
  textColor: Scalars['String']['output']
}

export type ServiceArea = {
  __typename: 'ServiceArea'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  tables: Array<MgmtTable>
}

export type ServiceAreaAvailabilityInfo = {
  __typename: 'ServiceAreaAvailabilityInfo'
  deposit?: Maybe<DepositAvailabilityInfo>
  externalServiceArea?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  onlineReservationsDisabled: Scalars['Boolean']['output']
  percentBookableOnline: Scalars['Int']['output']
  percentBooked: Scalars['Float']['output']
  serviceAreaGroupInfo?: Maybe<ServiceAreaGroupInfo>
  slotEnd: Scalars['DateTime']['output']
  tables: Array<TableAvailabilityInfo>
  turnTime: Scalars['Int']['output']
  violatesPercentBookableOnline: Scalars['Boolean']['output']
}

export type ServiceAreaGroupInfo = {
  __typename: 'ServiceAreaGroupInfo'
  enabled: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type ServiceAreaNotFound = Error & {
  __typename: 'ServiceAreaNotFound'
  message: Scalars['String']['output']
  status: ErrorCode
}

export type ServiceAreaResponse = {
  __typename: 'ServiceAreaResponse'
  results: Array<ServiceArea>
}

export type ServiceDataExtension = DataExtension & {
  __typename: 'ServiceDataExtension'
  service: OperatingScheduleService
  type: DataExtensionType
  value: Scalars['String']['output']
}

export type ServiceModel =
  /** The customer is not eligible for any support */
  | 'NONE'
  /** Onboarding support model for customers in the onboarding pilot */
  | 'ONBOARDING'
  /** Standard support model for live customers */
  | 'STANDARD'

export type ShiftAlreadyClockedOut = ShiftError & {
  __typename: 'ShiftAlreadyClockedOut'
  message?: Maybe<Scalars['String']['output']>
}

export type ShiftCheckedResultRepositoryDependencyFailure = ShiftError & {
  __typename: 'ShiftCheckedResultRepositoryDependencyFailure'
  message?: Maybe<Scalars['String']['output']>
}

export type ShiftError = {
  message?: Maybe<Scalars['String']['output']>
}

export type ShiftFilterInput = {
  dateRange: DateRangeInput
  includeDeleted?: Scalars['Boolean']['input']
}

export type ShiftFilterInputV2 = {
  autoClockout?: InputMaybe<Scalars['Boolean']['input']>
  deleted?: InputMaybe<Scalars['Boolean']['input']>
  endDate?: InputMaybe<Scalars['Date']['input']>
  restaurantIDs: Array<Scalars['ID']['input']>
  restaurantUserIds?: InputMaybe<Array<Scalars['ID']['input']>>
  shiftIds?: InputMaybe<Array<Scalars['ID']['input']>>
  shiftStatuses?: InputMaybe<Array<EmployeeShiftStatus>>
  startDate?: InputMaybe<Scalars['Date']['input']>
  userIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type ShiftNotFound = ShiftError & {
  __typename: 'ShiftNotFound'
  message?: Maybe<Scalars['String']['output']>
}

export type ShiftOrderByDirection = 'ASC' | 'DESC'

export type ShiftOrderByField =
  | 'BUSINESS_DATE'
  | 'ESTIMATED_OVERTIME_DURATION'
  | 'ESTIMATED_PAID_BREAK_TIME'
  | 'ESTIMATED_REGULAR_DURATION'
  | 'ESTIMATED_TOTAL_TIME'
  | 'ESTIMATED_UNPAID_BREAK_TIME'
  | 'IN_TIME'
  | 'IS_DELETED'
  | 'JOB_ID'
  | 'JOB_TITLE'
  | 'OUT_TIME'
  | 'RESTAURANT_ID'
  | 'RESTAURANT_LOCATION_NAME'
  | 'RESTAURANT_NAME'
  | 'RESTAURANT_USER_CHOSEN_NAME'
  | 'RESTAURANT_USER_DISPLAY_FIRST_NAME'
  | 'RESTAURANT_USER_DISPLAY_FULL_NAME'
  | 'RESTAURANT_USER_FIRST_NAME'
  | 'RESTAURANT_USER_ID'
  | 'RESTAURANT_USER_LAST_NAME'
  | 'SCHEDULE_ID'
  | 'SCHEDULE_IN_DATE'
  | 'SCHEDULE_OUT_DATE'
  | 'SHIFT_ID'
  | 'STATUS'
  | 'TIPS_CASH_GRATUITY'
  | 'TIPS_NON_CASH_GRATUITY'
  | 'TIPS_NON_CASH_TIPS'
  | 'TIPS_SHIFT_TIP'
  | 'TIPS_TOTAL_GRATUITY'
  | 'TIPS_TOTAL_TIPS'
  | 'TIPS_WITHHELD'
  | 'USER_ID'

export type ShiftOrderByInput = {
  direction: ShiftOrderByDirection
  field: ShiftOrderByField
}

export type ShiftReviewCollectAndTipOutMode =
  | 'OPTIONAL'
  | 'REQUIRED'
  | 'UNSPECIFIED'

export type ShiftReviewConfig = {
  __typename: 'ShiftReviewConfig'
  declareCashTipsConfig: DeclareCashTipsConfig
  id: Scalars['ID']['output']
  reconciliationConfig: ReconciliationConfig
  shouldRequireCashDrawerLockdown: Scalars['Boolean']['output']
  shouldRequireShiftReview: Scalars['Boolean']['output']
  tipOutConfig: TipOutConfig
}

export type ShiftReviewConfigInput = {
  declareCashTipsConfig?: InputMaybe<DeclareCashTipsConfigInput>
  reconciliationConfig?: InputMaybe<ReconciliationConfigInput>
  shouldRequireCashDrawerLockdown?: InputMaybe<Scalars['Boolean']['input']>
  shouldRequireShiftReview?: InputMaybe<Scalars['Boolean']['input']>
  tipOutConfig?: InputMaybe<TipOutConfigInput>
}

export type ShiftTipInput = {
  cashAmount: MoneyInput
}

/** An employee that has worked a shift */
export type ShiftV2Employee = {
  __typename: 'ShiftV2Employee'
  /** The name of the employee that was used in searches based on full name */
  displayFullName?: Maybe<Scalars['String']['output']>
  /** The list of RestaurantUsers associated with the employee */
  restaurantUsers: Array<RestaurantUser>
  shiftCount: Scalars['Int']['output']
  /** The User of the employee */
  user: User
}

export type ShiftV2EmployeesResponse = {
  __typename: 'ShiftV2EmployeesResponse'
  employees: Array<ShiftV2Employee>
}

export type ShiftsV2PageInfo = {
  __typename: 'ShiftsV2PageInfo'
  /** The cursor that can be used to continue querying with 'after' */
  endCursor?: Maybe<Scalars['String']['output']>
  /**  How many values were asked to be fetched. Can differ from the input if the user asks for more than the max the API allows you to fetch  */
  first: Scalars['Int']['output']
  /** Indicates whether or not the next page exists */
  hasNextPage: Scalars['Boolean']['output']
  /** Indicated whether or not a previous page exists */
  hasPreviousPage: Scalars['Boolean']['output']
  /**  The offset used to fetch with. Will be null if using cursor based pagination  */
  offset?: Maybe<Scalars['Int']['output']>
  /** The cursor that can be used to continue querying with 'before' */
  startCursor?: Maybe<Scalars['String']['output']>
}

export type ShiftsV2PaginationInput = {
  /** Cursor based inputs */
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first: Scalars['Int']['input']
  /** Offset based inputs */
  offset?: InputMaybe<Scalars['Int']['input']>
}

export type SizePrice = {
  __typename: 'SizePrice'
  /** @deprecated use pricing.basePrice */
  basePrice?: Maybe<Money>
  multiLocationId?: Maybe<Scalars['ID']['output']>
  name?: Maybe<Scalars['String']['output']>
  pricing?: Maybe<MenuItemPricing>
  /** @deprecated use pricing.pricingStrategy */
  pricingStrategy: MenuItemPricingStrategy
  versionId?: Maybe<Scalars['ID']['output']>
}

export type SlotAvailabilityInfo = {
  __typename: 'SlotAvailabilityInfo'
  blockedReason: Scalars['String']['output']
  bookingMaxHoursInAdvance: Scalars['Int']['output']
  bookingMinHoursInAdvance: Scalars['Int']['output']
  currentCovers: Scalars['Int']['output']
  datetime: Scalars['DateTime']['output']
  hostReservationsBlocked: Scalars['Boolean']['output']
  maxCoversPerTimeslot: Scalars['Int']['output']
  notInSlotSize: Scalars['Boolean']['output']
  onlineReservationsBlocked: Scalars['Boolean']['output']
  onlineReservationsDisabled: Scalars['Boolean']['output']
  serviceAreas: Array<ServiceAreaAvailabilityInfo>
  servicePeriodName?: Maybe<Scalars['String']['output']>
  servicePeriodOverrideName?: Maybe<Scalars['String']['output']>
  slotSize: Scalars['Int']['output']
  tooFar: Scalars['Boolean']['output']
  tooNear: Scalars['Boolean']['output']
  violatesCoverFlow: Scalars['Boolean']['output']
}

export type SmartQuoteAutoChangeStrategy = 'NONE' | 'SMART_QUOTE'

export type SmartQuoteAutoChangeStrategyResponse = {
  __typename: 'SmartQuoteAutoChangeStrategyResponse'
  strategy: SmartQuoteAutoChangeStrategy
}

export type SmartQuoteConfig = {
  __typename: 'SmartQuoteConfig'
  autoChangeStrategy: SmartQuoteAutoChangeStrategy
  reasonCode?: Maybe<SmartQuoteConfigReasonCode>
  statusCode: SmartQuoteConfigStatus
}

export type SmartQuoteConfigReasonCode =
  | 'BAD_FULFILLMENT_DATA'
  | 'DATA_IMPROVED'
  | 'EMPTY'
  | 'NEED_MORE_DATA'
  | 'UNDERPERFORMED'
  | 'UNKNOWN'

export type SmartQuoteConfigStatus =
  | 'ACTIVE'
  | 'FAILED'
  | 'FAILED_REQUIREMENTS'
  | 'IN_PROGRESS'
  | 'NOT_TRAINED'
  | 'STALE'

export type SmartQuoteMutation = {
  __typename: 'SmartQuoteMutation'
  /** Queue activation request for given restaurant */
  activate: SmartQuoteConfig
}

export type SmartQuoteQuery = {
  __typename: 'SmartQuoteQuery'
  /** Fetch smart-quote-configuration */
  config: SmartQuoteConfig
}

export type SmartQuoteSimpleComparison = {
  __typename: 'SmartQuoteSimpleComparison'
  dateFrom?: Maybe<Scalars['Int']['output']>
  dateTo?: Maybe<Scalars['Int']['output']>
  ordersOnTimeImprovement?: Maybe<Scalars['Int']['output']>
  percentageEarlyOrdersCurrentStrategy?: Maybe<Scalars['Int']['output']>
  percentageEarlyOrdersSmartQuote?: Maybe<Scalars['Int']['output']>
  percentageLateOrdersCurrentStrategy?: Maybe<Scalars['Int']['output']>
  percentageLateOrdersSmartQuote?: Maybe<Scalars['Int']['output']>
  percentageOrdersOnTimeCurrentStrategy?: Maybe<Scalars['Int']['output']>
  percentageOrdersOnTimeSmartQuote?: Maybe<Scalars['Int']['output']>
  serviceSpeedCurrentStrategy?: Maybe<Scalars['Int']['output']>
  serviceSpeedSmartQuote?: Maybe<Scalars['Int']['output']>
}

export type SmartQuoteSimpleComparisonQuery = {
  __typename: 'SmartQuoteSimpleComparisonQuery'
  /** Fetch smart-quote comparison with current */
  comparison?: Maybe<SmartQuoteSimpleComparison>
}

export type SnoozeUnit = 'DAYS' | 'MINUTES'

export type SnoozeValue = {
  time?: InputMaybe<Scalars['Int']['input']>
  unit?: InputMaybe<SnoozeUnit>
}

export type SousChefAction = {
  __typename: 'SousChefAction'
  description: Scalars['String']['output']
  id: Scalars['ID']['output']
  title: Scalars['String']['output']
  type: SousChefActionType
}

export type SousChefActionPlan = {
  __typename: 'SousChefActionPlan'
  actions: Array<SousChefAction>
  description: Scalars['String']['output']
}

export type SousChefActionType = 'SUGGESTION'

export type SousChefAdminMutation = {
  __typename: 'SousChefAdminMutation'
  createWorkflow: SousChefWorkflowDefinition
  saveWorkflow: Scalars['Boolean']['output']
}

export type SousChefAdminMutationCreateWorkflowArgs = {
  question: Scalars['String']['input']
}

export type SousChefAdminMutationSaveWorkflowArgs = {
  workflowId: Scalars['ID']['input']
}

export type SousChefAdminQuery = {
  __typename: 'SousChefAdminQuery'
  allDataPanels?: Maybe<Array<SousChefDataPanel>>
  allDataSources?: Maybe<Array<SousChefDataSource>>
  allDefinitions?: Maybe<Array<SousChefDefinition>>
  allKnowledge?: Maybe<Array<SousChefKnowledge>>
  dataPanel?: Maybe<SousChefDataPanel>
  dataSource?: Maybe<SousChefDataSource>
  definition?: Maybe<SousChefDefinition>
  knowledge?: Maybe<SousChefKnowledge>
}

export type SousChefAdminQueryDataPanelArgs = {
  id: Scalars['ID']['input']
}

export type SousChefAdminQueryDataSourceArgs = {
  id: Scalars['ID']['input']
}

export type SousChefAdminQueryDefinitionArgs = {
  id: Scalars['ID']['input']
}

export type SousChefAdminQueryKnowledgeArgs = {
  id: Scalars['ID']['input']
}

export type SousChefAlert = SousChefDataPoint & {
  __typename: 'SousChefAlert'
  description: Scalars['String']['output']
  label: Scalars['String']['output']
  shareableText?: Maybe<Scalars['String']['output']>
  source: SousChefInsightSource
  title: Scalars['String']['output']
  value: Scalars['Float']['output']
}

export type SousChefAnalysisNode = {
  __typename: 'SousChefAnalysisNode'
  id: Scalars['ID']['output']
  instruction: Scalars['String']['output']
  name: Scalars['String']['output']
}

export type SousChefAutoClockedOut = {
  __typename: 'SousChefAutoClockedOut'
  employee: RestaurantUser
  startTime: Scalars['DateTime']['output']
}

export type SousChefAutoClockedOutDataPoint = SousChefDataPoint & {
  __typename: 'SousChefAutoClockedOutDataPoint'
  description: Scalars['String']['output']
  items: Array<SousChefAutoClockedOut>
  label: Scalars['String']['output']
  shareableText?: Maybe<Scalars['String']['output']>
  source: SousChefInsightSource
  title: Scalars['String']['output']
}

/** Check List Types */
export type SousChefChecklistItem = {
  __typename: 'SousChefChecklistItem'
  insight: SousChefInsight
  viewed: Scalars['Boolean']['output']
  viewedAt?: Maybe<Scalars['DateTime']['output']>
}

/** A SousChef thread of thought that has been completed */
export type SousChefCompletedThread = SousChefThread & {
  __typename: 'SousChefCompletedThread'
  actionPlans: Array<SousChefActionPlan>
  analysis?: Maybe<Scalars['String']['output']>
  createdAt: Scalars['DateTime']['output']
  dataSources: Array<SousChefInsightSource>
  filters: Array<SousChefFilter>
  id: Scalars['ID']['output']
  relatedQuestions?: Maybe<Array<Scalars['String']['output']>>
  sequence: Scalars['Int']['output']
  status: Scalars['String']['output']
  summary?: Maybe<Scalars['String']['output']>
  thought?: Maybe<Scalars['String']['output']>
  title: Scalars['String']['output']
  workflow?: Maybe<Workflow>
  workflowTasks?: Maybe<Array<SousChefWorkflowTask>>
}

export type SousChefDataNode = {
  __typename: 'SousChefDataNode'
  dataSources: Array<Scalars['String']['output']>
  id: Scalars['ID']['output']
  instruction: Scalars['String']['output']
  name: Scalars['String']['output']
}

export type SousChefDataPanel = {
  __typename: 'SousChefDataPanel'
  data: Scalars['String']['output']
  description: Scalars['String']['output']
  id: Scalars['ID']['output']
  questions: Array<Scalars['String']['output']>
  title: Scalars['String']['output']
}

/** Sous Chef Data Point types */
export type SousChefDataPoint = {
  description: Scalars['String']['output']
  label: Scalars['String']['output']
  shareableText?: Maybe<Scalars['String']['output']>
  source: SousChefInsightSource
  title: Scalars['String']['output']
}

export type SousChefDataSource = {
  __typename: 'SousChefDataSource'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  panels: Array<Maybe<SousChefDataPanel>>
  prompt: Scalars['String']['output']
}

export type SousChefDateRange = {
  __typename: 'SousChefDateRange'
  end: Scalars['Date']['output']
  start: Scalars['Date']['output']
}

export type SousChefDateRangeFilter = {
  __typename: 'SousChefDateRangeFilter'
  endDate: Scalars['Date']['output']
  startDate: Scalars['Date']['output']
}

export type SousChefDateTimeRange = {
  __typename: 'SousChefDateTimeRange'
  end: Scalars['DateTime']['output']
  start: Scalars['DateTime']['output']
}

export type SousChefDayOfWeek =
  | 'FRI'
  | 'MON'
  | 'SAT'
  | 'SUN'
  | 'THU'
  | 'TUE'
  | 'WED'

export type SousChefDayOfWeekFilter = {
  __typename: 'SousChefDayOfWeekFilter'
  days: Array<SousChefDayOfWeek>
}

export type SousChefDayPart = {
  __typename: 'SousChefDayPart'
  name: Scalars['String']['output']
}

export type SousChefDayPartFilter = {
  __typename: 'SousChefDayPartFilter'
  dayParts: Array<SousChefDayPart>
}

export type SousChefDefinition = {
  __typename: 'SousChefDefinition'
  definition: Scalars['String']['output']
  id: Scalars['ID']['output']
  instruction?: Maybe<Scalars['String']['output']>
  questions: Array<Scalars['String']['output']>
  term?: Maybe<Scalars['String']['output']>
}

export type SousChefDiningOption = {
  __typename: 'SousChefDiningOption'
  name: Scalars['String']['output']
}

export type SousChefDiningOptionFilter = {
  __typename: 'SousChefDiningOptionFilter'
  diningOptions: Array<SousChefDiningOption>
}

export type SousChefEdge = {
  __typename: 'SousChefEdge'
  id: Scalars['ID']['output']
  sourceNodeId: Scalars['ID']['output']
  targetNodeId: Scalars['ID']['output']
}

export type SousChefEmployeeFilter = {
  __typename: 'SousChefEmployeeFilter'
  employees: RestaurantUser
}

export type SousChefEmptyDataPoint = SousChefDataPoint & {
  __typename: 'SousChefEmptyDataPoint'
  canRetry: Scalars['Boolean']['output']
  description: Scalars['String']['output']
  label: Scalars['String']['output']
  shareableText?: Maybe<Scalars['String']['output']>
  source: SousChefInsightSource
  title: Scalars['String']['output']
}

export type SousChefFeedback = {
  comments?: InputMaybe<Scalars['String']['input']>
  followUpId?: InputMaybe<Scalars['ID']['input']>
  insightId: Scalars['ID']['input']
  rating: SousChefFeedbackRating
  reason?: InputMaybe<SousChefFeedbackIssueReason>
}

export type SousChefFeedbackIssueReason = 'FACTUALLY_INCORRECT' | 'OFFENSIVE'

/** Feedback input types */
export type SousChefFeedbackRating = 'ISSUE' | 'NOT_INTERESTED' | 'SHOW_ME_MORE'

export type SousChefFilter =
  | SousChefDateRangeFilter
  | SousChefDayOfWeekFilter
  | SousChefDayPartFilter
  | SousChefDiningOptionFilter
  | SousChefEmployeeFilter
  | SousChefHoursOfDayFilter
  | SousChefIntervalFilter
  | SousChefMenuFilter
  | SousChefMenuGroupFilter
  | SousChefOrderSourceFilter
  | SousChefRevenueCenterFilter
  | SousChefSalesCategoryFilter
  | SousChefServiceAreaFilter

export type SousChefFilterType =
  | 'DATE_RANGE'
  | 'DAYS_OF_WEEK'
  | 'HOURS_OF_DAY'
  | 'INTERVAL'
  | 'MENU'

export type SousChefFinancialData = {
  __typename: 'SousChefFinancialData'
  label: Scalars['String']['output']
  value: Money
}

export type SousChefFinancialDataPoint = SousChefDataPoint & {
  __typename: 'SousChefFinancialDataPoint'
  data: Array<SousChefFinancialData>
  description: Scalars['String']['output']
  label: Scalars['String']['output']
  shareableText?: Maybe<Scalars['String']['output']>
  source: SousChefInsightSource
  title: Scalars['String']['output']
}

export type SousChefFixedResponseNode = {
  __typename: 'SousChefFixedResponseNode'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  response: Scalars['String']['output']
}

export type SousChefHoursOfDayFilter = {
  __typename: 'SousChefHoursOfDayFilter'
  end: Scalars['LocalTime']['output']
  start: Scalars['LocalTime']['output']
}

/** Sous Chef Insights Definition */
export type SousChefInsight = {
  __typename: 'SousChefInsight'
  canAskFollowUp: Scalars['Boolean']['output']
  /** @deprecated in favor of dataPoint: DataPoint! */
  data: Array<SousChefDataPoint>
  dataPoint: SousChefDataPoint
  filter?: Maybe<SousChefInsightFilter>
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  relatedInsights?: Maybe<Array<SousChefInsight>>
  source: SousChefInsightSource
  title: Scalars['String']['output']
}

/**  Filter types. For MVP we will only support dateRange to keep it simple In future phases we will extend this  */
export type SousChefInsightFilter = {
  __typename: 'SousChefInsightFilter'
  dateRange?: Maybe<SousChefDateRange>
}

export type SousChefInsightSource =
  | 'BENCHMARKING'
  | 'CASH_FLOW'
  | 'GUEST'
  | 'LABOR_API'
  | 'LABOR_COST'
  | 'MENU'
  | 'PMIX'
  | 'PRODUCT_MIX_PROFITABILITY'
  | 'RESTAURANT_CONFIG'
  | 'SALES_SUMMARY'
  | 'SOUS_CHEF_AI'
  | 'STOCK_API'
  | 'WEATHER'

export type SousChefInterval = 'DAILY' | 'MONTHLY' | 'QUARTERLY' | 'WEEKLY'

export type SousChefIntervalFilter = {
  __typename: 'SousChefIntervalFilter'
  interval: SousChefInterval
}

export type SousChefKnowledge = {
  __typename: 'SousChefKnowledge'
  content: Scalars['String']['output']
  description: Scalars['String']['output']
  id: Scalars['ID']['output']
  instruction?: Maybe<Scalars['String']['output']>
  questions: Array<Scalars['String']['output']>
  title: Scalars['String']['output']
}

export type SousChefMenu = {
  __typename: 'SousChefMenu'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type SousChefMenuFilter = {
  __typename: 'SousChefMenuFilter'
  menus: Array<SousChefMenu>
}

export type SousChefMenuGroup = {
  __typename: 'SousChefMenuGroup'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type SousChefMenuGroupFilter = {
  __typename: 'SousChefMenuGroupFilter'
  menuGroups: Array<SousChefMenuGroup>
}

export type SousChefMutation = {
  __typename: 'SousChefMutation'
  deleteWorkflow: Scalars['ID']['output']
  feedback?: Maybe<Scalars['Boolean']['output']>
  saveWorkflow: Scalars['ID']['output']
  threadFeedback: Scalars['ID']['output']
  updateWorkflow: Scalars['ID']['output']
  updateWorkflowFromFollowUp: Scalars['ID']['output']
}

export type SousChefMutationDeleteWorkflowArgs = {
  workflowId: Scalars['ID']['input']
}

export type SousChefMutationFeedbackArgs = {
  feedback: SousChefFeedback
}

export type SousChefMutationSaveWorkflowArgs = {
  name?: InputMaybe<Scalars['String']['input']>
  threadId: Scalars['ID']['input']
  threadSessionId: Scalars['ID']['input']
}

export type SousChefMutationThreadFeedbackArgs = {
  feedback: SousChefThreadFeedback
}

export type SousChefMutationUpdateWorkflowArgs = {
  isSaved?: InputMaybe<Scalars['Boolean']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  workflowId: Scalars['ID']['input']
}

export type SousChefMutationUpdateWorkflowFromFollowUpArgs = {
  name?: InputMaybe<Scalars['String']['input']>
  threadId: Scalars['ID']['input']
  threadSessionId: Scalars['ID']['input']
}

export type SousChefOrderSource = {
  __typename: 'SousChefOrderSource'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type SousChefOrderSourceFilter = {
  __typename: 'SousChefOrderSourceFilter'
  orderSources: Array<SousChefOrderSource>
}

export type SousChefOutOfStockItem = {
  __typename: 'SousChefOutOfStockItem'
  menuItem: PublishedMenuItem
  modifiedTime: Scalars['DateTime']['output']
}

export type SousChefPercentageData = {
  __typename: 'SousChefPercentageData'
  label: Scalars['String']['output']
  value: Scalars['Float']['output']
}

export type SousChefPercentageDataPoint = SousChefDataPoint & {
  __typename: 'SousChefPercentageDataPoint'
  data: Array<SousChefPercentageData>
  description: Scalars['String']['output']
  label: Scalars['String']['output']
  shareableText?: Maybe<Scalars['String']['output']>
  source: SousChefInsightSource
  title: Scalars['String']['output']
}

export type SousChefQuery = {
  __typename: 'SousChefQuery'
  dummyEndpoint: Scalars['Boolean']['output']
  insight?: Maybe<SousChefInsight>
  threadSession?: Maybe<SousChefThreadSession>
  threadSessions?: Maybe<Array<SousChefThreadSession>>
  workflows: Array<Workflow>
}

export type SousChefQueryInsightArgs = {
  id: Scalars['ID']['input']
}

export type SousChefQueryThreadSessionArgs = {
  sessionId: Scalars['ID']['input']
}

export type SousChefRevenueCenter = {
  __typename: 'SousChefRevenueCenter'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type SousChefRevenueCenterFilter = {
  __typename: 'SousChefRevenueCenterFilter'
  revenueCenters: Array<SousChefRevenueCenter>
}

export type SousChefSalesCategory = {
  __typename: 'SousChefSalesCategory'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type SousChefSalesCategoryFilter = {
  __typename: 'SousChefSalesCategoryFilter'
  salesCategories: Array<SousChefSalesCategory>
}

export type SousChefServiceArea = {
  __typename: 'SousChefServiceArea'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type SousChefServiceAreaFilter = {
  __typename: 'SousChefServiceAreaFilter'
  serviceAreas: Array<SousChefServiceArea>
}

export type SousChefStockDataPoint = SousChefDataPoint & {
  __typename: 'SousChefStockDataPoint'
  description: Scalars['String']['output']
  items: Array<SousChefOutOfStockItem>
  label: Scalars['String']['output']
  shareableText?: Maybe<Scalars['String']['output']>
  source: SousChefInsightSource
  title: Scalars['String']['output']
}

/** A SousChef thread of thought that is suggested */
export type SousChefSuggestedThread = SousChefThread & {
  __typename: 'SousChefSuggestedThread'
  dataSources: Array<SousChefInsightSource>
  /** Summary for the thread */
  summary?: Maybe<Scalars['String']['output']>
  title: Scalars['String']['output']
}

/** Sous Chef Summary Types */
export type SousChefSummary = {
  __typename: 'SousChefSummary'
  checklist: Array<SousChefChecklistItem>
  createdAt: Scalars['DateTime']['output']
  date: Scalars['Date']['output']
  highlights: Array<Scalars['String']['output']>
  insights: Array<SousChefInsight>
  /** Thread groups */
  threadGroups: Array<SousChefThreadGroup>
}

export type SousChefTaskData = {
  __typename: 'SousChefTaskData'
  text: Scalars['String']['output']
}

/**  Text data point, NOTE: This type is only intended for responses from the AI when we can NOT format the data as a different type of DataPoint  */
export type SousChefTextDataPoint = SousChefDataPoint & {
  __typename: 'SousChefTextDataPoint'
  description: Scalars['String']['output']
  label: Scalars['String']['output']
  shareableText?: Maybe<Scalars['String']['output']>
  source: SousChefInsightSource
  title: Scalars['String']['output']
}

/** A SousChef thread of thought */
export type SousChefThread = {
  dataSources: Array<SousChefInsightSource>
  /** Summary for the thread */
  summary?: Maybe<Scalars['String']['output']>
  title: Scalars['String']['output']
}

export type SousChefThreadFeedback = {
  comment?: InputMaybe<Scalars['String']['input']>
  reasons: Array<Scalars['String']['input']>
  score: Scalars['Int']['input']
  threadId: Scalars['ID']['input']
  threadSessionId?: InputMaybe<Scalars['ID']['input']>
}

/** The compilation of threads */
export type SousChefThreadGroup = {
  __typename: 'SousChefThreadGroup'
  threads: Array<SousChefThread>
  title: Scalars['String']['output']
  type: SousChefThreadGroupType
}

export type SousChefThreadGroupType =
  /** Represents the highlighted threads */
  | 'HIGHLIGHTS'
  /** Represents any grouping for threads */
  | 'OTHER'

export type SousChefThreadSession = {
  __typename: 'SousChefThreadSession'
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  summary: Scalars['String']['output']
  threads: Array<SousChefCompletedThread>
  updatedAt: Scalars['DateTime']['output']
}

export type SousChefTrend = {
  __typename: 'SousChefTrend'
  direction: SousChefTrendDirection
  label: Scalars['String']['output']
  sentiment: SousChefTrendSentiment
  value: Scalars['Float']['output']
}

export type SousChefTrendDataPoint = SousChefDataPoint & {
  __typename: 'SousChefTrendDataPoint'
  description: Scalars['String']['output']
  label: Scalars['String']['output']
  shareableText?: Maybe<Scalars['String']['output']>
  source: SousChefInsightSource
  title: Scalars['String']['output']
  trends: Array<SousChefTrend>
}

export type SousChefTrendDirection = 'DOWN' | 'UP'

export type SousChefTrendSentiment = 'NEGATIVE' | 'NEUTRAL' | 'POSITIVE'

export type SousChefWorkflowDefinition = {
  __typename: 'SousChefWorkflowDefinition'
  edges: Array<SousChefEdge>
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  nodes: Array<SousChefWorkflowNode>
}

export type SousChefWorkflowNode =
  | SousChefAnalysisNode
  | SousChefDataNode
  | SousChefFixedResponseNode

export type SousChefWorkflowSource = 'HARDCODED' | 'USER'

export type SousChefWorkflowTask = {
  __typename: 'SousChefWorkflowTask'
  children: Array<Scalars['ID']['output']>
  dataPanels?: Maybe<Array<SousChefDataPanel>>
  dataSources?: Maybe<Array<SousChefDataSource>>
  definitions: Array<SousChefDefinition>
  id: Scalars['ID']['output']
  input: Array<SousChefTaskData>
  knowledge: Array<SousChefKnowledge>
  name: Scalars['String']['output']
  nodeId: Scalars['ID']['output']
  output: Array<SousChefTaskData>
  status: Scalars['String']['output']
}

export type SpecialOccasion =
  | 'ANNIVERSARY'
  | 'BIRTHDAY'
  | 'BUSINESS'
  | 'CELEBRATION'
  | 'DATE'
  | 'NONE'
  | 'REUNION'

export type StartDoorDashOnboardingRequest = {
  doordash_store_uuid: Scalars['ID']['input']
  location_id: Scalars['String']['input']
  provider_store_name: Scalars['String']['input']
}

export type StaticPaymentDataExtension = DataExtension & {
  __typename: 'StaticPaymentDataExtension'
  paymentType: StaticPaymentType
  type: DataExtensionType
  value: Scalars['String']['output']
}

export type StaticPaymentType =
  | 'AMEX'
  | 'CASH'
  | 'DINERS_CLUB'
  | 'DISCOVER'
  | 'GIFT_CARD'
  | 'HOUSE_ACCOUNT'
  | 'JCB'
  | 'MAESTRO'
  | 'MASTERCARD'
  | 'SOLO'
  | 'VISA'

export type Step = {
  __typename: 'Step'
  children: Array<Scalars['ID']['output']>
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type Stock = {
  __typename: 'Stock'
  quantity?: Maybe<Scalars['Float']['output']>
  status: StockStatus
}

export type StockDataConfigInput = {
  stockdataEnabled: Scalars['Boolean']['input']
}

export type StockDataConfigOverride = {
  __typename: 'StockDataConfigOverride'
  fields: Array<ConfigField>
  stockdataEnabled?: Maybe<Scalars['Boolean']['output']>
  target: RestaurantSet
}

export type StockDataConfigOverrideInput = {
  fields: Array<ConfigFieldInput>
  stockdataEnabled?: InputMaybe<Scalars['Boolean']['input']>
  target: RestaurantSetInput
}

export type StockOutConfigInput = {
  stockImageUrl: Scalars['String']['input']
  stockoutEnabled: Scalars['Boolean']['input']
}

export type StockOutConfigOverride = {
  __typename: 'StockOutConfigOverride'
  fields: Array<ConfigField>
  stockImageUrl?: Maybe<Scalars['String']['output']>
  stockoutEnabled?: Maybe<Scalars['Boolean']['output']>
  target: RestaurantSet
}

export type StockOutConfigOverrideInput = {
  fields: Array<ConfigFieldInput>
  stockImageUrl?: InputMaybe<Scalars['String']['input']>
  stockoutEnabled?: InputMaybe<Scalars['Boolean']['input']>
  target: RestaurantSetInput
}

export type StockStatus = 'IN_STOCK' | 'OUT_OF_STOCK' | 'QUANTITY'

export type SubmitLogbookEntryInput = {
  businessDay: Scalars['Date']['input']
  categoryID: Scalars['ID']['input']
  content: LogbookContentInput
  locationID: Scalars['ID']['input']
  priority: LogbookEntryPriority
}

export type SubmitLogbookEntryReplyInput = {
  content: LogbookContentInput
  entryID: Scalars['ID']['input']
  locationID: Scalars['ID']['input']
}

export type SubscriptionState =
  | 'OPTIN'
  | 'OPTOUT'
  | 'TRIAL_CANCELLED'
  | 'TRIAL_INITIATED'

export type SubscriptionsCancellationReason =
  | 'NO_INTENTION_TO_PURCHASE'
  | 'NO_INTENTION_TO_USE'
  | 'NO_LONGER_A_CUSTOMER'
  | 'NO_TIME'
  | 'NO_VALUE'
  | 'SEASONAL'
  | 'TOO_DIFFICULT'
  | 'TOO_LONG'
  | 'UNDEFINED'

export type SubscriptionsCancellationRequest = {
  __typename: 'SubscriptionsCancellationRequest'
  comment?: Maybe<Scalars['String']['output']>
  createdAt: Scalars['DateTime']['output']
  location?: Maybe<SavedRestaurantLocation>
  reason: SubscriptionsCancellationReason
  salesforceCaseNumber?: Maybe<Scalars['String']['output']>
  state: CancellationRequestState
  subscriptionSalesforceId: Scalars['ID']['output']
  type: CancellationRequestType
  user?: Maybe<Scalars['String']['output']>
}

export type SubscriptionsCancellationRequestFilter = {
  state?: InputMaybe<Array<CancellationRequestState>>
}

export type SubscriptionsCancellationRequestInput = {
  items: Array<SubscriptionsCancellationRequestItemInput>
}

export type SubscriptionsCancellationRequestItemInput = {
  comment?: InputMaybe<Scalars['String']['input']>
  reason: SubscriptionsCancellationReason
  subscriptionSalesforceId: Scalars['ID']['input']
}

export type SuggestionResponse = {
  __typename: 'SuggestionResponse'
  items: Array<SearchSuggestion>
}

export type SupportQuery = {
  __typename: 'SupportQuery'
  chatTranscript?: Maybe<IntercomConversation>
  intercomOptions?: Maybe<IntercomOptions>
}

export type SupportQueryChatTranscriptArgs = {
  chatId: Scalars['ID']['input']
}

export type SupportQueryIntercomOptionsArgs = {
  clientType: IntercomClientType
}

export type SystemIdentity = Identity & {
  __typename: 'SystemIdentity'
  id: Scalars['String']['output']
  name: Scalars['String']['output']
  version: Scalars['String']['output']
}

export type TableAvailabilityInfo = {
  __typename: 'TableAvailabilityInfo'
  componentTables: Array<Scalars['ID']['output']>
  existingBookings: Array<Scalars['ID']['output']>
  id: Scalars['ID']['output']
  invalidPartySize: Scalars['Boolean']['output']
  isBlocked: Scalars['Boolean']['output']
  maxCapacity: Scalars['Int']['output']
  minCapacity: Scalars['Int']['output']
  name: Scalars['String']['output']
  onlineReservationsDisabled: Scalars['Boolean']['output']
}

export type TableBlocked = Error & {
  __typename: 'TableBlocked'
  message: Scalars['String']['output']
  status: ErrorCode
}

export type TableNotFound = Error & {
  __typename: 'TableNotFound'
  message: Scalars['String']['output']
  status: ErrorCode
}

export type TakeoutDeliveryCategoryPage = {
  __typename: 'TakeoutDeliveryCategoryPage'
  productRecommendations: Array<Maybe<ProductRecommendation>>
}

export type TakeoutDeliveryConfig = {
  __typename: 'TakeoutDeliveryConfig'
  deliveryEnabled?: Maybe<Scalars['Boolean']['output']>
  deliveryMode?: Maybe<Scalars['String']['output']>
  deliveryToggleUIConfiguration?: Maybe<DeliveryToggleUiConfiguration>
  id: Scalars['ID']['output']
  onlineOrderingDeliveryDiningOptionSelected?: Maybe<
    Scalars['Boolean']['output']
  >
  onlineOrderingEnabled?: Maybe<Scalars['String']['output']>
  onlineOrderingModuleEnabled?: Maybe<Scalars['Boolean']['output']>
  onlineOrderingStatusLabel: OnlineOrderingStatusLabel
  onlineOrderingTakeoutDiningOptionSelected?: Maybe<
    Scalars['Boolean']['output']
  >
  onlineOrderingUrl?: Maybe<Scalars['String']['output']>
  ordersApiModuleEnabled?: Maybe<Scalars['Boolean']['output']>
  snoozeUntilTime?: Maybe<Scalars['String']['output']>
  takeoutEnabled?: Maybe<Scalars['Boolean']['output']>
  tdsDeliveryEnabled?: Maybe<Scalars['Boolean']['output']>
  timeZone: Scalars['String']['output']
  toastShopAccess?: Maybe<Scalars['Boolean']['output']>
}

export type TargetAndOwnerInput = {
  owner: Scalars['ID']['input']
  target: Scalars['ID']['input']
}

export type Task = {
  __typename: 'Task'
  availableActions: Array<TaskAction>
  badgeText: Array<Scalars['String']['output']>
  description: Scalars['String']['output']
  id: Scalars['ID']['output']
  /** @deprecated This field is now available directly in the badgeText field. */
  internalOnly: Scalars['Boolean']['output']
  keywords: Array<Scalars['String']['output']>
  /** @deprecated These fields are now available directly in the task object. */
  metadata?: Maybe<Scalars['JSON']['output']>
  name: Scalars['String']['output']
  /** @deprecated Use taskType. New tab links will have taskType == NEW_TAB_LINK. */
  newTab: Scalars['Boolean']['output']
  path: Scalars['String']['output']
  pendoId?: Maybe<Scalars['String']['output']>
  /** @deprecated This field is now available directly in the badgeText field. */
  showBetaTag: Scalars['Boolean']['output']
  taskType: TaskType
}

export type TaskAction = {
  __typename: 'TaskAction'
  action: Scalars['String']['output']
  description: Scalars['String']['output']
}

export type TaskType =
  | 'BANQUET_LINK'
  | 'DOWNLOAD'
  | 'LINK'
  | 'NEW_TAB_LINK'
  | 'UPSELL'

export type TaxRateDataExtension = DataExtension & {
  __typename: 'TaxRateDataExtension'
  taxRate: SavedTaxRate
  type: DataExtensionType
  value: Scalars['String']['output']
}

export type TaxRateType =
  | 'EXTERNAL'
  | 'FIXED'
  | 'NONE'
  | 'PERCENT'
  | 'TABLE'
  | 'UNSPECIFIED'

/** An android device ie. a device running toastmobile */
export type TerminalHandheld = Device & {
  __typename: 'TerminalHandheld'
  androidOsLevel?: Maybe<Scalars['String']['output']>
  deviceDetails?: Maybe<DeviceDetails>
  deviceId: Scalars['String']['output']
  deviceType: DeviceType
  firmwareVersion?: Maybe<Scalars['String']['output']>
  isAutoFirer: Scalars['Boolean']['output']
  isPciCompliant: Scalars['Boolean']['output']
  isToastUser: Scalars['Boolean']['output']
  lastUpdate?: Maybe<Scalars['DateTime']['output']>
  model?: Maybe<Scalars['String']['output']>
  modelName?: Maybe<Scalars['String']['output']>
  name: Scalars['String']['output']
  networks?: Maybe<Array<DeviceNetworkDetails>>
  pciNonComplianceReason?: Maybe<Scalars['String']['output']>
  photo?: Maybe<Scalars['String']['output']>
  posAppVersion?: Maybe<Scalars['String']['output']>
  primaryMode?: Maybe<Scalars['String']['output']>
  restaurant: SavedRestaurantLocation
  securityPatchVersion?: Maybe<Scalars['String']['output']>
  serial: Scalars['ID']['output']
  sync?: Maybe<DeviceSyncDetails>
}

export type TerminatedEmploymentStatus = EmploymentStatus & {
  __typename: 'TerminatedEmploymentStatus'
  adjustedHireDate?: Maybe<Scalars['Date']['output']>
  /** @deprecated use employee.hireDate at the top level instead */
  hireDate?: Maybe<Scalars['String']['output']>
  lastDayOfEmployment?: Maybe<Scalars['String']['output']>
  lastDayToReceiveBenefits?: Maybe<Scalars['String']['output']>
}

export type TextArrayFilter = {
  operation: TextFilterOperation
  value: Array<InputMaybe<Scalars['String']['input']>>
}

export type TextFilter = {
  operation: TextFilterOperation
  value: Scalars['String']['input']
}

export type TextFilterOperation =
  | 'CONTAINS'
  | 'ENDS_WITH'
  | 'IN'
  | 'MATCH'
  | 'NOT_CONTAINS'
  | 'NOT_ENDS_WITH'
  | 'NOT_IN'
  | 'NOT_STARTS_WITH'
  | 'SEARCH_AS_YOU_TYPE'
  | 'STARTS_WITH'
  | 'TERM'

export type ThirdPartyMockMutation = {
  __typename: 'ThirdPartyMockMutation'
  clearEvents: Scalars['Boolean']['output']
  logEvent: Array<ThirdPartyMockProviderEvent>
  placeThirdPartyMockOrder: PlaceThirdPartyMockOrderResponse
  updateDoorDashMockOnboardingConfiguration: DoorDashMockOnboardingConfiguration
}

export type ThirdPartyMockMutationClearEventsArgs = {
  provider: Scalars['String']['input']
  restaurantGuid?: InputMaybe<Scalars['ID']['input']>
}

export type ThirdPartyMockMutationLogEventArgs = {
  event: ThirdPartyMockProviderEventInput
}

export type ThirdPartyMockMutationPlaceThirdPartyMockOrderArgs = {
  channelGuid: Scalars['ID']['input']
  provider: Scalars['String']['input']
}

export type ThirdPartyMockMutationUpdateDoorDashMockOnboardingConfigurationArgs =
  {
    configuration: DoorDashMockOnboardingConfigurationInput
  }

export type ThirdPartyMockProviderEvent = {
  __typename: 'ThirdPartyMockProviderEvent'
  data?: Maybe<Scalars['String']['output']>
  eventType?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  provider: Scalars['String']['output']
  restaurant: SavedRestaurantLocation
  timestamp?: Maybe<Scalars['DateTime']['output']>
}

export type ThirdPartyMockProviderEventInput = {
  data?: InputMaybe<Scalars['String']['input']>
  eventType: Scalars['String']['input']
  provider: Scalars['String']['input']
  restaurantGuid: Scalars['ID']['input']
}

export type ThirdPartyMockQuery = {
  __typename: 'ThirdPartyMockQuery'
  getDoorDashMockOnboardingConfiguration: DoorDashMockOnboardingConfiguration
  getEventLog: Array<ThirdPartyMockProviderEvent>
}

export type ThirdPartyMockQueryGetDoorDashMockOnboardingConfigurationArgs = {
  restaurantGuid?: InputMaybe<Scalars['ID']['input']>
}

export type ThirdPartyMockQueryGetEventLogArgs = {
  provider: Scalars['String']['input']
  restaurantGuid?: InputMaybe<Scalars['ID']['input']>
}

export type ThirdPartyOrderingOnboardingFastTrackRequest = {
  provider: ThirdPartyOrderingOnboardingProvider
  restaurantGuid: Scalars['ID']['input']
}

export type ThirdPartyOrderingOnboardingFlow = {
  __typename: 'ThirdPartyOrderingOnboardingFlow'
  provider: ThirdPartyOrderingOnboardingProvider
  restaurantGuid: Scalars['ID']['output']
  tasks: Array<ThirdPartyOrderingOnboardingTask>
}

export type ThirdPartyOrderingOnboardingFlowInput = {
  provider: ThirdPartyOrderingOnboardingProvider
  restaurantGuid: Scalars['ID']['input']
  tasks: Array<ThirdPartyOrderingOnboardingTaskInput>
}

export type ThirdPartyOrderingOnboardingMutation = {
  __typename: 'ThirdPartyOrderingOnboardingMutation'
  activateDoorDashIntegration: DoorDashOnboardingActivateIntegrationResponse
  createThirdPartyOrderingOnboardingFlow: ThirdPartyOrderingOnboardingFlow
  fastTrackThirdPartyIntegration?: Maybe<
    Array<ThirdPartyOrderingSavedMenuAuditSetting>
  >
  fastTrackThirdPartyIntegrationList: Array<
    Maybe<Array<ThirdPartyOrderingSavedMenuAuditSetting>>
  >
  netNewDoorDashOnboarding: DoorDashNetNewOnboardingResponse
  resetTasksForContractResubmission: Scalars['Boolean']['output']
  runThirdPartyOrderingMenuAudit?: Maybe<
    Array<ThirdPartyOrderingSavedMenuAuditSetting>
  >
  setThirdPartyOrderingOnboardingTaskCompleted: Scalars['Boolean']['output']
  setThirdPartyOrderingOnboardingTaskInProgress: Scalars['Boolean']['output']
  startDoorDashOnboarding: DoorDashOnboardingIdResponse
  updateThirdPartyOrderingOnboardingFlow: ThirdPartyOrderingOnboardingFlow
  updateThirdPartyOrderingOnboardingFlowTask: ThirdPartyOrderingOnboardingFlow
  updateThirdPartyPartnerAccess: Scalars['Boolean']['output']
}

export type ThirdPartyOrderingOnboardingMutationActivateDoorDashIntegrationArgs =
  {
    onboardingId: Scalars['String']['input']
  }

export type ThirdPartyOrderingOnboardingMutationCreateThirdPartyOrderingOnboardingFlowArgs =
  {
    provider: ThirdPartyOrderingOnboardingProvider
  }

export type ThirdPartyOrderingOnboardingMutationFastTrackThirdPartyIntegrationArgs =
  {
    provider: ThirdPartyOrderingOnboardingProvider
  }

export type ThirdPartyOrderingOnboardingMutationFastTrackThirdPartyIntegrationListArgs =
  {
    fastTrackRequests: Array<ThirdPartyOrderingOnboardingFastTrackRequest>
  }

export type ThirdPartyOrderingOnboardingMutationNetNewDoorDashOnboardingArgs = {
  netNewOnboardingRequest: DoorDashNetNewOnboardingRequest
}

export type ThirdPartyOrderingOnboardingMutationResetTasksForContractResubmissionArgs =
  {
    provider: ThirdPartyOrderingOnboardingProvider
  }

export type ThirdPartyOrderingOnboardingMutationRunThirdPartyOrderingMenuAuditArgs =
  {
    provider: ThirdPartyOrderingOnboardingProvider
    shouldPublishChanges: Scalars['Boolean']['input']
  }

export type ThirdPartyOrderingOnboardingMutationSetThirdPartyOrderingOnboardingTaskCompletedArgs =
  {
    provider: ThirdPartyOrderingOnboardingProvider
    taskId: ThirdPartyOrderingOnboardingTaskId
  }

export type ThirdPartyOrderingOnboardingMutationSetThirdPartyOrderingOnboardingTaskInProgressArgs =
  {
    provider: ThirdPartyOrderingOnboardingProvider
    taskId: ThirdPartyOrderingOnboardingTaskId
  }

export type ThirdPartyOrderingOnboardingMutationStartDoorDashOnboardingArgs = {
  onboardingRequest: StartDoorDashOnboardingRequest
}

export type ThirdPartyOrderingOnboardingMutationUpdateThirdPartyOrderingOnboardingFlowArgs =
  {
    provider: ThirdPartyOrderingOnboardingProvider
    thirdPartyOrderingOnboardingFlowInput: ThirdPartyOrderingOnboardingFlowInput
  }

export type ThirdPartyOrderingOnboardingMutationUpdateThirdPartyOrderingOnboardingFlowTaskArgs =
  {
    provider: ThirdPartyOrderingOnboardingProvider
    task: ThirdPartyOrderingOnboardingTaskInput
  }

export type ThirdPartyOrderingOnboardingMutationUpdateThirdPartyPartnerAccessArgs =
  {
    provider: ThirdPartyOrderingOnboardingProvider
  }

export type ThirdPartyOrderingOnboardingProvider =
  | 'DOORDASH'
  | 'GRUBHUB'
  | 'UBEREATS'

export type ThirdPartyOrderingOnboardingQuery = {
  __typename: 'ThirdPartyOrderingOnboardingQuery'
  doorDashOnboardingStatus: DoorDashOnboardingStatusResponse
  doorDashOnboardingStores: DoorDashOnboardingStores
  thirdPartyOrderingOnboardingFlow: ThirdPartyOrderingOnboardingFlow
  thirdPartyOrderingOnboardingTask: ThirdPartyOrderingOnboardingTaskResult
}

export type ThirdPartyOrderingOnboardingQueryDoorDashOnboardingStatusArgs = {
  onboardingId: Scalars['ID']['input']
}

export type ThirdPartyOrderingOnboardingQueryDoorDashOnboardingStoresArgs = {
  provider: ThirdPartyOrderingOnboardingProvider
}

export type ThirdPartyOrderingOnboardingQueryThirdPartyOrderingOnboardingFlowArgs =
  {
    provider: ThirdPartyOrderingOnboardingProvider
  }

export type ThirdPartyOrderingOnboardingQueryThirdPartyOrderingOnboardingTaskArgs =
  {
    provider: ThirdPartyOrderingOnboardingProvider
    taskId: ThirdPartyOrderingOnboardingTaskId
  }

export type ThirdPartyOrderingOnboardingTask = {
  __typename: 'ThirdPartyOrderingOnboardingTask'
  data?: Maybe<Scalars['String']['output']>
  lastUpdated?: Maybe<Scalars['String']['output']>
  taskId: ThirdPartyOrderingOnboardingTaskId
}

export type ThirdPartyOrderingOnboardingTaskId =
  | 'CONTRACT'
  | 'CONTRACT_READY'
  | 'INTEGRATION_COMPLETE'
  | 'INTEGRATION_STATUS'
  | 'MENU_AUDIT'
  | 'MENU_SELECTION'
  | 'PRICE_INCREASE'

export type ThirdPartyOrderingOnboardingTaskInput = {
  data?: InputMaybe<Scalars['String']['input']>
  lastUpdated?: InputMaybe<Scalars['String']['input']>
  taskId: ThirdPartyOrderingOnboardingTaskId
}

export type ThirdPartyOrderingOnboardingTaskResult = {
  __typename: 'ThirdPartyOrderingOnboardingTaskResult'
  key: Scalars['String']['output']
  status: ThirdPartyOrderingOnboardingTaskResultStatus
}

export type ThirdPartyOrderingOnboardingTaskResultStatus =
  | 'BLOCKED'
  | 'CANCELED'
  | 'COMPLETED'
  | 'IN_PROGRESS'
  | 'NOT_STARTED'
  | 'ON_HOLD'
  | 'SKIPPED'

export type ThirdPartyOrderingSavedMenuAuditEntityType =
  | 'Menu'
  | 'MenuGroup'
  | 'MenuItem'
  | 'Modifier'
  | 'ModifierGroup'
  | 'PreModifierGroup'

export type ThirdPartyOrderingSavedMenuAuditSetting = {
  __typename: 'ThirdPartyOrderingSavedMenuAuditSetting'
  entityType: ThirdPartyOrderingSavedMenuAuditEntityType
  multiLocationID: Scalars['String']['output']
  name: Scalars['String']['output']
  settingType: ThirdPartyOrderingSavedMenuAuditSettingType
  versionId: Scalars['String']['output']
}

export type ThirdPartyOrderingSavedMenuAuditSettingType =
  | 'DEFAULT_PRE_MODIFIER_GROUP'
  | 'MENU_ITEM_PORTION'
  | 'MODIFIER_GROUP_SEQUENCE_PRICE'
  | 'MODIFIER_GROUP_SIZE_PRICE'
  | 'MODIFIER_GROUP_SIZE_SEQUENCE_PRICE'
  | 'MODIFIER_GROUP_SUBSTITUTION_PRICE'
  | 'MODIFIER_SIZE_PRICE'
  | 'NESTED_SUBGROUP'
  | 'TIME_SPECIFIC_PRICE'
  | 'USE_PRE_MODIFIER_GROUP'
  | 'VISIBLE_LIST'

export type TimeClockInput = {
  breaks: Array<BreakInput>
  shiftTimes: TimeRangeInput
}

export type TimeEntryRoundingScheme = 'ADD_THEN_ROUND' | 'ROUND_THEN_ADD'

export type TimeRangeInput = {
  endTime?: InputMaybe<Scalars['DateTime']['input']>
  startTime: Scalars['DateTime']['input']
}

export type TipDetails = {
  __typename: 'TipDetails'
  amount: Scalars['String']['output']
  date: Scalars['String']['output']
}

export type TipInput = {
  shiftTip: ShiftTipInput
}

export type TipOutConfig = {
  __typename: 'TipOutConfig'
  isToastTipsManagerEnabled: Scalars['Boolean']['output']
  shouldTipOutGratuity: Scalars['Boolean']['output']
  shouldTipOutNonCash: Scalars['Boolean']['output']
}

export type TipOutConfigInput = {
  isToastTipsManagerEnabled?: InputMaybe<Scalars['Boolean']['input']>
  shouldTipOutGratuity?: InputMaybe<Scalars['Boolean']['input']>
  shouldTipOutNonCash?: InputMaybe<Scalars['Boolean']['input']>
}

export type TipSharingConfig = {
  __typename: 'TipSharingConfig'
  salesCategories: Array<SalesCategory>
  tipSharingPercentages: Array<TipSharingPercentage>
}

export type TipSharingPercentage = {
  __typename: 'TipSharingPercentage'
  description?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  percentage: Scalars['Float']['output']
  salesCategories: Array<SalesCategory>
}

export type TipSharingPercentageInput = {
  description?: InputMaybe<Scalars['String']['input']>
  id: Scalars['ID']['input']
  percentage: Scalars['Float']['input']
  salesCategories: Array<SalesCategoryInput>
}

export type TipsQuery = {
  __typename: 'TipsQuery'
  tips: GetTipsResponse
}

export type ToastCentralArticle = {
  __typename: 'ToastCentralArticle'
  id: Scalars['ID']['output']
}

export type ToastNavigationConfig = {
  __typename: 'ToastNavigationConfig'
  id: Scalars['ID']['output']
}

export type ToastNowDevicePlatform = 'ANDROID' | 'IOS'

export type ToastNowDeviceRegistryMutation = {
  __typename: 'ToastNowDeviceRegistryMutation'
  deleteDeviceToken?: Maybe<ToastNowDeviceToken>
  updateDeviceToken: ToastNowDeviceToken
}

export type ToastNowDeviceRegistryMutationDeleteDeviceTokenArgs = {
  deviceUUID: Scalars['ID']['input']
}

export type ToastNowDeviceRegistryMutationUpdateDeviceTokenArgs = {
  updateDeviceTokenInput: ToastNowUpdateDeviceTokenInput
}

export type ToastNowDeviceRegistryQuery = {
  __typename: 'ToastNowDeviceRegistryQuery'
  getDeviceTokens: Array<Maybe<ToastNowDeviceToken>>
}

export type ToastNowDeviceToken = {
  __typename: 'ToastNowDeviceToken'
  apnsToken?: Maybe<Scalars['String']['output']>
  deviceUUID: Scalars['ID']['output']
  fcmToken?: Maybe<Scalars['String']['output']>
  platform: ToastNowDevicePlatform
  updatedAt: Scalars['DateTime']['output']
  userUUID: Scalars['ID']['output']
}

export type ToastNowUpdateDeviceTokenInput = {
  apnsToken?: InputMaybe<Scalars['String']['input']>
  deviceUUID?: InputMaybe<Scalars['ID']['input']>
  fcmToken?: InputMaybe<Scalars['String']['input']>
  platform: ToastNowDevicePlatform
}

export type ToastPayConfig = {
  __typename: 'ToastPayConfig'
  qrCodeOnReceiptEnabled: Scalars['Boolean']['output']
}

export type ToastPayConfigInput = {
  qrCodeOnReceiptEnabled: Scalars['Boolean']['input']
}

export type ToastPayPaymentNotificationConfig = {
  __typename: 'ToastPayPaymentNotificationConfig'
  assignedServerNotifiedOnly: Scalars['Boolean']['output']
  notificationsEnabled: Scalars['Boolean']['output']
  paymentNotificationsExclusiveOnToastGo: Scalars['Boolean']['output']
}

export type ToastPayPaymentNotificationConfigError = {
  __typename: 'ToastPayPaymentNotificationConfigError'
  code?: Maybe<ToastPayPaymentNotificationConfigErrorCode>
}

export type ToastPayPaymentNotificationConfigErrorCode =
  | 'FailedToRetrieveConfig'

export type ToastPayPaymentNotificationConfigResponse =
  | ToastPayPaymentNotificationConfig
  | ToastPayPaymentNotificationConfigError

export type ToastPayRelatedConfiguration = {
  __typename: 'ToastPayRelatedConfiguration'
  paymentNotificationsConfig: ToastPayPaymentNotificationConfigResponse
  restaurantLogo: ToastPayRestaurantLogoResponse
  tipSelectionsConfig: ToastPayTipSelectionsConfigResponse
}

export type ToastPayRestaurantLogo = {
  __typename: 'ToastPayRestaurantLogo'
  url?: Maybe<Scalars['String']['output']>
}

export type ToastPayRestaurantLogoError = {
  __typename: 'ToastPayRestaurantLogoError'
  code: ToastPayRestaurantLogoErrorCode
}

export type ToastPayRestaurantLogoErrorCode = 'FailedToRetrieveConfig'

export type ToastPayRestaurantLogoResponse =
  | ToastPayRestaurantLogo
  | ToastPayRestaurantLogoError

export type ToastPayTipSelectionsConfig = {
  __typename: 'ToastPayTipSelectionsConfig'
  /** When null, means default percentage has not been specified */
  defaultPercent?: Maybe<Scalars['Float']['output']>
  percentages: Array<Scalars['Float']['output']>
}

export type ToastPayTipSelectionsConfigResponse =
  | ToastPayTipSelectionsConfig
  | ToastPayTipSelectionsError

export type ToastPayTipSelectionsError = {
  __typename: 'ToastPayTipSelectionsError'
  code: ToastPayTipSelectionsErrorCode
}

export type ToastPayTipSelectionsErrorCode = 'FailedToRetrieveConfig'

/** A ToastPermission type including the permission and a bitmask. */
export type ToastPermission = {
  __typename: 'ToastPermission'
  bitmask: Scalars['String']['output']
  permission: Permission
}

/** Connection for top selling items; see https://relay.dev/graphql/connections.htm */
export type TopSellingItemConnection = {
  __typename: 'TopSellingItemConnection'
  /** Edges of the top selling items */
  edges: Array<TopSellingItemEdge>
  /** Page information */
  pageInfo: PageInfo
  /** Total count of items */
  totalItemCount: Scalars['Int']['output']
}

/** Edge for top selling items; see https://relay.dev/graphql/connections.htm */
export type TopSellingItemEdge = {
  __typename: 'TopSellingItemEdge'
  /** Cursor for the item */
  cursor: Scalars['String']['output']
  /** Item metrics node */
  node: ItemMetrics
}

/** Filter for top selling items */
export type TopSellingItemFilter = {
  /** Business day for the metrics */
  businessDay: Scalars['Date']['input']
  /** Filters for the top selling items */
  filterBy?: InputMaybe<Array<TopSellingItemFilterBy>>
  /** Restaurant GUID */
  restaurantGuid: Scalars['ID']['input']
  /** Sort by a given dimension */
  sortBy: TopSellingSortBy
}

export type TopSellingItemFilterBy = {
  /** The dimension to filter by */
  dimension: TopSellingItemFilterDimension
  /** The identifier of the entities to filter by; if identifier is null then we will include items that do not have a value for the dimension */
  identifier?: InputMaybe<Scalars['ID']['input']>
}

export type TopSellingItemFilterDimension =
  /** Filter by employee */
  | 'EMPLOYEE'
  /** Filter by item tag */
  | 'ITEM_TAG'
  /** Filter by menu */
  | 'MENU'
  /** Filter by menu group */
  | 'MENU_GROUP'

/** Connection for top selling modifiers; see https://relay.dev/graphql/connections.htm */
export type TopSellingModifierConnection = {
  __typename: 'TopSellingModifierConnection'
  /** Edges of the top selling modifiers */
  edges: Array<TopSellingModifierEdge>
  /** Page information */
  pageInfo: PageInfo
  /** Total count of modifiers */
  totalModifierCount: Scalars['Int']['output']
}

/** Edge for top selling modifiers; see https://relay.dev/graphql/connections.htm */
export type TopSellingModifierEdge = {
  __typename: 'TopSellingModifierEdge'
  /** Cursor for the item */
  cursor: Scalars['String']['output']
  /** Item metrics node */
  node: ModifierMetrics
}

/** Filter for top selling modifiers */
export type TopSellingModifierFilter = {
  /** The dimension to filter by */
  dimension: TopSellingModifierFilterDimension
  /** The identifier of the entities to filter by; for now only a MenuItem guid */
  identifier?: InputMaybe<Scalars['ID']['input']>
}

export type TopSellingModifierFilterDimension =
  /** Filter by employee */
  | 'EMPLOYEE'
  /** Filter by item tag */
  | 'ITEM_TAG'
  /** Filter by menu */
  | 'MENU'
  /** Filter by menu group */
  | 'MENU_GROUP'
  /** Filter by the parent item of the modifier */
  | 'PARENT_ITEM'

/** The sort dimension */
export type TopSellingSortBy =
  /** Sort by quantity sold */
  | 'QUANTITY_SOLD'
  /** Sort by total net sales */
  | 'TOTAL_NET_SALES'

/** Total refund amount, i.e., the total amount of money refunded, including refunded tax and gratuities */
export type TotalRefundAmount = {
  __typename: 'TotalRefundAmount'
  /** Total refund amount, grouped by specified dimension */
  grouped?: Maybe<Array<GroupedTotalRefundAmount>>
  /** Total refund amount */
  totals: MoneyMetric
}

/** Total refund amount, i.e., the total amount of money refunded, including refunded tax and gratuities */
export type TotalRefundAmountGroupedArgs = {
  by: RefundsGroupingDimension
}

export type TrialEligibilityProbeResponse = {
  __typename: 'TrialEligibilityProbeResponse'
  status: TrialOperationStatus
  trialList?: Maybe<Array<TrialProbe>>
}

export type TrialEntity = {
  __typename: 'TrialEntity'
  allotment: Scalars['String']['output']
  allotmentUsage: Array<Scalars['String']['output']>
  auditLog: Array<Scalars['String']['output']>
  createdTime: Scalars['String']['output']
  ecommSku: Scalars['String']['output']
  entitledLocations: Array<Scalars['String']['output']>
  entityId: Scalars['String']['output']
  entityType: TrialEntityType
  expirationTime: Scalars['String']['output']
  featureGroupId: Scalars['String']['output']
  state: SubscriptionState
  trialId: Scalars['String']['output']
  userGuid: Scalars['String']['output']
  version: Scalars['String']['output']
}

export type TrialEntityType = 'BUSINESS' | 'LOCATION' | 'MANAGEMENT_GROUP'

export type TrialFeedbackRequest = {
  featureGroupId: Scalars['String']['input']
  feedback: Scalars['String']['input']
}

export type TrialFeedbackResponse = {
  __typename: 'TrialFeedbackResponse'
  status: TrialOperationStatus
}

export type TrialMutation = {
  __typename: 'TrialMutation'
  addTrialFeedback: TrialFeedbackResponse
  cancelTrial: TrialMutationResponse
  createTrial: TrialMutationResponse
  upsellTrial: TrialMutationResponse
}

export type TrialMutationAddTrialFeedbackArgs = {
  request: TrialFeedbackRequest
}

export type TrialMutationCancelTrialArgs = {
  request: CancelTrialRequest
}

export type TrialMutationCreateTrialArgs = {
  request: CreateTrialRequest
}

export type TrialMutationUpsellTrialArgs = {
  request: UpsellRequest
}

export type TrialMutationResponse = {
  __typename: 'TrialMutationResponse'
  status: TrialOperationStatus
  trial?: Maybe<TrialEntity>
}

export type TrialOperationStatus = 'FAIL' | 'SUCCESS'

export type TrialProbe = {
  __typename: 'TrialProbe'
  allotment: Scalars['String']['output']
  ecommSku: Scalars['String']['output']
  featureGroupId: Scalars['String']['output']
  permission?: Maybe<Scalars['Boolean']['output']>
  qualifyingLocations?: Maybe<Array<Scalars['String']['output']>>
  trialDurationDays: Scalars['String']['output']
}

export type TrialsQuery = {
  __typename: 'TrialsQuery'
  orderValidity: OrderValidityCheckResponse
  probe: TrialEligibilityProbeResponse
  trials: GetTrialsResponse
}

export type TrialsQueryOrderValidityArgs = {
  featureGroupId: Scalars['String']['input']
}

export type UiOptionInvalidEntity =
  | Menu
  | MenuGroup
  | MenuItem
  | SavedRevenueCenter

export type UiOptionInvalidSmartTax = {
  __typename: 'UIOptionInvalidSmartTax'
  invalidEntities: Array<UiOptionInvalidEntity>
}

export type UiOptionsMutation = {
  __typename: 'UIOptionsMutation'
  /** @deprecated Use updateSavedUIOptionV2 */
  updateSavedUIOption: MlmUiOption
  updateSavedUIOptionV2: UpdateSavedUiOptionResponse
}

export type UiOptionsMutationUpdateSavedUiOptionArgs = {
  input: UpdateMlmUiOptionInput
}

export type UiOptionsMutationUpdateSavedUiOptionV2Args = {
  input: UpdateMlmUiOptionInput
}

export type UiOptionsQuery = {
  __typename: 'UIOptionsQuery'
  savedUIOption: MlmUiOption
}

export type UiOptionsQuerySavedUiOptionArgs = {
  filter?: InputMaybe<MlmUiOptionVariantFilterInput>
}

export type UiOptionsTaxOptions = {
  __typename: 'UIOptionsTaxOptions'
  receiptTaxLabel?: Maybe<Scalars['String']['output']>
  splitTaxRatesEnabled?: Maybe<Scalars['Boolean']['output']>
}

export type UiOptionsTaxOptionsInput = {
  receiptTaxLabel?: InputMaybe<Scalars['String']['input']>
  splitTaxRatesEnabled?: InputMaybe<Scalars['Boolean']['input']>
}

export type UiOptionsTipSetup = {
  __typename: 'UIOptionsTipSetup'
  amounts?: Maybe<UiOptionsTippingAmounts>
  displayMode?: Maybe<UiOptionsTippingDisplayMode>
  percentages?: Maybe<UiOptionsTippingPercentages>
  thresholdAmount?: Maybe<Money>
}

export type UiOptionsTipSetupInput = {
  amounts?: InputMaybe<UiOptionsTippingAmountsInput>
  displayMode?: InputMaybe<UiOptionsTippingDisplayMode>
  percentages?: InputMaybe<UiOptionsTippingPercentagesInput>
  thresholdAmount?: InputMaybe<MoneyInput>
}

export type UiOptionsTippingAmounts = {
  __typename: 'UIOptionsTippingAmounts'
  amount1?: Maybe<Money>
  amount2?: Maybe<Money>
  amount3?: Maybe<Money>
}

export type UiOptionsTippingAmountsInput = {
  amount1?: InputMaybe<MoneyInput>
  amount2?: InputMaybe<MoneyInput>
  amount3?: InputMaybe<MoneyInput>
}

export type UiOptionsTippingDisplayMode =
  | 'DOLLARS'
  | 'DOLLARS_AND_PERCENTAGES'
  | 'PERCENTAGES'

export type UiOptionsTippingPercentages = {
  __typename: 'UIOptionsTippingPercentages'
  percentage1?: Maybe<Scalars['Float']['output']>
  percentage2?: Maybe<Scalars['Float']['output']>
  percentage3?: Maybe<Scalars['Float']['output']>
}

export type UiOptionsTippingPercentagesInput = {
  percentage1?: InputMaybe<Scalars['Float']['input']>
  percentage2?: InputMaybe<Scalars['Float']['input']>
  percentage3?: InputMaybe<Scalars['Float']['input']>
}

export type UiOptionsTippingRequireApproval = {
  __typename: 'UIOptionsTippingRequireApproval'
  enabled?: Maybe<Scalars['Boolean']['output']>
  minimumAmount?: Maybe<Money>
  minimumPercentage?: Maybe<Scalars['Float']['output']>
}

export type UiOptionsTippingRequireApprovalInput = {
  enabled?: InputMaybe<Scalars['Boolean']['input']>
  minimumAmount?: InputMaybe<MoneyInput>
  minimumPercentage?: InputMaybe<Scalars['Float']['input']>
}

/** UK invoice */
export type UkCustomerBillingInvoice = CustomerBillingInvoice & {
  __typename: 'UKCustomerBillingInvoice'
  billingAddress?: Maybe<Scalars['String']['output']>
  createdDate: Scalars['Date']['output']
  /**  Deduction from invoice  */
  deductions: CustomerBillingInvoiceDeductions
  dueDate: Scalars['Date']['output']
  id: Scalars['ID']['output']
  items: Array<UkCustomerBillingInvoiceItem>
  netsuiteId: Scalars['ID']['output']
  orderType?: Maybe<Scalars['String']['output']>
  restaurant?: Maybe<Restaurant>
  salesforceId: Scalars['ID']['output']
  /**  Shipping totals  */
  shipping: CustomerBillingInvoiceShipping
  shippingAddress?: Maybe<Scalars['String']['output']>
  status: CustomerBillingInvoiceStatus
  terms?: Maybe<Scalars['String']['output']>
  totals: CustomerBillingInvoiceTotals
  transactionDate: Scalars['Date']['output']
  type: CustomerBillingInvoiceType
}

/** UK invoice item */
export type UkCustomerBillingInvoiceItem = CustomerBillingInvoiceItem & {
  __typename: 'UKCustomerBillingInvoiceItem'
  amount: Money
  children: Array<UkCustomerBillingInvoiceItem>
  endDate: Scalars['Date']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  parentProduct?: Maybe<Scalars['String']['output']>
  quantity: Scalars['Int']['output']
  rate: Money
  startDate: Scalars['Date']['output']
  /**  Taxes amount for purchased items (amount * rate) */
  taxAmount: Money
  /**  Taxes amount for a single item  */
  taxRate: Money
}

/** USA invoice */
export type UsCustomerBillingInvoice = CustomerBillingInvoice & {
  __typename: 'USCustomerBillingInvoice'
  billingAddress?: Maybe<Scalars['String']['output']>
  createdDate: Scalars['Date']['output']
  /**  Deduction from invoice  */
  deductions: CustomerBillingInvoiceDeductions
  dueDate: Scalars['Date']['output']
  id: Scalars['ID']['output']
  items: Array<UsCustomerBillingInvoiceItem>
  netsuiteId: Scalars['ID']['output']
  orderType?: Maybe<Scalars['String']['output']>
  restaurant?: Maybe<Restaurant>
  salesforceId: Scalars['ID']['output']
  /**  Shipping totals  */
  shipping: CustomerBillingInvoiceShipping
  shippingAddress?: Maybe<Scalars['String']['output']>
  status: CustomerBillingInvoiceStatus
  terms?: Maybe<Scalars['String']['output']>
  totals: CustomerBillingInvoiceTotals
  transactionDate: Scalars['Date']['output']
  type: CustomerBillingInvoiceType
}

/** USA invoice item */
export type UsCustomerBillingInvoiceItem = CustomerBillingInvoiceItem & {
  __typename: 'USCustomerBillingInvoiceItem'
  amount: Money
  children: Array<UsCustomerBillingInvoiceItem>
  endDate: Scalars['Date']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  parentProduct?: Maybe<Scalars['String']['output']>
  quantity: Scalars['Int']['output']
  rate: Money
  startDate: Scalars['Date']['output']
  /**  Taxes amount for purchased items (amount * rate) */
  taxAmount: Money
  /**  Taxes amount for a single item  */
  taxRate: Money
}

export type UnableToCreateDepositOrder = Error & {
  __typename: 'UnableToCreateDepositOrder'
  message: Scalars['String']['output']
  status: ErrorCode
}

export type UnableToCreateGuest = Error & {
  __typename: 'UnableToCreateGuest'
  message: Scalars['String']['output']
  status: ErrorCode
}

export type UnableToFetchGuests = Error & {
  __typename: 'UnableToFetchGuests'
  message: Scalars['String']['output']
  status: ErrorCode
}

export type UnableToSendSms = Error & {
  __typename: 'UnableToSendSMS'
  message: Scalars['String']['output']
  status: ErrorCode
}

export type UnableToUpdateGuest = Error & {
  __typename: 'UnableToUpdateGuest'
  message: Scalars['String']['output']
  status: ErrorCode
}

export type Unauthorized = ShiftError & {
  __typename: 'Unauthorized'
  message?: Maybe<Scalars['String']['output']>
}

export type UnauthorizedAccessError = Errors & {
  __typename: 'UnauthorizedAccessError'
  message: Scalars['String']['output']
}

export type UnitOfMeasure = 'G' | 'KG' | 'LB' | 'NONE' | 'OZ'

export type UnknownError = ShiftError & {
  __typename: 'UnknownError'
  message?: Maybe<Scalars['String']['output']>
}

export type UnknownIdentity = Identity & {
  __typename: 'UnknownIdentity'
  id: Scalars['String']['output']
}

export type UnpublishedChangesFilterInput = {
  restaurantIds: Array<Scalars['ID']['input']>
}

export type UnpublishedConfig = {
  __typename: 'UnpublishedConfig'
  action?: Maybe<Action>
  entityType?: Maybe<ConfigType>
  id?: Maybe<Scalars['ID']['output']>
  lastModifiedBy?: Maybe<User>
  lastPublishJob?: Maybe<PublishJob>
  lastSavedTime?: Maybe<Scalars['String']['output']>
  managementSet?: Maybe<ManagementGroup>
  name?: Maybe<Scalars['String']['output']>
  restaurant?: Maybe<SavedRestaurantLocation>
}

export type UnpublishedConfigConnection = {
  __typename: 'UnpublishedConfigConnection'
  edges: Array<UnpublishedConfigEdge>
  pageInfo: PageInfo
}

export type UnpublishedConfigEdge = {
  __typename: 'UnpublishedConfigEdge'
  cursor: Scalars['String']['output']
  node: UnpublishedConfig
}

export type UnpublishedStatus = {
  __typename: 'UnpublishedStatus'
  entities: UnpublishedConfigConnection
  entityCount: Scalars['Int']['output']
  lastPublishedTime?: Maybe<Scalars['String']['output']>
  restaurantId: Scalars['ID']['output']
}

export type UnpublishedStatusEntitiesArgs = {
  pagination?: InputMaybe<ConnectionPaginationInput>
}

export type UnpublishedStatusConnection = {
  __typename: 'UnpublishedStatusConnection'
  edges: Array<UnpublishedStatusEdge>
  pageInfo: PageInfo
}

export type UnpublishedStatusEdge = {
  __typename: 'UnpublishedStatusEdge'
  cursor: Scalars['String']['output']
  node: UnpublishedStatus
}

export type UnsupportedTransition = Error & {
  __typename: 'UnsupportedTransition'
  message: Scalars['String']['output']
  status: ErrorCode
}

export type UpdateCashFlowTransactionRequest = {
  category: CashFlowTransactionCategory
  isExcludedFromPnl: Scalars['Boolean']['input']
  isInternalTransfer: Scalars['Boolean']['input']
  transactionId: Scalars['String']['input']
  version: Scalars['Int']['input']
}

export type UpdateDataExtensionSetInput = {
  id: Scalars['ID']['input']
  name?: InputMaybe<Scalars['String']['input']>
  overrides: Array<DataExtensionSetOverrideInput>
  payments: Array<PaymentDataExtensionInput>
  revenueCenters: Array<DataExtensionInput>
  revision: Scalars['Int']['input']
  salesCategories: Array<DataExtensionInput>
  services: Array<DataExtensionInput>
  target?: InputMaybe<Scalars['ID']['input']>
  taxRates: Array<DataExtensionInput>
}

export type UpdateDiningOptionInput = {
  archived?: InputMaybe<Scalars['Boolean']['input']>
  behavior?: InputMaybe<OrderDiningOptionBehavior>
  curbside?: InputMaybe<Scalars['Boolean']['input']>
  editabilityLevel?: InputMaybe<OrderConfigEditabilityLevel>
  editabilityReasonCode?: InputMaybe<Scalars['String']['input']>
  id: Scalars['ID']['input']
  isDefault?: InputMaybe<Scalars['Boolean']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  requiresSchedule?: InputMaybe<Scalars['Boolean']['input']>
  toastDeliveryServiceProvider?: InputMaybe<OrderDeliveryServicesProvider>
}

export type UpdateGfdConfigInput = {
  /** Current config target (represented as restaurant set guid) */
  currentTarget: Scalars['ID']['input']
  /** The display mode on the GFD. */
  displayMode: DisplayTheme
  /** Whether GFD is enabled or not. */
  enabled: Scalars['Boolean']['input']
  /** Whether to hide price from modifier item. */
  hideModifierPrices: Scalars['Boolean']['input']
  /** To enable/disable early loyalty check-in option for loyalty customers */
  isEarlyLoyaltyCheckIn: Scalars['Boolean']['input']
  /** Config id also known as masterId */
  multiLocationId: Scalars['ID']['input']
  /** User defined name for this config. */
  name?: InputMaybe<Scalars['String']['input']>
  /** New reassigned config target or same as currentTarget if unchanged (represented as restaurant set guid) */
  newTarget: Scalars['ID']['input']
  orderInProgressImage?: InputMaybe<GfdImageInput>
  /** New reassigned config owner (represented as restaurant set guid) */
  owner: Scalars['ID']['input']
  standByImage?: InputMaybe<GfdImageInput>
  /** Config entity guid */
  versionId: Scalars['ID']['input']
}

export type UpdateGuestRequest = {
  email?: InputMaybe<Scalars['String']['input']>
  firstName?: InputMaybe<Scalars['String']['input']>
  guestNotes?: InputMaybe<Scalars['String']['input']>
  guestTags?: InputMaybe<Array<Scalars['String']['input']>>
  lastName?: InputMaybe<Scalars['String']['input']>
  phoneNumber?: InputMaybe<Scalars['String']['input']>
}

export type UpdateItemFeedbackConfigError = {
  __typename: 'UpdateItemFeedbackConfigError'
  code: UpdateItemFeedbackConfigErrorCode
  message?: Maybe<Scalars['String']['output']>
}

export type UpdateItemFeedbackConfigErrorCode =
  | 'ConfigNotFound'
  | 'FailedToUpdateConfig'
  | 'ManagementSetGuidNotFound'

export type UpdateItemFeedbackConfigResponse =
  | ItemFeedbackConfig
  | UpdateItemFeedbackConfigError

export type UpdateKioskConfigInput = {
  brandingColor?: InputMaybe<Scalars['String']['input']>
  cashPaymentEnabled?: InputMaybe<Scalars['Boolean']['input']>
  cashPaymentFireBeforePaymentPreference?: InputMaybe<
    Scalars['Boolean']['input']
  >
  cashPaymentInstructionCopy?: InputMaybe<Scalars['String']['input']>
  dineInDiningOptionId?: InputMaybe<Scalars['ID']['input']>
  dineInKioskCustomerMessageSettingsId?: InputMaybe<Scalars['ID']['input']>
  diningOptionMode?: InputMaybe<DiningOptionMode>
  displayTheme?: InputMaybe<DisplayTheme>
  imagesEnabled?: InputMaybe<Scalars['Boolean']['input']>
  kioskGiftCardsEnabled?: InputMaybe<Scalars['Boolean']['input']>
  kioskServerGuid?: InputMaybe<Scalars['ID']['input']>
  lastUpdated?: InputMaybe<Scalars['String']['input']>
  logoMode?: InputMaybe<LogoMode>
  loyaltyPromptMode?: InputMaybe<LoyaltyPromptMode>
  orderBackgroundImageHeightWidthRatio?: InputMaybe<Scalars['Float']['input']>
  orderBackgroundImageImagePath?: InputMaybe<Scalars['String']['input']>
  serviceChargesEnabled?: InputMaybe<Scalars['Boolean']['input']>
  shouldHideLogo?: InputMaybe<Scalars['Boolean']['input']>
  shouldHideOutOfStockItems?: InputMaybe<Scalars['Boolean']['input']>
  shouldShowItemDescription?: InputMaybe<Scalars['Boolean']['input']>
  shouldShowNewKioskExperience?: InputMaybe<Scalars['Boolean']['input']>
  shouldShowOrderNumberOnConfirmationScreen?: InputMaybe<
    Scalars['Boolean']['input']
  >
  shouldShowTippingButtons?: InputMaybe<Scalars['Boolean']['input']>
  shouldUseMenuItemName?: InputMaybe<Scalars['Boolean']['input']>
  smallMenuCardsEnabled?: InputMaybe<Scalars['Boolean']['input']>
  specialRequestsEnabled?: InputMaybe<Scalars['Boolean']['input']>
  specialRequestsInstructionCopy?: InputMaybe<Scalars['String']['input']>
  takeOutDiningOptionId?: InputMaybe<Scalars['ID']['input']>
  takeOutKioskCustomerMessageSettingsId?: InputMaybe<Scalars['ID']['input']>
  textColor?: InputMaybe<Scalars['String']['input']>
  tipPercentage1?: InputMaybe<Scalars['Int']['input']>
  tipPercentage2?: InputMaybe<Scalars['Int']['input']>
  tipPercentage3?: InputMaybe<Scalars['Int']['input']>
  upsellsEnabled?: InputMaybe<Scalars['Boolean']['input']>
  voidReasonId?: InputMaybe<Scalars['ID']['input']>
  welcomeImageHeightWidthRatio?: InputMaybe<Scalars['Float']['input']>
  welcomeImageImagePath?: InputMaybe<Scalars['String']['input']>
  welcomeScreenMode?: InputMaybe<WelcomeScreenMode>
}

export type UpdateKioskConfigServiceChargeInput = {
  serviceChargeId: Scalars['ID']['input']
}

export type UpdateKioskCustomerMessageSettingsInput = {
  enableCustomMessage?: InputMaybe<Scalars['Boolean']['input']>
  fulfillmentText?: InputMaybe<Scalars['String']['input']>
  fulfillmentTextEnabled?: InputMaybe<Scalars['Boolean']['input']>
  id: Scalars['ID']['input']
  inputNumberText?: InputMaybe<Scalars['String']['input']>
  inputNumberTextEnabled?: InputMaybe<Scalars['Boolean']['input']>
  mode?: InputMaybe<MessageOptionMode>
  noInputText?: InputMaybe<Scalars['String']['input']>
  noInputTextEnabled?: InputMaybe<Scalars['Boolean']['input']>
  requiredGuestInformation?: InputMaybe<Scalars['Boolean']['input']>
  requiredGuestPhoneNumber?: InputMaybe<Scalars['Boolean']['input']>
}

export type UpdateKioskUpsellMenuItemInput = {
  groupGuid: Scalars['ID']['input']
  groupName: Scalars['String']['input']
  guid: Scalars['String']['input']
  name: Scalars['String']['input']
}

export type UpdateLogbookEntry = {
  categoryID: Scalars['ID']['input']
  content: Scalars['String']['input']
  entryID: Scalars['ID']['input']
  locationID: Scalars['ID']['input']
}

export type UpdateLogbookEntryInput = {
  categoryID: Scalars['ID']['input']
  content: LogbookContentInput
  entryID: Scalars['ID']['input']
  locationID: Scalars['ID']['input']
}

export type UpdateLogbookEntryReply = {
  content: Scalars['String']['input']
  entryID: Scalars['ID']['input']
  locationID: Scalars['ID']['input']
  replyID: Scalars['ID']['input']
}

export type UpdateLogbookEntryReplyInput = {
  content: LogbookContentInput
  entryID: Scalars['ID']['input']
  locationID: Scalars['ID']['input']
  replyID: Scalars['ID']['input']
}

export type UpdateMembershipInput = {
  address?: InputMaybe<MembershipAddressInput>
  cardGuid: Scalars['String']['input']
  customerNumber?: InputMaybe<Scalars['String']['input']>
  email: Scalars['String']['input']
  firstName: Scalars['String']['input']
  lastName?: InputMaybe<Scalars['String']['input']>
  phone: Scalars['String']['input']
}

export type UpdateMembershipResult = {
  __typename: 'UpdateMembershipResult'
  message?: Maybe<Scalars['String']['output']>
  status: Scalars['String']['output']
}

export type UpdateMenuItemPlateCost = {
  cost: MoneyInput
  id: Scalars['ID']['input']
  itemMultiLocationID: Scalars['String']['input']
  source?: PlateCostSource
}

export type UpdateMlmUiOptionInput = {
  /** MLM ID of the saved config */
  id: Scalars['ID']['input']
  /** A list of variants to update. Variants that are omitted will remain unchanged */
  variants: Array<UpdateMlmUiOptionVariantInput>
}

export type UpdateMlmUiOptionVariantInput = {
  additionalModifierGroups?: InputMaybe<AdditionalModifierGroupsInput>
  allowDeclareNegativeTips?: InputMaybe<Scalars['Boolean']['input']>
  allowScheduling?: InputMaybe<Scalars['Boolean']['input']>
  askForPartySize?: InputMaybe<Scalars['Boolean']['input']>
  autoSwitchUser?: InputMaybe<Scalars['Boolean']['input']>
  cashCloseoutDiscrepancyMax?: InputMaybe<MoneyInput>
  cashCloseoutDiscrepancyWarning?: InputMaybe<MoneyInput>
  cashDrawerLockdown?: InputMaybe<Scalars['Boolean']['input']>
  closeOnPrint?: InputMaybe<Scalars['Boolean']['input']>
  collapseModifierPrices?: InputMaybe<Scalars['Boolean']['input']>
  collectCashInDrawer?: InputMaybe<Scalars['Boolean']['input']>
  combineItems?: InputMaybe<Scalars['Boolean']['input']>
  consolidateDiscounts?: InputMaybe<Scalars['Boolean']['input']>
  consolidateModifiers?: InputMaybe<Scalars['Boolean']['input']>
  declaredCashTipsMinimum?: InputMaybe<Scalars['Float']['input']>
  defaultsDisplayMode?: InputMaybe<Scalars['Boolean']['input']>
  enableModifierOrderingPriority?: InputMaybe<Scalars['Boolean']['input']>
  fastCash?: InputMaybe<Scalars['Boolean']['input']>
  /** ConfigField ID */
  field: Scalars['ID']['input']
  intraModifierGroupSortOrder?: InputMaybe<IntraModifierGroupSortOrder>
  loginAdditionalText?: InputMaybe<Scalars['String']['input']>
  manualEntryForScaleEnabled?: InputMaybe<Scalars['Boolean']['input']>
  maskPasscode?: InputMaybe<Scalars['Boolean']['input']>
  modifierDisplayMode?: InputMaybe<ModifierDisplayMode>
  mustDeclareCashTips?: InputMaybe<Scalars['Boolean']['input']>
  paymentUserType?: InputMaybe<PaymentUserTypeMode>
  printClockSlip?: InputMaybe<Scalars['Boolean']['input']>
  promptForDiningOption?: InputMaybe<Scalars['Boolean']['input']>
  promptForEditOthersTable?: InputMaybe<Scalars['Boolean']['input']>
  promptForTab?: InputMaybe<Scalars['Boolean']['input']>
  scheduledOrderMaxDays?: InputMaybe<Scalars['Int']['input']>
  searchPromoCode?: InputMaybe<Scalars['Boolean']['input']>
  sendButtons?: InputMaybe<Array<PosButton>>
  sendChecksAfterPaymentOnly?: InputMaybe<Scalars['Boolean']['input']>
  shiftReviewCollectAndTipOutMode?: InputMaybe<ShiftReviewCollectAndTipOutMode>
  shiftReviewRequired?: InputMaybe<Scalars['Boolean']['input']>
  showSentTimestamp?: InputMaybe<Scalars['Boolean']['input']>
  smartTax?: InputMaybe<Scalars['Boolean']['input']>
  ssids?: InputMaybe<Array<Scalars['String']['input']>>
  strictMode?: InputMaybe<Scalars['Boolean']['input']>
  taxOptions?: InputMaybe<UiOptionsTaxOptionsInput>
  timeClockDisplayForClockInScreenEnabled?: InputMaybe<
    Scalars['Boolean']['input']
  >
  timeEntryRoundingScheme?: InputMaybe<TimeEntryRoundingScheme>
  tipOutGratuity?: InputMaybe<Scalars['Boolean']['input']>
  tipOutNonCash?: InputMaybe<Scalars['Boolean']['input']>
  tipPreTax?: InputMaybe<Scalars['Boolean']['input']>
  tipSetup?: InputMaybe<UiOptionsTipSetupInput>
  tippingRequireApproval?: InputMaybe<UiOptionsTippingRequireApprovalInput>
  tomorrowModeEnabled?: InputMaybe<Scalars['Boolean']['input']>
  tomorrowModeScheduledTime?: InputMaybe<Scalars['String']['input']>
  trackErrorCorrection?: InputMaybe<Scalars['Boolean']['input']>
  /** A list of version IDs of the entities */
  versions: Array<Scalars['ID']['input']>
}

export type UpdateReasonInput = {
  description?: InputMaybe<Scalars['String']['input']>
  isActive?: InputMaybe<Scalars['Boolean']['input']>
  name?: InputMaybe<Scalars['String']['input']>
}

export type UpdateReasonOrdinalInput = {
  id: Scalars['ID']['input']
  ordinal: Scalars['Int']['input']
}

export type UpdateReservationRequest = {
  bookingNotes?: InputMaybe<Scalars['String']['input']>
  expectedEndTime?: InputMaybe<Scalars['DateTime']['input']>
  expectedStartTime?: InputMaybe<Scalars['DateTime']['input']>
  guest?: InputMaybe<Scalars['ID']['input']>
  hasPinnedMessageThread?: InputMaybe<Scalars['Boolean']['input']>
  partySize?: InputMaybe<Scalars['Int']['input']>
  requestedServiceAreaGroups?: InputMaybe<Array<Scalars['ID']['input']>>
  serviceAreaGuids?: InputMaybe<Array<Scalars['ID']['input']>>
  specialOccasion?: InputMaybe<SpecialOccasion>
  tableGuids?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type UpdateReservationResponse =
  | BookingNotFound
  | GuestNotFound
  | InvalidEmail
  | InvalidPhoneNumber
  | RestaurantNotFound
  | ServiceAreaNotFound
  | TableBlocked
  | TableNotFound
  | UnableToCreateGuest
  | UnableToSendSms
  | UpdateReservationResponseSuccess

export type UpdateReservationResponseSuccess = {
  __typename: 'UpdateReservationResponseSuccess'
  booking: Booking
}

export type UpdateSavedUiOptionResponse = InvalidUiOption | MlmUiOption

export type UpdateShiftFailure = ShiftError & {
  __typename: 'UpdateShiftFailure'
  message?: Maybe<Scalars['String']['output']>
}

export type UpdateShiftInput = {
  employee: RestaurantUserInput
  id: Scalars['ID']['input']
  job?: InputMaybe<JobInput>
  restaurant: RestaurantInput
  timeClock: TimeClockInput
  tips?: InputMaybe<TipInput>
}

export type UpdateShiftResult =
  | BreakConfigDependencyFailure
  | BuildShiftFailure
  | FinishedEmployeeShift
  | InProgressEmployeeShift
  | InvalidBreakConfig
  | InvalidBreaks
  | InvalidJob
  | InvalidUpdatedTime
  | JobsRepositoryDependencyFailure
  | LoadShiftsFailure
  | LocateShiftFailure
  | MissingJob
  | MissingTimeClock
  | OverlappingBreaks
  | OverlappingShiftExists
  | ReadFailure
  | RestaurantRepositoryDependencyFailure
  | SaveFailure
  | ShiftNotFound
  | Unauthorized
  | UnknownError

export type UpdateShiftTimesInput = {
  /** The updated clock in time for the shift */
  clockInTime: Scalars['DateTime']['input']
  /** The updated clock out time for the shift */
  clockOutTime?: InputMaybe<Scalars['DateTime']['input']>
}

export type UpdateShiftTimesResult =
  | FinishedEmployeeShift
  | InProgressEmployeeShift
  | InvalidUpdatedTime
  | LoadShiftsFailure
  | LocateShiftFailure
  | OverlappingShiftExists
  | RestaurantRepositoryDependencyFailure
  | SaveFailure
  | ShiftNotFound
  | Unauthorized

export type UpdateSubscriptionAutoRenewalInput = {
  enableAutoRenewal: Scalars['Boolean']['input']
  subscriptionSalesforceId: Scalars['ID']['input']
}

export type UpdateTipAmountInput = {
  checkId: Scalars['ID']['input']
  orderId: Scalars['ID']['input']
  paymentId: Scalars['ID']['input']
  tipAmount: MoneyInput
}

export type UpdateToastPayConfigError = {
  __typename: 'UpdateToastPayConfigError'
  code: UpdateToastPayConfigErrorCode
  message?: Maybe<Scalars['String']['output']>
}

export type UpdateToastPayConfigErrorCode =
  | 'ConfigNotFound'
  | 'FailedToSaveConfig'
  | 'ManagementSetGuidNotFound'
  | 'ManagementSetRetrievalFailed'
  | 'RestaurantSetNotFound'

export type UpdateToastPayConfigResponse =
  | ToastPayConfig
  | UpdateToastPayConfigError

export type UpdateUserPreferredLocalResponse = {
  __typename: 'UpdateUserPreferredLocalResponse'
  message: Scalars['String']['output']
  success: Scalars['Boolean']['output']
}

export type UpdateUserQuickActionInput = {
  id: Scalars['ID']['input']
  order?: InputMaybe<Scalars['Int']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type UpgradeItemFeedbackConfigResponse =
  | CreateItemFeedbackConfigError
  | ItemFeedbackConfig
  | ItemFeedbackConfigAlreadyExists

export type UploadImageResponse = {
  __typename: 'UploadImageResponse'
  url: Scalars['String']['output']
}

export type UpsellRequest = {
  featureGroupId: Scalars['String']['input']
}

/** Federated customer user type */
export type User = {
  __typename: 'User'
  chosenName?: Maybe<Scalars['String']['output']>
  email?: Maybe<Scalars['String']['output']>
  firstName?: Maybe<Scalars['String']['output']>
  hasBackendAccess?: Maybe<Scalars['Boolean']['output']>
  hasToastRole?: Maybe<Scalars['Boolean']['output']>
  /** The identifier provided by Toast */
  id: Scalars['ID']['output']
  imagePath?: Maybe<Scalars['String']['output']>
  invitationDate?: Maybe<Scalars['Date']['output']>
  lastName?: Maybe<Scalars['String']['output']>
  phoneNumber?: Maybe<Scalars['String']['output']>
}

export type UserFeatureOptIn = {
  __typename: 'UserFeatureOptIn'
  deferToDate?: Maybe<Scalars['String']['output']>
  featureFlag?: Maybe<Scalars['Boolean']['output']>
  updatedBy?: Maybe<Scalars['String']['output']>
  updatedWhen?: Maybe<Scalars['String']['output']>
  value: Scalars['Boolean']['output']
}

export type UserFeatureOptInsResponse = {
  __typename: 'UserFeatureOptInsResponse'
  key?: Maybe<Scalars['String']['output']>
  value?: Maybe<UserFeatureOptIn>
}

export type UserIdentity = Identity & {
  __typename: 'UserIdentity'
  id: Scalars['String']['output']
  user: User
}

export type UserInteractionResponse = {
  __typename: 'UserInteractionResponse'
  items: Array<UserIteraction>
  totalHits: Scalars['Int']['output']
}

export type UserInterface = {
  createdDate: Scalars['Date']['output']
  deletedDate?: Maybe<Scalars['Date']['output']>
  id: Scalars['ID']['output']
  identities: Array<IdmIdentity>
  name: IdmName
  updatedDate?: Maybe<Scalars['Date']['output']>
  username: IdmUsername
  version: Scalars['Int']['output']
}

export type UserIteraction = {
  __typename: 'UserIteraction'
  interactionType: InteractionType
  itemId: Scalars['String']['output']
  searchDataSource: SearchDataSource
  searchTerm: Scalars['String']['output']
  timestamp?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
}

export type UserIteractionMutationResponse = ErrorResponse | UserIteraction

export type UserPreferencesMutation = {
  __typename: 'UserPreferencesMutation'
  acceptAiChatTermsOfUse: AiTermsOfUseDefaultResponse
  addUserQuickAction: UserQuickAction
  bulkUpdateUserQuickActions: Array<UserQuickAction>
  deferFeatureOptIn?: Maybe<Scalars['Boolean']['output']>
  deleteUserQuickAction: DeleteUserQuickActionResponse
  optInToFeature?: Maybe<Scalars['Boolean']['output']>
  optOutToFeature?: Maybe<Scalars['Boolean']['output']>
  updateUserPreferredLocale: UpdateUserPreferredLocalResponse
}

export type UserPreferencesMutationAddUserQuickActionArgs = {
  userQuickActionInput: UserQuickActionInput
}

export type UserPreferencesMutationBulkUpdateUserQuickActionsArgs = {
  toBeCreated: Array<CreateUserQuickActionInput>
  toBeDeleted: Array<Scalars['ID']['input']>
  toBeUpdated: Array<UpdateUserQuickActionInput>
}

export type UserPreferencesMutationDeferFeatureOptInArgs = {
  deferToDate: Scalars['String']['input']
  feature: Scalars['String']['input']
}

export type UserPreferencesMutationDeleteUserQuickActionArgs = {
  quickActionId: Scalars['ID']['input']
}

export type UserPreferencesMutationOptInToFeatureArgs = {
  feature: Scalars['String']['input']
}

export type UserPreferencesMutationOptOutToFeatureArgs = {
  feature: Scalars['String']['input']
}

export type UserPreferencesMutationUpdateUserPreferredLocaleArgs = {
  locale: Scalars['String']['input']
}

export type UserPreferencesQuery = {
  __typename: 'UserPreferencesQuery'
  aiChatTermsOfUse: AiChatTermsOfUseResponse
  userFeatureOptIns: Array<UserFeatureOptInsResponse>
  userPreferredLocale: Scalars['String']['output']
  userQuickActions: Array<UserQuickAction>
}

export type UserQuickAction = {
  __typename: 'UserQuickAction'
  entityId: Scalars['String']['output']
  entityType: UserQuickActionEntityType
  id: Scalars['ID']['output']
  navigationPage?: Maybe<NavigationPage>
  order?: Maybe<Scalars['Int']['output']>
  params?: Maybe<Array<UserQuickActionParam>>
  title?: Maybe<Scalars['String']['output']>
  updatedAt: Scalars['String']['output']
}

export type UserQuickActionEntityType = 'navigationPage' | 'widget'

export type UserQuickActionInput = {
  entityId: Scalars['String']['input']
  entityType: UserQuickActionEntityType
  order?: InputMaybe<Scalars['Int']['input']>
  params?: InputMaybe<Array<UserQuickActionParamInput>>
  title?: InputMaybe<Scalars['String']['input']>
}

export type UserQuickActionParam = {
  __typename: 'UserQuickActionParam'
  key: Scalars['String']['output']
  value: Scalars['String']['output']
}

export type UserQuickActionParamInput = {
  key: Scalars['String']['input']
  value: Scalars['String']['input']
}

export type UserSearch = {
  __typename: 'UserSearch'
  restaurantGuid: Scalars['String']['output']
  term: Scalars['String']['output']
  timestamp: Scalars['String']['output']
}

export type VersionInput = {
  entityToCopyFromVersionId: Scalars['ID']['input']
  multiLocationId: Scalars['String']['input']
  newVersions: Array<TargetAndOwnerInput>
}

export type VersionResolution =
  /** Returns all versions of the config */
  | 'ALL'
  /** Returns the highest resolved version of the config that is a descendant or ancestor of targetRestaurantSet */
  | 'HIGHEST_RESTAURANT_SET'
  /** Returns the most resolved version of the config in relation to the external-restaurant-set-id header */
  | 'RESTAURANT'

/** Total void amount, i.e., the total amount of money voided */
export type VoidAmount = {
  __typename: 'VoidAmount'
  /** Total void amount, grouped by specified dimension */
  grouped?: Maybe<Array<GroupedVoidAmount>>
  /** Total void amount */
  totals: MoneyMetric
}

/** Total void amount, i.e., the total amount of money voided */
export type VoidAmountGroupedArgs = {
  by: VoidGroupingDimension
}

/** Total void amount as percentage of net sales */
export type VoidAmountAsPercentageOfNetSales = {
  __typename: 'VoidAmountAsPercentageOfNetSales'
  /** Total void amount as percentage of net sales, grouped by specified dimension */
  grouped?: Maybe<Array<GroupedVoidAmountAsPercentageOfNetSales>>
  /** Total void amount as percentage of net sales */
  totals?: Maybe<PercentageMetric>
}

/** Total void amount as percentage of net sales */
export type VoidAmountAsPercentageOfNetSalesGroupedArgs = {
  by: VoidAmountAsPercentageOfNetSalesGroupingDimension
}

/** The grouping dimensions available for void amount as a percentge of net sales */
export type VoidAmountAsPercentageOfNetSalesGroupingDimension = 'EMPLOYEE'

/** A filter for Void metrics */
export type VoidFilter = {
  /** The dimension to filter by */
  dimension: VoidGroupingDimension
  /** The identifier of the entity to filter by; a RestaurantUser, VoidReason, or MenuItem guid */
  identifier: Scalars['ID']['input']
}

/** A union of the group entities that can be returned for void metrics */
export type VoidGroupEntity = Check | MenuItem | RestaurantUser | VoidReason

/** The grouping dimensions available for void metrics */
export type VoidGroupingDimension =
  | 'APPROVER'
  | 'CHECK'
  | 'EMPLOYEE'
  | 'ITEM'
  | 'REASON'

/** Reference to a VoidReason entity */
export type VoidReason = {
  __typename: 'VoidReason'
  id: Scalars['ID']['output']
}

/** Total count of voided items, i.e., the number of items that have been voided */
export type VoidedItemsCount = {
  __typename: 'VoidedItemsCount'
  /** Total count of voided items, grouped by specified dimension */
  grouped: Array<GroupedVoidedItemsCount>
  /** Total count of voided items */
  totals: DecimalMetric
}

/** Total count of voided items, i.e., the number of items that have been voided */
export type VoidedItemsCountGroupedArgs = {
  by: VoidGroupingDimension
}

export type WageFrequency = 'HOURLY' | 'SALARY' | 'UNSPECIFIED'

export type WaitlistAlgorithmType = 'LINEAR' | 'SMART'

export type WeekOperatingSchedule = {
  __typename: 'WeekOperatingSchedule'
  friday?: Maybe<DayOperatingSchedule>
  monday?: Maybe<DayOperatingSchedule>
  saturday?: Maybe<DayOperatingSchedule>
  sunday?: Maybe<DayOperatingSchedule>
  thursday?: Maybe<DayOperatingSchedule>
  tuesday?: Maybe<DayOperatingSchedule>
  wednesday?: Maybe<DayOperatingSchedule>
}

export type WelcomeScreenMode = 'FULLSCREEN' | 'STYLIZED'

export type Workflow = {
  __typename: 'Workflow'
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  isSaved: Scalars['Boolean']['output']
  name: Scalars['String']['output']
  steps: Array<Step>
  updatedAt: Scalars['DateTime']['output']
  workflowSource?: Maybe<SousChefWorkflowSource>
}

export type InitialDataQueryVariables = Exact<{ [key: string]: never }>

export type InitialDataQuery = {
  __typename: 'Query'
  userPreferences: {
    __typename: 'UserPreferencesQuery'
    userPreferredLocale: string
    userFeatureOptIns: Array<{
      __typename: 'UserFeatureOptInsResponse'
      key?: string | null
      value?: {
        __typename: 'UserFeatureOptIn'
        value: boolean
        deferToDate?: string | null
      } | null
    }>
  }
  permissions: { __typename: 'Permissions'; financialAccounts: boolean }
}

export const InitialDataDocument = {
  __meta__: { hash: 'e1dce2013c005042061a6815d75b89d536b643ce' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'initialData' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userPreferences' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userFeatureOptIns' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: '__typename' }
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'value' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: '__typename' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'value' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'deferToDate' }
                            }
                          ]
                        }
                      }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userPreferredLocale' }
                }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'permissions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'financialAccounts' }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<InitialDataQuery, InitialDataQueryVariables>

export type ResolverTypeWrapper<T> = Promise<T> | T

export type ReferenceResolver<TResult, TReference, TContext> = (
  reference: TReference,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult

type ScalarCheck<T, S> = S extends true ? T : NullableCheck<T, S>
type NullableCheck<T, S> = Maybe<T> extends T
  ? Maybe<ListCheck<NonNullable<T>, S>>
  : ListCheck<T, S>
type ListCheck<T, S> = T extends (infer U)[]
  ? NullableCheck<U, S>[]
  : GraphQLRecursivePick<T, S>
export type GraphQLRecursivePick<T, S> = {
  [K in keyof T & keyof S]: ScalarCheck<T[K], S[K]>
}

export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>
}
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> =
  | ResolverFn<TResult, TParent, TContext, TArgs>
  | ResolverWithResolve<TResult, TParent, TContext, TArgs>

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>

export interface SubscriptionSubscriberObject<
  TResult,
  TKey extends string,
  TParent,
  TContext,
  TArgs
> {
  subscribe: SubscriptionSubscribeFn<
    { [key in TKey]: TResult },
    TParent,
    TContext,
    TArgs
  >
  resolve?: SubscriptionResolveFn<
    TResult,
    { [key in TKey]: TResult },
    TContext,
    TArgs
  >
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>
}

export type SubscriptionObject<
  TResult,
  TKey extends string,
  TParent,
  TContext,
  TArgs
> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>

export type SubscriptionResolver<
  TResult,
  TKey extends string,
  TParent = {},
  TContext = {},
  TArgs = {}
> =
  | ((
      ...args: any[]
    ) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (
  obj: T,
  context: TContext,
  info: GraphQLResolveInfo
) => boolean | Promise<boolean>

export type NextResolverFn<T> = () => Promise<T>

export type DirectiveResolverFn<
  TResult = {},
  TParent = {},
  TContext = {},
  TArgs = {}
> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>

/** Mapping of union types */
export type ResolversUnionTypes<_RefType extends Record<string, unknown>> = {
  AllBookingsResponse:
    | AllBookingsResponseSuccess
    | InternalError
    | RestaurantNotFound
    | UnableToFetchGuests
  AllEmploymentStatuses:
    | ActiveEmploymentStatus
    | ApplicantEmploymentStatus
    | DeletedEmploymentStatus
    | DemoEmploymentStatus
    | ImplementationEmploymentStatus
    | LeaveOfAbsenceEmploymentStatus
    | NeverEmployedEmploymentStatus
    | PendingEmploymentStatus
    | RehiredEmploymentStatus
    | SeasonalEmploymentStatus
    | TerminatedEmploymentStatus
  AvailabilitiesResponse:
    | (Omit<AvailabilitiesResponseSuccess, 'restaurantAvailability'> & {
        restaurantAvailability?: Maybe<_RefType['RestaurantAvailabilityInfo']>
      })
    | BookingNotFound
    | RestaurantNotFound
  BlockConfigsResponse: BlockConfigsSuccess
  CancelReservationResponse:
    | BookingNotFound
    | CancelReservationResponseSuccess
    | ErrorRefundingDeposit
    | InternalError
    | RestaurantNotFound
    | UnsupportedTransition
  CreateBlockResponse: CreateBlockSuccess | RestaurantNotFound
  CreateItemFeedbackConfigResponse:
    | CreateItemFeedbackConfigError
    | ItemFeedbackConfig
    | ItemFeedbackConfigAlreadyExists
  CreateReservationResponse:
    | BookingRequiresDepositData
    | CreateReservationResponseSuccess
    | DepositAmountChanged
    | DepositRuleNotMatching
    | InvalidEmail
    | InvalidGuest
    | InvalidPhoneNumber
    | RestaurantNotFound
    | ServiceAreaNotFound
    | TableBlocked
    | TableNotFound
    | UnableToCreateDepositOrder
    | UnableToCreateGuest
    | UnableToSendSms
  CreateShiftResult:
    | BreakConfigDependencyFailure
    | BuildShiftFailure
    | EmployeeRepositoryDependencyFailure
    | EntitlementsRepositoryDependencyFailure
    | (Omit<FinishedEmployeeShift, 'timeClock' | 'tips'> & {
        timeClock?: Maybe<_RefType['EmployeeTime']>
        tips?: Maybe<_RefType['EmployeeTips']>
      })
    | (Omit<InProgressEmployeeShift, 'timeClock' | 'tips'> & {
        timeClock?: Maybe<_RefType['EmployeeTime']>
        tips?: Maybe<_RefType['EmployeeTips']>
      })
    | InvalidBreakConfig
    | InvalidBreaks
    | InvalidJob
    | InvalidUpdatedTime
    | JobsRepositoryDependencyFailure
    | LoadShiftsFailure
    | LocateShiftFailure
    | MissingJob
    | MissingTimeClock
    | OverlappingBreaks
    | OverlappingShiftExists
    | PublishRepositoryDependencyFailure
    | ReadFailure
    | RestaurantRepositoryDependencyFailure
    | SaveFailure
    | ShiftCheckedResultRepositoryDependencyFailure
    | ShiftNotFound
    | Unauthorized
    | UnknownError
  CreateToastPayConfigResponse: CreateToastPayConfigError | ToastPayConfig
  CurrentBookingUsageResponse:
    | CurrentBookingUsageResponseSuccess
    | RestaurantNotFound
  DeletedShiftResult:
    | (Omit<FinishedEmployeeShift, 'timeClock' | 'tips'> & {
        timeClock?: Maybe<_RefType['EmployeeTime']>
        tips?: Maybe<_RefType['EmployeeTips']>
      })
    | (Omit<InProgressEmployeeShift, 'timeClock' | 'tips'> & {
        timeClock?: Maybe<_RefType['EmployeeTime']>
        tips?: Maybe<_RefType['EmployeeTips']>
      })
    | LoadShiftsFailure
    | LocateShiftFailure
    | SaveFailure
    | ShiftNotFound
    | Unauthorized
  DepositStrategy: DepositStrategyByBooking | DepositStrategyByPartySize
  DepositStrategyAvailabilityInfo:
    | DepositStrategyAvailabilityInfoByBooking
    | DepositStrategyAvailabilityInfoByPartySize
  DiscountGroupEntity:
    | (Omit<Check, 'guestFeedback' | 'selections'> & {
        guestFeedback: Array<_RefType['GuestFeedbackInfo']>
        selections: Array<_RefType['OrdersSelection']>
      })
    | RestaurantUser
  GroupEntity: RestaurantUser
  GuestCreationResponse:
    | GuestAlreadyExists
    | GuestCreationResponseSuccess
    | InvalidPhoneNumber
    | RestaurantNotFound
    | UnableToCreateGuest
  GuestFeedbackGenerateResponse:
    | GeneratedGuestFeedbackAiResponse
    | GuestFeedbackGenerateResponseError
  GuestListResponse: GuestListResponseSuccess | GuestNotFound
  GuestUpdateResponse:
    | GuestAlreadyExists
    | GuestNotFound
    | GuestUpdateResponseSuccess
    | InvalidPhoneNumber
    | RestaurantNotFound
    | UnableToUpdateGuest
  ItemFeedbackConfigResponse: ItemFeedbackConfig | ItemFeedbackConfigError
  JobResponse:
    | InvalidUuidError
    | JobError
    | JobNotFoundError
    | JobV2
    | UnauthorizedAccessError
  JobsBatchResponse:
    | JobError
    | JobsBatchSizeError
    | JobsNotFoundBatchError
    | JobsV2
    | NoJobIdsProvidedBatchError
    | UnauthorizedAccessError
  JobsLegacyBatchResponse:
    | JobError
    | JobMappings
    | JobsBatchSizeError
    | JobsNotFoundBatchError
    | NoJobIdsProvidedBatchError
    | UnauthorizedAccessError
  JobsResponse:
    | CorruptPageTokenError
    | JobError
    | JobsFilterMissingError
    | JobsPaginatedResult
    | UnauthorizedAccessError
  LaborCostGroupEntity: Job | RestaurantUser
  LogbookEntrySource: User
  ManagerClockOutResult:
    | ClockOutFailure
    | (Omit<FinishedEmployeeShift, 'timeClock' | 'tips'> & {
        timeClock?: Maybe<_RefType['EmployeeTime']>
        tips?: Maybe<_RefType['EmployeeTips']>
      })
    | (Omit<InProgressEmployeeShift, 'timeClock' | 'tips'> & {
        timeClock?: Maybe<_RefType['EmployeeTime']>
        tips?: Maybe<_RefType['EmployeeTips']>
      })
    | InvalidUpdatedTime
    | LocateShiftFailure
    | MissingTimeClock
    | RestaurantRepositoryDependencyFailure
    | SaveFailure
    | ShiftAlreadyClockedOut
    | ShiftNotFound
    | Unauthorized
  MgmtRestaurantInformationResponse:
    | (Omit<RestaurantInformationResponseSuccess, 'response'> & {
        response: _RefType['MgmtRestaurantInformation']
      })
    | RestaurantNotFound
  MostRecentImportGuidResponse:
    | InternalError
    | MostRecentImportGuidResponseSuccess
    | NoPreviousImports
  NavigationMenuItem: NavigationHeader | NavigationLink
  NetworkStatusResponse: NetworkStatusData | NetworkStatusError
  OnboardingStepAction:
    | OnboardingLegacyDynamicLinkAction
    | OnboardingLinkAction
    | OnboardingScheduleAppointmentLinkAction
  PaymentDataExtension:
    | (Omit<AlternatePaymentDataExtension, 'alternatePaymentType'> & {
        alternatePaymentType: _RefType['SavedAlternatePaymentType']
      })
    | StaticPaymentDataExtension
  ProcessLegacyJobResponse:
    | InvalidCustomerUuidError
    | InvalidRestaurantIdError
    | JobError
    | ProcessLegacyJob
  PublishedItemFeedbackConfigResponse:
    | PublishedItemFeedbackConfig
    | PublishedItemFeedbackConfigError
  PublishedToastPayConfigResponse: PublishedToastPayConfigError | ToastPayConfig
  RestaurantGroupOrLocation:
    | RestaurantGroup
    | RestaurantLocation
    | SavedRestaurantLocation
  SavePackagingConfigResponse:
    | (Omit<OutdatedPackagingConfigError, 'currentConfig'> & {
        currentConfig: _RefType['SavedPackagingConfig']
      })
    | PackagingComplianceError
    | (Omit<SavedPackagingConfig, 'items' | 'overrides'> & {
        items: Array<_RefType['PackagingItem']>
        overrides: Array<_RefType['PackagingConfigOverride']>
      })
  SavedToastPayConfigResponse: SavedToastPayConfigError | ToastPayConfig
  SearchEntity:
    | (Omit<
        MenuItem,
        | 'ancestors'
        | 'modifierGroups'
        | 'ownerRestaurantSet'
        | 'pricing'
        | 'targetRestaurantSet'
      > & {
        ancestors: Array<_RefType['EntityAncestorResponse']>
        modifierGroups: Array<_RefType['ModifierGroup']>
        ownerRestaurantSet?: Maybe<_RefType['RestaurantSet']>
        pricing?: Maybe<_RefType['MenuItemPricing']>
        targetRestaurantSet: _RefType['RestaurantSet']
      })
    | ToastCentralArticle
    | ToastNavigationConfig
    | User
  SousChefFilter:
    | SousChefDateRangeFilter
    | SousChefDayOfWeekFilter
    | SousChefDayPartFilter
    | SousChefDiningOptionFilter
    | SousChefEmployeeFilter
    | SousChefHoursOfDayFilter
    | SousChefIntervalFilter
    | SousChefMenuFilter
    | SousChefMenuGroupFilter
    | SousChefOrderSourceFilter
    | SousChefRevenueCenterFilter
    | SousChefSalesCategoryFilter
    | SousChefServiceAreaFilter
  SousChefWorkflowNode:
    | SousChefAnalysisNode
    | SousChefDataNode
    | SousChefFixedResponseNode
  ToastPayPaymentNotificationConfigResponse:
    | ToastPayPaymentNotificationConfig
    | ToastPayPaymentNotificationConfigError
  ToastPayRestaurantLogoResponse:
    | ToastPayRestaurantLogo
    | ToastPayRestaurantLogoError
  ToastPayTipSelectionsConfigResponse:
    | ToastPayTipSelectionsConfig
    | ToastPayTipSelectionsError
  UIOptionInvalidEntity:
    | (Omit<
        Menu,
        | 'channelMenuEntities'
        | 'menuGroups'
        | 'ownerRestaurantSet'
        | 'targetRestaurantSet'
      > & {
        channelMenuEntities?: Maybe<Array<_RefType['ChannelMenuEntity']>>
        menuGroups?: Maybe<Array<_RefType['MenuGroup']>>
        ownerRestaurantSet?: Maybe<_RefType['RestaurantSet']>
        targetRestaurantSet: _RefType['RestaurantSet']
      })
    | (Omit<
        MenuGroup,
        | 'menuGroups'
        | 'menuItems'
        | 'modifierGroups'
        | 'ownerRestaurantSet'
        | 'targetRestaurantSet'
      > & {
        menuGroups?: Maybe<Array<_RefType['MenuGroup']>>
        menuItems: Array<_RefType['MenuItem']>
        modifierGroups: Array<_RefType['ModifierGroup']>
        ownerRestaurantSet?: Maybe<_RefType['RestaurantSet']>
        targetRestaurantSet: _RefType['RestaurantSet']
      })
    | (Omit<
        MenuItem,
        | 'ancestors'
        | 'modifierGroups'
        | 'ownerRestaurantSet'
        | 'pricing'
        | 'targetRestaurantSet'
      > & {
        ancestors: Array<_RefType['EntityAncestorResponse']>
        modifierGroups: Array<_RefType['ModifierGroup']>
        ownerRestaurantSet?: Maybe<_RefType['RestaurantSet']>
        pricing?: Maybe<_RefType['MenuItemPricing']>
        targetRestaurantSet: _RefType['RestaurantSet']
      })
    | (Omit<
        SavedRevenueCenter,
        'ownerRestaurantSet' | 'targetRestaurantSet'
      > & {
        ownerRestaurantSet: _RefType['RestaurantSet']
        targetRestaurantSet: _RefType['RestaurantSet']
      })
  UpdateItemFeedbackConfigResponse:
    | ItemFeedbackConfig
    | UpdateItemFeedbackConfigError
  UpdateReservationResponse:
    | BookingNotFound
    | GuestNotFound
    | InvalidEmail
    | InvalidPhoneNumber
    | RestaurantNotFound
    | ServiceAreaNotFound
    | TableBlocked
    | TableNotFound
    | UnableToCreateGuest
    | UnableToSendSms
    | UpdateReservationResponseSuccess
  UpdateSavedUIOptionResponse:
    | (Omit<InvalidUiOption, 'smartTax'> & {
        smartTax?: Maybe<_RefType['UIOptionInvalidSmartTax']>
      })
    | (Omit<MlmUiOption, 'variants'> & {
        variants: Array<_RefType['MlmUIOptionVariant']>
      })
  UpdateShiftResult:
    | BreakConfigDependencyFailure
    | BuildShiftFailure
    | (Omit<FinishedEmployeeShift, 'timeClock' | 'tips'> & {
        timeClock?: Maybe<_RefType['EmployeeTime']>
        tips?: Maybe<_RefType['EmployeeTips']>
      })
    | (Omit<InProgressEmployeeShift, 'timeClock' | 'tips'> & {
        timeClock?: Maybe<_RefType['EmployeeTime']>
        tips?: Maybe<_RefType['EmployeeTips']>
      })
    | InvalidBreakConfig
    | InvalidBreaks
    | InvalidJob
    | InvalidUpdatedTime
    | JobsRepositoryDependencyFailure
    | LoadShiftsFailure
    | LocateShiftFailure
    | MissingJob
    | MissingTimeClock
    | OverlappingBreaks
    | OverlappingShiftExists
    | ReadFailure
    | RestaurantRepositoryDependencyFailure
    | SaveFailure
    | ShiftNotFound
    | Unauthorized
    | UnknownError
  UpdateShiftTimesResult:
    | (Omit<FinishedEmployeeShift, 'timeClock' | 'tips'> & {
        timeClock?: Maybe<_RefType['EmployeeTime']>
        tips?: Maybe<_RefType['EmployeeTips']>
      })
    | (Omit<InProgressEmployeeShift, 'timeClock' | 'tips'> & {
        timeClock?: Maybe<_RefType['EmployeeTime']>
        tips?: Maybe<_RefType['EmployeeTips']>
      })
    | InvalidUpdatedTime
    | LoadShiftsFailure
    | LocateShiftFailure
    | OverlappingShiftExists
    | RestaurantRepositoryDependencyFailure
    | SaveFailure
    | ShiftNotFound
    | Unauthorized
  UpdateToastPayConfigResponse: ToastPayConfig | UpdateToastPayConfigError
  UpgradeItemFeedbackConfigResponse:
    | CreateItemFeedbackConfigError
    | ItemFeedbackConfig
    | ItemFeedbackConfigAlreadyExists
  UserIteractionMutationResponse: ErrorResponse | UserIteraction
  VoidGroupEntity:
    | (Omit<Check, 'guestFeedback' | 'selections'> & {
        guestFeedback: Array<_RefType['GuestFeedbackInfo']>
        selections: Array<_RefType['OrdersSelection']>
      })
    | (Omit<
        MenuItem,
        | 'ancestors'
        | 'modifierGroups'
        | 'ownerRestaurantSet'
        | 'pricing'
        | 'targetRestaurantSet'
      > & {
        ancestors: Array<_RefType['EntityAncestorResponse']>
        modifierGroups: Array<_RefType['ModifierGroup']>
        ownerRestaurantSet?: Maybe<_RefType['RestaurantSet']>
        pricing?: Maybe<_RefType['MenuItemPricing']>
        targetRestaurantSet: _RefType['RestaurantSet']
      })
    | RestaurantUser
    | VoidReason
}

/** Mapping of interface types */
export type ResolversInterfaceTypes<_RefType extends Record<string, unknown>> =
  {
    CustomerBillingInvoice:
      | (Omit<CanCustomerBillingInvoice, 'deductions'> & {
          deductions: _RefType['CustomerBillingInvoiceDeductions']
        })
      | EpCustomerBillingInvoice
      | (Omit<EmptyCustomerBillingInvoice, 'items'> & {
          items?: Maybe<Array<_RefType['CustomerBillingInvoiceItem']>>
        })
      | (Omit<IrlCustomerBillingInvoice, 'deductions'> & {
          deductions: _RefType['CustomerBillingInvoiceDeductions']
        })
      | (Omit<UkCustomerBillingInvoice, 'deductions'> & {
          deductions: _RefType['CustomerBillingInvoiceDeductions']
        })
      | (Omit<UsCustomerBillingInvoice, 'deductions'> & {
          deductions: _RefType['CustomerBillingInvoiceDeductions']
        })
    CustomerBillingInvoiceItem:
      | CanCustomerBillingInvoiceItem
      | EpCustomerBillingInvoiceItem
      | IrlCustomerBillingInvoiceItem
      | UkCustomerBillingInvoiceItem
      | UsCustomerBillingInvoiceItem
    CustomerBillingPaginatedResponse: Omit<
      CustomerBillingInvoicesPaginatedResponse,
      'invoices'
    > & { invoices: Array<_RefType['CustomerBillingInvoice']> }
    DataExtension:
      | (Omit<AlternatePaymentDataExtension, 'alternatePaymentType'> & {
          alternatePaymentType: _RefType['SavedAlternatePaymentType']
        })
      | (Omit<RevenueCenterDataExtension, 'revenueCenter'> & {
          revenueCenter: _RefType['SavedRevenueCenter']
        })
      | (Omit<SalesCategoryDataExtension, 'salesCategory'> & {
          salesCategory: _RefType['SavedSalesCategory']
        })
      | ServiceDataExtension
      | StaticPaymentDataExtension
      | (Omit<TaxRateDataExtension, 'taxRate'> & {
          taxRate: _RefType['SavedTaxRate']
        })
    Device: Printer | TerminalHandheld
    EmailMarketingCampaign: EmailMarketingBlastCampaign
    EmployeeEmploymentPeriodInterface:
      | EmployeeEmploymentPeriod
      | EmployeeHistoricalEmploymentPeriod
    EmployeeShift:
      | (Omit<FinishedEmployeeShift, 'timeClock' | 'tips'> & {
          timeClock?: Maybe<_RefType['EmployeeTime']>
          tips?: Maybe<_RefType['EmployeeTips']>
        })
      | (Omit<InProgressEmployeeShift, 'timeClock' | 'tips'> & {
          timeClock?: Maybe<_RefType['EmployeeTime']>
          tips?: Maybe<_RefType['EmployeeTips']>
        })
    EmployeeShiftV2:
      | (Omit<FinishedEmployeeShiftV2, 'timeClock'> & {
          timeClock?: Maybe<_RefType['EmployeeTime']>
        })
      | (Omit<InProgressEmployeeShiftV2, 'timeClock'> & {
          timeClock?: Maybe<_RefType['EmployeeTime']>
        })
    EmployeeTakenBreak:
      | FinishedEmployeeTakenBreak
      | InProgressEmployeeTakenBreak
    EmployeeTime:
      | (Omit<FinishedEmployeeTime, 'takenBreaks'> & {
          takenBreaks: Array<_RefType['EmployeeTakenBreak']>
        })
      | (Omit<InProgressEmployeeTime, 'takenBreaks'> & {
          takenBreaks: Array<_RefType['EmployeeTakenBreak']>
        })
    EmploymentStatus:
      | ActiveEmploymentStatus
      | ApplicantEmploymentStatus
      | DeletedEmploymentStatus
      | DemoEmploymentStatus
      | ImplementationEmploymentStatus
      | LeaveOfAbsenceEmploymentStatus
      | NeverEmployedEmploymentStatus
      | PendingEmploymentStatus
      | RehiredEmploymentStatus
      | SeasonalEmploymentStatus
      | TerminatedEmploymentStatus
    Error:
      | BookingNotFound
      | BookingRequiresDepositData
      | DepositAmountChanged
      | DepositRuleNotMatching
      | ErrorRefundingDeposit
      | GuestAlreadyExists
      | GuestNotFound
      | InternalError
      | InvalidEmail
      | InvalidGuest
      | InvalidPhoneNumber
      | NoPreviousImports
      | RestaurantNotFound
      | ServiceAreaNotFound
      | TableBlocked
      | TableNotFound
      | UnableToCreateDepositOrder
      | UnableToCreateGuest
      | UnableToFetchGuests
      | UnableToSendSms
      | UnableToUpdateGuest
      | UnsupportedTransition
    Errors:
      | CorruptPageTokenError
      | InvalidCustomerUuidError
      | InvalidIdempotencyKeyError
      | InvalidRestaurantIdError
      | InvalidUuidError
      | JobError
      | JobNotFoundError
      | JobsBatchSizeError
      | JobsFilterMissingError
      | JobsNotFoundBatchError
      | NoJobIdsProvidedBatchError
      | UnauthorizedAccessError
    Identity: SystemIdentity | UnknownIdentity | UserIdentity
    IdentityInterface: IdmIdentity | IdmUsername
    LogbookContentBlock: LogbookTextContentBlock | LogbookUserContentBlock
    LogbookContentTag: LogbookUserTag
    MenuEntity:
      | (Omit<
          Menu,
          | 'channelMenuEntities'
          | 'menuGroups'
          | 'ownerRestaurantSet'
          | 'targetRestaurantSet'
        > & {
          channelMenuEntities?: Maybe<Array<_RefType['ChannelMenuEntity']>>
          menuGroups?: Maybe<Array<_RefType['MenuGroup']>>
          ownerRestaurantSet?: Maybe<_RefType['RestaurantSet']>
          targetRestaurantSet: _RefType['RestaurantSet']
        })
      | (Omit<
          MenuGroup,
          | 'menuGroups'
          | 'menuItems'
          | 'modifierGroups'
          | 'ownerRestaurantSet'
          | 'targetRestaurantSet'
        > & {
          menuGroups?: Maybe<Array<_RefType['MenuGroup']>>
          menuItems: Array<_RefType['MenuItem']>
          modifierGroups: Array<_RefType['ModifierGroup']>
          ownerRestaurantSet?: Maybe<_RefType['RestaurantSet']>
          targetRestaurantSet: _RefType['RestaurantSet']
        })
      | (Omit<
          MenuItem,
          | 'ancestors'
          | 'modifierGroups'
          | 'ownerRestaurantSet'
          | 'pricing'
          | 'targetRestaurantSet'
        > & {
          ancestors: Array<_RefType['EntityAncestorResponse']>
          modifierGroups: Array<_RefType['ModifierGroup']>
          ownerRestaurantSet?: Maybe<_RefType['RestaurantSet']>
          pricing?: Maybe<_RefType['MenuItemPricing']>
          targetRestaurantSet: _RefType['RestaurantSet']
        })
      | (Omit<
          Modifier,
          | 'itemReference'
          | 'ownerRestaurantSet'
          | 'pricing'
          | 'targetRestaurantSet'
        > & {
          itemReference?: Maybe<_RefType['ItemReference']>
          ownerRestaurantSet?: Maybe<_RefType['RestaurantSet']>
          pricing?: Maybe<_RefType['MenuItemPricing']>
          targetRestaurantSet: _RefType['RestaurantSet']
        })
      | (Omit<
          ModifierGroup,
          'modifiers' | 'ownerRestaurantSet' | 'targetRestaurantSet'
        > & {
          modifiers?: Maybe<Array<_RefType['Modifier']>>
          ownerRestaurantSet?: Maybe<_RefType['RestaurantSet']>
          targetRestaurantSet: _RefType['RestaurantSet']
        })
      | (Omit<
          PriceLevelEntity,
          'ownerRestaurantSet' | 'pricing' | 'targetRestaurantSet'
        > & {
          ownerRestaurantSet?: Maybe<_RefType['RestaurantSet']>
          pricing?: Maybe<_RefType['MenuItemPricing']>
          targetRestaurantSet: _RefType['RestaurantSet']
        })
    MlmSavedConfig: Omit<MlmUiOption, 'variants'> & {
      variants: Array<_RefType['MlmUIOptionVariant']>
    }
    MlmSavedConfigVariant: Omit<
      MlmUiOptionVariant,
      'additionalModifierGroups' | 'versions'
    > & {
      additionalModifierGroups?: Maybe<_RefType['AdditionalModifierGroups']>
      versions: Array<_RefType['MlmSavedVersion']>
    }
    OnboardingAction:
      | OnboardingDefaultAction
      | OnboardingNavigationAction
      | OnboardingShareAction
    OnboardingInfo: Omit<OnboardingDefaultInfo, 'action'> & {
      action?: Maybe<_RefType['OnboardingAction']>
    }
    OnboardingItem: Omit<OnboardingDefaultItem, 'action'> & {
      action?: Maybe<_RefType['OnboardingStepAction']>
    }
    OnboardingItemMetadataV2:
      | OnboardingDefaultItemMetadataV2
      | (Omit<
          OnboardingMeetingItemMetadata,
          'actionSheet' | 'agenda' | 'preparationSteps'
        > & {
          actionSheet: Array<_RefType['OnboardingInfo']>
          agenda: Array<_RefType['OnboardingInfo']>
          preparationSteps: Array<_RefType['OnboardingInfo']>
        })
    OnboardingItemV2:
      | (Omit<OnboardingDefaultItemV2, 'action' | 'metadata'> & {
          action?: Maybe<_RefType['OnboardingAction']>
          metadata?: Maybe<_RefType['OnboardingItemMetadataV2']>
        })
      | (Omit<OnboardingMeetingItem, 'action' | 'metadata'> & {
          action?: Maybe<_RefType['OnboardingAction']>
          metadata?: Maybe<_RefType['OnboardingMeetingItemMetadata']>
        })
    OnboardingSetupStep:
      | (Omit<OnboardingImplementationStep, 'details' | 'items'> & {
          details?: Maybe<_RefType['OnboardingSetupStepDetails']>
          items?: Maybe<Array<_RefType['OnboardingItem']>>
        })
      | (Omit<OnboardingInfoStep, 'details'> & {
          details?: Maybe<_RefType['OnboardingSetupStepDetails']>
        })
      | (Omit<OnboardingMeetingStep, 'details'> & {
          details?: Maybe<_RefType['OnboardingMeetingStepDetails']>
        })
    OnboardingSetupStepDetails:
      | (Omit<OnboardingDefaultStepDetails, 'actions' | 'info'> & {
          actions: Array<Maybe<_RefType['OnboardingAction']>>
          info: Array<Maybe<_RefType['OnboardingStepInfo']>>
        })
      | (Omit<OnboardingMeetingStepDetails, 'actions' | 'info'> & {
          actions: Array<Maybe<_RefType['OnboardingAction']>>
          info: Array<Maybe<_RefType['OnboardingStepInfo']>>
        })
    OnboardingSetupStepV2: Omit<OnboardingImplementationStepV2, 'items'> & {
      items: Array<_RefType['OnboardingItemV2']>
    }
    OnboardingStepInfo: Omit<OnboardingDefaultStepInfo, 'action'> & {
      action?: Maybe<_RefType['OnboardingAction']>
    }
    ProductSubscriptionInterface:
      | ProductSubscription
      | ProductSubscriptionBundle
    PublishedConfigEntityI: Omit<
      PublishedDataExtensionSet,
      'payments' | 'revenueCenters' | 'salesCategories' | 'taxRates'
    > & {
      payments: Array<_RefType['PaymentDataExtension']>
      revenueCenters: Array<_RefType['RevenueCenterDataExtension']>
      salesCategories: Array<_RefType['SalesCategoryDataExtension']>
      taxRates: Array<_RefType['TaxRateDataExtension']>
    }
    PublishedMenuEntity:
      | PublishedMenu
      | PublishedMenuGroup
      | (Omit<PublishedMenuItem, 'ancestors'> & {
          ancestors: Array<_RefType['PublishedMenuItemAncestor']>
        })
    ResolvedConfigEntityI: Omit<
      ResolvedDataExtensionSet,
      'payments' | 'revenueCenters' | 'salesCategories' | 'target' | 'taxRates'
    > & {
      payments: Array<_RefType['PaymentDataExtension']>
      revenueCenters: Array<_RefType['RevenueCenterDataExtension']>
      salesCategories: Array<_RefType['SalesCategoryDataExtension']>
      target?: Maybe<_RefType['RestaurantSet']>
      taxRates: Array<_RefType['TaxRateDataExtension']>
    }
    RestaurantLocationInterface:
      | PublishedRestaurantLocation
      | SavedRestaurantLocation
    SavedConfigEntityI: Omit<
      SavedDataExtensionSet,
      | 'overrides'
      | 'payments'
      | 'revenueCenters'
      | 'salesCategories'
      | 'target'
      | 'taxRates'
    > & {
      overrides: Array<_RefType['DataExtensionSetOverride']>
      payments: Array<_RefType['PaymentDataExtension']>
      revenueCenters: Array<_RefType['RevenueCenterDataExtension']>
      salesCategories: Array<_RefType['SalesCategoryDataExtension']>
      target?: Maybe<_RefType['RestaurantSet']>
      taxRates: Array<_RefType['TaxRateDataExtension']>
    }
    SavedConfigOverrideI: Omit<
      DataExtensionSetOverride,
      'payments' | 'revenueCenters' | 'salesCategories' | 'target' | 'taxRates'
    > & {
      payments?: Maybe<Array<_RefType['PaymentDataExtension']>>
      revenueCenters?: Maybe<Array<_RefType['RevenueCenterDataExtension']>>
      salesCategories?: Maybe<Array<_RefType['SalesCategoryDataExtension']>>
      target: _RefType['RestaurantSet']
      taxRates?: Maybe<Array<_RefType['TaxRateDataExtension']>>
    }
    ShiftError:
      | AuthenticationFailure
      | BreakConfigDependencyFailure
      | BuildShiftFailure
      | ClockOutFailure
      | EmployeeDeleted
      | EmployeeRepositoryDependencyFailure
      | EntitlementsRepositoryDependencyFailure
      | InvalidBreakConfig
      | InvalidBreaks
      | InvalidJob
      | InvalidUpdatedTime
      | JobsRepositoryDependencyFailure
      | LoadShiftsFailure
      | LocateShiftFailure
      | MissingJob
      | MissingTimeClock
      | OverlappingBreaks
      | OverlappingShiftExists
      | PublishRepositoryDependencyFailure
      | ReadFailure
      | RestaurantRepositoryDependencyFailure
      | SaveFailure
      | ShiftAlreadyClockedOut
      | ShiftCheckedResultRepositoryDependencyFailure
      | ShiftNotFound
      | Unauthorized
      | UnknownError
      | UpdateShiftFailure
    SousChefDataPoint:
      | SousChefAlert
      | SousChefAutoClockedOutDataPoint
      | SousChefEmptyDataPoint
      | SousChefFinancialDataPoint
      | SousChefPercentageDataPoint
      | (Omit<SousChefStockDataPoint, 'items'> & {
          items: Array<_RefType['SousChefOutOfStockItem']>
        })
      | SousChefTextDataPoint
      | SousChefTrendDataPoint
    SousChefThread:
      | (Omit<SousChefCompletedThread, 'filters'> & {
          filters: Array<_RefType['SousChefFilter']>
        })
      | SousChefSuggestedThread
    UserInterface:
      | IdmAdminUser
      | IdmCustomerUser
      | IdmExternalDeveloperUser
      | IdmGuestUser
  }

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  AIInsightFeedbackType: AiInsightFeedbackType
  AccessibleRestaurantLocation: ResolverTypeWrapper<
    Omit<AccessibleRestaurantLocation, 'restaurantSet'> & {
      restaurantSet: ResolversTypes['RestaurantSet']
    }
  >
  String: ResolverTypeWrapper<Scalars['String']['output']>
  ID: ResolverTypeWrapper<Scalars['ID']['output']>
  AccessibleRestaurantSet: ResolverTypeWrapper<
    Omit<AccessibleRestaurantSet, 'restaurantSet'> & {
      restaurantSet: ResolversTypes['RestaurantSet']
    }
  >
  Boolean: ResolverTypeWrapper<Scalars['Boolean']['output']>
  AccessibleRestaurantSetCheckType: AccessibleRestaurantSetCheckType
  AccessibleRestaurantSetsInput: AccessibleRestaurantSetsInput
  Action: Action
  ActiveEmploymentStatus: ResolverTypeWrapper<ActiveEmploymentStatus>
  AdditionalModifierGroups: ResolverTypeWrapper<AdditionalModifierGroups>
  AdditionalModifierGroupsCourse: ResolverTypeWrapper<AdditionalModifierGroupsCourse>
  AdditionalModifierGroupsCourseInput: AdditionalModifierGroupsCourseInput
  AdditionalModifierGroupsDiningOption: ResolverTypeWrapper<AdditionalModifierGroupsDiningOption>
  AdditionalModifierGroupsDiningOptionInput: AdditionalModifierGroupsDiningOptionInput
  AdditionalModifierGroupsInput: AdditionalModifierGroupsInput
  AdditionalModifierGroupsSeatNumberQuickOrder: ResolverTypeWrapper<AdditionalModifierGroupsSeatNumberQuickOrder>
  AdditionalModifierGroupsSeatNumberQuickOrderInput: AdditionalModifierGroupsSeatNumberQuickOrderInput
  AdditionalModifierGroupsSeatNumberTableService: ResolverTypeWrapper<AdditionalModifierGroupsSeatNumberTableService>
  AdditionalModifierGroupsSeatNumberTableServiceInput: AdditionalModifierGroupsSeatNumberTableServiceInput
  AdditionalModifierGroupsSpecialRequest: ResolverTypeWrapper<AdditionalModifierGroupsSpecialRequest>
  AdditionalModifierGroupsSpecialRequestInput: AdditionalModifierGroupsSpecialRequestInput
  AdditionalModifierGroupsSplit: ResolverTypeWrapper<AdditionalModifierGroupsSplit>
  AdditionalModifierGroupsSplitInput: AdditionalModifierGroupsSplitInput
  AiChatTermsOfUseResponse: ResolverTypeWrapper<AiChatTermsOfUseResponse>
  AiTermsOfUseDefaultResponse: ResolverTypeWrapper<AiTermsOfUseDefaultResponse>
  AllBookingsResponse: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['AllBookingsResponse']
  >
  AllBookingsResponseSuccess: ResolverTypeWrapper<AllBookingsResponseSuccess>
  AllEmploymentStatuses: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['AllEmploymentStatuses']
  >
  AlternatePaymentDataExtension: ResolverTypeWrapper<
    Omit<AlternatePaymentDataExtension, 'alternatePaymentType'> & {
      alternatePaymentType: ResolversTypes['SavedAlternatePaymentType']
    }
  >
  Analysis: Analysis
  ApplicantEmploymentStatus: ResolverTypeWrapper<ApplicantEmploymentStatus>
  ApplicationMetadata: ResolverTypeWrapper<ApplicationMetadata>
  ArchiveCustomMenuItemUpsellInput: ArchiveCustomMenuItemUpsellInput
  ArchiveEntityInput: ArchiveEntityInput
  ArchiveMembershipResponse: ResolverTypeWrapper<ArchiveMembershipResponse>
  Int: ResolverTypeWrapper<Scalars['Int']['output']>
  AuthConfigurationMutation: ResolverTypeWrapper<AuthConfigurationMutation>
  AuthConfigurationQuery: ResolverTypeWrapper<AuthConfigurationQuery>
  AuthenticationFailure: ResolverTypeWrapper<AuthenticationFailure>
  AuthorizationDecision: AuthorizationDecision
  AuthorizationDecisionReason: ResolverTypeWrapper<AuthorizationDecisionReason>
  AuthorizationPage: ResolverTypeWrapper<AuthorizationPage>
  AuthorizationPageInput: AuthorizationPageInput
  AuthorizationTarget: ResolverTypeWrapper<AuthorizationTarget>
  AuthorizationTargetInput: AuthorizationTargetInput
  AvailabilitiesResponse: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['AvailabilitiesResponse']
  >
  AvailabilitiesResponseSuccess: ResolverTypeWrapper<
    Omit<AvailabilitiesResponseSuccess, 'restaurantAvailability'> & {
      restaurantAvailability?: Maybe<
        ResolversTypes['RestaurantAvailabilityInfo']
      >
    }
  >
  BackfillStatus: BackfillStatus
  BankingConnection: ResolverTypeWrapper<BankingConnection>
  Basis: Basis
  BlockConfig: ResolverTypeWrapper<BlockConfig>
  BlockConfigsResponse: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['BlockConfigsResponse']
  >
  BlockConfigsSuccess: ResolverTypeWrapper<BlockConfigsSuccess>
  BlockedDate: ResolverTypeWrapper<BlockedDate>
  Booking: ResolverTypeWrapper<Booking>
  Float: ResolverTypeWrapper<Scalars['Float']['output']>
  BookingMutation: ResolverTypeWrapper<
    Omit<
      BookingMutation,
      | 'cancelBooking'
      | 'createBlock'
      | 'createBooking'
      | 'createGuest'
      | 'updateBooking'
      | 'updateGuest'
    > & {
      cancelBooking: ResolversTypes['CancelReservationResponse']
      createBlock: ResolversTypes['CreateBlockResponse']
      createBooking: ResolversTypes['CreateReservationResponse']
      createGuest: ResolversTypes['GuestCreationResponse']
      updateBooking: ResolversTypes['UpdateReservationResponse']
      updateGuest: ResolversTypes['GuestUpdateResponse']
    }
  >
  BookingNotFound: ResolverTypeWrapper<BookingNotFound>
  BookingPlan: BookingPlan
  BookingQuery: ResolverTypeWrapper<
    Omit<
      BookingQuery,
      | 'availabilities'
      | 'blocks'
      | 'bookingLimit'
      | 'bookings'
      | 'guest'
      | 'mostRecentImportGuid'
      | 'restaurant'
    > & {
      availabilities: ResolversTypes['AvailabilitiesResponse']
      blocks: ResolversTypes['BlockConfigsResponse']
      bookingLimit: ResolversTypes['CurrentBookingUsageResponse']
      bookings: ResolversTypes['AllBookingsResponse']
      guest: ResolversTypes['GuestListResponse']
      mostRecentImportGuid: ResolversTypes['MostRecentImportGuidResponse']
      restaurant: ResolversTypes['MgmtRestaurantInformationResponse']
    }
  >
  BookingRequiresDepositData: ResolverTypeWrapper<BookingRequiresDepositData>
  BookingServiceArea: ResolverTypeWrapper<BookingServiceArea>
  BookingServiceAreaGroup: ResolverTypeWrapper<BookingServiceAreaGroup>
  BookingSource: BookingSource
  BookingStatus: BookingStatus
  BookingTable: ResolverTypeWrapper<BookingTable>
  BookingType: BookingType
  BookingsEmployee: ResolverTypeWrapper<BookingsEmployee>
  BreakConfig: ResolverTypeWrapper<BreakConfig>
  BreakConfigDependencyFailure: ResolverTypeWrapper<BreakConfigDependencyFailure>
  BreakConfigInput: BreakConfigInput
  BreakInput: BreakInput
  BuildShiftFailure: ResolverTypeWrapper<BuildShiftFailure>
  ByArchiveStatusSearchInput: ByArchiveStatusSearchInput
  ByEmailSearchInput: ByEmailSearchInput
  ByEmployeeIdInputSearchInput: ByEmployeeIdInputSearchInput
  ByJobIdInput: ByJobIdInput
  ByNameSearchInput: ByNameSearchInput
  ByPhoneNumberSearchInput: ByPhoneNumberSearchInput
  CANCustomerBillingInvoice: ResolverTypeWrapper<
    Omit<CanCustomerBillingInvoice, 'deductions'> & {
      deductions: ResolversTypes['CustomerBillingInvoiceDeductions']
    }
  >
  CANCustomerBillingInvoiceItem: ResolverTypeWrapper<CanCustomerBillingInvoiceItem>
  CANCustomerBillingInvoiceTaxDetails: ResolverTypeWrapper<CanCustomerBillingInvoiceTaxDetails>
  CacheControlScope: CacheControlScope
  CancelReservationResponse: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['CancelReservationResponse']
  >
  CancelReservationResponseSuccess: ResolverTypeWrapper<CancelReservationResponseSuccess>
  CancelTrialRequest: CancelTrialRequest
  CancellationRequestState: CancellationRequestState
  CancellationRequestType: CancellationRequestType
  CancellationSource: CancellationSource
  CapitalQuery: ResolverTypeWrapper<CapitalQuery>
  CashDrawer: ResolverTypeWrapper<CashDrawer>
  CashDrawerConfig: ResolverTypeWrapper<CashDrawerConfig>
  CashDrawerConfigInput: CashDrawerConfigInput
  CashDrawerInput: CashDrawerInput
  CashFlowAccount: ResolverTypeWrapper<CashFlowAccount>
  CashFlowAccountTransactions: ResolverTypeWrapper<CashFlowAccountTransactions>
  CashFlowSummary: ResolverTypeWrapper<CashFlowSummary>
  CashFlowTransaction: ResolverTypeWrapper<CashFlowTransaction>
  CashFlowTransactionCategory: CashFlowTransactionCategory
  CashFlowTransactionSortingFields: CashFlowTransactionSortingFields
  CashFlowTransactionSortingOrder: CashFlowTransactionSortingOrder
  CashFlowTransactionType: CashFlowTransactionType
  Category: ResolverTypeWrapper<Category>
  CategoryType: CategoryType
  ChannelAvailability: ResolverTypeWrapper<ChannelAvailability>
  ChannelMenuEntity: ResolverTypeWrapper<
    Omit<
      ChannelMenuEntity,
      'channel' | 'menu' | 'ownerRestaurantSet' | 'targetRestaurantSet'
    > & {
      channel?: Maybe<ResolversTypes['SavedChannel']>
      menu?: Maybe<ResolversTypes['Menu']>
      ownerRestaurantSet?: Maybe<ResolversTypes['RestaurantSet']>
      targetRestaurantSet?: Maybe<ResolversTypes['RestaurantSet']>
    }
  >
  ChannelMenuEntityInput: ChannelMenuEntityInput
  ChannelMenuPriceAdjustment: ResolverTypeWrapper<
    Omit<
      ChannelMenuPriceAdjustment,
      'channel' | 'ownerRestaurantSet' | 'targetRestaurantSet'
    > & {
      channel?: Maybe<ResolversTypes['SavedChannel']>
      ownerRestaurantSet?: Maybe<ResolversTypes['RestaurantSet']>
      targetRestaurantSet?: Maybe<ResolversTypes['RestaurantSet']>
    }
  >
  ChannelMenuPriceAdjustmentInput: ChannelMenuPriceAdjustmentInput
  ChannelMenuPriceIncreaseType: ChannelMenuPriceIncreaseType
  ChannelMutation: ResolverTypeWrapper<
    Omit<
      ChannelMutation,
      | 'createSavedChannelAvailabilities'
      | 'createSavedChannelAvailabilityForChannel'
      | 'createSavedChannels'
      | 'initializeSavedChannel'
      | 'updateSavedChannel'
      | 'updateSavedChannelAvailability'
    > & {
      createSavedChannelAvailabilities: Array<
        ResolversTypes['SavedChannelAvailability']
      >
      createSavedChannelAvailabilityForChannel: ResolversTypes['SavedChannel']
      createSavedChannels: Array<ResolversTypes['SavedChannel']>
      initializeSavedChannel: ResolversTypes['SavedChannel']
      updateSavedChannel: ResolversTypes['SavedChannel']
      updateSavedChannelAvailability: ResolversTypes['SavedChannelAvailability']
    }
  >
  ChannelQuery: ResolverTypeWrapper<
    Omit<
      ChannelQuery,
      | 'channelTypes'
      | 'publishedChannels'
      | 'savedChannelAvailabilities'
      | 'savedChannelAvailabilityById'
      | 'savedChannelAvailabilityByMultiLocationId'
      | 'savedChannelByChannelType'
      | 'savedChannelByMultiLocationId'
      | 'savedChannels'
      | 'searchSavedChannels'
    > & {
      channelTypes: Array<ResolversTypes['ChannelType']>
      publishedChannels: Array<ResolversTypes['PublishedChannel']>
      savedChannelAvailabilities: ResolversTypes['PagedSavedChannelAvailability']
      savedChannelAvailabilityById: ResolversTypes['SavedChannelAvailability']
      savedChannelAvailabilityByMultiLocationId: ResolversTypes['SavedChannelAvailability']
      savedChannelByChannelType: ResolversTypes['SavedChannel']
      savedChannelByMultiLocationId: ResolversTypes['SavedChannel']
      savedChannels?: Maybe<Array<ResolversTypes['SavedChannel']>>
      searchSavedChannels: ResolversTypes['PagedSavedChannel']
    }
  >
  ChannelType: ResolverTypeWrapper<
    Omit<ChannelType, 'savedChannels'> & {
      savedChannels: Array<ResolversTypes['SavedChannel']>
    }
  >
  ChannelTypeInput: ChannelTypeInput
  ChatMessage: ResolverTypeWrapper<ChatMessage>
  ChatMessageFeedback: ChatMessageFeedback
  ChatMessageFilters: ResolverTypeWrapper<ChatMessageFilters>
  ChatMessageFiltersDateRange: ResolverTypeWrapper<ChatMessageFiltersDateRange>
  ChatMessageFiltersDefinedHours: ResolverTypeWrapper<ChatMessageFiltersDefinedHours>
  ChatMessageInput: ChatMessageInput
  ChatMessageType: ChatMessageType
  ChatResponse: ResolverTypeWrapper<ChatResponse>
  ChatRole: ChatRole
  Check: ResolverTypeWrapper<
    Omit<Check, 'guestFeedback' | 'selections'> & {
      guestFeedback: Array<ResolversTypes['GuestFeedbackInfo']>
      selections: Array<ResolversTypes['OrdersSelection']>
    }
  >
  CheckPaymentStatus: CheckPaymentStatus
  ChecksWithRefundsCount: ResolverTypeWrapper<ChecksWithRefundsCount>
  ChecksWithVoidsCount: ResolverTypeWrapper<
    Omit<ChecksWithVoidsCount, 'grouped'> & {
      grouped?: Maybe<Array<ResolversTypes['GroupedChecksWithVoidsCount']>>
    }
  >
  ClockOutFailure: ResolverTypeWrapper<ClockOutFailure>
  CloseOutReportingConfig: ResolverTypeWrapper<CloseOutReportingConfig>
  CloseOutReportingContent: ResolverTypeWrapper<CloseOutReportingContent>
  CompareTo: CompareTo
  Comparison: ResolverTypeWrapper<Comparison>
  ConferencingPlatform: ConferencingPlatform
  ConfigCopyQuery: ResolverTypeWrapper<
    Omit<ConfigCopyQuery, 'copyConfigTask' | 'copyConfigTasks'> & {
      copyConfigTask?: Maybe<ResolversTypes['CopyConfigTask']>
      copyConfigTasks: ResolversTypes['CopyConfigTaskConnection']
    }
  >
  ConfigEntityTag: ConfigEntityTag
  ConfigField: ResolverTypeWrapper<ConfigField>
  ConfigFieldInput: ConfigFieldInput
  ConfigLocation: ResolverTypeWrapper<ConfigLocation>
  ConfigMutation: ResolverTypeWrapper<ConfigMutation>
  ConfigQuery: ResolverTypeWrapper<
    Omit<ConfigQuery, 'copyConfigTasks'> & {
      copyConfigTasks: ResolversTypes['CopyConfigTaskConnection']
    }
  >
  ConfigReferencesCount: ResolverTypeWrapper<ConfigReferencesCount>
  ConfigType: ResolverTypeWrapper<ConfigType>
  ConfigTypeInput: ConfigTypeInput
  ConfigValueDiff: ResolverTypeWrapper<ConfigValueDiff>
  ConfigValueDiffInformation: ResolverTypeWrapper<ConfigValueDiffInformation>
  ConnectionPaginationInput: ConnectionPaginationInput
  ContainsAlcohol: ContainsAlcohol
  CopyConfigMutation: ResolverTypeWrapper<
    Omit<CopyConfigMutation, 'createCopyConfigTask'> & {
      createCopyConfigTask: ResolversTypes['CopyConfigTask']
    }
  >
  CopyConfigStatus: CopyConfigStatus
  CopyConfigTask: ResolverTypeWrapper<
    Omit<CopyConfigTask, 'destinations'> & {
      destinations: Array<ResolversTypes['RestaurantSet']>
    }
  >
  CopyConfigTaskConnection: ResolverTypeWrapper<
    Omit<CopyConfigTaskConnection, 'edges'> & {
      edges: Array<Maybe<ResolversTypes['CopyConfigTaskEdge']>>
    }
  >
  CopyConfigTaskEdge: ResolverTypeWrapper<
    Omit<CopyConfigTaskEdge, 'node'> & {
      node: ResolversTypes['CopyConfigTask']
    }
  >
  CopyConfigTaskInput: CopyConfigTaskInput
  CopyConfigTasksPaginationInput: CopyConfigTasksPaginationInput
  CorruptPageTokenError: ResolverTypeWrapper<CorruptPageTokenError>
  Course: ResolverTypeWrapper<Course>
  CreateBlockRequest: CreateBlockRequest
  CreateBlockResponse: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['CreateBlockResponse']
  >
  CreateBlockSuccess: ResolverTypeWrapper<CreateBlockSuccess>
  CreateDataExtensionSetInput: CreateDataExtensionSetInput
  CreateGfdConfigInput: CreateGfdConfigInput
  CreateGuestRequest: CreateGuestRequest
  CreateItemFeedbackConfigError: ResolverTypeWrapper<CreateItemFeedbackConfigError>
  CreateItemFeedbackConfigErrorCode: CreateItemFeedbackConfigErrorCode
  CreateItemFeedbackConfigResponse: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['CreateItemFeedbackConfigResponse']
  >
  CreateLogbookEntryReply: CreateLogbookEntryReply
  CreateLogbookEntryWithBusinessDay: CreateLogbookEntryWithBusinessDay
  CreateMembershipInput: CreateMembershipInput
  CreateMembershipResult: ResolverTypeWrapper<CreateMembershipResult>
  CreateMenuItemPlateCost: CreateMenuItemPlateCost
  CreateReasonInput: CreateReasonInput
  CreateReservationRequest: CreateReservationRequest
  CreateReservationResponse: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['CreateReservationResponse']
  >
  CreateReservationResponseSuccess: ResolverTypeWrapper<CreateReservationResponseSuccess>
  CreateRestaurantGroupInput: CreateRestaurantGroupInput
  CreateShiftInput: CreateShiftInput
  CreateShiftResult: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['CreateShiftResult']
  >
  CreateToastPayConfigError: ResolverTypeWrapper<CreateToastPayConfigError>
  CreateToastPayConfigErrorCode: CreateToastPayConfigErrorCode
  CreateToastPayConfigResponse: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['CreateToastPayConfigResponse']
  >
  CreateTrialRequest: CreateTrialRequest
  CreateUserQuickActionInput: CreateUserQuickActionInput
  Currency: Currency
  CurrentBookingUsageResponse: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['CurrentBookingUsageResponse']
  >
  CurrentBookingUsageResponseSuccess: ResolverTypeWrapper<CurrentBookingUsageResponseSuccess>
  CustomMenuItemUpsell: ResolverTypeWrapper<
    Omit<CustomMenuItemUpsell, 'targets'> & {
      targets: Array<ResolversTypes['RestaurantSet']>
    }
  >
  CustomerBillingCreditMemo: ResolverTypeWrapper<
    Omit<CustomerBillingCreditMemo, 'appliedTo'> & {
      appliedTo: Array<ResolversTypes['CustomerBillingCreditMemoApplication']>
    }
  >
  CustomerBillingCreditMemoApplication: ResolverTypeWrapper<
    Omit<CustomerBillingCreditMemoApplication, 'invoice'> & {
      invoice: ResolversTypes['CustomerBillingInvoice']
    }
  >
  CustomerBillingCreditMemoStatus: CustomerBillingCreditMemoStatus
  CustomerBillingInvoice: ResolverTypeWrapper<
    ResolversInterfaceTypes<ResolversTypes>['CustomerBillingInvoice']
  >
  CustomerBillingInvoiceDeductions: ResolverTypeWrapper<
    Omit<CustomerBillingInvoiceDeductions, 'creditMemosApplied'> & {
      creditMemosApplied: Array<
        ResolversTypes['CustomerBillingCreditMemoApplication']
      >
    }
  >
  CustomerBillingInvoiceItem: ResolverTypeWrapper<
    ResolversInterfaceTypes<ResolversTypes>['CustomerBillingInvoiceItem']
  >
  CustomerBillingInvoiceShipping: ResolverTypeWrapper<CustomerBillingInvoiceShipping>
  CustomerBillingInvoiceStatus: CustomerBillingInvoiceStatus
  CustomerBillingInvoiceTotals: ResolverTypeWrapper<CustomerBillingInvoiceTotals>
  CustomerBillingInvoiceType: CustomerBillingInvoiceType
  CustomerBillingInvoicesPaginatedResponse: ResolverTypeWrapper<
    Omit<CustomerBillingInvoicesPaginatedResponse, 'invoices'> & {
      invoices: Array<ResolversTypes['CustomerBillingInvoice']>
    }
  >
  CustomerBillingPaginatedRequestInput: CustomerBillingPaginatedRequestInput
  CustomerBillingPaginatedResponse: ResolverTypeWrapper<
    ResolversInterfaceTypes<ResolversTypes>['CustomerBillingPaginatedResponse']
  >
  CustomerBillingQuery: ResolverTypeWrapper<
    Omit<CustomerBillingQuery, 'invoice' | 'invoices'> & {
      invoice?: Maybe<ResolversTypes['CustomerBillingInvoice']>
      invoices: ResolversTypes['CustomerBillingInvoicesPaginatedResponse']
    }
  >
  CustomerBillingRequestSortOrder: CustomerBillingRequestSortOrder
  CustomerBillingRequestSortType: CustomerBillingRequestSortType
  CustomerInvoiceDetailsReport: ResolverTypeWrapper<CustomerInvoiceDetailsReport>
  CustomerInvoiceDetailsReportCreditMemo: ResolverTypeWrapper<CustomerInvoiceDetailsReportCreditMemo>
  CustomerInvoiceDetailsReportServicePeriodItem: ResolverTypeWrapper<CustomerInvoiceDetailsReportServicePeriodItem>
  CustomerInvoiceDetailsReportServicePeriodItems: ResolverTypeWrapper<CustomerInvoiceDetailsReportServicePeriodItems>
  CustomerInvoiceListReport: ResolverTypeWrapper<CustomerInvoiceListReport>
  CustomerInvoiceListReportItem: ResolverTypeWrapper<CustomerInvoiceListReportItem>
  CustomerInvoiceListTotalAmounts: ResolverTypeWrapper<CustomerInvoiceListTotalAmounts>
  CustomerInvoiceQuery: ResolverTypeWrapper<CustomerInvoiceQuery>
  CustomerTasksCreateWorkflowTemplate: CustomerTasksCreateWorkflowTemplate
  CustomerTasksMutation: ResolverTypeWrapper<CustomerTasksMutation>
  CustomerTasksQuery: ResolverTypeWrapper<CustomerTasksQuery>
  CustomerTasksStatus: CustomerTasksStatus
  CustomerTasksTask: ResolverTypeWrapper<CustomerTasksTask>
  CustomerTasksTaskEvent: ResolverTypeWrapper<CustomerTasksTaskEvent>
  CustomerTasksTaskTemplate: ResolverTypeWrapper<CustomerTasksTaskTemplate>
  CustomerTasksTaskTimeline: ResolverTypeWrapper<CustomerTasksTaskTimeline>
  CustomerTasksWorkflow: ResolverTypeWrapper<CustomerTasksWorkflow>
  CustomerTasksWorkflowScope: CustomerTasksWorkflowScope
  CustomerTasksWorkflowTemplate: ResolverTypeWrapper<CustomerTasksWorkflowTemplate>
  CustomerUserAuthorizationQuery: ResolverTypeWrapper<CustomerUserAuthorizationQuery>
  DSModel: ResolverTypeWrapper<DsModel>
  DailyMetrics: ResolverTypeWrapper<DailyMetrics>
  DailyNetSalesMetric: ResolverTypeWrapper<DailyNetSalesMetric>
  DataExtension: ResolverTypeWrapper<
    ResolversInterfaceTypes<ResolversTypes>['DataExtension']
  >
  DataExtensionInput: DataExtensionInput
  DataExtensionMutation: ResolverTypeWrapper<
    Omit<
      DataExtensionMutation,
      'createDataExtensionSet' | 'updateDataExtensionSet'
    > & {
      createDataExtensionSet: ResolversTypes['SavedDataExtensionSet']
      updateDataExtensionSet: ResolversTypes['SavedDataExtensionSet']
    }
  >
  DataExtensionQuery: ResolverTypeWrapper<
    Omit<
      DataExtensionQuery,
      | 'publishedDataExtensionSet'
      | 'resolvedDataExtensionSet'
      | 'savedDataExtensionSet'
      | 'savedDataExtensionSets'
    > & {
      publishedDataExtensionSet: ResolversTypes['PublishedDataExtensionSet']
      resolvedDataExtensionSet: ResolversTypes['ResolvedDataExtensionSet']
      savedDataExtensionSet: ResolversTypes['SavedDataExtensionSet']
      savedDataExtensionSets: Array<ResolversTypes['SavedDataExtensionSet']>
    }
  >
  DataExtensionSetOverride: ResolverTypeWrapper<
    Omit<
      DataExtensionSetOverride,
      'payments' | 'revenueCenters' | 'salesCategories' | 'target' | 'taxRates'
    > & {
      payments?: Maybe<Array<ResolversTypes['PaymentDataExtension']>>
      revenueCenters?: Maybe<
        Array<ResolversTypes['RevenueCenterDataExtension']>
      >
      salesCategories?: Maybe<
        Array<ResolversTypes['SalesCategoryDataExtension']>
      >
      target: ResolversTypes['RestaurantSet']
      taxRates?: Maybe<Array<ResolversTypes['TaxRateDataExtension']>>
    }
  >
  DataExtensionSetOverrideInput: DataExtensionSetOverrideInput
  DataExtensionSetType: DataExtensionSetType
  DataExtensionType: DataExtensionType
  Date: ResolverTypeWrapper<Scalars['Date']['output']>
  DateRange: ResolverTypeWrapper<DateRange>
  DateRangeInput: DateRangeInput
  DateRangeMetrics: ResolverTypeWrapper<DateRangeMetrics>
  DateTime: ResolverTypeWrapper<Scalars['DateTime']['output']>
  DayOfWeek: DayOfWeek
  DayOperatingSchedule: ResolverTypeWrapper<DayOperatingSchedule>
  DayPeriod: ResolverTypeWrapper<DayPeriod>
  DayPeriodInput: DayPeriodInput
  Decimal: ResolverTypeWrapper<Scalars['Decimal']['output']>
  DecimalMetric: ResolverTypeWrapper<DecimalMetric>
  DeclareCashTipsConfig: ResolverTypeWrapper<DeclareCashTipsConfig>
  DeclareCashTipsConfigInput: DeclareCashTipsConfigInput
  DefaultRate: ResolverTypeWrapper<DefaultRate>
  DeleteLogbookEntry: DeleteLogbookEntry
  DeleteLogbookEntryInput: DeleteLogbookEntryInput
  DeleteLogbookEntryReply: DeleteLogbookEntryReply
  DeleteLogbookEntryReplyInput: DeleteLogbookEntryReplyInput
  DeleteUserQuickActionResponse: ResolverTypeWrapper<DeleteUserQuickActionResponse>
  DeletedEmploymentStatus: ResolverTypeWrapper<DeletedEmploymentStatus>
  DeletedShiftResult: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['DeletedShiftResult']
  >
  DeliveryToggleUIConfiguration: DeliveryToggleUiConfiguration
  DemoEmploymentStatus: ResolverTypeWrapper<DemoEmploymentStatus>
  DepositAmountChanged: ResolverTypeWrapper<DepositAmountChanged>
  DepositAvailabilityInfo: ResolverTypeWrapper<
    Omit<DepositAvailabilityInfo, 'strategy'> & {
      strategy: ResolversTypes['DepositStrategyAvailabilityInfo']
    }
  >
  DepositBookableConfigSnapshot: ResolverTypeWrapper<DepositBookableConfigSnapshot>
  DepositBookableConfigSnapshotStrategy: ResolverTypeWrapper<DepositBookableConfigSnapshotStrategy>
  DepositRequest: DepositRequest
  DepositRuleNotMatching: ResolverTypeWrapper<DepositRuleNotMatching>
  DepositStatus: DepositStatus
  DepositStrategy: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['DepositStrategy']
  >
  DepositStrategyAvailabilityInfo: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['DepositStrategyAvailabilityInfo']
  >
  DepositStrategyAvailabilityInfoByBooking: ResolverTypeWrapper<DepositStrategyAvailabilityInfoByBooking>
  DepositStrategyAvailabilityInfoByPartySize: ResolverTypeWrapper<DepositStrategyAvailabilityInfoByPartySize>
  DepositStrategyByBooking: ResolverTypeWrapper<DepositStrategyByBooking>
  DepositStrategyByPartySize: ResolverTypeWrapper<DepositStrategyByPartySize>
  DepositType: DepositType
  Device: ResolverTypeWrapper<ResolversInterfaceTypes<ResolversTypes>['Device']>
  DeviceAlert: ResolverTypeWrapper<DeviceAlert>
  DeviceAlertType: DeviceAlertType
  DeviceConnectionType: DeviceConnectionType
  DeviceConnectivityEvent: ResolverTypeWrapper<DeviceConnectivityEvent>
  DeviceConnectivityEventData: ResolverTypeWrapper<DeviceConnectivityEventData>
  DeviceConnectivityEventStatus: DeviceConnectivityEventStatus
  DeviceDetails: ResolverTypeWrapper<DeviceDetails>
  DeviceInfoPublishedPrepStation: ResolverTypeWrapper<DeviceInfoPublishedPrepStation>
  DeviceInput: DeviceInput
  DeviceIpType: DeviceIpType
  DeviceLastSeenInfo: ResolverTypeWrapper<DeviceLastSeenInfo>
  DeviceNetworkDetails: ResolverTypeWrapper<DeviceNetworkDetails>
  DeviceNetworkInterface: DeviceNetworkInterface
  DeviceOnlineStatus: DeviceOnlineStatus
  DeviceQueries: ResolverTypeWrapper<
    Omit<DeviceQueries, 'device' | 'devices'> & {
      device?: Maybe<ResolversTypes['Device']>
      devices?: Maybe<Array<ResolversTypes['Device']>>
    }
  >
  DeviceSyncDetails: ResolverTypeWrapper<DeviceSyncDetails>
  DeviceType: DeviceType
  DevicesInput: DevicesInput
  DiningOption: ResolverTypeWrapper<DiningOption>
  DiningOptionBehavior: DiningOptionBehavior
  DiningOptionMode: DiningOptionMode
  DiscountAmount: ResolverTypeWrapper<
    Omit<DiscountAmount, 'grouped'> & {
      grouped?: Maybe<Array<ResolversTypes['GroupedDiscountAmount']>>
    }
  >
  DiscountCount: ResolverTypeWrapper<
    Omit<DiscountCount, 'grouped'> & {
      grouped?: Maybe<Array<ResolversTypes['GroupedDiscountCount']>>
    }
  >
  DiscountFilter: DiscountFilter
  DiscountGroupEntity: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['DiscountGroupEntity']
  >
  DiscountsGroupingDimension: DiscountsGroupingDimension
  DisplayTheme: DisplayTheme
  DoorDashMockOnboardingConfiguration: ResolverTypeWrapper<DoorDashMockOnboardingConfiguration>
  DoorDashMockOnboardingConfigurationInput: DoorDashMockOnboardingConfigurationInput
  DoorDashNetNewOnboardingRequest: DoorDashNetNewOnboardingRequest
  DoorDashNetNewOnboardingResponse: ResolverTypeWrapper<DoorDashNetNewOnboardingResponse>
  DoorDashOnboardingActivateIntegrationResponse: ResolverTypeWrapper<DoorDashOnboardingActivateIntegrationResponse>
  DoorDashOnboardingAddress: DoorDashOnboardingAddress
  DoorDashOnboardingIdResponse: ResolverTypeWrapper<DoorDashOnboardingIdResponse>
  DoorDashOnboardingMenu: ResolverTypeWrapper<DoorDashOnboardingMenu>
  DoorDashOnboardingMenuCandidate: ResolverTypeWrapper<DoorDashOnboardingMenuCandidate>
  DoorDashOnboardingMenuInput: DoorDashOnboardingMenuInput
  DoorDashOnboardingStatusResponse: ResolverTypeWrapper<DoorDashOnboardingStatusResponse>
  DoorDashOnboardingStoreCandidate: ResolverTypeWrapper<DoorDashOnboardingStoreCandidate>
  DoorDashOnboardingStores: ResolverTypeWrapper<DoorDashOnboardingStores>
  DoorDashStoreCandidate: ResolverTypeWrapper<DoorDashStoreCandidate>
  DoorDashStoreCandidateInput: DoorDashStoreCandidateInput
  DriveThruMutation: ResolverTypeWrapper<
    Omit<
      DriveThruMutation,
      | 'deleteOrderConfirmationScreenConfigOverrides'
      | 'deleteStockDataConfigOverrides'
      | 'deleteStockOutConfigOverrides'
      | 'saveOrderConfirmationScreenConfigBase'
      | 'saveOrderConfirmationScreenConfigOverrides'
      | 'saveStockDataConfigBase'
      | 'saveStockDataConfigOverrides'
      | 'saveStockOutConfigBase'
      | 'saveStockOutConfigOverrides'
    > & {
      deleteOrderConfirmationScreenConfigOverrides: ResolversTypes['SavedOrderConfirmationScreenConfig']
      deleteStockDataConfigOverrides: ResolversTypes['SavedStockDataConfig']
      deleteStockOutConfigOverrides: ResolversTypes['SavedStockOutConfig']
      saveOrderConfirmationScreenConfigBase: ResolversTypes['QLOrderConfirmationScreenConfig']
      saveOrderConfirmationScreenConfigOverrides: ResolversTypes['SavedOrderConfirmationScreenConfig']
      saveStockDataConfigBase: ResolversTypes['QLStockDataConfig']
      saveStockDataConfigOverrides: ResolversTypes['SavedStockDataConfig']
      saveStockOutConfigBase: ResolversTypes['QLStockOutConfig']
      saveStockOutConfigOverrides: ResolversTypes['SavedStockOutConfig']
    }
  >
  DriveThruQuery: ResolverTypeWrapper<
    Omit<
      DriveThruQuery,
      | 'resolvedOrderConfirmationScreenConfig'
      | 'resolvedStockDataConfig'
      | 'resolvedStockOutConfig'
      | 'savedOrderConfirmationScreenConfig'
      | 'savedStockDataConfig'
      | 'savedStockOutConfig'
    > & {
      resolvedOrderConfirmationScreenConfig: ResolversTypes['QLOrderConfirmationScreenConfig']
      resolvedStockDataConfig: ResolversTypes['QLStockDataConfig']
      resolvedStockOutConfig: ResolversTypes['QLStockOutConfig']
      savedOrderConfirmationScreenConfig: ResolversTypes['SavedOrderConfirmationScreenConfig']
      savedStockDataConfig: ResolversTypes['SavedStockDataConfig']
      savedStockOutConfig: ResolversTypes['SavedStockOutConfig']
    }
  >
  DsModelHealthStatus: ResolverTypeWrapper<DsModelHealthStatus>
  Duration: ResolverTypeWrapper<Scalars['Duration']['output']>
  DurationBreakdown: ResolverTypeWrapper<DurationBreakdown>
  EPCustomerBillingInvoice: ResolverTypeWrapper<EpCustomerBillingInvoice>
  EPCustomerBillingInvoiceDeductions: ResolverTypeWrapper<EpCustomerBillingInvoiceDeductions>
  EPCustomerBillingInvoiceItem: ResolverTypeWrapper<EpCustomerBillingInvoiceItem>
  EditGroupsAndLocationsInput: EditGroupsAndLocationsInput
  EditRestaurantGroupInfoInput: EditRestaurantGroupInfoInput
  EmailMarketingBlastCampaign: ResolverTypeWrapper<EmailMarketingBlastCampaign>
  EmailMarketingCampaign: ResolverTypeWrapper<
    ResolversInterfaceTypes<ResolversTypes>['EmailMarketingCampaign']
  >
  EmailMarketingCampaignFilterInput: EmailMarketingCampaignFilterInput
  EmailMarketingCampaignPaginationInput: EmailMarketingCampaignPaginationInput
  EmailMarketingCampaignStatus: EmailMarketingCampaignStatus
  Employee: ResolverTypeWrapper<
    Omit<Employee, 'payroll'> & {
      payroll?: Maybe<ResolversTypes['PayrollEmployeeInfo']>
    }
  >
  EmployeeAddress: ResolverTypeWrapper<EmployeeAddress>
  EmployeeBreakConfirmationType: EmployeeBreakConfirmationType
  EmployeeConnection: ResolverTypeWrapper<EmployeeConnection>
  EmployeeCountry: ResolverTypeWrapper<EmployeeCountry>
  EmployeeCursorPaginationInput: EmployeeCursorPaginationInput
  EmployeeDeleted: ResolverTypeWrapper<EmployeeDeleted>
  EmployeeEdge: ResolverTypeWrapper<EmployeeEdge>
  EmployeeEmail: ResolverTypeWrapper<EmployeeEmail>
  EmployeeEmployment: ResolverTypeWrapper<EmployeeEmployment>
  EmployeeEmploymentPeriod: ResolverTypeWrapper<EmployeeEmploymentPeriod>
  EmployeeEmploymentPeriodInterface: ResolverTypeWrapper<
    ResolversInterfaceTypes<ResolversTypes>['EmployeeEmploymentPeriodInterface']
  >
  EmployeeEmploymentStatus: EmployeeEmploymentStatus
  EmployeeEmploymentType: EmployeeEmploymentType
  EmployeeHistoricalEmploymentPeriod: ResolverTypeWrapper<EmployeeHistoricalEmploymentPeriod>
  EmployeeJobAssignment: ResolverTypeWrapper<EmployeeJobAssignment>
  EmployeeLocationAssignments: ResolverTypeWrapper<EmployeeLocationAssignments>
  EmployeeMissedBreak: ResolverTypeWrapper<EmployeeMissedBreak>
  EmployeeName: ResolverTypeWrapper<EmployeeName>
  EmployeeOffsetPaginationInput: EmployeeOffsetPaginationInput
  EmployeeOrderByDirection: EmployeeOrderByDirection
  EmployeeOrderByField: EmployeeOrderByField
  EmployeeOrderByInput: EmployeeOrderByInput
  EmployeeOrderTips: ResolverTypeWrapper<
    Omit<EmployeeOrderTips, 'order'> & { order: ResolversTypes['Order'] }
  >
  EmployeeOvertimeEligibility: EmployeeOvertimeEligibility
  EmployeePageInfo: ResolverTypeWrapper<EmployeePageInfo>
  EmployeePaginationInput: EmployeePaginationInput
  EmployeePay: ResolverTypeWrapper<EmployeePay>
  EmployeePayBasis: EmployeePayBasis
  EmployeePhoneNumber: ResolverTypeWrapper<EmployeePhoneNumber>
  EmployeeRepositoryDependencyFailure: ResolverTypeWrapper<EmployeeRepositoryDependencyFailure>
  EmployeeSchedule: ResolverTypeWrapper<EmployeeSchedule>
  EmployeeScheduleV2: ResolverTypeWrapper<EmployeeScheduleV2>
  EmployeeSearchInput: EmployeeSearchInput
  EmployeeShift: ResolverTypeWrapper<
    ResolversInterfaceTypes<ResolversTypes>['EmployeeShift']
  >
  EmployeeShiftConnection: ResolverTypeWrapper<
    Omit<EmployeeShiftConnection, 'shifts'> & {
      shifts: Array<ResolversTypes['EmployeeShift']>
    }
  >
  EmployeeShiftConnectionV2: ResolverTypeWrapper<
    Omit<EmployeeShiftConnectionV2, 'edges'> & {
      edges: Array<ResolversTypes['EmployeeShiftConnectionV2Edge']>
    }
  >
  EmployeeShiftConnectionV2Edge: ResolverTypeWrapper<
    Omit<EmployeeShiftConnectionV2Edge, 'node'> & {
      node: ResolversTypes['EmployeeShiftV2']
    }
  >
  EmployeeShiftStatus: EmployeeShiftStatus
  EmployeeShiftTip: ResolverTypeWrapper<EmployeeShiftTip>
  EmployeeShiftV2: ResolverTypeWrapper<
    ResolversInterfaceTypes<ResolversTypes>['EmployeeShiftV2']
  >
  EmployeeTakenBreak: ResolverTypeWrapper<
    ResolversInterfaceTypes<ResolversTypes>['EmployeeTakenBreak']
  >
  EmployeeTime: ResolverTypeWrapper<
    ResolversInterfaceTypes<ResolversTypes>['EmployeeTime']
  >
  EmployeeTips: ResolverTypeWrapper<
    Omit<EmployeeTips, 'orderTips'> & {
      orderTips: Array<ResolversTypes['EmployeeOrderTips']>
    }
  >
  EmployeeTipsV2: ResolverTypeWrapper<EmployeeTipsV2>
  EmployeeV2: ResolverTypeWrapper<EmployeeV2>
  EmployeeWorkType: EmployeeWorkType
  EmploymentStatus: ResolverTypeWrapper<
    ResolversInterfaceTypes<ResolversTypes>['EmploymentStatus']
  >
  EmptyCustomerBillingInvoice: ResolverTypeWrapper<
    Omit<EmptyCustomerBillingInvoice, 'items'> & {
      items?: Maybe<Array<ResolversTypes['CustomerBillingInvoiceItem']>>
    }
  >
  EntitlementsRepositoryDependencyFailure: ResolverTypeWrapper<EntitlementsRepositoryDependencyFailure>
  EntityAncestorPath: ResolverTypeWrapper<
    Omit<EntityAncestorPath, 'ancestors'> & {
      ancestors: Array<ResolversTypes['MenuEntity']>
    }
  >
  EntityAncestorResponse: ResolverTypeWrapper<
    Omit<EntityAncestorResponse, 'paths'> & {
      paths: Array<ResolversTypes['EntityAncestorPath']>
    }
  >
  Error: ResolverTypeWrapper<ResolversInterfaceTypes<ResolversTypes>['Error']>
  ErrorCode: ErrorCode
  ErrorRefundingDeposit: ResolverTypeWrapper<ErrorRefundingDeposit>
  ErrorResponse: ResolverTypeWrapper<ErrorResponse>
  Errors: ResolverTypeWrapper<ResolversInterfaceTypes<ResolversTypes>['Errors']>
  ExternalPartner: ResolverTypeWrapper<ExternalPartner>
  ExternalPartnerToggle: ResolverTypeWrapper<ExternalPartnerToggle>
  FetchCashFlowTransactionsRequest: FetchCashFlowTransactionsRequest
  FetchCashFlowTransactionsResponse: ResolverTypeWrapper<FetchCashFlowTransactionsResponse>
  FilterType: FilterType
  FinancialInsightsMutation: ResolverTypeWrapper<FinancialInsightsMutation>
  FinancialInsightsQuery: ResolverTypeWrapper<FinancialInsightsQuery>
  FinancialProductForCustomer: ResolverTypeWrapper<FinancialProductForCustomer>
  FinishedEmployeeShift: ResolverTypeWrapper<
    Omit<FinishedEmployeeShift, 'timeClock' | 'tips'> & {
      timeClock?: Maybe<ResolversTypes['EmployeeTime']>
      tips?: Maybe<ResolversTypes['EmployeeTips']>
    }
  >
  FinishedEmployeeShiftV2: ResolverTypeWrapper<
    Omit<FinishedEmployeeShiftV2, 'timeClock'> & {
      timeClock?: Maybe<ResolversTypes['EmployeeTime']>
    }
  >
  FinishedEmployeeTakenBreak: ResolverTypeWrapper<FinishedEmployeeTakenBreak>
  FinishedEmployeeTime: ResolverTypeWrapper<
    Omit<FinishedEmployeeTime, 'takenBreaks'> & {
      takenBreaks: Array<ResolversTypes['EmployeeTakenBreak']>
    }
  >
  FixedFeeLoan: ResolverTypeWrapper<FixedFeeLoan>
  FixedFeePQO: ResolverTypeWrapper<FixedFeePqo>
  FixedFeePromotionalOffer: ResolverTypeWrapper<FixedFeePromotionalOffer>
  FixedFeePromotionalOfferType: FixedFeePromotionalOfferType
  FoodWasteConfig: ResolverTypeWrapper<FoodWasteConfig>
  FoodWasteConfigFields: FoodWasteConfigFields
  FoodWasteConfigInput: FoodWasteConfigInput
  FoodWasteConfigOverride: ResolverTypeWrapper<FoodWasteConfigOverride>
  FoodWasteConfigOverrideInput: FoodWasteConfigOverrideInput
  FoodWasteEducationDashboardData: ResolverTypeWrapper<FoodWasteEducationDashboardData>
  FoodWasteEstimationInputs: ResolverTypeWrapper<FoodWasteEstimationInputs>
  FoodWasteEstimationServiceType: FoodWasteEstimationServiceType
  FoodWasteInsights: ResolverTypeWrapper<FoodWasteInsights>
  FoodWasteLocalOrganizationDetails: ResolverTypeWrapper<FoodWasteLocalOrganizationDetails>
  FoodWasteMutation: ResolverTypeWrapper<FoodWasteMutation>
  FoodWasteOpportunity: ResolverTypeWrapper<FoodWasteOpportunity>
  FoodWasteQuery: ResolverTypeWrapper<FoodWasteQuery>
  FullPublishStartInput: FullPublishStartInput
  FundingPartner: ResolverTypeWrapper<FundingPartner>
  GenerateGuestFeedbackResponseInput: GenerateGuestFeedbackResponseInput
  GeneratedGuestFeedbackAIResponse: ResolverTypeWrapper<GeneratedGuestFeedbackAiResponse>
  GeoCoordinates: ResolverTypeWrapper<GeoCoordinates>
  GetTipsResponse: ResolverTypeWrapper<GetTipsResponse>
  GetTrialsResponse: ResolverTypeWrapper<GetTrialsResponse>
  GfdAddOnStatus: ResolverTypeWrapper<GfdAddOnStatus>
  GfdConfig: ResolverTypeWrapper<
    Omit<GfdConfig, 'ownerRestaurantSet' | 'targetRestaurantSet'> & {
      ownerRestaurantSet: ResolversTypes['RestaurantSet']
      targetRestaurantSet: ResolversTypes['RestaurantSet']
    }
  >
  GfdImage: ResolverTypeWrapper<GfdImage>
  GfdImageInput: GfdImageInput
  GiftCardMutation: ResolverTypeWrapper<
    Omit<GiftCardMutation, 'updateReceiptConfig'> & {
      updateReceiptConfig?: Maybe<ResolversTypes['SavedGiftCardReceiptConfig']>
    }
  >
  GiftCardQuery: ResolverTypeWrapper<GiftCardQuery>
  GiftCardReceiptConfigUpdate: GiftCardReceiptConfigUpdate
  GiftReceiptPrintStrategy: GiftReceiptPrintStrategy
  GlobalNavigationGroup: ResolverTypeWrapper<GlobalNavigationGroup>
  GlobalNavigationMenu: ResolverTypeWrapper<GlobalNavigationMenu>
  Group: ResolverTypeWrapper<Group>
  GroupEntity: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['GroupEntity']
  >
  GroupType: GroupType
  GroupedChecksWithRefundsCount: ResolverTypeWrapper<GroupedChecksWithRefundsCount>
  GroupedChecksWithVoidsCount: ResolverTypeWrapper<
    Omit<GroupedChecksWithVoidsCount, 'entity'> & {
      entity?: Maybe<ResolversTypes['VoidGroupEntity']>
    }
  >
  GroupedDiscountAmount: ResolverTypeWrapper<
    Omit<GroupedDiscountAmount, 'entity'> & {
      entity?: Maybe<ResolversTypes['DiscountGroupEntity']>
    }
  >
  GroupedDiscountCount: ResolverTypeWrapper<
    Omit<GroupedDiscountCount, 'entity'> & {
      entity?: Maybe<ResolversTypes['DiscountGroupEntity']>
    }
  >
  GroupedLaborCost: ResolverTypeWrapper<
    Omit<GroupedLaborCost, 'entity'> & {
      entity?: Maybe<ResolversTypes['LaborCostGroupEntity']>
    }
  >
  GroupedNetSales: ResolverTypeWrapper<
    Omit<GroupedNetSales, 'entity'> & {
      entity?: Maybe<ResolversTypes['GroupEntity']>
    }
  >
  GroupedTotalRefundAmount: ResolverTypeWrapper<GroupedTotalRefundAmount>
  GroupedVoidAmount: ResolverTypeWrapper<
    Omit<GroupedVoidAmount, 'entity'> & {
      entity?: Maybe<ResolversTypes['VoidGroupEntity']>
    }
  >
  GroupedVoidAmountAsPercentageOfNetSales: ResolverTypeWrapper<
    Omit<GroupedVoidAmountAsPercentageOfNetSales, 'entity'> & {
      entity?: Maybe<ResolversTypes['VoidGroupEntity']>
    }
  >
  GroupedVoidedItemsCount: ResolverTypeWrapper<
    Omit<GroupedVoidedItemsCount, 'entity'> & {
      entity?: Maybe<ResolversTypes['VoidGroupEntity']>
    }
  >
  GroupingDimension: GroupingDimension
  GroupsFilterInput: GroupsFilterInput
  Guest: ResolverTypeWrapper<Guest>
  GuestAlreadyExists: ResolverTypeWrapper<GuestAlreadyExists>
  GuestCount: ResolverTypeWrapper<GuestCount>
  GuestCreationResponse: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['GuestCreationResponse']
  >
  GuestCreationResponseSuccess: ResolverTypeWrapper<GuestCreationResponseSuccess>
  GuestFeedbackAiSummary: ResolverTypeWrapper<GuestFeedbackAiSummary>
  GuestFeedbackContactInformation: ResolverTypeWrapper<GuestFeedbackContactInformation>
  GuestFeedbackCountByWeek: ResolverTypeWrapper<GuestFeedbackCountByWeek>
  GuestFeedbackFilterInput: GuestFeedbackFilterInput
  GuestFeedbackGenerateResponse: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['GuestFeedbackGenerateResponse']
  >
  GuestFeedbackGenerateResponseError: ResolverTypeWrapper<GuestFeedbackGenerateResponseError>
  GuestFeedbackGenerateResponseErrorCode: GuestFeedbackGenerateResponseErrorCode
  GuestFeedbackInfo: ResolverTypeWrapper<
    Omit<
      GuestFeedbackInfo,
      'check' | 'menuItem' | 'menuItemFeedbacks' | 'order'
    > & {
      check?: Maybe<ResolversTypes['Check']>
      menuItem?: Maybe<ResolversTypes['MenuItem']>
      menuItemFeedbacks: Array<ResolversTypes['MenuItemFeedback']>
      order?: Maybe<ResolversTypes['Order']>
    }
  >
  GuestFeedbackMutation: ResolverTypeWrapper<
    Omit<
      GuestFeedbackMutation,
      'feedbackStatus' | 'generateFeedbackResponse' | 'sendFeedbackResponse'
    > & {
      feedbackStatus: ResolversTypes['GuestFeedbackInfo']
      generateFeedbackResponse: ResolversTypes['GuestFeedbackGenerateResponse']
      sendFeedbackResponse: ResolversTypes['GuestFeedbackInfo']
    }
  >
  GuestFeedbackOrderCheckInfo: ResolverTypeWrapper<GuestFeedbackOrderCheckInfo>
  GuestFeedbackPaginationInput: GuestFeedbackPaginationInput
  GuestFeedbackQuery: ResolverTypeWrapper<
    Omit<
      GuestFeedbackQuery,
      'guestFeedbackDetails' | 'guestFeedbacks' | 'statsSummary'
    > & {
      guestFeedbackDetails?: Maybe<ResolversTypes['GuestFeedbackInfo']>
      guestFeedbacks: Array<ResolversTypes['GuestFeedbackInfo']>
      statsSummary?: Maybe<ResolversTypes['GuestFeedbackStatsSummaryResponse']>
    }
  >
  GuestFeedbackRatingType: GuestFeedbackRatingType
  GuestFeedbackRawRatingType: GuestFeedbackRawRatingType
  GuestFeedbackResponse: ResolverTypeWrapper<GuestFeedbackResponse>
  GuestFeedbackResponseType: GuestFeedbackResponseType
  GuestFeedbackSentimentByMenuItem: ResolverTypeWrapper<
    Omit<GuestFeedbackSentimentByMenuItem, 'menuItem'> & {
      menuItem?: Maybe<ResolversTypes['MenuItem']>
    }
  >
  GuestFeedbackSentimentOverview: ResolverTypeWrapper<GuestFeedbackSentimentOverview>
  GuestFeedbackStatsSummaryResponse: ResolverTypeWrapper<
    Omit<
      GuestFeedbackStatsSummaryResponse,
      'topNegativeMenuItems' | 'topPositiveMenuItems'
    > & {
      topNegativeMenuItems: Array<
        ResolversTypes['GuestFeedbackSentimentByMenuItem']
      >
      topPositiveMenuItems: Array<
        ResolversTypes['GuestFeedbackSentimentByMenuItem']
      >
    }
  >
  GuestFeedbackStatus: GuestFeedbackStatus
  GuestListResponse: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['GuestListResponse']
  >
  GuestListResponseSuccess: ResolverTypeWrapper<GuestListResponseSuccess>
  GuestNotFound: ResolverTypeWrapper<GuestNotFound>
  GuestQuoteTimes: ResolverTypeWrapper<GuestQuoteTimes>
  GuestUpdateResponse: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['GuestUpdateResponse']
  >
  GuestUpdateResponseSuccess: ResolverTypeWrapper<GuestUpdateResponseSuccess>
  HardwareItemOrdered: ResolverTypeWrapper<HardwareItemOrdered>
  HardwareOrder: ResolverTypeWrapper<HardwareOrder>
  HardwareOrderDeliveryAddress: ResolverTypeWrapper<HardwareOrderDeliveryAddress>
  HardwareOrderDeliveryInfo: ResolverTypeWrapper<HardwareOrderDeliveryInfo>
  HardwareOrderStatus: HardwareOrderStatus
  HardwareOrderTrackingInfo: ResolverTypeWrapper<HardwareOrderTrackingInfo>
  IDM_IDENTITY_TYPE: Idm_Identity_Type
  IRLCustomerBillingInvoice: ResolverTypeWrapper<
    Omit<IrlCustomerBillingInvoice, 'deductions'> & {
      deductions: ResolversTypes['CustomerBillingInvoiceDeductions']
    }
  >
  IRLCustomerBillingInvoiceItem: ResolverTypeWrapper<IrlCustomerBillingInvoiceItem>
  Identity: ResolverTypeWrapper<
    ResolversInterfaceTypes<ResolversTypes>['Identity']
  >
  IdentityInterface: ResolverTypeWrapper<
    ResolversInterfaceTypes<ResolversTypes>['IdentityInterface']
  >
  IdmAdminUser: ResolverTypeWrapper<IdmAdminUser>
  IdmCustomerUser: ResolverTypeWrapper<IdmCustomerUser>
  IdmExternalDeveloperUser: ResolverTypeWrapper<IdmExternalDeveloperUser>
  IdmGuestAppMetadata: ResolverTypeWrapper<IdmGuestAppMetadata>
  IdmGuestUser: ResolverTypeWrapper<IdmGuestUser>
  IdmIdentity: ResolverTypeWrapper<IdmIdentity>
  IdmIdentityVerification: ResolverTypeWrapper<IdmIdentityVerification>
  IdmName: ResolverTypeWrapper<IdmName>
  IdmUserEmailFilter: IdmUserEmailFilter
  IdmUserFilter: IdmUserFilter
  IdmUserIdFilter: IdmUserIdFilter
  IdmUserPhoneFilter: IdmUserPhoneFilter
  IdmUsername: ResolverTypeWrapper<IdmUsername>
  IdmUsersQuery: ResolverTypeWrapper<IdmUsersQuery>
  ImageEntityType: ImageEntityType
  ImageSetType: ImageSetType
  ImageUploadInput: ImageUploadInput
  ImplementationEmploymentStatus: ResolverTypeWrapper<ImplementationEmploymentStatus>
  InProgressEmployeeShift: ResolverTypeWrapper<
    Omit<InProgressEmployeeShift, 'timeClock' | 'tips'> & {
      timeClock?: Maybe<ResolversTypes['EmployeeTime']>
      tips?: Maybe<ResolversTypes['EmployeeTips']>
    }
  >
  InProgressEmployeeShiftV2: ResolverTypeWrapper<
    Omit<InProgressEmployeeShiftV2, 'timeClock'> & {
      timeClock?: Maybe<ResolversTypes['EmployeeTime']>
    }
  >
  InProgressEmployeeTakenBreak: ResolverTypeWrapper<InProgressEmployeeTakenBreak>
  InProgressEmployeeTime: ResolverTypeWrapper<
    Omit<InProgressEmployeeTime, 'takenBreaks'> & {
      takenBreaks: Array<ResolversTypes['EmployeeTakenBreak']>
    }
  >
  IntegerMetric: ResolverTypeWrapper<IntegerMetric>
  InteractionType: InteractionType
  IntercomClientType: IntercomClientType
  IntercomConversation: ResolverTypeWrapper<IntercomConversation>
  IntercomConversationPart: ResolverTypeWrapper<IntercomConversationPart>
  IntercomConversationPartAuthor: ResolverTypeWrapper<IntercomConversationPartAuthor>
  IntercomConversationPartAuthorType: IntercomConversationPartAuthorType
  IntercomConversationPartType: IntercomConversationPartType
  IntercomConversationState: IntercomConversationState
  IntercomOptions: ResolverTypeWrapper<IntercomOptions>
  IntercomUserHashResult: ResolverTypeWrapper<IntercomUserHashResult>
  InternalError: ResolverTypeWrapper<InternalError>
  IntraModifierGroupSortOrder: IntraModifierGroupSortOrder
  InvalidBreakConfig: ResolverTypeWrapper<InvalidBreakConfig>
  InvalidBreaks: ResolverTypeWrapper<InvalidBreaks>
  InvalidCustomerUuidError: ResolverTypeWrapper<InvalidCustomerUuidError>
  InvalidEmail: ResolverTypeWrapper<InvalidEmail>
  InvalidGuest: ResolverTypeWrapper<InvalidGuest>
  InvalidIdempotencyKeyError: ResolverTypeWrapper<InvalidIdempotencyKeyError>
  InvalidJob: ResolverTypeWrapper<InvalidJob>
  InvalidLocation: ResolverTypeWrapper<InvalidLocation>
  InvalidLocationReason: InvalidLocationReason
  InvalidPhoneNumber: ResolverTypeWrapper<InvalidPhoneNumber>
  InvalidRestaurantIDError: ResolverTypeWrapper<InvalidRestaurantIdError>
  InvalidUIOption: ResolverTypeWrapper<
    Omit<InvalidUiOption, 'smartTax'> & {
      smartTax?: Maybe<ResolversTypes['UIOptionInvalidSmartTax']>
    }
  >
  InvalidUpdatedTime: ResolverTypeWrapper<InvalidUpdatedTime>
  InvalidUuidError: ResolverTypeWrapper<InvalidUuidError>
  ItemFeedbackConfig: ResolverTypeWrapper<ItemFeedbackConfig>
  ItemFeedbackConfigAlreadyExists: ResolverTypeWrapper<ItemFeedbackConfigAlreadyExists>
  ItemFeedbackConfigError: ResolverTypeWrapper<ItemFeedbackConfigError>
  ItemFeedbackConfigErrorCode: ItemFeedbackConfigErrorCode
  ItemFeedbackConfigInput: ItemFeedbackConfigInput
  ItemFeedbackConfigResponse: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['ItemFeedbackConfigResponse']
  >
  ItemMetrics: ResolverTypeWrapper<
    Omit<ItemMetrics, 'item'> & { item: ResolversTypes['MenuItem'] }
  >
  ItemReference: ResolverTypeWrapper<ItemReference>
  JSON: ResolverTypeWrapper<Scalars['JSON']['output']>
  Job: ResolverTypeWrapper<Job>
  JobAssignmentDefaults: ResolverTypeWrapper<JobAssignmentDefaults>
  JobAssignmentDefaultsExtendedProperties: ResolverTypeWrapper<JobAssignmentDefaultsExtendedProperties>
  JobAssignmentInfo: ResolverTypeWrapper<JobAssignmentInfo>
  JobBasedAccessConfig: ResolverTypeWrapper<JobBasedAccessConfig>
  JobBasedAccessConfigInput: JobBasedAccessConfigInput
  JobError: ResolverTypeWrapper<JobError>
  JobExtendedProperties: ResolverTypeWrapper<JobExtendedProperties>
  JobInput: JobInput
  JobMapping: ResolverTypeWrapper<JobMapping>
  JobMappings: ResolverTypeWrapper<JobMappings>
  JobNotFoundError: ResolverTypeWrapper<JobNotFoundError>
  JobRequest: JobRequest
  JobResponse: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['JobResponse']
  >
  JobTagAssignment: ResolverTypeWrapper<JobTagAssignment>
  JobTagType: JobTagType
  JobTagValue: ResolverTypeWrapper<JobTagValue>
  JobV2: ResolverTypeWrapper<JobV2>
  JobsBatchRequest: JobsBatchRequest
  JobsBatchResponse: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['JobsBatchResponse']
  >
  JobsBatchSizeError: ResolverTypeWrapper<JobsBatchSizeError>
  JobsFilterInput: JobsFilterInput
  JobsFilterMissingError: ResolverTypeWrapper<JobsFilterMissingError>
  JobsLegacyBatchResponse: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['JobsLegacyBatchResponse']
  >
  JobsNotFoundBatchError: ResolverTypeWrapper<JobsNotFoundBatchError>
  JobsPaginatedResult: ResolverTypeWrapper<JobsPaginatedResult>
  JobsPaginationInput: JobsPaginationInput
  JobsRepositoryDependencyFailure: ResolverTypeWrapper<JobsRepositoryDependencyFailure>
  JobsResponse: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['JobsResponse']
  >
  JobsV2: ResolverTypeWrapper<JobsV2>
  KioskConfig: ResolverTypeWrapper<KioskConfig>
  KioskConfigServiceCharge: ResolverTypeWrapper<KioskConfigServiceCharge>
  KioskCustomerMessageSettings: ResolverTypeWrapper<KioskCustomerMessageSettings>
  KioskDiningOption: ResolverTypeWrapper<KioskDiningOption>
  KioskLoyalty: ResolverTypeWrapper<KioskLoyalty>
  KioskMutation: ResolverTypeWrapper<KioskMutation>
  KioskQuery: ResolverTypeWrapper<KioskQuery>
  KioskUpsellMenuItem: ResolverTypeWrapper<KioskUpsellMenuItem>
  KioskUpsellMenuItemSearchQuery: KioskUpsellMenuItemSearchQuery
  KioskVoidReason: ResolverTypeWrapper<KioskVoidReason>
  KitchenCapacityHealthStatus: ResolverTypeWrapper<KitchenCapacityHealthStatus>
  KitchenVersionResolution: KitchenVersionResolution
  LaborCost: ResolverTypeWrapper<
    Omit<LaborCost, 'grouped'> & {
      grouped: Array<ResolversTypes['GroupedLaborCost']>
    }
  >
  LaborCostAsPercentageOfNetSales: ResolverTypeWrapper<LaborCostAsPercentageOfNetSales>
  LaborCostGroupEntity: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['LaborCostGroupEntity']
  >
  LaborFilter: LaborFilter
  LaborGroupingDimension: LaborGroupingDimension
  LaborMetrics: ResolverTypeWrapper<
    Omit<LaborMetrics, 'laborCost'> & {
      laborCost?: Maybe<ResolversTypes['LaborCost']>
    }
  >
  LeaveOfAbsenceEmploymentStatus: ResolverTypeWrapper<LeaveOfAbsenceEmploymentStatus>
  LegacyCustomerAuthorizationInput: LegacyCustomerAuthorizationInput
  LegacyJobRequest: LegacyJobRequest
  LegacyJobsBatchRequest: LegacyJobsBatchRequest
  LoadShiftsFailure: ResolverTypeWrapper<LoadShiftsFailure>
  LoanApplicationForCustomer: ResolverTypeWrapper<LoanApplicationForCustomer>
  LocalTime: ResolverTypeWrapper<Scalars['LocalTime']['output']>
  LocalTimeRange: ResolverTypeWrapper<LocalTimeRange>
  LocalTimeRangeInput: LocalTimeRangeInput
  LocateShiftFailure: ResolverTypeWrapper<LocateShiftFailure>
  LocationGroup: ResolverTypeWrapper<LocationGroup>
  LocationGroupsInput: LocationGroupsInput
  LocationSetupInformation: ResolverTypeWrapper<LocationSetupInformation>
  LocationSpecificPrice: ResolverTypeWrapper<
    Omit<LocationSpecificPrice, 'pricing'> & {
      pricing?: Maybe<ResolversTypes['MenuItemPricing']>
    }
  >
  LogbookCategory: ResolverTypeWrapper<LogbookCategory>
  LogbookCategoryColorType: LogbookCategoryColorType
  LogbookContent: ResolverTypeWrapper<
    Omit<LogbookContent, 'contentBlocks' | 'tags'> & {
      contentBlocks: Array<ResolversTypes['LogbookContentBlock']>
      tags?: Maybe<Array<ResolversTypes['LogbookContentTag']>>
    }
  >
  LogbookContentBlock: ResolverTypeWrapper<
    ResolversInterfaceTypes<ResolversTypes>['LogbookContentBlock']
  >
  LogbookContentBlockInput: LogbookContentBlockInput
  LogbookContentBlockType: LogbookContentBlockType
  LogbookContentInput: LogbookContentInput
  LogbookContentTag: ResolverTypeWrapper<
    ResolversInterfaceTypes<ResolversTypes>['LogbookContentTag']
  >
  LogbookEntry: ResolverTypeWrapper<
    Omit<LogbookEntry, 'content' | 'replies' | 'source'> & {
      content: ResolversTypes['LogbookContent']
      replies: Array<ResolversTypes['LogbookEntryReply']>
      source: ResolversTypes['LogbookEntrySource']
    }
  >
  LogbookEntryPriority: LogbookEntryPriority
  LogbookEntryReply: ResolverTypeWrapper<
    Omit<LogbookEntryReply, 'content' | 'source'> & {
      content: ResolversTypes['LogbookContent']
      source: ResolversTypes['LogbookEntrySource']
    }
  >
  LogbookEntrySource: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['LogbookEntrySource']
  >
  LogbookMutation: ResolverTypeWrapper<
    Omit<
      LogbookMutation,
      | 'deleteLogbookEntry'
      | 'deleteLogbookEntryReply'
      | 'submitLogbookEntry'
      | 'submitLogbookEntryReply'
      | 'updateLogbookEntry'
      | 'updateLogbookEntryReply'
    > & {
      deleteLogbookEntry?: Maybe<ResolversTypes['LogbookEntry']>
      deleteLogbookEntryReply?: Maybe<ResolversTypes['LogbookEntryReply']>
      submitLogbookEntry?: Maybe<ResolversTypes['LogbookEntry']>
      submitLogbookEntryReply?: Maybe<ResolversTypes['LogbookEntryReply']>
      updateLogbookEntry?: Maybe<ResolversTypes['LogbookEntry']>
      updateLogbookEntryReply?: Maybe<ResolversTypes['LogbookEntryReply']>
    }
  >
  LogbookQuery: ResolverTypeWrapper<
    Omit<
      LogbookQuery,
      'fetchLogbookEntriesByBusinessDay' | 'fetchLogbookEntry'
    > & {
      fetchLogbookEntriesByBusinessDay?: Maybe<
        Array<ResolversTypes['LogbookEntry']>
      >
      fetchLogbookEntry?: Maybe<ResolversTypes['LogbookEntry']>
    }
  >
  LogbookTextContentBlock: ResolverTypeWrapper<LogbookTextContentBlock>
  LogbookUserContentBlock: ResolverTypeWrapper<LogbookUserContentBlock>
  LogbookUserTag: ResolverTypeWrapper<LogbookUserTag>
  LogoMode: LogoMode
  Long: ResolverTypeWrapper<Scalars['Long']['output']>
  LoyaltyPromptMode: LoyaltyPromptMode
  ManagementGroup: ResolverTypeWrapper<ManagementGroup>
  ManagementSet: ResolverTypeWrapper<ManagementSet>
  ManagerClockOutResult: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['ManagerClockOutResult']
  >
  MarkdownText: ResolverTypeWrapper<Scalars['MarkdownText']['output']>
  MarketSegment: MarketSegment
  MarketingCampaign: ResolverTypeWrapper<MarketingCampaign>
  MarketingCampaignChannel: MarketingCampaignChannel
  MarketingCampaignCustomProperties: ResolverTypeWrapper<MarketingCampaignCustomProperties>
  MarketingCampaignFilterInput: MarketingCampaignFilterInput
  MarketingCampaignPaginationInput: MarketingCampaignPaginationInput
  MarketingCampaignStatus: MarketingCampaignStatus
  MarketingCampaignTrigger: ResolverTypeWrapper<MarketingCampaignTrigger>
  MarketingCampaignTriggerType: MarketingCampaignTriggerType
  MarketingQuery: ResolverTypeWrapper<
    Omit<MarketingQuery, 'emailCampaign' | 'emailCampaigns'> & {
      emailCampaign: ResolversTypes['EmailMarketingCampaign']
      emailCampaigns: Array<ResolversTypes['EmailMarketingCampaign']>
    }
  >
  MemberGuestBookData: ResolverTypeWrapper<MemberGuestBookData>
  Membership: ResolverTypeWrapper<Membership>
  MembershipAddress: ResolverTypeWrapper<MembershipAddress>
  MembershipAddressInput: MembershipAddressInput
  MembershipInvoice: ResolverTypeWrapper<MembershipInvoice>
  MembershipInvoiceStatus: MembershipInvoiceStatus
  MembershipInvoicesInput: MembershipInvoicesInput
  MembershipInvoicesResponse: ResolverTypeWrapper<MembershipInvoicesResponse>
  MembershipInvoicesSortColumn: MembershipInvoicesSortColumn
  MembershipTransaction: ResolverTypeWrapper<MembershipTransaction>
  MembershipTransactionType: MembershipTransactionType
  MembershipTransactionsInput: MembershipTransactionsInput
  MembershipTransactionsResponse: ResolverTypeWrapper<MembershipTransactionsResponse>
  MembershipTransactionsSortColumn: MembershipTransactionsSortColumn
  MembershipsFilters: MembershipsFilters
  MembershipsInput: MembershipsInput
  MembershipsMutation: ResolverTypeWrapper<MembershipsMutation>
  MembershipsQuery: ResolverTypeWrapper<MembershipsQuery>
  MembershipsResponse: ResolverTypeWrapper<MembershipsResponse>
  MembershipsSettings: ResolverTypeWrapper<MembershipsSettings>
  MembershipsSettingsInput: MembershipsSettingsInput
  MembershipsSettingsResponse: ResolverTypeWrapper<MembershipsSettingsResponse>
  MembershipsSortColumn: MembershipsSortColumn
  MembershipsSummary: ResolverTypeWrapper<MembershipsSummary>
  Menu: ResolverTypeWrapper<
    Omit<
      Menu,
      | 'channelMenuEntities'
      | 'menuGroups'
      | 'ownerRestaurantSet'
      | 'targetRestaurantSet'
    > & {
      channelMenuEntities?: Maybe<Array<ResolversTypes['ChannelMenuEntity']>>
      menuGroups?: Maybe<Array<ResolversTypes['MenuGroup']>>
      ownerRestaurantSet?: Maybe<ResolversTypes['RestaurantSet']>
      targetRestaurantSet: ResolversTypes['RestaurantSet']
    }
  >
  MenuAvailabilitySchedule: MenuAvailabilitySchedule
  MenuAvailabilityTimeRange: MenuAvailabilityTimeRange
  MenuEntitiesInput: MenuEntitiesInput
  MenuEntity: ResolverTypeWrapper<
    ResolversInterfaceTypes<ResolversTypes>['MenuEntity']
  >
  MenuEntityAlcohol: ResolverTypeWrapper<MenuEntityAlcohol>
  MenuEntityAlcoholInput: MenuEntityAlcoholInput
  MenuEntityAncestorsQuery: MenuEntityAncestorsQuery
  MenuEntityContentAdvisories: ResolverTypeWrapper<MenuEntityContentAdvisories>
  MenuEntityDiningOptionTax: MenuEntityDiningOptionTax
  MenuEntityImage: ResolverTypeWrapper<MenuEntityImage>
  MenuEntityPointOfSale: ResolverTypeWrapper<MenuEntityPointOfSale>
  MenuEntityQuery: MenuEntityQuery
  MenuEntityReorder: MenuEntityReorder
  MenuEntityReports: ResolverTypeWrapper<MenuEntityReports>
  MenuEntityTaxInclusionOption: MenuEntityTaxInclusionOption
  MenuEntityTaxes: ResolverTypeWrapper<MenuEntityTaxes>
  MenuEntityType: MenuEntityType
  MenuEntityVisibility: ResolverTypeWrapper<MenuEntityVisibility>
  MenuGroup: ResolverTypeWrapper<
    Omit<
      MenuGroup,
      | 'menuGroups'
      | 'menuItems'
      | 'modifierGroups'
      | 'ownerRestaurantSet'
      | 'targetRestaurantSet'
    > & {
      menuGroups?: Maybe<Array<ResolversTypes['MenuGroup']>>
      menuItems: Array<ResolversTypes['MenuItem']>
      modifierGroups: Array<ResolversTypes['ModifierGroup']>
      ownerRestaurantSet?: Maybe<ResolversTypes['RestaurantSet']>
      targetRestaurantSet: ResolversTypes['RestaurantSet']
    }
  >
  MenuItem: ResolverTypeWrapper<
    Omit<
      MenuItem,
      | 'ancestors'
      | 'modifierGroups'
      | 'ownerRestaurantSet'
      | 'pricing'
      | 'targetRestaurantSet'
    > & {
      ancestors: Array<ResolversTypes['EntityAncestorResponse']>
      modifierGroups: Array<ResolversTypes['ModifierGroup']>
      ownerRestaurantSet?: Maybe<ResolversTypes['RestaurantSet']>
      pricing?: Maybe<ResolversTypes['MenuItemPricing']>
      targetRestaurantSet: ResolversTypes['RestaurantSet']
    }
  >
  MenuItemFeedback: ResolverTypeWrapper<
    Omit<MenuItemFeedback, 'menuItem'> & {
      menuItem: ResolversTypes['MenuItem']
    }
  >
  MenuItemIcon: MenuItemIcon
  MenuItemInventoryConnection: ResolverTypeWrapper<
    Omit<MenuItemInventoryConnection, 'edges'> & {
      edges: Array<ResolversTypes['RestaurantMenuItemEdge']>
    }
  >
  MenuItemPlateCost: ResolverTypeWrapper<MenuItemPlateCost>
  MenuItemPricing: ResolverTypeWrapper<
    Omit<
      MenuItemPricing,
      'locationSpecificPrices' | 'priceLevels' | 'sizePrices'
    > & {
      locationSpecificPrices?: Maybe<
        Array<ResolversTypes['LocationSpecificPrice']>
      >
      priceLevels?: Maybe<Array<ResolversTypes['PriceLevelEntity']>>
      sizePrices?: Maybe<Array<ResolversTypes['SizePrice']>>
    }
  >
  MenuItemPricingStrategy: MenuItemPricingStrategy
  MenuItemStockUpdate: MenuItemStockUpdate
  MenuPathsQuery: MenuPathsQuery
  MenuReorder: MenuReorder
  MenuSpecificPrice: ResolverTypeWrapper<
    Omit<MenuSpecificPrice, 'pricing'> & {
      pricing?: Maybe<ResolversTypes['MenuItemPricing']>
    }
  >
  MenuUpsellConfigMutation: ResolverTypeWrapper<
    Omit<
      MenuUpsellConfigMutation,
      | 'archiveCustomMenuItemUpsell'
      | 'saveCustomMenuItemUpsell'
      | 'saveCustomMenuItemUpsells'
    > & {
      archiveCustomMenuItemUpsell: ResolversTypes['CustomMenuItemUpsell']
      saveCustomMenuItemUpsell: ResolversTypes['CustomMenuItemUpsell']
      saveCustomMenuItemUpsells: Array<ResolversTypes['CustomMenuItemUpsell']>
    }
  >
  MenuUpsellConfigQuery: ResolverTypeWrapper<
    Omit<MenuUpsellConfigQuery, 'customMenuItemUpsells'> & {
      customMenuItemUpsells: Array<ResolversTypes['CustomMenuItemUpsell']>
    }
  >
  MenusAncestorEntityInput: MenusAncestorEntityInput
  MenusAncestorQuery: MenusAncestorQuery
  MenusMoneyInput: MenusMoneyInput
  MenusVersionResolution: MenusVersionResolution
  MessageOptionMode: MessageOptionMode
  MgmtDailySummaryConfig: ResolverTypeWrapper<MgmtDailySummaryConfig>
  MgmtDepositBookableConfig: ResolverTypeWrapper<
    Omit<MgmtDepositBookableConfig, 'strategy'> & {
      strategy: ResolversTypes['DepositStrategy']
    }
  >
  MgmtEmailConfig: ResolverTypeWrapper<MgmtEmailConfig>
  MgmtEmailTemplate: ResolverTypeWrapper<MgmtEmailTemplate>
  MgmtRestaurantInformation: ResolverTypeWrapper<
    Omit<MgmtRestaurantInformation, 'depositBookableConfigs'> & {
      depositBookableConfigs: Array<ResolversTypes['MgmtDepositBookableConfig']>
    }
  >
  MgmtRestaurantInformationResponse: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['MgmtRestaurantInformationResponse']
  >
  MgmtServiceArea: ResolverTypeWrapper<MgmtServiceArea>
  MgmtServiceAreaGroup: ResolverTypeWrapper<MgmtServiceAreaGroup>
  MgmtSmsConfig: ResolverTypeWrapper<MgmtSmsConfig>
  MgmtSmsTemplate: ResolverTypeWrapper<MgmtSmsTemplate>
  MgmtTable: ResolverTypeWrapper<MgmtTable>
  MgmtWaitlistAutoRemoveConfig: ResolverTypeWrapper<MgmtWaitlistAutoRemoveConfig>
  MinimumRepayment: ResolverTypeWrapper<MinimumRepayment>
  MissingJob: ResolverTypeWrapper<MissingJob>
  MissingTimeClock: ResolverTypeWrapper<MissingTimeClock>
  MlmSavedConfig: ResolverTypeWrapper<
    ResolversInterfaceTypes<ResolversTypes>['MlmSavedConfig']
  >
  MlmSavedConfigVariant: ResolverTypeWrapper<
    ResolversInterfaceTypes<ResolversTypes>['MlmSavedConfigVariant']
  >
  MlmSavedVersion: ResolverTypeWrapper<
    Omit<MlmSavedVersion, 'owner' | 'target'> & {
      owner: ResolversTypes['RestaurantSet']
      target: ResolversTypes['RestaurantSet']
    }
  >
  MlmUIOption: ResolverTypeWrapper<
    Omit<MlmUiOption, 'variants'> & {
      variants: Array<ResolversTypes['MlmUIOptionVariant']>
    }
  >
  MlmUIOptionVariant: ResolverTypeWrapper<
    Omit<MlmUiOptionVariant, 'additionalModifierGroups' | 'versions'> & {
      additionalModifierGroups?: Maybe<
        ResolversTypes['AdditionalModifierGroups']
      >
      versions: Array<ResolversTypes['MlmSavedVersion']>
    }
  >
  MlmUIOptionVariantFilterInput: MlmUiOptionVariantFilterInput
  Modifier: ResolverTypeWrapper<
    Omit<
      Modifier,
      'itemReference' | 'ownerRestaurantSet' | 'pricing' | 'targetRestaurantSet'
    > & {
      itemReference?: Maybe<ResolversTypes['ItemReference']>
      ownerRestaurantSet?: Maybe<ResolversTypes['RestaurantSet']>
      pricing?: Maybe<ResolversTypes['MenuItemPricing']>
      targetRestaurantSet: ResolversTypes['RestaurantSet']
    }
  >
  ModifierDisplayMode: ModifierDisplayMode
  ModifierGroup: ResolverTypeWrapper<
    Omit<
      ModifierGroup,
      'modifiers' | 'ownerRestaurantSet' | 'targetRestaurantSet'
    > & {
      modifiers?: Maybe<Array<ResolversTypes['Modifier']>>
      ownerRestaurantSet?: Maybe<ResolversTypes['RestaurantSet']>
      targetRestaurantSet: ResolversTypes['RestaurantSet']
    }
  >
  ModifierGroupPointOfSale: ResolverTypeWrapper<ModifierGroupPointOfSale>
  ModifierGroupPricing: ResolverTypeWrapper<ModifierGroupPricing>
  ModifierGroupPricingStrategy: ModifierGroupPricingStrategy
  ModifierGroupSelections: ResolverTypeWrapper<ModifierGroupSelections>
  ModifierGroupType: ModifierGroupType
  ModifierMetrics: ResolverTypeWrapper<
    Omit<ModifierMetrics, 'modifier' | 'parent'> & {
      modifier: ResolversTypes['MenuItem']
      parent: ResolversTypes['MenuItem']
    }
  >
  Money: ResolverTypeWrapper<Money>
  MoneyInput: MoneyInput
  MoneyMetric: ResolverTypeWrapper<MoneyMetric>
  MostRecentImportGuidResponse: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['MostRecentImportGuidResponse']
  >
  MostRecentImportGuidResponseSuccess: ResolverTypeWrapper<MostRecentImportGuidResponseSuccess>
  Mutation: ResolverTypeWrapper<{}>
  NavigationCategory: ResolverTypeWrapper<NavigationCategory>
  NavigationCategoryType: NavigationCategoryType
  NavigationConfigLocation: ResolverTypeWrapper<NavigationConfigLocation>
  NavigationGroup: ResolverTypeWrapper<NavigationGroup>
  NavigationHeader: ResolverTypeWrapper<NavigationHeader>
  NavigationLink: ResolverTypeWrapper<NavigationLink>
  NavigationMenuItem: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['NavigationMenuItem']
  >
  NavigationMenuItemIcon: NavigationMenuItemIcon
  NavigationPage: ResolverTypeWrapper<NavigationPage>
  NavigationPageRoutingStrategy: NavigationPageRoutingStrategy
  NavigationProfileLink: ResolverTypeWrapper<NavigationProfileLink>
  NavigationProfileLinkType: NavigationProfileLinkType
  NavigationQuery: ResolverTypeWrapper<
    Omit<NavigationQuery, 'adminMenu'> & {
      adminMenu: Array<ResolversTypes['NavigationMenuItem']>
    }
  >
  NavigationQuickAction: ResolverTypeWrapper<NavigationQuickAction>
  NavigationSection: ResolverTypeWrapper<NavigationSection>
  NavigationTask: ResolverTypeWrapper<NavigationTask>
  NavigationTaskAction: ResolverTypeWrapper<NavigationTaskAction>
  NavigationTaskType: NavigationTaskType
  NetSales: ResolverTypeWrapper<
    Omit<NetSales, 'grouped'> & {
      grouped: Array<ResolversTypes['GroupedNetSales']>
    }
  >
  NetSalesDateRangeMetrics: ResolverTypeWrapper<NetSalesDateRangeMetrics>
  NetSalesPerLaborHour: ResolverTypeWrapper<NetSalesPerLaborHour>
  NetworkDevice: ResolverTypeWrapper<NetworkDevice>
  NetworkDeviceInput: NetworkDeviceInput
  NetworkDeviceStatus: NetworkDeviceStatus
  NetworkDeviceType: NetworkDeviceType
  NetworkDeviceVendor: NetworkDeviceVendor
  NetworkDevicesInput: NetworkDevicesInput
  NetworkManagementType: NetworkManagementType
  NetworkQueries: ResolverTypeWrapper<
    Omit<NetworkQueries, 'getStatus'> & {
      getStatus: ResolversTypes['NetworkStatusResponse']
    }
  >
  NetworkStatus: NetworkStatus
  NetworkStatusData: ResolverTypeWrapper<NetworkStatusData>
  NetworkStatusError: ResolverTypeWrapper<NetworkStatusError>
  NetworkStatusErrorCode: NetworkStatusErrorCode
  NetworkStatusInput: NetworkStatusInput
  NetworkStatusResponse: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['NetworkStatusResponse']
  >
  NeverEmployedEmploymentStatus: ResolverTypeWrapper<NeverEmployedEmploymentStatus>
  NoJobIdsProvidedBatchError: ResolverTypeWrapper<NoJobIdsProvidedBatchError>
  NoPreviousImports: ResolverTypeWrapper<NoPreviousImports>
  NotificationContact: ResolverTypeWrapper<NotificationContact>
  NotificationContactInput: NotificationContactInput
  OnboardingAction: ResolverTypeWrapper<
    ResolversInterfaceTypes<ResolversTypes>['OnboardingAction']
  >
  OnboardingActionType: OnboardingActionType
  OnboardingDefaultAction: ResolverTypeWrapper<OnboardingDefaultAction>
  OnboardingDefaultInfo: ResolverTypeWrapper<
    Omit<OnboardingDefaultInfo, 'action'> & {
      action?: Maybe<ResolversTypes['OnboardingAction']>
    }
  >
  OnboardingDefaultItem: ResolverTypeWrapper<
    Omit<OnboardingDefaultItem, 'action'> & {
      action?: Maybe<ResolversTypes['OnboardingStepAction']>
    }
  >
  OnboardingDefaultItemMetadataV2: ResolverTypeWrapper<OnboardingDefaultItemMetadataV2>
  OnboardingDefaultItemV2: ResolverTypeWrapper<
    Omit<OnboardingDefaultItemV2, 'action' | 'metadata'> & {
      action?: Maybe<ResolversTypes['OnboardingAction']>
      metadata?: Maybe<ResolversTypes['OnboardingItemMetadataV2']>
    }
  >
  OnboardingDefaultStepDetails: ResolverTypeWrapper<
    Omit<OnboardingDefaultStepDetails, 'actions' | 'info'> & {
      actions: Array<Maybe<ResolversTypes['OnboardingAction']>>
      info: Array<Maybe<ResolversTypes['OnboardingStepInfo']>>
    }
  >
  OnboardingDefaultStepInfo: ResolverTypeWrapper<
    Omit<OnboardingDefaultStepInfo, 'action'> & {
      action?: Maybe<ResolversTypes['OnboardingAction']>
    }
  >
  OnboardingIcon: OnboardingIcon
  OnboardingImplementationStep: ResolverTypeWrapper<
    Omit<OnboardingImplementationStep, 'details' | 'items'> & {
      details?: Maybe<ResolversTypes['OnboardingSetupStepDetails']>
      items?: Maybe<Array<ResolversTypes['OnboardingItem']>>
    }
  >
  OnboardingImplementationStepV2: ResolverTypeWrapper<
    Omit<OnboardingImplementationStepV2, 'items'> & {
      items: Array<ResolversTypes['OnboardingItemV2']>
    }
  >
  OnboardingInfo: ResolverTypeWrapper<
    ResolversInterfaceTypes<ResolversTypes>['OnboardingInfo']
  >
  OnboardingInfoStep: ResolverTypeWrapper<
    Omit<OnboardingInfoStep, 'details'> & {
      details?: Maybe<ResolversTypes['OnboardingSetupStepDetails']>
    }
  >
  OnboardingItem: ResolverTypeWrapper<
    ResolversInterfaceTypes<ResolversTypes>['OnboardingItem']
  >
  OnboardingItemMetadata: ResolverTypeWrapper<OnboardingItemMetadata>
  OnboardingItemMetadataV2: ResolverTypeWrapper<
    ResolversInterfaceTypes<ResolversTypes>['OnboardingItemMetadataV2']
  >
  OnboardingItemV2: ResolverTypeWrapper<
    ResolversInterfaceTypes<ResolversTypes>['OnboardingItemV2']
  >
  OnboardingLegacyBadgeType: OnboardingLegacyBadgeType
  OnboardingLegacyDynamicLinkAction: ResolverTypeWrapper<OnboardingLegacyDynamicLinkAction>
  OnboardingLegacyItem: ResolverTypeWrapper<
    Omit<OnboardingLegacyItem, 'action'> & {
      action?: Maybe<ResolversTypes['OnboardingStepAction']>
    }
  >
  OnboardingLegacyItemDetails: ResolverTypeWrapper<
    Omit<OnboardingLegacyItemDetails, 'legacyItems'> & {
      legacyItems: Array<ResolversTypes['OnboardingLegacyItem']>
    }
  >
  OnboardingLegacyStatusBadge: ResolverTypeWrapper<OnboardingLegacyStatusBadge>
  OnboardingLinkAction: ResolverTypeWrapper<OnboardingLinkAction>
  OnboardingMeetingItem: ResolverTypeWrapper<
    Omit<OnboardingMeetingItem, 'action' | 'metadata'> & {
      action?: Maybe<ResolversTypes['OnboardingAction']>
      metadata?: Maybe<ResolversTypes['OnboardingMeetingItemMetadata']>
    }
  >
  OnboardingMeetingItemMetadata: ResolverTypeWrapper<
    Omit<
      OnboardingMeetingItemMetadata,
      'actionSheet' | 'agenda' | 'preparationSteps'
    > & {
      actionSheet: Array<ResolversTypes['OnboardingInfo']>
      agenda: Array<ResolversTypes['OnboardingInfo']>
      preparationSteps: Array<ResolversTypes['OnboardingInfo']>
    }
  >
  OnboardingMeetingStep: ResolverTypeWrapper<
    Omit<OnboardingMeetingStep, 'details'> & {
      details?: Maybe<ResolversTypes['OnboardingMeetingStepDetails']>
    }
  >
  OnboardingMeetingStepDetails: ResolverTypeWrapper<
    Omit<OnboardingMeetingStepDetails, 'actions' | 'info'> & {
      actions: Array<Maybe<ResolversTypes['OnboardingAction']>>
      info: Array<Maybe<ResolversTypes['OnboardingStepInfo']>>
    }
  >
  OnboardingModule: OnboardingModule
  OnboardingMutation: ResolverTypeWrapper<OnboardingMutation>
  OnboardingNavigationAction: ResolverTypeWrapper<OnboardingNavigationAction>
  OnboardingQuery: ResolverTypeWrapper<
    Omit<
      OnboardingQuery,
      'locationSetupSteps' | 'locationSetupStepsV2' | 'onboardingSteps'
    > & {
      locationSetupSteps?: Maybe<Array<ResolversTypes['OnboardingSetupStep']>>
      locationSetupStepsV2?: Maybe<
        Array<ResolversTypes['OnboardingSetupStepV2']>
      >
      onboardingSteps?: Maybe<Array<ResolversTypes['OnboardingStep']>>
    }
  >
  OnboardingScheduleAppointmentLinkAction: ResolverTypeWrapper<OnboardingScheduleAppointmentLinkAction>
  OnboardingSetupStep: ResolverTypeWrapper<
    ResolversInterfaceTypes<ResolversTypes>['OnboardingSetupStep']
  >
  OnboardingSetupStepDetails: ResolverTypeWrapper<
    ResolversInterfaceTypes<ResolversTypes>['OnboardingSetupStepDetails']
  >
  OnboardingSetupStepV2: ResolverTypeWrapper<
    ResolversInterfaceTypes<ResolversTypes>['OnboardingSetupStepV2']
  >
  OnboardingShareAction: ResolverTypeWrapper<OnboardingShareAction>
  OnboardingStatus: OnboardingStatus
  OnboardingStep: ResolverTypeWrapper<
    Omit<OnboardingStep, 'details' | 'itemDetails'> & {
      details?: Maybe<ResolversTypes['OnboardingStepDetails']>
      itemDetails?: Maybe<ResolversTypes['OnboardingLegacyItemDetails']>
    }
  >
  OnboardingStepAction: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['OnboardingStepAction']
  >
  OnboardingStepDetails: ResolverTypeWrapper<
    Omit<OnboardingStepDetails, 'action' | 'substeps'> & {
      action?: Maybe<ResolversTypes['OnboardingStepAction']>
      substeps: Array<ResolversTypes['OnboardingStep']>
    }
  >
  OnboardingStepInfo: ResolverTypeWrapper<
    ResolversInterfaceTypes<ResolversTypes>['OnboardingStepInfo']
  >
  OnboardingStepTypes: OnboardingStepTypes
  OnboardingStepsFilter: OnboardingStepsFilter
  OnboardingStepsFilterV2: OnboardingStepsFilterV2
  OnlineOrderingAvailability: OnlineOrderingAvailability
  OnlineOrderingStatusLabel: ResolverTypeWrapper<OnlineOrderingStatusLabel>
  OnlineOrderingStatusLabelState: OnlineOrderingStatusLabelState
  OpenCashDrawerReason: ResolverTypeWrapper<OpenCashDrawerReason>
  OperatingSchedule: ResolverTypeWrapper<OperatingSchedule>
  OperatingScheduleService: ResolverTypeWrapper<OperatingScheduleService>
  OperatingScheduleServicePeriod: ResolverTypeWrapper<OperatingScheduleServicePeriod>
  OptMenuConfig: ResolverTypeWrapper<OptMenuConfig>
  OptMenuConfigInput: OptMenuConfigInput
  OptMenuConfigOverride: ResolverTypeWrapper<
    Omit<OptMenuConfigOverride, 'target'> & {
      target: ResolversTypes['RestaurantSet']
    }
  >
  OptMenuConfigOverrideInput: OptMenuConfigOverrideInput
  OptMutation: ResolverTypeWrapper<
    Omit<
      OptMutation,
      | 'deleteOptMenuConfigOverrides'
      | 'deleteOptOrderingConfigOverrides'
      | 'deleteOptPaymentConfigOverrides'
      | 'deleteOptSpotlightConfigOverrides'
      | 'saveOptMenuConfigBase'
      | 'saveOptMenuConfigOverrides'
      | 'saveOptOrderingConfigBase'
      | 'saveOptOrderingConfigOverrides'
      | 'saveOptPaymentConfigBase'
      | 'saveOptPaymentConfigOverrides'
      | 'saveOptSpotlightConfigBase'
      | 'saveOptSpotlightConfigOverrides'
    > & {
      deleteOptMenuConfigOverrides: ResolversTypes['OptSavedMenuConfig']
      deleteOptOrderingConfigOverrides: ResolversTypes['OptSavedOrderingConfig']
      deleteOptPaymentConfigOverrides: ResolversTypes['OptSavedPaymentConfig']
      deleteOptSpotlightConfigOverrides: ResolversTypes['OptSavedSpotlightConfig']
      saveOptMenuConfigBase: ResolversTypes['OptMenuConfig']
      saveOptMenuConfigOverrides: ResolversTypes['OptSavedMenuConfig']
      saveOptOrderingConfigBase: ResolversTypes['OptOrderingConfig']
      saveOptOrderingConfigOverrides: ResolversTypes['OptSavedOrderingConfig']
      saveOptPaymentConfigBase: ResolversTypes['OptPaymentConfig']
      saveOptPaymentConfigOverrides: ResolversTypes['OptSavedPaymentConfig']
      saveOptSpotlightConfigBase: ResolversTypes['OptSpotlightConfig']
      saveOptSpotlightConfigOverrides: ResolversTypes['OptSavedSpotlightConfig']
    }
  >
  OptOrderingConfig: ResolverTypeWrapper<OptOrderingConfig>
  OptOrderingConfigInput: OptOrderingConfigInput
  OptOrderingConfigOverride: ResolverTypeWrapper<
    Omit<OptOrderingConfigOverride, 'target'> & {
      target: ResolversTypes['RestaurantSet']
    }
  >
  OptOrderingConfigOverrideInput: OptOrderingConfigOverrideInput
  OptPaymentConfig: ResolverTypeWrapper<OptPaymentConfig>
  OptPaymentConfigInput: OptPaymentConfigInput
  OptPaymentConfigOverride: ResolverTypeWrapper<
    Omit<OptPaymentConfigOverride, 'target'> & {
      target: ResolversTypes['RestaurantSet']
    }
  >
  OptPaymentConfigOverrideInput: OptPaymentConfigOverrideInput
  OptQuery: ResolverTypeWrapper<
    Omit<
      OptQuery,
      | 'resolvedOptMenuConfig'
      | 'resolvedOptOrderingConfig'
      | 'resolvedOptPaymentConfig'
      | 'resolvedOptSpotlightConfig'
      | 'savedOptMenuConfig'
      | 'savedOptOrderingConfig'
      | 'savedOptPaymentConfig'
      | 'savedOptSpotlightConfig'
    > & {
      resolvedOptMenuConfig: ResolversTypes['OptMenuConfig']
      resolvedOptOrderingConfig: ResolversTypes['OptOrderingConfig']
      resolvedOptPaymentConfig: ResolversTypes['OptPaymentConfig']
      resolvedOptSpotlightConfig: ResolversTypes['OptSpotlightConfig']
      savedOptMenuConfig: ResolversTypes['OptSavedMenuConfig']
      savedOptOrderingConfig: ResolversTypes['OptSavedOrderingConfig']
      savedOptPaymentConfig: ResolversTypes['OptSavedPaymentConfig']
      savedOptSpotlightConfig: ResolversTypes['OptSavedSpotlightConfig']
    }
  >
  OptSavedMenuConfig: ResolverTypeWrapper<
    Omit<OptSavedMenuConfig, 'baseConfig' | 'overrides'> & {
      baseConfig: ResolversTypes['OptMenuConfig']
      overrides?: Maybe<Array<ResolversTypes['OptMenuConfigOverride']>>
    }
  >
  OptSavedOrderingConfig: ResolverTypeWrapper<
    Omit<OptSavedOrderingConfig, 'baseConfig' | 'overrides'> & {
      baseConfig: ResolversTypes['OptOrderingConfig']
      overrides?: Maybe<Array<ResolversTypes['OptOrderingConfigOverride']>>
    }
  >
  OptSavedPaymentConfig: ResolverTypeWrapper<
    Omit<OptSavedPaymentConfig, 'baseConfig' | 'overrides'> & {
      baseConfig: ResolversTypes['OptPaymentConfig']
      overrides?: Maybe<Array<ResolversTypes['OptPaymentConfigOverride']>>
    }
  >
  OptSavedSpotlightConfig: ResolverTypeWrapper<
    Omit<OptSavedSpotlightConfig, 'baseConfig' | 'overrides'> & {
      baseConfig: ResolversTypes['OptSpotlightConfig']
      overrides?: Maybe<Array<ResolversTypes['OptSpotlightConfigOverride']>>
    }
  >
  OptSpotlightConfig: ResolverTypeWrapper<OptSpotlightConfig>
  OptSpotlightConfigInput: OptSpotlightConfigInput
  OptSpotlightConfigOverride: ResolverTypeWrapper<
    Omit<OptSpotlightConfigOverride, 'target'> & {
      target: ResolversTypes['RestaurantSet']
    }
  >
  OptSpotlightConfigOverrideInput: OptSpotlightConfigOverrideInput
  OptionalFloat: OptionalFloat
  OptionalID: OptionalId
  OptionalInt: OptionalInt
  Order: ResolverTypeWrapper<
    Omit<Order, 'channel' | 'checks' | 'diningOption'> & {
      channel?: Maybe<ResolversTypes['PublishedChannel']>
      checks: Array<ResolversTypes['Check']>
      diningOption?: Maybe<ResolversTypes['SavedDiningOption']>
    }
  >
  OrderConfigEditabilityLevel: OrderConfigEditabilityLevel
  OrderConfirmationScreenConfigInput: OrderConfirmationScreenConfigInput
  OrderConfirmationScreenConfigOverride: ResolverTypeWrapper<
    Omit<OrderConfirmationScreenConfigOverride, 'target'> & {
      target: ResolversTypes['RestaurantSet']
    }
  >
  OrderConfirmationScreenConfigOverrideInput: OrderConfirmationScreenConfigOverrideInput
  OrderConfirmationScreenFontSize: OrderConfirmationScreenFontSize
  OrderCount: ResolverTypeWrapper<OrderCount>
  OrderDeliveryServicesProvider: OrderDeliveryServicesProvider
  OrderDiningOptionBehavior: OrderDiningOptionBehavior
  OrderMetrics: ResolverTypeWrapper<
    Omit<
      OrderMetrics,
      | 'checksWithVoidsCount'
      | 'discountAmount'
      | 'discountCount'
      | 'netSales'
      | 'voidAmount'
      | 'voidAmountAsPercentageOfNetSales'
      | 'voidedItemsCount'
    > & {
      checksWithVoidsCount?: Maybe<ResolversTypes['ChecksWithVoidsCount']>
      discountAmount?: Maybe<ResolversTypes['DiscountAmount']>
      discountCount?: Maybe<ResolversTypes['DiscountCount']>
      netSales?: Maybe<ResolversTypes['NetSales']>
      voidAmount?: Maybe<ResolversTypes['VoidAmount']>
      voidAmountAsPercentageOfNetSales?: Maybe<
        ResolversTypes['VoidAmountAsPercentageOfNetSales']
      >
      voidedItemsCount?: Maybe<ResolversTypes['VoidedItemsCount']>
    }
  >
  OrderValidityCheck: ResolverTypeWrapper<OrderValidityCheck>
  OrderValidityCheckResponse: ResolverTypeWrapper<OrderValidityCheckResponse>
  OrderingSchedule: ResolverTypeWrapper<OrderingSchedule>
  OrderingScheduleInput: OrderingScheduleInput
  OrdersAppliedDiscount: ResolverTypeWrapper<OrdersAppliedDiscount>
  OrdersAppliedLoyaltyInfo: ResolverTypeWrapper<OrdersAppliedLoyaltyInfo>
  OrdersAppliedPreauthInfo: ResolverTypeWrapper<OrdersAppliedPreauthInfo>
  OrdersConfigMutation: ResolverTypeWrapper<
    Omit<OrdersConfigMutation, 'updateDiningOption'> & {
      updateDiningOption: ResolversTypes['SavedDiningOption']
    }
  >
  OrdersConfigQuery: ResolverTypeWrapper<
    Omit<
      OrdersConfigQuery,
      'savedDiningOption' | 'savedDiningOptions' | 'savedRevenueCenters'
    > & {
      savedDiningOption?: Maybe<ResolversTypes['SavedDiningOption']>
      savedDiningOptions: Array<ResolversTypes['SavedDiningOption']>
      savedRevenueCenters: Array<ResolversTypes['SavedRevenueCenter']>
    }
  >
  OrdersCurbsidePickupInfo: ResolverTypeWrapper<OrdersCurbsidePickupInfo>
  OrdersFulfillmentStatus: OrdersFulfillmentStatus
  OrdersGiftCardInfo: ResolverTypeWrapper<OrdersGiftCardInfo>
  OrdersMutation: ResolverTypeWrapper<
    Omit<OrdersMutation, 'updateTipAmount'> & {
      updateTipAmount?: Maybe<ResolversTypes['Order']>
    }
  >
  OrdersPayment: ResolverTypeWrapper<OrdersPayment>
  OrdersPaymentCardType: OrdersPaymentCardType
  OrdersPaymentRefundStatus: OrdersPaymentRefundStatus
  OrdersPaymentStatus: OrdersPaymentStatus
  OrdersPaymentType: OrdersPaymentType
  OrdersQuery: ResolverTypeWrapper<
    Omit<OrdersQuery, 'check' | 'ordersSummary'> & {
      check?: Maybe<ResolversTypes['Check']>
      ordersSummary?: Maybe<ResolversTypes['OrdersSummary']>
    }
  >
  OrdersRefund: ResolverTypeWrapper<OrdersRefund>
  OrdersRefundDetails: ResolverTypeWrapper<OrdersRefundDetails>
  OrdersSelection: ResolverTypeWrapper<
    Omit<OrdersSelection, 'diningOption' | 'modifiers'> & {
      diningOption?: Maybe<ResolversTypes['SavedDiningOption']>
      modifiers: Array<ResolversTypes['OrdersSelection']>
    }
  >
  OrdersSelectionType: OrdersSelectionType
  OrdersSummary: ResolverTypeWrapper<
    Omit<OrdersSummary, 'orders'> & {
      orders?: Maybe<Array<ResolversTypes['Order']>>
    }
  >
  OrdersTaxInclusion: OrdersTaxInclusion
  OrdersUnitOfMeasure: OrdersUnitOfMeasure
  OutdatedPackagingConfigError: ResolverTypeWrapper<
    Omit<OutdatedPackagingConfigError, 'currentConfig'> & {
      currentConfig: ResolversTypes['SavedPackagingConfig']
    }
  >
  OverlappingBreaks: ResolverTypeWrapper<OverlappingBreaks>
  OverlappingShiftExists: ResolverTypeWrapper<OverlappingShiftExists>
  OverrideDateRange: ResolverTypeWrapper<OverrideDateRange>
  OverridePricing: ResolverTypeWrapper<OverridePricing>
  Overrides: ResolverTypeWrapper<Overrides>
  OverridesDateRangeInput: OverridesDateRangeInput
  OverridesInput: OverridesInput
  PackagingComplianceData: ResolverTypeWrapper<PackagingComplianceData>
  PackagingComplianceDetails: ResolverTypeWrapper<PackagingComplianceDetails>
  PackagingComplianceError: ResolverTypeWrapper<PackagingComplianceError>
  PackagingComplianceLocationError: ResolverTypeWrapper<PackagingComplianceLocationError>
  PackagingComplianceRegulationError: ResolverTypeWrapper<PackagingComplianceRegulationError>
  PackagingComplianceSummary: ResolverTypeWrapper<PackagingComplianceSummary>
  PackagingConfigOverride: ResolverTypeWrapper<
    Omit<PackagingConfigOverride, 'items' | 'owner' | 'target'> & {
      items: Array<ResolversTypes['PackagingItem']>
      owner: ResolversTypes['RestaurantSet']
      target: ResolversTypes['RestaurantSet']
    }
  >
  PackagingConfigOverrideUpdate: PackagingConfigOverrideUpdate
  PackagingConfigUpdate: PackagingConfigUpdate
  PackagingField: PackagingField
  PackagingGuestInclusion: PackagingGuestInclusion
  PackagingItem: ResolverTypeWrapper<PackagingItem>
  PackagingItemType: PackagingItemType
  PackagingItemUpdate: PackagingItemUpdate
  PackagingLocationCompliance: ResolverTypeWrapper<PackagingLocationCompliance>
  PackagingLocationRegulationCompliance: ResolverTypeWrapper<PackagingLocationRegulationCompliance>
  PackagingMutation: ResolverTypeWrapper<
    Omit<PackagingMutation, 'saveConfig'> & {
      saveConfig: ResolversTypes['SavePackagingConfigResponse']
    }
  >
  PackagingQuery: ResolverTypeWrapper<
    Omit<PackagingQuery, 'config'> & {
      config: ResolversTypes['SavedPackagingConfig']
    }
  >
  PackagingRegulation: ResolverTypeWrapper<PackagingRegulation>
  PackagingRestrictions: ResolverTypeWrapper<PackagingRestrictions>
  PackagingUpdateToastInternalOptions: PackagingUpdateToastInternalOptions
  PageInfo: ResolverTypeWrapper<PageInfo>
  PagedMenuItemResponse: ResolverTypeWrapper<
    Omit<PagedMenuItemResponse, 'entities'> & {
      entities: Array<ResolversTypes['MenuItem']>
    }
  >
  PagedModifierGroupsResponse: ResolverTypeWrapper<
    Omit<PagedModifierGroupsResponse, 'entities'> & {
      entities: Array<ResolversTypes['ModifierGroup']>
    }
  >
  PagedModifierResponse: ResolverTypeWrapper<
    Omit<PagedModifierResponse, 'entities'> & {
      entities: Array<ResolversTypes['Modifier']>
    }
  >
  PagedPriceLevelResponse: ResolverTypeWrapper<
    Omit<PagedPriceLevelResponse, 'entities'> & {
      entities: Array<ResolversTypes['PriceLevelEntity']>
    }
  >
  PagedRenderedAuthorizationDecision: ResolverTypeWrapper<PagedRenderedAuthorizationDecision>
  PagedSavedChannel: ResolverTypeWrapper<
    Omit<PagedSavedChannel, 'results'> & {
      results: Array<ResolversTypes['SavedChannel']>
    }
  >
  PagedSavedChannelAvailability: ResolverTypeWrapper<
    Omit<PagedSavedChannelAvailability, 'results'> & {
      results: Array<ResolversTypes['SavedChannelAvailability']>
    }
  >
  PagedSavedMenuItemTagsResponse: ResolverTypeWrapper<PagedSavedMenuItemTagsResponse>
  PaginationInput: PaginationInput
  ParentResponse: ResolverTypeWrapper<ParentResponse>
  Partner: ResolverTypeWrapper<Partner>
  PartnerApiAccess: ResolverTypeWrapper<PartnerApiAccess>
  PartnerCategory: ResolverTypeWrapper<PartnerCategory>
  PartnerChannel: ResolverTypeWrapper<
    Omit<PartnerChannel, 'channel'> & {
      channel?: Maybe<ResolversTypes['SavedChannel']>
    }
  >
  PartnerChannelConfig: ResolverTypeWrapper<
    Omit<PartnerChannelConfig, 'partnerChannels'> & {
      partnerChannels: Array<ResolversTypes['PartnerChannel']>
    }
  >
  PartnerChannelInput: PartnerChannelInput
  PartnerChannelsInput: PartnerChannelsInput
  PartnerQuery: ResolverTypeWrapper<PartnerQuery>
  PartnerScope: ResolverTypeWrapper<PartnerScope>
  PartnerStatusLabel: ResolverTypeWrapper<PartnerStatusLabel>
  PartnerStatusLabelState: PartnerStatusLabelState
  PaymentAssistanceProgram: PaymentAssistanceProgram
  PaymentDataExtension: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['PaymentDataExtension']
  >
  PaymentDataExtensionInput: PaymentDataExtensionInput
  PaymentUserTypeMode: PaymentUserTypeMode
  PaymentsConfigMutation: ResolverTypeWrapper<
    Omit<PaymentsConfigMutation, 'createGfdConfig' | 'updateGfdConfigs'> & {
      createGfdConfig: ResolversTypes['GfdConfig']
      updateGfdConfigs: Array<ResolversTypes['GfdConfig']>
    }
  >
  PaymentsConfigQuery: ResolverTypeWrapper<
    Omit<
      PaymentsConfigQuery,
      | 'alternatePaymentTypes'
      | 'gfdConfig'
      | 'gfdConfigByEntityId'
      | 'gfdConfigs'
    > & {
      alternatePaymentTypes: Array<ResolversTypes['SavedAlternatePaymentType']>
      gfdConfig?: Maybe<ResolversTypes['GfdConfig']>
      gfdConfigByEntityId?: Maybe<ResolversTypes['GfdConfig']>
      gfdConfigs: Array<ResolversTypes['GfdConfig']>
    }
  >
  PayrollEmployeeInfo: ResolverTypeWrapper<
    Omit<PayrollEmployeeInfo, 'employmentStatus'> & {
      employmentStatus: ResolversTypes['AllEmploymentStatuses']
    }
  >
  PendingEmploymentStatus: ResolverTypeWrapper<PendingEmploymentStatus>
  PercentageMetric: ResolverTypeWrapper<PercentageMetric>
  Permission: Permission
  PermissionBit: ResolverTypeWrapper<Scalars['PermissionBit']['output']>
  Permissions: ResolverTypeWrapper<Permissions>
  PermissionsCheckType: PermissionsCheckType
  PlaceThirdPartyMockOrderResponse: ResolverTypeWrapper<PlaceThirdPartyMockOrderResponse>
  PlateCostSource: PlateCostSource
  PnLAISummary: ResolverTypeWrapper<PnLaiSummary>
  PnLLineItemMetrics: ResolverTypeWrapper<PnLLineItemMetrics>
  PnLNetProfit: ResolverTypeWrapper<PnLNetProfit>
  PnLReport: ResolverTypeWrapper<PnLReport>
  PnLReportRequest: PnLReportRequest
  PnLSubCategory: ResolverTypeWrapper<PnLSubCategory>
  PnLSubCategoryDetail: ResolverTypeWrapper<PnLSubCategoryDetail>
  PnLSubProfit: ResolverTypeWrapper<PnLSubProfit>
  PnLTopLevel: ResolverTypeWrapper<PnLTopLevel>
  PnLTopLevelCategory: PnLTopLevelCategory
  PopularSearch: ResolverTypeWrapper<PopularSearch>
  PopularSearchResponse: ResolverTypeWrapper<PopularSearchResponse>
  PosButton: PosButton
  PosEmployeeInfo: ResolverTypeWrapper<PosEmployeeInfo>
  PrequalifiedOfferForCustomer: ResolverTypeWrapper<PrequalifiedOfferForCustomer>
  PrequalifiedOfferMetadata: ResolverTypeWrapper<PrequalifiedOfferMetadata>
  PriceLevel: ResolverTypeWrapper<PriceLevel>
  PriceLevelEntity: ResolverTypeWrapper<
    Omit<
      PriceLevelEntity,
      'ownerRestaurantSet' | 'pricing' | 'targetRestaurantSet'
    > & {
      ownerRestaurantSet?: Maybe<ResolversTypes['RestaurantSet']>
      pricing?: Maybe<ResolversTypes['MenuItemPricing']>
      targetRestaurantSet: ResolversTypes['RestaurantSet']
    }
  >
  PricingMode: PricingMode
  Printer: ResolverTypeWrapper<Printer>
  ProcessLegacyJob: ResolverTypeWrapper<ProcessLegacyJob>
  ProcessLegacyJobRequest: ProcessLegacyJobRequest
  ProcessLegacyJobResponse: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['ProcessLegacyJobResponse']
  >
  ProductRecommendation: ProductRecommendation
  ProductSubscription: ResolverTypeWrapper<ProductSubscription>
  ProductSubscriptionBillingFrequency: ProductSubscriptionBillingFrequency
  ProductSubscriptionBillingType: ProductSubscriptionBillingType
  ProductSubscriptionBundle: ResolverTypeWrapper<ProductSubscriptionBundle>
  ProductSubscriptionInterface: ResolverTypeWrapper<
    ResolversInterfaceTypes<ResolversTypes>['ProductSubscriptionInterface']
  >
  ProductSubscriptionProductType: ProductSubscriptionProductType
  ProductSubscriptionSalesforceProduct: ResolverTypeWrapper<ProductSubscriptionSalesforceProduct>
  ProductSubscriptionsMutation: ResolverTypeWrapper<ProductSubscriptionsMutation>
  ProductSubscriptionsQuery: ResolverTypeWrapper<
    Omit<ProductSubscriptionsQuery, 'list'> & {
      list: Array<ResolversTypes['ProductSubscriptionInterface']>
    }
  >
  ProductType: ProductType
  PublishJob: ResolverTypeWrapper<
    Omit<PublishJob, 'entities'> & {
      entities: ResolversTypes['PublishedConfigConnection']
    }
  >
  PublishJobConnection: ResolverTypeWrapper<PublishJobConnection>
  PublishJobEdge: ResolverTypeWrapper<PublishJobEdge>
  PublishJobEntityInfo: ResolverTypeWrapper<PublishJobEntityInfo>
  PublishJobSource: PublishJobSource
  PublishJobType: PublishJobType
  PublishJobsFilterInput: PublishJobsFilterInput
  PublishMutation: ResolverTypeWrapper<PublishMutation>
  PublishQuery: ResolverTypeWrapper<
    Omit<PublishQuery, 'publishedConfig'> & {
      publishedConfig: ResolversTypes['PublishedConfig']
    }
  >
  PublishRepositoryDependencyFailure: ResolverTypeWrapper<PublishRepositoryDependencyFailure>
  PublishRestaurantStatus: ResolverTypeWrapper<PublishRestaurantStatus>
  PublishStatus: ResolverTypeWrapper<PublishStatus>
  PublishedChannel: ResolverTypeWrapper<
    Omit<PublishedChannel, 'channelAvailability' | 'channelType'> & {
      channelAvailability?: Maybe<
        ResolversTypes['PublishedChannelAvailability']
      >
      channelType?: Maybe<ResolversTypes['ChannelType']>
    }
  >
  PublishedChannelAvailability: ResolverTypeWrapper<PublishedChannelAvailability>
  PublishedConfig: ResolverTypeWrapper<
    Omit<PublishedConfig, 'targets'> & {
      targets: Array<ResolversTypes['RestaurantSet']>
    }
  >
  PublishedConfigConnection: ResolverTypeWrapper<
    Omit<PublishedConfigConnection, 'edges'> & {
      edges: Array<ResolversTypes['PublishedConfigEdge']>
    }
  >
  PublishedConfigDiff: ResolverTypeWrapper<PublishedConfigDiff>
  PublishedConfigDiffType: PublishedConfigDiffType
  PublishedConfigEdge: ResolverTypeWrapper<
    Omit<PublishedConfigEdge, 'node'> & {
      node: ResolversTypes['PublishedConfig']
    }
  >
  PublishedConfigEntityI: ResolverTypeWrapper<
    ResolversInterfaceTypes<ResolversTypes>['PublishedConfigEntityI']
  >
  PublishedConfigFieldDiff: ResolverTypeWrapper<PublishedConfigFieldDiff>
  PublishedDataExtensionSet: ResolverTypeWrapper<
    Omit<
      PublishedDataExtensionSet,
      'payments' | 'revenueCenters' | 'salesCategories' | 'taxRates'
    > & {
      payments: Array<ResolversTypes['PaymentDataExtension']>
      revenueCenters: Array<ResolversTypes['RevenueCenterDataExtension']>
      salesCategories: Array<ResolversTypes['SalesCategoryDataExtension']>
      taxRates: Array<ResolversTypes['TaxRateDataExtension']>
    }
  >
  PublishedGiftCardReceiptConfig: ResolverTypeWrapper<PublishedGiftCardReceiptConfig>
  PublishedItemFeedbackConfig: ResolverTypeWrapper<PublishedItemFeedbackConfig>
  PublishedItemFeedbackConfigError: ResolverTypeWrapper<PublishedItemFeedbackConfigError>
  PublishedItemFeedbackConfigErrorCode: PublishedItemFeedbackConfigErrorCode
  PublishedItemFeedbackConfigResponse: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['PublishedItemFeedbackConfigResponse']
  >
  PublishedMenu: ResolverTypeWrapper<PublishedMenu>
  PublishedMenuEntity: ResolverTypeWrapper<
    ResolversInterfaceTypes<ResolversTypes>['PublishedMenuEntity']
  >
  PublishedMenuGroup: ResolverTypeWrapper<PublishedMenuGroup>
  PublishedMenuItem: ResolverTypeWrapper<
    Omit<PublishedMenuItem, 'ancestors'> & {
      ancestors: Array<ResolversTypes['PublishedMenuItemAncestor']>
    }
  >
  PublishedMenuItemAncestor: ResolverTypeWrapper<
    Omit<PublishedMenuItemAncestor, 'paths'> & {
      paths: ResolversTypes['PublishedMenuItemAncestorPath']
    }
  >
  PublishedMenuItemAncestorPath: ResolverTypeWrapper<
    Omit<PublishedMenuItemAncestorPath, 'ancestors'> & {
      ancestors: Array<ResolversTypes['PublishedMenuEntity']>
    }
  >
  PublishedPrinterConfig: ResolverTypeWrapper<PublishedPrinterConfig>
  PublishedQueries: ResolverTypeWrapper<PublishedQueries>
  PublishedRestaurantLocation: ResolverTypeWrapper<PublishedRestaurantLocation>
  PublishedToastPayConfigError: ResolverTypeWrapper<PublishedToastPayConfigError>
  PublishedToastPayConfigErrorCode: PublishedToastPayConfigErrorCode
  PublishedToastPayConfigResponse: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['PublishedToastPayConfigResponse']
  >
  QLOrderConfirmationScreenConfig: ResolverTypeWrapper<QlOrderConfirmationScreenConfig>
  QLStockDataConfig: ResolverTypeWrapper<QlStockDataConfig>
  QLStockOutConfig: ResolverTypeWrapper<QlStockOutConfig>
  Query: ResolverTypeWrapper<{}>
  QuickAction: ResolverTypeWrapper<QuickAction>
  QuoteTime: ResolverTypeWrapper<QuoteTime>
  QuoteTimeInput: QuoteTimeInput
  ReadFailure: ResolverTypeWrapper<ReadFailure>
  RecentSearchesResponse: ResolverTypeWrapper<RecentSearchesResponse>
  ReconciliationConfig: ResolverTypeWrapper<ReconciliationConfig>
  ReconciliationConfigInput: ReconciliationConfigInput
  RefundsGroupingDimension: RefundsGroupingDimension
  RehiredEmploymentStatus: ResolverTypeWrapper<RehiredEmploymentStatus>
  RemoveMenuGroupInput: RemoveMenuGroupInput
  RemoveMenuItemInput: RemoveMenuItemInput
  RenderedAuthorizationDecision: ResolverTypeWrapper<RenderedAuthorizationDecision>
  RepaymentMilestone: ResolverTypeWrapper<RepaymentMilestone>
  ReportSection: ResolverTypeWrapper<ReportSection>
  ReportSectionInput: ReportSectionInput
  RequiredMode: RequiredMode
  ResetType: ResetType
  ResolvedConfigEntityI: ResolverTypeWrapper<
    ResolversInterfaceTypes<ResolversTypes>['ResolvedConfigEntityI']
  >
  ResolvedDataExtensionSet: ResolverTypeWrapper<
    Omit<
      ResolvedDataExtensionSet,
      'payments' | 'revenueCenters' | 'salesCategories' | 'target' | 'taxRates'
    > & {
      payments: Array<ResolversTypes['PaymentDataExtension']>
      revenueCenters: Array<ResolversTypes['RevenueCenterDataExtension']>
      salesCategories: Array<ResolversTypes['SalesCategoryDataExtension']>
      target?: Maybe<ResolversTypes['RestaurantSet']>
      taxRates: Array<ResolversTypes['TaxRateDataExtension']>
    }
  >
  Response: ResolverTypeWrapper<Response>
  Restaurant: ResolverTypeWrapper<Restaurant>
  RestaurantAccess: ResolverTypeWrapper<
    Omit<RestaurantAccess, 'restaurantLocation'> & {
      restaurantLocation: ResolversTypes['AccessibleRestaurantLocation']
    }
  >
  RestaurantAvailabilityInfo: ResolverTypeWrapper<
    Omit<RestaurantAvailabilityInfo, 'slots'> & {
      slots: Array<ResolversTypes['SlotAvailabilityInfo']>
    }
  >
  RestaurantBookingLimit: ResolverTypeWrapper<RestaurantBookingLimit>
  RestaurantBreakConfig: ResolverTypeWrapper<RestaurantBreakConfig>
  RestaurantGroup: ResolverTypeWrapper<RestaurantGroup>
  RestaurantGroupOrLocation: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['RestaurantGroupOrLocation']
  >
  RestaurantInformationResponseSuccess: ResolverTypeWrapper<
    Omit<RestaurantInformationResponseSuccess, 'response'> & {
      response: ResolversTypes['MgmtRestaurantInformation']
    }
  >
  RestaurantInput: RestaurantInput
  RestaurantLeafDataJobInput: RestaurantLeafDataJobInput
  RestaurantLeafDataJobLastEvaluatedKey: ResolverTypeWrapper<RestaurantLeafDataJobLastEvaluatedKey>
  RestaurantLeafDataJobLastEvaluatedKeyInput: RestaurantLeafDataJobLastEvaluatedKeyInput
  RestaurantLeafDataJobResult: ResolverTypeWrapper<RestaurantLeafDataJobResult>
  RestaurantLocation: ResolverTypeWrapper<RestaurantLocation>
  RestaurantLocationAddress: ResolverTypeWrapper<RestaurantLocationAddress>
  RestaurantLocationImages: ResolverTypeWrapper<RestaurantLocationImages>
  RestaurantLocationInterface: ResolverTypeWrapper<
    ResolversInterfaceTypes<ResolversTypes>['RestaurantLocationInterface']
  >
  RestaurantLocationStatus: RestaurantLocationStatus
  RestaurantLocationsDiff: ResolverTypeWrapper<
    Omit<RestaurantLocationsDiff, 'left' | 'right'> & {
      left: ResolversTypes['RestaurantGroupOrLocation']
      right: ResolversTypes['RestaurantGroupOrLocation']
    }
  >
  RestaurantLocationsQuery: ResolverTypeWrapper<RestaurantLocationsQuery>
  RestaurantMenuItemEdge: ResolverTypeWrapper<
    Omit<RestaurantMenuItemEdge, 'node'> & {
      node: ResolversTypes['PublishedMenuItem']
    }
  >
  RestaurantMetrics: ResolverTypeWrapper<
    Omit<RestaurantMetrics, 'laborMetrics' | 'orderMetrics'> & {
      laborMetrics: ResolversTypes['LaborMetrics']
      orderMetrics: ResolversTypes['OrderMetrics']
    }
  >
  RestaurantNotFound: ResolverTypeWrapper<RestaurantNotFound>
  RestaurantRepositoryDependencyFailure: ResolverTypeWrapper<RestaurantRepositoryDependencyFailure>
  RestaurantSet: ResolverTypeWrapper<
    Omit<RestaurantSet, 'permissions' | 'set'> & {
      permissions?: Maybe<ResolversTypes['Permissions']>
      set: ResolversTypes['RestaurantGroupOrLocation']
    }
  >
  RestaurantSetInput: RestaurantSetInput
  RestaurantSetPermissionStatus: RestaurantSetPermissionStatus
  RestaurantUser: ResolverTypeWrapper<RestaurantUser>
  RestaurantUserInput: RestaurantUserInput
  RestoreMembershipResponse: ResolverTypeWrapper<RestoreMembershipResponse>
  RevenueCenterDataExtension: ResolverTypeWrapper<
    Omit<RevenueCenterDataExtension, 'revenueCenter'> & {
      revenueCenter: ResolversTypes['SavedRevenueCenter']
    }
  >
  SalesCategory: ResolverTypeWrapper<SalesCategory>
  SalesCategoryDataExtension: ResolverTypeWrapper<
    Omit<SalesCategoryDataExtension, 'salesCategory'> & {
      salesCategory: ResolversTypes['SavedSalesCategory']
    }
  >
  SalesCategoryInput: SalesCategoryInput
  SaveCustomMenuItemUpsellInput: SaveCustomMenuItemUpsellInput
  SaveFailure: ResolverTypeWrapper<SaveFailure>
  SavePackagingConfigResponse: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['SavePackagingConfigResponse']
  >
  SavedAlternatePaymentType: ResolverTypeWrapper<
    Omit<SavedAlternatePaymentType, 'target'> & {
      target: ResolversTypes['RestaurantSet']
    }
  >
  SavedChannel: ResolverTypeWrapper<
    Omit<
      SavedChannel,
      | 'channelAvailability'
      | 'channelMenuEntities'
      | 'channelMenuPriceAdjustment'
      | 'channelType'
    > & {
      channelAvailability?: Maybe<ResolversTypes['SavedChannelAvailability']>
      channelMenuEntities?: Maybe<Array<ResolversTypes['ChannelMenuEntity']>>
      channelMenuPriceAdjustment?: Maybe<
        ResolversTypes['ChannelMenuPriceAdjustment']
      >
      channelType?: Maybe<ResolversTypes['ChannelType']>
    }
  >
  SavedChannelAvailability: ResolverTypeWrapper<SavedChannelAvailability>
  SavedChannelAvailabilityInput: SavedChannelAvailabilityInput
  SavedChannelAvailabilitySearchInput: SavedChannelAvailabilitySearchInput
  SavedChannelInput: SavedChannelInput
  SavedChannelSearchInput: SavedChannelSearchInput
  SavedConfig: ResolverTypeWrapper<SavedConfig>
  SavedConfigEntityI: ResolverTypeWrapper<
    ResolversInterfaceTypes<ResolversTypes>['SavedConfigEntityI']
  >
  SavedConfigOverrideI: ResolverTypeWrapper<
    ResolversInterfaceTypes<ResolversTypes>['SavedConfigOverrideI']
  >
  SavedDataExtensionSet: ResolverTypeWrapper<
    Omit<
      SavedDataExtensionSet,
      | 'overrides'
      | 'payments'
      | 'revenueCenters'
      | 'salesCategories'
      | 'target'
      | 'taxRates'
    > & {
      overrides: Array<ResolversTypes['DataExtensionSetOverride']>
      payments: Array<ResolversTypes['PaymentDataExtension']>
      revenueCenters: Array<ResolversTypes['RevenueCenterDataExtension']>
      salesCategories: Array<ResolversTypes['SalesCategoryDataExtension']>
      target?: Maybe<ResolversTypes['RestaurantSet']>
      taxRates: Array<ResolversTypes['TaxRateDataExtension']>
    }
  >
  SavedDiningOption: ResolverTypeWrapper<
    Omit<SavedDiningOption, 'ownerRestaurantSet' | 'targetRestaurantSet'> & {
      ownerRestaurantSet?: Maybe<ResolversTypes['RestaurantSet']>
      targetRestaurantSet?: Maybe<ResolversTypes['RestaurantSet']>
    }
  >
  SavedGiftCardReceiptConfig: ResolverTypeWrapper<
    Omit<SavedGiftCardReceiptConfig, 'target'> & {
      target?: Maybe<ResolversTypes['RestaurantSet']>
    }
  >
  SavedMenuAuditEntityType: SavedMenuAuditEntityType
  SavedMenuAuditSetting: ResolverTypeWrapper<SavedMenuAuditSetting>
  SavedMenuAuditSettingInput: SavedMenuAuditSettingInput
  SavedMenuAuditSettingType: SavedMenuAuditSettingType
  SavedMenuCreate: SavedMenuCreate
  SavedMenuEntityAvailabilityInput: SavedMenuEntityAvailabilityInput
  SavedMenuEntityCollapseModifierPricesInput: SavedMenuEntityCollapseModifierPricesInput
  SavedMenuEntityContentAdvisoriesInput: SavedMenuEntityContentAdvisoriesInput
  SavedMenuEntityImageInput: SavedMenuEntityImageInput
  SavedMenuEntityInventoryInput: SavedMenuEntityInventoryInput
  SavedMenuEntityPointOfSaleInput: SavedMenuEntityPointOfSaleInput
  SavedMenuEntityReportsInput: SavedMenuEntityReportsInput
  SavedMenuEntityTaxesInput: SavedMenuEntityTaxesInput
  SavedMenuEntityVisibilityInput: SavedMenuEntityVisibilityInput
  SavedMenuGroupCreate: SavedMenuGroupCreate
  SavedMenuGroupPreparation: ResolverTypeWrapper<SavedMenuGroupPreparation>
  SavedMenuGroupPreparationInput: SavedMenuGroupPreparationInput
  SavedMenuItemCreate: SavedMenuItemCreate
  SavedMenuItemModifiers: SavedMenuItemModifiers
  SavedMenuItemPreparation: ResolverTypeWrapper<SavedMenuItemPreparation>
  SavedMenuItemPreparationInput: SavedMenuItemPreparationInput
  SavedMenuItemPricingInput: SavedMenuItemPricingInput
  SavedMenuItemTag: ResolverTypeWrapper<SavedMenuItemTag>
  SavedMenuItemTagsInput: SavedMenuItemTagsInput
  SavedMenuItemUpdate: SavedMenuItemUpdate
  SavedMenuPreparation: ResolverTypeWrapper<SavedMenuPreparation>
  SavedMenuSpecificPriceInput: SavedMenuSpecificPriceInput
  SavedMenuUpdate: SavedMenuUpdate
  SavedOrderConfirmationScreenConfig: ResolverTypeWrapper<
    Omit<SavedOrderConfirmationScreenConfig, 'baseConfig' | 'overrides'> & {
      baseConfig: ResolversTypes['QLOrderConfirmationScreenConfig']
      overrides?: Maybe<
        Array<ResolversTypes['OrderConfirmationScreenConfigOverride']>
      >
    }
  >
  SavedPackagingConfig: ResolverTypeWrapper<
    Omit<SavedPackagingConfig, 'items' | 'overrides'> & {
      items: Array<ResolversTypes['PackagingItem']>
      overrides: Array<ResolversTypes['PackagingConfigOverride']>
    }
  >
  SavedPrepSequence: ResolverTypeWrapper<
    Omit<SavedPrepSequence, 'ownerRestaurantSet' | 'targetRestaurantSet'> & {
      ownerRestaurantSet: ResolversTypes['RestaurantSet']
      targetRestaurantSet: ResolversTypes['RestaurantSet']
    }
  >
  SavedPrepSequencesSearch: SavedPrepSequencesSearch
  SavedPrepStation: ResolverTypeWrapper<
    Omit<SavedPrepStation, 'ownerRestaurantSet' | 'targetRestaurantSet'> & {
      ownerRestaurantSet: ResolversTypes['RestaurantSet']
      targetRestaurantSet: ResolversTypes['RestaurantSet']
    }
  >
  SavedPrepStationsSearch: SavedPrepStationsSearch
  SavedQueries: ResolverTypeWrapper<SavedQueries>
  SavedRestaurantLocation: ResolverTypeWrapper<SavedRestaurantLocation>
  SavedRestaurantLocationConnection: ResolverTypeWrapper<SavedRestaurantLocationConnection>
  SavedRestaurantLocationEdge: ResolverTypeWrapper<SavedRestaurantLocationEdge>
  SavedRestaurantTable: ResolverTypeWrapper<SavedRestaurantTable>
  SavedRevenueCenter: ResolverTypeWrapper<
    Omit<SavedRevenueCenter, 'ownerRestaurantSet' | 'targetRestaurantSet'> & {
      ownerRestaurantSet: ResolversTypes['RestaurantSet']
      targetRestaurantSet: ResolversTypes['RestaurantSet']
    }
  >
  SavedRevenueCenterSearch: SavedRevenueCenterSearch
  SavedSalesCategoriesSearch: SavedSalesCategoriesSearch
  SavedSalesCategory: ResolverTypeWrapper<
    Omit<SavedSalesCategory, 'ownerRestaurantSet' | 'targetRestaurantSet'> & {
      ownerRestaurantSet: ResolversTypes['RestaurantSet']
      targetRestaurantSet: ResolversTypes['RestaurantSet']
    }
  >
  SavedSizePriceInput: SavedSizePriceInput
  SavedStockDataConfig: ResolverTypeWrapper<
    Omit<SavedStockDataConfig, 'baseConfig' | 'overrides'> & {
      baseConfig: ResolversTypes['QLStockDataConfig']
      overrides?: Maybe<Array<ResolversTypes['StockDataConfigOverride']>>
    }
  >
  SavedStockOutConfig: ResolverTypeWrapper<
    Omit<SavedStockOutConfig, 'baseConfig' | 'overrides'> & {
      baseConfig: ResolversTypes['QLStockOutConfig']
      overrides?: Maybe<Array<ResolversTypes['StockOutConfigOverride']>>
    }
  >
  SavedTaxRate: ResolverTypeWrapper<
    Omit<SavedTaxRate, 'ownerRestaurantSet' | 'targetRestaurantSet'> & {
      ownerRestaurantSet: ResolversTypes['RestaurantSet']
      targetRestaurantSet: ResolversTypes['RestaurantSet']
    }
  >
  SavedTaxRatesSearch: SavedTaxRatesSearch
  SavedToastPayConfigError: ResolverTypeWrapper<SavedToastPayConfigError>
  SavedToastPayConfigErrorCode: SavedToastPayConfigErrorCode
  SavedToastPayConfigResponse: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['SavedToastPayConfigResponse']
  >
  Schedule: ResolverTypeWrapper<Schedule>
  ScheduleInput: ScheduleInput
  SearchDataSource: SearchDataSource
  SearchEntity: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['SearchEntity']
  >
  SearchItem: ResolverTypeWrapper<
    Omit<SearchItem, 'entity'> & {
      entity?: Maybe<ResolversTypes['SearchEntity']>
    }
  >
  SearchMutation: ResolverTypeWrapper<
    Omit<SearchMutation, 'createUserInteractions'> & {
      createUserInteractions: ResolversTypes['UserIteractionMutationResponse']
    }
  >
  SearchQuery: ResolverTypeWrapper<
    Omit<SearchQuery, 'autoComplete' | 'search'> & {
      autoComplete: ResolversTypes['SearchResponse']
      search: ResolversTypes['SearchResponse']
    }
  >
  SearchResponse: ResolverTypeWrapper<
    Omit<SearchResponse, 'items'> & {
      items: Array<ResolversTypes['SearchItem']>
    }
  >
  SearchSuggestion: ResolverTypeWrapper<SearchSuggestion>
  SeasonalEmploymentStatus: ResolverTypeWrapper<SeasonalEmploymentStatus>
  Section: ResolverTypeWrapper<Section>
  SendGuestFeedbackResponseInput: SendGuestFeedbackResponseInput
  ServerColor: ResolverTypeWrapper<ServerColor>
  ServiceArea: ResolverTypeWrapper<ServiceArea>
  ServiceAreaAvailabilityInfo: ResolverTypeWrapper<
    Omit<ServiceAreaAvailabilityInfo, 'deposit'> & {
      deposit?: Maybe<ResolversTypes['DepositAvailabilityInfo']>
    }
  >
  ServiceAreaGroupInfo: ResolverTypeWrapper<ServiceAreaGroupInfo>
  ServiceAreaNotFound: ResolverTypeWrapper<ServiceAreaNotFound>
  ServiceAreaResponse: ResolverTypeWrapper<ServiceAreaResponse>
  ServiceDataExtension: ResolverTypeWrapper<ServiceDataExtension>
  ServiceModel: ServiceModel
  ShiftAlreadyClockedOut: ResolverTypeWrapper<ShiftAlreadyClockedOut>
  ShiftCheckedResultRepositoryDependencyFailure: ResolverTypeWrapper<ShiftCheckedResultRepositoryDependencyFailure>
  ShiftError: ResolverTypeWrapper<
    ResolversInterfaceTypes<ResolversTypes>['ShiftError']
  >
  ShiftFilterInput: ShiftFilterInput
  ShiftFilterInputV2: ShiftFilterInputV2
  ShiftNotFound: ResolverTypeWrapper<ShiftNotFound>
  ShiftOrderByDirection: ShiftOrderByDirection
  ShiftOrderByField: ShiftOrderByField
  ShiftOrderByInput: ShiftOrderByInput
  ShiftReviewCollectAndTipOutMode: ShiftReviewCollectAndTipOutMode
  ShiftReviewConfig: ResolverTypeWrapper<ShiftReviewConfig>
  ShiftReviewConfigInput: ShiftReviewConfigInput
  ShiftTipInput: ShiftTipInput
  ShiftV2Employee: ResolverTypeWrapper<ShiftV2Employee>
  ShiftV2EmployeesResponse: ResolverTypeWrapper<ShiftV2EmployeesResponse>
  ShiftsV2PageInfo: ResolverTypeWrapper<ShiftsV2PageInfo>
  ShiftsV2PaginationInput: ShiftsV2PaginationInput
  SizePrice: ResolverTypeWrapper<
    Omit<SizePrice, 'pricing'> & {
      pricing?: Maybe<ResolversTypes['MenuItemPricing']>
    }
  >
  SlotAvailabilityInfo: ResolverTypeWrapper<
    Omit<SlotAvailabilityInfo, 'serviceAreas'> & {
      serviceAreas: Array<ResolversTypes['ServiceAreaAvailabilityInfo']>
    }
  >
  SmartQuoteAutoChangeStrategy: SmartQuoteAutoChangeStrategy
  SmartQuoteAutoChangeStrategyResponse: ResolverTypeWrapper<SmartQuoteAutoChangeStrategyResponse>
  SmartQuoteConfig: ResolverTypeWrapper<SmartQuoteConfig>
  SmartQuoteConfigReasonCode: SmartQuoteConfigReasonCode
  SmartQuoteConfigStatus: SmartQuoteConfigStatus
  SmartQuoteMutation: ResolverTypeWrapper<SmartQuoteMutation>
  SmartQuoteQuery: ResolverTypeWrapper<SmartQuoteQuery>
  SmartQuoteSimpleComparison: ResolverTypeWrapper<SmartQuoteSimpleComparison>
  SmartQuoteSimpleComparisonQuery: ResolverTypeWrapper<SmartQuoteSimpleComparisonQuery>
  SnoozeUnit: SnoozeUnit
  SnoozeValue: SnoozeValue
  SousChefAction: ResolverTypeWrapper<SousChefAction>
  SousChefActionPlan: ResolverTypeWrapper<SousChefActionPlan>
  SousChefActionType: SousChefActionType
  SousChefAdminMutation: ResolverTypeWrapper<
    Omit<SousChefAdminMutation, 'createWorkflow'> & {
      createWorkflow: ResolversTypes['SousChefWorkflowDefinition']
    }
  >
  SousChefAdminQuery: ResolverTypeWrapper<SousChefAdminQuery>
  SousChefAlert: ResolverTypeWrapper<SousChefAlert>
  SousChefAnalysisNode: ResolverTypeWrapper<SousChefAnalysisNode>
  SousChefAutoClockedOut: ResolverTypeWrapper<SousChefAutoClockedOut>
  SousChefAutoClockedOutDataPoint: ResolverTypeWrapper<SousChefAutoClockedOutDataPoint>
  SousChefChecklistItem: ResolverTypeWrapper<
    Omit<SousChefChecklistItem, 'insight'> & {
      insight: ResolversTypes['SousChefInsight']
    }
  >
  SousChefCompletedThread: ResolverTypeWrapper<
    Omit<SousChefCompletedThread, 'filters'> & {
      filters: Array<ResolversTypes['SousChefFilter']>
    }
  >
  SousChefDataNode: ResolverTypeWrapper<SousChefDataNode>
  SousChefDataPanel: ResolverTypeWrapper<SousChefDataPanel>
  SousChefDataPoint: ResolverTypeWrapper<
    ResolversInterfaceTypes<ResolversTypes>['SousChefDataPoint']
  >
  SousChefDataSource: ResolverTypeWrapper<SousChefDataSource>
  SousChefDateRange: ResolverTypeWrapper<SousChefDateRange>
  SousChefDateRangeFilter: ResolverTypeWrapper<SousChefDateRangeFilter>
  SousChefDateTimeRange: ResolverTypeWrapper<SousChefDateTimeRange>
  SousChefDayOfWeek: SousChefDayOfWeek
  SousChefDayOfWeekFilter: ResolverTypeWrapper<SousChefDayOfWeekFilter>
  SousChefDayPart: ResolverTypeWrapper<SousChefDayPart>
  SousChefDayPartFilter: ResolverTypeWrapper<SousChefDayPartFilter>
  SousChefDefinition: ResolverTypeWrapper<SousChefDefinition>
  SousChefDiningOption: ResolverTypeWrapper<SousChefDiningOption>
  SousChefDiningOptionFilter: ResolverTypeWrapper<SousChefDiningOptionFilter>
  SousChefEdge: ResolverTypeWrapper<SousChefEdge>
  SousChefEmployeeFilter: ResolverTypeWrapper<SousChefEmployeeFilter>
  SousChefEmptyDataPoint: ResolverTypeWrapper<SousChefEmptyDataPoint>
  SousChefFeedback: SousChefFeedback
  SousChefFeedbackIssueReason: SousChefFeedbackIssueReason
  SousChefFeedbackRating: SousChefFeedbackRating
  SousChefFilter: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['SousChefFilter']
  >
  SousChefFilterType: SousChefFilterType
  SousChefFinancialData: ResolverTypeWrapper<SousChefFinancialData>
  SousChefFinancialDataPoint: ResolverTypeWrapper<SousChefFinancialDataPoint>
  SousChefFixedResponseNode: ResolverTypeWrapper<SousChefFixedResponseNode>
  SousChefHoursOfDayFilter: ResolverTypeWrapper<SousChefHoursOfDayFilter>
  SousChefInsight: ResolverTypeWrapper<
    Omit<SousChefInsight, 'data' | 'dataPoint' | 'relatedInsights'> & {
      data: Array<ResolversTypes['SousChefDataPoint']>
      dataPoint: ResolversTypes['SousChefDataPoint']
      relatedInsights?: Maybe<Array<ResolversTypes['SousChefInsight']>>
    }
  >
  SousChefInsightFilter: ResolverTypeWrapper<SousChefInsightFilter>
  SousChefInsightSource: SousChefInsightSource
  SousChefInterval: SousChefInterval
  SousChefIntervalFilter: ResolverTypeWrapper<SousChefIntervalFilter>
  SousChefKnowledge: ResolverTypeWrapper<SousChefKnowledge>
  SousChefMenu: ResolverTypeWrapper<SousChefMenu>
  SousChefMenuFilter: ResolverTypeWrapper<SousChefMenuFilter>
  SousChefMenuGroup: ResolverTypeWrapper<SousChefMenuGroup>
  SousChefMenuGroupFilter: ResolverTypeWrapper<SousChefMenuGroupFilter>
  SousChefMutation: ResolverTypeWrapper<SousChefMutation>
  SousChefOrderSource: ResolverTypeWrapper<SousChefOrderSource>
  SousChefOrderSourceFilter: ResolverTypeWrapper<SousChefOrderSourceFilter>
  SousChefOutOfStockItem: ResolverTypeWrapper<
    Omit<SousChefOutOfStockItem, 'menuItem'> & {
      menuItem: ResolversTypes['PublishedMenuItem']
    }
  >
  SousChefPercentageData: ResolverTypeWrapper<SousChefPercentageData>
  SousChefPercentageDataPoint: ResolverTypeWrapper<SousChefPercentageDataPoint>
  SousChefQuery: ResolverTypeWrapper<
    Omit<SousChefQuery, 'insight' | 'threadSession' | 'threadSessions'> & {
      insight?: Maybe<ResolversTypes['SousChefInsight']>
      threadSession?: Maybe<ResolversTypes['SousChefThreadSession']>
      threadSessions?: Maybe<Array<ResolversTypes['SousChefThreadSession']>>
    }
  >
  SousChefRevenueCenter: ResolverTypeWrapper<SousChefRevenueCenter>
  SousChefRevenueCenterFilter: ResolverTypeWrapper<SousChefRevenueCenterFilter>
  SousChefSalesCategory: ResolverTypeWrapper<SousChefSalesCategory>
  SousChefSalesCategoryFilter: ResolverTypeWrapper<SousChefSalesCategoryFilter>
  SousChefServiceArea: ResolverTypeWrapper<SousChefServiceArea>
  SousChefServiceAreaFilter: ResolverTypeWrapper<SousChefServiceAreaFilter>
  SousChefStockDataPoint: ResolverTypeWrapper<
    Omit<SousChefStockDataPoint, 'items'> & {
      items: Array<ResolversTypes['SousChefOutOfStockItem']>
    }
  >
  SousChefSuggestedThread: ResolverTypeWrapper<SousChefSuggestedThread>
  SousChefSummary: ResolverTypeWrapper<
    Omit<SousChefSummary, 'checklist' | 'insights' | 'threadGroups'> & {
      checklist: Array<ResolversTypes['SousChefChecklistItem']>
      insights: Array<ResolversTypes['SousChefInsight']>
      threadGroups: Array<ResolversTypes['SousChefThreadGroup']>
    }
  >
  SousChefTaskData: ResolverTypeWrapper<SousChefTaskData>
  SousChefTextDataPoint: ResolverTypeWrapper<SousChefTextDataPoint>
  SousChefThread: ResolverTypeWrapper<
    ResolversInterfaceTypes<ResolversTypes>['SousChefThread']
  >
  SousChefThreadFeedback: SousChefThreadFeedback
  SousChefThreadGroup: ResolverTypeWrapper<
    Omit<SousChefThreadGroup, 'threads'> & {
      threads: Array<ResolversTypes['SousChefThread']>
    }
  >
  SousChefThreadGroupType: SousChefThreadGroupType
  SousChefThreadSession: ResolverTypeWrapper<
    Omit<SousChefThreadSession, 'threads'> & {
      threads: Array<ResolversTypes['SousChefCompletedThread']>
    }
  >
  SousChefTrend: ResolverTypeWrapper<SousChefTrend>
  SousChefTrendDataPoint: ResolverTypeWrapper<SousChefTrendDataPoint>
  SousChefTrendDirection: SousChefTrendDirection
  SousChefTrendSentiment: SousChefTrendSentiment
  SousChefWorkflowDefinition: ResolverTypeWrapper<
    Omit<SousChefWorkflowDefinition, 'edges' | 'nodes'> & {
      edges: Array<ResolversTypes['SousChefEdge']>
      nodes: Array<ResolversTypes['SousChefWorkflowNode']>
    }
  >
  SousChefWorkflowNode: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['SousChefWorkflowNode']
  >
  SousChefWorkflowSource: SousChefWorkflowSource
  SousChefWorkflowTask: ResolverTypeWrapper<SousChefWorkflowTask>
  SpecialOccasion: SpecialOccasion
  StartDoorDashOnboardingRequest: StartDoorDashOnboardingRequest
  StaticPaymentDataExtension: ResolverTypeWrapper<StaticPaymentDataExtension>
  StaticPaymentType: StaticPaymentType
  Step: ResolverTypeWrapper<Step>
  Stock: ResolverTypeWrapper<Stock>
  StockDataConfigInput: StockDataConfigInput
  StockDataConfigOverride: ResolverTypeWrapper<
    Omit<StockDataConfigOverride, 'target'> & {
      target: ResolversTypes['RestaurantSet']
    }
  >
  StockDataConfigOverrideInput: StockDataConfigOverrideInput
  StockOutConfigInput: StockOutConfigInput
  StockOutConfigOverride: ResolverTypeWrapper<
    Omit<StockOutConfigOverride, 'target'> & {
      target: ResolversTypes['RestaurantSet']
    }
  >
  StockOutConfigOverrideInput: StockOutConfigOverrideInput
  StockStatus: StockStatus
  SubmitLogbookEntryInput: SubmitLogbookEntryInput
  SubmitLogbookEntryReplyInput: SubmitLogbookEntryReplyInput
  SubscriptionState: SubscriptionState
  SubscriptionsCancellationReason: SubscriptionsCancellationReason
  SubscriptionsCancellationRequest: ResolverTypeWrapper<SubscriptionsCancellationRequest>
  SubscriptionsCancellationRequestFilter: SubscriptionsCancellationRequestFilter
  SubscriptionsCancellationRequestInput: SubscriptionsCancellationRequestInput
  SubscriptionsCancellationRequestItemInput: SubscriptionsCancellationRequestItemInput
  SuggestionResponse: ResolverTypeWrapper<SuggestionResponse>
  SupportQuery: ResolverTypeWrapper<SupportQuery>
  SystemIdentity: ResolverTypeWrapper<SystemIdentity>
  TableAvailabilityInfo: ResolverTypeWrapper<TableAvailabilityInfo>
  TableBlocked: ResolverTypeWrapper<TableBlocked>
  TableNotFound: ResolverTypeWrapper<TableNotFound>
  TakeoutDeliveryCategoryPage: ResolverTypeWrapper<TakeoutDeliveryCategoryPage>
  TakeoutDeliveryConfig: ResolverTypeWrapper<TakeoutDeliveryConfig>
  TargetAndOwnerInput: TargetAndOwnerInput
  Task: ResolverTypeWrapper<Task>
  TaskAction: ResolverTypeWrapper<TaskAction>
  TaskType: TaskType
  TaxRateDataExtension: ResolverTypeWrapper<
    Omit<TaxRateDataExtension, 'taxRate'> & {
      taxRate: ResolversTypes['SavedTaxRate']
    }
  >
  TaxRateType: TaxRateType
  TerminalHandheld: ResolverTypeWrapper<TerminalHandheld>
  TerminatedEmploymentStatus: ResolverTypeWrapper<TerminatedEmploymentStatus>
  TextArrayFilter: TextArrayFilter
  TextFilter: TextFilter
  TextFilterOperation: TextFilterOperation
  ThirdPartyMockMutation: ResolverTypeWrapper<ThirdPartyMockMutation>
  ThirdPartyMockProviderEvent: ResolverTypeWrapper<ThirdPartyMockProviderEvent>
  ThirdPartyMockProviderEventInput: ThirdPartyMockProviderEventInput
  ThirdPartyMockQuery: ResolverTypeWrapper<ThirdPartyMockQuery>
  ThirdPartyOrderingOnboardingFastTrackRequest: ThirdPartyOrderingOnboardingFastTrackRequest
  ThirdPartyOrderingOnboardingFlow: ResolverTypeWrapper<ThirdPartyOrderingOnboardingFlow>
  ThirdPartyOrderingOnboardingFlowInput: ThirdPartyOrderingOnboardingFlowInput
  ThirdPartyOrderingOnboardingMutation: ResolverTypeWrapper<ThirdPartyOrderingOnboardingMutation>
  ThirdPartyOrderingOnboardingProvider: ThirdPartyOrderingOnboardingProvider
  ThirdPartyOrderingOnboardingQuery: ResolverTypeWrapper<ThirdPartyOrderingOnboardingQuery>
  ThirdPartyOrderingOnboardingTask: ResolverTypeWrapper<ThirdPartyOrderingOnboardingTask>
  ThirdPartyOrderingOnboardingTaskId: ThirdPartyOrderingOnboardingTaskId
  ThirdPartyOrderingOnboardingTaskInput: ThirdPartyOrderingOnboardingTaskInput
  ThirdPartyOrderingOnboardingTaskResult: ResolverTypeWrapper<ThirdPartyOrderingOnboardingTaskResult>
  ThirdPartyOrderingOnboardingTaskResultStatus: ThirdPartyOrderingOnboardingTaskResultStatus
  ThirdPartyOrderingSavedMenuAuditEntityType: ThirdPartyOrderingSavedMenuAuditEntityType
  ThirdPartyOrderingSavedMenuAuditSetting: ResolverTypeWrapper<ThirdPartyOrderingSavedMenuAuditSetting>
  ThirdPartyOrderingSavedMenuAuditSettingType: ThirdPartyOrderingSavedMenuAuditSettingType
  TimeClockInput: TimeClockInput
  TimeEntryRoundingScheme: TimeEntryRoundingScheme
  TimeRangeInput: TimeRangeInput
  TipDetails: ResolverTypeWrapper<TipDetails>
  TipInput: TipInput
  TipOutConfig: ResolverTypeWrapper<TipOutConfig>
  TipOutConfigInput: TipOutConfigInput
  TipSharingConfig: ResolverTypeWrapper<TipSharingConfig>
  TipSharingPercentage: ResolverTypeWrapper<TipSharingPercentage>
  TipSharingPercentageInput: TipSharingPercentageInput
  TipsQuery: ResolverTypeWrapper<TipsQuery>
  ToastCentralArticle: ResolverTypeWrapper<ToastCentralArticle>
  ToastNavigationConfig: ResolverTypeWrapper<ToastNavigationConfig>
  ToastNowDevicePlatform: ToastNowDevicePlatform
  ToastNowDeviceRegistryMutation: ResolverTypeWrapper<ToastNowDeviceRegistryMutation>
  ToastNowDeviceRegistryQuery: ResolverTypeWrapper<ToastNowDeviceRegistryQuery>
  ToastNowDeviceToken: ResolverTypeWrapper<ToastNowDeviceToken>
  ToastNowUpdateDeviceTokenInput: ToastNowUpdateDeviceTokenInput
  ToastPayConfig: ResolverTypeWrapper<ToastPayConfig>
  ToastPayConfigInput: ToastPayConfigInput
  ToastPayPaymentNotificationConfig: ResolverTypeWrapper<ToastPayPaymentNotificationConfig>
  ToastPayPaymentNotificationConfigError: ResolverTypeWrapper<ToastPayPaymentNotificationConfigError>
  ToastPayPaymentNotificationConfigErrorCode: ToastPayPaymentNotificationConfigErrorCode
  ToastPayPaymentNotificationConfigResponse: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['ToastPayPaymentNotificationConfigResponse']
  >
  ToastPayRelatedConfiguration: ResolverTypeWrapper<
    Omit<
      ToastPayRelatedConfiguration,
      'paymentNotificationsConfig' | 'restaurantLogo' | 'tipSelectionsConfig'
    > & {
      paymentNotificationsConfig: ResolversTypes['ToastPayPaymentNotificationConfigResponse']
      restaurantLogo: ResolversTypes['ToastPayRestaurantLogoResponse']
      tipSelectionsConfig: ResolversTypes['ToastPayTipSelectionsConfigResponse']
    }
  >
  ToastPayRestaurantLogo: ResolverTypeWrapper<ToastPayRestaurantLogo>
  ToastPayRestaurantLogoError: ResolverTypeWrapper<ToastPayRestaurantLogoError>
  ToastPayRestaurantLogoErrorCode: ToastPayRestaurantLogoErrorCode
  ToastPayRestaurantLogoResponse: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['ToastPayRestaurantLogoResponse']
  >
  ToastPayTipSelectionsConfig: ResolverTypeWrapper<ToastPayTipSelectionsConfig>
  ToastPayTipSelectionsConfigResponse: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['ToastPayTipSelectionsConfigResponse']
  >
  ToastPayTipSelectionsError: ResolverTypeWrapper<ToastPayTipSelectionsError>
  ToastPayTipSelectionsErrorCode: ToastPayTipSelectionsErrorCode
  ToastPermission: ResolverTypeWrapper<ToastPermission>
  TopSellingItemConnection: ResolverTypeWrapper<
    Omit<TopSellingItemConnection, 'edges'> & {
      edges: Array<ResolversTypes['TopSellingItemEdge']>
    }
  >
  TopSellingItemEdge: ResolverTypeWrapper<
    Omit<TopSellingItemEdge, 'node'> & { node: ResolversTypes['ItemMetrics'] }
  >
  TopSellingItemFilter: TopSellingItemFilter
  TopSellingItemFilterBy: TopSellingItemFilterBy
  TopSellingItemFilterDimension: TopSellingItemFilterDimension
  TopSellingModifierConnection: ResolverTypeWrapper<
    Omit<TopSellingModifierConnection, 'edges'> & {
      edges: Array<ResolversTypes['TopSellingModifierEdge']>
    }
  >
  TopSellingModifierEdge: ResolverTypeWrapper<
    Omit<TopSellingModifierEdge, 'node'> & {
      node: ResolversTypes['ModifierMetrics']
    }
  >
  TopSellingModifierFilter: TopSellingModifierFilter
  TopSellingModifierFilterDimension: TopSellingModifierFilterDimension
  TopSellingSortBy: TopSellingSortBy
  TotalRefundAmount: ResolverTypeWrapper<TotalRefundAmount>
  TrialEligibilityProbeResponse: ResolverTypeWrapper<TrialEligibilityProbeResponse>
  TrialEntity: ResolverTypeWrapper<TrialEntity>
  TrialEntityType: TrialEntityType
  TrialFeedbackRequest: TrialFeedbackRequest
  TrialFeedbackResponse: ResolverTypeWrapper<TrialFeedbackResponse>
  TrialMutation: ResolverTypeWrapper<TrialMutation>
  TrialMutationResponse: ResolverTypeWrapper<TrialMutationResponse>
  TrialOperationStatus: TrialOperationStatus
  TrialProbe: ResolverTypeWrapper<TrialProbe>
  TrialsQuery: ResolverTypeWrapper<TrialsQuery>
  UIOptionInvalidEntity: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['UIOptionInvalidEntity']
  >
  UIOptionInvalidSmartTax: ResolverTypeWrapper<
    Omit<UiOptionInvalidSmartTax, 'invalidEntities'> & {
      invalidEntities: Array<ResolversTypes['UIOptionInvalidEntity']>
    }
  >
  UIOptionsMutation: ResolverTypeWrapper<
    Omit<UiOptionsMutation, 'updateSavedUIOption' | 'updateSavedUIOptionV2'> & {
      updateSavedUIOption: ResolversTypes['MlmUIOption']
      updateSavedUIOptionV2: ResolversTypes['UpdateSavedUIOptionResponse']
    }
  >
  UIOptionsQuery: ResolverTypeWrapper<
    Omit<UiOptionsQuery, 'savedUIOption'> & {
      savedUIOption: ResolversTypes['MlmUIOption']
    }
  >
  UIOptionsTaxOptions: ResolverTypeWrapper<UiOptionsTaxOptions>
  UIOptionsTaxOptionsInput: UiOptionsTaxOptionsInput
  UIOptionsTipSetup: ResolverTypeWrapper<UiOptionsTipSetup>
  UIOptionsTipSetupInput: UiOptionsTipSetupInput
  UIOptionsTippingAmounts: ResolverTypeWrapper<UiOptionsTippingAmounts>
  UIOptionsTippingAmountsInput: UiOptionsTippingAmountsInput
  UIOptionsTippingDisplayMode: UiOptionsTippingDisplayMode
  UIOptionsTippingPercentages: ResolverTypeWrapper<UiOptionsTippingPercentages>
  UIOptionsTippingPercentagesInput: UiOptionsTippingPercentagesInput
  UIOptionsTippingRequireApproval: ResolverTypeWrapper<UiOptionsTippingRequireApproval>
  UIOptionsTippingRequireApprovalInput: UiOptionsTippingRequireApprovalInput
  UKCustomerBillingInvoice: ResolverTypeWrapper<
    Omit<UkCustomerBillingInvoice, 'deductions'> & {
      deductions: ResolversTypes['CustomerBillingInvoiceDeductions']
    }
  >
  UKCustomerBillingInvoiceItem: ResolverTypeWrapper<UkCustomerBillingInvoiceItem>
  USCustomerBillingInvoice: ResolverTypeWrapper<
    Omit<UsCustomerBillingInvoice, 'deductions'> & {
      deductions: ResolversTypes['CustomerBillingInvoiceDeductions']
    }
  >
  USCustomerBillingInvoiceItem: ResolverTypeWrapper<UsCustomerBillingInvoiceItem>
  UnableToCreateDepositOrder: ResolverTypeWrapper<UnableToCreateDepositOrder>
  UnableToCreateGuest: ResolverTypeWrapper<UnableToCreateGuest>
  UnableToFetchGuests: ResolverTypeWrapper<UnableToFetchGuests>
  UnableToSendSMS: ResolverTypeWrapper<UnableToSendSms>
  UnableToUpdateGuest: ResolverTypeWrapper<UnableToUpdateGuest>
  Unauthorized: ResolverTypeWrapper<Unauthorized>
  UnauthorizedAccessError: ResolverTypeWrapper<UnauthorizedAccessError>
  UnitOfMeasure: UnitOfMeasure
  UnknownError: ResolverTypeWrapper<UnknownError>
  UnknownIdentity: ResolverTypeWrapper<UnknownIdentity>
  UnpublishedChangesFilterInput: UnpublishedChangesFilterInput
  UnpublishedConfig: ResolverTypeWrapper<UnpublishedConfig>
  UnpublishedConfigConnection: ResolverTypeWrapper<UnpublishedConfigConnection>
  UnpublishedConfigEdge: ResolverTypeWrapper<UnpublishedConfigEdge>
  UnpublishedStatus: ResolverTypeWrapper<UnpublishedStatus>
  UnpublishedStatusConnection: ResolverTypeWrapper<UnpublishedStatusConnection>
  UnpublishedStatusEdge: ResolverTypeWrapper<UnpublishedStatusEdge>
  UnsupportedTransition: ResolverTypeWrapper<UnsupportedTransition>
  UpdateCashFlowTransactionRequest: UpdateCashFlowTransactionRequest
  UpdateDataExtensionSetInput: UpdateDataExtensionSetInput
  UpdateDiningOptionInput: UpdateDiningOptionInput
  UpdateGfdConfigInput: UpdateGfdConfigInput
  UpdateGuestRequest: UpdateGuestRequest
  UpdateItemFeedbackConfigError: ResolverTypeWrapper<UpdateItemFeedbackConfigError>
  UpdateItemFeedbackConfigErrorCode: UpdateItemFeedbackConfigErrorCode
  UpdateItemFeedbackConfigResponse: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['UpdateItemFeedbackConfigResponse']
  >
  UpdateKioskConfigInput: UpdateKioskConfigInput
  UpdateKioskConfigServiceChargeInput: UpdateKioskConfigServiceChargeInput
  UpdateKioskCustomerMessageSettingsInput: UpdateKioskCustomerMessageSettingsInput
  UpdateKioskUpsellMenuItemInput: UpdateKioskUpsellMenuItemInput
  UpdateLogbookEntry: UpdateLogbookEntry
  UpdateLogbookEntryInput: UpdateLogbookEntryInput
  UpdateLogbookEntryReply: UpdateLogbookEntryReply
  UpdateLogbookEntryReplyInput: UpdateLogbookEntryReplyInput
  UpdateMembershipInput: UpdateMembershipInput
  UpdateMembershipResult: ResolverTypeWrapper<UpdateMembershipResult>
  UpdateMenuItemPlateCost: UpdateMenuItemPlateCost
  UpdateMlmUIOptionInput: UpdateMlmUiOptionInput
  UpdateMlmUIOptionVariantInput: UpdateMlmUiOptionVariantInput
  UpdateReasonInput: UpdateReasonInput
  UpdateReasonOrdinalInput: UpdateReasonOrdinalInput
  UpdateReservationRequest: UpdateReservationRequest
  UpdateReservationResponse: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['UpdateReservationResponse']
  >
  UpdateReservationResponseSuccess: ResolverTypeWrapper<UpdateReservationResponseSuccess>
  UpdateSavedUIOptionResponse: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['UpdateSavedUIOptionResponse']
  >
  UpdateShiftFailure: ResolverTypeWrapper<UpdateShiftFailure>
  UpdateShiftInput: UpdateShiftInput
  UpdateShiftResult: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['UpdateShiftResult']
  >
  UpdateShiftTimesInput: UpdateShiftTimesInput
  UpdateShiftTimesResult: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['UpdateShiftTimesResult']
  >
  UpdateSubscriptionAutoRenewalInput: UpdateSubscriptionAutoRenewalInput
  UpdateTipAmountInput: UpdateTipAmountInput
  UpdateToastPayConfigError: ResolverTypeWrapper<UpdateToastPayConfigError>
  UpdateToastPayConfigErrorCode: UpdateToastPayConfigErrorCode
  UpdateToastPayConfigResponse: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['UpdateToastPayConfigResponse']
  >
  UpdateUserPreferredLocalResponse: ResolverTypeWrapper<UpdateUserPreferredLocalResponse>
  UpdateUserQuickActionInput: UpdateUserQuickActionInput
  UpgradeItemFeedbackConfigResponse: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['UpgradeItemFeedbackConfigResponse']
  >
  UploadImageResponse: ResolverTypeWrapper<UploadImageResponse>
  UpsellRequest: UpsellRequest
  Url: ResolverTypeWrapper<Scalars['Url']['output']>
  User: ResolverTypeWrapper<User>
  UserFeatureOptIn: ResolverTypeWrapper<UserFeatureOptIn>
  UserFeatureOptInsResponse: ResolverTypeWrapper<UserFeatureOptInsResponse>
  UserIdentity: ResolverTypeWrapper<UserIdentity>
  UserInteractionResponse: ResolverTypeWrapper<UserInteractionResponse>
  UserInterface: ResolverTypeWrapper<
    ResolversInterfaceTypes<ResolversTypes>['UserInterface']
  >
  UserIteraction: ResolverTypeWrapper<UserIteraction>
  UserIteractionMutationResponse: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['UserIteractionMutationResponse']
  >
  UserPreferencesMutation: ResolverTypeWrapper<UserPreferencesMutation>
  UserPreferencesQuery: ResolverTypeWrapper<UserPreferencesQuery>
  UserQuickAction: ResolverTypeWrapper<UserQuickAction>
  UserQuickActionEntityType: UserQuickActionEntityType
  UserQuickActionInput: UserQuickActionInput
  UserQuickActionParam: ResolverTypeWrapper<UserQuickActionParam>
  UserQuickActionParamInput: UserQuickActionParamInput
  UserSearch: ResolverTypeWrapper<UserSearch>
  VersionInput: VersionInput
  VersionResolution: VersionResolution
  VoidAmount: ResolverTypeWrapper<
    Omit<VoidAmount, 'grouped'> & {
      grouped?: Maybe<Array<ResolversTypes['GroupedVoidAmount']>>
    }
  >
  VoidAmountAsPercentageOfNetSales: ResolverTypeWrapper<
    Omit<VoidAmountAsPercentageOfNetSales, 'grouped'> & {
      grouped?: Maybe<
        Array<ResolversTypes['GroupedVoidAmountAsPercentageOfNetSales']>
      >
    }
  >
  VoidAmountAsPercentageOfNetSalesGroupingDimension: VoidAmountAsPercentageOfNetSalesGroupingDimension
  VoidFilter: VoidFilter
  VoidGroupEntity: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['VoidGroupEntity']
  >
  VoidGroupingDimension: VoidGroupingDimension
  VoidReason: ResolverTypeWrapper<VoidReason>
  VoidedItemsCount: ResolverTypeWrapper<
    Omit<VoidedItemsCount, 'grouped'> & {
      grouped: Array<ResolversTypes['GroupedVoidedItemsCount']>
    }
  >
  WageFrequency: WageFrequency
  WaitlistAlgorithmType: WaitlistAlgorithmType
  WeekOperatingSchedule: ResolverTypeWrapper<WeekOperatingSchedule>
  WelcomeScreenMode: WelcomeScreenMode
  Workflow: ResolverTypeWrapper<Workflow>
}

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  AccessibleRestaurantLocation: Omit<
    AccessibleRestaurantLocation,
    'restaurantSet'
  > & { restaurantSet: ResolversParentTypes['RestaurantSet'] }
  String: Scalars['String']['output']
  ID: Scalars['ID']['output']
  AccessibleRestaurantSet: Omit<AccessibleRestaurantSet, 'restaurantSet'> & {
    restaurantSet: ResolversParentTypes['RestaurantSet']
  }
  Boolean: Scalars['Boolean']['output']
  AccessibleRestaurantSetsInput: AccessibleRestaurantSetsInput
  ActiveEmploymentStatus: ActiveEmploymentStatus
  AdditionalModifierGroups: AdditionalModifierGroups
  AdditionalModifierGroupsCourse: AdditionalModifierGroupsCourse
  AdditionalModifierGroupsCourseInput: AdditionalModifierGroupsCourseInput
  AdditionalModifierGroupsDiningOption: AdditionalModifierGroupsDiningOption
  AdditionalModifierGroupsDiningOptionInput: AdditionalModifierGroupsDiningOptionInput
  AdditionalModifierGroupsInput: AdditionalModifierGroupsInput
  AdditionalModifierGroupsSeatNumberQuickOrder: AdditionalModifierGroupsSeatNumberQuickOrder
  AdditionalModifierGroupsSeatNumberQuickOrderInput: AdditionalModifierGroupsSeatNumberQuickOrderInput
  AdditionalModifierGroupsSeatNumberTableService: AdditionalModifierGroupsSeatNumberTableService
  AdditionalModifierGroupsSeatNumberTableServiceInput: AdditionalModifierGroupsSeatNumberTableServiceInput
  AdditionalModifierGroupsSpecialRequest: AdditionalModifierGroupsSpecialRequest
  AdditionalModifierGroupsSpecialRequestInput: AdditionalModifierGroupsSpecialRequestInput
  AdditionalModifierGroupsSplit: AdditionalModifierGroupsSplit
  AdditionalModifierGroupsSplitInput: AdditionalModifierGroupsSplitInput
  AiChatTermsOfUseResponse: AiChatTermsOfUseResponse
  AiTermsOfUseDefaultResponse: AiTermsOfUseDefaultResponse
  AllBookingsResponse: ResolversUnionTypes<ResolversParentTypes>['AllBookingsResponse']
  AllBookingsResponseSuccess: AllBookingsResponseSuccess
  AllEmploymentStatuses: ResolversUnionTypes<ResolversParentTypes>['AllEmploymentStatuses']
  AlternatePaymentDataExtension: Omit<
    AlternatePaymentDataExtension,
    'alternatePaymentType'
  > & {
    alternatePaymentType: ResolversParentTypes['SavedAlternatePaymentType']
  }
  ApplicantEmploymentStatus: ApplicantEmploymentStatus
  ApplicationMetadata: ApplicationMetadata
  ArchiveCustomMenuItemUpsellInput: ArchiveCustomMenuItemUpsellInput
  ArchiveEntityInput: ArchiveEntityInput
  ArchiveMembershipResponse: ArchiveMembershipResponse
  Int: Scalars['Int']['output']
  AuthConfigurationMutation: AuthConfigurationMutation
  AuthConfigurationQuery: AuthConfigurationQuery
  AuthenticationFailure: AuthenticationFailure
  AuthorizationDecisionReason: AuthorizationDecisionReason
  AuthorizationPage: AuthorizationPage
  AuthorizationPageInput: AuthorizationPageInput
  AuthorizationTarget: AuthorizationTarget
  AuthorizationTargetInput: AuthorizationTargetInput
  AvailabilitiesResponse: ResolversUnionTypes<ResolversParentTypes>['AvailabilitiesResponse']
  AvailabilitiesResponseSuccess: Omit<
    AvailabilitiesResponseSuccess,
    'restaurantAvailability'
  > & {
    restaurantAvailability?: Maybe<
      ResolversParentTypes['RestaurantAvailabilityInfo']
    >
  }
  BankingConnection: BankingConnection
  BlockConfig: BlockConfig
  BlockConfigsResponse: ResolversUnionTypes<ResolversParentTypes>['BlockConfigsResponse']
  BlockConfigsSuccess: BlockConfigsSuccess
  BlockedDate: BlockedDate
  Booking: Booking
  Float: Scalars['Float']['output']
  BookingMutation: Omit<
    BookingMutation,
    | 'cancelBooking'
    | 'createBlock'
    | 'createBooking'
    | 'createGuest'
    | 'updateBooking'
    | 'updateGuest'
  > & {
    cancelBooking: ResolversParentTypes['CancelReservationResponse']
    createBlock: ResolversParentTypes['CreateBlockResponse']
    createBooking: ResolversParentTypes['CreateReservationResponse']
    createGuest: ResolversParentTypes['GuestCreationResponse']
    updateBooking: ResolversParentTypes['UpdateReservationResponse']
    updateGuest: ResolversParentTypes['GuestUpdateResponse']
  }
  BookingNotFound: BookingNotFound
  BookingQuery: Omit<
    BookingQuery,
    | 'availabilities'
    | 'blocks'
    | 'bookingLimit'
    | 'bookings'
    | 'guest'
    | 'mostRecentImportGuid'
    | 'restaurant'
  > & {
    availabilities: ResolversParentTypes['AvailabilitiesResponse']
    blocks: ResolversParentTypes['BlockConfigsResponse']
    bookingLimit: ResolversParentTypes['CurrentBookingUsageResponse']
    bookings: ResolversParentTypes['AllBookingsResponse']
    guest: ResolversParentTypes['GuestListResponse']
    mostRecentImportGuid: ResolversParentTypes['MostRecentImportGuidResponse']
    restaurant: ResolversParentTypes['MgmtRestaurantInformationResponse']
  }
  BookingRequiresDepositData: BookingRequiresDepositData
  BookingServiceArea: BookingServiceArea
  BookingServiceAreaGroup: BookingServiceAreaGroup
  BookingTable: BookingTable
  BookingsEmployee: BookingsEmployee
  BreakConfig: BreakConfig
  BreakConfigDependencyFailure: BreakConfigDependencyFailure
  BreakConfigInput: BreakConfigInput
  BreakInput: BreakInput
  BuildShiftFailure: BuildShiftFailure
  ByArchiveStatusSearchInput: ByArchiveStatusSearchInput
  ByEmailSearchInput: ByEmailSearchInput
  ByEmployeeIdInputSearchInput: ByEmployeeIdInputSearchInput
  ByJobIdInput: ByJobIdInput
  ByNameSearchInput: ByNameSearchInput
  ByPhoneNumberSearchInput: ByPhoneNumberSearchInput
  CANCustomerBillingInvoice: Omit<CanCustomerBillingInvoice, 'deductions'> & {
    deductions: ResolversParentTypes['CustomerBillingInvoiceDeductions']
  }
  CANCustomerBillingInvoiceItem: CanCustomerBillingInvoiceItem
  CANCustomerBillingInvoiceTaxDetails: CanCustomerBillingInvoiceTaxDetails
  CancelReservationResponse: ResolversUnionTypes<ResolversParentTypes>['CancelReservationResponse']
  CancelReservationResponseSuccess: CancelReservationResponseSuccess
  CancelTrialRequest: CancelTrialRequest
  CapitalQuery: CapitalQuery
  CashDrawer: CashDrawer
  CashDrawerConfig: CashDrawerConfig
  CashDrawerConfigInput: CashDrawerConfigInput
  CashDrawerInput: CashDrawerInput
  CashFlowAccount: CashFlowAccount
  CashFlowAccountTransactions: CashFlowAccountTransactions
  CashFlowSummary: CashFlowSummary
  CashFlowTransaction: CashFlowTransaction
  Category: Category
  ChannelAvailability: ChannelAvailability
  ChannelMenuEntity: Omit<
    ChannelMenuEntity,
    'channel' | 'menu' | 'ownerRestaurantSet' | 'targetRestaurantSet'
  > & {
    channel?: Maybe<ResolversParentTypes['SavedChannel']>
    menu?: Maybe<ResolversParentTypes['Menu']>
    ownerRestaurantSet?: Maybe<ResolversParentTypes['RestaurantSet']>
    targetRestaurantSet?: Maybe<ResolversParentTypes['RestaurantSet']>
  }
  ChannelMenuEntityInput: ChannelMenuEntityInput
  ChannelMenuPriceAdjustment: Omit<
    ChannelMenuPriceAdjustment,
    'channel' | 'ownerRestaurantSet' | 'targetRestaurantSet'
  > & {
    channel?: Maybe<ResolversParentTypes['SavedChannel']>
    ownerRestaurantSet?: Maybe<ResolversParentTypes['RestaurantSet']>
    targetRestaurantSet?: Maybe<ResolversParentTypes['RestaurantSet']>
  }
  ChannelMenuPriceAdjustmentInput: ChannelMenuPriceAdjustmentInput
  ChannelMutation: Omit<
    ChannelMutation,
    | 'createSavedChannelAvailabilities'
    | 'createSavedChannelAvailabilityForChannel'
    | 'createSavedChannels'
    | 'initializeSavedChannel'
    | 'updateSavedChannel'
    | 'updateSavedChannelAvailability'
  > & {
    createSavedChannelAvailabilities: Array<
      ResolversParentTypes['SavedChannelAvailability']
    >
    createSavedChannelAvailabilityForChannel: ResolversParentTypes['SavedChannel']
    createSavedChannels: Array<ResolversParentTypes['SavedChannel']>
    initializeSavedChannel: ResolversParentTypes['SavedChannel']
    updateSavedChannel: ResolversParentTypes['SavedChannel']
    updateSavedChannelAvailability: ResolversParentTypes['SavedChannelAvailability']
  }
  ChannelQuery: Omit<
    ChannelQuery,
    | 'channelTypes'
    | 'publishedChannels'
    | 'savedChannelAvailabilities'
    | 'savedChannelAvailabilityById'
    | 'savedChannelAvailabilityByMultiLocationId'
    | 'savedChannelByChannelType'
    | 'savedChannelByMultiLocationId'
    | 'savedChannels'
    | 'searchSavedChannels'
  > & {
    channelTypes: Array<ResolversParentTypes['ChannelType']>
    publishedChannels: Array<ResolversParentTypes['PublishedChannel']>
    savedChannelAvailabilities: ResolversParentTypes['PagedSavedChannelAvailability']
    savedChannelAvailabilityById: ResolversParentTypes['SavedChannelAvailability']
    savedChannelAvailabilityByMultiLocationId: ResolversParentTypes['SavedChannelAvailability']
    savedChannelByChannelType: ResolversParentTypes['SavedChannel']
    savedChannelByMultiLocationId: ResolversParentTypes['SavedChannel']
    savedChannels?: Maybe<Array<ResolversParentTypes['SavedChannel']>>
    searchSavedChannels: ResolversParentTypes['PagedSavedChannel']
  }
  ChannelType: Omit<ChannelType, 'savedChannels'> & {
    savedChannels: Array<ResolversParentTypes['SavedChannel']>
  }
  ChannelTypeInput: ChannelTypeInput
  ChatMessage: ChatMessage
  ChatMessageFeedback: ChatMessageFeedback
  ChatMessageFilters: ChatMessageFilters
  ChatMessageFiltersDateRange: ChatMessageFiltersDateRange
  ChatMessageFiltersDefinedHours: ChatMessageFiltersDefinedHours
  ChatMessageInput: ChatMessageInput
  ChatResponse: ChatResponse
  Check: Omit<Check, 'guestFeedback' | 'selections'> & {
    guestFeedback: Array<ResolversParentTypes['GuestFeedbackInfo']>
    selections: Array<ResolversParentTypes['OrdersSelection']>
  }
  ChecksWithRefundsCount: ChecksWithRefundsCount
  ChecksWithVoidsCount: Omit<ChecksWithVoidsCount, 'grouped'> & {
    grouped?: Maybe<Array<ResolversParentTypes['GroupedChecksWithVoidsCount']>>
  }
  ClockOutFailure: ClockOutFailure
  CloseOutReportingConfig: CloseOutReportingConfig
  CloseOutReportingContent: CloseOutReportingContent
  Comparison: Comparison
  ConfigCopyQuery: Omit<
    ConfigCopyQuery,
    'copyConfigTask' | 'copyConfigTasks'
  > & {
    copyConfigTask?: Maybe<ResolversParentTypes['CopyConfigTask']>
    copyConfigTasks: ResolversParentTypes['CopyConfigTaskConnection']
  }
  ConfigField: ConfigField
  ConfigFieldInput: ConfigFieldInput
  ConfigLocation: ConfigLocation
  ConfigMutation: ConfigMutation
  ConfigQuery: Omit<ConfigQuery, 'copyConfigTasks'> & {
    copyConfigTasks: ResolversParentTypes['CopyConfigTaskConnection']
  }
  ConfigReferencesCount: ConfigReferencesCount
  ConfigType: ConfigType
  ConfigTypeInput: ConfigTypeInput
  ConfigValueDiff: ConfigValueDiff
  ConfigValueDiffInformation: ConfigValueDiffInformation
  ConnectionPaginationInput: ConnectionPaginationInput
  CopyConfigMutation: Omit<CopyConfigMutation, 'createCopyConfigTask'> & {
    createCopyConfigTask: ResolversParentTypes['CopyConfigTask']
  }
  CopyConfigTask: Omit<CopyConfigTask, 'destinations'> & {
    destinations: Array<ResolversParentTypes['RestaurantSet']>
  }
  CopyConfigTaskConnection: Omit<CopyConfigTaskConnection, 'edges'> & {
    edges: Array<Maybe<ResolversParentTypes['CopyConfigTaskEdge']>>
  }
  CopyConfigTaskEdge: Omit<CopyConfigTaskEdge, 'node'> & {
    node: ResolversParentTypes['CopyConfigTask']
  }
  CopyConfigTaskInput: CopyConfigTaskInput
  CopyConfigTasksPaginationInput: CopyConfigTasksPaginationInput
  CorruptPageTokenError: CorruptPageTokenError
  Course: Course
  CreateBlockRequest: CreateBlockRequest
  CreateBlockResponse: ResolversUnionTypes<ResolversParentTypes>['CreateBlockResponse']
  CreateBlockSuccess: CreateBlockSuccess
  CreateDataExtensionSetInput: CreateDataExtensionSetInput
  CreateGfdConfigInput: CreateGfdConfigInput
  CreateGuestRequest: CreateGuestRequest
  CreateItemFeedbackConfigError: CreateItemFeedbackConfigError
  CreateItemFeedbackConfigResponse: ResolversUnionTypes<ResolversParentTypes>['CreateItemFeedbackConfigResponse']
  CreateLogbookEntryReply: CreateLogbookEntryReply
  CreateLogbookEntryWithBusinessDay: CreateLogbookEntryWithBusinessDay
  CreateMembershipInput: CreateMembershipInput
  CreateMembershipResult: CreateMembershipResult
  CreateMenuItemPlateCost: CreateMenuItemPlateCost
  CreateReasonInput: CreateReasonInput
  CreateReservationRequest: CreateReservationRequest
  CreateReservationResponse: ResolversUnionTypes<ResolversParentTypes>['CreateReservationResponse']
  CreateReservationResponseSuccess: CreateReservationResponseSuccess
  CreateRestaurantGroupInput: CreateRestaurantGroupInput
  CreateShiftInput: CreateShiftInput
  CreateShiftResult: ResolversUnionTypes<ResolversParentTypes>['CreateShiftResult']
  CreateToastPayConfigError: CreateToastPayConfigError
  CreateToastPayConfigResponse: ResolversUnionTypes<ResolversParentTypes>['CreateToastPayConfigResponse']
  CreateTrialRequest: CreateTrialRequest
  CreateUserQuickActionInput: CreateUserQuickActionInput
  CurrentBookingUsageResponse: ResolversUnionTypes<ResolversParentTypes>['CurrentBookingUsageResponse']
  CurrentBookingUsageResponseSuccess: CurrentBookingUsageResponseSuccess
  CustomMenuItemUpsell: Omit<CustomMenuItemUpsell, 'targets'> & {
    targets: Array<ResolversParentTypes['RestaurantSet']>
  }
  CustomerBillingCreditMemo: Omit<CustomerBillingCreditMemo, 'appliedTo'> & {
    appliedTo: Array<
      ResolversParentTypes['CustomerBillingCreditMemoApplication']
    >
  }
  CustomerBillingCreditMemoApplication: Omit<
    CustomerBillingCreditMemoApplication,
    'invoice'
  > & { invoice: ResolversParentTypes['CustomerBillingInvoice'] }
  CustomerBillingInvoice: ResolversInterfaceTypes<ResolversParentTypes>['CustomerBillingInvoice']
  CustomerBillingInvoiceDeductions: Omit<
    CustomerBillingInvoiceDeductions,
    'creditMemosApplied'
  > & {
    creditMemosApplied: Array<
      ResolversParentTypes['CustomerBillingCreditMemoApplication']
    >
  }
  CustomerBillingInvoiceItem: ResolversInterfaceTypes<ResolversParentTypes>['CustomerBillingInvoiceItem']
  CustomerBillingInvoiceShipping: CustomerBillingInvoiceShipping
  CustomerBillingInvoiceTotals: CustomerBillingInvoiceTotals
  CustomerBillingInvoicesPaginatedResponse: Omit<
    CustomerBillingInvoicesPaginatedResponse,
    'invoices'
  > & { invoices: Array<ResolversParentTypes['CustomerBillingInvoice']> }
  CustomerBillingPaginatedRequestInput: CustomerBillingPaginatedRequestInput
  CustomerBillingPaginatedResponse: ResolversInterfaceTypes<ResolversParentTypes>['CustomerBillingPaginatedResponse']
  CustomerBillingQuery: Omit<CustomerBillingQuery, 'invoice' | 'invoices'> & {
    invoice?: Maybe<ResolversParentTypes['CustomerBillingInvoice']>
    invoices: ResolversParentTypes['CustomerBillingInvoicesPaginatedResponse']
  }
  CustomerInvoiceDetailsReport: CustomerInvoiceDetailsReport
  CustomerInvoiceDetailsReportCreditMemo: CustomerInvoiceDetailsReportCreditMemo
  CustomerInvoiceDetailsReportServicePeriodItem: CustomerInvoiceDetailsReportServicePeriodItem
  CustomerInvoiceDetailsReportServicePeriodItems: CustomerInvoiceDetailsReportServicePeriodItems
  CustomerInvoiceListReport: CustomerInvoiceListReport
  CustomerInvoiceListReportItem: CustomerInvoiceListReportItem
  CustomerInvoiceListTotalAmounts: CustomerInvoiceListTotalAmounts
  CustomerInvoiceQuery: CustomerInvoiceQuery
  CustomerTasksCreateWorkflowTemplate: CustomerTasksCreateWorkflowTemplate
  CustomerTasksMutation: CustomerTasksMutation
  CustomerTasksQuery: CustomerTasksQuery
  CustomerTasksTask: CustomerTasksTask
  CustomerTasksTaskEvent: CustomerTasksTaskEvent
  CustomerTasksTaskTemplate: CustomerTasksTaskTemplate
  CustomerTasksTaskTimeline: CustomerTasksTaskTimeline
  CustomerTasksWorkflow: CustomerTasksWorkflow
  CustomerTasksWorkflowTemplate: CustomerTasksWorkflowTemplate
  CustomerUserAuthorizationQuery: CustomerUserAuthorizationQuery
  DSModel: DsModel
  DailyMetrics: DailyMetrics
  DailyNetSalesMetric: DailyNetSalesMetric
  DataExtension: ResolversInterfaceTypes<ResolversParentTypes>['DataExtension']
  DataExtensionInput: DataExtensionInput
  DataExtensionMutation: Omit<
    DataExtensionMutation,
    'createDataExtensionSet' | 'updateDataExtensionSet'
  > & {
    createDataExtensionSet: ResolversParentTypes['SavedDataExtensionSet']
    updateDataExtensionSet: ResolversParentTypes['SavedDataExtensionSet']
  }
  DataExtensionQuery: Omit<
    DataExtensionQuery,
    | 'publishedDataExtensionSet'
    | 'resolvedDataExtensionSet'
    | 'savedDataExtensionSet'
    | 'savedDataExtensionSets'
  > & {
    publishedDataExtensionSet: ResolversParentTypes['PublishedDataExtensionSet']
    resolvedDataExtensionSet: ResolversParentTypes['ResolvedDataExtensionSet']
    savedDataExtensionSet: ResolversParentTypes['SavedDataExtensionSet']
    savedDataExtensionSets: Array<ResolversParentTypes['SavedDataExtensionSet']>
  }
  DataExtensionSetOverride: Omit<
    DataExtensionSetOverride,
    'payments' | 'revenueCenters' | 'salesCategories' | 'target' | 'taxRates'
  > & {
    payments?: Maybe<Array<ResolversParentTypes['PaymentDataExtension']>>
    revenueCenters?: Maybe<
      Array<ResolversParentTypes['RevenueCenterDataExtension']>
    >
    salesCategories?: Maybe<
      Array<ResolversParentTypes['SalesCategoryDataExtension']>
    >
    target: ResolversParentTypes['RestaurantSet']
    taxRates?: Maybe<Array<ResolversParentTypes['TaxRateDataExtension']>>
  }
  DataExtensionSetOverrideInput: DataExtensionSetOverrideInput
  Date: Scalars['Date']['output']
  DateRange: DateRange
  DateRangeInput: DateRangeInput
  DateRangeMetrics: DateRangeMetrics
  DateTime: Scalars['DateTime']['output']
  DayOperatingSchedule: DayOperatingSchedule
  DayPeriod: DayPeriod
  DayPeriodInput: DayPeriodInput
  Decimal: Scalars['Decimal']['output']
  DecimalMetric: DecimalMetric
  DeclareCashTipsConfig: DeclareCashTipsConfig
  DeclareCashTipsConfigInput: DeclareCashTipsConfigInput
  DefaultRate: DefaultRate
  DeleteLogbookEntry: DeleteLogbookEntry
  DeleteLogbookEntryInput: DeleteLogbookEntryInput
  DeleteLogbookEntryReply: DeleteLogbookEntryReply
  DeleteLogbookEntryReplyInput: DeleteLogbookEntryReplyInput
  DeleteUserQuickActionResponse: DeleteUserQuickActionResponse
  DeletedEmploymentStatus: DeletedEmploymentStatus
  DeletedShiftResult: ResolversUnionTypes<ResolversParentTypes>['DeletedShiftResult']
  DemoEmploymentStatus: DemoEmploymentStatus
  DepositAmountChanged: DepositAmountChanged
  DepositAvailabilityInfo: Omit<DepositAvailabilityInfo, 'strategy'> & {
    strategy: ResolversParentTypes['DepositStrategyAvailabilityInfo']
  }
  DepositBookableConfigSnapshot: DepositBookableConfigSnapshot
  DepositBookableConfigSnapshotStrategy: DepositBookableConfigSnapshotStrategy
  DepositRequest: DepositRequest
  DepositRuleNotMatching: DepositRuleNotMatching
  DepositStrategy: ResolversUnionTypes<ResolversParentTypes>['DepositStrategy']
  DepositStrategyAvailabilityInfo: ResolversUnionTypes<ResolversParentTypes>['DepositStrategyAvailabilityInfo']
  DepositStrategyAvailabilityInfoByBooking: DepositStrategyAvailabilityInfoByBooking
  DepositStrategyAvailabilityInfoByPartySize: DepositStrategyAvailabilityInfoByPartySize
  DepositStrategyByBooking: DepositStrategyByBooking
  DepositStrategyByPartySize: DepositStrategyByPartySize
  Device: ResolversInterfaceTypes<ResolversParentTypes>['Device']
  DeviceAlert: DeviceAlert
  DeviceConnectivityEvent: DeviceConnectivityEvent
  DeviceConnectivityEventData: DeviceConnectivityEventData
  DeviceDetails: DeviceDetails
  DeviceInfoPublishedPrepStation: DeviceInfoPublishedPrepStation
  DeviceInput: DeviceInput
  DeviceLastSeenInfo: DeviceLastSeenInfo
  DeviceNetworkDetails: DeviceNetworkDetails
  DeviceQueries: Omit<DeviceQueries, 'device' | 'devices'> & {
    device?: Maybe<ResolversParentTypes['Device']>
    devices?: Maybe<Array<ResolversParentTypes['Device']>>
  }
  DeviceSyncDetails: DeviceSyncDetails
  DevicesInput: DevicesInput
  DiningOption: DiningOption
  DiscountAmount: Omit<DiscountAmount, 'grouped'> & {
    grouped?: Maybe<Array<ResolversParentTypes['GroupedDiscountAmount']>>
  }
  DiscountCount: Omit<DiscountCount, 'grouped'> & {
    grouped?: Maybe<Array<ResolversParentTypes['GroupedDiscountCount']>>
  }
  DiscountFilter: DiscountFilter
  DiscountGroupEntity: ResolversUnionTypes<ResolversParentTypes>['DiscountGroupEntity']
  DoorDashMockOnboardingConfiguration: DoorDashMockOnboardingConfiguration
  DoorDashMockOnboardingConfigurationInput: DoorDashMockOnboardingConfigurationInput
  DoorDashNetNewOnboardingRequest: DoorDashNetNewOnboardingRequest
  DoorDashNetNewOnboardingResponse: DoorDashNetNewOnboardingResponse
  DoorDashOnboardingActivateIntegrationResponse: DoorDashOnboardingActivateIntegrationResponse
  DoorDashOnboardingAddress: DoorDashOnboardingAddress
  DoorDashOnboardingIdResponse: DoorDashOnboardingIdResponse
  DoorDashOnboardingMenu: DoorDashOnboardingMenu
  DoorDashOnboardingMenuCandidate: DoorDashOnboardingMenuCandidate
  DoorDashOnboardingMenuInput: DoorDashOnboardingMenuInput
  DoorDashOnboardingStatusResponse: DoorDashOnboardingStatusResponse
  DoorDashOnboardingStoreCandidate: DoorDashOnboardingStoreCandidate
  DoorDashOnboardingStores: DoorDashOnboardingStores
  DoorDashStoreCandidate: DoorDashStoreCandidate
  DoorDashStoreCandidateInput: DoorDashStoreCandidateInput
  DriveThruMutation: Omit<
    DriveThruMutation,
    | 'deleteOrderConfirmationScreenConfigOverrides'
    | 'deleteStockDataConfigOverrides'
    | 'deleteStockOutConfigOverrides'
    | 'saveOrderConfirmationScreenConfigBase'
    | 'saveOrderConfirmationScreenConfigOverrides'
    | 'saveStockDataConfigBase'
    | 'saveStockDataConfigOverrides'
    | 'saveStockOutConfigBase'
    | 'saveStockOutConfigOverrides'
  > & {
    deleteOrderConfirmationScreenConfigOverrides: ResolversParentTypes['SavedOrderConfirmationScreenConfig']
    deleteStockDataConfigOverrides: ResolversParentTypes['SavedStockDataConfig']
    deleteStockOutConfigOverrides: ResolversParentTypes['SavedStockOutConfig']
    saveOrderConfirmationScreenConfigBase: ResolversParentTypes['QLOrderConfirmationScreenConfig']
    saveOrderConfirmationScreenConfigOverrides: ResolversParentTypes['SavedOrderConfirmationScreenConfig']
    saveStockDataConfigBase: ResolversParentTypes['QLStockDataConfig']
    saveStockDataConfigOverrides: ResolversParentTypes['SavedStockDataConfig']
    saveStockOutConfigBase: ResolversParentTypes['QLStockOutConfig']
    saveStockOutConfigOverrides: ResolversParentTypes['SavedStockOutConfig']
  }
  DriveThruQuery: Omit<
    DriveThruQuery,
    | 'resolvedOrderConfirmationScreenConfig'
    | 'resolvedStockDataConfig'
    | 'resolvedStockOutConfig'
    | 'savedOrderConfirmationScreenConfig'
    | 'savedStockDataConfig'
    | 'savedStockOutConfig'
  > & {
    resolvedOrderConfirmationScreenConfig: ResolversParentTypes['QLOrderConfirmationScreenConfig']
    resolvedStockDataConfig: ResolversParentTypes['QLStockDataConfig']
    resolvedStockOutConfig: ResolversParentTypes['QLStockOutConfig']
    savedOrderConfirmationScreenConfig: ResolversParentTypes['SavedOrderConfirmationScreenConfig']
    savedStockDataConfig: ResolversParentTypes['SavedStockDataConfig']
    savedStockOutConfig: ResolversParentTypes['SavedStockOutConfig']
  }
  DsModelHealthStatus: DsModelHealthStatus
  Duration: Scalars['Duration']['output']
  DurationBreakdown: DurationBreakdown
  EPCustomerBillingInvoice: EpCustomerBillingInvoice
  EPCustomerBillingInvoiceDeductions: EpCustomerBillingInvoiceDeductions
  EPCustomerBillingInvoiceItem: EpCustomerBillingInvoiceItem
  EditGroupsAndLocationsInput: EditGroupsAndLocationsInput
  EditRestaurantGroupInfoInput: EditRestaurantGroupInfoInput
  EmailMarketingBlastCampaign: EmailMarketingBlastCampaign
  EmailMarketingCampaign: ResolversInterfaceTypes<ResolversParentTypes>['EmailMarketingCampaign']
  EmailMarketingCampaignFilterInput: EmailMarketingCampaignFilterInput
  EmailMarketingCampaignPaginationInput: EmailMarketingCampaignPaginationInput
  Employee: Omit<Employee, 'payroll'> & {
    payroll?: Maybe<ResolversParentTypes['PayrollEmployeeInfo']>
  }
  EmployeeAddress: EmployeeAddress
  EmployeeConnection: EmployeeConnection
  EmployeeCountry: EmployeeCountry
  EmployeeCursorPaginationInput: EmployeeCursorPaginationInput
  EmployeeDeleted: EmployeeDeleted
  EmployeeEdge: EmployeeEdge
  EmployeeEmail: EmployeeEmail
  EmployeeEmployment: EmployeeEmployment
  EmployeeEmploymentPeriod: EmployeeEmploymentPeriod
  EmployeeEmploymentPeriodInterface: ResolversInterfaceTypes<ResolversParentTypes>['EmployeeEmploymentPeriodInterface']
  EmployeeHistoricalEmploymentPeriod: EmployeeHistoricalEmploymentPeriod
  EmployeeJobAssignment: EmployeeJobAssignment
  EmployeeLocationAssignments: EmployeeLocationAssignments
  EmployeeMissedBreak: EmployeeMissedBreak
  EmployeeName: EmployeeName
  EmployeeOffsetPaginationInput: EmployeeOffsetPaginationInput
  EmployeeOrderByInput: EmployeeOrderByInput
  EmployeeOrderTips: Omit<EmployeeOrderTips, 'order'> & {
    order: ResolversParentTypes['Order']
  }
  EmployeePageInfo: EmployeePageInfo
  EmployeePaginationInput: EmployeePaginationInput
  EmployeePay: EmployeePay
  EmployeePhoneNumber: EmployeePhoneNumber
  EmployeeRepositoryDependencyFailure: EmployeeRepositoryDependencyFailure
  EmployeeSchedule: EmployeeSchedule
  EmployeeScheduleV2: EmployeeScheduleV2
  EmployeeSearchInput: EmployeeSearchInput
  EmployeeShift: ResolversInterfaceTypes<ResolversParentTypes>['EmployeeShift']
  EmployeeShiftConnection: Omit<EmployeeShiftConnection, 'shifts'> & {
    shifts: Array<ResolversParentTypes['EmployeeShift']>
  }
  EmployeeShiftConnectionV2: Omit<EmployeeShiftConnectionV2, 'edges'> & {
    edges: Array<ResolversParentTypes['EmployeeShiftConnectionV2Edge']>
  }
  EmployeeShiftConnectionV2Edge: Omit<EmployeeShiftConnectionV2Edge, 'node'> & {
    node: ResolversParentTypes['EmployeeShiftV2']
  }
  EmployeeShiftTip: EmployeeShiftTip
  EmployeeShiftV2: ResolversInterfaceTypes<ResolversParentTypes>['EmployeeShiftV2']
  EmployeeTakenBreak: ResolversInterfaceTypes<ResolversParentTypes>['EmployeeTakenBreak']
  EmployeeTime: ResolversInterfaceTypes<ResolversParentTypes>['EmployeeTime']
  EmployeeTips: Omit<EmployeeTips, 'orderTips'> & {
    orderTips: Array<ResolversParentTypes['EmployeeOrderTips']>
  }
  EmployeeTipsV2: EmployeeTipsV2
  EmployeeV2: EmployeeV2
  EmploymentStatus: ResolversInterfaceTypes<ResolversParentTypes>['EmploymentStatus']
  EmptyCustomerBillingInvoice: Omit<EmptyCustomerBillingInvoice, 'items'> & {
    items?: Maybe<Array<ResolversParentTypes['CustomerBillingInvoiceItem']>>
  }
  EntitlementsRepositoryDependencyFailure: EntitlementsRepositoryDependencyFailure
  EntityAncestorPath: Omit<EntityAncestorPath, 'ancestors'> & {
    ancestors: Array<ResolversParentTypes['MenuEntity']>
  }
  EntityAncestorResponse: Omit<EntityAncestorResponse, 'paths'> & {
    paths: Array<ResolversParentTypes['EntityAncestorPath']>
  }
  Error: ResolversInterfaceTypes<ResolversParentTypes>['Error']
  ErrorRefundingDeposit: ErrorRefundingDeposit
  ErrorResponse: ErrorResponse
  Errors: ResolversInterfaceTypes<ResolversParentTypes>['Errors']
  ExternalPartner: ExternalPartner
  ExternalPartnerToggle: ExternalPartnerToggle
  FetchCashFlowTransactionsRequest: FetchCashFlowTransactionsRequest
  FetchCashFlowTransactionsResponse: FetchCashFlowTransactionsResponse
  FinancialInsightsMutation: FinancialInsightsMutation
  FinancialInsightsQuery: FinancialInsightsQuery
  FinancialProductForCustomer: FinancialProductForCustomer
  FinishedEmployeeShift: Omit<FinishedEmployeeShift, 'timeClock' | 'tips'> & {
    timeClock?: Maybe<ResolversParentTypes['EmployeeTime']>
    tips?: Maybe<ResolversParentTypes['EmployeeTips']>
  }
  FinishedEmployeeShiftV2: Omit<FinishedEmployeeShiftV2, 'timeClock'> & {
    timeClock?: Maybe<ResolversParentTypes['EmployeeTime']>
  }
  FinishedEmployeeTakenBreak: FinishedEmployeeTakenBreak
  FinishedEmployeeTime: Omit<FinishedEmployeeTime, 'takenBreaks'> & {
    takenBreaks: Array<ResolversParentTypes['EmployeeTakenBreak']>
  }
  FixedFeeLoan: FixedFeeLoan
  FixedFeePQO: FixedFeePqo
  FixedFeePromotionalOffer: FixedFeePromotionalOffer
  FoodWasteConfig: FoodWasteConfig
  FoodWasteConfigInput: FoodWasteConfigInput
  FoodWasteConfigOverride: FoodWasteConfigOverride
  FoodWasteConfigOverrideInput: FoodWasteConfigOverrideInput
  FoodWasteEducationDashboardData: FoodWasteEducationDashboardData
  FoodWasteEstimationInputs: FoodWasteEstimationInputs
  FoodWasteInsights: FoodWasteInsights
  FoodWasteLocalOrganizationDetails: FoodWasteLocalOrganizationDetails
  FoodWasteMutation: FoodWasteMutation
  FoodWasteOpportunity: FoodWasteOpportunity
  FoodWasteQuery: FoodWasteQuery
  FullPublishStartInput: FullPublishStartInput
  FundingPartner: FundingPartner
  GenerateGuestFeedbackResponseInput: GenerateGuestFeedbackResponseInput
  GeneratedGuestFeedbackAIResponse: GeneratedGuestFeedbackAiResponse
  GeoCoordinates: GeoCoordinates
  GetTipsResponse: GetTipsResponse
  GetTrialsResponse: GetTrialsResponse
  GfdAddOnStatus: GfdAddOnStatus
  GfdConfig: Omit<GfdConfig, 'ownerRestaurantSet' | 'targetRestaurantSet'> & {
    ownerRestaurantSet: ResolversParentTypes['RestaurantSet']
    targetRestaurantSet: ResolversParentTypes['RestaurantSet']
  }
  GfdImage: GfdImage
  GfdImageInput: GfdImageInput
  GiftCardMutation: Omit<GiftCardMutation, 'updateReceiptConfig'> & {
    updateReceiptConfig?: Maybe<
      ResolversParentTypes['SavedGiftCardReceiptConfig']
    >
  }
  GiftCardQuery: GiftCardQuery
  GiftCardReceiptConfigUpdate: GiftCardReceiptConfigUpdate
  GlobalNavigationGroup: GlobalNavigationGroup
  GlobalNavigationMenu: GlobalNavigationMenu
  Group: Group
  GroupEntity: ResolversUnionTypes<ResolversParentTypes>['GroupEntity']
  GroupedChecksWithRefundsCount: GroupedChecksWithRefundsCount
  GroupedChecksWithVoidsCount: Omit<GroupedChecksWithVoidsCount, 'entity'> & {
    entity?: Maybe<ResolversParentTypes['VoidGroupEntity']>
  }
  GroupedDiscountAmount: Omit<GroupedDiscountAmount, 'entity'> & {
    entity?: Maybe<ResolversParentTypes['DiscountGroupEntity']>
  }
  GroupedDiscountCount: Omit<GroupedDiscountCount, 'entity'> & {
    entity?: Maybe<ResolversParentTypes['DiscountGroupEntity']>
  }
  GroupedLaborCost: Omit<GroupedLaborCost, 'entity'> & {
    entity?: Maybe<ResolversParentTypes['LaborCostGroupEntity']>
  }
  GroupedNetSales: Omit<GroupedNetSales, 'entity'> & {
    entity?: Maybe<ResolversParentTypes['GroupEntity']>
  }
  GroupedTotalRefundAmount: GroupedTotalRefundAmount
  GroupedVoidAmount: Omit<GroupedVoidAmount, 'entity'> & {
    entity?: Maybe<ResolversParentTypes['VoidGroupEntity']>
  }
  GroupedVoidAmountAsPercentageOfNetSales: Omit<
    GroupedVoidAmountAsPercentageOfNetSales,
    'entity'
  > & { entity?: Maybe<ResolversParentTypes['VoidGroupEntity']> }
  GroupedVoidedItemsCount: Omit<GroupedVoidedItemsCount, 'entity'> & {
    entity?: Maybe<ResolversParentTypes['VoidGroupEntity']>
  }
  GroupsFilterInput: GroupsFilterInput
  Guest: Guest
  GuestAlreadyExists: GuestAlreadyExists
  GuestCount: GuestCount
  GuestCreationResponse: ResolversUnionTypes<ResolversParentTypes>['GuestCreationResponse']
  GuestCreationResponseSuccess: GuestCreationResponseSuccess
  GuestFeedbackAiSummary: GuestFeedbackAiSummary
  GuestFeedbackContactInformation: GuestFeedbackContactInformation
  GuestFeedbackCountByWeek: GuestFeedbackCountByWeek
  GuestFeedbackFilterInput: GuestFeedbackFilterInput
  GuestFeedbackGenerateResponse: ResolversUnionTypes<ResolversParentTypes>['GuestFeedbackGenerateResponse']
  GuestFeedbackGenerateResponseError: GuestFeedbackGenerateResponseError
  GuestFeedbackInfo: Omit<
    GuestFeedbackInfo,
    'check' | 'menuItem' | 'menuItemFeedbacks' | 'order'
  > & {
    check?: Maybe<ResolversParentTypes['Check']>
    menuItem?: Maybe<ResolversParentTypes['MenuItem']>
    menuItemFeedbacks: Array<ResolversParentTypes['MenuItemFeedback']>
    order?: Maybe<ResolversParentTypes['Order']>
  }
  GuestFeedbackMutation: Omit<
    GuestFeedbackMutation,
    'feedbackStatus' | 'generateFeedbackResponse' | 'sendFeedbackResponse'
  > & {
    feedbackStatus: ResolversParentTypes['GuestFeedbackInfo']
    generateFeedbackResponse: ResolversParentTypes['GuestFeedbackGenerateResponse']
    sendFeedbackResponse: ResolversParentTypes['GuestFeedbackInfo']
  }
  GuestFeedbackOrderCheckInfo: GuestFeedbackOrderCheckInfo
  GuestFeedbackPaginationInput: GuestFeedbackPaginationInput
  GuestFeedbackQuery: Omit<
    GuestFeedbackQuery,
    'guestFeedbackDetails' | 'guestFeedbacks' | 'statsSummary'
  > & {
    guestFeedbackDetails?: Maybe<ResolversParentTypes['GuestFeedbackInfo']>
    guestFeedbacks: Array<ResolversParentTypes['GuestFeedbackInfo']>
    statsSummary?: Maybe<
      ResolversParentTypes['GuestFeedbackStatsSummaryResponse']
    >
  }
  GuestFeedbackResponse: GuestFeedbackResponse
  GuestFeedbackSentimentByMenuItem: Omit<
    GuestFeedbackSentimentByMenuItem,
    'menuItem'
  > & { menuItem?: Maybe<ResolversParentTypes['MenuItem']> }
  GuestFeedbackSentimentOverview: GuestFeedbackSentimentOverview
  GuestFeedbackStatsSummaryResponse: Omit<
    GuestFeedbackStatsSummaryResponse,
    'topNegativeMenuItems' | 'topPositiveMenuItems'
  > & {
    topNegativeMenuItems: Array<
      ResolversParentTypes['GuestFeedbackSentimentByMenuItem']
    >
    topPositiveMenuItems: Array<
      ResolversParentTypes['GuestFeedbackSentimentByMenuItem']
    >
  }
  GuestListResponse: ResolversUnionTypes<ResolversParentTypes>['GuestListResponse']
  GuestListResponseSuccess: GuestListResponseSuccess
  GuestNotFound: GuestNotFound
  GuestQuoteTimes: GuestQuoteTimes
  GuestUpdateResponse: ResolversUnionTypes<ResolversParentTypes>['GuestUpdateResponse']
  GuestUpdateResponseSuccess: GuestUpdateResponseSuccess
  HardwareItemOrdered: HardwareItemOrdered
  HardwareOrder: HardwareOrder
  HardwareOrderDeliveryAddress: HardwareOrderDeliveryAddress
  HardwareOrderDeliveryInfo: HardwareOrderDeliveryInfo
  HardwareOrderTrackingInfo: HardwareOrderTrackingInfo
  IRLCustomerBillingInvoice: Omit<IrlCustomerBillingInvoice, 'deductions'> & {
    deductions: ResolversParentTypes['CustomerBillingInvoiceDeductions']
  }
  IRLCustomerBillingInvoiceItem: IrlCustomerBillingInvoiceItem
  Identity: ResolversInterfaceTypes<ResolversParentTypes>['Identity']
  IdentityInterface: ResolversInterfaceTypes<ResolversParentTypes>['IdentityInterface']
  IdmAdminUser: IdmAdminUser
  IdmCustomerUser: IdmCustomerUser
  IdmExternalDeveloperUser: IdmExternalDeveloperUser
  IdmGuestAppMetadata: IdmGuestAppMetadata
  IdmGuestUser: IdmGuestUser
  IdmIdentity: IdmIdentity
  IdmIdentityVerification: IdmIdentityVerification
  IdmName: IdmName
  IdmUserEmailFilter: IdmUserEmailFilter
  IdmUserFilter: IdmUserFilter
  IdmUserIdFilter: IdmUserIdFilter
  IdmUserPhoneFilter: IdmUserPhoneFilter
  IdmUsername: IdmUsername
  IdmUsersQuery: IdmUsersQuery
  ImageUploadInput: ImageUploadInput
  ImplementationEmploymentStatus: ImplementationEmploymentStatus
  InProgressEmployeeShift: Omit<
    InProgressEmployeeShift,
    'timeClock' | 'tips'
  > & {
    timeClock?: Maybe<ResolversParentTypes['EmployeeTime']>
    tips?: Maybe<ResolversParentTypes['EmployeeTips']>
  }
  InProgressEmployeeShiftV2: Omit<InProgressEmployeeShiftV2, 'timeClock'> & {
    timeClock?: Maybe<ResolversParentTypes['EmployeeTime']>
  }
  InProgressEmployeeTakenBreak: InProgressEmployeeTakenBreak
  InProgressEmployeeTime: Omit<InProgressEmployeeTime, 'takenBreaks'> & {
    takenBreaks: Array<ResolversParentTypes['EmployeeTakenBreak']>
  }
  IntegerMetric: IntegerMetric
  IntercomConversation: IntercomConversation
  IntercomConversationPart: IntercomConversationPart
  IntercomConversationPartAuthor: IntercomConversationPartAuthor
  IntercomOptions: IntercomOptions
  IntercomUserHashResult: IntercomUserHashResult
  InternalError: InternalError
  InvalidBreakConfig: InvalidBreakConfig
  InvalidBreaks: InvalidBreaks
  InvalidCustomerUuidError: InvalidCustomerUuidError
  InvalidEmail: InvalidEmail
  InvalidGuest: InvalidGuest
  InvalidIdempotencyKeyError: InvalidIdempotencyKeyError
  InvalidJob: InvalidJob
  InvalidLocation: InvalidLocation
  InvalidPhoneNumber: InvalidPhoneNumber
  InvalidRestaurantIDError: InvalidRestaurantIdError
  InvalidUIOption: Omit<InvalidUiOption, 'smartTax'> & {
    smartTax?: Maybe<ResolversParentTypes['UIOptionInvalidSmartTax']>
  }
  InvalidUpdatedTime: InvalidUpdatedTime
  InvalidUuidError: InvalidUuidError
  ItemFeedbackConfig: ItemFeedbackConfig
  ItemFeedbackConfigAlreadyExists: ItemFeedbackConfigAlreadyExists
  ItemFeedbackConfigError: ItemFeedbackConfigError
  ItemFeedbackConfigInput: ItemFeedbackConfigInput
  ItemFeedbackConfigResponse: ResolversUnionTypes<ResolversParentTypes>['ItemFeedbackConfigResponse']
  ItemMetrics: Omit<ItemMetrics, 'item'> & {
    item: ResolversParentTypes['MenuItem']
  }
  ItemReference: ItemReference
  JSON: Scalars['JSON']['output']
  Job: Job
  JobAssignmentDefaults: JobAssignmentDefaults
  JobAssignmentDefaultsExtendedProperties: JobAssignmentDefaultsExtendedProperties
  JobAssignmentInfo: JobAssignmentInfo
  JobBasedAccessConfig: JobBasedAccessConfig
  JobBasedAccessConfigInput: JobBasedAccessConfigInput
  JobError: JobError
  JobExtendedProperties: JobExtendedProperties
  JobInput: JobInput
  JobMapping: JobMapping
  JobMappings: JobMappings
  JobNotFoundError: JobNotFoundError
  JobRequest: JobRequest
  JobResponse: ResolversUnionTypes<ResolversParentTypes>['JobResponse']
  JobTagAssignment: JobTagAssignment
  JobTagValue: JobTagValue
  JobV2: JobV2
  JobsBatchRequest: JobsBatchRequest
  JobsBatchResponse: ResolversUnionTypes<ResolversParentTypes>['JobsBatchResponse']
  JobsBatchSizeError: JobsBatchSizeError
  JobsFilterInput: JobsFilterInput
  JobsFilterMissingError: JobsFilterMissingError
  JobsLegacyBatchResponse: ResolversUnionTypes<ResolversParentTypes>['JobsLegacyBatchResponse']
  JobsNotFoundBatchError: JobsNotFoundBatchError
  JobsPaginatedResult: JobsPaginatedResult
  JobsPaginationInput: JobsPaginationInput
  JobsRepositoryDependencyFailure: JobsRepositoryDependencyFailure
  JobsResponse: ResolversUnionTypes<ResolversParentTypes>['JobsResponse']
  JobsV2: JobsV2
  KioskConfig: KioskConfig
  KioskConfigServiceCharge: KioskConfigServiceCharge
  KioskCustomerMessageSettings: KioskCustomerMessageSettings
  KioskDiningOption: KioskDiningOption
  KioskLoyalty: KioskLoyalty
  KioskMutation: KioskMutation
  KioskQuery: KioskQuery
  KioskUpsellMenuItem: KioskUpsellMenuItem
  KioskUpsellMenuItemSearchQuery: KioskUpsellMenuItemSearchQuery
  KioskVoidReason: KioskVoidReason
  KitchenCapacityHealthStatus: KitchenCapacityHealthStatus
  LaborCost: Omit<LaborCost, 'grouped'> & {
    grouped: Array<ResolversParentTypes['GroupedLaborCost']>
  }
  LaborCostAsPercentageOfNetSales: LaborCostAsPercentageOfNetSales
  LaborCostGroupEntity: ResolversUnionTypes<ResolversParentTypes>['LaborCostGroupEntity']
  LaborFilter: LaborFilter
  LaborMetrics: Omit<LaborMetrics, 'laborCost'> & {
    laborCost?: Maybe<ResolversParentTypes['LaborCost']>
  }
  LeaveOfAbsenceEmploymentStatus: LeaveOfAbsenceEmploymentStatus
  LegacyCustomerAuthorizationInput: LegacyCustomerAuthorizationInput
  LegacyJobRequest: LegacyJobRequest
  LegacyJobsBatchRequest: LegacyJobsBatchRequest
  LoadShiftsFailure: LoadShiftsFailure
  LoanApplicationForCustomer: LoanApplicationForCustomer
  LocalTime: Scalars['LocalTime']['output']
  LocalTimeRange: LocalTimeRange
  LocalTimeRangeInput: LocalTimeRangeInput
  LocateShiftFailure: LocateShiftFailure
  LocationGroup: LocationGroup
  LocationGroupsInput: LocationGroupsInput
  LocationSetupInformation: LocationSetupInformation
  LocationSpecificPrice: Omit<LocationSpecificPrice, 'pricing'> & {
    pricing?: Maybe<ResolversParentTypes['MenuItemPricing']>
  }
  LogbookCategory: LogbookCategory
  LogbookContent: Omit<LogbookContent, 'contentBlocks' | 'tags'> & {
    contentBlocks: Array<ResolversParentTypes['LogbookContentBlock']>
    tags?: Maybe<Array<ResolversParentTypes['LogbookContentTag']>>
  }
  LogbookContentBlock: ResolversInterfaceTypes<ResolversParentTypes>['LogbookContentBlock']
  LogbookContentBlockInput: LogbookContentBlockInput
  LogbookContentInput: LogbookContentInput
  LogbookContentTag: ResolversInterfaceTypes<ResolversParentTypes>['LogbookContentTag']
  LogbookEntry: Omit<LogbookEntry, 'content' | 'replies' | 'source'> & {
    content: ResolversParentTypes['LogbookContent']
    replies: Array<ResolversParentTypes['LogbookEntryReply']>
    source: ResolversParentTypes['LogbookEntrySource']
  }
  LogbookEntryReply: Omit<LogbookEntryReply, 'content' | 'source'> & {
    content: ResolversParentTypes['LogbookContent']
    source: ResolversParentTypes['LogbookEntrySource']
  }
  LogbookEntrySource: ResolversUnionTypes<ResolversParentTypes>['LogbookEntrySource']
  LogbookMutation: Omit<
    LogbookMutation,
    | 'deleteLogbookEntry'
    | 'deleteLogbookEntryReply'
    | 'submitLogbookEntry'
    | 'submitLogbookEntryReply'
    | 'updateLogbookEntry'
    | 'updateLogbookEntryReply'
  > & {
    deleteLogbookEntry?: Maybe<ResolversParentTypes['LogbookEntry']>
    deleteLogbookEntryReply?: Maybe<ResolversParentTypes['LogbookEntryReply']>
    submitLogbookEntry?: Maybe<ResolversParentTypes['LogbookEntry']>
    submitLogbookEntryReply?: Maybe<ResolversParentTypes['LogbookEntryReply']>
    updateLogbookEntry?: Maybe<ResolversParentTypes['LogbookEntry']>
    updateLogbookEntryReply?: Maybe<ResolversParentTypes['LogbookEntryReply']>
  }
  LogbookQuery: Omit<
    LogbookQuery,
    'fetchLogbookEntriesByBusinessDay' | 'fetchLogbookEntry'
  > & {
    fetchLogbookEntriesByBusinessDay?: Maybe<
      Array<ResolversParentTypes['LogbookEntry']>
    >
    fetchLogbookEntry?: Maybe<ResolversParentTypes['LogbookEntry']>
  }
  LogbookTextContentBlock: LogbookTextContentBlock
  LogbookUserContentBlock: LogbookUserContentBlock
  LogbookUserTag: LogbookUserTag
  Long: Scalars['Long']['output']
  ManagementGroup: ManagementGroup
  ManagementSet: ManagementSet
  ManagerClockOutResult: ResolversUnionTypes<ResolversParentTypes>['ManagerClockOutResult']
  MarkdownText: Scalars['MarkdownText']['output']
  MarketingCampaign: MarketingCampaign
  MarketingCampaignCustomProperties: MarketingCampaignCustomProperties
  MarketingCampaignFilterInput: MarketingCampaignFilterInput
  MarketingCampaignPaginationInput: MarketingCampaignPaginationInput
  MarketingCampaignTrigger: MarketingCampaignTrigger
  MarketingQuery: Omit<MarketingQuery, 'emailCampaign' | 'emailCampaigns'> & {
    emailCampaign: ResolversParentTypes['EmailMarketingCampaign']
    emailCampaigns: Array<ResolversParentTypes['EmailMarketingCampaign']>
  }
  MemberGuestBookData: MemberGuestBookData
  Membership: Membership
  MembershipAddress: MembershipAddress
  MembershipAddressInput: MembershipAddressInput
  MembershipInvoice: MembershipInvoice
  MembershipInvoicesInput: MembershipInvoicesInput
  MembershipInvoicesResponse: MembershipInvoicesResponse
  MembershipTransaction: MembershipTransaction
  MembershipTransactionsInput: MembershipTransactionsInput
  MembershipTransactionsResponse: MembershipTransactionsResponse
  MembershipsFilters: MembershipsFilters
  MembershipsInput: MembershipsInput
  MembershipsMutation: MembershipsMutation
  MembershipsQuery: MembershipsQuery
  MembershipsResponse: MembershipsResponse
  MembershipsSettings: MembershipsSettings
  MembershipsSettingsInput: MembershipsSettingsInput
  MembershipsSettingsResponse: MembershipsSettingsResponse
  MembershipsSummary: MembershipsSummary
  Menu: Omit<
    Menu,
    | 'channelMenuEntities'
    | 'menuGroups'
    | 'ownerRestaurantSet'
    | 'targetRestaurantSet'
  > & {
    channelMenuEntities?: Maybe<
      Array<ResolversParentTypes['ChannelMenuEntity']>
    >
    menuGroups?: Maybe<Array<ResolversParentTypes['MenuGroup']>>
    ownerRestaurantSet?: Maybe<ResolversParentTypes['RestaurantSet']>
    targetRestaurantSet: ResolversParentTypes['RestaurantSet']
  }
  MenuAvailabilitySchedule: MenuAvailabilitySchedule
  MenuAvailabilityTimeRange: MenuAvailabilityTimeRange
  MenuEntitiesInput: MenuEntitiesInput
  MenuEntity: ResolversInterfaceTypes<ResolversParentTypes>['MenuEntity']
  MenuEntityAlcohol: MenuEntityAlcohol
  MenuEntityAlcoholInput: MenuEntityAlcoholInput
  MenuEntityAncestorsQuery: MenuEntityAncestorsQuery
  MenuEntityContentAdvisories: MenuEntityContentAdvisories
  MenuEntityImage: MenuEntityImage
  MenuEntityPointOfSale: MenuEntityPointOfSale
  MenuEntityQuery: MenuEntityQuery
  MenuEntityReorder: MenuEntityReorder
  MenuEntityReports: MenuEntityReports
  MenuEntityTaxes: MenuEntityTaxes
  MenuEntityVisibility: MenuEntityVisibility
  MenuGroup: Omit<
    MenuGroup,
    | 'menuGroups'
    | 'menuItems'
    | 'modifierGroups'
    | 'ownerRestaurantSet'
    | 'targetRestaurantSet'
  > & {
    menuGroups?: Maybe<Array<ResolversParentTypes['MenuGroup']>>
    menuItems: Array<ResolversParentTypes['MenuItem']>
    modifierGroups: Array<ResolversParentTypes['ModifierGroup']>
    ownerRestaurantSet?: Maybe<ResolversParentTypes['RestaurantSet']>
    targetRestaurantSet: ResolversParentTypes['RestaurantSet']
  }
  MenuItem: Omit<
    MenuItem,
    | 'ancestors'
    | 'modifierGroups'
    | 'ownerRestaurantSet'
    | 'pricing'
    | 'targetRestaurantSet'
  > & {
    ancestors: Array<ResolversParentTypes['EntityAncestorResponse']>
    modifierGroups: Array<ResolversParentTypes['ModifierGroup']>
    ownerRestaurantSet?: Maybe<ResolversParentTypes['RestaurantSet']>
    pricing?: Maybe<ResolversParentTypes['MenuItemPricing']>
    targetRestaurantSet: ResolversParentTypes['RestaurantSet']
  }
  MenuItemFeedback: Omit<MenuItemFeedback, 'menuItem'> & {
    menuItem: ResolversParentTypes['MenuItem']
  }
  MenuItemInventoryConnection: Omit<MenuItemInventoryConnection, 'edges'> & {
    edges: Array<ResolversParentTypes['RestaurantMenuItemEdge']>
  }
  MenuItemPlateCost: MenuItemPlateCost
  MenuItemPricing: Omit<
    MenuItemPricing,
    'locationSpecificPrices' | 'priceLevels' | 'sizePrices'
  > & {
    locationSpecificPrices?: Maybe<
      Array<ResolversParentTypes['LocationSpecificPrice']>
    >
    priceLevels?: Maybe<Array<ResolversParentTypes['PriceLevelEntity']>>
    sizePrices?: Maybe<Array<ResolversParentTypes['SizePrice']>>
  }
  MenuItemStockUpdate: MenuItemStockUpdate
  MenuPathsQuery: MenuPathsQuery
  MenuReorder: MenuReorder
  MenuSpecificPrice: Omit<MenuSpecificPrice, 'pricing'> & {
    pricing?: Maybe<ResolversParentTypes['MenuItemPricing']>
  }
  MenuUpsellConfigMutation: Omit<
    MenuUpsellConfigMutation,
    | 'archiveCustomMenuItemUpsell'
    | 'saveCustomMenuItemUpsell'
    | 'saveCustomMenuItemUpsells'
  > & {
    archiveCustomMenuItemUpsell: ResolversParentTypes['CustomMenuItemUpsell']
    saveCustomMenuItemUpsell: ResolversParentTypes['CustomMenuItemUpsell']
    saveCustomMenuItemUpsells: Array<
      ResolversParentTypes['CustomMenuItemUpsell']
    >
  }
  MenuUpsellConfigQuery: Omit<
    MenuUpsellConfigQuery,
    'customMenuItemUpsells'
  > & {
    customMenuItemUpsells: Array<ResolversParentTypes['CustomMenuItemUpsell']>
  }
  MenusAncestorEntityInput: MenusAncestorEntityInput
  MenusAncestorQuery: MenusAncestorQuery
  MenusMoneyInput: MenusMoneyInput
  MgmtDailySummaryConfig: MgmtDailySummaryConfig
  MgmtDepositBookableConfig: Omit<MgmtDepositBookableConfig, 'strategy'> & {
    strategy: ResolversParentTypes['DepositStrategy']
  }
  MgmtEmailConfig: MgmtEmailConfig
  MgmtEmailTemplate: MgmtEmailTemplate
  MgmtRestaurantInformation: Omit<
    MgmtRestaurantInformation,
    'depositBookableConfigs'
  > & {
    depositBookableConfigs: Array<
      ResolversParentTypes['MgmtDepositBookableConfig']
    >
  }
  MgmtRestaurantInformationResponse: ResolversUnionTypes<ResolversParentTypes>['MgmtRestaurantInformationResponse']
  MgmtServiceArea: MgmtServiceArea
  MgmtServiceAreaGroup: MgmtServiceAreaGroup
  MgmtSmsConfig: MgmtSmsConfig
  MgmtSmsTemplate: MgmtSmsTemplate
  MgmtTable: MgmtTable
  MgmtWaitlistAutoRemoveConfig: MgmtWaitlistAutoRemoveConfig
  MinimumRepayment: MinimumRepayment
  MissingJob: MissingJob
  MissingTimeClock: MissingTimeClock
  MlmSavedConfig: ResolversInterfaceTypes<ResolversParentTypes>['MlmSavedConfig']
  MlmSavedConfigVariant: ResolversInterfaceTypes<ResolversParentTypes>['MlmSavedConfigVariant']
  MlmSavedVersion: Omit<MlmSavedVersion, 'owner' | 'target'> & {
    owner: ResolversParentTypes['RestaurantSet']
    target: ResolversParentTypes['RestaurantSet']
  }
  MlmUIOption: Omit<MlmUiOption, 'variants'> & {
    variants: Array<ResolversParentTypes['MlmUIOptionVariant']>
  }
  MlmUIOptionVariant: Omit<
    MlmUiOptionVariant,
    'additionalModifierGroups' | 'versions'
  > & {
    additionalModifierGroups?: Maybe<
      ResolversParentTypes['AdditionalModifierGroups']
    >
    versions: Array<ResolversParentTypes['MlmSavedVersion']>
  }
  MlmUIOptionVariantFilterInput: MlmUiOptionVariantFilterInput
  Modifier: Omit<
    Modifier,
    'itemReference' | 'ownerRestaurantSet' | 'pricing' | 'targetRestaurantSet'
  > & {
    itemReference?: Maybe<ResolversParentTypes['ItemReference']>
    ownerRestaurantSet?: Maybe<ResolversParentTypes['RestaurantSet']>
    pricing?: Maybe<ResolversParentTypes['MenuItemPricing']>
    targetRestaurantSet: ResolversParentTypes['RestaurantSet']
  }
  ModifierGroup: Omit<
    ModifierGroup,
    'modifiers' | 'ownerRestaurantSet' | 'targetRestaurantSet'
  > & {
    modifiers?: Maybe<Array<ResolversParentTypes['Modifier']>>
    ownerRestaurantSet?: Maybe<ResolversParentTypes['RestaurantSet']>
    targetRestaurantSet: ResolversParentTypes['RestaurantSet']
  }
  ModifierGroupPointOfSale: ModifierGroupPointOfSale
  ModifierGroupPricing: ModifierGroupPricing
  ModifierGroupSelections: ModifierGroupSelections
  ModifierMetrics: Omit<ModifierMetrics, 'modifier' | 'parent'> & {
    modifier: ResolversParentTypes['MenuItem']
    parent: ResolversParentTypes['MenuItem']
  }
  Money: Money
  MoneyInput: MoneyInput
  MoneyMetric: MoneyMetric
  MostRecentImportGuidResponse: ResolversUnionTypes<ResolversParentTypes>['MostRecentImportGuidResponse']
  MostRecentImportGuidResponseSuccess: MostRecentImportGuidResponseSuccess
  Mutation: {}
  NavigationCategory: NavigationCategory
  NavigationConfigLocation: NavigationConfigLocation
  NavigationGroup: NavigationGroup
  NavigationHeader: NavigationHeader
  NavigationLink: NavigationLink
  NavigationMenuItem: ResolversUnionTypes<ResolversParentTypes>['NavigationMenuItem']
  NavigationPage: NavigationPage
  NavigationProfileLink: NavigationProfileLink
  NavigationQuery: Omit<NavigationQuery, 'adminMenu'> & {
    adminMenu: Array<ResolversParentTypes['NavigationMenuItem']>
  }
  NavigationQuickAction: NavigationQuickAction
  NavigationSection: NavigationSection
  NavigationTask: NavigationTask
  NavigationTaskAction: NavigationTaskAction
  NetSales: Omit<NetSales, 'grouped'> & {
    grouped: Array<ResolversParentTypes['GroupedNetSales']>
  }
  NetSalesDateRangeMetrics: NetSalesDateRangeMetrics
  NetSalesPerLaborHour: NetSalesPerLaborHour
  NetworkDevice: NetworkDevice
  NetworkDeviceInput: NetworkDeviceInput
  NetworkDevicesInput: NetworkDevicesInput
  NetworkQueries: Omit<NetworkQueries, 'getStatus'> & {
    getStatus: ResolversParentTypes['NetworkStatusResponse']
  }
  NetworkStatusData: NetworkStatusData
  NetworkStatusError: NetworkStatusError
  NetworkStatusInput: NetworkStatusInput
  NetworkStatusResponse: ResolversUnionTypes<ResolversParentTypes>['NetworkStatusResponse']
  NeverEmployedEmploymentStatus: NeverEmployedEmploymentStatus
  NoJobIdsProvidedBatchError: NoJobIdsProvidedBatchError
  NoPreviousImports: NoPreviousImports
  NotificationContact: NotificationContact
  NotificationContactInput: NotificationContactInput
  OnboardingAction: ResolversInterfaceTypes<ResolversParentTypes>['OnboardingAction']
  OnboardingDefaultAction: OnboardingDefaultAction
  OnboardingDefaultInfo: Omit<OnboardingDefaultInfo, 'action'> & {
    action?: Maybe<ResolversParentTypes['OnboardingAction']>
  }
  OnboardingDefaultItem: Omit<OnboardingDefaultItem, 'action'> & {
    action?: Maybe<ResolversParentTypes['OnboardingStepAction']>
  }
  OnboardingDefaultItemMetadataV2: OnboardingDefaultItemMetadataV2
  OnboardingDefaultItemV2: Omit<
    OnboardingDefaultItemV2,
    'action' | 'metadata'
  > & {
    action?: Maybe<ResolversParentTypes['OnboardingAction']>
    metadata?: Maybe<ResolversParentTypes['OnboardingItemMetadataV2']>
  }
  OnboardingDefaultStepDetails: Omit<
    OnboardingDefaultStepDetails,
    'actions' | 'info'
  > & {
    actions: Array<Maybe<ResolversParentTypes['OnboardingAction']>>
    info: Array<Maybe<ResolversParentTypes['OnboardingStepInfo']>>
  }
  OnboardingDefaultStepInfo: Omit<OnboardingDefaultStepInfo, 'action'> & {
    action?: Maybe<ResolversParentTypes['OnboardingAction']>
  }
  OnboardingImplementationStep: Omit<
    OnboardingImplementationStep,
    'details' | 'items'
  > & {
    details?: Maybe<ResolversParentTypes['OnboardingSetupStepDetails']>
    items?: Maybe<Array<ResolversParentTypes['OnboardingItem']>>
  }
  OnboardingImplementationStepV2: Omit<
    OnboardingImplementationStepV2,
    'items'
  > & { items: Array<ResolversParentTypes['OnboardingItemV2']> }
  OnboardingInfo: ResolversInterfaceTypes<ResolversParentTypes>['OnboardingInfo']
  OnboardingInfoStep: Omit<OnboardingInfoStep, 'details'> & {
    details?: Maybe<ResolversParentTypes['OnboardingSetupStepDetails']>
  }
  OnboardingItem: ResolversInterfaceTypes<ResolversParentTypes>['OnboardingItem']
  OnboardingItemMetadata: OnboardingItemMetadata
  OnboardingItemMetadataV2: ResolversInterfaceTypes<ResolversParentTypes>['OnboardingItemMetadataV2']
  OnboardingItemV2: ResolversInterfaceTypes<ResolversParentTypes>['OnboardingItemV2']
  OnboardingLegacyDynamicLinkAction: OnboardingLegacyDynamicLinkAction
  OnboardingLegacyItem: Omit<OnboardingLegacyItem, 'action'> & {
    action?: Maybe<ResolversParentTypes['OnboardingStepAction']>
  }
  OnboardingLegacyItemDetails: Omit<
    OnboardingLegacyItemDetails,
    'legacyItems'
  > & { legacyItems: Array<ResolversParentTypes['OnboardingLegacyItem']> }
  OnboardingLegacyStatusBadge: OnboardingLegacyStatusBadge
  OnboardingLinkAction: OnboardingLinkAction
  OnboardingMeetingItem: Omit<OnboardingMeetingItem, 'action' | 'metadata'> & {
    action?: Maybe<ResolversParentTypes['OnboardingAction']>
    metadata?: Maybe<ResolversParentTypes['OnboardingMeetingItemMetadata']>
  }
  OnboardingMeetingItemMetadata: Omit<
    OnboardingMeetingItemMetadata,
    'actionSheet' | 'agenda' | 'preparationSteps'
  > & {
    actionSheet: Array<ResolversParentTypes['OnboardingInfo']>
    agenda: Array<ResolversParentTypes['OnboardingInfo']>
    preparationSteps: Array<ResolversParentTypes['OnboardingInfo']>
  }
  OnboardingMeetingStep: Omit<OnboardingMeetingStep, 'details'> & {
    details?: Maybe<ResolversParentTypes['OnboardingMeetingStepDetails']>
  }
  OnboardingMeetingStepDetails: Omit<
    OnboardingMeetingStepDetails,
    'actions' | 'info'
  > & {
    actions: Array<Maybe<ResolversParentTypes['OnboardingAction']>>
    info: Array<Maybe<ResolversParentTypes['OnboardingStepInfo']>>
  }
  OnboardingMutation: OnboardingMutation
  OnboardingNavigationAction: OnboardingNavigationAction
  OnboardingQuery: Omit<
    OnboardingQuery,
    'locationSetupSteps' | 'locationSetupStepsV2' | 'onboardingSteps'
  > & {
    locationSetupSteps?: Maybe<
      Array<ResolversParentTypes['OnboardingSetupStep']>
    >
    locationSetupStepsV2?: Maybe<
      Array<ResolversParentTypes['OnboardingSetupStepV2']>
    >
    onboardingSteps?: Maybe<Array<ResolversParentTypes['OnboardingStep']>>
  }
  OnboardingScheduleAppointmentLinkAction: OnboardingScheduleAppointmentLinkAction
  OnboardingSetupStep: ResolversInterfaceTypes<ResolversParentTypes>['OnboardingSetupStep']
  OnboardingSetupStepDetails: ResolversInterfaceTypes<ResolversParentTypes>['OnboardingSetupStepDetails']
  OnboardingSetupStepV2: ResolversInterfaceTypes<ResolversParentTypes>['OnboardingSetupStepV2']
  OnboardingShareAction: OnboardingShareAction
  OnboardingStep: Omit<OnboardingStep, 'details' | 'itemDetails'> & {
    details?: Maybe<ResolversParentTypes['OnboardingStepDetails']>
    itemDetails?: Maybe<ResolversParentTypes['OnboardingLegacyItemDetails']>
  }
  OnboardingStepAction: ResolversUnionTypes<ResolversParentTypes>['OnboardingStepAction']
  OnboardingStepDetails: Omit<OnboardingStepDetails, 'action' | 'substeps'> & {
    action?: Maybe<ResolversParentTypes['OnboardingStepAction']>
    substeps: Array<ResolversParentTypes['OnboardingStep']>
  }
  OnboardingStepInfo: ResolversInterfaceTypes<ResolversParentTypes>['OnboardingStepInfo']
  OnboardingStepsFilter: OnboardingStepsFilter
  OnboardingStepsFilterV2: OnboardingStepsFilterV2
  OnlineOrderingStatusLabel: OnlineOrderingStatusLabel
  OpenCashDrawerReason: OpenCashDrawerReason
  OperatingSchedule: OperatingSchedule
  OperatingScheduleService: OperatingScheduleService
  OperatingScheduleServicePeriod: OperatingScheduleServicePeriod
  OptMenuConfig: OptMenuConfig
  OptMenuConfigInput: OptMenuConfigInput
  OptMenuConfigOverride: Omit<OptMenuConfigOverride, 'target'> & {
    target: ResolversParentTypes['RestaurantSet']
  }
  OptMenuConfigOverrideInput: OptMenuConfigOverrideInput
  OptMutation: Omit<
    OptMutation,
    | 'deleteOptMenuConfigOverrides'
    | 'deleteOptOrderingConfigOverrides'
    | 'deleteOptPaymentConfigOverrides'
    | 'deleteOptSpotlightConfigOverrides'
    | 'saveOptMenuConfigBase'
    | 'saveOptMenuConfigOverrides'
    | 'saveOptOrderingConfigBase'
    | 'saveOptOrderingConfigOverrides'
    | 'saveOptPaymentConfigBase'
    | 'saveOptPaymentConfigOverrides'
    | 'saveOptSpotlightConfigBase'
    | 'saveOptSpotlightConfigOverrides'
  > & {
    deleteOptMenuConfigOverrides: ResolversParentTypes['OptSavedMenuConfig']
    deleteOptOrderingConfigOverrides: ResolversParentTypes['OptSavedOrderingConfig']
    deleteOptPaymentConfigOverrides: ResolversParentTypes['OptSavedPaymentConfig']
    deleteOptSpotlightConfigOverrides: ResolversParentTypes['OptSavedSpotlightConfig']
    saveOptMenuConfigBase: ResolversParentTypes['OptMenuConfig']
    saveOptMenuConfigOverrides: ResolversParentTypes['OptSavedMenuConfig']
    saveOptOrderingConfigBase: ResolversParentTypes['OptOrderingConfig']
    saveOptOrderingConfigOverrides: ResolversParentTypes['OptSavedOrderingConfig']
    saveOptPaymentConfigBase: ResolversParentTypes['OptPaymentConfig']
    saveOptPaymentConfigOverrides: ResolversParentTypes['OptSavedPaymentConfig']
    saveOptSpotlightConfigBase: ResolversParentTypes['OptSpotlightConfig']
    saveOptSpotlightConfigOverrides: ResolversParentTypes['OptSavedSpotlightConfig']
  }
  OptOrderingConfig: OptOrderingConfig
  OptOrderingConfigInput: OptOrderingConfigInput
  OptOrderingConfigOverride: Omit<OptOrderingConfigOverride, 'target'> & {
    target: ResolversParentTypes['RestaurantSet']
  }
  OptOrderingConfigOverrideInput: OptOrderingConfigOverrideInput
  OptPaymentConfig: OptPaymentConfig
  OptPaymentConfigInput: OptPaymentConfigInput
  OptPaymentConfigOverride: Omit<OptPaymentConfigOverride, 'target'> & {
    target: ResolversParentTypes['RestaurantSet']
  }
  OptPaymentConfigOverrideInput: OptPaymentConfigOverrideInput
  OptQuery: Omit<
    OptQuery,
    | 'resolvedOptMenuConfig'
    | 'resolvedOptOrderingConfig'
    | 'resolvedOptPaymentConfig'
    | 'resolvedOptSpotlightConfig'
    | 'savedOptMenuConfig'
    | 'savedOptOrderingConfig'
    | 'savedOptPaymentConfig'
    | 'savedOptSpotlightConfig'
  > & {
    resolvedOptMenuConfig: ResolversParentTypes['OptMenuConfig']
    resolvedOptOrderingConfig: ResolversParentTypes['OptOrderingConfig']
    resolvedOptPaymentConfig: ResolversParentTypes['OptPaymentConfig']
    resolvedOptSpotlightConfig: ResolversParentTypes['OptSpotlightConfig']
    savedOptMenuConfig: ResolversParentTypes['OptSavedMenuConfig']
    savedOptOrderingConfig: ResolversParentTypes['OptSavedOrderingConfig']
    savedOptPaymentConfig: ResolversParentTypes['OptSavedPaymentConfig']
    savedOptSpotlightConfig: ResolversParentTypes['OptSavedSpotlightConfig']
  }
  OptSavedMenuConfig: Omit<OptSavedMenuConfig, 'baseConfig' | 'overrides'> & {
    baseConfig: ResolversParentTypes['OptMenuConfig']
    overrides?: Maybe<Array<ResolversParentTypes['OptMenuConfigOverride']>>
  }
  OptSavedOrderingConfig: Omit<
    OptSavedOrderingConfig,
    'baseConfig' | 'overrides'
  > & {
    baseConfig: ResolversParentTypes['OptOrderingConfig']
    overrides?: Maybe<Array<ResolversParentTypes['OptOrderingConfigOverride']>>
  }
  OptSavedPaymentConfig: Omit<
    OptSavedPaymentConfig,
    'baseConfig' | 'overrides'
  > & {
    baseConfig: ResolversParentTypes['OptPaymentConfig']
    overrides?: Maybe<Array<ResolversParentTypes['OptPaymentConfigOverride']>>
  }
  OptSavedSpotlightConfig: Omit<
    OptSavedSpotlightConfig,
    'baseConfig' | 'overrides'
  > & {
    baseConfig: ResolversParentTypes['OptSpotlightConfig']
    overrides?: Maybe<Array<ResolversParentTypes['OptSpotlightConfigOverride']>>
  }
  OptSpotlightConfig: OptSpotlightConfig
  OptSpotlightConfigInput: OptSpotlightConfigInput
  OptSpotlightConfigOverride: Omit<OptSpotlightConfigOverride, 'target'> & {
    target: ResolversParentTypes['RestaurantSet']
  }
  OptSpotlightConfigOverrideInput: OptSpotlightConfigOverrideInput
  OptionalFloat: OptionalFloat
  OptionalID: OptionalId
  OptionalInt: OptionalInt
  Order: Omit<Order, 'channel' | 'checks' | 'diningOption'> & {
    channel?: Maybe<ResolversParentTypes['PublishedChannel']>
    checks: Array<ResolversParentTypes['Check']>
    diningOption?: Maybe<ResolversParentTypes['SavedDiningOption']>
  }
  OrderConfirmationScreenConfigInput: OrderConfirmationScreenConfigInput
  OrderConfirmationScreenConfigOverride: Omit<
    OrderConfirmationScreenConfigOverride,
    'target'
  > & { target: ResolversParentTypes['RestaurantSet'] }
  OrderConfirmationScreenConfigOverrideInput: OrderConfirmationScreenConfigOverrideInput
  OrderCount: OrderCount
  OrderMetrics: Omit<
    OrderMetrics,
    | 'checksWithVoidsCount'
    | 'discountAmount'
    | 'discountCount'
    | 'netSales'
    | 'voidAmount'
    | 'voidAmountAsPercentageOfNetSales'
    | 'voidedItemsCount'
  > & {
    checksWithVoidsCount?: Maybe<ResolversParentTypes['ChecksWithVoidsCount']>
    discountAmount?: Maybe<ResolversParentTypes['DiscountAmount']>
    discountCount?: Maybe<ResolversParentTypes['DiscountCount']>
    netSales?: Maybe<ResolversParentTypes['NetSales']>
    voidAmount?: Maybe<ResolversParentTypes['VoidAmount']>
    voidAmountAsPercentageOfNetSales?: Maybe<
      ResolversParentTypes['VoidAmountAsPercentageOfNetSales']
    >
    voidedItemsCount?: Maybe<ResolversParentTypes['VoidedItemsCount']>
  }
  OrderValidityCheck: OrderValidityCheck
  OrderValidityCheckResponse: OrderValidityCheckResponse
  OrderingSchedule: OrderingSchedule
  OrderingScheduleInput: OrderingScheduleInput
  OrdersAppliedDiscount: OrdersAppliedDiscount
  OrdersAppliedLoyaltyInfo: OrdersAppliedLoyaltyInfo
  OrdersAppliedPreauthInfo: OrdersAppliedPreauthInfo
  OrdersConfigMutation: Omit<OrdersConfigMutation, 'updateDiningOption'> & {
    updateDiningOption: ResolversParentTypes['SavedDiningOption']
  }
  OrdersConfigQuery: Omit<
    OrdersConfigQuery,
    'savedDiningOption' | 'savedDiningOptions' | 'savedRevenueCenters'
  > & {
    savedDiningOption?: Maybe<ResolversParentTypes['SavedDiningOption']>
    savedDiningOptions: Array<ResolversParentTypes['SavedDiningOption']>
    savedRevenueCenters: Array<ResolversParentTypes['SavedRevenueCenter']>
  }
  OrdersCurbsidePickupInfo: OrdersCurbsidePickupInfo
  OrdersGiftCardInfo: OrdersGiftCardInfo
  OrdersMutation: Omit<OrdersMutation, 'updateTipAmount'> & {
    updateTipAmount?: Maybe<ResolversParentTypes['Order']>
  }
  OrdersPayment: OrdersPayment
  OrdersQuery: Omit<OrdersQuery, 'check' | 'ordersSummary'> & {
    check?: Maybe<ResolversParentTypes['Check']>
    ordersSummary?: Maybe<ResolversParentTypes['OrdersSummary']>
  }
  OrdersRefund: OrdersRefund
  OrdersRefundDetails: OrdersRefundDetails
  OrdersSelection: Omit<OrdersSelection, 'diningOption' | 'modifiers'> & {
    diningOption?: Maybe<ResolversParentTypes['SavedDiningOption']>
    modifiers: Array<ResolversParentTypes['OrdersSelection']>
  }
  OrdersSummary: Omit<OrdersSummary, 'orders'> & {
    orders?: Maybe<Array<ResolversParentTypes['Order']>>
  }
  OutdatedPackagingConfigError: Omit<
    OutdatedPackagingConfigError,
    'currentConfig'
  > & { currentConfig: ResolversParentTypes['SavedPackagingConfig'] }
  OverlappingBreaks: OverlappingBreaks
  OverlappingShiftExists: OverlappingShiftExists
  OverrideDateRange: OverrideDateRange
  OverridePricing: OverridePricing
  Overrides: Overrides
  OverridesDateRangeInput: OverridesDateRangeInput
  OverridesInput: OverridesInput
  PackagingComplianceData: PackagingComplianceData
  PackagingComplianceDetails: PackagingComplianceDetails
  PackagingComplianceError: PackagingComplianceError
  PackagingComplianceLocationError: PackagingComplianceLocationError
  PackagingComplianceRegulationError: PackagingComplianceRegulationError
  PackagingComplianceSummary: PackagingComplianceSummary
  PackagingConfigOverride: Omit<
    PackagingConfigOverride,
    'items' | 'owner' | 'target'
  > & {
    items: Array<ResolversParentTypes['PackagingItem']>
    owner: ResolversParentTypes['RestaurantSet']
    target: ResolversParentTypes['RestaurantSet']
  }
  PackagingConfigOverrideUpdate: PackagingConfigOverrideUpdate
  PackagingConfigUpdate: PackagingConfigUpdate
  PackagingItem: PackagingItem
  PackagingItemUpdate: PackagingItemUpdate
  PackagingLocationCompliance: PackagingLocationCompliance
  PackagingLocationRegulationCompliance: PackagingLocationRegulationCompliance
  PackagingMutation: Omit<PackagingMutation, 'saveConfig'> & {
    saveConfig: ResolversParentTypes['SavePackagingConfigResponse']
  }
  PackagingQuery: Omit<PackagingQuery, 'config'> & {
    config: ResolversParentTypes['SavedPackagingConfig']
  }
  PackagingRegulation: PackagingRegulation
  PackagingRestrictions: PackagingRestrictions
  PackagingUpdateToastInternalOptions: PackagingUpdateToastInternalOptions
  PageInfo: PageInfo
  PagedMenuItemResponse: Omit<PagedMenuItemResponse, 'entities'> & {
    entities: Array<ResolversParentTypes['MenuItem']>
  }
  PagedModifierGroupsResponse: Omit<PagedModifierGroupsResponse, 'entities'> & {
    entities: Array<ResolversParentTypes['ModifierGroup']>
  }
  PagedModifierResponse: Omit<PagedModifierResponse, 'entities'> & {
    entities: Array<ResolversParentTypes['Modifier']>
  }
  PagedPriceLevelResponse: Omit<PagedPriceLevelResponse, 'entities'> & {
    entities: Array<ResolversParentTypes['PriceLevelEntity']>
  }
  PagedRenderedAuthorizationDecision: PagedRenderedAuthorizationDecision
  PagedSavedChannel: Omit<PagedSavedChannel, 'results'> & {
    results: Array<ResolversParentTypes['SavedChannel']>
  }
  PagedSavedChannelAvailability: Omit<
    PagedSavedChannelAvailability,
    'results'
  > & { results: Array<ResolversParentTypes['SavedChannelAvailability']> }
  PagedSavedMenuItemTagsResponse: PagedSavedMenuItemTagsResponse
  PaginationInput: PaginationInput
  ParentResponse: ParentResponse
  Partner: Partner
  PartnerApiAccess: PartnerApiAccess
  PartnerCategory: PartnerCategory
  PartnerChannel: Omit<PartnerChannel, 'channel'> & {
    channel?: Maybe<ResolversParentTypes['SavedChannel']>
  }
  PartnerChannelConfig: Omit<PartnerChannelConfig, 'partnerChannels'> & {
    partnerChannels: Array<ResolversParentTypes['PartnerChannel']>
  }
  PartnerChannelInput: PartnerChannelInput
  PartnerChannelsInput: PartnerChannelsInput
  PartnerQuery: PartnerQuery
  PartnerScope: PartnerScope
  PartnerStatusLabel: PartnerStatusLabel
  PaymentDataExtension: ResolversUnionTypes<ResolversParentTypes>['PaymentDataExtension']
  PaymentDataExtensionInput: PaymentDataExtensionInput
  PaymentsConfigMutation: Omit<
    PaymentsConfigMutation,
    'createGfdConfig' | 'updateGfdConfigs'
  > & {
    createGfdConfig: ResolversParentTypes['GfdConfig']
    updateGfdConfigs: Array<ResolversParentTypes['GfdConfig']>
  }
  PaymentsConfigQuery: Omit<
    PaymentsConfigQuery,
    'alternatePaymentTypes' | 'gfdConfig' | 'gfdConfigByEntityId' | 'gfdConfigs'
  > & {
    alternatePaymentTypes: Array<
      ResolversParentTypes['SavedAlternatePaymentType']
    >
    gfdConfig?: Maybe<ResolversParentTypes['GfdConfig']>
    gfdConfigByEntityId?: Maybe<ResolversParentTypes['GfdConfig']>
    gfdConfigs: Array<ResolversParentTypes['GfdConfig']>
  }
  PayrollEmployeeInfo: Omit<PayrollEmployeeInfo, 'employmentStatus'> & {
    employmentStatus: ResolversParentTypes['AllEmploymentStatuses']
  }
  PendingEmploymentStatus: PendingEmploymentStatus
  PercentageMetric: PercentageMetric
  PermissionBit: Scalars['PermissionBit']['output']
  Permissions: Permissions
  PlaceThirdPartyMockOrderResponse: PlaceThirdPartyMockOrderResponse
  PnLAISummary: PnLaiSummary
  PnLLineItemMetrics: PnLLineItemMetrics
  PnLNetProfit: PnLNetProfit
  PnLReport: PnLReport
  PnLReportRequest: PnLReportRequest
  PnLSubCategory: PnLSubCategory
  PnLSubCategoryDetail: PnLSubCategoryDetail
  PnLSubProfit: PnLSubProfit
  PnLTopLevel: PnLTopLevel
  PopularSearch: PopularSearch
  PopularSearchResponse: PopularSearchResponse
  PosEmployeeInfo: PosEmployeeInfo
  PrequalifiedOfferForCustomer: PrequalifiedOfferForCustomer
  PrequalifiedOfferMetadata: PrequalifiedOfferMetadata
  PriceLevel: PriceLevel
  PriceLevelEntity: Omit<
    PriceLevelEntity,
    'ownerRestaurantSet' | 'pricing' | 'targetRestaurantSet'
  > & {
    ownerRestaurantSet?: Maybe<ResolversParentTypes['RestaurantSet']>
    pricing?: Maybe<ResolversParentTypes['MenuItemPricing']>
    targetRestaurantSet: ResolversParentTypes['RestaurantSet']
  }
  Printer: Printer
  ProcessLegacyJob: ProcessLegacyJob
  ProcessLegacyJobRequest: ProcessLegacyJobRequest
  ProcessLegacyJobResponse: ResolversUnionTypes<ResolversParentTypes>['ProcessLegacyJobResponse']
  ProductSubscription: ProductSubscription
  ProductSubscriptionBundle: ProductSubscriptionBundle
  ProductSubscriptionInterface: ResolversInterfaceTypes<ResolversParentTypes>['ProductSubscriptionInterface']
  ProductSubscriptionSalesforceProduct: ProductSubscriptionSalesforceProduct
  ProductSubscriptionsMutation: ProductSubscriptionsMutation
  ProductSubscriptionsQuery: Omit<ProductSubscriptionsQuery, 'list'> & {
    list: Array<ResolversParentTypes['ProductSubscriptionInterface']>
  }
  PublishJob: Omit<PublishJob, 'entities'> & {
    entities: ResolversParentTypes['PublishedConfigConnection']
  }
  PublishJobConnection: PublishJobConnection
  PublishJobEdge: PublishJobEdge
  PublishJobEntityInfo: PublishJobEntityInfo
  PublishJobsFilterInput: PublishJobsFilterInput
  PublishMutation: PublishMutation
  PublishQuery: Omit<PublishQuery, 'publishedConfig'> & {
    publishedConfig: ResolversParentTypes['PublishedConfig']
  }
  PublishRepositoryDependencyFailure: PublishRepositoryDependencyFailure
  PublishRestaurantStatus: PublishRestaurantStatus
  PublishStatus: PublishStatus
  PublishedChannel: Omit<
    PublishedChannel,
    'channelAvailability' | 'channelType'
  > & {
    channelAvailability?: Maybe<
      ResolversParentTypes['PublishedChannelAvailability']
    >
    channelType?: Maybe<ResolversParentTypes['ChannelType']>
  }
  PublishedChannelAvailability: PublishedChannelAvailability
  PublishedConfig: Omit<PublishedConfig, 'targets'> & {
    targets: Array<ResolversParentTypes['RestaurantSet']>
  }
  PublishedConfigConnection: Omit<PublishedConfigConnection, 'edges'> & {
    edges: Array<ResolversParentTypes['PublishedConfigEdge']>
  }
  PublishedConfigDiff: PublishedConfigDiff
  PublishedConfigEdge: Omit<PublishedConfigEdge, 'node'> & {
    node: ResolversParentTypes['PublishedConfig']
  }
  PublishedConfigEntityI: ResolversInterfaceTypes<ResolversParentTypes>['PublishedConfigEntityI']
  PublishedConfigFieldDiff: PublishedConfigFieldDiff
  PublishedDataExtensionSet: Omit<
    PublishedDataExtensionSet,
    'payments' | 'revenueCenters' | 'salesCategories' | 'taxRates'
  > & {
    payments: Array<ResolversParentTypes['PaymentDataExtension']>
    revenueCenters: Array<ResolversParentTypes['RevenueCenterDataExtension']>
    salesCategories: Array<ResolversParentTypes['SalesCategoryDataExtension']>
    taxRates: Array<ResolversParentTypes['TaxRateDataExtension']>
  }
  PublishedGiftCardReceiptConfig: PublishedGiftCardReceiptConfig
  PublishedItemFeedbackConfig: PublishedItemFeedbackConfig
  PublishedItemFeedbackConfigError: PublishedItemFeedbackConfigError
  PublishedItemFeedbackConfigResponse: ResolversUnionTypes<ResolversParentTypes>['PublishedItemFeedbackConfigResponse']
  PublishedMenu: PublishedMenu
  PublishedMenuEntity: ResolversInterfaceTypes<ResolversParentTypes>['PublishedMenuEntity']
  PublishedMenuGroup: PublishedMenuGroup
  PublishedMenuItem: Omit<PublishedMenuItem, 'ancestors'> & {
    ancestors: Array<ResolversParentTypes['PublishedMenuItemAncestor']>
  }
  PublishedMenuItemAncestor: Omit<PublishedMenuItemAncestor, 'paths'> & {
    paths: ResolversParentTypes['PublishedMenuItemAncestorPath']
  }
  PublishedMenuItemAncestorPath: Omit<
    PublishedMenuItemAncestorPath,
    'ancestors'
  > & { ancestors: Array<ResolversParentTypes['PublishedMenuEntity']> }
  PublishedPrinterConfig: PublishedPrinterConfig
  PublishedQueries: PublishedQueries
  PublishedRestaurantLocation: PublishedRestaurantLocation
  PublishedToastPayConfigError: PublishedToastPayConfigError
  PublishedToastPayConfigResponse: ResolversUnionTypes<ResolversParentTypes>['PublishedToastPayConfigResponse']
  QLOrderConfirmationScreenConfig: QlOrderConfirmationScreenConfig
  QLStockDataConfig: QlStockDataConfig
  QLStockOutConfig: QlStockOutConfig
  Query: {}
  QuickAction: QuickAction
  QuoteTime: QuoteTime
  QuoteTimeInput: QuoteTimeInput
  ReadFailure: ReadFailure
  RecentSearchesResponse: RecentSearchesResponse
  ReconciliationConfig: ReconciliationConfig
  ReconciliationConfigInput: ReconciliationConfigInput
  RehiredEmploymentStatus: RehiredEmploymentStatus
  RemoveMenuGroupInput: RemoveMenuGroupInput
  RemoveMenuItemInput: RemoveMenuItemInput
  RenderedAuthorizationDecision: RenderedAuthorizationDecision
  RepaymentMilestone: RepaymentMilestone
  ReportSection: ReportSection
  ReportSectionInput: ReportSectionInput
  ResolvedConfigEntityI: ResolversInterfaceTypes<ResolversParentTypes>['ResolvedConfigEntityI']
  ResolvedDataExtensionSet: Omit<
    ResolvedDataExtensionSet,
    'payments' | 'revenueCenters' | 'salesCategories' | 'target' | 'taxRates'
  > & {
    payments: Array<ResolversParentTypes['PaymentDataExtension']>
    revenueCenters: Array<ResolversParentTypes['RevenueCenterDataExtension']>
    salesCategories: Array<ResolversParentTypes['SalesCategoryDataExtension']>
    target?: Maybe<ResolversParentTypes['RestaurantSet']>
    taxRates: Array<ResolversParentTypes['TaxRateDataExtension']>
  }
  Response: Response
  Restaurant: Restaurant
  RestaurantAccess: Omit<RestaurantAccess, 'restaurantLocation'> & {
    restaurantLocation: ResolversParentTypes['AccessibleRestaurantLocation']
  }
  RestaurantAvailabilityInfo: Omit<RestaurantAvailabilityInfo, 'slots'> & {
    slots: Array<ResolversParentTypes['SlotAvailabilityInfo']>
  }
  RestaurantBookingLimit: RestaurantBookingLimit
  RestaurantBreakConfig: RestaurantBreakConfig
  RestaurantGroup: RestaurantGroup
  RestaurantGroupOrLocation: ResolversUnionTypes<ResolversParentTypes>['RestaurantGroupOrLocation']
  RestaurantInformationResponseSuccess: Omit<
    RestaurantInformationResponseSuccess,
    'response'
  > & { response: ResolversParentTypes['MgmtRestaurantInformation'] }
  RestaurantInput: RestaurantInput
  RestaurantLeafDataJobInput: RestaurantLeafDataJobInput
  RestaurantLeafDataJobLastEvaluatedKey: RestaurantLeafDataJobLastEvaluatedKey
  RestaurantLeafDataJobLastEvaluatedKeyInput: RestaurantLeafDataJobLastEvaluatedKeyInput
  RestaurantLeafDataJobResult: RestaurantLeafDataJobResult
  RestaurantLocation: RestaurantLocation
  RestaurantLocationAddress: RestaurantLocationAddress
  RestaurantLocationImages: RestaurantLocationImages
  RestaurantLocationInterface: ResolversInterfaceTypes<ResolversParentTypes>['RestaurantLocationInterface']
  RestaurantLocationsDiff: Omit<RestaurantLocationsDiff, 'left' | 'right'> & {
    left: ResolversParentTypes['RestaurantGroupOrLocation']
    right: ResolversParentTypes['RestaurantGroupOrLocation']
  }
  RestaurantLocationsQuery: RestaurantLocationsQuery
  RestaurantMenuItemEdge: Omit<RestaurantMenuItemEdge, 'node'> & {
    node: ResolversParentTypes['PublishedMenuItem']
  }
  RestaurantMetrics: Omit<
    RestaurantMetrics,
    'laborMetrics' | 'orderMetrics'
  > & {
    laborMetrics: ResolversParentTypes['LaborMetrics']
    orderMetrics: ResolversParentTypes['OrderMetrics']
  }
  RestaurantNotFound: RestaurantNotFound
  RestaurantRepositoryDependencyFailure: RestaurantRepositoryDependencyFailure
  RestaurantSet: Omit<RestaurantSet, 'permissions' | 'set'> & {
    permissions?: Maybe<ResolversParentTypes['Permissions']>
    set: ResolversParentTypes['RestaurantGroupOrLocation']
  }
  RestaurantSetInput: RestaurantSetInput
  RestaurantUser: RestaurantUser
  RestaurantUserInput: RestaurantUserInput
  RestoreMembershipResponse: RestoreMembershipResponse
  RevenueCenterDataExtension: Omit<
    RevenueCenterDataExtension,
    'revenueCenter'
  > & { revenueCenter: ResolversParentTypes['SavedRevenueCenter'] }
  SalesCategory: SalesCategory
  SalesCategoryDataExtension: Omit<
    SalesCategoryDataExtension,
    'salesCategory'
  > & { salesCategory: ResolversParentTypes['SavedSalesCategory'] }
  SalesCategoryInput: SalesCategoryInput
  SaveCustomMenuItemUpsellInput: SaveCustomMenuItemUpsellInput
  SaveFailure: SaveFailure
  SavePackagingConfigResponse: ResolversUnionTypes<ResolversParentTypes>['SavePackagingConfigResponse']
  SavedAlternatePaymentType: Omit<SavedAlternatePaymentType, 'target'> & {
    target: ResolversParentTypes['RestaurantSet']
  }
  SavedChannel: Omit<
    SavedChannel,
    | 'channelAvailability'
    | 'channelMenuEntities'
    | 'channelMenuPriceAdjustment'
    | 'channelType'
  > & {
    channelAvailability?: Maybe<
      ResolversParentTypes['SavedChannelAvailability']
    >
    channelMenuEntities?: Maybe<
      Array<ResolversParentTypes['ChannelMenuEntity']>
    >
    channelMenuPriceAdjustment?: Maybe<
      ResolversParentTypes['ChannelMenuPriceAdjustment']
    >
    channelType?: Maybe<ResolversParentTypes['ChannelType']>
  }
  SavedChannelAvailability: SavedChannelAvailability
  SavedChannelAvailabilityInput: SavedChannelAvailabilityInput
  SavedChannelAvailabilitySearchInput: SavedChannelAvailabilitySearchInput
  SavedChannelInput: SavedChannelInput
  SavedChannelSearchInput: SavedChannelSearchInput
  SavedConfig: SavedConfig
  SavedConfigEntityI: ResolversInterfaceTypes<ResolversParentTypes>['SavedConfigEntityI']
  SavedConfigOverrideI: ResolversInterfaceTypes<ResolversParentTypes>['SavedConfigOverrideI']
  SavedDataExtensionSet: Omit<
    SavedDataExtensionSet,
    | 'overrides'
    | 'payments'
    | 'revenueCenters'
    | 'salesCategories'
    | 'target'
    | 'taxRates'
  > & {
    overrides: Array<ResolversParentTypes['DataExtensionSetOverride']>
    payments: Array<ResolversParentTypes['PaymentDataExtension']>
    revenueCenters: Array<ResolversParentTypes['RevenueCenterDataExtension']>
    salesCategories: Array<ResolversParentTypes['SalesCategoryDataExtension']>
    target?: Maybe<ResolversParentTypes['RestaurantSet']>
    taxRates: Array<ResolversParentTypes['TaxRateDataExtension']>
  }
  SavedDiningOption: Omit<
    SavedDiningOption,
    'ownerRestaurantSet' | 'targetRestaurantSet'
  > & {
    ownerRestaurantSet?: Maybe<ResolversParentTypes['RestaurantSet']>
    targetRestaurantSet?: Maybe<ResolversParentTypes['RestaurantSet']>
  }
  SavedGiftCardReceiptConfig: Omit<SavedGiftCardReceiptConfig, 'target'> & {
    target?: Maybe<ResolversParentTypes['RestaurantSet']>
  }
  SavedMenuAuditSetting: SavedMenuAuditSetting
  SavedMenuAuditSettingInput: SavedMenuAuditSettingInput
  SavedMenuCreate: SavedMenuCreate
  SavedMenuEntityAvailabilityInput: SavedMenuEntityAvailabilityInput
  SavedMenuEntityCollapseModifierPricesInput: SavedMenuEntityCollapseModifierPricesInput
  SavedMenuEntityContentAdvisoriesInput: SavedMenuEntityContentAdvisoriesInput
  SavedMenuEntityImageInput: SavedMenuEntityImageInput
  SavedMenuEntityInventoryInput: SavedMenuEntityInventoryInput
  SavedMenuEntityPointOfSaleInput: SavedMenuEntityPointOfSaleInput
  SavedMenuEntityReportsInput: SavedMenuEntityReportsInput
  SavedMenuEntityTaxesInput: SavedMenuEntityTaxesInput
  SavedMenuEntityVisibilityInput: SavedMenuEntityVisibilityInput
  SavedMenuGroupCreate: SavedMenuGroupCreate
  SavedMenuGroupPreparation: SavedMenuGroupPreparation
  SavedMenuGroupPreparationInput: SavedMenuGroupPreparationInput
  SavedMenuItemCreate: SavedMenuItemCreate
  SavedMenuItemModifiers: SavedMenuItemModifiers
  SavedMenuItemPreparation: SavedMenuItemPreparation
  SavedMenuItemPreparationInput: SavedMenuItemPreparationInput
  SavedMenuItemPricingInput: SavedMenuItemPricingInput
  SavedMenuItemTag: SavedMenuItemTag
  SavedMenuItemTagsInput: SavedMenuItemTagsInput
  SavedMenuItemUpdate: SavedMenuItemUpdate
  SavedMenuPreparation: SavedMenuPreparation
  SavedMenuSpecificPriceInput: SavedMenuSpecificPriceInput
  SavedMenuUpdate: SavedMenuUpdate
  SavedOrderConfirmationScreenConfig: Omit<
    SavedOrderConfirmationScreenConfig,
    'baseConfig' | 'overrides'
  > & {
    baseConfig: ResolversParentTypes['QLOrderConfirmationScreenConfig']
    overrides?: Maybe<
      Array<ResolversParentTypes['OrderConfirmationScreenConfigOverride']>
    >
  }
  SavedPackagingConfig: Omit<SavedPackagingConfig, 'items' | 'overrides'> & {
    items: Array<ResolversParentTypes['PackagingItem']>
    overrides: Array<ResolversParentTypes['PackagingConfigOverride']>
  }
  SavedPrepSequence: Omit<
    SavedPrepSequence,
    'ownerRestaurantSet' | 'targetRestaurantSet'
  > & {
    ownerRestaurantSet: ResolversParentTypes['RestaurantSet']
    targetRestaurantSet: ResolversParentTypes['RestaurantSet']
  }
  SavedPrepSequencesSearch: SavedPrepSequencesSearch
  SavedPrepStation: Omit<
    SavedPrepStation,
    'ownerRestaurantSet' | 'targetRestaurantSet'
  > & {
    ownerRestaurantSet: ResolversParentTypes['RestaurantSet']
    targetRestaurantSet: ResolversParentTypes['RestaurantSet']
  }
  SavedPrepStationsSearch: SavedPrepStationsSearch
  SavedQueries: SavedQueries
  SavedRestaurantLocation: SavedRestaurantLocation
  SavedRestaurantLocationConnection: SavedRestaurantLocationConnection
  SavedRestaurantLocationEdge: SavedRestaurantLocationEdge
  SavedRestaurantTable: SavedRestaurantTable
  SavedRevenueCenter: Omit<
    SavedRevenueCenter,
    'ownerRestaurantSet' | 'targetRestaurantSet'
  > & {
    ownerRestaurantSet: ResolversParentTypes['RestaurantSet']
    targetRestaurantSet: ResolversParentTypes['RestaurantSet']
  }
  SavedRevenueCenterSearch: SavedRevenueCenterSearch
  SavedSalesCategoriesSearch: SavedSalesCategoriesSearch
  SavedSalesCategory: Omit<
    SavedSalesCategory,
    'ownerRestaurantSet' | 'targetRestaurantSet'
  > & {
    ownerRestaurantSet: ResolversParentTypes['RestaurantSet']
    targetRestaurantSet: ResolversParentTypes['RestaurantSet']
  }
  SavedSizePriceInput: SavedSizePriceInput
  SavedStockDataConfig: Omit<
    SavedStockDataConfig,
    'baseConfig' | 'overrides'
  > & {
    baseConfig: ResolversParentTypes['QLStockDataConfig']
    overrides?: Maybe<Array<ResolversParentTypes['StockDataConfigOverride']>>
  }
  SavedStockOutConfig: Omit<SavedStockOutConfig, 'baseConfig' | 'overrides'> & {
    baseConfig: ResolversParentTypes['QLStockOutConfig']
    overrides?: Maybe<Array<ResolversParentTypes['StockOutConfigOverride']>>
  }
  SavedTaxRate: Omit<
    SavedTaxRate,
    'ownerRestaurantSet' | 'targetRestaurantSet'
  > & {
    ownerRestaurantSet: ResolversParentTypes['RestaurantSet']
    targetRestaurantSet: ResolversParentTypes['RestaurantSet']
  }
  SavedTaxRatesSearch: SavedTaxRatesSearch
  SavedToastPayConfigError: SavedToastPayConfigError
  SavedToastPayConfigResponse: ResolversUnionTypes<ResolversParentTypes>['SavedToastPayConfigResponse']
  Schedule: Schedule
  ScheduleInput: ScheduleInput
  SearchEntity: ResolversUnionTypes<ResolversParentTypes>['SearchEntity']
  SearchItem: Omit<SearchItem, 'entity'> & {
    entity?: Maybe<ResolversParentTypes['SearchEntity']>
  }
  SearchMutation: Omit<SearchMutation, 'createUserInteractions'> & {
    createUserInteractions: ResolversParentTypes['UserIteractionMutationResponse']
  }
  SearchQuery: Omit<SearchQuery, 'autoComplete' | 'search'> & {
    autoComplete: ResolversParentTypes['SearchResponse']
    search: ResolversParentTypes['SearchResponse']
  }
  SearchResponse: Omit<SearchResponse, 'items'> & {
    items: Array<ResolversParentTypes['SearchItem']>
  }
  SearchSuggestion: SearchSuggestion
  SeasonalEmploymentStatus: SeasonalEmploymentStatus
  Section: Section
  SendGuestFeedbackResponseInput: SendGuestFeedbackResponseInput
  ServerColor: ServerColor
  ServiceArea: ServiceArea
  ServiceAreaAvailabilityInfo: Omit<ServiceAreaAvailabilityInfo, 'deposit'> & {
    deposit?: Maybe<ResolversParentTypes['DepositAvailabilityInfo']>
  }
  ServiceAreaGroupInfo: ServiceAreaGroupInfo
  ServiceAreaNotFound: ServiceAreaNotFound
  ServiceAreaResponse: ServiceAreaResponse
  ServiceDataExtension: ServiceDataExtension
  ShiftAlreadyClockedOut: ShiftAlreadyClockedOut
  ShiftCheckedResultRepositoryDependencyFailure: ShiftCheckedResultRepositoryDependencyFailure
  ShiftError: ResolversInterfaceTypes<ResolversParentTypes>['ShiftError']
  ShiftFilterInput: ShiftFilterInput
  ShiftFilterInputV2: ShiftFilterInputV2
  ShiftNotFound: ShiftNotFound
  ShiftOrderByInput: ShiftOrderByInput
  ShiftReviewConfig: ShiftReviewConfig
  ShiftReviewConfigInput: ShiftReviewConfigInput
  ShiftTipInput: ShiftTipInput
  ShiftV2Employee: ShiftV2Employee
  ShiftV2EmployeesResponse: ShiftV2EmployeesResponse
  ShiftsV2PageInfo: ShiftsV2PageInfo
  ShiftsV2PaginationInput: ShiftsV2PaginationInput
  SizePrice: Omit<SizePrice, 'pricing'> & {
    pricing?: Maybe<ResolversParentTypes['MenuItemPricing']>
  }
  SlotAvailabilityInfo: Omit<SlotAvailabilityInfo, 'serviceAreas'> & {
    serviceAreas: Array<ResolversParentTypes['ServiceAreaAvailabilityInfo']>
  }
  SmartQuoteAutoChangeStrategyResponse: SmartQuoteAutoChangeStrategyResponse
  SmartQuoteConfig: SmartQuoteConfig
  SmartQuoteMutation: SmartQuoteMutation
  SmartQuoteQuery: SmartQuoteQuery
  SmartQuoteSimpleComparison: SmartQuoteSimpleComparison
  SmartQuoteSimpleComparisonQuery: SmartQuoteSimpleComparisonQuery
  SnoozeValue: SnoozeValue
  SousChefAction: SousChefAction
  SousChefActionPlan: SousChefActionPlan
  SousChefAdminMutation: Omit<SousChefAdminMutation, 'createWorkflow'> & {
    createWorkflow: ResolversParentTypes['SousChefWorkflowDefinition']
  }
  SousChefAdminQuery: SousChefAdminQuery
  SousChefAlert: SousChefAlert
  SousChefAnalysisNode: SousChefAnalysisNode
  SousChefAutoClockedOut: SousChefAutoClockedOut
  SousChefAutoClockedOutDataPoint: SousChefAutoClockedOutDataPoint
  SousChefChecklistItem: Omit<SousChefChecklistItem, 'insight'> & {
    insight: ResolversParentTypes['SousChefInsight']
  }
  SousChefCompletedThread: Omit<SousChefCompletedThread, 'filters'> & {
    filters: Array<ResolversParentTypes['SousChefFilter']>
  }
  SousChefDataNode: SousChefDataNode
  SousChefDataPanel: SousChefDataPanel
  SousChefDataPoint: ResolversInterfaceTypes<ResolversParentTypes>['SousChefDataPoint']
  SousChefDataSource: SousChefDataSource
  SousChefDateRange: SousChefDateRange
  SousChefDateRangeFilter: SousChefDateRangeFilter
  SousChefDateTimeRange: SousChefDateTimeRange
  SousChefDayOfWeekFilter: SousChefDayOfWeekFilter
  SousChefDayPart: SousChefDayPart
  SousChefDayPartFilter: SousChefDayPartFilter
  SousChefDefinition: SousChefDefinition
  SousChefDiningOption: SousChefDiningOption
  SousChefDiningOptionFilter: SousChefDiningOptionFilter
  SousChefEdge: SousChefEdge
  SousChefEmployeeFilter: SousChefEmployeeFilter
  SousChefEmptyDataPoint: SousChefEmptyDataPoint
  SousChefFeedback: SousChefFeedback
  SousChefFilter: ResolversUnionTypes<ResolversParentTypes>['SousChefFilter']
  SousChefFinancialData: SousChefFinancialData
  SousChefFinancialDataPoint: SousChefFinancialDataPoint
  SousChefFixedResponseNode: SousChefFixedResponseNode
  SousChefHoursOfDayFilter: SousChefHoursOfDayFilter
  SousChefInsight: Omit<
    SousChefInsight,
    'data' | 'dataPoint' | 'relatedInsights'
  > & {
    data: Array<ResolversParentTypes['SousChefDataPoint']>
    dataPoint: ResolversParentTypes['SousChefDataPoint']
    relatedInsights?: Maybe<Array<ResolversParentTypes['SousChefInsight']>>
  }
  SousChefInsightFilter: SousChefInsightFilter
  SousChefIntervalFilter: SousChefIntervalFilter
  SousChefKnowledge: SousChefKnowledge
  SousChefMenu: SousChefMenu
  SousChefMenuFilter: SousChefMenuFilter
  SousChefMenuGroup: SousChefMenuGroup
  SousChefMenuGroupFilter: SousChefMenuGroupFilter
  SousChefMutation: SousChefMutation
  SousChefOrderSource: SousChefOrderSource
  SousChefOrderSourceFilter: SousChefOrderSourceFilter
  SousChefOutOfStockItem: Omit<SousChefOutOfStockItem, 'menuItem'> & {
    menuItem: ResolversParentTypes['PublishedMenuItem']
  }
  SousChefPercentageData: SousChefPercentageData
  SousChefPercentageDataPoint: SousChefPercentageDataPoint
  SousChefQuery: Omit<
    SousChefQuery,
    'insight' | 'threadSession' | 'threadSessions'
  > & {
    insight?: Maybe<ResolversParentTypes['SousChefInsight']>
    threadSession?: Maybe<ResolversParentTypes['SousChefThreadSession']>
    threadSessions?: Maybe<Array<ResolversParentTypes['SousChefThreadSession']>>
  }
  SousChefRevenueCenter: SousChefRevenueCenter
  SousChefRevenueCenterFilter: SousChefRevenueCenterFilter
  SousChefSalesCategory: SousChefSalesCategory
  SousChefSalesCategoryFilter: SousChefSalesCategoryFilter
  SousChefServiceArea: SousChefServiceArea
  SousChefServiceAreaFilter: SousChefServiceAreaFilter
  SousChefStockDataPoint: Omit<SousChefStockDataPoint, 'items'> & {
    items: Array<ResolversParentTypes['SousChefOutOfStockItem']>
  }
  SousChefSuggestedThread: SousChefSuggestedThread
  SousChefSummary: Omit<
    SousChefSummary,
    'checklist' | 'insights' | 'threadGroups'
  > & {
    checklist: Array<ResolversParentTypes['SousChefChecklistItem']>
    insights: Array<ResolversParentTypes['SousChefInsight']>
    threadGroups: Array<ResolversParentTypes['SousChefThreadGroup']>
  }
  SousChefTaskData: SousChefTaskData
  SousChefTextDataPoint: SousChefTextDataPoint
  SousChefThread: ResolversInterfaceTypes<ResolversParentTypes>['SousChefThread']
  SousChefThreadFeedback: SousChefThreadFeedback
  SousChefThreadGroup: Omit<SousChefThreadGroup, 'threads'> & {
    threads: Array<ResolversParentTypes['SousChefThread']>
  }
  SousChefThreadSession: Omit<SousChefThreadSession, 'threads'> & {
    threads: Array<ResolversParentTypes['SousChefCompletedThread']>
  }
  SousChefTrend: SousChefTrend
  SousChefTrendDataPoint: SousChefTrendDataPoint
  SousChefWorkflowDefinition: Omit<
    SousChefWorkflowDefinition,
    'edges' | 'nodes'
  > & {
    edges: Array<ResolversParentTypes['SousChefEdge']>
    nodes: Array<ResolversParentTypes['SousChefWorkflowNode']>
  }
  SousChefWorkflowNode: ResolversUnionTypes<ResolversParentTypes>['SousChefWorkflowNode']
  SousChefWorkflowTask: SousChefWorkflowTask
  StartDoorDashOnboardingRequest: StartDoorDashOnboardingRequest
  StaticPaymentDataExtension: StaticPaymentDataExtension
  Step: Step
  Stock: Stock
  StockDataConfigInput: StockDataConfigInput
  StockDataConfigOverride: Omit<StockDataConfigOverride, 'target'> & {
    target: ResolversParentTypes['RestaurantSet']
  }
  StockDataConfigOverrideInput: StockDataConfigOverrideInput
  StockOutConfigInput: StockOutConfigInput
  StockOutConfigOverride: Omit<StockOutConfigOverride, 'target'> & {
    target: ResolversParentTypes['RestaurantSet']
  }
  StockOutConfigOverrideInput: StockOutConfigOverrideInput
  SubmitLogbookEntryInput: SubmitLogbookEntryInput
  SubmitLogbookEntryReplyInput: SubmitLogbookEntryReplyInput
  SubscriptionsCancellationRequest: SubscriptionsCancellationRequest
  SubscriptionsCancellationRequestFilter: SubscriptionsCancellationRequestFilter
  SubscriptionsCancellationRequestInput: SubscriptionsCancellationRequestInput
  SubscriptionsCancellationRequestItemInput: SubscriptionsCancellationRequestItemInput
  SuggestionResponse: SuggestionResponse
  SupportQuery: SupportQuery
  SystemIdentity: SystemIdentity
  TableAvailabilityInfo: TableAvailabilityInfo
  TableBlocked: TableBlocked
  TableNotFound: TableNotFound
  TakeoutDeliveryCategoryPage: TakeoutDeliveryCategoryPage
  TakeoutDeliveryConfig: TakeoutDeliveryConfig
  TargetAndOwnerInput: TargetAndOwnerInput
  Task: Task
  TaskAction: TaskAction
  TaxRateDataExtension: Omit<TaxRateDataExtension, 'taxRate'> & {
    taxRate: ResolversParentTypes['SavedTaxRate']
  }
  TerminalHandheld: TerminalHandheld
  TerminatedEmploymentStatus: TerminatedEmploymentStatus
  TextArrayFilter: TextArrayFilter
  TextFilter: TextFilter
  ThirdPartyMockMutation: ThirdPartyMockMutation
  ThirdPartyMockProviderEvent: ThirdPartyMockProviderEvent
  ThirdPartyMockProviderEventInput: ThirdPartyMockProviderEventInput
  ThirdPartyMockQuery: ThirdPartyMockQuery
  ThirdPartyOrderingOnboardingFastTrackRequest: ThirdPartyOrderingOnboardingFastTrackRequest
  ThirdPartyOrderingOnboardingFlow: ThirdPartyOrderingOnboardingFlow
  ThirdPartyOrderingOnboardingFlowInput: ThirdPartyOrderingOnboardingFlowInput
  ThirdPartyOrderingOnboardingMutation: ThirdPartyOrderingOnboardingMutation
  ThirdPartyOrderingOnboardingQuery: ThirdPartyOrderingOnboardingQuery
  ThirdPartyOrderingOnboardingTask: ThirdPartyOrderingOnboardingTask
  ThirdPartyOrderingOnboardingTaskInput: ThirdPartyOrderingOnboardingTaskInput
  ThirdPartyOrderingOnboardingTaskResult: ThirdPartyOrderingOnboardingTaskResult
  ThirdPartyOrderingSavedMenuAuditSetting: ThirdPartyOrderingSavedMenuAuditSetting
  TimeClockInput: TimeClockInput
  TimeRangeInput: TimeRangeInput
  TipDetails: TipDetails
  TipInput: TipInput
  TipOutConfig: TipOutConfig
  TipOutConfigInput: TipOutConfigInput
  TipSharingConfig: TipSharingConfig
  TipSharingPercentage: TipSharingPercentage
  TipSharingPercentageInput: TipSharingPercentageInput
  TipsQuery: TipsQuery
  ToastCentralArticle: ToastCentralArticle
  ToastNavigationConfig: ToastNavigationConfig
  ToastNowDeviceRegistryMutation: ToastNowDeviceRegistryMutation
  ToastNowDeviceRegistryQuery: ToastNowDeviceRegistryQuery
  ToastNowDeviceToken: ToastNowDeviceToken
  ToastNowUpdateDeviceTokenInput: ToastNowUpdateDeviceTokenInput
  ToastPayConfig: ToastPayConfig
  ToastPayConfigInput: ToastPayConfigInput
  ToastPayPaymentNotificationConfig: ToastPayPaymentNotificationConfig
  ToastPayPaymentNotificationConfigError: ToastPayPaymentNotificationConfigError
  ToastPayPaymentNotificationConfigResponse: ResolversUnionTypes<ResolversParentTypes>['ToastPayPaymentNotificationConfigResponse']
  ToastPayRelatedConfiguration: Omit<
    ToastPayRelatedConfiguration,
    'paymentNotificationsConfig' | 'restaurantLogo' | 'tipSelectionsConfig'
  > & {
    paymentNotificationsConfig: ResolversParentTypes['ToastPayPaymentNotificationConfigResponse']
    restaurantLogo: ResolversParentTypes['ToastPayRestaurantLogoResponse']
    tipSelectionsConfig: ResolversParentTypes['ToastPayTipSelectionsConfigResponse']
  }
  ToastPayRestaurantLogo: ToastPayRestaurantLogo
  ToastPayRestaurantLogoError: ToastPayRestaurantLogoError
  ToastPayRestaurantLogoResponse: ResolversUnionTypes<ResolversParentTypes>['ToastPayRestaurantLogoResponse']
  ToastPayTipSelectionsConfig: ToastPayTipSelectionsConfig
  ToastPayTipSelectionsConfigResponse: ResolversUnionTypes<ResolversParentTypes>['ToastPayTipSelectionsConfigResponse']
  ToastPayTipSelectionsError: ToastPayTipSelectionsError
  ToastPermission: ToastPermission
  TopSellingItemConnection: Omit<TopSellingItemConnection, 'edges'> & {
    edges: Array<ResolversParentTypes['TopSellingItemEdge']>
  }
  TopSellingItemEdge: Omit<TopSellingItemEdge, 'node'> & {
    node: ResolversParentTypes['ItemMetrics']
  }
  TopSellingItemFilter: TopSellingItemFilter
  TopSellingItemFilterBy: TopSellingItemFilterBy
  TopSellingModifierConnection: Omit<TopSellingModifierConnection, 'edges'> & {
    edges: Array<ResolversParentTypes['TopSellingModifierEdge']>
  }
  TopSellingModifierEdge: Omit<TopSellingModifierEdge, 'node'> & {
    node: ResolversParentTypes['ModifierMetrics']
  }
  TopSellingModifierFilter: TopSellingModifierFilter
  TotalRefundAmount: TotalRefundAmount
  TrialEligibilityProbeResponse: TrialEligibilityProbeResponse
  TrialEntity: TrialEntity
  TrialFeedbackRequest: TrialFeedbackRequest
  TrialFeedbackResponse: TrialFeedbackResponse
  TrialMutation: TrialMutation
  TrialMutationResponse: TrialMutationResponse
  TrialProbe: TrialProbe
  TrialsQuery: TrialsQuery
  UIOptionInvalidEntity: ResolversUnionTypes<ResolversParentTypes>['UIOptionInvalidEntity']
  UIOptionInvalidSmartTax: Omit<UiOptionInvalidSmartTax, 'invalidEntities'> & {
    invalidEntities: Array<ResolversParentTypes['UIOptionInvalidEntity']>
  }
  UIOptionsMutation: Omit<
    UiOptionsMutation,
    'updateSavedUIOption' | 'updateSavedUIOptionV2'
  > & {
    updateSavedUIOption: ResolversParentTypes['MlmUIOption']
    updateSavedUIOptionV2: ResolversParentTypes['UpdateSavedUIOptionResponse']
  }
  UIOptionsQuery: Omit<UiOptionsQuery, 'savedUIOption'> & {
    savedUIOption: ResolversParentTypes['MlmUIOption']
  }
  UIOptionsTaxOptions: UiOptionsTaxOptions
  UIOptionsTaxOptionsInput: UiOptionsTaxOptionsInput
  UIOptionsTipSetup: UiOptionsTipSetup
  UIOptionsTipSetupInput: UiOptionsTipSetupInput
  UIOptionsTippingAmounts: UiOptionsTippingAmounts
  UIOptionsTippingAmountsInput: UiOptionsTippingAmountsInput
  UIOptionsTippingPercentages: UiOptionsTippingPercentages
  UIOptionsTippingPercentagesInput: UiOptionsTippingPercentagesInput
  UIOptionsTippingRequireApproval: UiOptionsTippingRequireApproval
  UIOptionsTippingRequireApprovalInput: UiOptionsTippingRequireApprovalInput
  UKCustomerBillingInvoice: Omit<UkCustomerBillingInvoice, 'deductions'> & {
    deductions: ResolversParentTypes['CustomerBillingInvoiceDeductions']
  }
  UKCustomerBillingInvoiceItem: UkCustomerBillingInvoiceItem
  USCustomerBillingInvoice: Omit<UsCustomerBillingInvoice, 'deductions'> & {
    deductions: ResolversParentTypes['CustomerBillingInvoiceDeductions']
  }
  USCustomerBillingInvoiceItem: UsCustomerBillingInvoiceItem
  UnableToCreateDepositOrder: UnableToCreateDepositOrder
  UnableToCreateGuest: UnableToCreateGuest
  UnableToFetchGuests: UnableToFetchGuests
  UnableToSendSMS: UnableToSendSms
  UnableToUpdateGuest: UnableToUpdateGuest
  Unauthorized: Unauthorized
  UnauthorizedAccessError: UnauthorizedAccessError
  UnknownError: UnknownError
  UnknownIdentity: UnknownIdentity
  UnpublishedChangesFilterInput: UnpublishedChangesFilterInput
  UnpublishedConfig: UnpublishedConfig
  UnpublishedConfigConnection: UnpublishedConfigConnection
  UnpublishedConfigEdge: UnpublishedConfigEdge
  UnpublishedStatus: UnpublishedStatus
  UnpublishedStatusConnection: UnpublishedStatusConnection
  UnpublishedStatusEdge: UnpublishedStatusEdge
  UnsupportedTransition: UnsupportedTransition
  UpdateCashFlowTransactionRequest: UpdateCashFlowTransactionRequest
  UpdateDataExtensionSetInput: UpdateDataExtensionSetInput
  UpdateDiningOptionInput: UpdateDiningOptionInput
  UpdateGfdConfigInput: UpdateGfdConfigInput
  UpdateGuestRequest: UpdateGuestRequest
  UpdateItemFeedbackConfigError: UpdateItemFeedbackConfigError
  UpdateItemFeedbackConfigResponse: ResolversUnionTypes<ResolversParentTypes>['UpdateItemFeedbackConfigResponse']
  UpdateKioskConfigInput: UpdateKioskConfigInput
  UpdateKioskConfigServiceChargeInput: UpdateKioskConfigServiceChargeInput
  UpdateKioskCustomerMessageSettingsInput: UpdateKioskCustomerMessageSettingsInput
  UpdateKioskUpsellMenuItemInput: UpdateKioskUpsellMenuItemInput
  UpdateLogbookEntry: UpdateLogbookEntry
  UpdateLogbookEntryInput: UpdateLogbookEntryInput
  UpdateLogbookEntryReply: UpdateLogbookEntryReply
  UpdateLogbookEntryReplyInput: UpdateLogbookEntryReplyInput
  UpdateMembershipInput: UpdateMembershipInput
  UpdateMembershipResult: UpdateMembershipResult
  UpdateMenuItemPlateCost: UpdateMenuItemPlateCost
  UpdateMlmUIOptionInput: UpdateMlmUiOptionInput
  UpdateMlmUIOptionVariantInput: UpdateMlmUiOptionVariantInput
  UpdateReasonInput: UpdateReasonInput
  UpdateReasonOrdinalInput: UpdateReasonOrdinalInput
  UpdateReservationRequest: UpdateReservationRequest
  UpdateReservationResponse: ResolversUnionTypes<ResolversParentTypes>['UpdateReservationResponse']
  UpdateReservationResponseSuccess: UpdateReservationResponseSuccess
  UpdateSavedUIOptionResponse: ResolversUnionTypes<ResolversParentTypes>['UpdateSavedUIOptionResponse']
  UpdateShiftFailure: UpdateShiftFailure
  UpdateShiftInput: UpdateShiftInput
  UpdateShiftResult: ResolversUnionTypes<ResolversParentTypes>['UpdateShiftResult']
  UpdateShiftTimesInput: UpdateShiftTimesInput
  UpdateShiftTimesResult: ResolversUnionTypes<ResolversParentTypes>['UpdateShiftTimesResult']
  UpdateSubscriptionAutoRenewalInput: UpdateSubscriptionAutoRenewalInput
  UpdateTipAmountInput: UpdateTipAmountInput
  UpdateToastPayConfigError: UpdateToastPayConfigError
  UpdateToastPayConfigResponse: ResolversUnionTypes<ResolversParentTypes>['UpdateToastPayConfigResponse']
  UpdateUserPreferredLocalResponse: UpdateUserPreferredLocalResponse
  UpdateUserQuickActionInput: UpdateUserQuickActionInput
  UpgradeItemFeedbackConfigResponse: ResolversUnionTypes<ResolversParentTypes>['UpgradeItemFeedbackConfigResponse']
  UploadImageResponse: UploadImageResponse
  UpsellRequest: UpsellRequest
  Url: Scalars['Url']['output']
  User: User
  UserFeatureOptIn: UserFeatureOptIn
  UserFeatureOptInsResponse: UserFeatureOptInsResponse
  UserIdentity: UserIdentity
  UserInteractionResponse: UserInteractionResponse
  UserInterface: ResolversInterfaceTypes<ResolversParentTypes>['UserInterface']
  UserIteraction: UserIteraction
  UserIteractionMutationResponse: ResolversUnionTypes<ResolversParentTypes>['UserIteractionMutationResponse']
  UserPreferencesMutation: UserPreferencesMutation
  UserPreferencesQuery: UserPreferencesQuery
  UserQuickAction: UserQuickAction
  UserQuickActionInput: UserQuickActionInput
  UserQuickActionParam: UserQuickActionParam
  UserQuickActionParamInput: UserQuickActionParamInput
  UserSearch: UserSearch
  VersionInput: VersionInput
  VoidAmount: Omit<VoidAmount, 'grouped'> & {
    grouped?: Maybe<Array<ResolversParentTypes['GroupedVoidAmount']>>
  }
  VoidAmountAsPercentageOfNetSales: Omit<
    VoidAmountAsPercentageOfNetSales,
    'grouped'
  > & {
    grouped?: Maybe<
      Array<ResolversParentTypes['GroupedVoidAmountAsPercentageOfNetSales']>
    >
  }
  VoidFilter: VoidFilter
  VoidGroupEntity: ResolversUnionTypes<ResolversParentTypes>['VoidGroupEntity']
  VoidReason: VoidReason
  VoidedItemsCount: Omit<VoidedItemsCount, 'grouped'> & {
    grouped: Array<ResolversParentTypes['GroupedVoidedItemsCount']>
  }
  WeekOperatingSchedule: WeekOperatingSchedule
  Workflow: Workflow
}

export type CacheControlDirectiveArgs = {
  inheritMaxAge?: Maybe<Scalars['Boolean']['input']>
  maxAge?: Maybe<Scalars['Int']['input']>
  scope?: Maybe<CacheControlScope>
}

export type CacheControlDirectiveResolver<
  Result,
  Parent,
  ContextType = any,
  Args = CacheControlDirectiveArgs
> = DirectiveResolverFn<Result, Parent, ContextType, Args>

export type ExtendsDirectiveArgs = {}

export type ExtendsDirectiveResolver<
  Result,
  Parent,
  ContextType = any,
  Args = ExtendsDirectiveArgs
> = DirectiveResolverFn<Result, Parent, ContextType, Args>

export type LinkDirectiveArgs = {
  import?: Maybe<Array<Maybe<Scalars['String']['input']>>>
  url?: Maybe<Scalars['String']['input']>
}

export type LinkDirectiveResolver<
  Result,
  Parent,
  ContextType = any,
  Args = LinkDirectiveArgs
> = DirectiveResolverFn<Result, Parent, ContextType, Args>

export type ShareableDirectiveArgs = {}

export type ShareableDirectiveResolver<
  Result,
  Parent,
  ContextType = any,
  Args = ShareableDirectiveArgs
> = DirectiveResolverFn<Result, Parent, ContextType, Args>

export type TraceDirectiveArgs = {
  name: Scalars['String']['input']
  value: Scalars['String']['input']
}

export type TraceDirectiveResolver<
  Result,
  Parent,
  ContextType = any,
  Args = TraceDirectiveArgs
> = DirectiveResolverFn<Result, Parent, ContextType, Args>

export type AccessibleRestaurantLocationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AccessibleRestaurantLocation'] = ResolversParentTypes['AccessibleRestaurantLocation']
> = {
  description?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  locationName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  managementGroup?: Resolver<
    Maybe<ResolversTypes['ManagementGroup']>,
    ParentType,
    ContextType
  >
  managementSet?: Resolver<
    Maybe<ResolversTypes['ManagementSet']>,
    ParentType,
    ContextType
  >
  restaurantName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  restaurantSet?: Resolver<
    ResolversTypes['RestaurantSet'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AccessibleRestaurantSetResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AccessibleRestaurantSet'] = ResolversParentTypes['AccessibleRestaurantSet']
> = {
  isAccessible?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  restaurantSet?: Resolver<
    ResolversTypes['RestaurantSet'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ActiveEmploymentStatusResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ActiveEmploymentStatus'] = ResolversParentTypes['ActiveEmploymentStatus']
> = {
  adjustedHireDate?: Resolver<
    Maybe<ResolversTypes['Date']>,
    ParentType,
    ContextType
  >
  hireDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AdditionalModifierGroupsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AdditionalModifierGroups'] = ResolversParentTypes['AdditionalModifierGroups']
> = {
  course?: Resolver<
    Maybe<ResolversTypes['AdditionalModifierGroupsCourse']>,
    ParentType,
    ContextType
  >
  diningOption?: Resolver<
    Maybe<ResolversTypes['AdditionalModifierGroupsDiningOption']>,
    ParentType,
    ContextType
  >
  seatNumberQuickOrder?: Resolver<
    Maybe<ResolversTypes['AdditionalModifierGroupsSeatNumberQuickOrder']>,
    ParentType,
    ContextType
  >
  seatNumberTableService?: Resolver<
    Maybe<ResolversTypes['AdditionalModifierGroupsSeatNumberTableService']>,
    ParentType,
    ContextType
  >
  specialRequest?: Resolver<
    Maybe<ResolversTypes['AdditionalModifierGroupsSpecialRequest']>,
    ParentType,
    ContextType
  >
  split?: Resolver<
    Maybe<ResolversTypes['AdditionalModifierGroupsSplit']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AdditionalModifierGroupsCourseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AdditionalModifierGroupsCourse'] = ResolversParentTypes['AdditionalModifierGroupsCourse']
> = {
  enabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AdditionalModifierGroupsDiningOptionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AdditionalModifierGroupsDiningOption'] = ResolversParentTypes['AdditionalModifierGroupsDiningOption']
> = {
  enabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AdditionalModifierGroupsSeatNumberQuickOrderResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AdditionalModifierGroupsSeatNumberQuickOrder'] = ResolversParentTypes['AdditionalModifierGroupsSeatNumberQuickOrder']
> = {
  enabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  required?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AdditionalModifierGroupsSeatNumberTableServiceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AdditionalModifierGroupsSeatNumberTableService'] = ResolversParentTypes['AdditionalModifierGroupsSeatNumberTableService']
> = {
  enabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  required?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AdditionalModifierGroupsSpecialRequestResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AdditionalModifierGroupsSpecialRequest'] = ResolversParentTypes['AdditionalModifierGroupsSpecialRequest']
> = {
  enabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AdditionalModifierGroupsSplitResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AdditionalModifierGroupsSplit'] = ResolversParentTypes['AdditionalModifierGroupsSplit']
> = {
  enabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AiChatTermsOfUseResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AiChatTermsOfUseResponse'] = ResolversParentTypes['AiChatTermsOfUseResponse']
> = {
  acceptedDate?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  isAccepted?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AiTermsOfUseDefaultResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AiTermsOfUseDefaultResponse'] = ResolversParentTypes['AiTermsOfUseDefaultResponse']
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AllBookingsResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AllBookingsResponse'] = ResolversParentTypes['AllBookingsResponse']
> = {
  __resolveType: TypeResolveFn<
    | 'AllBookingsResponseSuccess'
    | 'InternalError'
    | 'RestaurantNotFound'
    | 'UnableToFetchGuests',
    ParentType,
    ContextType
  >
}

export type AllBookingsResponseSuccessResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AllBookingsResponseSuccess'] = ResolversParentTypes['AllBookingsResponseSuccess']
> = {
  bookingResponse?: Resolver<
    Array<ResolversTypes['Booking']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AllEmploymentStatusesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AllEmploymentStatuses'] = ResolversParentTypes['AllEmploymentStatuses']
> = {
  __resolveType: TypeResolveFn<
    | 'ActiveEmploymentStatus'
    | 'ApplicantEmploymentStatus'
    | 'DeletedEmploymentStatus'
    | 'DemoEmploymentStatus'
    | 'ImplementationEmploymentStatus'
    | 'LeaveOfAbsenceEmploymentStatus'
    | 'NeverEmployedEmploymentStatus'
    | 'PendingEmploymentStatus'
    | 'RehiredEmploymentStatus'
    | 'SeasonalEmploymentStatus'
    | 'TerminatedEmploymentStatus',
    ParentType,
    ContextType
  >
}

export type AlternatePaymentDataExtensionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AlternatePaymentDataExtension'] = ResolversParentTypes['AlternatePaymentDataExtension']
> = {
  alternatePaymentType?: Resolver<
    ResolversTypes['SavedAlternatePaymentType'],
    ParentType,
    ContextType
  >
  type?: Resolver<ResolversTypes['DataExtensionType'], ParentType, ContextType>
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ApplicantEmploymentStatusResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ApplicantEmploymentStatus'] = ResolversParentTypes['ApplicantEmploymentStatus']
> = {
  adjustedHireDate?: Resolver<
    Maybe<ResolversTypes['Date']>,
    ParentType,
    ContextType
  >
  hireDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ApplicationMetadataResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ApplicationMetadata'] = ResolversParentTypes['ApplicationMetadata']
> = {
  isPreapproval?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ArchiveMembershipResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ArchiveMembershipResponse'] = ResolversParentTypes['ArchiveMembershipResponse']
> = {
  code?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AuthConfigurationMutationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AuthConfigurationMutation'] = ResolversParentTypes['AuthConfigurationMutation']
> = {
  applyJobBasedAccessConfig?: Resolver<
    ResolversTypes['JobBasedAccessConfig'],
    ParentType,
    ContextType,
    RequireFields<
      AuthConfigurationMutationApplyJobBasedAccessConfigArgs,
      'jobBasedAccessConfig'
    >
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AuthConfigurationQueryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AuthConfigurationQuery'] = ResolversParentTypes['AuthConfigurationQuery']
> = {
  jobBasedAccessConfig?: Resolver<
    Maybe<ResolversTypes['JobBasedAccessConfig']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AuthenticationFailureResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AuthenticationFailure'] = ResolversParentTypes['AuthenticationFailure']
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AuthorizationDecisionReasonResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AuthorizationDecisionReason'] = ResolversParentTypes['AuthorizationDecisionReason']
> = {
  result?: Resolver<
    ResolversTypes['AuthorizationDecision'],
    ParentType,
    ContextType
  >
  ruleGuid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AuthorizationPageResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AuthorizationPage'] = ResolversParentTypes['AuthorizationPage']
> = {
  lastEvaluatedUserId?: Resolver<
    Maybe<ResolversTypes['ID']>,
    ParentType,
    ContextType
  >
  limit?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AuthorizationTargetResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AuthorizationTarget'] = ResolversParentTypes['AuthorizationTarget']
> = {
  objectName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  operationName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AvailabilitiesResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AvailabilitiesResponse'] = ResolversParentTypes['AvailabilitiesResponse']
> = {
  __resolveType: TypeResolveFn<
    'AvailabilitiesResponseSuccess' | 'BookingNotFound' | 'RestaurantNotFound',
    ParentType,
    ContextType
  >
}

export type AvailabilitiesResponseSuccessResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AvailabilitiesResponseSuccess'] = ResolversParentTypes['AvailabilitiesResponseSuccess']
> = {
  restaurantAvailability?: Resolver<
    Maybe<ResolversTypes['RestaurantAvailabilityInfo']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type BankingConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BankingConnection'] = ResolversParentTypes['BankingConnection']
> = {
  connectionId?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type BlockConfigResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BlockConfig'] = ResolversParentTypes['BlockConfig']
> = {
  blockedReason?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  hostReservationsBlocked?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  onlineReservationsBlocked?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  onlineWaitlistBlocked?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type BlockConfigsResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BlockConfigsResponse'] = ResolversParentTypes['BlockConfigsResponse']
> = {
  __resolveType: TypeResolveFn<'BlockConfigsSuccess', ParentType, ContextType>
}

export type BlockConfigsSuccessResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BlockConfigsSuccess'] = ResolversParentTypes['BlockConfigsSuccess']
> = {
  blockConfigs?: Resolver<
    Array<ResolversTypes['BlockedDate']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type BlockedDateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BlockedDate'] = ResolversParentTypes['BlockedDate']
> = {
  block?: Resolver<ResolversTypes['BlockConfig'], ParentType, ContextType>
  date?: Resolver<ResolversTypes['Date'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type BookingResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Booking'] = ResolversParentTypes['Booking']
> = {
  actualEndTime?: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >
  actualStartTime?: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >
  arrivedTime?: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >
  bookingNotes?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  bookingSource?: Resolver<
    Maybe<ResolversTypes['BookingSource']>,
    ParentType,
    ContextType
  >
  bookingStatus?: Resolver<
    ResolversTypes['BookingStatus'],
    ParentType,
    ContextType
  >
  bookingTables?: Resolver<
    Array<ResolversTypes['BookingTable']>,
    ParentType,
    ContextType
  >
  bookingType?: Resolver<ResolversTypes['BookingType'], ParentType, ContextType>
  cancellationSource?: Resolver<
    Maybe<ResolversTypes['CancellationSource']>,
    ParentType,
    ContextType
  >
  cancelledTime?: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  createdByAuthenticatedUser?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  depositAmount?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >
  depositBookableConfigSnapshot?: Resolver<
    Maybe<ResolversTypes['DepositBookableConfigSnapshot']>,
    ParentType,
    ContextType
  >
  depositCheckId?: Resolver<
    Maybe<ResolversTypes['ID']>,
    ParentType,
    ContextType
  >
  depositOrderId?: Resolver<
    Maybe<ResolversTypes['ID']>,
    ParentType,
    ContextType
  >
  depositPaymentExpirationDatetime?: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >
  depositRefundableCancellationDatetime?: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >
  depositStatus?: Resolver<
    Maybe<ResolversTypes['DepositStatus']>,
    ParentType,
    ContextType
  >
  dismissToHistory?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  expectedEndTime?: Resolver<
    ResolversTypes['DateTime'],
    ParentType,
    ContextType
  >
  expectedStartTime?: Resolver<
    ResolversTypes['DateTime'],
    ParentType,
    ContextType
  >
  externalServiceArea?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  externalServiceAreas?: Resolver<
    Maybe<Array<ResolversTypes['String']>>,
    ParentType,
    ContextType
  >
  firstNotified?: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >
  hasPinnedMessageThread?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  lastNotified?: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >
  mainGuest?: Resolver<Maybe<ResolversTypes['Guest']>, ParentType, ContextType>
  noShowTime?: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >
  notificationCount?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >
  partySize?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  requestedServiceAreaGroups?: Resolver<
    Array<ResolversTypes['BookingServiceAreaGroup']>,
    ParentType,
    ContextType
  >
  restaurant?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  server?: Resolver<
    Maybe<ResolversTypes['BookingsEmployee']>,
    ParentType,
    ContextType
  >
  serviceAreaGroup?: Resolver<
    Maybe<ResolversTypes['BookingServiceAreaGroup']>,
    ParentType,
    ContextType
  >
  serviceAreas?: Resolver<
    Array<ResolversTypes['BookingServiceArea']>,
    ParentType,
    ContextType
  >
  specialOccasion?: Resolver<
    ResolversTypes['SpecialOccasion'],
    ParentType,
    ContextType
  >
  unreadSmsMessageCount?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type BookingMutationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BookingMutation'] = ResolversParentTypes['BookingMutation']
> = {
  cancelBooking?: Resolver<
    ResolversTypes['CancelReservationResponse'],
    ParentType,
    ContextType,
    RequireFields<BookingMutationCancelBookingArgs, 'bookingGuid'>
  >
  createBlock?: Resolver<
    ResolversTypes['CreateBlockResponse'],
    ParentType,
    ContextType,
    RequireFields<BookingMutationCreateBlockArgs, 'businessDate' | 'request'>
  >
  createBooking?: Resolver<
    ResolversTypes['CreateReservationResponse'],
    ParentType,
    ContextType,
    RequireFields<BookingMutationCreateBookingArgs, 'request'>
  >
  createGuest?: Resolver<
    ResolversTypes['GuestCreationResponse'],
    ParentType,
    ContextType,
    RequireFields<BookingMutationCreateGuestArgs, 'request'>
  >
  updateBooking?: Resolver<
    ResolversTypes['UpdateReservationResponse'],
    ParentType,
    ContextType,
    RequireFields<BookingMutationUpdateBookingArgs, 'bookingGuid' | 'request'>
  >
  updateGuest?: Resolver<
    ResolversTypes['GuestUpdateResponse'],
    ParentType,
    ContextType,
    RequireFields<BookingMutationUpdateGuestArgs, 'guestGuid' | 'request'>
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type BookingNotFoundResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BookingNotFound'] = ResolversParentTypes['BookingNotFound']
> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  status?: Resolver<ResolversTypes['ErrorCode'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type BookingQueryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BookingQuery'] = ResolversParentTypes['BookingQuery']
> = {
  availabilities?: Resolver<
    ResolversTypes['AvailabilitiesResponse'],
    ParentType,
    ContextType,
    RequireFields<
      BookingQueryAvailabilitiesArgs,
      'endTime' | 'partySize' | 'startTime'
    >
  >
  blocks?: Resolver<
    ResolversTypes['BlockConfigsResponse'],
    ParentType,
    ContextType
  >
  bookingLimit?: Resolver<
    ResolversTypes['CurrentBookingUsageResponse'],
    ParentType,
    ContextType
  >
  bookings?: Resolver<
    ResolversTypes['AllBookingsResponse'],
    ParentType,
    ContextType,
    RequireFields<BookingQueryBookingsArgs, 'searchDate'>
  >
  guest?: Resolver<
    ResolversTypes['GuestListResponse'],
    ParentType,
    ContextType,
    RequireFields<BookingQueryGuestArgs, 'phoneNumber'>
  >
  mostRecentImportGuid?: Resolver<
    ResolversTypes['MostRecentImportGuidResponse'],
    ParentType,
    ContextType
  >
  restaurant?: Resolver<
    ResolversTypes['MgmtRestaurantInformationResponse'],
    ParentType,
    ContextType
  >
  serviceAreas?: Resolver<
    ResolversTypes['ServiceAreaResponse'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type BookingRequiresDepositDataResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BookingRequiresDepositData'] = ResolversParentTypes['BookingRequiresDepositData']
> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  status?: Resolver<ResolversTypes['ErrorCode'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type BookingServiceAreaResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BookingServiceArea'] = ResolversParentTypes['BookingServiceArea']
> = {
  externalServiceArea?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type BookingServiceAreaGroupResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BookingServiceAreaGroup'] = ResolversParentTypes['BookingServiceAreaGroup']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type BookingTableResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BookingTable'] = ResolversParentTypes['BookingTable']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type BookingsEmployeeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BookingsEmployee'] = ResolversParentTypes['BookingsEmployee']
> = {
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  serverColor?: Resolver<
    Maybe<ResolversTypes['ServerColor']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type BreakConfigResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BreakConfig'] = ResolversParentTypes['BreakConfig']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  multiLocationId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type BreakConfigDependencyFailureResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BreakConfigDependencyFailure'] = ResolversParentTypes['BreakConfigDependencyFailure']
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type BuildShiftFailureResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BuildShiftFailure'] = ResolversParentTypes['BuildShiftFailure']
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CanCustomerBillingInvoiceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CANCustomerBillingInvoice'] = ResolversParentTypes['CANCustomerBillingInvoice']
> = {
  __resolveReference?: ReferenceResolver<
    Maybe<ResolversTypes['CANCustomerBillingInvoice']>,
    { __typename: 'CANCustomerBillingInvoice' } & GraphQLRecursivePick<
      ParentType,
      { id: true; restaurant: { id: true } }
    >,
    ContextType
  >
  billingAddress?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  createdDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>
  deductions?: Resolver<
    ResolversTypes['CustomerBillingInvoiceDeductions'],
    ParentType,
    ContextType
  >
  dueDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  items?: Resolver<
    Array<ResolversTypes['CANCustomerBillingInvoiceItem']>,
    ParentType,
    ContextType
  >
  netsuiteId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  orderType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  restaurant?: Resolver<
    Maybe<ResolversTypes['Restaurant']>,
    ParentType,
    ContextType
  >
  salesforceId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  shipping?: Resolver<
    ResolversTypes['CustomerBillingInvoiceShipping'],
    ParentType,
    ContextType
  >
  shippingAddress?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  status?: Resolver<
    ResolversTypes['CustomerBillingInvoiceStatus'],
    ParentType,
    ContextType
  >
  terms?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  totalTaxDetails?: Resolver<
    ResolversTypes['CANCustomerBillingInvoiceTaxDetails'],
    ParentType,
    ContextType
  >
  totals?: Resolver<
    ResolversTypes['CustomerBillingInvoiceTotals'],
    ParentType,
    ContextType
  >
  transactionDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>
  type?: Resolver<
    ResolversTypes['CustomerBillingInvoiceType'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CanCustomerBillingInvoiceItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CANCustomerBillingInvoiceItem'] = ResolversParentTypes['CANCustomerBillingInvoiceItem']
> = {
  GSTHST?: Resolver<ResolversTypes['Money'], ParentType, ContextType>
  PST?: Resolver<ResolversTypes['Money'], ParentType, ContextType>
  amount?: Resolver<ResolversTypes['Money'], ParentType, ContextType>
  children?: Resolver<
    Array<ResolversTypes['CANCustomerBillingInvoiceItem']>,
    ParentType,
    ContextType
  >
  endDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  parentProduct?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  quantity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  rate?: Resolver<ResolversTypes['Money'], ParentType, ContextType>
  startDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CanCustomerBillingInvoiceTaxDetailsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CANCustomerBillingInvoiceTaxDetails'] = ResolversParentTypes['CANCustomerBillingInvoiceTaxDetails']
> = {
  GSTHST?: Resolver<ResolversTypes['Money'], ParentType, ContextType>
  PST?: Resolver<ResolversTypes['Money'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CancelReservationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CancelReservationResponse'] = ResolversParentTypes['CancelReservationResponse']
> = {
  __resolveType: TypeResolveFn<
    | 'BookingNotFound'
    | 'CancelReservationResponseSuccess'
    | 'ErrorRefundingDeposit'
    | 'InternalError'
    | 'RestaurantNotFound'
    | 'UnsupportedTransition',
    ParentType,
    ContextType
  >
}

export type CancelReservationResponseSuccessResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CancelReservationResponseSuccess'] = ResolversParentTypes['CancelReservationResponseSuccess']
> = {
  booking?: Resolver<ResolversTypes['Booking'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CapitalQueryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CapitalQuery'] = ResolversParentTypes['CapitalQuery']
> = {
  applications?: Resolver<
    Array<ResolversTypes['LoanApplicationForCustomer']>,
    ParentType,
    ContextType
  >
  financialProducts?: Resolver<
    Array<ResolversTypes['FinancialProductForCustomer']>,
    ParentType,
    ContextType
  >
  prequalifiedOffers?: Resolver<
    Array<ResolversTypes['PrequalifiedOfferForCustomer']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CashDrawerResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CashDrawer'] = ResolversParentTypes['CashDrawer']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  primaryPrinterId?: Resolver<
    Maybe<ResolversTypes['ID']>,
    ParentType,
    ContextType
  >
  resetType?: Resolver<ResolversTypes['ResetType'], ParentType, ContextType>
  secondaryPrinterId?: Resolver<
    Maybe<ResolversTypes['ID']>,
    ParentType,
    ContextType
  >
  startingBalance?: Resolver<ResolversTypes['Money'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CashDrawerConfigResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CashDrawerConfig'] = ResolversParentTypes['CashDrawerConfig']
> = {
  closeOutVarianceMax?: Resolver<
    Maybe<ResolversTypes['Money']>,
    ParentType,
    ContextType
  >
  closeOutVarianceWarning?: Resolver<
    Maybe<ResolversTypes['Money']>,
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CashFlowAccountResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CashFlowAccount'] = ResolversParentTypes['CashFlowAccount']
> = {
  account_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  account_last_four?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CashFlowAccountTransactionsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CashFlowAccountTransactions'] = ResolversParentTypes['CashFlowAccountTransactions']
> = {
  accountId?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  transactions?: Resolver<
    Array<ResolversTypes['CashFlowTransaction']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CashFlowSummaryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CashFlowSummary'] = ResolversParentTypes['CashFlowSummary']
> = {
  availableBalance?: Resolver<
    Maybe<ResolversTypes['Money']>,
    ParentType,
    ContextType
  >
  connectionCompanyId?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >
  connectionStatus?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  estimatedAvailableBalance?: Resolver<
    Maybe<ResolversTypes['Money']>,
    ParentType,
    ContextType
  >
  expenseForecast?: Resolver<
    Maybe<ResolversTypes['Money']>,
    ParentType,
    ContextType
  >
  revenueForecast?: Resolver<ResolversTypes['Money'], ParentType, ContextType>
  yyyymmdd?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CashFlowTransactionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CashFlowTransaction'] = ResolversParentTypes['CashFlowTransaction']
> = {
  amount?: Resolver<ResolversTypes['Money'], ParentType, ContextType>
  category?: Resolver<
    ResolversTypes['CashFlowTransactionCategory'],
    ParentType,
    ContextType
  >
  description?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  isExcludedFromPnl?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  isInternalTransfer?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  isReviewed?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  merchantName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  transactionDateYyyymmdd?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType
  >
  transactionId?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  transactionType?: Resolver<
    ResolversTypes['CashFlowTransactionType'],
    ParentType,
    ContextType
  >
  version?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CategoryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Category'] = ResolversParentTypes['Category']
> = {
  categorySpa?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  categoryType?: Resolver<
    ResolversTypes['CategoryType'],
    ParentType,
    ContextType
  >
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  groupId?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  headerSpa?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  icon?: Resolver<ResolversTypes['MenuItemIcon'], ParentType, ContextType>
  iconHighlightOverridePaths?: Resolver<
    Array<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  longName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  path?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  sections?: Resolver<Array<ResolversTypes['Section']>, ParentType, ContextType>
  taskPaths?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ChannelAvailabilityResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ChannelAvailability'] = ResolversParentTypes['ChannelAvailability']
> = {
  enabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  multiLocationId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  snoozedUntil?: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ChannelMenuEntityResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ChannelMenuEntity'] = ResolversParentTypes['ChannelMenuEntity']
> = {
  channel?: Resolver<
    Maybe<ResolversTypes['SavedChannel']>,
    ParentType,
    ContextType
  >
  channelMultiLocationId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  deleted?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  menu?: Resolver<Maybe<ResolversTypes['Menu']>, ParentType, ContextType>
  menuEntityMultiLocationId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  menuEntityType?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  menuPriceIncreaseType?: Resolver<
    Maybe<ResolversTypes['ChannelMenuPriceIncreaseType']>,
    ParentType,
    ContextType
  >
  menuPriceIncreaseValue?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >
  multiLocationId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  owner?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  ownerRestaurantSet?: Resolver<
    Maybe<ResolversTypes['RestaurantSet']>,
    ParentType,
    ContextType
  >
  target?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  targetRestaurantSet?: Resolver<
    Maybe<ResolversTypes['RestaurantSet']>,
    ParentType,
    ContextType
  >
  visible?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ChannelMenuPriceAdjustmentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ChannelMenuPriceAdjustment'] = ResolversParentTypes['ChannelMenuPriceAdjustment']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Money']>, ParentType, ContextType>
  channel?: Resolver<
    Maybe<ResolversTypes['SavedChannel']>,
    ParentType,
    ContextType
  >
  channelMultiLocationId?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  multiLocationId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  owner?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  ownerRestaurantSet?: Resolver<
    Maybe<ResolversTypes['RestaurantSet']>,
    ParentType,
    ContextType
  >
  percentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  revision?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  target?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  targetRestaurantSet?: Resolver<
    Maybe<ResolversTypes['RestaurantSet']>,
    ParentType,
    ContextType
  >
  type?: Resolver<
    ResolversTypes['ChannelMenuPriceIncreaseType'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ChannelMutationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ChannelMutation'] = ResolversParentTypes['ChannelMutation']
> = {
  createSavedChannelAvailabilities?: Resolver<
    Array<ResolversTypes['SavedChannelAvailability']>,
    ParentType,
    ContextType,
    RequireFields<
      ChannelMutationCreateSavedChannelAvailabilitiesArgs,
      'savedChannelAvailabilities'
    >
  >
  createSavedChannelAvailabilityForChannel?: Resolver<
    ResolversTypes['SavedChannel'],
    ParentType,
    ContextType,
    RequireFields<
      ChannelMutationCreateSavedChannelAvailabilityForChannelArgs,
      'savedChannel'
    >
  >
  createSavedChannels?: Resolver<
    Array<ResolversTypes['SavedChannel']>,
    ParentType,
    ContextType,
    RequireFields<ChannelMutationCreateSavedChannelsArgs, 'savedChannels'>
  >
  deleteSavedChannel?: Resolver<
    ResolversTypes['ID'],
    ParentType,
    ContextType,
    RequireFields<
      ChannelMutationDeleteSavedChannelArgs,
      'channelMultilocationId' | 'versionId'
    >
  >
  deleteSavedChannelAvailability?: Resolver<
    ResolversTypes['ID'],
    ParentType,
    ContextType,
    RequireFields<
      ChannelMutationDeleteSavedChannelAvailabilityArgs,
      'channelAvailabilityMultilocationId' | 'versionId'
    >
  >
  initializeSavedChannel?: Resolver<
    ResolversTypes['SavedChannel'],
    ParentType,
    ContextType,
    RequireFields<ChannelMutationInitializeSavedChannelArgs, 'channelTypeId'>
  >
  updateSavedChannel?: Resolver<
    ResolversTypes['SavedChannel'],
    ParentType,
    ContextType,
    RequireFields<ChannelMutationUpdateSavedChannelArgs, 'savedChannel'>
  >
  updateSavedChannelAvailability?: Resolver<
    ResolversTypes['SavedChannelAvailability'],
    ParentType,
    ContextType,
    RequireFields<
      ChannelMutationUpdateSavedChannelAvailabilityArgs,
      'savedChannelAvailability'
    >
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ChannelQueryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ChannelQuery'] = ResolversParentTypes['ChannelQuery']
> = {
  channelTypes?: Resolver<
    Array<ResolversTypes['ChannelType']>,
    ParentType,
    ContextType
  >
  publishedChannels?: Resolver<
    Array<ResolversTypes['PublishedChannel']>,
    ParentType,
    ContextType
  >
  savedChannelAvailabilities?: Resolver<
    ResolversTypes['PagedSavedChannelAvailability'],
    ParentType,
    ContextType,
    Partial<ChannelQuerySavedChannelAvailabilitiesArgs>
  >
  savedChannelAvailabilityById?: Resolver<
    ResolversTypes['SavedChannelAvailability'],
    ParentType,
    ContextType,
    RequireFields<
      ChannelQuerySavedChannelAvailabilityByIdArgs,
      'availabilityId'
    >
  >
  savedChannelAvailabilityByMultiLocationId?: Resolver<
    ResolversTypes['SavedChannelAvailability'],
    ParentType,
    ContextType,
    RequireFields<
      ChannelQuerySavedChannelAvailabilityByMultiLocationIdArgs,
      'multiLocationId'
    >
  >
  savedChannelByChannelType?: Resolver<
    ResolversTypes['SavedChannel'],
    ParentType,
    ContextType,
    RequireFields<ChannelQuerySavedChannelByChannelTypeArgs, 'channelTypeId'>
  >
  savedChannelByMultiLocationId?: Resolver<
    ResolversTypes['SavedChannel'],
    ParentType,
    ContextType,
    RequireFields<
      ChannelQuerySavedChannelByMultiLocationIdArgs,
      'channelMultiLocationId'
    >
  >
  savedChannels?: Resolver<
    Maybe<Array<ResolversTypes['SavedChannel']>>,
    ParentType,
    ContextType
  >
  searchSavedChannels?: Resolver<
    ResolversTypes['PagedSavedChannel'],
    ParentType,
    ContextType,
    Partial<ChannelQuerySearchSavedChannelsArgs>
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ChannelTypeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ChannelType'] = ResolversParentTypes['ChannelType']
> = {
  __resolveReference?: ReferenceResolver<
    Maybe<ResolversTypes['ChannelType']>,
    { __typename: 'ChannelType' } & GraphQLRecursivePick<
      ParentType,
      { partnerId: true }
    >,
    ContextType
  >
  category?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  clientId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  created?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>
  deleted?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  partner?: Resolver<Maybe<ResolversTypes['Partner']>, ParentType, ContextType>
  partnerId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  requestIdentifier?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >
  savedChannels?: Resolver<
    Array<ResolversTypes['SavedChannel']>,
    ParentType,
    ContextType
  >
  updated?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ChatMessageResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ChatMessage'] = ResolversParentTypes['ChatMessage']
> = {
  content?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  filters?: Resolver<
    Maybe<ResolversTypes['ChatMessageFilters']>,
    ParentType,
    ContextType
  >
  metadata?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  role?: Resolver<ResolversTypes['ChatRole'], ParentType, ContextType>
  sequence?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['ChatMessageType'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ChatMessageFiltersResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ChatMessageFilters'] = ResolversParentTypes['ChatMessageFilters']
> = {
  dateRange?: Resolver<
    ResolversTypes['ChatMessageFiltersDateRange'],
    ParentType,
    ContextType
  >
  daysOfWeek?: Resolver<
    Maybe<Array<ResolversTypes['String']>>,
    ParentType,
    ContextType
  >
  definedHours?: Resolver<
    Maybe<ResolversTypes['ChatMessageFiltersDefinedHours']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ChatMessageFiltersDateRangeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ChatMessageFiltersDateRange'] = ResolversParentTypes['ChatMessageFiltersDateRange']
> = {
  end?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  start?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ChatMessageFiltersDefinedHoursResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ChatMessageFiltersDefinedHours'] = ResolversParentTypes['ChatMessageFiltersDefinedHours']
> = {
  end?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  start?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ChatResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ChatResponse'] = ResolversParentTypes['ChatResponse']
> = {
  messages?: Resolver<
    Array<ResolversTypes['ChatMessage']>,
    ParentType,
    ContextType
  >
  sessionId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  timestamp?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CheckResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Check'] = ResolversParentTypes['Check']
> = {
  __resolveReference?: ReferenceResolver<
    Maybe<ResolversTypes['Check']>,
    { __typename: 'Check' } & GraphQLRecursivePick<
      ParentType,
      { id: true; orderId: true }
    >,
    ContextType
  >
  amount?: Resolver<Maybe<ResolversTypes['Money']>, ParentType, ContextType>
  amountDue?: Resolver<Maybe<ResolversTypes['Money']>, ParentType, ContextType>
  appliedDiscounts?: Resolver<
    Array<ResolversTypes['OrdersAppliedDiscount']>,
    ParentType,
    ContextType
  >
  appliedLoyaltyInfo?: Resolver<
    Maybe<ResolversTypes['OrdersAppliedLoyaltyInfo']>,
    ParentType,
    ContextType
  >
  appliedPreauthInfo?: Resolver<
    Maybe<ResolversTypes['OrdersAppliedPreauthInfo']>,
    ParentType,
    ContextType
  >
  closedDate?: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >
  deleted?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  displayNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  guestFeedback?: Resolver<
    Array<ResolversTypes['GuestFeedbackInfo']>,
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  openedDate?: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >
  orderId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  paymentStatus?: Resolver<
    Maybe<ResolversTypes['CheckPaymentStatus']>,
    ParentType,
    ContextType
  >
  payments?: Resolver<
    Array<ResolversTypes['OrdersPayment']>,
    ParentType,
    ContextType
  >
  selections?: Resolver<
    Array<ResolversTypes['OrdersSelection']>,
    ParentType,
    ContextType
  >
  tabName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  taxAmount?: Resolver<Maybe<ResolversTypes['Money']>, ParentType, ContextType>
  tipAmount?: Resolver<Maybe<ResolversTypes['Money']>, ParentType, ContextType>
  tipPercentage?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >
  totalAmount?: Resolver<
    Maybe<ResolversTypes['Money']>,
    ParentType,
    ContextType
  >
  voidedDate?: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ChecksWithRefundsCountResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ChecksWithRefundsCount'] = ResolversParentTypes['ChecksWithRefundsCount']
> = {
  grouped?: Resolver<
    Maybe<Array<ResolversTypes['GroupedChecksWithRefundsCount']>>,
    ParentType,
    ContextType,
    RequireFields<ChecksWithRefundsCountGroupedArgs, 'by'>
  >
  totals?: Resolver<ResolversTypes['IntegerMetric'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ChecksWithVoidsCountResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ChecksWithVoidsCount'] = ResolversParentTypes['ChecksWithVoidsCount']
> = {
  grouped?: Resolver<
    Maybe<Array<ResolversTypes['GroupedChecksWithVoidsCount']>>,
    ParentType,
    ContextType,
    RequireFields<ChecksWithVoidsCountGroupedArgs, 'by'>
  >
  totals?: Resolver<ResolversTypes['IntegerMetric'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ClockOutFailureResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClockOutFailure'] = ResolversParentTypes['ClockOutFailure']
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CloseOutReportingConfigResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CloseOutReportingConfig'] = ResolversParentTypes['CloseOutReportingConfig']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  shouldAuditRemovedItems?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  shouldAutoPrintClosedCashDrawerReportOnClose?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  shouldShowTipsBySalesCategory?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  tipSharingConfig?: Resolver<
    ResolversTypes['TipSharingConfig'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CloseOutReportingContentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CloseOutReportingContent'] = ResolversParentTypes['CloseOutReportingContent']
> = {
  closedCashDrawerReportSections?: Resolver<
    Array<ResolversTypes['ReportSection']>,
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  shiftReviewReportSections?: Resolver<
    Array<ResolversTypes['ReportSection']>,
    ParentType,
    ContextType
  >
  zReportSections?: Resolver<
    Array<ResolversTypes['ReportSection']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ComparisonResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Comparison'] = ResolversParentTypes['Comparison']
> = {
  analysis?: Resolver<ResolversTypes['Analysis'], ParentType, ContextType>
  percentageChange?: Resolver<
    ResolversTypes['Decimal'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ConfigCopyQueryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ConfigCopyQuery'] = ResolversParentTypes['ConfigCopyQuery']
> = {
  configEntities?: Resolver<
    Array<ResolversTypes['SavedConfig']>,
    ParentType,
    ContextType,
    RequireFields<ConfigCopyQueryConfigEntitiesArgs, 'restaurantSetId'>
  >
  configTypes?: Resolver<
    Array<ResolversTypes['ConfigType']>,
    ParentType,
    ContextType
  >
  copyConfigTask?: Resolver<
    Maybe<ResolversTypes['CopyConfigTask']>,
    ParentType,
    ContextType,
    RequireFields<ConfigCopyQueryCopyConfigTaskArgs, 'id'>
  >
  copyConfigTasks?: Resolver<
    ResolversTypes['CopyConfigTaskConnection'],
    ParentType,
    ContextType,
    RequireFields<ConfigCopyQueryCopyConfigTasksArgs, 'input'>
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ConfigFieldResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ConfigField'] = ResolversParentTypes['ConfigField']
> = {
  __resolveReference?: ReferenceResolver<
    Maybe<ResolversTypes['ConfigField']>,
    { __typename: 'ConfigField' } & GraphQLRecursivePick<
      ParentType,
      { id: true }
    >,
    ContextType
  >
  configType?: Resolver<
    Maybe<ResolversTypes['ConfigType']>,
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  navigationPage?: Resolver<
    Maybe<ResolversTypes['NavigationPage']>,
    ParentType,
    ContextType
  >
  navigationPages?: Resolver<
    Array<ResolversTypes['NavigationPage']>,
    ParentType,
    ContextType
  >
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ConfigLocationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ConfigLocation'] = ResolversParentTypes['ConfigLocation']
> = {
  categoryName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  icon?: Resolver<ResolversTypes['MenuItemIcon'], ParentType, ContextType>
  sectionName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  taskName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  taskPath?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ConfigMutationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ConfigMutation'] = ResolversParentTypes['ConfigMutation']
> = {
  archiveRestaurantGroup?: Resolver<
    ResolversTypes['RestaurantGroup'],
    ParentType,
    ContextType,
    RequireFields<ConfigMutationArchiveRestaurantGroupArgs, 'id'>
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ConfigQueryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ConfigQuery'] = ResolversParentTypes['ConfigQuery']
> = {
  availableConfigTypes?: Resolver<
    Array<ResolversTypes['ConfigType']>,
    ParentType,
    ContextType
  >
  copyConfigTasks?: Resolver<
    ResolversTypes['CopyConfigTaskConnection'],
    ParentType,
    ContextType,
    RequireFields<ConfigQueryCopyConfigTasksArgs, 'input'>
  >
  referencesCount?: Resolver<
    Array<ResolversTypes['ConfigReferencesCount']>,
    ParentType,
    ContextType,
    RequireFields<ConfigQueryReferencesCountArgs, 'restaurantSetId'>
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ConfigReferencesCountResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ConfigReferencesCount'] = ResolversParentTypes['ConfigReferencesCount']
> = {
  configType?: Resolver<ResolversTypes['ConfigType'], ParentType, ContextType>
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ConfigTypeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ConfigType'] = ResolversParentTypes['ConfigType']
> = {
  __resolveReference?: ReferenceResolver<
    Maybe<ResolversTypes['ConfigType']>,
    { __typename: 'ConfigType' } & GraphQLRecursivePick<
      ParentType,
      { id: true }
    >,
    ContextType
  >
  displayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  fields?: Resolver<
    Array<ResolversTypes['ConfigField']>,
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  navigationPage?: Resolver<
    Maybe<ResolversTypes['NavigationPage']>,
    ParentType,
    ContextType
  >
  partial?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  tags?: Resolver<
    Array<ResolversTypes['ConfigEntityTag']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ConfigValueDiffResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ConfigValueDiff'] = ResolversParentTypes['ConfigValueDiff']
> = {
  current?: Resolver<
    Array<ResolversTypes['ConfigValueDiffInformation']>,
    ParentType,
    ContextType
  >
  previous?: Resolver<
    Array<ResolversTypes['ConfigValueDiffInformation']>,
    ParentType,
    ContextType
  >
  type?: Resolver<
    ResolversTypes['PublishedConfigDiffType'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ConfigValueDiffInformationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ConfigValueDiffInformation'] = ResolversParentTypes['ConfigValueDiffInformation']
> = {
  displayValue?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  referenceId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CopyConfigMutationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CopyConfigMutation'] = ResolversParentTypes['CopyConfigMutation']
> = {
  createCopyConfigTask?: Resolver<
    ResolversTypes['CopyConfigTask'],
    ParentType,
    ContextType,
    RequireFields<CopyConfigMutationCreateCopyConfigTaskArgs, 'copyConfigTask'>
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CopyConfigTaskResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CopyConfigTask'] = ResolversParentTypes['CopyConfigTask']
> = {
  configTypes?: Resolver<
    Array<ResolversTypes['ConfigType']>,
    ParentType,
    ContextType
  >
  destinations?: Resolver<
    Array<ResolversTypes['RestaurantSet']>,
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  requestedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  source?: Resolver<
    ResolversTypes['SavedRestaurantLocation'],
    ParentType,
    ContextType
  >
  startedBy?: Resolver<ResolversTypes['User'], ParentType, ContextType>
  status?: Resolver<ResolversTypes['CopyConfigStatus'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CopyConfigTaskConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CopyConfigTaskConnection'] = ResolversParentTypes['CopyConfigTaskConnection']
> = {
  edges?: Resolver<
    Array<Maybe<ResolversTypes['CopyConfigTaskEdge']>>,
    ParentType,
    ContextType
  >
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CopyConfigTaskEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CopyConfigTaskEdge'] = ResolversParentTypes['CopyConfigTaskEdge']
> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  node?: Resolver<ResolversTypes['CopyConfigTask'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CorruptPageTokenErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CorruptPageTokenError'] = ResolversParentTypes['CorruptPageTokenError']
> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CourseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Course'] = ResolversParentTypes['Course']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  visible?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CreateBlockResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CreateBlockResponse'] = ResolversParentTypes['CreateBlockResponse']
> = {
  __resolveType: TypeResolveFn<
    'CreateBlockSuccess' | 'RestaurantNotFound',
    ParentType,
    ContextType
  >
}

export type CreateBlockSuccessResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CreateBlockSuccess'] = ResolversParentTypes['CreateBlockSuccess']
> = {
  blockConfigs?: Resolver<
    Array<ResolversTypes['BlockedDate']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CreateItemFeedbackConfigErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CreateItemFeedbackConfigError'] = ResolversParentTypes['CreateItemFeedbackConfigError']
> = {
  code?: Resolver<
    ResolversTypes['CreateItemFeedbackConfigErrorCode'],
    ParentType,
    ContextType
  >
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CreateItemFeedbackConfigResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CreateItemFeedbackConfigResponse'] = ResolversParentTypes['CreateItemFeedbackConfigResponse']
> = {
  __resolveType: TypeResolveFn<
    | 'CreateItemFeedbackConfigError'
    | 'ItemFeedbackConfig'
    | 'ItemFeedbackConfigAlreadyExists',
    ParentType,
    ContextType
  >
}

export type CreateMembershipResultResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CreateMembershipResult'] = ResolversParentTypes['CreateMembershipResult']
> = {
  cardGuid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CreateReservationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CreateReservationResponse'] = ResolversParentTypes['CreateReservationResponse']
> = {
  __resolveType: TypeResolveFn<
    | 'BookingRequiresDepositData'
    | 'CreateReservationResponseSuccess'
    | 'DepositAmountChanged'
    | 'DepositRuleNotMatching'
    | 'InvalidEmail'
    | 'InvalidGuest'
    | 'InvalidPhoneNumber'
    | 'RestaurantNotFound'
    | 'ServiceAreaNotFound'
    | 'TableBlocked'
    | 'TableNotFound'
    | 'UnableToCreateDepositOrder'
    | 'UnableToCreateGuest'
    | 'UnableToSendSMS',
    ParentType,
    ContextType
  >
}

export type CreateReservationResponseSuccessResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CreateReservationResponseSuccess'] = ResolversParentTypes['CreateReservationResponseSuccess']
> = {
  booking?: Resolver<ResolversTypes['Booking'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CreateShiftResultResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CreateShiftResult'] = ResolversParentTypes['CreateShiftResult']
> = {
  __resolveType: TypeResolveFn<
    | 'BreakConfigDependencyFailure'
    | 'BuildShiftFailure'
    | 'EmployeeRepositoryDependencyFailure'
    | 'EntitlementsRepositoryDependencyFailure'
    | 'FinishedEmployeeShift'
    | 'InProgressEmployeeShift'
    | 'InvalidBreakConfig'
    | 'InvalidBreaks'
    | 'InvalidJob'
    | 'InvalidUpdatedTime'
    | 'JobsRepositoryDependencyFailure'
    | 'LoadShiftsFailure'
    | 'LocateShiftFailure'
    | 'MissingJob'
    | 'MissingTimeClock'
    | 'OverlappingBreaks'
    | 'OverlappingShiftExists'
    | 'PublishRepositoryDependencyFailure'
    | 'ReadFailure'
    | 'RestaurantRepositoryDependencyFailure'
    | 'SaveFailure'
    | 'ShiftCheckedResultRepositoryDependencyFailure'
    | 'ShiftNotFound'
    | 'Unauthorized'
    | 'UnknownError',
    ParentType,
    ContextType
  >
}

export type CreateToastPayConfigErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CreateToastPayConfigError'] = ResolversParentTypes['CreateToastPayConfigError']
> = {
  code?: Resolver<
    ResolversTypes['CreateToastPayConfigErrorCode'],
    ParentType,
    ContextType
  >
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CreateToastPayConfigResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CreateToastPayConfigResponse'] = ResolversParentTypes['CreateToastPayConfigResponse']
> = {
  __resolveType: TypeResolveFn<
    'CreateToastPayConfigError' | 'ToastPayConfig',
    ParentType,
    ContextType
  >
}

export type CurrentBookingUsageResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CurrentBookingUsageResponse'] = ResolversParentTypes['CurrentBookingUsageResponse']
> = {
  __resolveType: TypeResolveFn<
    'CurrentBookingUsageResponseSuccess' | 'RestaurantNotFound',
    ParentType,
    ContextType
  >
}

export type CurrentBookingUsageResponseSuccessResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CurrentBookingUsageResponseSuccess'] = ResolversParentTypes['CurrentBookingUsageResponseSuccess']
> = {
  result?: Resolver<
    ResolversTypes['RestaurantBookingLimit'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CustomMenuItemUpsellResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CustomMenuItemUpsell'] = ResolversParentTypes['CustomMenuItemUpsell']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  managementGroup?: Resolver<
    ResolversTypes['ManagementGroup'],
    ParentType,
    ContextType
  >
  menuGroupGuids?: Resolver<
    Array<ResolversTypes['ID']>,
    ParentType,
    ContextType
  >
  menuGroupMlids?: Resolver<
    Array<ResolversTypes['ID']>,
    ParentType,
    ContextType
  >
  menuItemGuid?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  menuItemMlid?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  ordinal?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  targets?: Resolver<
    Array<ResolversTypes['RestaurantSet']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CustomerBillingCreditMemoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CustomerBillingCreditMemo'] = ResolversParentTypes['CustomerBillingCreditMemo']
> = {
  __resolveReference?: ReferenceResolver<
    Maybe<ResolversTypes['CustomerBillingCreditMemo']>,
    { __typename: 'CustomerBillingCreditMemo' } & GraphQLRecursivePick<
      ParentType,
      { id: true; restaurant: { id: true } }
    >,
    ContextType
  >
  appliedTo?: Resolver<
    Array<ResolversTypes['CustomerBillingCreditMemoApplication']>,
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  lastModifiedDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>
  netsuiteId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  reason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  restaurant?: Resolver<ResolversTypes['Restaurant'], ParentType, ContextType>
  status?: Resolver<
    ResolversTypes['CustomerBillingCreditMemoStatus'],
    ParentType,
    ContextType
  >
  total?: Resolver<ResolversTypes['Money'], ParentType, ContextType>
  transactionDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CustomerBillingCreditMemoApplicationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CustomerBillingCreditMemoApplication'] = ResolversParentTypes['CustomerBillingCreditMemoApplication']
> = {
  appliedAmount?: Resolver<ResolversTypes['Money'], ParentType, ContextType>
  creditMemo?: Resolver<
    ResolversTypes['CustomerBillingCreditMemo'],
    ParentType,
    ContextType
  >
  invoice?: Resolver<
    ResolversTypes['CustomerBillingInvoice'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CustomerBillingInvoiceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CustomerBillingInvoice'] = ResolversParentTypes['CustomerBillingInvoice']
> = {
  __resolveType: TypeResolveFn<
    | 'CANCustomerBillingInvoice'
    | 'EPCustomerBillingInvoice'
    | 'EmptyCustomerBillingInvoice'
    | 'IRLCustomerBillingInvoice'
    | 'UKCustomerBillingInvoice'
    | 'USCustomerBillingInvoice',
    ParentType,
    ContextType
  >
  billingAddress?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  createdDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>
  dueDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  items?: Resolver<
    Maybe<Array<ResolversTypes['CustomerBillingInvoiceItem']>>,
    ParentType,
    ContextType
  >
  netsuiteId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  orderType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  restaurant?: Resolver<
    Maybe<ResolversTypes['Restaurant']>,
    ParentType,
    ContextType
  >
  salesforceId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  shippingAddress?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  status?: Resolver<
    Maybe<ResolversTypes['CustomerBillingInvoiceStatus']>,
    ParentType,
    ContextType
  >
  terms?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  totals?: Resolver<
    Maybe<ResolversTypes['CustomerBillingInvoiceTotals']>,
    ParentType,
    ContextType
  >
  transactionDate?: Resolver<
    Maybe<ResolversTypes['Date']>,
    ParentType,
    ContextType
  >
  type?: Resolver<
    Maybe<ResolversTypes['CustomerBillingInvoiceType']>,
    ParentType,
    ContextType
  >
}

export type CustomerBillingInvoiceDeductionsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CustomerBillingInvoiceDeductions'] = ResolversParentTypes['CustomerBillingInvoiceDeductions']
> = {
  creditMemosApplied?: Resolver<
    Array<ResolversTypes['CustomerBillingCreditMemoApplication']>,
    ParentType,
    ContextType
  >
  creditsApplied?: Resolver<ResolversTypes['Money'], ParentType, ContextType>
  depositsApplied?: Resolver<ResolversTypes['Money'], ParentType, ContextType>
  paymentsApplied?: Resolver<ResolversTypes['Money'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CustomerBillingInvoiceItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CustomerBillingInvoiceItem'] = ResolversParentTypes['CustomerBillingInvoiceItem']
> = {
  __resolveType: TypeResolveFn<
    | 'CANCustomerBillingInvoiceItem'
    | 'EPCustomerBillingInvoiceItem'
    | 'IRLCustomerBillingInvoiceItem'
    | 'UKCustomerBillingInvoiceItem'
    | 'USCustomerBillingInvoiceItem',
    ParentType,
    ContextType
  >
  amount?: Resolver<ResolversTypes['Money'], ParentType, ContextType>
  children?: Resolver<
    Array<ResolversTypes['CustomerBillingInvoiceItem']>,
    ParentType,
    ContextType
  >
  endDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  quantity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  rate?: Resolver<ResolversTypes['Money'], ParentType, ContextType>
  startDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>
}

export type CustomerBillingInvoiceShippingResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CustomerBillingInvoiceShipping'] = ResolversParentTypes['CustomerBillingInvoiceShipping']
> = {
  taxAmount?: Resolver<ResolversTypes['Money'], ParentType, ContextType>
  taxRate?: Resolver<ResolversTypes['Money'], ParentType, ContextType>
  total?: Resolver<ResolversTypes['Money'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CustomerBillingInvoiceTotalsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CustomerBillingInvoiceTotals'] = ResolversParentTypes['CustomerBillingInvoiceTotals']
> = {
  subtotal?: Resolver<ResolversTypes['Money'], ParentType, ContextType>
  taxAmount?: Resolver<ResolversTypes['Money'], ParentType, ContextType>
  total?: Resolver<ResolversTypes['Money'], ParentType, ContextType>
  totalDue?: Resolver<ResolversTypes['Money'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CustomerBillingInvoicesPaginatedResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CustomerBillingInvoicesPaginatedResponse'] = ResolversParentTypes['CustomerBillingInvoicesPaginatedResponse']
> = {
  currentPage?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  hasNextPage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  invoices?: Resolver<
    Array<ResolversTypes['CustomerBillingInvoice']>,
    ParentType,
    ContextType
  >
  nextPageToken?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CustomerBillingPaginatedResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CustomerBillingPaginatedResponse'] = ResolversParentTypes['CustomerBillingPaginatedResponse']
> = {
  __resolveType: TypeResolveFn<
    'CustomerBillingInvoicesPaginatedResponse',
    ParentType,
    ContextType
  >
  currentPage?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  hasNextPage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  nextPageToken?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
}

export type CustomerBillingQueryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CustomerBillingQuery'] = ResolversParentTypes['CustomerBillingQuery']
> = {
  invoice?: Resolver<
    Maybe<ResolversTypes['CustomerBillingInvoice']>,
    ParentType,
    ContextType,
    RequireFields<CustomerBillingQueryInvoiceArgs, 'id'>
  >
  invoices?: Resolver<
    ResolversTypes['CustomerBillingInvoicesPaginatedResponse'],
    ParentType,
    ContextType,
    Partial<CustomerBillingQueryInvoicesArgs>
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CustomerInvoiceDetailsReportResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CustomerInvoiceDetailsReport'] = ResolversParentTypes['CustomerInvoiceDetailsReport']
> = {
  billingAddress?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  creditMemos?: Resolver<
    Array<ResolversTypes['CustomerInvoiceDetailsReportCreditMemo']>,
    ParentType,
    ContextType
  >
  creditMemosApplied?: Resolver<
    ResolversTypes['Money'],
    ParentType,
    ContextType
  >
  depositsApplied?: Resolver<ResolversTypes['Money'], ParentType, ContextType>
  downSellItems?: Resolver<
    Array<ResolversTypes['CustomerInvoiceDetailsReportServicePeriodItems']>,
    ParentType,
    ContextType
  >
  hasJsonData?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  invoiceCreatedDate?: Resolver<
    ResolversTypes['DateTime'],
    ParentType,
    ContextType
  >
  invoiceDueDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  invoiceName?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  invoiceTotal?: Resolver<ResolversTypes['Money'], ParentType, ContextType>
  invoiceType?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  items?: Resolver<
    Array<ResolversTypes['CustomerInvoiceDetailsReportServicePeriodItems']>,
    ParentType,
    ContextType
  >
  netsuiteInvoiceId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  otherInvoiceDeductions?: Resolver<
    ResolversTypes['Money'],
    ParentType,
    ContextType
  >
  paymentsApplied?: Resolver<ResolversTypes['Money'], ParentType, ContextType>
  salesforceInvoiceId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  shippingAddress?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  shippingTotal?: Resolver<ResolversTypes['Money'], ParentType, ContextType>
  subtotal?: Resolver<ResolversTypes['Money'], ParentType, ContextType>
  taxTotal?: Resolver<ResolversTypes['Money'], ParentType, ContextType>
  terms?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  toastGuid?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  totalDue?: Resolver<ResolversTypes['Money'], ParentType, ContextType>
  transactionDate?: Resolver<
    ResolversTypes['DateTime'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CustomerInvoiceDetailsReportCreditMemoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CustomerInvoiceDetailsReportCreditMemo'] = ResolversParentTypes['CustomerInvoiceDetailsReportCreditMemo']
> = {
  amount?: Resolver<ResolversTypes['Money'], ParentType, ContextType>
  createdDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CustomerInvoiceDetailsReportServicePeriodItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CustomerInvoiceDetailsReportServicePeriodItem'] = ResolversParentTypes['CustomerInvoiceDetailsReportServicePeriodItem']
> = {
  amount?: Resolver<ResolversTypes['Money'], ParentType, ContextType>
  children?: Resolver<
    Array<ResolversTypes['CustomerInvoiceDetailsReportServicePeriodItem']>,
    ParentType,
    ContextType
  >
  itemType?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  productFamily?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  productName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  quantity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  rate?: Resolver<ResolversTypes['Money'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CustomerInvoiceDetailsReportServicePeriodItemsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CustomerInvoiceDetailsReportServicePeriodItems'] = ResolversParentTypes['CustomerInvoiceDetailsReportServicePeriodItems']
> = {
  items?: Resolver<
    Array<ResolversTypes['CustomerInvoiceDetailsReportServicePeriodItem']>,
    ParentType,
    ContextType
  >
  serviceDateEnd?: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >
  serviceDateStart?: Resolver<
    ResolversTypes['DateTime'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CustomerInvoiceListReportResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CustomerInvoiceListReport'] = ResolversParentTypes['CustomerInvoiceListReport']
> = {
  endDateInCurrentPeriod?: Resolver<
    ResolversTypes['DateTime'],
    ParentType,
    ContextType
  >
  invoicesInCurrentPeriod?: Resolver<
    Maybe<Array<ResolversTypes['CustomerInvoiceListReportItem']>>,
    ParentType,
    ContextType
  >
  previousInvoices?: Resolver<
    Maybe<Array<ResolversTypes['CustomerInvoiceListReportItem']>>,
    ParentType,
    ContextType
  >
  startDateInCurrentPeriod?: Resolver<
    ResolversTypes['DateTime'],
    ParentType,
    ContextType
  >
  totalAmountsInCurrentPeriod?: Resolver<
    Maybe<ResolversTypes['CustomerInvoiceListTotalAmounts']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CustomerInvoiceListReportItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CustomerInvoiceListReportItem'] = ResolversParentTypes['CustomerInvoiceListReportItem']
> = {
  invoiceCreatedDate?: Resolver<
    ResolversTypes['DateTime'],
    ParentType,
    ContextType
  >
  invoiceDetailsAreAvailable?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  invoiceDueDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  invoiceName?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  invoiceStatus?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  invoiceTotal?: Resolver<ResolversTypes['Money'], ParentType, ContextType>
  invoiceTotalPaid?: Resolver<ResolversTypes['Money'], ParentType, ContextType>
  invoiceTransactionDate?: Resolver<
    ResolversTypes['DateTime'],
    ParentType,
    ContextType
  >
  invoiceType?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  netsuiteTransactionId?: Resolver<
    ResolversTypes['ID'],
    ParentType,
    ContextType
  >
  salesforceInvoiceId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  toastGuid?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CustomerInvoiceListTotalAmountsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CustomerInvoiceListTotalAmounts'] = ResolversParentTypes['CustomerInvoiceListTotalAmounts']
> = {
  totalCreditsApplied?: Resolver<
    ResolversTypes['Money'],
    ParentType,
    ContextType
  >
  totalHardwarePurchased?: Resolver<
    ResolversTypes['Money'],
    ParentType,
    ContextType
  >
  totalSoftwareFeesCharged?: Resolver<
    ResolversTypes['Money'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CustomerInvoiceQueryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CustomerInvoiceQuery'] = ResolversParentTypes['CustomerInvoiceQuery']
> = {
  invoiceDetailsReport?: Resolver<
    Maybe<ResolversTypes['CustomerInvoiceDetailsReport']>,
    ParentType,
    ContextType,
    RequireFields<
      CustomerInvoiceQueryInvoiceDetailsReportArgs,
      'invoiceName' | 'restaurantId'
    >
  >
  invoiceListReport?: Resolver<
    Maybe<ResolversTypes['CustomerInvoiceListReport']>,
    ParentType,
    ContextType,
    RequireFields<CustomerInvoiceQueryInvoiceListReportArgs, 'restaurantId'>
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CustomerTasksMutationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CustomerTasksMutation'] = ResolversParentTypes['CustomerTasksMutation']
> = {
  activateTaskTemplate?: Resolver<
    ResolversTypes['CustomerTasksTask'],
    ParentType,
    ContextType,
    RequireFields<
      CustomerTasksMutationActivateTaskTemplateArgs,
      'entityIdentifier' | 'metadata' | 'taskKey'
    >
  >
  activateWorkflowTemplate?: Resolver<
    ResolversTypes['CustomerTasksWorkflow'],
    ParentType,
    ContextType,
    RequireFields<
      CustomerTasksMutationActivateWorkflowTemplateArgs,
      'entityIdentifier' | 'workflowKey'
    >
  >
  bulkActivateWorkflows?: Resolver<
    Array<ResolversTypes['ID']>,
    ParentType,
    ContextType,
    RequireFields<
      CustomerTasksMutationBulkActivateWorkflowsArgs,
      'entityIdentifiers' | 'workflowKey'
    >
  >
  bulkDeactivateWorkflows?: Resolver<
    Array<ResolversTypes['ID']>,
    ParentType,
    ContextType,
    RequireFields<
      CustomerTasksMutationBulkDeactivateWorkflowsArgs,
      'entityIdentifiers' | 'workflowKey'
    >
  >
  createWorkflowTemplate?: Resolver<
    ResolversTypes['CustomerTasksWorkflowTemplate'],
    ParentType,
    ContextType,
    RequireFields<
      CustomerTasksMutationCreateWorkflowTemplateArgs,
      'template' | 'workflowKey'
    >
  >
  resetTask?: Resolver<
    ResolversTypes['CustomerTasksTask'],
    ParentType,
    ContextType,
    RequireFields<CustomerTasksMutationResetTaskArgs, 'metadata' | 'taskKey'>
  >
  updateTask?: Resolver<
    ResolversTypes['CustomerTasksTask'],
    ParentType,
    ContextType,
    RequireFields<
      CustomerTasksMutationUpdateTaskArgs,
      'metadata' | 'status' | 'taskKey'
    >
  >
  updateWorkflowTemplate?: Resolver<
    ResolversTypes['CustomerTasksWorkflowTemplate'],
    ParentType,
    ContextType,
    RequireFields<
      CustomerTasksMutationUpdateWorkflowTemplateArgs,
      'taskKeys' | 'workflowKey'
    >
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CustomerTasksQueryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CustomerTasksQuery'] = ResolversParentTypes['CustomerTasksQuery']
> = {
  task?: Resolver<
    ResolversTypes['CustomerTasksTask'],
    ParentType,
    ContextType,
    RequireFields<CustomerTasksQueryTaskArgs, 'taskKey'>
  >
  taskProgress?: Resolver<
    ResolversTypes['CustomerTasksTaskTimeline'],
    ParentType,
    ContextType,
    RequireFields<CustomerTasksQueryTaskProgressArgs, 'taskKey'>
  >
  taskTemplates?: Resolver<
    Array<ResolversTypes['CustomerTasksTaskTemplate']>,
    ParentType,
    ContextType
  >
  workflow?: Resolver<
    Maybe<ResolversTypes['CustomerTasksWorkflow']>,
    ParentType,
    ContextType,
    RequireFields<CustomerTasksQueryWorkflowArgs, 'workflowKey'>
  >
  workflowTemplate?: Resolver<
    ResolversTypes['CustomerTasksWorkflowTemplate'],
    ParentType,
    ContextType,
    RequireFields<CustomerTasksQueryWorkflowTemplateArgs, 'workflowKey'>
  >
  workflowTemplates?: Resolver<
    Array<ResolversTypes['CustomerTasksWorkflowTemplate']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CustomerTasksTaskResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CustomerTasksTask'] = ResolversParentTypes['CustomerTasksTask']
> = {
  dateCreated?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  externalEntityId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  history?: Resolver<
    Array<ResolversTypes['CustomerTasksTaskEvent']>,
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  lastUpdated?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  status?: Resolver<
    ResolversTypes['CustomerTasksStatus'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CustomerTasksTaskEventResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CustomerTasksTaskEvent'] = ResolversParentTypes['CustomerTasksTaskEvent']
> = {
  dateOccurred?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  entityId?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  eventKey?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  updatedStatus?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>
  userId?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CustomerTasksTaskTemplateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CustomerTasksTaskTemplate'] = ResolversParentTypes['CustomerTasksTaskTemplate']
> = {
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CustomerTasksTaskTimelineResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CustomerTasksTaskTimeline'] = ResolversParentTypes['CustomerTasksTaskTimeline']
> = {
  history?: Resolver<
    Array<ResolversTypes['CustomerTasksTaskEvent']>,
    ParentType,
    ContextType
  >
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CustomerTasksWorkflowResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CustomerTasksWorkflow'] = ResolversParentTypes['CustomerTasksWorkflow']
> = {
  active?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  externalEntityId?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  scope?: Resolver<
    Maybe<ResolversTypes['CustomerTasksWorkflowScope']>,
    ParentType,
    ContextType
  >
  tasks?: Resolver<
    Array<ResolversTypes['CustomerTasksTask']>,
    ParentType,
    ContextType
  >
  workflowTemplateId?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CustomerTasksWorkflowTemplateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CustomerTasksWorkflowTemplate'] = ResolversParentTypes['CustomerTasksWorkflowTemplate']
> = {
  dateCreated?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  scope?: Resolver<
    ResolversTypes['CustomerTasksWorkflowScope'],
    ParentType,
    ContextType
  >
  tasks?: Resolver<
    Array<ResolversTypes['CustomerTasksTaskTemplate']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CustomerUserAuthorizationQueryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CustomerUserAuthorizationQuery'] = ResolversParentTypes['CustomerUserAuthorizationQuery']
> = {
  customerUsersWithAccess?: Resolver<
    Maybe<Array<ResolversTypes['RenderedAuthorizationDecision']>>,
    ParentType,
    ContextType,
    RequireFields<
      CustomerUserAuthorizationQueryCustomerUsersWithAccessArgs,
      'legacyCustomerAuthorization' | 'target'
    >
  >
  customerUsersWithAccessPaged?: Resolver<
    ResolversTypes['PagedRenderedAuthorizationDecision'],
    ParentType,
    ContextType,
    RequireFields<
      CustomerUserAuthorizationQueryCustomerUsersWithAccessPagedArgs,
      'legacyCustomerAuthorization' | 'page' | 'target'
    >
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DsModelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DSModel'] = ResolversParentTypes['DSModel']
> = {
  active?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  currentPrepTime?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DailyMetricsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DailyMetrics'] = ResolversParentTypes['DailyMetrics']
> = {
  laborCostAsPercentageOfNetSales?: Resolver<
    Maybe<ResolversTypes['PercentageMetric']>,
    ParentType,
    ContextType
  >
  netSales?: Resolver<ResolversTypes['MoneyMetric'], ParentType, ContextType>
  netSalesPerLaborHour?: Resolver<
    Maybe<ResolversTypes['MoneyMetric']>,
    ParentType,
    ContextType
  >
  restaurant?: Resolver<
    ResolversTypes['SavedRestaurantLocation'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DailyNetSalesMetricResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DailyNetSalesMetric'] = ResolversParentTypes['DailyNetSalesMetric']
> = {
  businessDay?: Resolver<ResolversTypes['Date'], ParentType, ContextType>
  comparisonBusinessDay?: Resolver<
    Maybe<ResolversTypes['Date']>,
    ParentType,
    ContextType
  >
  netSales?: Resolver<ResolversTypes['MoneyMetric'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DataExtensionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DataExtension'] = ResolversParentTypes['DataExtension']
> = {
  __resolveType: TypeResolveFn<
    | 'AlternatePaymentDataExtension'
    | 'RevenueCenterDataExtension'
    | 'SalesCategoryDataExtension'
    | 'ServiceDataExtension'
    | 'StaticPaymentDataExtension'
    | 'TaxRateDataExtension',
    ParentType,
    ContextType
  >
  type?: Resolver<ResolversTypes['DataExtensionType'], ParentType, ContextType>
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>
}

export type DataExtensionMutationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DataExtensionMutation'] = ResolversParentTypes['DataExtensionMutation']
> = {
  createDataExtensionSet?: Resolver<
    ResolversTypes['SavedDataExtensionSet'],
    ParentType,
    ContextType,
    RequireFields<DataExtensionMutationCreateDataExtensionSetArgs, 'input'>
  >
  updateDataExtensionSet?: Resolver<
    ResolversTypes['SavedDataExtensionSet'],
    ParentType,
    ContextType,
    RequireFields<DataExtensionMutationUpdateDataExtensionSetArgs, 'input'>
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DataExtensionQueryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DataExtensionQuery'] = ResolversParentTypes['DataExtensionQuery']
> = {
  publishedDataExtensionSet?: Resolver<
    ResolversTypes['PublishedDataExtensionSet'],
    ParentType,
    ContextType,
    RequireFields<DataExtensionQueryPublishedDataExtensionSetArgs, 'id'>
  >
  resolvedDataExtensionSet?: Resolver<
    ResolversTypes['ResolvedDataExtensionSet'],
    ParentType,
    ContextType,
    RequireFields<DataExtensionQueryResolvedDataExtensionSetArgs, 'id'>
  >
  savedDataExtensionSet?: Resolver<
    ResolversTypes['SavedDataExtensionSet'],
    ParentType,
    ContextType,
    RequireFields<DataExtensionQuerySavedDataExtensionSetArgs, 'id'>
  >
  savedDataExtensionSets?: Resolver<
    Array<ResolversTypes['SavedDataExtensionSet']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DataExtensionSetOverrideResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DataExtensionSetOverride'] = ResolversParentTypes['DataExtensionSetOverride']
> = {
  fields?: Resolver<
    Array<ResolversTypes['ConfigField']>,
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  payments?: Resolver<
    Maybe<Array<ResolversTypes['PaymentDataExtension']>>,
    ParentType,
    ContextType
  >
  revenueCenters?: Resolver<
    Maybe<Array<ResolversTypes['RevenueCenterDataExtension']>>,
    ParentType,
    ContextType
  >
  revision?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  salesCategories?: Resolver<
    Maybe<Array<ResolversTypes['SalesCategoryDataExtension']>>,
    ParentType,
    ContextType
  >
  services?: Resolver<
    Maybe<Array<ResolversTypes['ServiceDataExtension']>>,
    ParentType,
    ContextType
  >
  target?: Resolver<ResolversTypes['RestaurantSet'], ParentType, ContextType>
  taxRates?: Resolver<
    Maybe<Array<ResolversTypes['TaxRateDataExtension']>>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export interface DateScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['Date'], any> {
  name: 'Date'
}

export type DateRangeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DateRange'] = ResolversParentTypes['DateRange']
> = {
  endDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>
  startDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DateRangeMetricsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DateRangeMetrics'] = ResolversParentTypes['DateRangeMetrics']
> = {
  netSales?: Resolver<
    Maybe<ResolversTypes['NetSalesDateRangeMetrics']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export interface DateTimeScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['DateTime'], any> {
  name: 'DateTime'
}

export type DayOperatingScheduleResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DayOperatingSchedule'] = ResolversParentTypes['DayOperatingSchedule']
> = {
  closeTime?: Resolver<
    Maybe<ResolversTypes['LocalTime']>,
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  openTime?: Resolver<
    Maybe<ResolversTypes['LocalTime']>,
    ParentType,
    ContextType
  >
  servicePeriods?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['OperatingScheduleServicePeriod']>>>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DayPeriodResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DayPeriod'] = ResolversParentTypes['DayPeriod']
> = {
  day?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  timeRanges?: Resolver<
    Array<ResolversTypes['LocalTimeRange']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export interface DecimalScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['Decimal'], any> {
  name: 'Decimal'
}

export type DecimalMetricResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DecimalMetric'] = ResolversParentTypes['DecimalMetric']
> = {
  comparison?: Resolver<
    Maybe<ResolversTypes['Decimal']>,
    ParentType,
    ContextType
  >
  current?: Resolver<ResolversTypes['Decimal'], ParentType, ContextType>
  endTime?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  startTime?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  variation?: Resolver<
    Maybe<ResolversTypes['Comparison']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DeclareCashTipsConfigResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DeclareCashTipsConfig'] = ResolversParentTypes['DeclareCashTipsConfig']
> = {
  minimumTipPercentage?: Resolver<
    ResolversTypes['Float'],
    ParentType,
    ContextType
  >
  shouldAllowNegativeDeclaredTips?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  shouldRequireDeclareCashTips?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DefaultRateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DefaultRate'] = ResolversParentTypes['DefaultRate']
> = {
  basis?: Resolver<ResolversTypes['Basis'], ParentType, ContextType>
  money?: Resolver<ResolversTypes['Money'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DeleteUserQuickActionResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DeleteUserQuickActionResponse'] = ResolversParentTypes['DeleteUserQuickActionResponse']
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DeletedEmploymentStatusResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DeletedEmploymentStatus'] = ResolversParentTypes['DeletedEmploymentStatus']
> = {
  adjustedHireDate?: Resolver<
    Maybe<ResolversTypes['Date']>,
    ParentType,
    ContextType
  >
  hireDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DeletedShiftResultResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DeletedShiftResult'] = ResolversParentTypes['DeletedShiftResult']
> = {
  __resolveType: TypeResolveFn<
    | 'FinishedEmployeeShift'
    | 'InProgressEmployeeShift'
    | 'LoadShiftsFailure'
    | 'LocateShiftFailure'
    | 'SaveFailure'
    | 'ShiftNotFound'
    | 'Unauthorized',
    ParentType,
    ContextType
  >
}

export type DemoEmploymentStatusResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DemoEmploymentStatus'] = ResolversParentTypes['DemoEmploymentStatus']
> = {
  adjustedHireDate?: Resolver<
    Maybe<ResolversTypes['Date']>,
    ParentType,
    ContextType
  >
  hireDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DepositAmountChangedResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DepositAmountChanged'] = ResolversParentTypes['DepositAmountChanged']
> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  status?: Resolver<ResolversTypes['ErrorCode'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DepositAvailabilityInfoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DepositAvailabilityInfo'] = ResolversParentTypes['DepositAvailabilityInfo']
> = {
  autoCancelUnpaidDeposit?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  autoCancelUnpaidDepositTimeframe?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType
  >
  cancellationRefundableTimeframe?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType
  >
  depositPolicy?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  enabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  strategy?: Resolver<
    ResolversTypes['DepositStrategyAvailabilityInfo'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DepositBookableConfigSnapshotResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DepositBookableConfigSnapshot'] = ResolversParentTypes['DepositBookableConfigSnapshot']
> = {
  cancellationRefundableTimeframe?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType
  >
  depositPolicy?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  strategy?: Resolver<
    ResolversTypes['DepositBookableConfigSnapshotStrategy'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DepositBookableConfigSnapshotStrategyResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DepositBookableConfigSnapshotStrategy'] = ResolversParentTypes['DepositBookableConfigSnapshotStrategy']
> = {
  amount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['DepositType'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DepositRuleNotMatchingResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DepositRuleNotMatching'] = ResolversParentTypes['DepositRuleNotMatching']
> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  status?: Resolver<ResolversTypes['ErrorCode'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DepositStrategyResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DepositStrategy'] = ResolversParentTypes['DepositStrategy']
> = {
  __resolveType: TypeResolveFn<
    'DepositStrategyByBooking' | 'DepositStrategyByPartySize',
    ParentType,
    ContextType
  >
}

export type DepositStrategyAvailabilityInfoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DepositStrategyAvailabilityInfo'] = ResolversParentTypes['DepositStrategyAvailabilityInfo']
> = {
  __resolveType: TypeResolveFn<
    | 'DepositStrategyAvailabilityInfoByBooking'
    | 'DepositStrategyAvailabilityInfoByPartySize',
    ParentType,
    ContextType
  >
}

export type DepositStrategyAvailabilityInfoByBookingResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DepositStrategyAvailabilityInfoByBooking'] = ResolversParentTypes['DepositStrategyAvailabilityInfoByBooking']
> = {
  actualAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  amountsByParty?: Resolver<
    Array<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >
  defaultAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DepositStrategyAvailabilityInfoByPartySizeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DepositStrategyAvailabilityInfoByPartySize'] = ResolversParentTypes['DepositStrategyAvailabilityInfoByPartySize']
> = {
  actualAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  amountPerGuest?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  maxPartySize?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  minPartySize?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DepositStrategyByBookingResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DepositStrategyByBooking'] = ResolversParentTypes['DepositStrategyByBooking']
> = {
  amountsByParty?: Resolver<
    Array<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >
  defaultAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DepositStrategyByPartySizeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DepositStrategyByPartySize'] = ResolversParentTypes['DepositStrategyByPartySize']
> = {
  amountPerGuest?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  maxPartySize?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  minPartySize?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DeviceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Device'] = ResolversParentTypes['Device']
> = {
  __resolveType: TypeResolveFn<
    'Printer' | 'TerminalHandheld',
    ParentType,
    ContextType
  >
  deviceDetails?: Resolver<
    Maybe<ResolversTypes['DeviceDetails']>,
    ParentType,
    ContextType
  >
  deviceType?: Resolver<ResolversTypes['DeviceType'], ParentType, ContextType>
  firmwareVersion?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  lastUpdate?: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >
  model?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  modelName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  networks?: Resolver<
    Maybe<Array<ResolversTypes['DeviceNetworkDetails']>>,
    ParentType,
    ContextType
  >
  photo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  restaurant?: Resolver<
    ResolversTypes['SavedRestaurantLocation'],
    ParentType,
    ContextType
  >
  serial?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
}

export type DeviceAlertResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DeviceAlert'] = ResolversParentTypes['DeviceAlert']
> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  reportingDeviceSerial?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  time?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['DeviceAlertType'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DeviceConnectivityEventResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DeviceConnectivityEvent'] = ResolversParentTypes['DeviceConnectivityEvent']
> = {
  data?: Resolver<
    Maybe<ResolversTypes['DeviceConnectivityEventData']>,
    ParentType,
    ContextType
  >
  deviceId?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  deviceTime?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  serial?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  time?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DeviceConnectivityEventDataResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DeviceConnectivityEventData'] = ResolversParentTypes['DeviceConnectivityEventData']
> = {
  ipAddress?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  lastConnectedType?: Resolver<
    Maybe<ResolversTypes['DeviceConnectionType']>,
    ParentType,
    ContextType
  >
  macAddress?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  networkType?: Resolver<
    Maybe<ResolversTypes['DeviceConnectionType']>,
    ParentType,
    ContextType
  >
  newStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  ssid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  status?: Resolver<
    Maybe<ResolversTypes['DeviceConnectivityEventStatus']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DeviceDetailsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DeviceDetails'] = ResolversParentTypes['DeviceDetails']
> = {
  alerts?: Resolver<
    Array<ResolversTypes['DeviceAlert']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DeviceInfoPublishedPrepStationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DeviceInfoPublishedPrepStation'] = ResolversParentTypes['DeviceInfoPublishedPrepStation']
> = {
  alwaysPrint?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  expediter?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  multiLocationId?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DeviceLastSeenInfoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DeviceLastSeenInfo'] = ResolversParentTypes['DeviceLastSeenInfo']
> = {
  heartbeatStatus?: Resolver<
    ResolversTypes['DeviceOnlineStatus'],
    ParentType,
    ContextType
  >
  lastSeenTime?: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >
  offlineBarStatus?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  serial?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DeviceNetworkDetailsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DeviceNetworkDetails'] = ResolversParentTypes['DeviceNetworkDetails']
> = {
  dhcp?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  gatewayIp?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  iccid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  imei?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  interfaceType?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  ipAddress?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  ipType?: Resolver<ResolversTypes['DeviceIpType'], ParentType, ContextType>
  isDefaultRoute?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  mac?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  netmaskIp?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  networkInterface?: Resolver<
    Maybe<ResolversTypes['DeviceNetworkInterface']>,
    ParentType,
    ContextType
  >
  port?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  ssid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  wifiBand?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DeviceQueriesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DeviceQueries'] = ResolversParentTypes['DeviceQueries']
> = {
  connectivityEvents?: Resolver<
    Maybe<Array<ResolversTypes['DeviceConnectivityEvent']>>,
    ParentType,
    ContextType,
    RequireFields<
      DeviceQueriesConnectivityEventsArgs,
      'restaurantId' | 'serial'
    >
  >
  device?: Resolver<
    Maybe<ResolversTypes['Device']>,
    ParentType,
    ContextType,
    RequireFields<DeviceQueriesDeviceArgs, 'input'>
  >
  devices?: Resolver<
    Maybe<Array<ResolversTypes['Device']>>,
    ParentType,
    ContextType,
    Partial<DeviceQueriesDevicesArgs>
  >
  lastSeenInfo?: Resolver<
    Maybe<Array<ResolversTypes['DeviceLastSeenInfo']>>,
    ParentType,
    ContextType,
    RequireFields<DeviceQueriesLastSeenInfoArgs, 'restaurantIds'>
  >
  printerIssues?: Resolver<
    Maybe<Array<ResolversTypes['DeviceAlert']>>,
    ParentType,
    ContextType,
    RequireFields<DeviceQueriesPrinterIssuesArgs, 'restaurantId' | 'serial'>
  >
  publishedPrinter?: Resolver<
    Maybe<ResolversTypes['Printer']>,
    ParentType,
    ContextType,
    RequireFields<DeviceQueriesPublishedPrinterArgs, 'restaurantId' | 'serial'>
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DeviceSyncDetailsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DeviceSyncDetails'] = ResolversParentTypes['DeviceSyncDetails']
> = {
  isRunningHubServices?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DiningOptionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DiningOption'] = ResolversParentTypes['DiningOption']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DiscountAmountResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DiscountAmount'] = ResolversParentTypes['DiscountAmount']
> = {
  grouped?: Resolver<
    Maybe<Array<ResolversTypes['GroupedDiscountAmount']>>,
    ParentType,
    ContextType,
    RequireFields<DiscountAmountGroupedArgs, 'by'>
  >
  totals?: Resolver<ResolversTypes['MoneyMetric'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DiscountCountResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DiscountCount'] = ResolversParentTypes['DiscountCount']
> = {
  grouped?: Resolver<
    Maybe<Array<ResolversTypes['GroupedDiscountCount']>>,
    ParentType,
    ContextType,
    RequireFields<DiscountCountGroupedArgs, 'by'>
  >
  totals?: Resolver<ResolversTypes['IntegerMetric'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DiscountGroupEntityResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DiscountGroupEntity'] = ResolversParentTypes['DiscountGroupEntity']
> = {
  __resolveType: TypeResolveFn<
    'Check' | 'RestaurantUser',
    ParentType,
    ContextType
  >
}

export type DoorDashMockOnboardingConfigurationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DoorDashMockOnboardingConfiguration'] = ResolversParentTypes['DoorDashMockOnboardingConfiguration']
> = {
  doordashStoreGuid?: Resolver<
    Maybe<ResolversTypes['ID']>,
    ParentType,
    ContextType
  >
  exchangeCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  hasBegunOnboarding?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  integrationIsActivated?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  menus?: Resolver<
    Maybe<Array<ResolversTypes['DoorDashOnboardingMenu']>>,
    ParentType,
    ContextType
  >
  netNewSignupUrl?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  onboardingId?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  onboardingStatus?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  onboardingStatusExclusionCode?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  onboardingStatusExclusionCodeOverride?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  onboardingStatusOverride?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  restaurant?: Resolver<
    ResolversTypes['SavedRestaurantLocation'],
    ParentType,
    ContextType
  >
  storeCandidates?: Resolver<
    Maybe<Array<ResolversTypes['DoorDashStoreCandidate']>>,
    ParentType,
    ContextType
  >
  throwActivateIntegrationError?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  throwAuthError?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  throwHttpError?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >
  throwStoreInProcessOfOnboardingError?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DoorDashNetNewOnboardingResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DoorDashNetNewOnboardingResponse'] = ResolversParentTypes['DoorDashNetNewOnboardingResponse']
> = {
  signupUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DoorDashOnboardingActivateIntegrationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DoorDashOnboardingActivateIntegrationResponse'] = ResolversParentTypes['DoorDashOnboardingActivateIntegrationResponse']
> = {
  body?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  isSuccessful?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DoorDashOnboardingIdResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DoorDashOnboardingIdResponse'] = ResolversParentTypes['DoorDashOnboardingIdResponse']
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  onboardingId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DoorDashOnboardingMenuResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DoorDashOnboardingMenu'] = ResolversParentTypes['DoorDashOnboardingMenu']
> = {
  menuError?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  menuId?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  menuPreviewLink?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DoorDashOnboardingMenuCandidateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DoorDashOnboardingMenuCandidate'] = ResolversParentTypes['DoorDashOnboardingMenuCandidate']
> = {
  menuError?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  menuPreviewLink?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  menuUuid?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DoorDashOnboardingStatusResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DoorDashOnboardingStatusResponse'] = ResolversParentTypes['DoorDashOnboardingStatusResponse']
> = {
  details?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  exclusionCode?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  locationGuid?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  locationName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  menus?: Resolver<
    Array<ResolversTypes['DoorDashOnboardingMenuCandidate']>,
    ParentType,
    ContextType
  >
  onboardingId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  onboardingStatus?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  providerRestaurantGuid?: Resolver<
    ResolversTypes['ID'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DoorDashOnboardingStoreCandidateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DoorDashOnboardingStoreCandidate'] = ResolversParentTypes['DoorDashOnboardingStoreCandidate']
> = {
  address?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  isActivated?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  locationGuid?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  locationName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  onboardingEligible?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  providerRestaurantGuid?: Resolver<
    ResolversTypes['ID'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DoorDashOnboardingStoresResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DoorDashOnboardingStores'] = ResolversParentTypes['DoorDashOnboardingStores']
> = {
  storeCandidates?: Resolver<
    Array<ResolversTypes['DoorDashOnboardingStoreCandidate']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DoorDashStoreCandidateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DoorDashStoreCandidate'] = ResolversParentTypes['DoorDashStoreCandidate']
> = {
  address?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  businessUUID?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  doordashStoreUUID?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isActiveOnIntegration?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  isEligibleToOnboard?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  locationName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DriveThruMutationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DriveThruMutation'] = ResolversParentTypes['DriveThruMutation']
> = {
  deleteOrderConfirmationScreenConfigOverrides?: Resolver<
    ResolversTypes['SavedOrderConfirmationScreenConfig'],
    ParentType,
    ContextType,
    RequireFields<
      DriveThruMutationDeleteOrderConfirmationScreenConfigOverridesArgs,
      'targets'
    >
  >
  deleteStockDataConfigOverrides?: Resolver<
    ResolversTypes['SavedStockDataConfig'],
    ParentType,
    ContextType,
    RequireFields<
      DriveThruMutationDeleteStockDataConfigOverridesArgs,
      'targets'
    >
  >
  deleteStockOutConfigOverrides?: Resolver<
    ResolversTypes['SavedStockOutConfig'],
    ParentType,
    ContextType,
    RequireFields<DriveThruMutationDeleteStockOutConfigOverridesArgs, 'targets'>
  >
  saveOrderConfirmationScreenConfigBase?: Resolver<
    ResolversTypes['QLOrderConfirmationScreenConfig'],
    ParentType,
    ContextType,
    RequireFields<
      DriveThruMutationSaveOrderConfirmationScreenConfigBaseArgs,
      'input'
    >
  >
  saveOrderConfirmationScreenConfigOverrides?: Resolver<
    ResolversTypes['SavedOrderConfirmationScreenConfig'],
    ParentType,
    ContextType,
    RequireFields<
      DriveThruMutationSaveOrderConfirmationScreenConfigOverridesArgs,
      'input'
    >
  >
  saveStockDataConfigBase?: Resolver<
    ResolversTypes['QLStockDataConfig'],
    ParentType,
    ContextType,
    RequireFields<DriveThruMutationSaveStockDataConfigBaseArgs, 'input'>
  >
  saveStockDataConfigOverrides?: Resolver<
    ResolversTypes['SavedStockDataConfig'],
    ParentType,
    ContextType,
    RequireFields<DriveThruMutationSaveStockDataConfigOverridesArgs, 'input'>
  >
  saveStockOutConfigBase?: Resolver<
    ResolversTypes['QLStockOutConfig'],
    ParentType,
    ContextType,
    RequireFields<DriveThruMutationSaveStockOutConfigBaseArgs, 'input'>
  >
  saveStockOutConfigOverrides?: Resolver<
    ResolversTypes['SavedStockOutConfig'],
    ParentType,
    ContextType,
    RequireFields<DriveThruMutationSaveStockOutConfigOverridesArgs, 'input'>
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DriveThruQueryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DriveThruQuery'] = ResolversParentTypes['DriveThruQuery']
> = {
  resolvedOrderConfirmationScreenConfig?: Resolver<
    ResolversTypes['QLOrderConfirmationScreenConfig'],
    ParentType,
    ContextType
  >
  resolvedStockDataConfig?: Resolver<
    ResolversTypes['QLStockDataConfig'],
    ParentType,
    ContextType
  >
  resolvedStockOutConfig?: Resolver<
    ResolversTypes['QLStockOutConfig'],
    ParentType,
    ContextType
  >
  savedOrderConfirmationScreenConfig?: Resolver<
    ResolversTypes['SavedOrderConfirmationScreenConfig'],
    ParentType,
    ContextType
  >
  savedStockDataConfig?: Resolver<
    ResolversTypes['SavedStockDataConfig'],
    ParentType,
    ContextType
  >
  savedStockOutConfig?: Resolver<
    ResolversTypes['SavedStockOutConfig'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DsModelHealthStatusResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DsModelHealthStatus'] = ResolversParentTypes['DsModelHealthStatus']
> = {
  isHealthy?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export interface DurationScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['Duration'], any> {
  name: 'Duration'
}

export type DurationBreakdownResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DurationBreakdown'] = ResolversParentTypes['DurationBreakdown']
> = {
  overtimeDuration?: Resolver<
    Maybe<ResolversTypes['Duration']>,
    ParentType,
    ContextType
  >
  paidBreakTime?: Resolver<
    Maybe<ResolversTypes['Duration']>,
    ParentType,
    ContextType
  >
  payableTime?: Resolver<
    Maybe<ResolversTypes['Duration']>,
    ParentType,
    ContextType
  >
  regularDuration?: Resolver<
    Maybe<ResolversTypes['Duration']>,
    ParentType,
    ContextType
  >
  totalTime?: Resolver<
    Maybe<ResolversTypes['Duration']>,
    ParentType,
    ContextType
  >
  unpaidBreakTime?: Resolver<
    Maybe<ResolversTypes['Duration']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EpCustomerBillingInvoiceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EPCustomerBillingInvoice'] = ResolversParentTypes['EPCustomerBillingInvoice']
> = {
  __resolveReference?: ReferenceResolver<
    Maybe<ResolversTypes['EPCustomerBillingInvoice']>,
    { __typename: 'EPCustomerBillingInvoice' } & GraphQLRecursivePick<
      ParentType,
      { id: true; restaurant: { id: true } }
    >,
    ContextType
  >
  billingAddress?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  createdDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>
  deductions?: Resolver<
    ResolversTypes['EPCustomerBillingInvoiceDeductions'],
    ParentType,
    ContextType
  >
  dueDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  items?: Resolver<
    Array<ResolversTypes['EPCustomerBillingInvoiceItem']>,
    ParentType,
    ContextType
  >
  netsuiteId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  orderType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  restaurant?: Resolver<
    Maybe<ResolversTypes['Restaurant']>,
    ParentType,
    ContextType
  >
  salesforceId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  shippingAddress?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  status?: Resolver<
    ResolversTypes['CustomerBillingInvoiceStatus'],
    ParentType,
    ContextType
  >
  terms?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  totals?: Resolver<
    ResolversTypes['CustomerBillingInvoiceTotals'],
    ParentType,
    ContextType
  >
  transactionDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>
  type?: Resolver<
    ResolversTypes['CustomerBillingInvoiceType'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EpCustomerBillingInvoiceDeductionsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EPCustomerBillingInvoiceDeductions'] = ResolversParentTypes['EPCustomerBillingInvoiceDeductions']
> = {
  dailyRepaymentRate?: Resolver<
    Maybe<ResolversTypes['Money']>,
    ParentType,
    ContextType
  >
  securityDeposit?: Resolver<ResolversTypes['Money'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EpCustomerBillingInvoiceItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EPCustomerBillingInvoiceItem'] = ResolversParentTypes['EPCustomerBillingInvoiceItem']
> = {
  amount?: Resolver<ResolversTypes['Money'], ParentType, ContextType>
  children?: Resolver<
    Array<ResolversTypes['EPCustomerBillingInvoiceItem']>,
    ParentType,
    ContextType
  >
  endDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  parentProduct?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  quantity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  rate?: Resolver<ResolversTypes['Money'], ParentType, ContextType>
  startDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>
  taxAmount?: Resolver<ResolversTypes['Money'], ParentType, ContextType>
  taxRate?: Resolver<ResolversTypes['Money'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EmailMarketingBlastCampaignResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmailMarketingBlastCampaign'] = ResolversParentTypes['EmailMarketingBlastCampaign']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  recommendationName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  sendDate?: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >
  status?: Resolver<
    ResolversTypes['EmailMarketingCampaignStatus'],
    ParentType,
    ContextType
  >
  theme?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EmailMarketingCampaignResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmailMarketingCampaign'] = ResolversParentTypes['EmailMarketingCampaign']
> = {
  __resolveType: TypeResolveFn<
    'EmailMarketingBlastCampaign',
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  status?: Resolver<
    ResolversTypes['EmailMarketingCampaignStatus'],
    ParentType,
    ContextType
  >
}

export type EmployeeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Employee'] = ResolversParentTypes['Employee']
> = {
  chosenName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  hireDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>
  lastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  payroll?: Resolver<
    Maybe<ResolversTypes['PayrollEmployeeInfo']>,
    ParentType,
    ContextType
  >
  phoneNumber?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  pos?: Resolver<
    Array<ResolversTypes['PosEmployeeInfo']>,
    ParentType,
    ContextType
  >
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EmployeeAddressResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmployeeAddress'] = ResolversParentTypes['EmployeeAddress']
> = {
  addressLines?: Resolver<
    Array<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  administrativeArea?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >
  locality?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  postalCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  regionCode?: Resolver<
    ResolversTypes['EmployeeCountry'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EmployeeConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmployeeConnection'] = ResolversParentTypes['EmployeeConnection']
> = {
  edges?: Resolver<
    Array<ResolversTypes['EmployeeEdge']>,
    ParentType,
    ContextType
  >
  paginationInfo?: Resolver<
    ResolversTypes['EmployeePageInfo'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EmployeeCountryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmployeeCountry'] = ResolversParentTypes['EmployeeCountry']
> = {
  alpha2?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  alpha3?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  enCountryName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EmployeeDeletedResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmployeeDeleted'] = ResolversParentTypes['EmployeeDeleted']
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EmployeeEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmployeeEdge'] = ResolversParentTypes['EmployeeEdge']
> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  node?: Resolver<ResolversTypes['EmployeeV2'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EmployeeEmailResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmployeeEmail'] = ResolversParentTypes['EmployeeEmail']
> = {
  address?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  isVerified?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EmployeeEmploymentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmployeeEmployment'] = ResolversParentTypes['EmployeeEmployment']
> = {
  currentEmployment?: Resolver<
    ResolversTypes['EmployeeEmploymentPeriod'],
    ParentType,
    ContextType
  >
  employmentHistory?: Resolver<
    Array<ResolversTypes['EmployeeHistoricalEmploymentPeriod']>,
    ParentType,
    ContextType
  >
  employmentType?: Resolver<
    ResolversTypes['EmployeeEmploymentType'],
    ParentType,
    ContextType
  >
  overtime?: Resolver<
    ResolversTypes['EmployeeOvertimeEligibility'],
    ParentType,
    ContextType
  >
  pendingEmployment?: Resolver<
    Maybe<ResolversTypes['EmployeeEmploymentPeriod']>,
    ParentType,
    ContextType
  >
  totalLengthOfEmployment?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >
  workType?: Resolver<
    ResolversTypes['EmployeeWorkType'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EmployeeEmploymentPeriodResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmployeeEmploymentPeriod'] = ResolversParentTypes['EmployeeEmploymentPeriod']
> = {
  employeeNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  startDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>
  status?: Resolver<
    Maybe<ResolversTypes['EmployeeEmploymentStatus']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EmployeeEmploymentPeriodInterfaceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmployeeEmploymentPeriodInterface'] = ResolversParentTypes['EmployeeEmploymentPeriodInterface']
> = {
  __resolveType: TypeResolveFn<
    'EmployeeEmploymentPeriod' | 'EmployeeHistoricalEmploymentPeriod',
    ParentType,
    ContextType
  >
  employeeNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  startDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>
  status?: Resolver<
    Maybe<ResolversTypes['EmployeeEmploymentStatus']>,
    ParentType,
    ContextType
  >
}

export type EmployeeHistoricalEmploymentPeriodResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmployeeHistoricalEmploymentPeriod'] = ResolversParentTypes['EmployeeHistoricalEmploymentPeriod']
> = {
  employeeNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  endDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>
  startDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>
  status?: Resolver<
    Maybe<ResolversTypes['EmployeeEmploymentStatus']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EmployeeJobAssignmentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmployeeJobAssignment'] = ResolversParentTypes['EmployeeJobAssignment']
> = {
  jobAssignmentId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  jobId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  pay?: Resolver<Maybe<ResolversTypes['EmployeePay']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EmployeeLocationAssignmentsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmployeeLocationAssignments'] = ResolversParentTypes['EmployeeLocationAssignments']
> = {
  isEmployeeArchived?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  isPrimaryLocation?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  jobAssignments?: Resolver<
    Maybe<Array<ResolversTypes['EmployeeJobAssignment']>>,
    ParentType,
    ContextType
  >
  locationId?: Resolver<
    Maybe<ResolversTypes['SavedRestaurantLocation']>,
    ParentType,
    ContextType
  >
  posAccessCode?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EmployeeMissedBreakResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmployeeMissedBreak'] = ResolversParentTypes['EmployeeMissedBreak']
> = {
  config?: Resolver<ResolversTypes['BreakConfig'], ParentType, ContextType>
  confirmationType?: Resolver<
    ResolversTypes['EmployeeBreakConfirmationType'],
    ParentType,
    ContextType
  >
  expectedStartTime?: Resolver<
    ResolversTypes['DateTime'],
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isPaid?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EmployeeNameResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmployeeName'] = ResolversParentTypes['EmployeeName']
> = {
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  middleName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EmployeeOrderTipsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmployeeOrderTips'] = ResolversParentTypes['EmployeeOrderTips']
> = {
  cashGratuity?: Resolver<
    Maybe<ResolversTypes['Money']>,
    ParentType,
    ContextType
  >
  creditCardGratuity?: Resolver<
    Maybe<ResolversTypes['Money']>,
    ParentType,
    ContextType
  >
  creditCardTips?: Resolver<
    Maybe<ResolversTypes['Money']>,
    ParentType,
    ContextType
  >
  order?: Resolver<ResolversTypes['Order'], ParentType, ContextType>
  otherNonCashGratuity?: Resolver<
    Maybe<ResolversTypes['Money']>,
    ParentType,
    ContextType
  >
  otherNonCashTips?: Resolver<
    Maybe<ResolversTypes['Money']>,
    ParentType,
    ContextType
  >
  tipRefund?: Resolver<Maybe<ResolversTypes['Money']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EmployeePageInfoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmployeePageInfo'] = ResolversParentTypes['EmployeePageInfo']
> = {
  endCursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  first?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  hasNextPage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  hasPreviousPage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  offset?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  startCursor?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EmployeePayResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmployeePay'] = ResolversParentTypes['EmployeePay']
> = {
  basis?: Resolver<
    Maybe<ResolversTypes['EmployeePayBasis']>,
    ParentType,
    ContextType
  >
  rate?: Resolver<Maybe<ResolversTypes['Money']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EmployeePhoneNumberResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmployeePhoneNumber'] = ResolversParentTypes['EmployeePhoneNumber']
> = {
  countryCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  nationalNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EmployeeRepositoryDependencyFailureResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmployeeRepositoryDependencyFailure'] = ResolversParentTypes['EmployeeRepositoryDependencyFailure']
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EmployeeScheduleResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmployeeSchedule'] = ResolversParentTypes['EmployeeSchedule']
> = {
  clockInWithinGracePeriod?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  inDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>
  outDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EmployeeScheduleV2Resolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmployeeScheduleV2'] = ResolversParentTypes['EmployeeScheduleV2']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  inDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>
  outDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EmployeeShiftResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmployeeShift'] = ResolversParentTypes['EmployeeShift']
> = {
  __resolveType: TypeResolveFn<
    'FinishedEmployeeShift' | 'InProgressEmployeeShift',
    ParentType,
    ContextType
  >
  businessDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>
  employee?: Resolver<ResolversTypes['RestaurantUser'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isDeleted?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  job?: Resolver<Maybe<ResolversTypes['Job']>, ParentType, ContextType>
  restaurant?: Resolver<ResolversTypes['Restaurant'], ParentType, ContextType>
  schedule?: Resolver<
    Maybe<ResolversTypes['EmployeeSchedule']>,
    ParentType,
    ContextType
  >
  status?: Resolver<
    Maybe<ResolversTypes['EmployeeShiftStatus']>,
    ParentType,
    ContextType
  >
  timeClock?: Resolver<
    Maybe<ResolversTypes['EmployeeTime']>,
    ParentType,
    ContextType
  >
  tips?: Resolver<
    Maybe<ResolversTypes['EmployeeTips']>,
    ParentType,
    ContextType
  >
}

export type EmployeeShiftConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmployeeShiftConnection'] = ResolversParentTypes['EmployeeShiftConnection']
> = {
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>
  shifts?: Resolver<
    Array<ResolversTypes['EmployeeShift']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EmployeeShiftConnectionV2Resolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmployeeShiftConnectionV2'] = ResolversParentTypes['EmployeeShiftConnectionV2']
> = {
  edges?: Resolver<
    Array<ResolversTypes['EmployeeShiftConnectionV2Edge']>,
    ParentType,
    ContextType
  >
  pageInfo?: Resolver<
    ResolversTypes['ShiftsV2PageInfo'],
    ParentType,
    ContextType
  >
  totalItemCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EmployeeShiftConnectionV2EdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmployeeShiftConnectionV2Edge'] = ResolversParentTypes['EmployeeShiftConnectionV2Edge']
> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  node?: Resolver<ResolversTypes['EmployeeShiftV2'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EmployeeShiftTipResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmployeeShiftTip'] = ResolversParentTypes['EmployeeShiftTip']
> = {
  cashAmount?: Resolver<ResolversTypes['Money'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EmployeeShiftV2Resolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmployeeShiftV2'] = ResolversParentTypes['EmployeeShiftV2']
> = {
  __resolveType: TypeResolveFn<
    'FinishedEmployeeShiftV2' | 'InProgressEmployeeShiftV2',
    ParentType,
    ContextType
  >
  businessDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>
  employee?: Resolver<ResolversTypes['RestaurantUser'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isDeleted?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  job?: Resolver<Maybe<ResolversTypes['Job']>, ParentType, ContextType>
  restaurant?: Resolver<ResolversTypes['Restaurant'], ParentType, ContextType>
  schedule?: Resolver<
    Maybe<ResolversTypes['EmployeeScheduleV2']>,
    ParentType,
    ContextType
  >
  status?: Resolver<
    Maybe<ResolversTypes['EmployeeShiftStatus']>,
    ParentType,
    ContextType
  >
  timeClock?: Resolver<
    Maybe<ResolversTypes['EmployeeTime']>,
    ParentType,
    ContextType
  >
  tips?: Resolver<
    Maybe<ResolversTypes['EmployeeTipsV2']>,
    ParentType,
    ContextType
  >
}

export type EmployeeTakenBreakResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmployeeTakenBreak'] = ResolversParentTypes['EmployeeTakenBreak']
> = {
  __resolveType: TypeResolveFn<
    'FinishedEmployeeTakenBreak' | 'InProgressEmployeeTakenBreak',
    ParentType,
    ContextType
  >
  config?: Resolver<ResolversTypes['BreakConfig'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isPaid?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  startTime?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
}

export type EmployeeTimeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmployeeTime'] = ResolversParentTypes['EmployeeTime']
> = {
  __resolveType: TypeResolveFn<
    'FinishedEmployeeTime' | 'InProgressEmployeeTime',
    ParentType,
    ContextType
  >
  inTime?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  missedBreaks?: Resolver<
    Array<ResolversTypes['EmployeeMissedBreak']>,
    ParentType,
    ContextType
  >
  takenBreaks?: Resolver<
    Array<ResolversTypes['EmployeeTakenBreak']>,
    ParentType,
    ContextType
  >
}

export type EmployeeTipsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmployeeTips'] = ResolversParentTypes['EmployeeTips']
> = {
  cashGratuity?: Resolver<
    Maybe<ResolversTypes['Money']>,
    ParentType,
    ContextType
  >
  nonCashGratuity?: Resolver<
    Maybe<ResolversTypes['Money']>,
    ParentType,
    ContextType
  >
  nonCashTips?: Resolver<
    Maybe<ResolversTypes['Money']>,
    ParentType,
    ContextType
  >
  orderTips?: Resolver<
    Array<ResolversTypes['EmployeeOrderTips']>,
    ParentType,
    ContextType
  >
  shiftTip?: Resolver<
    Maybe<ResolversTypes['EmployeeShiftTip']>,
    ParentType,
    ContextType
  >
  tipsWithHeld?: Resolver<
    Maybe<ResolversTypes['Money']>,
    ParentType,
    ContextType
  >
  totalGratuity?: Resolver<
    Maybe<ResolversTypes['Money']>,
    ParentType,
    ContextType
  >
  totalTips?: Resolver<Maybe<ResolversTypes['Money']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EmployeeTipsV2Resolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmployeeTipsV2'] = ResolversParentTypes['EmployeeTipsV2']
> = {
  cashGratuity?: Resolver<
    Maybe<ResolversTypes['Money']>,
    ParentType,
    ContextType
  >
  nonCashGratuity?: Resolver<
    Maybe<ResolversTypes['Money']>,
    ParentType,
    ContextType
  >
  nonCashTips?: Resolver<
    Maybe<ResolversTypes['Money']>,
    ParentType,
    ContextType
  >
  shiftTip?: Resolver<
    Maybe<ResolversTypes['EmployeeShiftTip']>,
    ParentType,
    ContextType
  >
  tipsWithHeld?: Resolver<
    Maybe<ResolversTypes['Money']>,
    ParentType,
    ContextType
  >
  totalGratuity?: Resolver<
    Maybe<ResolversTypes['Money']>,
    ParentType,
    ContextType
  >
  totalTips?: Resolver<Maybe<ResolversTypes['Money']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EmployeeV2Resolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmployeeV2'] = ResolversParentTypes['EmployeeV2']
> = {
  __resolveReference?: ReferenceResolver<
    Maybe<ResolversTypes['EmployeeV2']>,
    { __typename: 'EmployeeV2' } & GraphQLRecursivePick<
      ParentType,
      { id: true }
    >,
    ContextType
  >
  email?: Resolver<ResolversTypes['EmployeeEmail'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  locationAssignments?: Resolver<
    Maybe<Array<ResolversTypes['EmployeeLocationAssignments']>>,
    ParentType,
    ContextType
  >
  name?: Resolver<ResolversTypes['EmployeeName'], ParentType, ContextType>
  primaryPhoneNumber?: Resolver<
    Maybe<ResolversTypes['EmployeePhoneNumber']>,
    ParentType,
    ContextType
  >
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>
  version?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EmploymentStatusResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmploymentStatus'] = ResolversParentTypes['EmploymentStatus']
> = {
  __resolveType: TypeResolveFn<
    | 'ActiveEmploymentStatus'
    | 'ApplicantEmploymentStatus'
    | 'DeletedEmploymentStatus'
    | 'DemoEmploymentStatus'
    | 'ImplementationEmploymentStatus'
    | 'LeaveOfAbsenceEmploymentStatus'
    | 'NeverEmployedEmploymentStatus'
    | 'PendingEmploymentStatus'
    | 'RehiredEmploymentStatus'
    | 'SeasonalEmploymentStatus'
    | 'TerminatedEmploymentStatus',
    ParentType,
    ContextType
  >
  adjustedHireDate?: Resolver<
    Maybe<ResolversTypes['Date']>,
    ParentType,
    ContextType
  >
  hireDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
}

export type EmptyCustomerBillingInvoiceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmptyCustomerBillingInvoice'] = ResolversParentTypes['EmptyCustomerBillingInvoice']
> = {
  __resolveReference?: ReferenceResolver<
    Maybe<ResolversTypes['EmptyCustomerBillingInvoice']>,
    { __typename: 'EmptyCustomerBillingInvoice' } & GraphQLRecursivePick<
      ParentType,
      { id: true; restaurant: { id: true } }
    >,
    ContextType
  >
  billingAddress?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  createdDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>
  dueDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  items?: Resolver<
    Maybe<Array<ResolversTypes['CustomerBillingInvoiceItem']>>,
    ParentType,
    ContextType
  >
  netsuiteId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  orderType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  restaurant?: Resolver<
    Maybe<ResolversTypes['Restaurant']>,
    ParentType,
    ContextType
  >
  salesforceId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  shippingAddress?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  status?: Resolver<
    Maybe<ResolversTypes['CustomerBillingInvoiceStatus']>,
    ParentType,
    ContextType
  >
  terms?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  totals?: Resolver<
    Maybe<ResolversTypes['CustomerBillingInvoiceTotals']>,
    ParentType,
    ContextType
  >
  transactionDate?: Resolver<
    Maybe<ResolversTypes['Date']>,
    ParentType,
    ContextType
  >
  type?: Resolver<
    Maybe<ResolversTypes['CustomerBillingInvoiceType']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EntitlementsRepositoryDependencyFailureResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EntitlementsRepositoryDependencyFailure'] = ResolversParentTypes['EntitlementsRepositoryDependencyFailure']
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EntityAncestorPathResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EntityAncestorPath'] = ResolversParentTypes['EntityAncestorPath']
> = {
  ancestors?: Resolver<
    Array<ResolversTypes['MenuEntity']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EntityAncestorResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EntityAncestorResponse'] = ResolversParentTypes['EntityAncestorResponse']
> = {
  entityType?: Resolver<
    ResolversTypes['MenuEntityType'],
    ParentType,
    ContextType
  >
  paths?: Resolver<
    Array<ResolversTypes['EntityAncestorPath']>,
    ParentType,
    ContextType
  >
  versionId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Error'] = ResolversParentTypes['Error']
> = {
  __resolveType: TypeResolveFn<
    | 'BookingNotFound'
    | 'BookingRequiresDepositData'
    | 'DepositAmountChanged'
    | 'DepositRuleNotMatching'
    | 'ErrorRefundingDeposit'
    | 'GuestAlreadyExists'
    | 'GuestNotFound'
    | 'InternalError'
    | 'InvalidEmail'
    | 'InvalidGuest'
    | 'InvalidPhoneNumber'
    | 'NoPreviousImports'
    | 'RestaurantNotFound'
    | 'ServiceAreaNotFound'
    | 'TableBlocked'
    | 'TableNotFound'
    | 'UnableToCreateDepositOrder'
    | 'UnableToCreateGuest'
    | 'UnableToFetchGuests'
    | 'UnableToSendSMS'
    | 'UnableToUpdateGuest'
    | 'UnsupportedTransition',
    ParentType,
    ContextType
  >
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>
}

export type ErrorRefundingDepositResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ErrorRefundingDeposit'] = ResolversParentTypes['ErrorRefundingDeposit']
> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  status?: Resolver<ResolversTypes['ErrorCode'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ErrorResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ErrorResponse'] = ResolversParentTypes['ErrorResponse']
> = {
  code?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ErrorsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Errors'] = ResolversParentTypes['Errors']
> = {
  __resolveType: TypeResolveFn<
    | 'CorruptPageTokenError'
    | 'InvalidCustomerUuidError'
    | 'InvalidIdempotencyKeyError'
    | 'InvalidRestaurantIDError'
    | 'InvalidUuidError'
    | 'JobError'
    | 'JobNotFoundError'
    | 'JobsBatchSizeError'
    | 'JobsFilterMissingError'
    | 'JobsNotFoundBatchError'
    | 'NoJobIdsProvidedBatchError'
    | 'UnauthorizedAccessError',
    ParentType,
    ContextType
  >
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>
}

export type ExternalPartnerResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ExternalPartner'] = ResolversParentTypes['ExternalPartner']
> = {
  google?: Resolver<
    ResolversTypes['ExternalPartnerToggle'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ExternalPartnerToggleResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ExternalPartnerToggle'] = ResolversParentTypes['ExternalPartnerToggle']
> = {
  reservationsEnabled?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  waitlistEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type FetchCashFlowTransactionsResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FetchCashFlowTransactionsResponse'] = ResolversParentTypes['FetchCashFlowTransactionsResponse']
> = {
  accountTransactions?: Resolver<
    Array<ResolversTypes['CashFlowAccountTransactions']>,
    ParentType,
    ContextType
  >
  currentPage?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  pageSize?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  totalResultCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type FinancialInsightsMutationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FinancialInsightsMutation'] = ResolversParentTypes['FinancialInsightsMutation']
> = {
  updateCashFlowTransactions?: Resolver<
    Array<ResolversTypes['CashFlowAccountTransactions']>,
    ParentType,
    ContextType,
    RequireFields<
      FinancialInsightsMutationUpdateCashFlowTransactionsArgs,
      'request'
    >
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type FinancialInsightsQueryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FinancialInsightsQuery'] = ResolversParentTypes['FinancialInsightsQuery']
> = {
  fetchCashFlowTransactions?: Resolver<
    ResolversTypes['FetchCashFlowTransactionsResponse'],
    ParentType,
    ContextType,
    RequireFields<
      FinancialInsightsQueryFetchCashFlowTransactionsArgs,
      'request'
    >
  >
  getBankingConnection?: Resolver<
    Maybe<ResolversTypes['BankingConnection']>,
    ParentType,
    ContextType
  >
  getCashFlowAccounts?: Resolver<
    Array<ResolversTypes['CashFlowAccount']>,
    ParentType,
    ContextType
  >
  getCashFlowSummary?: Resolver<
    Maybe<ResolversTypes['CashFlowSummary']>,
    ParentType,
    ContextType
  >
  getPnLReport?: Resolver<
    ResolversTypes['PnLReport'],
    ParentType,
    ContextType,
    RequireFields<FinancialInsightsQueryGetPnLReportArgs, 'request'>
  >
  getPnLReportAISummary?: Resolver<
    Maybe<ResolversTypes['PnLAISummary']>,
    ParentType,
    ContextType,
    RequireFields<FinancialInsightsQueryGetPnLReportAiSummaryArgs, 'request'>
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type FinancialProductForCustomerResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FinancialProductForCustomer'] = ResolversParentTypes['FinancialProductForCustomer']
> = {
  cashAdvanceDate?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >
  externalID?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  fundingPartner?: Resolver<
    ResolversTypes['FundingPartner'],
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  minimumRepayment?: Resolver<
    Maybe<ResolversTypes['MinimumRepayment']>,
    ParentType,
    ContextType
  >
  originationAmount?: Resolver<
    Maybe<ResolversTypes['Money']>,
    ParentType,
    ContextType
  >
  productType?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  repaidDate?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  repaymentMilestones?: Resolver<
    Maybe<Array<ResolversTypes['RepaymentMilestone']>>,
    ParentType,
    ContextType
  >
  repaymentRate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  startRecollectionDate?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  termInDays?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  totalRepaymentAmountOwed?: Resolver<
    ResolversTypes['Money'],
    ParentType,
    ContextType
  >
  totalRepaymentAmountOwedAfterForgiveness?: Resolver<
    ResolversTypes['Money'],
    ParentType,
    ContextType
  >
  totalRepaymentAmountSettled?: Resolver<
    ResolversTypes['Money'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type FinishedEmployeeShiftResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FinishedEmployeeShift'] = ResolversParentTypes['FinishedEmployeeShift']
> = {
  businessDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>
  employee?: Resolver<ResolversTypes['RestaurantUser'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isDeleted?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  job?: Resolver<Maybe<ResolversTypes['Job']>, ParentType, ContextType>
  restaurant?: Resolver<ResolversTypes['Restaurant'], ParentType, ContextType>
  schedule?: Resolver<
    Maybe<ResolversTypes['EmployeeSchedule']>,
    ParentType,
    ContextType
  >
  status?: Resolver<
    Maybe<ResolversTypes['EmployeeShiftStatus']>,
    ParentType,
    ContextType
  >
  timeClock?: Resolver<
    Maybe<ResolversTypes['EmployeeTime']>,
    ParentType,
    ContextType
  >
  tips?: Resolver<
    Maybe<ResolversTypes['EmployeeTips']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type FinishedEmployeeShiftV2Resolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FinishedEmployeeShiftV2'] = ResolversParentTypes['FinishedEmployeeShiftV2']
> = {
  businessDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>
  employee?: Resolver<ResolversTypes['RestaurantUser'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isDeleted?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  job?: Resolver<Maybe<ResolversTypes['Job']>, ParentType, ContextType>
  restaurant?: Resolver<ResolversTypes['Restaurant'], ParentType, ContextType>
  schedule?: Resolver<
    Maybe<ResolversTypes['EmployeeScheduleV2']>,
    ParentType,
    ContextType
  >
  status?: Resolver<
    Maybe<ResolversTypes['EmployeeShiftStatus']>,
    ParentType,
    ContextType
  >
  timeClock?: Resolver<
    Maybe<ResolversTypes['EmployeeTime']>,
    ParentType,
    ContextType
  >
  tips?: Resolver<
    Maybe<ResolversTypes['EmployeeTipsV2']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type FinishedEmployeeTakenBreakResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FinishedEmployeeTakenBreak'] = ResolversParentTypes['FinishedEmployeeTakenBreak']
> = {
  config?: Resolver<ResolversTypes['BreakConfig'], ParentType, ContextType>
  confirmationType?: Resolver<
    ResolversTypes['EmployeeBreakConfirmationType'],
    ParentType,
    ContextType
  >
  endTime?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isPaid?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  startTime?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type FinishedEmployeeTimeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FinishedEmployeeTime'] = ResolversParentTypes['FinishedEmployeeTime']
> = {
  estimatedDurationBreakdown?: Resolver<
    Maybe<ResolversTypes['DurationBreakdown']>,
    ParentType,
    ContextType
  >
  inTime?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  missedBreaks?: Resolver<
    Array<ResolversTypes['EmployeeMissedBreak']>,
    ParentType,
    ContextType
  >
  outTime?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  takenBreaks?: Resolver<
    Array<ResolversTypes['EmployeeTakenBreak']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type FixedFeeLoanResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FixedFeeLoan'] = ResolversParentTypes['FixedFeeLoan']
> = {
  balance?: Resolver<ResolversTypes['Money'], ParentType, ContextType>
  fixedFee?: Resolver<ResolversTypes['Money'], ParentType, ContextType>
  holdbackRate?: Resolver<ResolversTypes['Decimal'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  originationAmount?: Resolver<ResolversTypes['Money'], ParentType, ContextType>
  startDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>
  termInDays?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type FixedFeePqoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FixedFeePQO'] = ResolversParentTypes['FixedFeePQO']
> = {
  fixedFee?: Resolver<ResolversTypes['Money'], ParentType, ContextType>
  holdbackRate?: Resolver<ResolversTypes['Decimal'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isMinimumPaymentEligible?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  minOriginationAmount?: Resolver<
    ResolversTypes['Money'],
    ParentType,
    ContextType
  >
  originationAmount?: Resolver<ResolversTypes['Money'], ParentType, ContextType>
  promotionalOffers?: Resolver<
    Array<ResolversTypes['FixedFeePromotionalOffer']>,
    ParentType,
    ContextType
  >
  refinanceLoans?: Resolver<
    Array<ResolversTypes['FixedFeeLoan']>,
    ParentType,
    ContextType
  >
  termInDays?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type FixedFeePromotionalOfferResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FixedFeePromotionalOffer'] = ResolversParentTypes['FixedFeePromotionalOffer']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Money']>, ParentType, ContextType>
  percent?: Resolver<Maybe<ResolversTypes['Decimal']>, ParentType, ContextType>
  progress?: Resolver<Maybe<ResolversTypes['Decimal']>, ParentType, ContextType>
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  targetDateTime?: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >
  type?: Resolver<
    ResolversTypes['FixedFeePromotionalOfferType'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type FoodWasteConfigResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FoodWasteConfig'] = ResolversParentTypes['FoodWasteConfig']
> = {
  enableTracking?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  managementSetGuid?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >
  overrides?: Resolver<
    Array<ResolversTypes['FoodWasteConfigOverride']>,
    ParentType,
    ContextType
  >
  requireReason?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  revision?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type FoodWasteConfigOverrideResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FoodWasteConfigOverride'] = ResolversParentTypes['FoodWasteConfigOverride']
> = {
  enableTracking?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  overriddenFields?: Resolver<
    Array<Maybe<ResolversTypes['FoodWasteConfigFields']>>,
    ParentType,
    ContextType
  >
  requireReason?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  revision?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  target?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type FoodWasteEducationDashboardDataResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FoodWasteEducationDashboardData'] = ResolversParentTypes['FoodWasteEducationDashboardData']
> = {
  localFoodOrganizations?: Resolver<
    Array<ResolversTypes['FoodWasteLocalOrganizationDetails']>,
    ParentType,
    ContextType
  >
  wasteEstimationInputs?: Resolver<
    ResolversTypes['FoodWasteEstimationInputs'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type FoodWasteEstimationInputsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FoodWasteEstimationInputs'] = ResolversParentTypes['FoodWasteEstimationInputs']
> = {
  serviceType?: Resolver<
    Maybe<ResolversTypes['FoodWasteEstimationServiceType']>,
    ParentType,
    ContextType
  >
  weeklyGuestCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type FoodWasteInsightsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FoodWasteInsights'] = ResolversParentTypes['FoodWasteInsights']
> = {
  topWasteReason?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  topWastedItem?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  totalWastedItemQuantity?: Resolver<
    ResolversTypes['Decimal'],
    ParentType,
    ContextType
  >
  totalWastedRetailValue?: Resolver<
    ResolversTypes['Money'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type FoodWasteLocalOrganizationDetailsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FoodWasteLocalOrganizationDetails'] = ResolversParentTypes['FoodWasteLocalOrganizationDetails']
> = {
  description?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  logoUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type FoodWasteMutationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FoodWasteMutation'] = ResolversParentTypes['FoodWasteMutation']
> = {
  createConfig?: Resolver<
    Maybe<ResolversTypes['FoodWasteConfig']>,
    ParentType,
    ContextType,
    RequireFields<FoodWasteMutationCreateConfigArgs, 'input'>
  >
  updateConfig?: Resolver<
    Maybe<ResolversTypes['FoodWasteConfig']>,
    ParentType,
    ContextType,
    RequireFields<FoodWasteMutationUpdateConfigArgs, 'input'>
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type FoodWasteOpportunityResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FoodWasteOpportunity'] = ResolversParentTypes['FoodWasteOpportunity']
> = {
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  impactLikelihood?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >
  savingsEstimate?: Resolver<
    Maybe<ResolversTypes['Money']>,
    ParentType,
    ContextType
  >
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type FoodWasteQueryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FoodWasteQuery'] = ResolversParentTypes['FoodWasteQuery']
> = {
  educationDashboard?: Resolver<
    ResolversTypes['FoodWasteEducationDashboardData'],
    ParentType,
    ContextType
  >
  opportunities?: Resolver<
    Array<ResolversTypes['FoodWasteOpportunity']>,
    ParentType,
    ContextType
  >
  savedConfig?: Resolver<
    Maybe<ResolversTypes['FoodWasteConfig']>,
    ParentType,
    ContextType
  >
  thirtyDayInsights?: Resolver<
    Maybe<ResolversTypes['FoodWasteInsights']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type FundingPartnerResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FundingPartner'] = ResolversParentTypes['FundingPartner']
> = {
  externalID?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  fundingPartnerUID?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GeneratedGuestFeedbackAiResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GeneratedGuestFeedbackAIResponse'] = ResolversParentTypes['GeneratedGuestFeedbackAIResponse']
> = {
  response?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GeoCoordinatesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GeoCoordinates'] = ResolversParentTypes['GeoCoordinates']
> = {
  latitude?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  longitude?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GetTipsResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GetTipsResponse'] = ResolversParentTypes['GetTipsResponse']
> = {
  tipsList?: Resolver<
    Array<ResolversTypes['TipDetails']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GetTrialsResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GetTrialsResponse'] = ResolversParentTypes['GetTrialsResponse']
> = {
  trialList?: Resolver<
    Array<ResolversTypes['TrialEntity']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GfdAddOnStatusResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GfdAddOnStatus'] = ResolversParentTypes['GfdAddOnStatus']
> = {
  isFirstPartyLoyaltyActive?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  isFundraisingActive?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  isGfdUpsellActive?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GfdConfigResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GfdConfig'] = ResolversParentTypes['GfdConfig']
> = {
  displayMode?: Resolver<
    ResolversTypes['DisplayTheme'],
    ParentType,
    ContextType
  >
  enabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  hideModifierPrices?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  isEarlyLoyaltyCheckIn?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  multiLocationId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  orderInProgressImage?: Resolver<
    Maybe<ResolversTypes['GfdImage']>,
    ParentType,
    ContextType
  >
  owner?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  ownerRestaurantSet?: Resolver<
    ResolversTypes['RestaurantSet'],
    ParentType,
    ContextType
  >
  standByImage?: Resolver<
    Maybe<ResolversTypes['GfdImage']>,
    ParentType,
    ContextType
  >
  target?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  targetRestaurantSet?: Resolver<
    ResolversTypes['RestaurantSet'],
    ParentType,
    ContextType
  >
  versionId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GfdImageResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GfdImage'] = ResolversParentTypes['GfdImage']
> = {
  heightWidthRatio?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >
  path?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GiftCardMutationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GiftCardMutation'] = ResolversParentTypes['GiftCardMutation']
> = {
  publishReceiptConfig?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  updateReceiptConfig?: Resolver<
    Maybe<ResolversTypes['SavedGiftCardReceiptConfig']>,
    ParentType,
    ContextType,
    RequireFields<GiftCardMutationUpdateReceiptConfigArgs, 'receiptConfig'>
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GiftCardQueryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GiftCardQuery'] = ResolversParentTypes['GiftCardQuery']
> = {
  receiptConfig?: Resolver<
    Maybe<ResolversTypes['PublishedGiftCardReceiptConfig']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GlobalNavigationGroupResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GlobalNavigationGroup'] = ResolversParentTypes['GlobalNavigationGroup']
> = {
  globalNavigationItems?: Resolver<
    Array<ResolversTypes['Category']>,
    ParentType,
    ContextType
  >
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GlobalNavigationMenuResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GlobalNavigationMenu'] = ResolversParentTypes['GlobalNavigationMenu']
> = {
  groups?: Resolver<
    Array<ResolversTypes['GlobalNavigationGroup']>,
    ParentType,
    ContextType
  >
  homeDescription?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  homeName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  homePath?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GroupResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Group'] = ResolversParentTypes['Group']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  tasks?: Resolver<Array<ResolversTypes['Task']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GroupEntityResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GroupEntity'] = ResolversParentTypes['GroupEntity']
> = {
  __resolveType: TypeResolveFn<'RestaurantUser', ParentType, ContextType>
}

export type GroupedChecksWithRefundsCountResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GroupedChecksWithRefundsCount'] = ResolversParentTypes['GroupedChecksWithRefundsCount']
> = {
  groupKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  totals?: Resolver<ResolversTypes['IntegerMetric'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GroupedChecksWithVoidsCountResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GroupedChecksWithVoidsCount'] = ResolversParentTypes['GroupedChecksWithVoidsCount']
> = {
  entity?: Resolver<
    Maybe<ResolversTypes['VoidGroupEntity']>,
    ParentType,
    ContextType
  >
  groupKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  totals?: Resolver<ResolversTypes['IntegerMetric'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GroupedDiscountAmountResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GroupedDiscountAmount'] = ResolversParentTypes['GroupedDiscountAmount']
> = {
  entity?: Resolver<
    Maybe<ResolversTypes['DiscountGroupEntity']>,
    ParentType,
    ContextType
  >
  groupKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  totals?: Resolver<ResolversTypes['MoneyMetric'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GroupedDiscountCountResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GroupedDiscountCount'] = ResolversParentTypes['GroupedDiscountCount']
> = {
  entity?: Resolver<
    Maybe<ResolversTypes['DiscountGroupEntity']>,
    ParentType,
    ContextType
  >
  groupKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  totals?: Resolver<ResolversTypes['IntegerMetric'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GroupedLaborCostResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GroupedLaborCost'] = ResolversParentTypes['GroupedLaborCost']
> = {
  entity?: Resolver<
    Maybe<ResolversTypes['LaborCostGroupEntity']>,
    ParentType,
    ContextType
  >
  groupKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  hourly?: Resolver<
    Array<ResolversTypes['MoneyMetric']>,
    ParentType,
    ContextType
  >
  totals?: Resolver<ResolversTypes['MoneyMetric'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GroupedNetSalesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GroupedNetSales'] = ResolversParentTypes['GroupedNetSales']
> = {
  entity?: Resolver<
    Maybe<ResolversTypes['GroupEntity']>,
    ParentType,
    ContextType
  >
  groupKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  hourly?: Resolver<
    Array<ResolversTypes['MoneyMetric']>,
    ParentType,
    ContextType
  >
  totals?: Resolver<ResolversTypes['MoneyMetric'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GroupedTotalRefundAmountResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GroupedTotalRefundAmount'] = ResolversParentTypes['GroupedTotalRefundAmount']
> = {
  groupKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  totals?: Resolver<ResolversTypes['MoneyMetric'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GroupedVoidAmountResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GroupedVoidAmount'] = ResolversParentTypes['GroupedVoidAmount']
> = {
  entity?: Resolver<
    Maybe<ResolversTypes['VoidGroupEntity']>,
    ParentType,
    ContextType
  >
  groupKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  totals?: Resolver<ResolversTypes['MoneyMetric'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GroupedVoidAmountAsPercentageOfNetSalesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GroupedVoidAmountAsPercentageOfNetSales'] = ResolversParentTypes['GroupedVoidAmountAsPercentageOfNetSales']
> = {
  entity?: Resolver<
    Maybe<ResolversTypes['VoidGroupEntity']>,
    ParentType,
    ContextType
  >
  groupKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  totals?: Resolver<ResolversTypes['PercentageMetric'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GroupedVoidedItemsCountResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GroupedVoidedItemsCount'] = ResolversParentTypes['GroupedVoidedItemsCount']
> = {
  entity?: Resolver<
    Maybe<ResolversTypes['VoidGroupEntity']>,
    ParentType,
    ContextType
  >
  groupKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  totals?: Resolver<ResolversTypes['DecimalMetric'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GuestResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Guest'] = ResolversParentTypes['Guest']
> = {
  acceptedTos?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  bookingCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  coverCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  createdAt?: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >
  deleted?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  dobDay?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  dobMonth?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  dobYear?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  guestBookGuid?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  guestNotes?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  guestProfilesGuid?: Resolver<
    Maybe<ResolversTypes['ID']>,
    ParentType,
    ContextType
  >
  guestTags?: Resolver<
    Maybe<Array<ResolversTypes['String']>>,
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  lastVisitDate?: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >
  phoneNumber?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  piServicePhoneNumberToken?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  restaurant?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  rotationPhoneNumber?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  toastMarketable?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  updatedAt?: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GuestAlreadyExistsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GuestAlreadyExists'] = ResolversParentTypes['GuestAlreadyExists']
> = {
  guest?: Resolver<ResolversTypes['Guest'], ParentType, ContextType>
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  status?: Resolver<ResolversTypes['ErrorCode'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GuestCountResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GuestCount'] = ResolversParentTypes['GuestCount']
> = {
  hourly?: Resolver<
    Array<ResolversTypes['IntegerMetric']>,
    ParentType,
    ContextType
  >
  totals?: Resolver<ResolversTypes['IntegerMetric'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GuestCreationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GuestCreationResponse'] = ResolversParentTypes['GuestCreationResponse']
> = {
  __resolveType: TypeResolveFn<
    | 'GuestAlreadyExists'
    | 'GuestCreationResponseSuccess'
    | 'InvalidPhoneNumber'
    | 'RestaurantNotFound'
    | 'UnableToCreateGuest',
    ParentType,
    ContextType
  >
}

export type GuestCreationResponseSuccessResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GuestCreationResponseSuccess'] = ResolversParentTypes['GuestCreationResponseSuccess']
> = {
  guest?: Resolver<ResolversTypes['Guest'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GuestFeedbackAiSummaryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GuestFeedbackAiSummary'] = ResolversParentTypes['GuestFeedbackAiSummary']
> = {
  createdDate?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  managementGroup?: Resolver<
    ResolversTypes['ManagementGroup'],
    ParentType,
    ContextType
  >
  modifiedDate?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  restaurant?: Resolver<
    Maybe<ResolversTypes['SavedRestaurantLocation']>,
    ParentType,
    ContextType
  >
  summary?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  topNegativeKeywords?: Resolver<
    Array<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  topNeutralKeywords?: Resolver<
    Array<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  topPositiveKeywords?: Resolver<
    Array<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GuestFeedbackContactInformationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GuestFeedbackContactInformation'] = ResolversParentTypes['GuestFeedbackContactInformation']
> = {
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  optIn?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  phone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GuestFeedbackCountByWeekResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GuestFeedbackCountByWeek'] = ResolversParentTypes['GuestFeedbackCountByWeek']
> = {
  negativeFeedbackCount?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType
  >
  positiveFeedbackCount?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType
  >
  weekStartDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GuestFeedbackGenerateResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GuestFeedbackGenerateResponse'] = ResolversParentTypes['GuestFeedbackGenerateResponse']
> = {
  __resolveType: TypeResolveFn<
    'GeneratedGuestFeedbackAIResponse' | 'GuestFeedbackGenerateResponseError',
    ParentType,
    ContextType
  >
}

export type GuestFeedbackGenerateResponseErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GuestFeedbackGenerateResponseError'] = ResolversParentTypes['GuestFeedbackGenerateResponseError']
> = {
  code?: Resolver<
    ResolversTypes['GuestFeedbackGenerateResponseErrorCode'],
    ParentType,
    ContextType
  >
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GuestFeedbackInfoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GuestFeedbackInfo'] = ResolversParentTypes['GuestFeedbackInfo']
> = {
  check?: Resolver<Maybe<ResolversTypes['Check']>, ParentType, ContextType>
  contactInformation?: Resolver<
    Maybe<ResolversTypes['GuestFeedbackContactInformation']>,
    ParentType,
    ContextType
  >
  createdDate?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  feedbackText?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  managementGroup?: Resolver<
    Maybe<ResolversTypes['ManagementGroup']>,
    ParentType,
    ContextType
  >
  menuItem?: Resolver<
    Maybe<ResolversTypes['MenuItem']>,
    ParentType,
    ContextType
  >
  menuItemFeedbacks?: Resolver<
    Array<ResolversTypes['MenuItemFeedback']>,
    ParentType,
    ContextType
  >
  order?: Resolver<Maybe<ResolversTypes['Order']>, ParentType, ContextType>
  orderCheckInfo?: Resolver<
    Maybe<ResolversTypes['GuestFeedbackOrderCheckInfo']>,
    ParentType,
    ContextType
  >
  payment?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  rating?: Resolver<
    Maybe<ResolversTypes['GuestFeedbackRatingType']>,
    ParentType,
    ContextType
  >
  rawRating?: Resolver<
    Maybe<ResolversTypes['GuestFeedbackRawRatingType']>,
    ParentType,
    ContextType
  >
  reasons?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>
  responses?: Resolver<
    Array<ResolversTypes['GuestFeedbackResponse']>,
    ParentType,
    ContextType
  >
  restaurant?: Resolver<
    Maybe<ResolversTypes['SavedRestaurantLocation']>,
    ParentType,
    ContextType
  >
  source?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  status?: Resolver<
    Maybe<ResolversTypes['GuestFeedbackStatus']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GuestFeedbackMutationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GuestFeedbackMutation'] = ResolversParentTypes['GuestFeedbackMutation']
> = {
  feedbackStatus?: Resolver<
    ResolversTypes['GuestFeedbackInfo'],
    ParentType,
    ContextType,
    RequireFields<GuestFeedbackMutationFeedbackStatusArgs, 'id' | 'status'>
  >
  generateFeedbackResponse?: Resolver<
    ResolversTypes['GuestFeedbackGenerateResponse'],
    ParentType,
    ContextType,
    RequireFields<GuestFeedbackMutationGenerateFeedbackResponseArgs, 'data'>
  >
  sendFeedbackResponse?: Resolver<
    ResolversTypes['GuestFeedbackInfo'],
    ParentType,
    ContextType,
    RequireFields<GuestFeedbackMutationSendFeedbackResponseArgs, 'data'>
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GuestFeedbackOrderCheckInfoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GuestFeedbackOrderCheckInfo'] = ResolversParentTypes['GuestFeedbackOrderCheckInfo']
> = {
  checkNumber?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  serverName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  totalAmount?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GuestFeedbackQueryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GuestFeedbackQuery'] = ResolversParentTypes['GuestFeedbackQuery']
> = {
  aiSummary?: Resolver<
    ResolversTypes['GuestFeedbackAiSummary'],
    ParentType,
    ContextType,
    RequireFields<GuestFeedbackQueryAiSummaryArgs, 'filter'>
  >
  guestFeedbackDetails?: Resolver<
    Maybe<ResolversTypes['GuestFeedbackInfo']>,
    ParentType,
    ContextType,
    RequireFields<GuestFeedbackQueryGuestFeedbackDetailsArgs, 'id'>
  >
  guestFeedbacks?: Resolver<
    Array<ResolversTypes['GuestFeedbackInfo']>,
    ParentType,
    ContextType,
    RequireFields<GuestFeedbackQueryGuestFeedbacksArgs, 'filter' | 'pagination'>
  >
  hasManagementSetPermission?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  statsSummary?: Resolver<
    Maybe<ResolversTypes['GuestFeedbackStatsSummaryResponse']>,
    ParentType,
    ContextType,
    RequireFields<GuestFeedbackQueryStatsSummaryArgs, 'filter'>
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GuestFeedbackResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GuestFeedbackResponse'] = ResolversParentTypes['GuestFeedbackResponse']
> = {
  createdDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  response?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  type?: Resolver<
    ResolversTypes['GuestFeedbackResponseType'],
    ParentType,
    ContextType
  >
  userID?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GuestFeedbackSentimentByMenuItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GuestFeedbackSentimentByMenuItem'] = ResolversParentTypes['GuestFeedbackSentimentByMenuItem']
> = {
  menuItem?: Resolver<
    Maybe<ResolversTypes['MenuItem']>,
    ParentType,
    ContextType
  >
  noOfFeedbacks?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  percentageSentiment?: Resolver<
    ResolversTypes['Float'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GuestFeedbackSentimentOverviewResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GuestFeedbackSentimentOverview'] = ResolversParentTypes['GuestFeedbackSentimentOverview']
> = {
  feedbackCountByWeek?: Resolver<
    Array<ResolversTypes['GuestFeedbackCountByWeek']>,
    ParentType,
    ContextType
  >
  sentimentScore?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  sentimentScoreDelta?: Resolver<
    ResolversTypes['Float'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GuestFeedbackStatsSummaryResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GuestFeedbackStatsSummaryResponse'] = ResolversParentTypes['GuestFeedbackStatsSummaryResponse']
> = {
  createdDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  managementGroup?: Resolver<
    ResolversTypes['ManagementGroup'],
    ParentType,
    ContextType
  >
  modifiedDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  numberOfClosedFeedback?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType
  >
  numberOfOpenFeedback?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType
  >
  numberOfOpenFeedbackDelta?: Resolver<
    ResolversTypes['Float'],
    ParentType,
    ContextType
  >
  numberOfPriorityFeedback?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType
  >
  restaurant?: Resolver<
    Maybe<ResolversTypes['SavedRestaurantLocation']>,
    ParentType,
    ContextType
  >
  sentimentOverview?: Resolver<
    ResolversTypes['GuestFeedbackSentimentOverview'],
    ParentType,
    ContextType
  >
  topNegativeMenuItems?: Resolver<
    Array<ResolversTypes['GuestFeedbackSentimentByMenuItem']>,
    ParentType,
    ContextType
  >
  topPositiveMenuItems?: Resolver<
    Array<ResolversTypes['GuestFeedbackSentimentByMenuItem']>,
    ParentType,
    ContextType
  >
  totalNumberOfFeedbackDelta?: Resolver<
    ResolversTypes['Float'],
    ParentType,
    ContextType
  >
  totalNumberOfFeedbacks?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GuestListResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GuestListResponse'] = ResolversParentTypes['GuestListResponse']
> = {
  __resolveType: TypeResolveFn<
    'GuestListResponseSuccess' | 'GuestNotFound',
    ParentType,
    ContextType
  >
}

export type GuestListResponseSuccessResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GuestListResponseSuccess'] = ResolversParentTypes['GuestListResponseSuccess']
> = {
  guests?: Resolver<Array<ResolversTypes['Guest']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GuestNotFoundResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GuestNotFound'] = ResolversParentTypes['GuestNotFound']
> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  status?: Resolver<ResolversTypes['ErrorCode'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GuestQuoteTimesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GuestQuoteTimes'] = ResolversParentTypes['GuestQuoteTimes']
> = {
  deliveryEnabled?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  deliveryThrottlingTimeMinutes?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >
  deliveryTimeMinutes?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  itemsPerSlot?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  kitchenCapacity?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  maximumAutomatedPrepTimeMinutes?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >
  minDeliveryHours?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >
  minDeliveryMinutes?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >
  minTakeoutHours?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >
  minTakeoutMinutes?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >
  minimumAutomatedPrepTimeMinutes?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >
  orderPerSlot?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  quoteTimes?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['QuoteTime']>>>,
    ParentType,
    ContextType
  >
  strategy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  takeoutEnabled?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  takeoutThrottlingTimeMinutes?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GuestUpdateResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GuestUpdateResponse'] = ResolversParentTypes['GuestUpdateResponse']
> = {
  __resolveType: TypeResolveFn<
    | 'GuestAlreadyExists'
    | 'GuestNotFound'
    | 'GuestUpdateResponseSuccess'
    | 'InvalidPhoneNumber'
    | 'RestaurantNotFound'
    | 'UnableToUpdateGuest',
    ParentType,
    ContextType
  >
}

export type GuestUpdateResponseSuccessResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GuestUpdateResponseSuccess'] = ResolversParentTypes['GuestUpdateResponseSuccess']
> = {
  guest?: Resolver<ResolversTypes['Guest'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type HardwareItemOrderedResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HardwareItemOrdered'] = ResolversParentTypes['HardwareItemOrdered']
> = {
  image?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  itemId?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  quantity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type HardwareOrderResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HardwareOrder'] = ResolversParentTypes['HardwareOrder']
> = {
  deliveryInfo?: Resolver<
    ResolversTypes['HardwareOrderDeliveryInfo'],
    ParentType,
    ContextType
  >
  itemsOrdered?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['HardwareItemOrdered']>>>,
    ParentType,
    ContextType
  >
  orderConfirmedDate?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  status?: Resolver<
    ResolversTypes['HardwareOrderStatus'],
    ParentType,
    ContextType
  >
  trackingInfo?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['HardwareOrderTrackingInfo']>>>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type HardwareOrderDeliveryAddressResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HardwareOrderDeliveryAddress'] = ResolversParentTypes['HardwareOrderDeliveryAddress']
> = {
  city?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  country?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  formattedAddress?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  state?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  street?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  zipCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type HardwareOrderDeliveryInfoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HardwareOrderDeliveryInfo'] = ResolversParentTypes['HardwareOrderDeliveryInfo']
> = {
  actualDeliveryDate?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  actualDeliveryTime?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  deliveryAddress?: Resolver<
    ResolversTypes['HardwareOrderDeliveryAddress'],
    ParentType,
    ContextType
  >
  estimatedDeliveryDate?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  estimatedDeliveryWindow?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type HardwareOrderTrackingInfoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HardwareOrderTrackingInfo'] = ResolversParentTypes['HardwareOrderTrackingInfo']
> = {
  carrier?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  shipMethod?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  trackingLink?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  trackingNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type IrlCustomerBillingInvoiceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['IRLCustomerBillingInvoice'] = ResolversParentTypes['IRLCustomerBillingInvoice']
> = {
  __resolveReference?: ReferenceResolver<
    Maybe<ResolversTypes['IRLCustomerBillingInvoice']>,
    { __typename: 'IRLCustomerBillingInvoice' } & GraphQLRecursivePick<
      ParentType,
      { id: true; restaurant: { id: true } }
    >,
    ContextType
  >
  billingAddress?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  createdDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>
  deductions?: Resolver<
    ResolversTypes['CustomerBillingInvoiceDeductions'],
    ParentType,
    ContextType
  >
  dueDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  items?: Resolver<
    Array<ResolversTypes['IRLCustomerBillingInvoiceItem']>,
    ParentType,
    ContextType
  >
  netsuiteId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  orderType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  restaurant?: Resolver<
    Maybe<ResolversTypes['Restaurant']>,
    ParentType,
    ContextType
  >
  salesforceId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  shipping?: Resolver<
    ResolversTypes['CustomerBillingInvoiceShipping'],
    ParentType,
    ContextType
  >
  shippingAddress?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  status?: Resolver<
    ResolversTypes['CustomerBillingInvoiceStatus'],
    ParentType,
    ContextType
  >
  terms?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  totals?: Resolver<
    ResolversTypes['CustomerBillingInvoiceTotals'],
    ParentType,
    ContextType
  >
  transactionDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>
  type?: Resolver<
    ResolversTypes['CustomerBillingInvoiceType'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type IrlCustomerBillingInvoiceItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['IRLCustomerBillingInvoiceItem'] = ResolversParentTypes['IRLCustomerBillingInvoiceItem']
> = {
  amount?: Resolver<ResolversTypes['Money'], ParentType, ContextType>
  children?: Resolver<
    Array<ResolversTypes['IRLCustomerBillingInvoiceItem']>,
    ParentType,
    ContextType
  >
  endDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  parentProduct?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  quantity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  rate?: Resolver<ResolversTypes['Money'], ParentType, ContextType>
  startDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>
  taxAmount?: Resolver<ResolversTypes['Money'], ParentType, ContextType>
  taxRate?: Resolver<ResolversTypes['Money'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type IdentityResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Identity'] = ResolversParentTypes['Identity']
> = {
  __resolveType: TypeResolveFn<
    'SystemIdentity' | 'UnknownIdentity' | 'UserIdentity',
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>
}

export type IdentityInterfaceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['IdentityInterface'] = ResolversParentTypes['IdentityInterface']
> = {
  __resolveType: TypeResolveFn<
    'IdmIdentity' | 'IdmUsername',
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['IDM_IDENTITY_TYPE'], ParentType, ContextType>
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>
}

export type IdmAdminUserResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['IdmAdminUser'] = ResolversParentTypes['IdmAdminUser']
> = {
  createdDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>
  deletedDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  identities?: Resolver<
    Array<ResolversTypes['IdmIdentity']>,
    ParentType,
    ContextType
  >
  name?: Resolver<ResolversTypes['IdmName'], ParentType, ContextType>
  updatedDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>
  username?: Resolver<ResolversTypes['IdmUsername'], ParentType, ContextType>
  version?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type IdmCustomerUserResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['IdmCustomerUser'] = ResolversParentTypes['IdmCustomerUser']
> = {
  canLogin?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  createdDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>
  deletedDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  identities?: Resolver<
    Array<ResolversTypes['IdmIdentity']>,
    ParentType,
    ContextType
  >
  name?: Resolver<ResolversTypes['IdmName'], ParentType, ContextType>
  updatedDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>
  username?: Resolver<ResolversTypes['IdmUsername'], ParentType, ContextType>
  version?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type IdmExternalDeveloperUserResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['IdmExternalDeveloperUser'] = ResolversParentTypes['IdmExternalDeveloperUser']
> = {
  createdDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>
  deletedDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  identities?: Resolver<
    Array<ResolversTypes['IdmIdentity']>,
    ParentType,
    ContextType
  >
  name?: Resolver<ResolversTypes['IdmName'], ParentType, ContextType>
  updatedDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>
  username?: Resolver<ResolversTypes['IdmUsername'], ParentType, ContextType>
  version?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type IdmGuestAppMetadataResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['IdmGuestAppMetadata'] = ResolversParentTypes['IdmGuestAppMetadata']
> = {
  isProfileCreated?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type IdmGuestUserResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['IdmGuestUser'] = ResolversParentTypes['IdmGuestUser']
> = {
  appMetadata?: Resolver<
    Maybe<ResolversTypes['IdmGuestAppMetadata']>,
    ParentType,
    ContextType
  >
  createdDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>
  deletedDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  identities?: Resolver<
    Array<ResolversTypes['IdmIdentity']>,
    ParentType,
    ContextType
  >
  name?: Resolver<ResolversTypes['IdmName'], ParentType, ContextType>
  updatedDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>
  username?: Resolver<ResolversTypes['IdmUsername'], ParentType, ContextType>
  version?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type IdmIdentityResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['IdmIdentity'] = ResolversParentTypes['IdmIdentity']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isPrimary?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  issuer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  type?: Resolver<ResolversTypes['IDM_IDENTITY_TYPE'], ParentType, ContextType>
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  verification?: Resolver<
    ResolversTypes['IdmIdentityVerification'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type IdmIdentityVerificationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['IdmIdentityVerification'] = ResolversParentTypes['IdmIdentityVerification']
> = {
  date?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type IdmNameResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['IdmName'] = ResolversParentTypes['IdmName']
> = {
  chosen?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  first?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  last?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  middle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type IdmUsernameResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['IdmUsername'] = ResolversParentTypes['IdmUsername']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['IDM_IDENTITY_TYPE'], ParentType, ContextType>
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type IdmUsersQueryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['IdmUsersQuery'] = ResolversParentTypes['IdmUsersQuery']
> = {
  adminUser?: Resolver<
    Maybe<ResolversTypes['IdmAdminUser']>,
    ParentType,
    ContextType,
    RequireFields<IdmUsersQueryAdminUserArgs, 'filters'>
  >
  customerUser?: Resolver<
    Maybe<ResolversTypes['IdmCustomerUser']>,
    ParentType,
    ContextType,
    RequireFields<IdmUsersQueryCustomerUserArgs, 'filters'>
  >
  externalDeveloperUser?: Resolver<
    Maybe<ResolversTypes['IdmExternalDeveloperUser']>,
    ParentType,
    ContextType,
    RequireFields<IdmUsersQueryExternalDeveloperUserArgs, 'filters'>
  >
  guestUser?: Resolver<
    Maybe<ResolversTypes['IdmGuestUser']>,
    ParentType,
    ContextType,
    RequireFields<IdmUsersQueryGuestUserArgs, 'filters'>
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ImplementationEmploymentStatusResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ImplementationEmploymentStatus'] = ResolversParentTypes['ImplementationEmploymentStatus']
> = {
  adjustedHireDate?: Resolver<
    Maybe<ResolversTypes['Date']>,
    ParentType,
    ContextType
  >
  hireDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type InProgressEmployeeShiftResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['InProgressEmployeeShift'] = ResolversParentTypes['InProgressEmployeeShift']
> = {
  businessDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>
  employee?: Resolver<ResolversTypes['RestaurantUser'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isDeleted?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  job?: Resolver<Maybe<ResolversTypes['Job']>, ParentType, ContextType>
  restaurant?: Resolver<ResolversTypes['Restaurant'], ParentType, ContextType>
  schedule?: Resolver<
    Maybe<ResolversTypes['EmployeeSchedule']>,
    ParentType,
    ContextType
  >
  shiftCanBeClockedOut?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  status?: Resolver<
    Maybe<ResolversTypes['EmployeeShiftStatus']>,
    ParentType,
    ContextType
  >
  timeClock?: Resolver<
    Maybe<ResolversTypes['EmployeeTime']>,
    ParentType,
    ContextType
  >
  tips?: Resolver<
    Maybe<ResolversTypes['EmployeeTips']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type InProgressEmployeeShiftV2Resolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['InProgressEmployeeShiftV2'] = ResolversParentTypes['InProgressEmployeeShiftV2']
> = {
  businessDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>
  employee?: Resolver<ResolversTypes['RestaurantUser'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isDeleted?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  job?: Resolver<Maybe<ResolversTypes['Job']>, ParentType, ContextType>
  restaurant?: Resolver<ResolversTypes['Restaurant'], ParentType, ContextType>
  schedule?: Resolver<
    Maybe<ResolversTypes['EmployeeScheduleV2']>,
    ParentType,
    ContextType
  >
  status?: Resolver<
    Maybe<ResolversTypes['EmployeeShiftStatus']>,
    ParentType,
    ContextType
  >
  timeClock?: Resolver<
    Maybe<ResolversTypes['EmployeeTime']>,
    ParentType,
    ContextType
  >
  tips?: Resolver<
    Maybe<ResolversTypes['EmployeeTipsV2']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type InProgressEmployeeTakenBreakResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['InProgressEmployeeTakenBreak'] = ResolversParentTypes['InProgressEmployeeTakenBreak']
> = {
  config?: Resolver<ResolversTypes['BreakConfig'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isPaid?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  startTime?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type InProgressEmployeeTimeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['InProgressEmployeeTime'] = ResolversParentTypes['InProgressEmployeeTime']
> = {
  inTime?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  missedBreaks?: Resolver<
    Array<ResolversTypes['EmployeeMissedBreak']>,
    ParentType,
    ContextType
  >
  takenBreaks?: Resolver<
    Array<ResolversTypes['EmployeeTakenBreak']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type IntegerMetricResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['IntegerMetric'] = ResolversParentTypes['IntegerMetric']
> = {
  comparison?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  current?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  endTime?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  startTime?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  variation?: Resolver<
    Maybe<ResolversTypes['Comparison']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type IntercomConversationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['IntercomConversation'] = ResolversParentTypes['IntercomConversation']
> = {
  conversationParts?: Resolver<
    Array<ResolversTypes['IntercomConversationPart']>,
    ParentType,
    ContextType
  >
  conversationRating?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  finInvolved?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  finOutcome?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  originUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  restaurantLocation?: Resolver<
    Maybe<ResolversTypes['SavedRestaurantLocation']>,
    ParentType,
    ContextType
  >
  state?: Resolver<
    ResolversTypes['IntercomConversationState'],
    ParentType,
    ContextType
  >
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type IntercomConversationPartResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['IntercomConversationPart'] = ResolversParentTypes['IntercomConversationPart']
> = {
  author?: Resolver<
    ResolversTypes['IntercomConversationPartAuthor'],
    ParentType,
    ContextType
  >
  body?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  timeStamp?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  type?: Resolver<
    ResolversTypes['IntercomConversationPartType'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type IntercomConversationPartAuthorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['IntercomConversationPartAuthor'] = ResolversParentTypes['IntercomConversationPartAuthor']
> = {
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  type?: Resolver<
    ResolversTypes['IntercomConversationPartAuthorType'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type IntercomOptionsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['IntercomOptions'] = ResolversParentTypes['IntercomOptions']
> = {
  isIntercomMessengerEnabled?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  serviceModel?: Resolver<
    ResolversTypes['ServiceModel'],
    ParentType,
    ContextType
  >
  userHash?: Resolver<
    Maybe<ResolversTypes['IntercomUserHashResult']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type IntercomUserHashResultResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['IntercomUserHashResult'] = ResolversParentTypes['IntercomUserHashResult']
> = {
  hmacDigest?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type InternalErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['InternalError'] = ResolversParentTypes['InternalError']
> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  status?: Resolver<ResolversTypes['ErrorCode'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type InvalidBreakConfigResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['InvalidBreakConfig'] = ResolversParentTypes['InvalidBreakConfig']
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type InvalidBreaksResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['InvalidBreaks'] = ResolversParentTypes['InvalidBreaks']
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type InvalidCustomerUuidErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['InvalidCustomerUuidError'] = ResolversParentTypes['InvalidCustomerUuidError']
> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type InvalidEmailResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['InvalidEmail'] = ResolversParentTypes['InvalidEmail']
> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  status?: Resolver<ResolversTypes['ErrorCode'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type InvalidGuestResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['InvalidGuest'] = ResolversParentTypes['InvalidGuest']
> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  status?: Resolver<ResolversTypes['ErrorCode'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type InvalidIdempotencyKeyErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['InvalidIdempotencyKeyError'] = ResolversParentTypes['InvalidIdempotencyKeyError']
> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type InvalidJobResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['InvalidJob'] = ResolversParentTypes['InvalidJob']
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type InvalidLocationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['InvalidLocation'] = ResolversParentTypes['InvalidLocation']
> = {
  locationId?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  reason?: Resolver<
    ResolversTypes['InvalidLocationReason'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type InvalidPhoneNumberResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['InvalidPhoneNumber'] = ResolversParentTypes['InvalidPhoneNumber']
> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  status?: Resolver<ResolversTypes['ErrorCode'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type InvalidRestaurantIdErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['InvalidRestaurantIDError'] = ResolversParentTypes['InvalidRestaurantIDError']
> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type InvalidUiOptionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['InvalidUIOption'] = ResolversParentTypes['InvalidUIOption']
> = {
  smartTax?: Resolver<
    Maybe<ResolversTypes['UIOptionInvalidSmartTax']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type InvalidUpdatedTimeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['InvalidUpdatedTime'] = ResolversParentTypes['InvalidUpdatedTime']
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  subType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type InvalidUuidErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['InvalidUuidError'] = ResolversParentTypes['InvalidUuidError']
> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ItemFeedbackConfigResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ItemFeedbackConfig'] = ResolversParentTypes['ItemFeedbackConfig']
> = {
  itemFeedbackEnabled?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  notificationContacts?: Resolver<
    Array<ResolversTypes['NotificationContact']>,
    ParentType,
    ContextType
  >
  unsavedNotificationContacts?: Resolver<
    Array<ResolversTypes['NotificationContact']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ItemFeedbackConfigAlreadyExistsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ItemFeedbackConfigAlreadyExists'] = ResolversParentTypes['ItemFeedbackConfigAlreadyExists']
> = {
  existingConfig?: Resolver<
    ResolversTypes['ItemFeedbackConfig'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ItemFeedbackConfigErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ItemFeedbackConfigError'] = ResolversParentTypes['ItemFeedbackConfigError']
> = {
  code?: Resolver<
    ResolversTypes['ItemFeedbackConfigErrorCode'],
    ParentType,
    ContextType
  >
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ItemFeedbackConfigResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ItemFeedbackConfigResponse'] = ResolversParentTypes['ItemFeedbackConfigResponse']
> = {
  __resolveType: TypeResolveFn<
    'ItemFeedbackConfig' | 'ItemFeedbackConfigError',
    ParentType,
    ContextType
  >
}

export type ItemMetricsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ItemMetrics'] = ResolversParentTypes['ItemMetrics']
> = {
  item?: Resolver<ResolversTypes['MenuItem'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  salesQuantity?: Resolver<
    ResolversTypes['DecimalMetric'],
    ParentType,
    ContextType
  >
  totalNetSales?: Resolver<
    ResolversTypes['MoneyMetric'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ItemReferenceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ItemReference'] = ResolversParentTypes['ItemReference']
> = {
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export interface JsonScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['JSON'], any> {
  name: 'JSON'
}

export type JobResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Job'] = ResolversParentTypes['Job']
> = {
  __resolveReference?: ReferenceResolver<
    Maybe<ResolversTypes['Job']>,
    { __typename: 'Job' } & GraphQLRecursivePick<ParentType, { id: true }>,
    ContextType
  >
  basis?: Resolver<
    Maybe<ResolversTypes['Basis']>,
    { __typename: 'Job' } & GraphQLRecursivePick<ParentType, { id: true }>,
    ContextType
  >
  cashier?: Resolver<
    ResolversTypes['Boolean'],
    { __typename: 'Job' } & GraphQLRecursivePick<ParentType, { id: true }>,
    ContextType
  >
  code?: Resolver<
    Maybe<ResolversTypes['String']>,
    { __typename: 'Job' } & GraphQLRecursivePick<ParentType, { id: true }>,
    ContextType
  >

  isDeleted?: Resolver<
    ResolversTypes['Boolean'],
    { __typename: 'Job' } & GraphQLRecursivePick<ParentType, { id: true }>,
    ContextType
  >
  isTipped?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    { __typename: 'Job' } & GraphQLRecursivePick<ParentType, { id: true }>,
    ContextType
  >

  title?: Resolver<
    Maybe<ResolversTypes['String']>,
    { __typename: 'Job' } & GraphQLRecursivePick<ParentType, { id: true }>,
    ContextType
  >
  wageFrequency?: Resolver<
    ResolversTypes['WageFrequency'],
    { __typename: 'Job' } & GraphQLRecursivePick<ParentType, { id: true }>,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type JobAssignmentDefaultsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['JobAssignmentDefaults'] = ResolversParentTypes['JobAssignmentDefaults']
> = {
  defaultRate?: Resolver<ResolversTypes['DefaultRate'], ParentType, ContextType>
  defaultToastPermissions?: Resolver<
    Array<ResolversTypes['ToastPermission']>,
    ParentType,
    ContextType
  >
  extendedProperties?: Resolver<
    ResolversTypes['JobAssignmentDefaultsExtendedProperties'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type JobAssignmentDefaultsExtendedPropertiesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['JobAssignmentDefaultsExtendedProperties'] = ResolversParentTypes['JobAssignmentDefaultsExtendedProperties']
> = {
  isCashier?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  isDriver?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type JobAssignmentInfoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['JobAssignmentInfo'] = ResolversParentTypes['JobAssignmentInfo']
> = {
  locationName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type JobBasedAccessConfigResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['JobBasedAccessConfig'] = ResolversParentTypes['JobBasedAccessConfig']
> = {
  enabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  managementGroup?: Resolver<
    ResolversTypes['ManagementGroup'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type JobErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['JobError'] = ResolversParentTypes['JobError']
> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type JobExtendedPropertiesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['JobExtendedProperties'] = ResolversParentTypes['JobExtendedProperties']
> = {
  excludeFromLaborReporting?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  hideFromSchedule?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  isTipped?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type JobMappingResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['JobMapping'] = ResolversParentTypes['JobMapping']
> = {
  job?: Resolver<ResolversTypes['JobV2'], ParentType, ContextType>
  legacyJobId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type JobMappingsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['JobMappings'] = ResolversParentTypes['JobMappings']
> = {
  jobMappings?: Resolver<
    Array<ResolversTypes['JobMapping']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type JobNotFoundErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['JobNotFoundError'] = ResolversParentTypes['JobNotFoundError']
> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type JobResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['JobResponse'] = ResolversParentTypes['JobResponse']
> = {
  __resolveType: TypeResolveFn<
    | 'InvalidUuidError'
    | 'JobError'
    | 'JobNotFoundError'
    | 'JobV2'
    | 'UnauthorizedAccessError',
    ParentType,
    ContextType
  >
}

export type JobTagAssignmentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['JobTagAssignment'] = ResolversParentTypes['JobTagAssignment']
> = {
  jobTagType?: Resolver<ResolversTypes['JobTagType'], ParentType, ContextType>
  jobTagValue?: Resolver<ResolversTypes['JobTagValue'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type JobTagValueResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['JobTagValue'] = ResolversParentTypes['JobTagValue']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type JobV2Resolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['JobV2'] = ResolversParentTypes['JobV2']
> = {
  __resolveReference?: ReferenceResolver<
    Maybe<ResolversTypes['JobV2']>,
    { __typename: 'JobV2' } & GraphQLRecursivePick<ParentType, { id: true }>,
    ContextType
  >
  assignableLocationGroups?: Resolver<
    Array<ResolversTypes['LocationGroup']>,
    ParentType,
    ContextType
  >
  createdTimestamp?: Resolver<
    ResolversTypes['DateTime'],
    ParentType,
    ContextType
  >
  deletedTimestamp?: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >
  extendedProperties?: Resolver<
    ResolversTypes['JobExtendedProperties'],
    ParentType,
    ContextType
  >
  externalIdentifier?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  jobAssignmentDefaults?: Resolver<
    ResolversTypes['JobAssignmentDefaults'],
    ParentType,
    ContextType
  >
  jobColor?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  jobTagAssignments?: Resolver<
    Array<ResolversTypes['JobTagAssignment']>,
    ParentType,
    ContextType
  >
  modifiedTimestamp?: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  version?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type JobsBatchResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['JobsBatchResponse'] = ResolversParentTypes['JobsBatchResponse']
> = {
  __resolveType: TypeResolveFn<
    | 'JobError'
    | 'JobsBatchSizeError'
    | 'JobsNotFoundBatchError'
    | 'JobsV2'
    | 'NoJobIdsProvidedBatchError'
    | 'UnauthorizedAccessError',
    ParentType,
    ContextType
  >
}

export type JobsBatchSizeErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['JobsBatchSizeError'] = ResolversParentTypes['JobsBatchSizeError']
> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type JobsFilterMissingErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['JobsFilterMissingError'] = ResolversParentTypes['JobsFilterMissingError']
> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type JobsLegacyBatchResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['JobsLegacyBatchResponse'] = ResolversParentTypes['JobsLegacyBatchResponse']
> = {
  __resolveType: TypeResolveFn<
    | 'JobError'
    | 'JobMappings'
    | 'JobsBatchSizeError'
    | 'JobsNotFoundBatchError'
    | 'NoJobIdsProvidedBatchError'
    | 'UnauthorizedAccessError',
    ParentType,
    ContextType
  >
}

export type JobsNotFoundBatchErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['JobsNotFoundBatchError'] = ResolversParentTypes['JobsNotFoundBatchError']
> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type JobsPaginatedResultResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['JobsPaginatedResult'] = ResolversParentTypes['JobsPaginatedResult']
> = {
  currentPageToken?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  jobs?: Resolver<Array<ResolversTypes['JobV2']>, ParentType, ContextType>
  nextPageToken?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type JobsRepositoryDependencyFailureResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['JobsRepositoryDependencyFailure'] = ResolversParentTypes['JobsRepositoryDependencyFailure']
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type JobsResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['JobsResponse'] = ResolversParentTypes['JobsResponse']
> = {
  __resolveType: TypeResolveFn<
    | 'CorruptPageTokenError'
    | 'JobError'
    | 'JobsFilterMissingError'
    | 'JobsPaginatedResult'
    | 'UnauthorizedAccessError',
    ParentType,
    ContextType
  >
}

export type JobsV2Resolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['JobsV2'] = ResolversParentTypes['JobsV2']
> = {
  jobs?: Resolver<Array<ResolversTypes['JobV2']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type KioskConfigResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['KioskConfig'] = ResolversParentTypes['KioskConfig']
> = {
  brandingColor?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  cashPaymentEnabled?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  cashPaymentFireBeforePaymentPreference?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  cashPaymentInstructionCopy?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  dineInDiningOptionId?: Resolver<
    Maybe<ResolversTypes['ID']>,
    ParentType,
    ContextType
  >
  dineInKioskCustomerMessageSettingsId?: Resolver<
    Maybe<ResolversTypes['ID']>,
    ParentType,
    ContextType
  >
  diningOptionMode?: Resolver<
    Maybe<ResolversTypes['DiningOptionMode']>,
    ParentType,
    ContextType
  >
  displayTheme?: Resolver<
    ResolversTypes['DisplayTheme'],
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  imagesEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  kioskGiftCardsEnabled?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  kioskServerGuid?: Resolver<
    Maybe<ResolversTypes['ID']>,
    ParentType,
    ContextType
  >
  lastUpdated?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  logoMode?: Resolver<ResolversTypes['LogoMode'], ParentType, ContextType>
  loyaltyPromptMode?: Resolver<
    ResolversTypes['LoyaltyPromptMode'],
    ParentType,
    ContextType
  >
  orderBackgroundImageHeightWidthRatio?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >
  orderBackgroundImageImagePath?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  serviceChargesEnabled?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  shouldHideLogo?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  shouldHideOutOfStockItems?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  shouldShowItemDescription?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  shouldShowNewKioskExperience?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  shouldShowOrderNumberOnConfirmationScreen?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  shouldShowTippingButtons?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  shouldUseMenuItemName?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  smallMenuCardsEnabled?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  specialRequestsEnabled?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  specialRequestsInstructionCopy?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  takeOutDiningOptionId?: Resolver<
    Maybe<ResolversTypes['ID']>,
    ParentType,
    ContextType
  >
  takeOutKioskCustomerMessageSettingsId?: Resolver<
    Maybe<ResolversTypes['ID']>,
    ParentType,
    ContextType
  >
  textColor?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  tipPercentage1?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >
  tipPercentage2?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >
  tipPercentage3?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >
  upsellsEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  voidReasonId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  welcomeImageHeightWidthRatio?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >
  welcomeImageImagePath?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  welcomeScreenMode?: Resolver<
    ResolversTypes['WelcomeScreenMode'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type KioskConfigServiceChargeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['KioskConfigServiceCharge'] = ResolversParentTypes['KioskConfigServiceCharge']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  selected?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type KioskCustomerMessageSettingsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['KioskCustomerMessageSettings'] = ResolversParentTypes['KioskCustomerMessageSettings']
> = {
  enableCustomMessage?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  fulfillmentText?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  fulfillmentTextEnabled?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  inputNumberText?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  inputNumberTextEnabled?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  mode?: Resolver<ResolversTypes['MessageOptionMode'], ParentType, ContextType>
  noInputText?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  noInputTextEnabled?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  requiredGuestInformation?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  requiredGuestPhoneNumber?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type KioskDiningOptionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['KioskDiningOption'] = ResolversParentTypes['KioskDiningOption']
> = {
  behavior?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type KioskLoyaltyResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['KioskLoyalty'] = ResolversParentTypes['KioskLoyalty']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type KioskMutationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['KioskMutation'] = ResolversParentTypes['KioskMutation']
> = {
  updateConfig?: Resolver<
    ResolversTypes['KioskConfig'],
    ParentType,
    ContextType,
    RequireFields<KioskMutationUpdateConfigArgs, 'input'>
  >
  updateCustomerMessageSettings?: Resolver<
    Array<ResolversTypes['KioskCustomerMessageSettings']>,
    ParentType,
    ContextType,
    RequireFields<KioskMutationUpdateCustomerMessageSettingsArgs, 'input'>
  >
  updateServiceCharges?: Resolver<
    Array<ResolversTypes['KioskConfigServiceCharge']>,
    ParentType,
    ContextType,
    RequireFields<KioskMutationUpdateServiceChargesArgs, 'input'>
  >
  updateUpsellMenuItems?: Resolver<
    Array<ResolversTypes['KioskUpsellMenuItem']>,
    ParentType,
    ContextType,
    RequireFields<KioskMutationUpdateUpsellMenuItemsArgs, 'input'>
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type KioskQueryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['KioskQuery'] = ResolversParentTypes['KioskQuery']
> = {
  config?: Resolver<ResolversTypes['KioskConfig'], ParentType, ContextType>
  customerMessageSettings?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['KioskCustomerMessageSettings']>>>,
    ParentType,
    ContextType
  >
  diningOptions?: Resolver<
    Array<ResolversTypes['KioskDiningOption']>,
    ParentType,
    ContextType
  >
  loyalty?: Resolver<ResolversTypes['KioskLoyalty'], ParentType, ContextType>
  searchMenuItems?: Resolver<
    Array<ResolversTypes['KioskUpsellMenuItem']>,
    ParentType,
    ContextType,
    RequireFields<KioskQuerySearchMenuItemsArgs, 'input'>
  >
  serviceCharges?: Resolver<
    Array<ResolversTypes['KioskConfigServiceCharge']>,
    ParentType,
    ContextType
  >
  upsellMenuItems?: Resolver<
    Array<ResolversTypes['KioskUpsellMenuItem']>,
    ParentType,
    ContextType
  >
  voidReasons?: Resolver<
    Array<ResolversTypes['KioskVoidReason']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type KioskUpsellMenuItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['KioskUpsellMenuItem'] = ResolversParentTypes['KioskUpsellMenuItem']
> = {
  groupGuid?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  groupName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  guid?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type KioskVoidReasonResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['KioskVoidReason'] = ResolversParentTypes['KioskVoidReason']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type KitchenCapacityHealthStatusResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['KitchenCapacityHealthStatus'] = ResolversParentTypes['KitchenCapacityHealthStatus']
> = {
  details?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  duration?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  healthy?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  time?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  timestamp?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type LaborCostResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LaborCost'] = ResolversParentTypes['LaborCost']
> = {
  grouped?: Resolver<
    Array<ResolversTypes['GroupedLaborCost']>,
    ParentType,
    ContextType,
    RequireFields<LaborCostGroupedArgs, 'by'>
  >
  hourly?: Resolver<
    Array<ResolversTypes['MoneyMetric']>,
    ParentType,
    ContextType
  >
  totals?: Resolver<ResolversTypes['MoneyMetric'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type LaborCostAsPercentageOfNetSalesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LaborCostAsPercentageOfNetSales'] = ResolversParentTypes['LaborCostAsPercentageOfNetSales']
> = {
  hourly?: Resolver<
    Array<ResolversTypes['PercentageMetric']>,
    ParentType,
    ContextType
  >
  totals?: Resolver<ResolversTypes['PercentageMetric'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type LaborCostGroupEntityResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LaborCostGroupEntity'] = ResolversParentTypes['LaborCostGroupEntity']
> = {
  __resolveType: TypeResolveFn<
    'Job' | 'RestaurantUser',
    ParentType,
    ContextType
  >
}

export type LaborMetricsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LaborMetrics'] = ResolversParentTypes['LaborMetrics']
> = {
  laborCost?: Resolver<
    Maybe<ResolversTypes['LaborCost']>,
    ParentType,
    ContextType,
    RequireFields<LaborMetricsLaborCostArgs, 'filter'>
  >
  laborCostAsPercentageOfNetSales?: Resolver<
    Maybe<ResolversTypes['LaborCostAsPercentageOfNetSales']>,
    ParentType,
    ContextType
  >
  netSalesPerLaborHour?: Resolver<
    Maybe<ResolversTypes['NetSalesPerLaborHour']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type LeaveOfAbsenceEmploymentStatusResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LeaveOfAbsenceEmploymentStatus'] = ResolversParentTypes['LeaveOfAbsenceEmploymentStatus']
> = {
  adjustedHireDate?: Resolver<
    Maybe<ResolversTypes['Date']>,
    ParentType,
    ContextType
  >
  hireDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  leaveType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  scheduledLeave?: Resolver<
    Maybe<ResolversTypes['DateRange']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type LoadShiftsFailureResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LoadShiftsFailure'] = ResolversParentTypes['LoadShiftsFailure']
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type LoanApplicationForCustomerResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LoanApplicationForCustomer'] = ResolversParentTypes['LoanApplicationForCustomer']
> = {
  applicationStage?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  ccHoldbackRate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  loanAmount?: Resolver<ResolversTypes['Money'], ParentType, ContextType>
  metadata?: Resolver<
    Maybe<ResolversTypes['ApplicationMetadata']>,
    ParentType,
    ContextType
  >
  preapprovedLoanAmount?: Resolver<
    Maybe<ResolversTypes['Money']>,
    ParentType,
    ContextType
  >
  repaymentAmount?: Resolver<ResolversTypes['Money'], ParentType, ContextType>
  targetMaturityInDays?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export interface LocalTimeScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['LocalTime'], any> {
  name: 'LocalTime'
}

export type LocalTimeRangeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LocalTimeRange'] = ResolversParentTypes['LocalTimeRange']
> = {
  end?: Resolver<ResolversTypes['LocalTime'], ParentType, ContextType>
  start?: Resolver<ResolversTypes['LocalTime'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type LocateShiftFailureResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LocateShiftFailure'] = ResolversParentTypes['LocateShiftFailure']
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type LocationGroupResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LocationGroup'] = ResolversParentTypes['LocationGroup']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type LocationSetupInformationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LocationSetupInformation'] = ResolversParentTypes['LocationSetupInformation']
> = {
  anticipatedPosGoLiveDate?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type LocationSpecificPriceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LocationSpecificPrice'] = ResolversParentTypes['LocationSpecificPrice']
> = {
  basePrice?: Resolver<Maybe<ResolversTypes['Money']>, ParentType, ContextType>
  pricing?: Resolver<
    Maybe<ResolversTypes['MenuItemPricing']>,
    ParentType,
    ContextType
  >
  pricingStrategy?: Resolver<
    ResolversTypes['MenuItemPricingStrategy'],
    ParentType,
    ContextType
  >
  target?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  versionId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type LogbookCategoryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LogbookCategory'] = ResolversParentTypes['LogbookCategory']
> = {
  color?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  colorType?: Resolver<
    ResolversTypes['LogbookCategoryColorType'],
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isDefault?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type LogbookContentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LogbookContent'] = ResolversParentTypes['LogbookContent']
> = {
  contentAsMarkdown?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >
  contentBlocks?: Resolver<
    Array<ResolversTypes['LogbookContentBlock']>,
    ParentType,
    ContextType
  >
  isEdited?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  tags?: Resolver<
    Maybe<Array<ResolversTypes['LogbookContentTag']>>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type LogbookContentBlockResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LogbookContentBlock'] = ResolversParentTypes['LogbookContentBlock']
> = {
  __resolveType: TypeResolveFn<
    'LogbookTextContentBlock' | 'LogbookUserContentBlock',
    ParentType,
    ContextType
  >
  content?: Resolver<ResolversTypes['String'], ParentType, ContextType>
}

export type LogbookContentTagResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LogbookContentTag'] = ResolversParentTypes['LogbookContentTag']
> = {
  __resolveType: TypeResolveFn<'LogbookUserTag', ParentType, ContextType>
  content?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  tag?: Resolver<ResolversTypes['String'], ParentType, ContextType>
}

export type LogbookEntryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LogbookEntry'] = ResolversParentTypes['LogbookEntry']
> = {
  businessDay?: Resolver<ResolversTypes['Date'], ParentType, ContextType>
  category?: Resolver<
    ResolversTypes['LogbookCategory'],
    ParentType,
    ContextType
  >
  content?: Resolver<ResolversTypes['LogbookContent'], ParentType, ContextType>
  entryTime?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  numberOfReplies?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  priority?: Resolver<
    ResolversTypes['LogbookEntryPriority'],
    ParentType,
    ContextType
  >
  replies?: Resolver<
    Array<ResolversTypes['LogbookEntryReply']>,
    ParentType,
    ContextType
  >
  source?: Resolver<
    ResolversTypes['LogbookEntrySource'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type LogbookEntryReplyResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LogbookEntryReply'] = ResolversParentTypes['LogbookEntryReply']
> = {
  content?: Resolver<ResolversTypes['LogbookContent'], ParentType, ContextType>
  entryTime?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  source?: Resolver<
    ResolversTypes['LogbookEntrySource'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type LogbookEntrySourceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LogbookEntrySource'] = ResolversParentTypes['LogbookEntrySource']
> = {
  __resolveType: TypeResolveFn<'User', ParentType, ContextType>
}

export type LogbookMutationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LogbookMutation'] = ResolversParentTypes['LogbookMutation']
> = {
  deleteLogbookEntry?: Resolver<
    Maybe<ResolversTypes['LogbookEntry']>,
    ParentType,
    ContextType,
    RequireFields<LogbookMutationDeleteLogbookEntryArgs, 'entry'>
  >
  deleteLogbookEntryReply?: Resolver<
    Maybe<ResolversTypes['LogbookEntryReply']>,
    ParentType,
    ContextType,
    RequireFields<LogbookMutationDeleteLogbookEntryReplyArgs, 'reply'>
  >
  submitLogbookEntry?: Resolver<
    Maybe<ResolversTypes['LogbookEntry']>,
    ParentType,
    ContextType,
    RequireFields<LogbookMutationSubmitLogbookEntryArgs, 'entry'>
  >
  submitLogbookEntryReply?: Resolver<
    Maybe<ResolversTypes['LogbookEntryReply']>,
    ParentType,
    ContextType,
    RequireFields<LogbookMutationSubmitLogbookEntryReplyArgs, 'reply'>
  >
  updateLogbookEntry?: Resolver<
    Maybe<ResolversTypes['LogbookEntry']>,
    ParentType,
    ContextType,
    RequireFields<LogbookMutationUpdateLogbookEntryArgs, 'entry'>
  >
  updateLogbookEntryReply?: Resolver<
    Maybe<ResolversTypes['LogbookEntryReply']>,
    ParentType,
    ContextType,
    RequireFields<LogbookMutationUpdateLogbookEntryReplyArgs, 'reply'>
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type LogbookQueryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LogbookQuery'] = ResolversParentTypes['LogbookQuery']
> = {
  fetchLogbookCategories?: Resolver<
    Maybe<Array<ResolversTypes['LogbookCategory']>>,
    ParentType,
    ContextType,
    RequireFields<LogbookQueryFetchLogbookCategoriesArgs, 'managementSetId'>
  >
  fetchLogbookEntriesByBusinessDay?: Resolver<
    Maybe<Array<ResolversTypes['LogbookEntry']>>,
    ParentType,
    ContextType,
    RequireFields<
      LogbookQueryFetchLogbookEntriesByBusinessDayArgs,
      'businessDay' | 'locationID'
    >
  >
  fetchLogbookEntry?: Resolver<
    Maybe<ResolversTypes['LogbookEntry']>,
    ParentType,
    ContextType,
    RequireFields<LogbookQueryFetchLogbookEntryArgs, 'id' | 'locationID'>
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type LogbookTextContentBlockResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LogbookTextContentBlock'] = ResolversParentTypes['LogbookTextContentBlock']
> = {
  content?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type LogbookUserContentBlockResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LogbookUserContentBlock'] = ResolversParentTypes['LogbookUserContentBlock']
> = {
  content?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type LogbookUserTagResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LogbookUserTag'] = ResolversParentTypes['LogbookUserTag']
> = {
  content?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  tag?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export interface LongScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['Long'], any> {
  name: 'Long'
}

export type ManagementGroupResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ManagementGroup'] = ResolversParentTypes['ManagementGroup']
> = {
  __resolveReference?: ReferenceResolver<
    Maybe<ResolversTypes['ManagementGroup']>,
    { __typename: 'ManagementGroup' } & GraphQLRecursivePick<
      ParentType,
      { id: true }
    >,
    ContextType
  >
  descendantLocations?: Resolver<
    ResolversTypes['SavedRestaurantLocationConnection'],
    { __typename: 'ManagementGroup' } & GraphQLRecursivePick<
      ParentType,
      { id: true }
    >,
    ContextType,
    RequireFields<ManagementGroupDescendantLocationsArgs, 'after' | 'first'>
  >
  description?: Resolver<
    Maybe<ResolversTypes['String']>,
    { __typename: 'ManagementGroup' } & GraphQLRecursivePick<
      ParentType,
      { id: true }
    >,
    ContextType
  >

  name?: Resolver<
    ResolversTypes['String'],
    { __typename: 'ManagementGroup' } & GraphQLRecursivePick<
      ParentType,
      { id: true }
    >,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ManagementSetResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ManagementSet'] = ResolversParentTypes['ManagementSet']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ManagerClockOutResultResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ManagerClockOutResult'] = ResolversParentTypes['ManagerClockOutResult']
> = {
  __resolveType: TypeResolveFn<
    | 'ClockOutFailure'
    | 'FinishedEmployeeShift'
    | 'InProgressEmployeeShift'
    | 'InvalidUpdatedTime'
    | 'LocateShiftFailure'
    | 'MissingTimeClock'
    | 'RestaurantRepositoryDependencyFailure'
    | 'SaveFailure'
    | 'ShiftAlreadyClockedOut'
    | 'ShiftNotFound'
    | 'Unauthorized',
    ParentType,
    ContextType
  >
}

export interface MarkdownTextScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['MarkdownText'], any> {
  name: 'MarkdownText'
}

export type MarketingCampaignResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MarketingCampaign'] = ResolversParentTypes['MarketingCampaign']
> = {
  channel?: Resolver<
    ResolversTypes['MarketingCampaignChannel'],
    ParentType,
    ContextType
  >
  customProperties?: Resolver<
    Maybe<ResolversTypes['MarketingCampaignCustomProperties']>,
    ParentType,
    ContextType
  >
  externalReferenceUrl?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  status?: Resolver<
    ResolversTypes['MarketingCampaignStatus'],
    ParentType,
    ContextType
  >
  trigger?: Resolver<
    Maybe<ResolversTypes['MarketingCampaignTrigger']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MarketingCampaignCustomPropertiesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MarketingCampaignCustomProperties'] = ResolversParentTypes['MarketingCampaignCustomProperties']
> = {
  recommendationName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MarketingCampaignTriggerResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MarketingCampaignTrigger'] = ResolversParentTypes['MarketingCampaignTrigger']
> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  scheduledFor?: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >
  type?: Resolver<
    ResolversTypes['MarketingCampaignTriggerType'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MarketingQueryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MarketingQuery'] = ResolversParentTypes['MarketingQuery']
> = {
  emailCampaign?: Resolver<
    ResolversTypes['EmailMarketingCampaign'],
    ParentType,
    ContextType,
    RequireFields<MarketingQueryEmailCampaignArgs, 'id'>
  >
  emailCampaigns?: Resolver<
    Array<ResolversTypes['EmailMarketingCampaign']>,
    ParentType,
    ContextType,
    Partial<MarketingQueryEmailCampaignsArgs>
  >
  marketingCampaign?: Resolver<
    ResolversTypes['MarketingCampaign'],
    ParentType,
    ContextType,
    RequireFields<MarketingQueryMarketingCampaignArgs, 'id'>
  >
  marketingCampaigns?: Resolver<
    Array<ResolversTypes['MarketingCampaign']>,
    ParentType,
    ContextType,
    Partial<MarketingQueryMarketingCampaignsArgs>
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MemberGuestBookDataResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MemberGuestBookData'] = ResolversParentTypes['MemberGuestBookData']
> = {
  averageSpend?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  averageTip?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  averageTipPercentage?: Resolver<
    ResolversTypes['Float'],
    ParentType,
    ContextType
  >
  firstVisitDate?: Resolver<
    Maybe<ResolversTypes['Date']>,
    ParentType,
    ContextType
  >
  guestGuid?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  lastVisitDate?: Resolver<
    Maybe<ResolversTypes['Date']>,
    ParentType,
    ContextType
  >
  sumTotalCheckSpend?: Resolver<
    ResolversTypes['Float'],
    ParentType,
    ContextType
  >
  sumTotalTipSpend?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  topMenuItems?: Resolver<
    Array<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  totalVisits?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MembershipResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Membership'] = ResolversParentTypes['Membership']
> = {
  accountNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  address?: Resolver<
    Maybe<ResolversTypes['MembershipAddress']>,
    ParentType,
    ContextType
  >
  archived?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  createdDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>
  customerNumber?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  guestbook?: Resolver<
    Maybe<ResolversTypes['MemberGuestBookData']>,
    ParentType,
    ContextType
  >
  guid?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  lastTransactionDate?: Resolver<
    Maybe<ResolversTypes['Date']>,
    ParentType,
    ContextType
  >
  outstandingBalance?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >
  phone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MembershipAddressResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MembershipAddress'] = ResolversParentTypes['MembershipAddress']
> = {
  address1?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  address2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  notes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  state?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  zip?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MembershipInvoiceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MembershipInvoice'] = ResolversParentTypes['MembershipInvoice']
> = {
  createdDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  dueDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>
  guid?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  number?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  status?: Resolver<
    ResolversTypes['MembershipInvoiceStatus'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MembershipInvoicesResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MembershipInvoicesResponse'] = ResolversParentTypes['MembershipInvoicesResponse']
> = {
  invoices?: Resolver<
    Array<ResolversTypes['MembershipInvoice']>,
    ParentType,
    ContextType
  >
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MembershipTransactionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MembershipTransaction'] = ResolversParentTypes['MembershipTransaction']
> = {
  amount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  balance?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  transactionDate?: Resolver<
    ResolversTypes['DateTime'],
    ParentType,
    ContextType
  >
  transactionId?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  transactionType?: Resolver<
    ResolversTypes['MembershipTransactionType'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MembershipTransactionsResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MembershipTransactionsResponse'] = ResolversParentTypes['MembershipTransactionsResponse']
> = {
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  transactions?: Resolver<
    Array<ResolversTypes['MembershipTransaction']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MembershipsMutationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MembershipsMutation'] = ResolversParentTypes['MembershipsMutation']
> = {
  archiveMembership?: Resolver<
    ResolversTypes['ArchiveMembershipResponse'],
    ParentType,
    ContextType,
    RequireFields<MembershipsMutationArchiveMembershipArgs, 'guid'>
  >
  createMembership?: Resolver<
    ResolversTypes['CreateMembershipResult'],
    ParentType,
    ContextType,
    RequireFields<
      MembershipsMutationCreateMembershipArgs,
      'createMembershipInput'
    >
  >
  restoreMembership?: Resolver<
    ResolversTypes['RestoreMembershipResponse'],
    ParentType,
    ContextType,
    RequireFields<MembershipsMutationRestoreMembershipArgs, 'guid'>
  >
  updateMembership?: Resolver<
    ResolversTypes['UpdateMembershipResult'],
    ParentType,
    ContextType,
    RequireFields<
      MembershipsMutationUpdateMembershipArgs,
      'updateMembershipInput'
    >
  >
  updateSettings?: Resolver<
    ResolversTypes['MembershipsSettingsResponse'],
    ParentType,
    ContextType,
    RequireFields<
      MembershipsMutationUpdateSettingsArgs,
      'membershipsSettingsInput'
    >
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MembershipsQueryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MembershipsQuery'] = ResolversParentTypes['MembershipsQuery']
> = {
  invoices?: Resolver<
    ResolversTypes['MembershipInvoicesResponse'],
    ParentType,
    ContextType,
    RequireFields<MembershipsQueryInvoicesArgs, 'membershipInvoicesInput'>
  >
  membership?: Resolver<
    Maybe<ResolversTypes['Membership']>,
    ParentType,
    ContextType,
    RequireFields<MembershipsQueryMembershipArgs, 'guid'>
  >
  memberships?: Resolver<
    ResolversTypes['MembershipsResponse'],
    ParentType,
    ContextType,
    RequireFields<MembershipsQueryMembershipsArgs, 'membershipsInput'>
  >
  membershipsSummary?: Resolver<
    ResolversTypes['MembershipsSummary'],
    ParentType,
    ContextType
  >
  settings?: Resolver<
    Maybe<ResolversTypes['MembershipsSettings']>,
    ParentType,
    ContextType
  >
  transactions?: Resolver<
    ResolversTypes['MembershipTransactionsResponse'],
    ParentType,
    ContextType,
    RequireFields<
      MembershipsQueryTransactionsArgs,
      'membershipTransactionsInput'
    >
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MembershipsResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MembershipsResponse'] = ResolversParentTypes['MembershipsResponse']
> = {
  memberships?: Resolver<
    Array<ResolversTypes['Membership']>,
    ParentType,
    ContextType
  >
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MembershipsSettingsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MembershipsSettings'] = ResolversParentTypes['MembershipsSettings']
> = {
  houseAccountEnabled?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  invoiceAutoPay?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MembershipsSettingsResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MembershipsSettingsResponse'] = ResolversParentTypes['MembershipsSettingsResponse']
> = {
  code?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MembershipsSummaryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MembershipsSummary'] = ResolversParentTypes['MembershipsSummary']
> = {
  totalMemberships?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  totalOutstandingBalance?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MenuResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Menu'] = ResolversParentTypes['Menu']
> = {
  __resolveReference?: ReferenceResolver<
    Maybe<ResolversTypes['Menu']>,
    { __typename: 'Menu' } & GraphQLRecursivePick<ParentType, { id: true }>,
    ContextType
  >
  channelMenuEntities?: Resolver<
    Maybe<Array<ResolversTypes['ChannelMenuEntity']>>,
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  menuGroupMultiLocationIds?: Resolver<
    Maybe<Array<ResolversTypes['ID']>>,
    ParentType,
    ContextType
  >
  menuGroups?: Resolver<
    Maybe<Array<ResolversTypes['MenuGroup']>>,
    ParentType,
    ContextType
  >
  multiLocationId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  ordinal?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  owner?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  ownerRestaurantSet?: Resolver<
    Maybe<ResolversTypes['RestaurantSet']>,
    ParentType,
    ContextType
  >
  pointOfSale?: Resolver<
    ResolversTypes['MenuEntityPointOfSale'],
    ParentType,
    ContextType
  >
  preparation?: Resolver<
    ResolversTypes['SavedMenuPreparation'],
    ParentType,
    ContextType
  >
  salesCategoryMultiLocationId?: Resolver<
    Maybe<ResolversTypes['ID']>,
    ParentType,
    ContextType
  >
  target?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  targetRestaurantSet?: Resolver<
    ResolversTypes['RestaurantSet'],
    ParentType,
    ContextType
  >
  taxes?: Resolver<ResolversTypes['MenuEntityTaxes'], ParentType, ContextType>
  versionId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  visibility?: Resolver<
    ResolversTypes['MenuEntityVisibility'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MenuEntityResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MenuEntity'] = ResolversParentTypes['MenuEntity']
> = {
  __resolveType: TypeResolveFn<
    | 'Menu'
    | 'MenuGroup'
    | 'MenuItem'
    | 'Modifier'
    | 'ModifierGroup'
    | 'PriceLevelEntity',
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  multiLocationId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  owner?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  ownerRestaurantSet?: Resolver<
    Maybe<ResolversTypes['RestaurantSet']>,
    ParentType,
    ContextType
  >
  target?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  targetRestaurantSet?: Resolver<
    ResolversTypes['RestaurantSet'],
    ParentType,
    ContextType
  >
  versionId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
}

export type MenuEntityAlcoholResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MenuEntityAlcohol'] = ResolversParentTypes['MenuEntityAlcohol']
> = {
  containsAlcohol?: Resolver<
    Maybe<ResolversTypes['ContainsAlcohol']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MenuEntityContentAdvisoriesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MenuEntityContentAdvisories'] = ResolversParentTypes['MenuEntityContentAdvisories']
> = {
  alcohol?: Resolver<
    ResolversTypes['MenuEntityAlcohol'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MenuEntityImageResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MenuEntityImage'] = ResolversParentTypes['MenuEntityImage']
> = {
  imagePath?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MenuEntityPointOfSaleResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MenuEntityPointOfSale'] = ResolversParentTypes['MenuEntityPointOfSale']
> = {
  barcodeEmbeddedAmountType?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  color?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  kdsColor?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  kitchenName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  shortName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  sku?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MenuEntityReportsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MenuEntityReports'] = ResolversParentTypes['MenuEntityReports']
> = {
  inheritSalesCategory?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  plu?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  salesCategory?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MenuEntityTaxesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MenuEntityTaxes'] = ResolversParentTypes['MenuEntityTaxes']
> = {
  diningOptionTaxation?: Resolver<
    ResolversTypes['MenuEntityDiningOptionTax'],
    ParentType,
    ContextType
  >
  inheritDiningOptionTax?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  inheritTaxInclusive?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  inheritTaxRates?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  taxInclusionOption?: Resolver<
    ResolversTypes['MenuEntityTaxInclusionOption'],
    ParentType,
    ContextType
  >
  taxRateMultiLocationIds?: Resolver<
    Array<ResolversTypes['ID']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MenuEntityVisibilityResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MenuEntityVisibility'] = ResolversParentTypes['MenuEntityVisibility']
> = {
  kioskVisible?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  onlineOrderingVisible?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  orderingPartnerVisible?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  posVisible?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MenuGroupResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MenuGroup'] = ResolversParentTypes['MenuGroup']
> = {
  __resolveReference?: ReferenceResolver<
    Maybe<ResolversTypes['MenuGroup']>,
    { __typename: 'MenuGroup' } & GraphQLRecursivePick<
      ParentType,
      { id: true }
    >,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  inheritModifierGroups?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  menuGroupMultiLocationIds?: Resolver<
    Maybe<Array<ResolversTypes['ID']>>,
    ParentType,
    ContextType
  >
  menuGroups?: Resolver<
    Maybe<Array<ResolversTypes['MenuGroup']>>,
    ParentType,
    ContextType
  >
  menuItemMultiLocationIds?: Resolver<
    Maybe<Array<ResolversTypes['ID']>>,
    ParentType,
    ContextType
  >
  menuItems?: Resolver<
    Array<ResolversTypes['MenuItem']>,
    ParentType,
    ContextType
  >
  modifierGroupMultiLocationIds?: Resolver<
    Array<ResolversTypes['ID']>,
    ParentType,
    ContextType
  >
  modifierGroups?: Resolver<
    Array<ResolversTypes['ModifierGroup']>,
    ParentType,
    ContextType,
    RequireFields<
      MenuGroupModifierGroupsArgs,
      'versionResolution' | 'versionResolutionTarget'
    >
  >
  multiLocationId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  owner?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  ownerRestaurantSet?: Resolver<
    Maybe<ResolversTypes['RestaurantSet']>,
    ParentType,
    ContextType
  >
  pointOfSale?: Resolver<
    ResolversTypes['MenuEntityPointOfSale'],
    ParentType,
    ContextType
  >
  preparation?: Resolver<
    ResolversTypes['SavedMenuGroupPreparation'],
    ParentType,
    ContextType
  >
  reports?: Resolver<
    ResolversTypes['MenuEntityReports'],
    ParentType,
    ContextType
  >
  target?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  targetRestaurantSet?: Resolver<
    ResolversTypes['RestaurantSet'],
    ParentType,
    ContextType
  >
  taxes?: Resolver<ResolversTypes['MenuEntityTaxes'], ParentType, ContextType>
  versionId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  visibility?: Resolver<
    ResolversTypes['MenuEntityVisibility'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MenuItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MenuItem'] = ResolversParentTypes['MenuItem']
> = {
  __resolveReference?: ReferenceResolver<
    Maybe<ResolversTypes['MenuItem']>,
    { __typename: 'MenuItem' } & GraphQLRecursivePick<ParentType, { id: true }>,
    ContextType
  >
  ancestors?: Resolver<
    Array<ResolversTypes['EntityAncestorResponse']>,
    ParentType,
    ContextType
  >
  calories?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  contentAdvisories?: Resolver<
    ResolversTypes['MenuEntityContentAdvisories'],
    ParentType,
    ContextType
  >
  description?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  guestCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  image?: Resolver<ResolversTypes['MenuEntityImage'], ParentType, ContextType>
  inheritModifierGroups?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  modifierGroupMultiLocationIds?: Resolver<
    Maybe<Array<ResolversTypes['ID']>>,
    ParentType,
    ContextType
  >
  modifierGroups?: Resolver<
    Array<ResolversTypes['ModifierGroup']>,
    ParentType,
    ContextType,
    RequireFields<
      MenuItemModifierGroupsArgs,
      'versionResolution' | 'versionResolutionTarget'
    >
  >
  modifierOnly?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  multiLocationId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  owner?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  ownerRestaurantSet?: Resolver<
    Maybe<ResolversTypes['RestaurantSet']>,
    ParentType,
    ContextType
  >
  parentVersionIds?: Resolver<
    Maybe<Array<ResolversTypes['String']>>,
    ParentType,
    ContextType
  >
  pointOfSale?: Resolver<
    ResolversTypes['MenuEntityPointOfSale'],
    ParentType,
    ContextType
  >
  preparation?: Resolver<
    ResolversTypes['SavedMenuItemPreparation'],
    ParentType,
    ContextType
  >
  pricing?: Resolver<
    Maybe<ResolversTypes['MenuItemPricing']>,
    ParentType,
    ContextType
  >
  reports?: Resolver<
    ResolversTypes['MenuEntityReports'],
    ParentType,
    ContextType
  >
  stock?: Resolver<ResolversTypes['Stock'], ParentType, ContextType>
  target?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  targetRestaurantSet?: Resolver<
    ResolversTypes['RestaurantSet'],
    ParentType,
    ContextType
  >
  taxes?: Resolver<ResolversTypes['MenuEntityTaxes'], ParentType, ContextType>
  versionId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  visibility?: Resolver<
    ResolversTypes['MenuEntityVisibility'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MenuItemFeedbackResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MenuItemFeedback'] = ResolversParentTypes['MenuItemFeedback']
> = {
  createdDate?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  feedbackText?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  menuItem?: Resolver<ResolversTypes['MenuItem'], ParentType, ContextType>
  rating?: Resolver<
    Maybe<ResolversTypes['GuestFeedbackRatingType']>,
    ParentType,
    ContextType
  >
  rawRating?: Resolver<
    Maybe<ResolversTypes['GuestFeedbackRawRatingType']>,
    ParentType,
    ContextType
  >
  reasons?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>
  source?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MenuItemInventoryConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MenuItemInventoryConnection'] = ResolversParentTypes['MenuItemInventoryConnection']
> = {
  edges?: Resolver<
    Array<ResolversTypes['RestaurantMenuItemEdge']>,
    ParentType,
    ContextType
  >
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MenuItemPlateCostResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MenuItemPlateCost'] = ResolversParentTypes['MenuItemPlateCost']
> = {
  cost?: Resolver<ResolversTypes['Money'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  itemMultiLocationID?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  source?: Resolver<ResolversTypes['PlateCostSource'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MenuItemPricingResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MenuItemPricing'] = ResolversParentTypes['MenuItemPricing']
> = {
  basePrice?: Resolver<Maybe<ResolversTypes['Money']>, ParentType, ContextType>
  inheritPricing?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  locationSpecificPrices?: Resolver<
    Maybe<Array<ResolversTypes['LocationSpecificPrice']>>,
    ParentType,
    ContextType
  >
  maxPrice?: Resolver<Maybe<ResolversTypes['Money']>, ParentType, ContextType>
  menuSpecificPrices?: Resolver<
    Maybe<Array<ResolversTypes['MenuSpecificPrice']>>,
    ParentType,
    ContextType
  >
  minPrice?: Resolver<Maybe<ResolversTypes['Money']>, ParentType, ContextType>
  priceLevel?: Resolver<
    Maybe<ResolversTypes['PriceLevel']>,
    ParentType,
    ContextType
  >
  priceLevels?: Resolver<
    Maybe<Array<ResolversTypes['PriceLevelEntity']>>,
    ParentType,
    ContextType
  >
  pricingStrategy?: Resolver<
    ResolversTypes['MenuItemPricingStrategy'],
    ParentType,
    ContextType
  >
  sizePrices?: Resolver<
    Maybe<Array<ResolversTypes['SizePrice']>>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MenuSpecificPriceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MenuSpecificPrice'] = ResolversParentTypes['MenuSpecificPrice']
> = {
  menuMultiLocationId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  menuName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  pricing?: Resolver<
    Maybe<ResolversTypes['MenuItemPricing']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MenuUpsellConfigMutationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MenuUpsellConfigMutation'] = ResolversParentTypes['MenuUpsellConfigMutation']
> = {
  archiveCustomMenuItemUpsell?: Resolver<
    ResolversTypes['CustomMenuItemUpsell'],
    ParentType,
    ContextType,
    RequireFields<
      MenuUpsellConfigMutationArchiveCustomMenuItemUpsellArgs,
      'input'
    >
  >
  saveCustomMenuItemUpsell?: Resolver<
    ResolversTypes['CustomMenuItemUpsell'],
    ParentType,
    ContextType,
    RequireFields<MenuUpsellConfigMutationSaveCustomMenuItemUpsellArgs, 'input'>
  >
  saveCustomMenuItemUpsells?: Resolver<
    Array<ResolversTypes['CustomMenuItemUpsell']>,
    ParentType,
    ContextType,
    RequireFields<
      MenuUpsellConfigMutationSaveCustomMenuItemUpsellsArgs,
      'input'
    >
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MenuUpsellConfigQueryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MenuUpsellConfigQuery'] = ResolversParentTypes['MenuUpsellConfigQuery']
> = {
  customMenuItemUpsells?: Resolver<
    Array<ResolversTypes['CustomMenuItemUpsell']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MgmtDailySummaryConfigResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MgmtDailySummaryConfig'] = ResolversParentTypes['MgmtDailySummaryConfig']
> = {
  emails?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>
  enabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  scheduledTime?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MgmtDepositBookableConfigResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MgmtDepositBookableConfig'] = ResolversParentTypes['MgmtDepositBookableConfig']
> = {
  autoCancelUnpaidDeposit?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  autoCancelUnpaidDepositTimeframe?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType
  >
  cancellationRefundableTimeframe?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType
  >
  depositPolicy?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  enabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  strategy?: Resolver<
    ResolversTypes['DepositStrategy'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MgmtEmailConfigResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MgmtEmailConfig'] = ResolversParentTypes['MgmtEmailConfig']
> = {
  reservationCancel?: Resolver<
    Maybe<ResolversTypes['MgmtEmailTemplate']>,
    ParentType,
    ContextType
  >
  reservationChange?: Resolver<
    Maybe<ResolversTypes['MgmtEmailTemplate']>,
    ParentType,
    ContextType
  >
  reservationConfirmation?: Resolver<
    Maybe<ResolversTypes['MgmtEmailTemplate']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MgmtEmailTemplateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MgmtEmailTemplate'] = ResolversParentTypes['MgmtEmailTemplate']
> = {
  enabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MgmtRestaurantInformationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MgmtRestaurantInformation'] = ResolversParentTypes['MgmtRestaurantInformation']
> = {
  allowJoinOnlineNoWait?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  bookingMaxHoursInAdvance?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType
  >
  bookingMaxMinutesInAdvance?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType
  >
  bookingMinHoursInAdvance?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType
  >
  bookingMinMinutesInAdvance?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType
  >
  closeOutHour?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  courses?: Resolver<Array<ResolversTypes['Course']>, ParentType, ContextType>
  coursingEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  dailySummaryConfig?: Resolver<
    Maybe<ResolversTypes['MgmtDailySummaryConfig']>,
    ParentType,
    ContextType
  >
  defaultServicePeriods?: Resolver<
    Array<ResolversTypes['ID']>,
    ParentType,
    ContextType
  >
  depositAlternatePaymentTypeGuid?: Resolver<
    Maybe<ResolversTypes['ID']>,
    ParentType,
    ContextType
  >
  depositBookableConfigs?: Resolver<
    Array<ResolversTypes['MgmtDepositBookableConfig']>,
    ParentType,
    ContextType
  >
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  diningOptions?: Resolver<
    Array<ResolversTypes['DiningOption']>,
    ParentType,
    ContextType
  >
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  emailConfig?: Resolver<
    Maybe<ResolversTypes['MgmtEmailConfig']>,
    ParentType,
    ContextType
  >
  externalPartners?: Resolver<
    Maybe<ResolversTypes['ExternalPartner']>,
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  locationName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  loyaltyEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  maxPartySize?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  minPartySize?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  onlineReservationsEnabled?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  onlineWaitlistDisableThreshold?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType
  >
  onlineWaitlistEnabled?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  orderCreationEnabled?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  phoneNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  profilePicUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  readOnlyMenuUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  reservationPolicy?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >
  reservationsEnabled?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  selectedDiningOption?: Resolver<
    Maybe<ResolversTypes['ID']>,
    ParentType,
    ContextType
  >
  sendOptLink?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  sendStpLink?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  serviceAreaGroups?: Resolver<
    Array<ResolversTypes['MgmtServiceAreaGroup']>,
    ParentType,
    ContextType
  >
  serviceAreas?: Resolver<
    Array<ResolversTypes['MgmtServiceArea']>,
    ParentType,
    ContextType
  >
  servicePeriodOverrides?: Resolver<
    Array<ResolversTypes['ID']>,
    ParentType,
    ContextType
  >
  showWaitlistEstimate?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  smsConfig?: Resolver<ResolversTypes['MgmtSmsConfig'], ParentType, ContextType>
  timeZone?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  toastRestaurantShortName?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >
  twoWaySmsEnabled?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  waitlistAlgorithmType?: Resolver<
    ResolversTypes['WaitlistAlgorithmType'],
    ParentType,
    ContextType
  >
  waitlistAutoRemoveConfig?: Resolver<
    ResolversTypes['MgmtWaitlistAutoRemoveConfig'],
    ParentType,
    ContextType
  >
  waitlistEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  waitlistEtaBuffer?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  waitlistEtaTableCount?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType
  >
  waitlistEtaTurnTime?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  waitlistMaxPartySize?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType
  >
  waitlistMinPartySize?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType
  >
  waitlistPolicy?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  websiteUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MgmtRestaurantInformationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MgmtRestaurantInformationResponse'] = ResolversParentTypes['MgmtRestaurantInformationResponse']
> = {
  __resolveType: TypeResolveFn<
    'RestaurantInformationResponseSuccess' | 'RestaurantNotFound',
    ParentType,
    ContextType
  >
}

export type MgmtServiceAreaResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MgmtServiceArea'] = ResolversParentTypes['MgmtServiceArea']
> = {
  defaultTurnTime?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  externalName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  serviceAreaGroup?: Resolver<
    Maybe<ResolversTypes['ID']>,
    ParentType,
    ContextType
  >
  tables?: Resolver<Array<ResolversTypes['MgmtTable']>, ParentType, ContextType>
  turnTimes?: Resolver<Array<ResolversTypes['Int']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MgmtServiceAreaGroupResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MgmtServiceAreaGroup'] = ResolversParentTypes['MgmtServiceAreaGroup']
> = {
  enabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  serviceAreas?: Resolver<Array<ResolversTypes['ID']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MgmtSmsConfigResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MgmtSmsConfig'] = ResolversParentTypes['MgmtSmsConfig']
> = {
  reservationCancellation?: Resolver<
    ResolversTypes['MgmtSmsTemplate'],
    ParentType,
    ContextType
  >
  reservationConfirm?: Resolver<
    ResolversTypes['MgmtSmsTemplate'],
    ParentType,
    ContextType
  >
  reservationNotify?: Resolver<
    ResolversTypes['MgmtSmsTemplate'],
    ParentType,
    ContextType
  >
  reservationReminder?: Resolver<
    ResolversTypes['MgmtSmsTemplate'],
    ParentType,
    ContextType
  >
  sendDeposit?: Resolver<
    ResolversTypes['MgmtSmsTemplate'],
    ParentType,
    ContextType
  >
  sendOpt?: Resolver<ResolversTypes['MgmtSmsTemplate'], ParentType, ContextType>
  sendStp?: Resolver<ResolversTypes['MgmtSmsTemplate'], ParentType, ContextType>
  waitlistCancellation?: Resolver<
    ResolversTypes['MgmtSmsTemplate'],
    ParentType,
    ContextType
  >
  waitlistConfirm?: Resolver<
    ResolversTypes['MgmtSmsTemplate'],
    ParentType,
    ContextType
  >
  waitlistNoShow?: Resolver<
    ResolversTypes['MgmtSmsTemplate'],
    ParentType,
    ContextType
  >
  waitlistNotify?: Resolver<
    ResolversTypes['MgmtSmsTemplate'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MgmtSmsTemplateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MgmtSmsTemplate'] = ResolversParentTypes['MgmtSmsTemplate']
> = {
  enabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  receiveAutomatedMessageEnabled?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MgmtTableResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MgmtTable'] = ResolversParentTypes['MgmtTable']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  maxCapacity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  minCapacity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MgmtWaitlistAutoRemoveConfigResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MgmtWaitlistAutoRemoveConfig'] = ResolversParentTypes['MgmtWaitlistAutoRemoveConfig']
> = {
  enabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  gracePeriodMinutes?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MinimumRepaymentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MinimumRepayment'] = ResolversParentTypes['MinimumRepayment']
> = {
  amount?: Resolver<ResolversTypes['Money'], ParentType, ContextType>
  initiateDate?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  intervalDay?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  isInitiated?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  isScheduled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  milestoneAmount?: Resolver<ResolversTypes['Money'], ParentType, ContextType>
  milestoneDate?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MissingJobResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MissingJob'] = ResolversParentTypes['MissingJob']
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MissingTimeClockResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MissingTimeClock'] = ResolversParentTypes['MissingTimeClock']
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MlmSavedConfigResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MlmSavedConfig'] = ResolversParentTypes['MlmSavedConfig']
> = {
  __resolveType: TypeResolveFn<'MlmUIOption', ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  managementGroup?: Resolver<
    ResolversTypes['ManagementGroup'],
    ParentType,
    ContextType
  >
  variants?: Resolver<
    Array<ResolversTypes['MlmSavedConfigVariant']>,
    ParentType,
    ContextType
  >
}

export type MlmSavedConfigVariantResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MlmSavedConfigVariant'] = ResolversParentTypes['MlmSavedConfigVariant']
> = {
  __resolveType: TypeResolveFn<'MlmUIOptionVariant', ParentType, ContextType>
  field?: Resolver<ResolversTypes['ConfigField'], ParentType, ContextType>
  versions?: Resolver<
    Array<ResolversTypes['MlmSavedVersion']>,
    ParentType,
    ContextType
  >
}

export type MlmSavedVersionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MlmSavedVersion'] = ResolversParentTypes['MlmSavedVersion']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  owner?: Resolver<ResolversTypes['RestaurantSet'], ParentType, ContextType>
  target?: Resolver<ResolversTypes['RestaurantSet'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MlmUiOptionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MlmUIOption'] = ResolversParentTypes['MlmUIOption']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  managementGroup?: Resolver<
    ResolversTypes['ManagementGroup'],
    ParentType,
    ContextType
  >
  variants?: Resolver<
    Array<ResolversTypes['MlmUIOptionVariant']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MlmUiOptionVariantResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MlmUIOptionVariant'] = ResolversParentTypes['MlmUIOptionVariant']
> = {
  additionalModifierGroups?: Resolver<
    Maybe<ResolversTypes['AdditionalModifierGroups']>,
    ParentType,
    ContextType
  >
  allowDeclareNegativeTips?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  allowScheduling?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  askForPartySize?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  autoSwitchUser?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  cashCloseoutDiscrepancyMax?: Resolver<
    Maybe<ResolversTypes['Money']>,
    ParentType,
    ContextType
  >
  cashCloseoutDiscrepancyWarning?: Resolver<
    Maybe<ResolversTypes['Money']>,
    ParentType,
    ContextType
  >
  cashDrawerLockdown?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  closeOnPrint?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  collapseModifierPrices?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  collectCashInDrawer?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  combineItems?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  consolidateDiscounts?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  consolidateModifiers?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  declaredCashTipsMinimum?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >
  defaultsDisplayMode?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  enableModifierOrderingPriority?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  fastCash?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  field?: Resolver<ResolversTypes['ConfigField'], ParentType, ContextType>
  intraModifierGroupSortOrder?: Resolver<
    Maybe<ResolversTypes['IntraModifierGroupSortOrder']>,
    ParentType,
    ContextType
  >
  loginAdditionalText?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  manualEntryForScaleEnabled?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  maskPasscode?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  modifierDisplayMode?: Resolver<
    Maybe<ResolversTypes['ModifierDisplayMode']>,
    ParentType,
    ContextType
  >
  mustDeclareCashTips?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  paymentUserType?: Resolver<
    Maybe<ResolversTypes['PaymentUserTypeMode']>,
    ParentType,
    ContextType
  >
  printClockSlip?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  promptForDiningOption?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  promptForEditOthersTable?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  promptForTab?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  scheduledOrderMaxDays?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >
  searchPromoCode?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  sendButtons?: Resolver<
    Maybe<Array<ResolversTypes['PosButton']>>,
    ParentType,
    ContextType
  >
  sendChecksAfterPaymentOnly?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  shiftReviewCollectAndTipOutMode?: Resolver<
    Maybe<ResolversTypes['ShiftReviewCollectAndTipOutMode']>,
    ParentType,
    ContextType
  >
  shiftReviewRequired?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  showSentTimestamp?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  smartTax?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  ssids?: Resolver<
    Maybe<Array<ResolversTypes['String']>>,
    ParentType,
    ContextType
  >
  strictMode?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  taxOptions?: Resolver<
    Maybe<ResolversTypes['UIOptionsTaxOptions']>,
    ParentType,
    ContextType
  >
  timeClockDisplayForClockInScreenEnabled?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  timeEntryRoundingScheme?: Resolver<
    Maybe<ResolversTypes['TimeEntryRoundingScheme']>,
    ParentType,
    ContextType
  >
  tipOutGratuity?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  tipOutNonCash?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  tipPreTax?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  tipSetup?: Resolver<
    Maybe<ResolversTypes['UIOptionsTipSetup']>,
    ParentType,
    ContextType
  >
  tippingRequireApproval?: Resolver<
    Maybe<ResolversTypes['UIOptionsTippingRequireApproval']>,
    ParentType,
    ContextType
  >
  tomorrowModeEnabled?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  tomorrowModeScheduledTime?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  trackErrorCorrection?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  versions?: Resolver<
    Array<ResolversTypes['MlmSavedVersion']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ModifierResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Modifier'] = ResolversParentTypes['Modifier']
> = {
  __resolveReference?: ReferenceResolver<
    Maybe<ResolversTypes['Modifier']>,
    { __typename: 'Modifier' } & GraphQLRecursivePick<ParentType, { id: true }>,
    ContextType
  >
  guestCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  itemReference?: Resolver<
    Maybe<ResolversTypes['ItemReference']>,
    ParentType,
    ContextType
  >
  multiLocationId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  nameOverride?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  owner?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  ownerRestaurantSet?: Resolver<
    Maybe<ResolversTypes['RestaurantSet']>,
    ParentType,
    ContextType
  >
  pricing?: Resolver<
    Maybe<ResolversTypes['MenuItemPricing']>,
    ParentType,
    ContextType
  >
  pricingOverride?: Resolver<
    Maybe<ResolversTypes['OverridePricing']>,
    ParentType,
    ContextType
  >
  target?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  targetRestaurantSet?: Resolver<
    ResolversTypes['RestaurantSet'],
    ParentType,
    ContextType
  >
  versionId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  visibility?: Resolver<
    ResolversTypes['MenuEntityVisibility'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ModifierGroupResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ModifierGroup'] = ResolversParentTypes['ModifierGroup']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  modifierMultiLocationIds?: Resolver<
    Maybe<Array<ResolversTypes['ID']>>,
    ParentType,
    ContextType
  >
  modifiers?: Resolver<
    Maybe<Array<ResolversTypes['Modifier']>>,
    ParentType,
    ContextType,
    RequireFields<
      ModifierGroupModifiersArgs,
      'versionResolution' | 'versionResolutionTarget'
    >
  >
  multiLocationId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  owner?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  ownerRestaurantSet?: Resolver<
    Maybe<ResolversTypes['RestaurantSet']>,
    ParentType,
    ContextType
  >
  parents?: Resolver<
    Array<ResolversTypes['ParentResponse']>,
    ParentType,
    ContextType
  >
  pointOfSale?: Resolver<
    ResolversTypes['ModifierGroupPointOfSale'],
    ParentType,
    ContextType
  >
  pricing?: Resolver<
    ResolversTypes['ModifierGroupPricing'],
    ParentType,
    ContextType
  >
  requiredMode?: Resolver<
    ResolversTypes['RequiredMode'],
    ParentType,
    ContextType
  >
  selections?: Resolver<
    ResolversTypes['ModifierGroupSelections'],
    ParentType,
    ContextType
  >
  target?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  targetRestaurantSet?: Resolver<
    ResolversTypes['RestaurantSet'],
    ParentType,
    ContextType
  >
  type?: Resolver<ResolversTypes['ModifierGroupType'], ParentType, ContextType>
  versionId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  visibility?: Resolver<
    ResolversTypes['MenuEntityVisibility'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ModifierGroupPointOfSaleResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ModifierGroupPointOfSale'] = ResolversParentTypes['ModifierGroupPointOfSale']
> = {
  color?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  orderingPriority?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >
  shortName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ModifierGroupPricingResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ModifierGroupPricing'] = ResolversParentTypes['ModifierGroupPricing']
> = {
  fixedPrice?: Resolver<Maybe<ResolversTypes['Money']>, ParentType, ContextType>
  pricingMode?: Resolver<ResolversTypes['PricingMode'], ParentType, ContextType>
  pricingStrategy?: Resolver<
    ResolversTypes['ModifierGroupPricingStrategy'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ModifierGroupSelectionsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ModifierGroupSelections'] = ResolversParentTypes['ModifierGroupSelections']
> = {
  duplicateModifiersEnabled?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  maxSelections?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >
  minSelection?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  multiSelect?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ModifierMetricsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ModifierMetrics'] = ResolversParentTypes['ModifierMetrics']
> = {
  modifier?: Resolver<ResolversTypes['MenuItem'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  parent?: Resolver<ResolversTypes['MenuItem'], ParentType, ContextType>
  salesQuantity?: Resolver<
    ResolversTypes['DecimalMetric'],
    ParentType,
    ContextType
  >
  totalNetSales?: Resolver<
    ResolversTypes['MoneyMetric'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MoneyResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Money'] = ResolversParentTypes['Money']
> = {
  amount?: Resolver<ResolversTypes['Decimal'], ParentType, ContextType>
  currency?: Resolver<ResolversTypes['Currency'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MoneyMetricResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MoneyMetric'] = ResolversParentTypes['MoneyMetric']
> = {
  comparison?: Resolver<Maybe<ResolversTypes['Money']>, ParentType, ContextType>
  current?: Resolver<ResolversTypes['Money'], ParentType, ContextType>
  endTime?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  startTime?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  variation?: Resolver<
    Maybe<ResolversTypes['Comparison']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MostRecentImportGuidResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MostRecentImportGuidResponse'] = ResolversParentTypes['MostRecentImportGuidResponse']
> = {
  __resolveType: TypeResolveFn<
    | 'InternalError'
    | 'MostRecentImportGuidResponseSuccess'
    | 'NoPreviousImports',
    ParentType,
    ContextType
  >
}

export type MostRecentImportGuidResponseSuccessResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MostRecentImportGuidResponseSuccess'] = ResolversParentTypes['MostRecentImportGuidResponseSuccess']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MutationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']
> = {
  addAdhocShift?: Resolver<
    ResolversTypes['EmployeeShift'],
    ParentType,
    ContextType,
    RequireFields<
      MutationAddAdhocShiftArgs,
      'businessDate' | 'employee' | 'inDate' | 'restaurant'
    >
  >
  addGroupsAndLocationsToGroup?: Resolver<
    ResolversTypes['RestaurantGroup'],
    ParentType,
    ContextType,
    RequireFields<MutationAddGroupsAndLocationsToGroupArgs, 'input'>
  >
  addLocationToGroups?: Resolver<
    ResolversTypes['RestaurantLocation'],
    ParentType,
    ContextType,
    RequireFields<MutationAddLocationToGroupsArgs, 'input'>
  >
  archiveChannelMenuEntities?: Resolver<
    Array<ResolversTypes['ID']>,
    ParentType,
    ContextType,
    RequireFields<MutationArchiveChannelMenuEntitiesArgs, 'channelMenuEntities'>
  >
  archiveGroup?: Resolver<
    ResolversTypes['RestaurantGroup'],
    ParentType,
    ContextType,
    RequireFields<MutationArchiveGroupArgs, 'guid'>
  >
  archiveMenus?: Resolver<
    Array<ResolversTypes['ID']>,
    ParentType,
    ContextType,
    RequireFields<MutationArchiveMenusArgs, 'input'>
  >
  archiveNoSaleReason?: Resolver<
    Array<ResolversTypes['OpenCashDrawerReason']>,
    ParentType,
    ContextType,
    RequireFields<MutationArchiveNoSaleReasonArgs, 'id'>
  >
  archivePayoutReason?: Resolver<
    Array<ResolversTypes['OpenCashDrawerReason']>,
    ParentType,
    ContextType,
    RequireFields<MutationArchivePayoutReasonArgs, 'id'>
  >
  archiveRestaurantGroup?: Resolver<
    ResolversTypes['RestaurantGroup'],
    ParentType,
    ContextType,
    RequireFields<MutationArchiveRestaurantGroupArgs, 'id'>
  >
  attachMenuItemToMenuGroups?: Resolver<
    Array<ResolversTypes['MenuGroup']>,
    ParentType,
    ContextType,
    RequireFields<
      MutationAttachMenuItemToMenuGroupsArgs,
      'itemMultiLocationId' | 'menuGroupVersionIDs'
    >
  >
  authConfiguration?: Resolver<
    ResolversTypes['AuthConfigurationMutation'],
    ParentType,
    ContextType
  >
  booking?: Resolver<ResolversTypes['BookingMutation'], ParentType, ContextType>
  channel?: Resolver<
    Maybe<ResolversTypes['ChannelMutation']>,
    ParentType,
    ContextType
  >
  chatMessageFeedback?: Resolver<
    Maybe<ResolversTypes['ID']>,
    ParentType,
    ContextType,
    RequireFields<MutationChatMessageFeedbackArgs, 'feedback' | 'sessionId'>
  >
  configCopyMutation?: Resolver<
    ResolversTypes['CopyConfigMutation'],
    ParentType,
    ContextType
  >
  configMutation?: Resolver<
    ResolversTypes['ConfigMutation'],
    ParentType,
    ContextType
  >
  createChannelMenuEntities?: Resolver<
    Array<ResolversTypes['ChannelMenuEntity']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateChannelMenuEntitiesArgs, 'channelMenuEntities'>
  >
  createCopyConfigTaskAsync?: Resolver<
    ResolversTypes['CopyConfigTask'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateCopyConfigTaskAsyncArgs, 'copyConfigTask'>
  >
  createGroup?: Resolver<
    ResolversTypes['RestaurantGroup'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateGroupArgs, 'input'>
  >
  createItemFeedbackConfig?: Resolver<
    ResolversTypes['CreateItemFeedbackConfigResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateItemFeedbackConfigArgs, 'itemFeedbackConfig'>
  >
  createMenuGroupsOnMenu?: Resolver<
    Array<ResolversTypes['MenuGroup']>,
    ParentType,
    ContextType,
    RequireFields<
      MutationCreateMenuGroupsOnMenuArgs,
      'input' | 'parentMenuVersionId'
    >
  >
  createMenuGroupsOnMenuGroup?: Resolver<
    Array<ResolversTypes['MenuGroup']>,
    ParentType,
    ContextType,
    RequireFields<
      MutationCreateMenuGroupsOnMenuGroupArgs,
      'input' | 'parentGroupVersionId'
    >
  >
  createMenuItemPlateCost?: Resolver<
    ResolversTypes['MenuItemPlateCost'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateMenuItemPlateCostArgs, 'plateCost'>
  >
  createMenuItems?: Resolver<
    Array<ResolversTypes['MenuItem']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateMenuItemsArgs, 'input'>
  >
  createMenus?: Resolver<
    Array<ResolversTypes['Menu']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateMenusArgs, 'input'>
  >
  createNoSaleReason?: Resolver<
    Array<ResolversTypes['OpenCashDrawerReason']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateNoSaleReasonArgs, 'createNoSaleReasonInput'>
  >
  createOrUpdateChannelMenuEntities?: Resolver<
    Array<ResolversTypes['ChannelMenuEntity']>,
    ParentType,
    ContextType,
    RequireFields<
      MutationCreateOrUpdateChannelMenuEntitiesArgs,
      'channelMenuEntities'
    >
  >
  createPayoutReason?: Resolver<
    Array<ResolversTypes['OpenCashDrawerReason']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreatePayoutReasonArgs, 'createPayoutReasonInput'>
  >
  createShift?: Resolver<
    ResolversTypes['CreateShiftResult'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateShiftArgs, 'createShiftInput'>
  >
  createToastPayConfig?: Resolver<
    ResolversTypes['CreateToastPayConfigResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateToastPayConfigArgs, 'config'>
  >
  customerTasks?: Resolver<
    ResolversTypes['CustomerTasksMutation'],
    ParentType,
    ContextType
  >
  dataExtensions?: Resolver<
    ResolversTypes['DataExtensionMutation'],
    ParentType,
    ContextType
  >
  deleteLogbookEntry?: Resolver<
    Maybe<ResolversTypes['LogbookEntry']>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteLogbookEntryArgs, 'entry'>
  >
  deleteLogbookEntryReply?: Resolver<
    Maybe<ResolversTypes['LogbookEntryReply']>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteLogbookEntryReplyArgs, 'reply'>
  >
  deleteShift?: Resolver<
    ResolversTypes['DeletedShiftResult'],
    ParentType,
    ContextType,
    RequireFields<MutationDeleteShiftArgs, 'shift'>
  >
  driveThru?: Resolver<
    ResolversTypes['DriveThruMutation'],
    ParentType,
    ContextType
  >
  editGroupInfo?: Resolver<
    ResolversTypes['RestaurantGroup'],
    ParentType,
    ContextType,
    RequireFields<MutationEditGroupInfoArgs, 'input'>
  >
  financialInsights?: Resolver<
    ResolversTypes['FinancialInsightsMutation'],
    ParentType,
    ContextType
  >
  foodWaste?: Resolver<
    ResolversTypes['FoodWasteMutation'],
    ParentType,
    ContextType
  >
  giftCardConfig?: Resolver<
    ResolversTypes['GiftCardMutation'],
    ParentType,
    ContextType
  >
  guestFeedback?: Resolver<
    Maybe<ResolversTypes['GuestFeedbackMutation']>,
    ParentType,
    ContextType
  >
  kiosk?: Resolver<
    Maybe<ResolversTypes['KioskMutation']>,
    ParentType,
    ContextType
  >
  logbook?: Resolver<ResolversTypes['LogbookMutation'], ParentType, ContextType>
  managerClockOutShift?: Resolver<
    ResolversTypes['ManagerClockOutResult'],
    ParentType,
    ContextType,
    RequireFields<MutationManagerClockOutShiftArgs, 'shift'>
  >
  memberships?: Resolver<
    ResolversTypes['MembershipsMutation'],
    ParentType,
    ContextType
  >
  menuUpsellConfig?: Resolver<
    ResolversTypes['MenuUpsellConfigMutation'],
    ParentType,
    ContextType
  >
  onboarding?: Resolver<
    Maybe<ResolversTypes['OnboardingMutation']>,
    ParentType,
    ContextType
  >
  opt?: Resolver<ResolversTypes['OptMutation'], ParentType, ContextType>
  orders?: Resolver<ResolversTypes['OrdersMutation'], ParentType, ContextType>
  ordersConfig?: Resolver<
    Maybe<ResolversTypes['OrdersConfigMutation']>,
    ParentType,
    ContextType
  >
  packaging?: Resolver<
    ResolversTypes['PackagingMutation'],
    ParentType,
    ContextType
  >
  paymentsConfig?: Resolver<
    Maybe<ResolversTypes['PaymentsConfigMutation']>,
    ParentType,
    ContextType
  >
  processLegacyJobs?: Resolver<
    Maybe<ResolversTypes['ProcessLegacyJobResponse']>,
    ParentType,
    ContextType,
    RequireFields<MutationProcessLegacyJobsArgs, 'processLegacyJobRequest'>
  >
  productSubscriptionsMutation?: Resolver<
    ResolversTypes['ProductSubscriptionsMutation'],
    ParentType,
    ContextType
  >
  publishing?: Resolver<
    Maybe<ResolversTypes['PublishMutation']>,
    ParentType,
    ContextType
  >
  removeGroupsAndLocationsFromGroup?: Resolver<
    ResolversTypes['RestaurantGroup'],
    ParentType,
    ContextType,
    RequireFields<MutationRemoveGroupsAndLocationsFromGroupArgs, 'input'>
  >
  removeLocationFromGroups?: Resolver<
    ResolversTypes['RestaurantLocation'],
    ParentType,
    ContextType,
    RequireFields<MutationRemoveLocationFromGroupsArgs, 'input'>
  >
  removeMenuGroupFromMenu?: Resolver<
    ResolversTypes['Menu'],
    ParentType,
    ContextType,
    RequireFields<MutationRemoveMenuGroupFromMenuArgs, 'input'>
  >
  removeMenuGroupFromParentGroup?: Resolver<
    ResolversTypes['MenuGroup'],
    ParentType,
    ContextType,
    RequireFields<MutationRemoveMenuGroupFromParentGroupArgs, 'input'>
  >
  removeMenuItemFromGroup?: Resolver<
    ResolversTypes['MenuGroup'],
    ParentType,
    ContextType,
    RequireFields<MutationRemoveMenuItemFromGroupArgs, 'input'>
  >
  reorderMenuGroupsOnMenu?: Resolver<
    ResolversTypes['Menu'],
    ParentType,
    ContextType,
    RequireFields<MutationReorderMenuGroupsOnMenuArgs, 'input'>
  >
  reorderMenuGroupsOnMenuGroup?: Resolver<
    ResolversTypes['MenuGroup'],
    ParentType,
    ContextType,
    RequireFields<MutationReorderMenuGroupsOnMenuGroupArgs, 'input'>
  >
  reorderMenuItemsOnMenuGroup?: Resolver<
    ResolversTypes['MenuGroup'],
    ParentType,
    ContextType,
    RequireFields<MutationReorderMenuItemsOnMenuGroupArgs, 'input'>
  >
  reorderMenus?: Resolver<
    Array<ResolversTypes['Menu']>,
    ParentType,
    ContextType,
    RequireFields<MutationReorderMenusArgs, 'input'>
  >
  runRestaurantLeafJob?: Resolver<
    Maybe<ResolversTypes['RestaurantLeafDataJobResult']>,
    ParentType,
    ContextType,
    RequireFields<MutationRunRestaurantLeafJobArgs, 'jobInput'>
  >
  setAutoChangeStrategy?: Resolver<
    ResolversTypes['SmartQuoteAutoChangeStrategyResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationSetAutoChangeStrategyArgs, 'strategy'>
  >
  setAutomatedPrepTimeThresholds?: Resolver<
    Maybe<ResolversTypes['GuestQuoteTimes']>,
    ParentType,
    ContextType,
    RequireFields<
      MutationSetAutomatedPrepTimeThresholdsArgs,
      'maximumValue' | 'minimumValue' | 'publish'
    >
  >
  setClosedAllDayToggle?: Resolver<
    Maybe<ResolversTypes['OrderingSchedule']>,
    ParentType,
    ContextType,
    RequireFields<
      MutationSetClosedAllDayToggleArgs,
      'day' | 'diningOptionBehavior'
    >
  >
  setDeliveryEnabled?: Resolver<
    Maybe<ResolversTypes['TakeoutDeliveryConfig']>,
    ParentType,
    ContextType,
    RequireFields<MutationSetDeliveryEnabledArgs, 'publish' | 'value'>
  >
  setDeliveryTimeMinutes?: Resolver<
    Maybe<ResolversTypes['GuestQuoteTimes']>,
    ParentType,
    ContextType,
    RequireFields<MutationSetDeliveryTimeMinutesArgs, 'publish' | 'value'>
  >
  setKitchenPacingItems?: Resolver<
    Maybe<ResolversTypes['GuestQuoteTimes']>,
    ParentType,
    ContextType,
    RequireFields<MutationSetKitchenPacingItemsArgs, 'publish' | 'value'>
  >
  setKitchenPacingOrders?: Resolver<
    Maybe<ResolversTypes['GuestQuoteTimes']>,
    ParentType,
    ContextType,
    RequireFields<MutationSetKitchenPacingOrdersArgs, 'publish' | 'value'>
  >
  setLastOrderConfiguration?: Resolver<
    Maybe<ResolversTypes['OrderingSchedule']>,
    ParentType,
    ContextType,
    RequireFields<
      MutationSetLastOrderConfigurationArgs,
      'lastOrderConfiguration'
    >
  >
  setMinTakeoutDeliveryTime?: Resolver<
    Maybe<ResolversTypes['GuestQuoteTimes']>,
    ParentType,
    ContextType,
    RequireFields<
      MutationSetMinTakeoutDeliveryTimeArgs,
      | 'deliveryHours'
      | 'deliveryMinutes'
      | 'publish'
      | 'takeoutHours'
      | 'takeoutMinutes'
    >
  >
  setOnlineOrderingHoursSchedule?: Resolver<
    Maybe<ResolversTypes['OrderingSchedule']>,
    ParentType,
    ContextType,
    RequireFields<MutationSetOnlineOrderingHoursScheduleArgs, 'schedule'>
  >
  setOrderingSchedule?: Resolver<
    Maybe<ResolversTypes['OrderingSchedule']>,
    ParentType,
    ContextType,
    RequireFields<MutationSetOrderingScheduleArgs, 'overrides' | 'schedule'>
  >
  setPartnerChannelConfig?: Resolver<
    Maybe<ResolversTypes['PartnerChannelConfig']>,
    ParentType,
    ContextType,
    RequireFields<
      MutationSetPartnerChannelConfigArgs,
      'partnerChannel' | 'publish'
    >
  >
  setPartnerChannelsConfig?: Resolver<
    Maybe<ResolversTypes['PartnerChannelConfig']>,
    ParentType,
    ContextType,
    RequireFields<
      MutationSetPartnerChannelsConfigArgs,
      'partnerChannels' | 'publish'
    >
  >
  setQuoteTimes?: Resolver<
    Maybe<ResolversTypes['GuestQuoteTimes']>,
    ParentType,
    ContextType,
    RequireFields<MutationSetQuoteTimesArgs, 'publish' | 'quoteTimes'>
  >
  setScheduleOverrides?: Resolver<
    Maybe<ResolversTypes['OrderingSchedule']>,
    ParentType,
    ContextType,
    RequireFields<MutationSetScheduleOverridesArgs, 'overrides'>
  >
  setSnooze?: Resolver<
    Maybe<ResolversTypes['TakeoutDeliveryConfig']>,
    ParentType,
    ContextType,
    RequireFields<
      MutationSetSnoozeArgs,
      'onlineOrderingAvailability' | 'publish'
    >
  >
  setStrategy?: Resolver<
    Maybe<ResolversTypes['GuestQuoteTimes']>,
    ParentType,
    ContextType,
    RequireFields<MutationSetStrategyArgs, 'publish' | 'value'>
  >
  setTakeoutAndDeliveryEnabled?: Resolver<
    Maybe<ResolversTypes['TakeoutDeliveryConfig']>,
    ParentType,
    ContextType,
    RequireFields<
      MutationSetTakeoutAndDeliveryEnabledArgs,
      'deliveryEnabled' | 'publish' | 'takeoutEnabled'
    >
  >
  setTakeoutEnabled?: Resolver<
    Maybe<ResolversTypes['TakeoutDeliveryConfig']>,
    ParentType,
    ContextType,
    RequireFields<MutationSetTakeoutEnabledArgs, 'value'>
  >
  setThrottlingTimes?: Resolver<
    Maybe<ResolversTypes['GuestQuoteTimes']>,
    ParentType,
    ContextType,
    RequireFields<
      MutationSetThrottlingTimesArgs,
      | 'deliveryThrottlingTimeMinutes'
      | 'publish'
      | 'takeoutThrottlingTimeMinutes'
    >
  >
  smartQuote?: Resolver<
    Maybe<ResolversTypes['SmartQuoteMutation']>,
    ParentType,
    ContextType
  >
  sousChef?: Resolver<
    ResolversTypes['SousChefMutation'],
    ParentType,
    ContextType
  >
  sousChefAdmin?: Resolver<
    ResolversTypes['SousChefAdminMutation'],
    ParentType,
    ContextType
  >
  submitLogbookEntryReply?: Resolver<
    Maybe<ResolversTypes['LogbookEntryReply']>,
    ParentType,
    ContextType,
    RequireFields<MutationSubmitLogbookEntryReplyArgs, 'reply'>
  >
  submitLogbookEntryWithBusinessDay?: Resolver<
    Maybe<ResolversTypes['LogbookEntry']>,
    ParentType,
    ContextType,
    RequireFields<MutationSubmitLogbookEntryWithBusinessDayArgs, 'entry'>
  >
  thirdPartyMock?: Resolver<
    Maybe<ResolversTypes['ThirdPartyMockMutation']>,
    ParentType,
    ContextType
  >
  thirdPartyOrderingOnboarding?: Resolver<
    ResolversTypes['ThirdPartyOrderingOnboardingMutation'],
    ParentType,
    ContextType
  >
  toastNowDeviceRegistry?: Resolver<
    ResolversTypes['ToastNowDeviceRegistryMutation'],
    ParentType,
    ContextType
  >
  toastSearch?: Resolver<
    ResolversTypes['SearchMutation'],
    ParentType,
    ContextType
  >
  trial?: Resolver<ResolversTypes['TrialMutation'], ParentType, ContextType>
  uiOptions?: Resolver<
    Maybe<ResolversTypes['UIOptionsMutation']>,
    ParentType,
    ContextType
  >
  unarchiveGroups?: Resolver<
    Array<ResolversTypes['RestaurantGroup']>,
    ParentType,
    ContextType,
    RequireFields<MutationUnarchiveGroupsArgs, 'guids'>
  >
  unarchiveNoSaleReason?: Resolver<
    Array<ResolversTypes['OpenCashDrawerReason']>,
    ParentType,
    ContextType,
    RequireFields<MutationUnarchiveNoSaleReasonArgs, 'id'>
  >
  unarchivePayoutReason?: Resolver<
    Array<ResolversTypes['OpenCashDrawerReason']>,
    ParentType,
    ContextType,
    RequireFields<MutationUnarchivePayoutReasonArgs, 'id'>
  >
  updateCashDrawer?: Resolver<
    Maybe<ResolversTypes['CashDrawer']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateCashDrawerArgs, 'cashDrawerInput' | 'id'>
  >
  updateCashDrawerConfig?: Resolver<
    Maybe<ResolversTypes['CashDrawerConfig']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateCashDrawerConfigArgs, 'cashDrawerConfigInput'>
  >
  updateCashFlowTransactions?: Resolver<
    Array<ResolversTypes['CashFlowAccountTransactions']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateCashFlowTransactionsArgs, 'request'>
  >
  updateChannelMenuEntities?: Resolver<
    Array<ResolversTypes['ChannelMenuEntity']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateChannelMenuEntitiesArgs, 'channelMenuEntities'>
  >
  updateClosedCashDrawerReportSections?: Resolver<
    Maybe<ResolversTypes['CloseOutReportingContent']>,
    ParentType,
    ContextType,
    RequireFields<
      MutationUpdateClosedCashDrawerReportSectionsArgs,
      'closedCashDrawerReportSectionInputs'
    >
  >
  updateDeclareCashTipsConfig?: Resolver<
    Maybe<ResolversTypes['ShiftReviewConfig']>,
    ParentType,
    ContextType,
    RequireFields<
      MutationUpdateDeclareCashTipsConfigArgs,
      'declareCashTipsConfigInput'
    >
  >
  updateItemFeedbackConfig?: Resolver<
    ResolversTypes['UpdateItemFeedbackConfigResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateItemFeedbackConfigArgs, 'itemFeedbackConfig'>
  >
  updateLogbookEntry?: Resolver<
    Maybe<ResolversTypes['LogbookEntry']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateLogbookEntryArgs, 'entry'>
  >
  updateLogbookEntryReply?: Resolver<
    Maybe<ResolversTypes['LogbookEntryReply']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateLogbookEntryReplyArgs, 'reply'>
  >
  updateMenuItemPlateCost?: Resolver<
    ResolversTypes['MenuItemPlateCost'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateMenuItemPlateCostArgs, 'plateCost'>
  >
  updateMenuItems?: Resolver<
    Array<ResolversTypes['MenuItem']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateMenuItemsArgs, 'input'>
  >
  updateMenus?: Resolver<
    Array<ResolversTypes['Menu']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateMenusArgs, 'input'>
  >
  updateNoSaleReason?: Resolver<
    Array<ResolversTypes['OpenCashDrawerReason']>,
    ParentType,
    ContextType,
    RequireFields<
      MutationUpdateNoSaleReasonArgs,
      'id' | 'updateNoSaleReasonInput'
    >
  >
  updateNoSaleReasonOrdinals?: Resolver<
    Array<ResolversTypes['OpenCashDrawerReason']>,
    ParentType,
    ContextType,
    RequireFields<
      MutationUpdateNoSaleReasonOrdinalsArgs,
      'noSaleReasonOrdinalInputs'
    >
  >
  updatePayoutReason?: Resolver<
    Array<ResolversTypes['OpenCashDrawerReason']>,
    ParentType,
    ContextType,
    RequireFields<
      MutationUpdatePayoutReasonArgs,
      'id' | 'updatePayoutReasonInput'
    >
  >
  updatePayoutReasonOrdinals?: Resolver<
    Array<ResolversTypes['OpenCashDrawerReason']>,
    ParentType,
    ContextType,
    RequireFields<
      MutationUpdatePayoutReasonOrdinalsArgs,
      'payoutReasonOrdinalInputs'
    >
  >
  updateReconciliationConfig?: Resolver<
    Maybe<ResolversTypes['ShiftReviewConfig']>,
    ParentType,
    ContextType,
    RequireFields<
      MutationUpdateReconciliationConfigArgs,
      'reconciliationConfigInput'
    >
  >
  updateShift?: Resolver<
    ResolversTypes['UpdateShiftResult'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateShiftArgs, 'shift' | 'updateShiftInput'>
  >
  updateShiftReviewReportSections?: Resolver<
    Maybe<ResolversTypes['CloseOutReportingContent']>,
    ParentType,
    ContextType,
    RequireFields<
      MutationUpdateShiftReviewReportSectionsArgs,
      'shiftReviewReportSectionInputs'
    >
  >
  updateShiftTimes?: Resolver<
    ResolversTypes['UpdateShiftTimesResult'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateShiftTimesArgs, 'shift' | 'shiftUpdateInput'>
  >
  updateShouldAuditRemovedItems?: Resolver<
    Maybe<ResolversTypes['CloseOutReportingConfig']>,
    ParentType,
    ContextType,
    RequireFields<
      MutationUpdateShouldAuditRemovedItemsArgs,
      'shouldAuditRemovedItems'
    >
  >
  updateShouldAutoPrintClosedCashDrawerReportOnClose?: Resolver<
    Maybe<ResolversTypes['CloseOutReportingConfig']>,
    ParentType,
    ContextType,
    RequireFields<
      MutationUpdateShouldAutoPrintClosedCashDrawerReportOnCloseArgs,
      'shouldAutoPrintClosedCashDrawerReportOnClose'
    >
  >
  updateShouldRequireCashDrawerLockdown?: Resolver<
    Maybe<ResolversTypes['ShiftReviewConfig']>,
    ParentType,
    ContextType,
    RequireFields<
      MutationUpdateShouldRequireCashDrawerLockdownArgs,
      'shouldRequireCashDrawerLockdown'
    >
  >
  updateShouldRequireShiftReview?: Resolver<
    Maybe<ResolversTypes['ShiftReviewConfig']>,
    ParentType,
    ContextType,
    RequireFields<
      MutationUpdateShouldRequireShiftReviewArgs,
      'shouldRequireShiftReview'
    >
  >
  updateShouldShowTipsBySalesCategory?: Resolver<
    Maybe<ResolversTypes['CloseOutReportingConfig']>,
    ParentType,
    ContextType,
    RequireFields<
      MutationUpdateShouldShowTipsBySalesCategoryArgs,
      'shouldShowTipsBySalesCategory'
    >
  >
  updateStockForMenuItems?: Resolver<
    Maybe<Array<ResolversTypes['MenuItem']>>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateStockForMenuItemsArgs, 'items'>
  >
  updateTipOutConfig?: Resolver<
    Maybe<ResolversTypes['ShiftReviewConfig']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateTipOutConfigArgs, 'tipOutConfigInput'>
  >
  updateTipSharingPercentages?: Resolver<
    Maybe<ResolversTypes['CloseOutReportingConfig']>,
    ParentType,
    ContextType,
    RequireFields<
      MutationUpdateTipSharingPercentagesArgs,
      'allTipSharingPercentages'
    >
  >
  updateToastPayConfig?: Resolver<
    ResolversTypes['UpdateToastPayConfigResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateToastPayConfigArgs, 'config'>
  >
  updateZReportSections?: Resolver<
    Maybe<ResolversTypes['CloseOutReportingContent']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateZReportSectionsArgs, 'zReportSectionInputs'>
  >
  upgradeItemFeedbackConfig?: Resolver<
    ResolversTypes['UpgradeItemFeedbackConfigResponse'],
    ParentType,
    ContextType
  >
  uploadImage?: Resolver<
    Maybe<ResolversTypes['UploadImageResponse']>,
    ParentType,
    ContextType,
    RequireFields<MutationUploadImageArgs, 'input'>
  >
  upsertChannelMenuPriceAdjustments?: Resolver<
    Array<ResolversTypes['ChannelMenuPriceAdjustment']>,
    ParentType,
    ContextType,
    RequireFields<
      MutationUpsertChannelMenuPriceAdjustmentsArgs,
      'channelMenuPriceAdjustments'
    >
  >
  userPreferences?: Resolver<
    ResolversTypes['UserPreferencesMutation'],
    ParentType,
    ContextType
  >
  versionMenu?: Resolver<
    Array<ResolversTypes['Menu']>,
    ParentType,
    ContextType,
    Partial<MutationVersionMenuArgs>
  >
  versionMenuGroup?: Resolver<
    Array<ResolversTypes['MenuGroup']>,
    ParentType,
    ContextType,
    Partial<MutationVersionMenuGroupArgs>
  >
  versionMenuItem?: Resolver<
    Array<ResolversTypes['MenuItem']>,
    ParentType,
    ContextType,
    Partial<MutationVersionMenuItemArgs>
  >
  versionModifierGroup?: Resolver<
    Array<ResolversTypes['ModifierGroup']>,
    ParentType,
    ContextType,
    Partial<MutationVersionModifierGroupArgs>
  >
  versionPriceLevel?: Resolver<
    Array<ResolversTypes['PriceLevelEntity']>,
    ParentType,
    ContextType,
    Partial<MutationVersionPriceLevelArgs>
  >
}

export type NavigationCategoryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NavigationCategory'] = ResolversParentTypes['NavigationCategory']
> = {
  categorySpa?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  categoryType?: Resolver<
    ResolversTypes['NavigationCategoryType'],
    ParentType,
    ContextType
  >
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  groupId?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  headerSpa?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  icon?: Resolver<
    ResolversTypes['NavigationMenuItemIcon'],
    ParentType,
    ContextType
  >
  iconHighlightOverridePaths?: Resolver<
    Array<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  longName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  path?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  sections?: Resolver<
    Array<ResolversTypes['NavigationSection']>,
    ParentType,
    ContextType
  >
  taskPaths?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type NavigationConfigLocationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NavigationConfigLocation'] = ResolversParentTypes['NavigationConfigLocation']
> = {
  location?: Resolver<ResolversTypes['ConfigLocation'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type NavigationGroupResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NavigationGroup'] = ResolversParentTypes['NavigationGroup']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  tasks?: Resolver<
    Array<ResolversTypes['NavigationTask']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type NavigationHeaderResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NavigationHeader'] = ResolversParentTypes['NavigationHeader']
> = {
  links?: Resolver<
    Maybe<Array<ResolversTypes['NavigationLink']>>,
    ParentType,
    ContextType
  >
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type NavigationLinkResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NavigationLink'] = ResolversParentTypes['NavigationLink']
> = {
  link?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  spaName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type NavigationMenuItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NavigationMenuItem'] = ResolversParentTypes['NavigationMenuItem']
> = {
  __resolveType: TypeResolveFn<
    'NavigationHeader' | 'NavigationLink',
    ParentType,
    ContextType
  >
}

export type NavigationPageResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NavigationPage'] = ResolversParentTypes['NavigationPage']
> = {
  __resolveReference?: ReferenceResolver<
    Maybe<ResolversTypes['NavigationPage']>,
    { __typename: 'NavigationPage' } & GraphQLRecursivePick<
      ParentType,
      { id: true }
    >,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  keywords?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  path?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  routingStrategy?: Resolver<
    ResolversTypes['NavigationPageRoutingStrategy'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type NavigationProfileLinkResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NavigationProfileLink'] = ResolversParentTypes['NavigationProfileLink']
> = {
  href?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  type?: Resolver<
    ResolversTypes['NavigationProfileLinkType'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type NavigationQueryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NavigationQuery'] = ResolversParentTypes['NavigationQuery']
> = {
  adminMenu?: Resolver<
    Array<ResolversTypes['NavigationMenuItem']>,
    ParentType,
    ContextType
  >
  categories?: Resolver<
    Array<ResolversTypes['NavigationCategory']>,
    ParentType,
    ContextType,
    RequireFields<NavigationQueryCategoriesArgs, 'cacheKey' | 'restaurantGuid'>
  >
  configLocations?: Resolver<
    Array<ResolversTypes['NavigationConfigLocation']>,
    ParentType,
    ContextType
  >
  globalNavigationMenu?: Resolver<
    ResolversTypes['GlobalNavigationMenu'],
    ParentType,
    ContextType,
    RequireFields<
      NavigationQueryGlobalNavigationMenuArgs,
      'cacheKey' | 'restaurantGuid'
    >
  >
  pages?: Resolver<
    Array<ResolversTypes['NavigationPage']>,
    ParentType,
    ContextType
  >
  profileLinks?: Resolver<
    Array<ResolversTypes['NavigationProfileLink']>,
    ParentType,
    ContextType,
    RequireFields<NavigationQueryProfileLinksArgs, 'authenticityToken'>
  >
  quickActions?: Resolver<
    Array<ResolversTypes['NavigationQuickAction']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type NavigationQuickActionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NavigationQuickAction'] = ResolversParentTypes['NavigationQuickAction']
> = {
  hasPermission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  href?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  showAsNew?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type NavigationSectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NavigationSection'] = ResolversParentTypes['NavigationSection']
> = {
  groups?: Resolver<
    Array<ResolversTypes['NavigationGroup']>,
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  sectionSpa?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type NavigationTaskResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NavigationTask'] = ResolversParentTypes['NavigationTask']
> = {
  availableActions?: Resolver<
    Array<ResolversTypes['TaskAction']>,
    ParentType,
    ContextType
  >
  badgeText?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  keywords?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  path?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  pendoId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  taskType?: Resolver<
    ResolversTypes['NavigationTaskType'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type NavigationTaskActionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NavigationTaskAction'] = ResolversParentTypes['NavigationTaskAction']
> = {
  action?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type NetSalesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NetSales'] = ResolversParentTypes['NetSales']
> = {
  grouped?: Resolver<
    Array<ResolversTypes['GroupedNetSales']>,
    ParentType,
    ContextType,
    RequireFields<NetSalesGroupedArgs, 'by'>
  >
  hourly?: Resolver<
    Array<ResolversTypes['MoneyMetric']>,
    ParentType,
    ContextType
  >
  totals?: Resolver<ResolversTypes['MoneyMetric'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type NetSalesDateRangeMetricsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NetSalesDateRangeMetrics'] = ResolversParentTypes['NetSalesDateRangeMetrics']
> = {
  daily?: Resolver<
    Array<ResolversTypes['DailyNetSalesMetric']>,
    ParentType,
    ContextType
  >
  totals?: Resolver<ResolversTypes['MoneyMetric'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type NetSalesPerLaborHourResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NetSalesPerLaborHour'] = ResolversParentTypes['NetSalesPerLaborHour']
> = {
  hourly?: Resolver<
    Array<ResolversTypes['MoneyMetric']>,
    ParentType,
    ContextType
  >
  totals?: Resolver<ResolversTypes['MoneyMetric'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type NetworkDeviceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NetworkDevice'] = ResolversParentTypes['NetworkDevice']
> = {
  __resolveReference?: ReferenceResolver<
    Maybe<ResolversTypes['NetworkDevice']>,
    { __typename: 'NetworkDevice' } & (
      | GraphQLRecursivePick<ParentType, { serial: true }>
      | GraphQLRecursivePick<ParentType, { mac: true }>
    ),
    ContextType
  >
  lastUpdate?: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >
  mac?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  modelName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  photo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  serial?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  status?: Resolver<
    ResolversTypes['NetworkDeviceStatus'],
    ParentType,
    ContextType
  >
  statusMessage?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  type?: Resolver<
    Maybe<ResolversTypes['NetworkDeviceType']>,
    ParentType,
    ContextType
  >
  vendor?: Resolver<
    Maybe<ResolversTypes['NetworkDeviceVendor']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type NetworkQueriesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NetworkQueries'] = ResolversParentTypes['NetworkQueries']
> = {
  getDevice?: Resolver<
    ResolversTypes['NetworkDevice'],
    ParentType,
    ContextType,
    RequireFields<NetworkQueriesGetDeviceArgs, 'input'>
  >
  getDevices?: Resolver<
    Array<ResolversTypes['NetworkDevice']>,
    ParentType,
    ContextType,
    RequireFields<NetworkQueriesGetDevicesArgs, 'input'>
  >
  getStatus?: Resolver<
    ResolversTypes['NetworkStatusResponse'],
    ParentType,
    ContextType,
    RequireFields<NetworkQueriesGetStatusArgs, 'input'>
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type NetworkStatusDataResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NetworkStatusData'] = ResolversParentTypes['NetworkStatusData']
> = {
  devices?: Resolver<
    Array<ResolversTypes['NetworkDevice']>,
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  managementType?: Resolver<
    ResolversTypes['NetworkManagementType'],
    ParentType,
    ContextType
  >
  status?: Resolver<ResolversTypes['NetworkStatus'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type NetworkStatusErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NetworkStatusError'] = ResolversParentTypes['NetworkStatusError']
> = {
  code?: Resolver<
    ResolversTypes['NetworkStatusErrorCode'],
    ParentType,
    ContextType
  >
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type NetworkStatusResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NetworkStatusResponse'] = ResolversParentTypes['NetworkStatusResponse']
> = {
  __resolveType: TypeResolveFn<
    'NetworkStatusData' | 'NetworkStatusError',
    ParentType,
    ContextType
  >
}

export type NeverEmployedEmploymentStatusResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NeverEmployedEmploymentStatus'] = ResolversParentTypes['NeverEmployedEmploymentStatus']
> = {
  adjustedHireDate?: Resolver<
    Maybe<ResolversTypes['Date']>,
    ParentType,
    ContextType
  >
  hireDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type NoJobIdsProvidedBatchErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NoJobIdsProvidedBatchError'] = ResolversParentTypes['NoJobIdsProvidedBatchError']
> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type NoPreviousImportsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NoPreviousImports'] = ResolversParentTypes['NoPreviousImports']
> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  status?: Resolver<ResolversTypes['ErrorCode'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type NotificationContactResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NotificationContact'] = ResolversParentTypes['NotificationContact']
> = {
  chosenName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  userGuid?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OnboardingActionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OnboardingAction'] = ResolversParentTypes['OnboardingAction']
> = {
  __resolveType: TypeResolveFn<
    | 'OnboardingDefaultAction'
    | 'OnboardingNavigationAction'
    | 'OnboardingShareAction',
    ParentType,
    ContextType
  >
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  type?: Resolver<
    ResolversTypes['OnboardingActionType'],
    ParentType,
    ContextType
  >
}

export type OnboardingDefaultActionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OnboardingDefaultAction'] = ResolversParentTypes['OnboardingDefaultAction']
> = {
  associatedTask?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  type?: Resolver<
    ResolversTypes['OnboardingActionType'],
    ParentType,
    ContextType
  >
  updatedTaskStatus?: Resolver<
    Maybe<ResolversTypes['OnboardingStatus']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OnboardingDefaultInfoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OnboardingDefaultInfo'] = ResolversParentTypes['OnboardingDefaultInfo']
> = {
  action?: Resolver<
    Maybe<ResolversTypes['OnboardingAction']>,
    ParentType,
    ContextType
  >
  icon?: Resolver<
    Maybe<ResolversTypes['OnboardingIcon']>,
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  subtext?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OnboardingDefaultItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OnboardingDefaultItem'] = ResolversParentTypes['OnboardingDefaultItem']
> = {
  action?: Resolver<
    Maybe<ResolversTypes['OnboardingStepAction']>,
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  metadata?: Resolver<
    ResolversTypes['OnboardingItemMetadata'],
    ParentType,
    ContextType
  >
  status?: Resolver<ResolversTypes['OnboardingStatus'], ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OnboardingDefaultItemMetadataV2Resolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OnboardingDefaultItemMetadataV2'] = ResolversParentTypes['OnboardingDefaultItemMetadataV2']
> = {
  aboveItemMessage?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  belowItemMessage?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OnboardingDefaultItemV2Resolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OnboardingDefaultItemV2'] = ResolversParentTypes['OnboardingDefaultItemV2']
> = {
  action?: Resolver<
    Maybe<ResolversTypes['OnboardingAction']>,
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  metadata?: Resolver<
    Maybe<ResolversTypes['OnboardingItemMetadataV2']>,
    ParentType,
    ContextType
  >
  status?: Resolver<ResolversTypes['OnboardingStatus'], ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OnboardingDefaultStepDetailsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OnboardingDefaultStepDetails'] = ResolversParentTypes['OnboardingDefaultStepDetails']
> = {
  actions?: Resolver<
    Array<Maybe<ResolversTypes['OnboardingAction']>>,
    ParentType,
    ContextType
  >
  description?: Resolver<
    Maybe<ResolversTypes['MarkdownText']>,
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  info?: Resolver<
    Array<Maybe<ResolversTypes['OnboardingStepInfo']>>,
    ParentType,
    ContextType
  >
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OnboardingDefaultStepInfoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OnboardingDefaultStepInfo'] = ResolversParentTypes['OnboardingDefaultStepInfo']
> = {
  action?: Resolver<
    Maybe<ResolversTypes['OnboardingAction']>,
    ParentType,
    ContextType
  >
  description?: Resolver<
    Maybe<ResolversTypes['MarkdownText']>,
    ParentType,
    ContextType
  >
  icon?: Resolver<
    Maybe<ResolversTypes['OnboardingIcon']>,
    ParentType,
    ContextType
  >
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OnboardingImplementationStepResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OnboardingImplementationStep'] = ResolversParentTypes['OnboardingImplementationStep']
> = {
  details?: Resolver<
    Maybe<ResolversTypes['OnboardingSetupStepDetails']>,
    ParentType,
    ContextType
  >
  icon?: Resolver<
    Maybe<ResolversTypes['OnboardingIcon']>,
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  items?: Resolver<
    Maybe<Array<ResolversTypes['OnboardingItem']>>,
    ParentType,
    ContextType
  >
  module?: Resolver<ResolversTypes['OnboardingModule'], ParentType, ContextType>
  status?: Resolver<ResolversTypes['OnboardingStatus'], ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OnboardingImplementationStepV2Resolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OnboardingImplementationStepV2'] = ResolversParentTypes['OnboardingImplementationStepV2']
> = {
  icon?: Resolver<
    Maybe<ResolversTypes['OnboardingIcon']>,
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  items?: Resolver<
    Array<ResolversTypes['OnboardingItemV2']>,
    ParentType,
    ContextType
  >
  status?: Resolver<ResolversTypes['OnboardingStatus'], ParentType, ContextType>
  subtext?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OnboardingInfoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OnboardingInfo'] = ResolversParentTypes['OnboardingInfo']
> = {
  __resolveType: TypeResolveFn<'OnboardingDefaultInfo', ParentType, ContextType>
  action?: Resolver<
    Maybe<ResolversTypes['OnboardingAction']>,
    ParentType,
    ContextType
  >
  icon?: Resolver<
    Maybe<ResolversTypes['OnboardingIcon']>,
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  subtext?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
}

export type OnboardingInfoStepResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OnboardingInfoStep'] = ResolversParentTypes['OnboardingInfoStep']
> = {
  details?: Resolver<
    Maybe<ResolversTypes['OnboardingSetupStepDetails']>,
    ParentType,
    ContextType
  >
  icon?: Resolver<
    Maybe<ResolversTypes['OnboardingIcon']>,
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  module?: Resolver<ResolversTypes['OnboardingModule'], ParentType, ContextType>
  status?: Resolver<ResolversTypes['OnboardingStatus'], ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OnboardingItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OnboardingItem'] = ResolversParentTypes['OnboardingItem']
> = {
  __resolveType: TypeResolveFn<'OnboardingDefaultItem', ParentType, ContextType>
  action?: Resolver<
    Maybe<ResolversTypes['OnboardingStepAction']>,
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  metadata?: Resolver<
    ResolversTypes['OnboardingItemMetadata'],
    ParentType,
    ContextType
  >
  status?: Resolver<ResolversTypes['OnboardingStatus'], ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
}

export type OnboardingItemMetadataResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OnboardingItemMetadata'] = ResolversParentTypes['OnboardingItemMetadata']
> = {
  aboveItemMessage?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  belowItemMessage?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  icon?: Resolver<
    Maybe<ResolversTypes['OnboardingIcon']>,
    ParentType,
    ContextType
  >
  statusBadge?: Resolver<
    Maybe<ResolversTypes['OnboardingLegacyStatusBadge']>,
    ParentType,
    ContextType
  >
  timeCreated?: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >
  timeUpdated?: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OnboardingItemMetadataV2Resolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OnboardingItemMetadataV2'] = ResolversParentTypes['OnboardingItemMetadataV2']
> = {
  __resolveType: TypeResolveFn<
    'OnboardingDefaultItemMetadataV2' | 'OnboardingMeetingItemMetadata',
    ParentType,
    ContextType
  >
  aboveItemMessage?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  belowItemMessage?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
}

export type OnboardingItemV2Resolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OnboardingItemV2'] = ResolversParentTypes['OnboardingItemV2']
> = {
  __resolveType: TypeResolveFn<
    'OnboardingDefaultItemV2' | 'OnboardingMeetingItem',
    ParentType,
    ContextType
  >
  action?: Resolver<
    Maybe<ResolversTypes['OnboardingAction']>,
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  metadata?: Resolver<
    Maybe<ResolversTypes['OnboardingItemMetadataV2']>,
    ParentType,
    ContextType
  >
  status?: Resolver<ResolversTypes['OnboardingStatus'], ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
}

export type OnboardingLegacyDynamicLinkActionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OnboardingLegacyDynamicLinkAction'] = ResolversParentTypes['OnboardingLegacyDynamicLinkAction']
> = {
  desktopUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  isDynamicUrl?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  mobileUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OnboardingLegacyItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OnboardingLegacyItem'] = ResolversParentTypes['OnboardingLegacyItem']
> = {
  aboveItemMessage?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  action?: Resolver<
    Maybe<ResolversTypes['OnboardingStepAction']>,
    ParentType,
    ContextType
  >
  belowItemMessage?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  status?: Resolver<ResolversTypes['OnboardingStatus'], ParentType, ContextType>
  statusBadge?: Resolver<
    Maybe<ResolversTypes['OnboardingLegacyStatusBadge']>,
    ParentType,
    ContextType
  >
  timeCreated?: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >
  timeUpdated?: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OnboardingLegacyItemDetailsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OnboardingLegacyItemDetails'] = ResolversParentTypes['OnboardingLegacyItemDetails']
> = {
  description?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  legacyItems?: Resolver<
    Array<ResolversTypes['OnboardingLegacyItem']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OnboardingLegacyStatusBadgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OnboardingLegacyStatusBadge'] = ResolversParentTypes['OnboardingLegacyStatusBadge']
> = {
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  tooltipText?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  type?: Resolver<
    ResolversTypes['OnboardingLegacyBadgeType'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OnboardingLinkActionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OnboardingLinkAction'] = ResolversParentTypes['OnboardingLinkAction']
> = {
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OnboardingMeetingItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OnboardingMeetingItem'] = ResolversParentTypes['OnboardingMeetingItem']
> = {
  action?: Resolver<
    Maybe<ResolversTypes['OnboardingAction']>,
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  metadata?: Resolver<
    Maybe<ResolversTypes['OnboardingMeetingItemMetadata']>,
    ParentType,
    ContextType
  >
  status?: Resolver<ResolversTypes['OnboardingStatus'], ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OnboardingMeetingItemMetadataResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OnboardingMeetingItemMetadata'] = ResolversParentTypes['OnboardingMeetingItemMetadata']
> = {
  aboveItemMessage?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  actionSheet?: Resolver<
    Array<ResolversTypes['OnboardingInfo']>,
    ParentType,
    ContextType
  >
  agenda?: Resolver<
    Array<ResolversTypes['OnboardingInfo']>,
    ParentType,
    ContextType
  >
  belowItemMessage?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  conferencing?: Resolver<
    Maybe<ResolversTypes['ConferencingPlatform']>,
    ParentType,
    ContextType
  >
  duration?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  joinLink?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  meetingDateTime?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  meetingHost?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  meetingTimezone?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  preparationSteps?: Resolver<
    Array<ResolversTypes['OnboardingInfo']>,
    ParentType,
    ContextType
  >
  rescheduleUrl?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OnboardingMeetingStepResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OnboardingMeetingStep'] = ResolversParentTypes['OnboardingMeetingStep']
> = {
  details?: Resolver<
    Maybe<ResolversTypes['OnboardingMeetingStepDetails']>,
    ParentType,
    ContextType
  >
  icon?: Resolver<
    Maybe<ResolversTypes['OnboardingIcon']>,
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  module?: Resolver<ResolversTypes['OnboardingModule'], ParentType, ContextType>
  status?: Resolver<ResolversTypes['OnboardingStatus'], ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OnboardingMeetingStepDetailsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OnboardingMeetingStepDetails'] = ResolversParentTypes['OnboardingMeetingStepDetails']
> = {
  actions?: Resolver<
    Array<Maybe<ResolversTypes['OnboardingAction']>>,
    ParentType,
    ContextType
  >
  conferencing?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  description?: Resolver<
    Maybe<ResolversTypes['MarkdownText']>,
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  info?: Resolver<
    Array<Maybe<ResolversTypes['OnboardingStepInfo']>>,
    ParentType,
    ContextType
  >
  meetingDateTime?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  meetingDuration?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >
  meetingHost?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  meetingTimezone?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OnboardingMutationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OnboardingMutation'] = ResolversParentTypes['OnboardingMutation']
> = {
  updateAnticipatedPosGoLiveDate?: Resolver<
    ResolversTypes['LocationSetupInformation'],
    ParentType,
    ContextType,
    RequireFields<
      OnboardingMutationUpdateAnticipatedPosGoLiveDateArgs,
      'date' | 'locationId'
    >
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OnboardingNavigationActionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OnboardingNavigationAction'] = ResolversParentTypes['OnboardingNavigationAction']
> = {
  associatedTask?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  type?: Resolver<
    ResolversTypes['OnboardingActionType'],
    ParentType,
    ContextType
  >
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OnboardingQueryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OnboardingQuery'] = ResolversParentTypes['OnboardingQuery']
> = {
  hardwareOrder?: Resolver<
    Maybe<ResolversTypes['HardwareOrder']>,
    ParentType,
    ContextType,
    RequireFields<OnboardingQueryHardwareOrderArgs, 'locationId'>
  >
  locationSetupInformation?: Resolver<
    ResolversTypes['LocationSetupInformation'],
    ParentType,
    ContextType,
    RequireFields<OnboardingQueryLocationSetupInformationArgs, 'locationId'>
  >
  locationSetupSteps?: Resolver<
    Maybe<Array<ResolversTypes['OnboardingSetupStep']>>,
    ParentType,
    ContextType,
    RequireFields<
      OnboardingQueryLocationSetupStepsArgs,
      'filter' | 'locationId'
    >
  >
  locationSetupStepsV2?: Resolver<
    Maybe<Array<ResolversTypes['OnboardingSetupStepV2']>>,
    ParentType,
    ContextType,
    RequireFields<
      OnboardingQueryLocationSetupStepsV2Args,
      'filter' | 'locationId'
    >
  >
  onboardingSteps?: Resolver<
    Maybe<Array<ResolversTypes['OnboardingStep']>>,
    ParentType,
    ContextType,
    RequireFields<OnboardingQueryOnboardingStepsArgs, 'filter' | 'locationId'>
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OnboardingScheduleAppointmentLinkActionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OnboardingScheduleAppointmentLinkAction'] = ResolversParentTypes['OnboardingScheduleAppointmentLinkAction']
> = {
  scheduleUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OnboardingSetupStepResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OnboardingSetupStep'] = ResolversParentTypes['OnboardingSetupStep']
> = {
  __resolveType: TypeResolveFn<
    | 'OnboardingImplementationStep'
    | 'OnboardingInfoStep'
    | 'OnboardingMeetingStep',
    ParentType,
    ContextType
  >
  details?: Resolver<
    Maybe<ResolversTypes['OnboardingSetupStepDetails']>,
    ParentType,
    ContextType
  >
  icon?: Resolver<
    Maybe<ResolversTypes['OnboardingIcon']>,
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  module?: Resolver<ResolversTypes['OnboardingModule'], ParentType, ContextType>
  status?: Resolver<ResolversTypes['OnboardingStatus'], ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
}

export type OnboardingSetupStepDetailsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OnboardingSetupStepDetails'] = ResolversParentTypes['OnboardingSetupStepDetails']
> = {
  __resolveType: TypeResolveFn<
    'OnboardingDefaultStepDetails' | 'OnboardingMeetingStepDetails',
    ParentType,
    ContextType
  >
  actions?: Resolver<
    Array<Maybe<ResolversTypes['OnboardingAction']>>,
    ParentType,
    ContextType
  >
  description?: Resolver<
    Maybe<ResolversTypes['MarkdownText']>,
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  info?: Resolver<
    Array<Maybe<ResolversTypes['OnboardingStepInfo']>>,
    ParentType,
    ContextType
  >
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
}

export type OnboardingSetupStepV2Resolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OnboardingSetupStepV2'] = ResolversParentTypes['OnboardingSetupStepV2']
> = {
  __resolveType: TypeResolveFn<
    'OnboardingImplementationStepV2',
    ParentType,
    ContextType
  >
  icon?: Resolver<
    Maybe<ResolversTypes['OnboardingIcon']>,
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  status?: Resolver<ResolversTypes['OnboardingStatus'], ParentType, ContextType>
  subtext?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
}

export type OnboardingShareActionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OnboardingShareAction'] = ResolversParentTypes['OnboardingShareAction']
> = {
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  subject?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  type?: Resolver<
    ResolversTypes['OnboardingActionType'],
    ParentType,
    ContextType
  >
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OnboardingStepResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OnboardingStep'] = ResolversParentTypes['OnboardingStep']
> = {
  description?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  details?: Resolver<
    Maybe<ResolversTypes['OnboardingStepDetails']>,
    ParentType,
    ContextType
  >
  icon?: Resolver<
    Maybe<ResolversTypes['OnboardingIcon']>,
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  itemDetails?: Resolver<
    Maybe<ResolversTypes['OnboardingLegacyItemDetails']>,
    ParentType,
    ContextType
  >
  module?: Resolver<ResolversTypes['OnboardingModule'], ParentType, ContextType>
  status?: Resolver<ResolversTypes['OnboardingStatus'], ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OnboardingStepActionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OnboardingStepAction'] = ResolversParentTypes['OnboardingStepAction']
> = {
  __resolveType: TypeResolveFn<
    | 'OnboardingLegacyDynamicLinkAction'
    | 'OnboardingLinkAction'
    | 'OnboardingScheduleAppointmentLinkAction',
    ParentType,
    ContextType
  >
}

export type OnboardingStepDetailsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OnboardingStepDetails'] = ResolversParentTypes['OnboardingStepDetails']
> = {
  action?: Resolver<
    Maybe<ResolversTypes['OnboardingStepAction']>,
    ParentType,
    ContextType
  >
  description?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  substeps?: Resolver<
    Array<ResolversTypes['OnboardingStep']>,
    ParentType,
    ContextType
  >
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OnboardingStepInfoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OnboardingStepInfo'] = ResolversParentTypes['OnboardingStepInfo']
> = {
  __resolveType: TypeResolveFn<
    'OnboardingDefaultStepInfo',
    ParentType,
    ContextType
  >
  action?: Resolver<
    Maybe<ResolversTypes['OnboardingAction']>,
    ParentType,
    ContextType
  >
  description?: Resolver<
    Maybe<ResolversTypes['MarkdownText']>,
    ParentType,
    ContextType
  >
  icon?: Resolver<
    Maybe<ResolversTypes['OnboardingIcon']>,
    ParentType,
    ContextType
  >
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
}

export type OnlineOrderingStatusLabelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OnlineOrderingStatusLabel'] = ResolversParentTypes['OnlineOrderingStatusLabel']
> = {
  state?: Resolver<
    ResolversTypes['OnlineOrderingStatusLabelState'],
    ParentType,
    ContextType
  >
  textContent?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OpenCashDrawerReasonResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OpenCashDrawerReason'] = ResolversParentTypes['OpenCashDrawerReason']
> = {
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isActive?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  isArchived?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  ordinal?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OperatingScheduleResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OperatingSchedule'] = ResolversParentTypes['OperatingSchedule']
> = {
  daySchedules?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['DayOperatingSchedule']>>>,
    ParentType,
    ContextType
  >
  services?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['OperatingScheduleService']>>>,
    ParentType,
    ContextType
  >
  weekSchedule?: Resolver<
    Maybe<ResolversTypes['WeekOperatingSchedule']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OperatingScheduleServiceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OperatingScheduleService'] = ResolversParentTypes['OperatingScheduleService']
> = {
  __resolveReference?: ReferenceResolver<
    Maybe<ResolversTypes['OperatingScheduleService']>,
    { __typename: 'OperatingScheduleService' } & GraphQLRecursivePick<
      ParentType,
      { id: true }
    >,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  order?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OperatingScheduleServicePeriodResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OperatingScheduleServicePeriod'] = ResolversParentTypes['OperatingScheduleServicePeriod']
> = {
  endTime?: Resolver<ResolversTypes['LocalTime'], ParentType, ContextType>
  overnight?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  service?: Resolver<
    ResolversTypes['OperatingScheduleService'],
    ParentType,
    ContextType
  >
  startTime?: Resolver<ResolversTypes['LocalTime'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OptMenuConfigResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OptMenuConfig'] = ResolversParentTypes['OptMenuConfig']
> = {
  menusEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  showFeaturedItems?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  showPopularItems?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OptMenuConfigOverrideResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OptMenuConfigOverride'] = ResolversParentTypes['OptMenuConfigOverride']
> = {
  fields?: Resolver<
    Array<ResolversTypes['ConfigField']>,
    ParentType,
    ContextType
  >
  menusEnabled?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  showFeaturedItems?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  showPopularItems?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  target?: Resolver<ResolversTypes['RestaurantSet'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OptMutationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OptMutation'] = ResolversParentTypes['OptMutation']
> = {
  deleteOptMenuConfigOverrides?: Resolver<
    ResolversTypes['OptSavedMenuConfig'],
    ParentType,
    ContextType,
    RequireFields<OptMutationDeleteOptMenuConfigOverridesArgs, 'targets'>
  >
  deleteOptOrderingConfigOverrides?: Resolver<
    ResolversTypes['OptSavedOrderingConfig'],
    ParentType,
    ContextType,
    RequireFields<OptMutationDeleteOptOrderingConfigOverridesArgs, 'targets'>
  >
  deleteOptPaymentConfigOverrides?: Resolver<
    ResolversTypes['OptSavedPaymentConfig'],
    ParentType,
    ContextType,
    RequireFields<OptMutationDeleteOptPaymentConfigOverridesArgs, 'targets'>
  >
  deleteOptSpotlightConfigOverrides?: Resolver<
    ResolversTypes['OptSavedSpotlightConfig'],
    ParentType,
    ContextType,
    RequireFields<OptMutationDeleteOptSpotlightConfigOverridesArgs, 'targets'>
  >
  saveOptMenuConfigBase?: Resolver<
    ResolversTypes['OptMenuConfig'],
    ParentType,
    ContextType,
    RequireFields<OptMutationSaveOptMenuConfigBaseArgs, 'input'>
  >
  saveOptMenuConfigOverrides?: Resolver<
    ResolversTypes['OptSavedMenuConfig'],
    ParentType,
    ContextType,
    RequireFields<OptMutationSaveOptMenuConfigOverridesArgs, 'input'>
  >
  saveOptOrderingConfigBase?: Resolver<
    ResolversTypes['OptOrderingConfig'],
    ParentType,
    ContextType,
    RequireFields<OptMutationSaveOptOrderingConfigBaseArgs, 'input'>
  >
  saveOptOrderingConfigOverrides?: Resolver<
    ResolversTypes['OptSavedOrderingConfig'],
    ParentType,
    ContextType,
    RequireFields<OptMutationSaveOptOrderingConfigOverridesArgs, 'input'>
  >
  saveOptPaymentConfigBase?: Resolver<
    ResolversTypes['OptPaymentConfig'],
    ParentType,
    ContextType,
    RequireFields<OptMutationSaveOptPaymentConfigBaseArgs, 'input'>
  >
  saveOptPaymentConfigOverrides?: Resolver<
    ResolversTypes['OptSavedPaymentConfig'],
    ParentType,
    ContextType,
    RequireFields<OptMutationSaveOptPaymentConfigOverridesArgs, 'input'>
  >
  saveOptSpotlightConfigBase?: Resolver<
    ResolversTypes['OptSpotlightConfig'],
    ParentType,
    ContextType,
    RequireFields<OptMutationSaveOptSpotlightConfigBaseArgs, 'input'>
  >
  saveOptSpotlightConfigOverrides?: Resolver<
    ResolversTypes['OptSavedSpotlightConfig'],
    ParentType,
    ContextType,
    RequireFields<OptMutationSaveOptSpotlightConfigOverridesArgs, 'input'>
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OptOrderingConfigResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OptOrderingConfig'] = ResolversParentTypes['OptOrderingConfig']
> = {
  orderingEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OptOrderingConfigOverrideResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OptOrderingConfigOverride'] = ResolversParentTypes['OptOrderingConfigOverride']
> = {
  fields?: Resolver<
    Array<ResolversTypes['ConfigField']>,
    ParentType,
    ContextType
  >
  orderingEnabled?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  target?: Resolver<ResolversTypes['RestaurantSet'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OptPaymentConfigResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OptPaymentConfig'] = ResolversParentTypes['OptPaymentConfig']
> = {
  paymentEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OptPaymentConfigOverrideResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OptPaymentConfigOverride'] = ResolversParentTypes['OptPaymentConfigOverride']
> = {
  fields?: Resolver<
    Array<ResolversTypes['ConfigField']>,
    ParentType,
    ContextType
  >
  paymentEnabled?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  target?: Resolver<ResolversTypes['RestaurantSet'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OptQueryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OptQuery'] = ResolversParentTypes['OptQuery']
> = {
  resolvedOptMenuConfig?: Resolver<
    ResolversTypes['OptMenuConfig'],
    ParentType,
    ContextType
  >
  resolvedOptOrderingConfig?: Resolver<
    ResolversTypes['OptOrderingConfig'],
    ParentType,
    ContextType
  >
  resolvedOptPaymentConfig?: Resolver<
    ResolversTypes['OptPaymentConfig'],
    ParentType,
    ContextType
  >
  resolvedOptSpotlightConfig?: Resolver<
    ResolversTypes['OptSpotlightConfig'],
    ParentType,
    ContextType
  >
  savedOptMenuConfig?: Resolver<
    ResolversTypes['OptSavedMenuConfig'],
    ParentType,
    ContextType
  >
  savedOptOrderingConfig?: Resolver<
    ResolversTypes['OptSavedOrderingConfig'],
    ParentType,
    ContextType
  >
  savedOptPaymentConfig?: Resolver<
    ResolversTypes['OptSavedPaymentConfig'],
    ParentType,
    ContextType
  >
  savedOptSpotlightConfig?: Resolver<
    ResolversTypes['OptSavedSpotlightConfig'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OptSavedMenuConfigResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OptSavedMenuConfig'] = ResolversParentTypes['OptSavedMenuConfig']
> = {
  baseConfig?: Resolver<
    ResolversTypes['OptMenuConfig'],
    ParentType,
    ContextType
  >
  overrides?: Resolver<
    Maybe<Array<ResolversTypes['OptMenuConfigOverride']>>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OptSavedOrderingConfigResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OptSavedOrderingConfig'] = ResolversParentTypes['OptSavedOrderingConfig']
> = {
  baseConfig?: Resolver<
    ResolversTypes['OptOrderingConfig'],
    ParentType,
    ContextType
  >
  overrides?: Resolver<
    Maybe<Array<ResolversTypes['OptOrderingConfigOverride']>>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OptSavedPaymentConfigResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OptSavedPaymentConfig'] = ResolversParentTypes['OptSavedPaymentConfig']
> = {
  baseConfig?: Resolver<
    ResolversTypes['OptPaymentConfig'],
    ParentType,
    ContextType
  >
  overrides?: Resolver<
    Maybe<Array<ResolversTypes['OptPaymentConfigOverride']>>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OptSavedSpotlightConfigResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OptSavedSpotlightConfig'] = ResolversParentTypes['OptSavedSpotlightConfig']
> = {
  baseConfig?: Resolver<
    ResolversTypes['OptSpotlightConfig'],
    ParentType,
    ContextType
  >
  overrides?: Resolver<
    Maybe<Array<ResolversTypes['OptSpotlightConfigOverride']>>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OptSpotlightConfigResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OptSpotlightConfig'] = ResolversParentTypes['OptSpotlightConfig']
> = {
  body?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  header?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  visible?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OptSpotlightConfigOverrideResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OptSpotlightConfigOverride'] = ResolversParentTypes['OptSpotlightConfigOverride']
> = {
  body?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  fields?: Resolver<
    Array<ResolversTypes['ConfigField']>,
    ParentType,
    ContextType
  >
  header?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  target?: Resolver<ResolversTypes['RestaurantSet'], ParentType, ContextType>
  visible?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OrderResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Order'] = ResolversParentTypes['Order']
> = {
  __resolveReference?: ReferenceResolver<
    Maybe<ResolversTypes['Order']>,
    { __typename: 'Order' } & GraphQLRecursivePick<ParentType, { id: true }>,
    ContextType
  >
  channel?: Resolver<
    Maybe<ResolversTypes['PublishedChannel']>,
    ParentType,
    ContextType
  >
  checks?: Resolver<
    Array<ResolversTypes['Check']>,
    ParentType,
    ContextType,
    Partial<OrderChecksArgs>
  >
  curbsidePickupInfo?: Resolver<
    Maybe<ResolversTypes['OrdersCurbsidePickupInfo']>,
    ParentType,
    ContextType
  >
  deleted?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  diningOption?: Resolver<
    Maybe<ResolversTypes['SavedDiningOption']>,
    ParentType,
    ContextType
  >
  displayNumber?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  numberOfGuests?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >
  promisedDate?: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >
  server?: Resolver<
    Maybe<ResolversTypes['RestaurantUser']>,
    ParentType,
    ContextType
  >
  table?: Resolver<
    Maybe<ResolversTypes['SavedRestaurantTable']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OrderConfirmationScreenConfigOverrideResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrderConfirmationScreenConfigOverride'] = ResolversParentTypes['OrderConfirmationScreenConfigOverride']
> = {
  backgroundColor?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  fields?: Resolver<
    Array<ResolversTypes['ConfigField']>,
    ParentType,
    ContextType
  >
  fontPrimaryColor?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  fontSecondaryColor?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  fontSize?: Resolver<
    Maybe<ResolversTypes['OrderConfirmationScreenFontSize']>,
    ParentType,
    ContextType
  >
  fontUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  idleBackgroundImageDurationMs?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >
  idleBackgroundImageUrls?: Resolver<
    Maybe<Array<ResolversTypes['String']>>,
    ParentType,
    ContextType
  >
  orderInProgressBackgroundImageUrl?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  orderInProgressForegroundColor?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  target?: Resolver<ResolversTypes['RestaurantSet'], ParentType, ContextType>
  thankYouMessage?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  thankYouTitle?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OrderCountResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrderCount'] = ResolversParentTypes['OrderCount']
> = {
  hourly?: Resolver<
    Array<ResolversTypes['IntegerMetric']>,
    ParentType,
    ContextType
  >
  totals?: Resolver<ResolversTypes['IntegerMetric'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OrderMetricsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrderMetrics'] = ResolversParentTypes['OrderMetrics']
> = {
  checksWithRefundsCount?: Resolver<
    Maybe<ResolversTypes['ChecksWithRefundsCount']>,
    ParentType,
    ContextType
  >
  checksWithVoidsCount?: Resolver<
    Maybe<ResolversTypes['ChecksWithVoidsCount']>,
    ParentType,
    ContextType,
    RequireFields<OrderMetricsChecksWithVoidsCountArgs, 'filter'>
  >
  discountAmount?: Resolver<
    Maybe<ResolversTypes['DiscountAmount']>,
    ParentType,
    ContextType,
    RequireFields<OrderMetricsDiscountAmountArgs, 'filter'>
  >
  discountCount?: Resolver<
    Maybe<ResolversTypes['DiscountCount']>,
    ParentType,
    ContextType,
    RequireFields<OrderMetricsDiscountCountArgs, 'filter'>
  >
  guestCount?: Resolver<
    Maybe<ResolversTypes['GuestCount']>,
    ParentType,
    ContextType
  >
  netSales?: Resolver<
    Maybe<ResolversTypes['NetSales']>,
    ParentType,
    ContextType
  >
  orderCount?: Resolver<
    Maybe<ResolversTypes['OrderCount']>,
    ParentType,
    ContextType
  >
  totalRefundAmount?: Resolver<
    Maybe<ResolversTypes['TotalRefundAmount']>,
    ParentType,
    ContextType
  >
  voidAmount?: Resolver<
    Maybe<ResolversTypes['VoidAmount']>,
    ParentType,
    ContextType,
    RequireFields<OrderMetricsVoidAmountArgs, 'filter'>
  >
  voidAmountAsPercentageOfNetSales?: Resolver<
    Maybe<ResolversTypes['VoidAmountAsPercentageOfNetSales']>,
    ParentType,
    ContextType
  >
  voidedItemsCount?: Resolver<
    Maybe<ResolversTypes['VoidedItemsCount']>,
    ParentType,
    ContextType,
    RequireFields<OrderMetricsVoidedItemsCountArgs, 'filter'>
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OrderValidityCheckResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrderValidityCheck'] = ResolversParentTypes['OrderValidityCheck']
> = {
  entityId?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  featureGroupId?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  invalidLocations?: Resolver<
    Maybe<Array<ResolversTypes['InvalidLocation']>>,
    ParentType,
    ContextType
  >
  validLocations?: Resolver<
    Maybe<Array<ResolversTypes['String']>>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OrderValidityCheckResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrderValidityCheckResponse'] = ResolversParentTypes['OrderValidityCheckResponse']
> = {
  orderValidityCheck?: Resolver<
    ResolversTypes['OrderValidityCheck'],
    ParentType,
    ContextType
  >
  status?: Resolver<
    ResolversTypes['TrialOperationStatus'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OrderingScheduleResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrderingSchedule'] = ResolversParentTypes['OrderingSchedule']
> = {
  closeoutHour?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  lastOrderConfiguration?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >
  maxDayPeriodTimeRange?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType
  >
  overrides?: Resolver<
    Array<ResolversTypes['Overrides']>,
    ParentType,
    ContextType
  >
  schedule?: Resolver<
    Array<ResolversTypes['Schedule']>,
    ParentType,
    ContextType
  >
  scheduledOrderMaxDays?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OrdersAppliedDiscountResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrdersAppliedDiscount'] = ResolversParentTypes['OrdersAppliedDiscount']
> = {
  discountAmount?: Resolver<
    Maybe<ResolversTypes['Money']>,
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OrdersAppliedLoyaltyInfoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrdersAppliedLoyaltyInfo'] = ResolversParentTypes['OrdersAppliedLoyaltyInfo']
> = {
  accrualText?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  maskedLoyaltyIdentifier?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OrdersAppliedPreauthInfoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrdersAppliedPreauthInfo'] = ResolversParentTypes['OrdersAppliedPreauthInfo']
> = {
  cardHolderFirstName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  cardHolderLastName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  last4CardDigits?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OrdersConfigMutationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrdersConfigMutation'] = ResolversParentTypes['OrdersConfigMutation']
> = {
  updateDiningOption?: Resolver<
    ResolversTypes['SavedDiningOption'],
    ParentType,
    ContextType,
    RequireFields<OrdersConfigMutationUpdateDiningOptionArgs, 'input'>
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OrdersConfigQueryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrdersConfigQuery'] = ResolversParentTypes['OrdersConfigQuery']
> = {
  savedDiningOption?: Resolver<
    Maybe<ResolversTypes['SavedDiningOption']>,
    ParentType,
    ContextType,
    RequireFields<OrdersConfigQuerySavedDiningOptionArgs, 'id'>
  >
  savedDiningOptions?: Resolver<
    Array<ResolversTypes['SavedDiningOption']>,
    ParentType,
    ContextType,
    RequireFields<OrdersConfigQuerySavedDiningOptionsArgs, 'includeArchived'>
  >
  savedRevenueCenters?: Resolver<
    Array<ResolversTypes['SavedRevenueCenter']>,
    ParentType,
    ContextType,
    RequireFields<OrdersConfigQuerySavedRevenueCentersArgs, 'search'>
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OrdersCurbsidePickupInfoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrdersCurbsidePickupInfo'] = ResolversParentTypes['OrdersCurbsidePickupInfo']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  notes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  transportColor?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  transportDescription?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OrdersGiftCardInfoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrdersGiftCardInfo'] = ResolversParentTypes['OrdersGiftCardInfo']
> = {
  last4CardDigits?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OrdersMutationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrdersMutation'] = ResolversParentTypes['OrdersMutation']
> = {
  updateTipAmount?: Resolver<
    Maybe<ResolversTypes['Order']>,
    ParentType,
    ContextType,
    RequireFields<OrdersMutationUpdateTipAmountArgs, 'input'>
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OrdersPaymentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrdersPayment'] = ResolversParentTypes['OrdersPayment']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Money']>, ParentType, ContextType>
  canBeTipAdjusted?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  cardType?: Resolver<
    Maybe<ResolversTypes['OrdersPaymentCardType']>,
    ParentType,
    ContextType
  >
  giftCardInfo?: Resolver<
    Maybe<ResolversTypes['OrdersGiftCardInfo']>,
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  last4Digits?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  paymentStatus?: Resolver<
    Maybe<ResolversTypes['OrdersPaymentStatus']>,
    ParentType,
    ContextType
  >
  postRefundTotal?: Resolver<
    Maybe<ResolversTypes['Money']>,
    ParentType,
    ContextType
  >
  refund?: Resolver<
    Maybe<ResolversTypes['OrdersRefund']>,
    ParentType,
    ContextType
  >
  refundStatus?: Resolver<
    Maybe<ResolversTypes['OrdersPaymentRefundStatus']>,
    ParentType,
    ContextType
  >
  tipAmount?: Resolver<Maybe<ResolversTypes['Money']>, ParentType, ContextType>
  totalAmount?: Resolver<
    Maybe<ResolversTypes['Money']>,
    ParentType,
    ContextType
  >
  type?: Resolver<
    Maybe<ResolversTypes['OrdersPaymentType']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OrdersQueryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrdersQuery'] = ResolversParentTypes['OrdersQuery']
> = {
  check?: Resolver<
    Maybe<ResolversTypes['Check']>,
    ParentType,
    ContextType,
    RequireFields<OrdersQueryCheckArgs, 'checkId' | 'orderId'>
  >
  ordersSummary?: Resolver<
    Maybe<ResolversTypes['OrdersSummary']>,
    ParentType,
    ContextType,
    RequireFields<
      OrdersQueryOrdersSummaryArgs,
      'businessDate' | 'checkPaymentStatus' | 'includeDeleted'
    >
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OrdersRefundResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrdersRefund'] = ResolversParentTypes['OrdersRefund']
> = {
  refundAmount?: Resolver<
    Maybe<ResolversTypes['Money']>,
    ParentType,
    ContextType
  >
  tipRefundAmount?: Resolver<
    Maybe<ResolversTypes['Money']>,
    ParentType,
    ContextType
  >
  totalRefundAmount?: Resolver<
    Maybe<ResolversTypes['Money']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OrdersRefundDetailsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrdersRefundDetails'] = ResolversParentTypes['OrdersRefundDetails']
> = {
  refundAmount?: Resolver<
    Maybe<ResolversTypes['Money']>,
    ParentType,
    ContextType
  >
  taxRefundAmount?: Resolver<
    Maybe<ResolversTypes['Money']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OrdersSelectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrdersSelection'] = ResolversParentTypes['OrdersSelection']
> = {
  appliedDiscounts?: Resolver<
    Array<ResolversTypes['OrdersAppliedDiscount']>,
    ParentType,
    ContextType
  >
  createdDate?: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >
  diningOption?: Resolver<
    Maybe<ResolversTypes['SavedDiningOption']>,
    ParentType,
    ContextType
  >
  displayName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  fulfillmentStatus?: Resolver<
    Maybe<ResolversTypes['OrdersFulfillmentStatus']>,
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  modifiedDate?: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >
  modifiers?: Resolver<
    Array<ResolversTypes['OrdersSelection']>,
    ParentType,
    ContextType
  >
  preDiscountPrice?: Resolver<
    Maybe<ResolversTypes['Money']>,
    ParentType,
    ContextType
  >
  price?: Resolver<Maybe<ResolversTypes['Money']>, ParentType, ContextType>
  quantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  receiptLinePrice?: Resolver<
    Maybe<ResolversTypes['Money']>,
    ParentType,
    ContextType
  >
  refundDetails?: Resolver<
    Maybe<ResolversTypes['OrdersRefundDetails']>,
    ParentType,
    ContextType
  >
  seatNumber?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  selectionType?: Resolver<
    Maybe<ResolversTypes['OrdersSelectionType']>,
    ParentType,
    ContextType
  >
  tax?: Resolver<Maybe<ResolversTypes['Money']>, ParentType, ContextType>
  taxInclusion?: Resolver<
    Maybe<ResolversTypes['OrdersTaxInclusion']>,
    ParentType,
    ContextType
  >
  unitOfMeasure?: Resolver<
    Maybe<ResolversTypes['OrdersUnitOfMeasure']>,
    ParentType,
    ContextType
  >
  voidBusinessDate?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >
  voidDate?: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >
  voided?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OrdersSummaryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrdersSummary'] = ResolversParentTypes['OrdersSummary']
> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  countOfChecks?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  orders?: Resolver<
    Maybe<Array<ResolversTypes['Order']>>,
    ParentType,
    ContextType
  >
  sumOfChecksTotalAmount?: Resolver<
    ResolversTypes['Money'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OutdatedPackagingConfigErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OutdatedPackagingConfigError'] = ResolversParentTypes['OutdatedPackagingConfigError']
> = {
  currentConfig?: Resolver<
    ResolversTypes['SavedPackagingConfig'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OverlappingBreaksResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OverlappingBreaks'] = ResolversParentTypes['OverlappingBreaks']
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OverlappingShiftExistsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OverlappingShiftExists'] = ResolversParentTypes['OverlappingShiftExists']
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OverrideDateRangeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OverrideDateRange'] = ResolversParentTypes['OverrideDateRange']
> = {
  endBusinessDate?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  startBusinessDate?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OverridePricingResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OverridePricing'] = ResolversParentTypes['OverridePricing']
> = {
  basePrice?: Resolver<Maybe<ResolversTypes['Money']>, ParentType, ContextType>
  hasOverridePrice?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OverridesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Overrides'] = ResolversParentTypes['Overrides']
> = {
  businessDate?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  dateRange?: Resolver<
    ResolversTypes['OverrideDateRange'],
    ParentType,
    ContextType
  >
  description?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  diningOptionBehavior?: Resolver<
    Maybe<Array<ResolversTypes['DiningOptionBehavior']>>,
    ParentType,
    ContextType
  >
  timeRanges?: Resolver<
    Maybe<Array<ResolversTypes['LocalTimeRange']>>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PackagingComplianceDataResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PackagingComplianceData'] = ResolversParentTypes['PackagingComplianceData']
> = {
  featureEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  locationDetails?: Resolver<
    ResolversTypes['PackagingComplianceDetails'],
    ParentType,
    ContextType
  >
  mgmtGroupSummary?: Resolver<
    ResolversTypes['PackagingComplianceSummary'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PackagingComplianceDetailsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PackagingComplianceDetails'] = ResolversParentTypes['PackagingComplianceDetails']
> = {
  applicableRegulations?: Resolver<
    Array<ResolversTypes['PackagingRegulation']>,
    ParentType,
    ContextType
  >
  locations?: Resolver<
    Array<ResolversTypes['PackagingLocationCompliance']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PackagingComplianceErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PackagingComplianceError'] = ResolversParentTypes['PackagingComplianceError']
> = {
  nonCompliantLocations?: Resolver<
    Array<ResolversTypes['PackagingComplianceLocationError']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PackagingComplianceLocationErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PackagingComplianceLocationError'] = ResolversParentTypes['PackagingComplianceLocationError']
> = {
  location?: Resolver<
    ResolversTypes['SavedRestaurantLocation'],
    ParentType,
    ContextType
  >
  nonCompliantRegulations?: Resolver<
    Array<ResolversTypes['PackagingComplianceRegulationError']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PackagingComplianceRegulationErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PackagingComplianceRegulationError'] = ResolversParentTypes['PackagingComplianceRegulationError']
> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  regulationId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PackagingComplianceSummaryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PackagingComplianceSummary'] = ResolversParentTypes['PackagingComplianceSummary']
> = {
  applicableRegulations?: Resolver<
    Array<ResolversTypes['PackagingRegulation']>,
    ParentType,
    ContextType
  >
  compliant?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  compliantCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  nonCompliantCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PackagingConfigOverrideResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PackagingConfigOverride'] = ResolversParentTypes['PackagingConfigOverride']
> = {
  disabledItemTypes?: Resolver<
    Array<ResolversTypes['PackagingItemType']>,
    ParentType,
    ContextType
  >
  enabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  guestMessage?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  items?: Resolver<
    Array<ResolversTypes['PackagingItem']>,
    ParentType,
    ContextType
  >
  overriddenFields?: Resolver<
    Array<ResolversTypes['PackagingField']>,
    ParentType,
    ContextType
  >
  owner?: Resolver<ResolversTypes['RestaurantSet'], ParentType, ContextType>
  posShowDefaults?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  revision?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  target?: Resolver<ResolversTypes['RestaurantSet'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PackagingItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PackagingItem'] = ResolversParentTypes['PackagingItem']
> = {
  guestDescription?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  guestInclusionType?: Resolver<
    ResolversTypes['PackagingGuestInclusion'],
    ParentType,
    ContextType
  >
  guestName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  itemTypes?: Resolver<
    Array<ResolversTypes['PackagingItemType']>,
    ParentType,
    ContextType
  >
  posName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PackagingLocationComplianceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PackagingLocationCompliance'] = ResolversParentTypes['PackagingLocationCompliance']
> = {
  compliant?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  location?: Resolver<
    ResolversTypes['SavedRestaurantLocation'],
    ParentType,
    ContextType
  >
  locationRegulations?: Resolver<
    Array<ResolversTypes['PackagingLocationRegulationCompliance']>,
    ParentType,
    ContextType
  >
  needsCompliance?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PackagingLocationRegulationComplianceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PackagingLocationRegulationCompliance'] = ResolversParentTypes['PackagingLocationRegulationCompliance']
> = {
  complianceOverridden?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  compliant?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  regulationId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PackagingMutationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PackagingMutation'] = ResolversParentTypes['PackagingMutation']
> = {
  saveConfig?: Resolver<
    ResolversTypes['SavePackagingConfigResponse'],
    ParentType,
    ContextType,
    RequireFields<PackagingMutationSaveConfigArgs, 'input'>
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PackagingQueryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PackagingQuery'] = ResolversParentTypes['PackagingQuery']
> = {
  compliance?: Resolver<
    ResolversTypes['PackagingComplianceData'],
    ParentType,
    ContextType
  >
  config?: Resolver<
    ResolversTypes['SavedPackagingConfig'],
    ParentType,
    ContextType
  >
  mgmtGroupLocationCount?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType
  >
  restrictions?: Resolver<
    ResolversTypes['PackagingRestrictions'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PackagingRegulationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PackagingRegulation'] = ResolversParentTypes['PackagingRegulation']
> = {
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  effectiveDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>
  fullName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  officialLink?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  shortName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  toastEnforced?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PackagingRestrictionsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PackagingRestrictions'] = ResolversParentTypes['PackagingRestrictions']
> = {
  baseConfigReadOnly?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  inaccessibleRestaurants?: Resolver<
    Array<ResolversTypes['SavedRestaurantLocation']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PageInfoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PageInfo'] = ResolversParentTypes['PageInfo']
> = {
  endCursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  hasNextPage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  hasPreviousPage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  startCursor?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PagedMenuItemResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PagedMenuItemResponse'] = ResolversParentTypes['PagedMenuItemResponse']
> = {
  entities?: Resolver<
    Array<ResolversTypes['MenuItem']>,
    ParentType,
    ContextType
  >
  nextPageToken?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PagedModifierGroupsResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PagedModifierGroupsResponse'] = ResolversParentTypes['PagedModifierGroupsResponse']
> = {
  entities?: Resolver<
    Array<ResolversTypes['ModifierGroup']>,
    ParentType,
    ContextType
  >
  nextPageToken?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PagedModifierResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PagedModifierResponse'] = ResolversParentTypes['PagedModifierResponse']
> = {
  entities?: Resolver<
    Array<ResolversTypes['Modifier']>,
    ParentType,
    ContextType
  >
  nextPageToken?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PagedPriceLevelResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PagedPriceLevelResponse'] = ResolversParentTypes['PagedPriceLevelResponse']
> = {
  entities?: Resolver<
    Array<ResolversTypes['PriceLevelEntity']>,
    ParentType,
    ContextType
  >
  nextPageToken?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PagedRenderedAuthorizationDecisionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PagedRenderedAuthorizationDecision'] = ResolversParentTypes['PagedRenderedAuthorizationDecision']
> = {
  page?: Resolver<ResolversTypes['AuthorizationPage'], ParentType, ContextType>
  results?: Resolver<
    Array<ResolversTypes['RenderedAuthorizationDecision']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PagedSavedChannelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PagedSavedChannel'] = ResolversParentTypes['PagedSavedChannel']
> = {
  currentPageToken?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  nextPageToken?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  pageSize?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  results?: Resolver<
    Array<ResolversTypes['SavedChannel']>,
    ParentType,
    ContextType
  >
  totalResultCount?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PagedSavedChannelAvailabilityResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PagedSavedChannelAvailability'] = ResolversParentTypes['PagedSavedChannelAvailability']
> = {
  currentPageToken?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  nextPageToken?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  pageSize?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  results?: Resolver<
    Array<ResolversTypes['SavedChannelAvailability']>,
    ParentType,
    ContextType
  >
  totalResultCount?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PagedSavedMenuItemTagsResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PagedSavedMenuItemTagsResponse'] = ResolversParentTypes['PagedSavedMenuItemTagsResponse']
> = {
  entities?: Resolver<
    Array<ResolversTypes['SavedMenuItemTag']>,
    ParentType,
    ContextType
  >
  nextPageToken?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ParentResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ParentResponse'] = ResolversParentTypes['ParentResponse']
> = {
  multiLocationId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  owner?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  target?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  versionId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PartnerResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Partner'] = ResolversParentTypes['Partner']
> = {
  __resolveReference?: ReferenceResolver<
    Maybe<ResolversTypes['Partner']>,
    { __typename: 'Partner' } & GraphQLRecursivePick<ParentType, { id: true }>,
    ContextType
  >
  additionalFee?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  adminRemoveOnly?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  applicationName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  availableForToastNow?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  behaviour?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  categories?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['PartnerCategory']>>>,
    ParentType,
    ContextType
  >
  channel?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  clientId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  connectRequired?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  countries?: Resolver<
    Maybe<Array<ResolversTypes['String']>>,
    ParentType,
    ContextType
  >
  createdBy?: Resolver<ResolversTypes['User'], ParentType, ContextType>
  createdDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  deleted?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  deletedDate?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  featureId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  iconUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  infoUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  isThirdPartyOrderPartner?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  mainUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  marketImageUrl?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  modifiedBy?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>
  modifiedDate?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  needsAccountVerification?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  onboardingEmail?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  originalIconUrl?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  preferred?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  primaryAction?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  primaryActionReference?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  primaryCustomCtaLabel?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  requiredFeatures?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['String']>>>,
    ParentType,
    ContextType
  >
  scopes?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['PartnerScope']>>>,
    ParentType,
    ContextType
  >
  secondaryAction?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  secondaryActionReference?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  secondaryCustomCtaLabel?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  signUpUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  supportEmail?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  supportPhone?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  version?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  visible?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PartnerApiAccessResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PartnerApiAccess'] = ResolversParentTypes['PartnerApiAccess']
> = {
  accessorId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  createdBy?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>
  createdDate?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  deleted?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  deletedBy?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>
  deletedDate?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  externalGroupRef?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  externalRestaurantRef?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  modifiedBy?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>
  modifiedDate?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  partner?: Resolver<ResolversTypes['Partner'], ParentType, ContextType>
  restaurantId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  restaurantLocation?: Resolver<
    ResolversTypes['SavedRestaurantLocation'],
    ParentType,
    ContextType
  >
  version?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PartnerCategoryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PartnerCategory'] = ResolversParentTypes['PartnerCategory']
> = {
  description?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  label?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PartnerChannelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PartnerChannel'] = ResolversParentTypes['PartnerChannel']
> = {
  __resolveReference?: ReferenceResolver<
    Maybe<ResolversTypes['PartnerChannel']>,
    { __typename: 'PartnerChannel' } & GraphQLRecursivePick<
      ParentType,
      { name: true }
    >,
    ContextType
  >
  channel?: Resolver<
    Maybe<ResolversTypes['SavedChannel']>,
    ParentType,
    ContextType
  >
  guid?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  iconUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  isEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  snoozeUntil?: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >
  statusLabel?: Resolver<
    ResolversTypes['PartnerStatusLabel'],
    ParentType,
    ContextType
  >
  thirdPartyOrderingIntegrationStatus?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  thirdPartyOrderingOnboardingFlow?: Resolver<
    Maybe<ResolversTypes['ThirdPartyOrderingOnboardingFlow']>,
    ParentType,
    ContextType
  >
  toggleVisible?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PartnerChannelConfigResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PartnerChannelConfig'] = ResolversParentTypes['PartnerChannelConfig']
> = {
  partnerChannels?: Resolver<
    Array<ResolversTypes['PartnerChannel']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PartnerQueryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PartnerQuery'] = ResolversParentTypes['PartnerQuery']
> = {
  apiAccessForAccessor?: Resolver<
    Array<ResolversTypes['PartnerApiAccess']>,
    ParentType,
    ContextType,
    RequireFields<PartnerQueryApiAccessForAccessorArgs, 'accessorId'>
  >
  categories?: Resolver<
    Array<ResolversTypes['PartnerCategory']>,
    ParentType,
    ContextType
  >
  partner?: Resolver<
    Maybe<ResolversTypes['Partner']>,
    ParentType,
    ContextType,
    RequireFields<PartnerQueryPartnerArgs, 'id'>
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PartnerScopeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PartnerScope'] = ResolversParentTypes['PartnerScope']
> = {
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PartnerStatusLabelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PartnerStatusLabel'] = ResolversParentTypes['PartnerStatusLabel']
> = {
  state?: Resolver<
    ResolversTypes['PartnerStatusLabelState'],
    ParentType,
    ContextType
  >
  textContent?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PaymentDataExtensionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PaymentDataExtension'] = ResolversParentTypes['PaymentDataExtension']
> = {
  __resolveType: TypeResolveFn<
    'AlternatePaymentDataExtension' | 'StaticPaymentDataExtension',
    ParentType,
    ContextType
  >
}

export type PaymentsConfigMutationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PaymentsConfigMutation'] = ResolversParentTypes['PaymentsConfigMutation']
> = {
  archiveGfdConfigs?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<
      PaymentsConfigMutationArchiveGfdConfigsArgs,
      'authorId' | 'restaurantId' | 'versionIds'
    >
  >
  createGfdConfig?: Resolver<
    ResolversTypes['GfdConfig'],
    ParentType,
    ContextType,
    RequireFields<
      PaymentsConfigMutationCreateGfdConfigArgs,
      'authorId' | 'newGfdConfig' | 'restaurantId'
    >
  >
  updateGfdConfigs?: Resolver<
    Array<ResolversTypes['GfdConfig']>,
    ParentType,
    ContextType,
    RequireFields<
      PaymentsConfigMutationUpdateGfdConfigsArgs,
      'authorId' | 'configs' | 'restaurantId'
    >
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PaymentsConfigQueryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PaymentsConfigQuery'] = ResolversParentTypes['PaymentsConfigQuery']
> = {
  alternatePaymentTypes?: Resolver<
    Array<ResolversTypes['SavedAlternatePaymentType']>,
    ParentType,
    ContextType,
    RequireFields<
      PaymentsConfigQueryAlternatePaymentTypesArgs,
      'restaurantSetIds'
    >
  >
  gfdAddOnStatuses?: Resolver<
    ResolversTypes['GfdAddOnStatus'],
    ParentType,
    ContextType
  >
  gfdConfig?: Resolver<
    Maybe<ResolversTypes['GfdConfig']>,
    ParentType,
    ContextType,
    RequireFields<PaymentsConfigQueryGfdConfigArgs, 'authorId' | 'restaurantId'>
  >
  gfdConfigByEntityId?: Resolver<
    Maybe<ResolversTypes['GfdConfig']>,
    ParentType,
    ContextType,
    RequireFields<PaymentsConfigQueryGfdConfigByEntityIdArgs, 'versionId'>
  >
  gfdConfigs?: Resolver<
    Array<ResolversTypes['GfdConfig']>,
    ParentType,
    ContextType,
    RequireFields<
      PaymentsConfigQueryGfdConfigsArgs,
      'restaurantId' | 'selectedRestaurantSetIds'
    >
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PayrollEmployeeInfoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PayrollEmployeeInfo'] = ResolversParentTypes['PayrollEmployeeInfo']
> = {
  employeeNumber?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  employmentStatus?: Resolver<
    ResolversTypes['AllEmploymentStatuses'],
    ParentType,
    ContextType
  >
  payrollEmployeeId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PendingEmploymentStatusResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PendingEmploymentStatus'] = ResolversParentTypes['PendingEmploymentStatus']
> = {
  adjustedHireDate?: Resolver<
    Maybe<ResolversTypes['Date']>,
    ParentType,
    ContextType
  >
  hireDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PercentageMetricResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PercentageMetric'] = ResolversParentTypes['PercentageMetric']
> = {
  comparison?: Resolver<
    Maybe<ResolversTypes['Decimal']>,
    ParentType,
    ContextType
  >
  current?: Resolver<Maybe<ResolversTypes['Decimal']>, ParentType, ContextType>
  endTime?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  startTime?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  variation?: Resolver<
    Maybe<ResolversTypes['Comparison']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export interface PermissionBitScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['PermissionBit'], any> {
  name: 'PermissionBit'
}

export type PermissionsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Permissions'] = ResolversParentTypes['Permissions']
> = {
  accountAdmin?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  adjustCashDrawerStartBalance?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  advancedTerminalSetup?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  alternatePaymentTypes?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  applyCashPayments?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  blindCashDrawers?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  bookingApp?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  bookingSetup?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  bulkTransferChecks?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  cancelDispatch?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  cashCloseoutOverMaxDiscrepancy?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  cashDrawerAccess?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  cashDrawerLockdown?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  cashDrawers?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  changeServer?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  changeTable?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  checkAudit?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  closeMultiplePaidChecks?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  closeOutDay?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  completeDelivery?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  crmCustomer?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  dataExportConfig?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  deliveryAccess?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  deliveryMode?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  deviceSetup?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  discounts?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  discountsSetup?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  dispatchDriver?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  editHistoricalData?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  editOtherUsersOrders?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  editSentItems?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  editTimeEntries?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  employeeInfo?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  employeeJobsAndWages?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  endBreaksEarly?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  financialAccounts?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  findChecks?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  fullMenuEdit?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  houseAccount?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  instantDeposit?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  kdsSetup?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  keyInCreditCards?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  kitchenAndDiningRoomSetup?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  laborReporting?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  localMenuEdit?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  logBook?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  manageIntegrations?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  manager?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  marketingInfo?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  menuAuditAndCleanup?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  menuReporting?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  myReports?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  negativeDeclaredTips?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  noSale?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  offlineBackgroundCreditCardProcessing?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  openItems?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  orderReadyBoardAccess?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  payOuts?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  paymentTerminalMode?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  paymentsSetup?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  pendingOrdersMode?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  pos?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  publishing?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  quickEdit?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  quickEditButtonAddExisting?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  quickEditButtonAddNew?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  quickEditButtonColour?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  quickEditButtonRearrange?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  quickEditButtonRemove?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  quickEditCategory?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  quickEditInventoryQuantity?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  quickEditName?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  quickEditPosName?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  quickEditPrice?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  quickEditSku?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  quickOrderMode?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  refunds?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  refundsUnlinked?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  registerSwipeCard?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  restaurantAdmin?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  restaurantCard?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  restaurantGroupsSetup?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  restaurantOperationsSetup?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  salesReporting?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  scheduledPublishing?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  sendNotifications?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  shiftReview?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  shiftReviewSalesData?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  shopAccess?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  svcCharges?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  tableServiceMode?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  tables?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  taxExempt?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  taxRatesSetup?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  terminalSetup?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  throttleOnlineOrders?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  ticketDisplayScreenMode?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  toastCardAddPoints?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  toastCardAddValue?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  toastCardReporting?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  togglePosExperience?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  updateAllDeliveryOrders?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  updateDriver?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  userPermissions?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  viewAllChecks?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  voidMultipleOpenChecks?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  voids?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  wasteTrackingMode?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  webSetup?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  websitesSetup?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PlaceThirdPartyMockOrderResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlaceThirdPartyMockOrderResponse'] = ResolversParentTypes['PlaceThirdPartyMockOrderResponse']
> = {
  body?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PnLaiSummaryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PnLAISummary'] = ResolversParentTypes['PnLAISummary']
> = {
  summary?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PnLLineItemMetricsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PnLLineItemMetrics'] = ResolversParentTypes['PnLLineItemMetrics']
> = {
  amountChange?: Resolver<
    Maybe<ResolversTypes['Money']>,
    ParentType,
    ContextType
  >
  inflow?: Resolver<Maybe<ResolversTypes['Money']>, ParentType, ContextType>
  inflowComparisonPeriod?: Resolver<
    Maybe<ResolversTypes['Money']>,
    ParentType,
    ContextType
  >
  outflow?: Resolver<Maybe<ResolversTypes['Money']>, ParentType, ContextType>
  outflowComparisonPeriod?: Resolver<
    Maybe<ResolversTypes['Money']>,
    ParentType,
    ContextType
  >
  percentChange?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >
  percentOfSales?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >
  percentOfSalesComparisonPeriod?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >
  total?: Resolver<Maybe<ResolversTypes['Money']>, ParentType, ContextType>
  totalComparisonPeriod?: Resolver<
    Maybe<ResolversTypes['Money']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PnLNetProfitResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PnLNetProfit'] = ResolversParentTypes['PnLNetProfit']
> = {
  controllableProfit?: Resolver<
    ResolversTypes['PnLSubProfit'],
    ParentType,
    ContextType
  >
  metrics?: Resolver<
    ResolversTypes['PnLLineItemMetrics'],
    ParentType,
    ContextType
  >
  nonControllableProfit?: Resolver<
    ResolversTypes['PnLSubProfit'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PnLReportResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PnLReport'] = ResolversParentTypes['PnLReport']
> = {
  netProfit?: Resolver<ResolversTypes['PnLNetProfit'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PnLSubCategoryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PnLSubCategory'] = ResolversParentTypes['PnLSubCategory']
> = {
  category?: Resolver<
    ResolversTypes['CashFlowTransactionCategory'],
    ParentType,
    ContextType
  >
  metrics?: Resolver<
    ResolversTypes['PnLLineItemMetrics'],
    ParentType,
    ContextType
  >
  subCategoryDetails?: Resolver<
    Array<ResolversTypes['PnLSubCategoryDetail']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PnLSubCategoryDetailResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PnLSubCategoryDetail'] = ResolversParentTypes['PnLSubCategoryDetail']
> = {
  metrics?: Resolver<
    ResolversTypes['PnLLineItemMetrics'],
    ParentType,
    ContextType
  >
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PnLSubProfitResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PnLSubProfit'] = ResolversParentTypes['PnLSubProfit']
> = {
  subProfit?: Resolver<
    ResolversTypes['PnLLineItemMetrics'],
    ParentType,
    ContextType
  >
  subProfitTopLevels?: Resolver<
    Array<ResolversTypes['PnLTopLevel']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PnLTopLevelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PnLTopLevel'] = ResolversParentTypes['PnLTopLevel']
> = {
  category?: Resolver<
    ResolversTypes['PnLTopLevelCategory'],
    ParentType,
    ContextType
  >
  metrics?: Resolver<
    ResolversTypes['PnLLineItemMetrics'],
    ParentType,
    ContextType
  >
  subCategories?: Resolver<
    Array<ResolversTypes['PnLSubCategory']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PopularSearchResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PopularSearch'] = ResolversParentTypes['PopularSearch']
> = {
  docCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PopularSearchResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PopularSearchResponse'] = ResolversParentTypes['PopularSearchResponse']
> = {
  items?: Resolver<
    Array<ResolversTypes['PopularSearch']>,
    ParentType,
    ContextType
  >
  totalHits?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PosEmployeeInfoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PosEmployeeInfo'] = ResolversParentTypes['PosEmployeeInfo']
> = {
  archived?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  restaurantLocation?: Resolver<
    Maybe<ResolversTypes['SavedRestaurantLocation']>,
    ParentType,
    ContextType
  >
  restaurantUserGuid?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PrequalifiedOfferForCustomerResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PrequalifiedOfferForCustomer'] = ResolversParentTypes['PrequalifiedOfferForCustomer']
> = {
  ccHoldbackRate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  offerMetadata?: Resolver<
    ResolversTypes['PrequalifiedOfferMetadata'],
    ParentType,
    ContextType
  >
  originationAmount?: Resolver<ResolversTypes['Money'], ParentType, ContextType>
  refinanceEligibleLoans?: Resolver<
    Array<ResolversTypes['ID']>,
    ParentType,
    ContextType
  >
  termInDays?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  totalExpectedRepayment?: Resolver<
    ResolversTypes['Money'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PrequalifiedOfferMetadataResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PrequalifiedOfferMetadata'] = ResolversParentTypes['PrequalifiedOfferMetadata']
> = {
  appliedFactorDiscountPercent?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >
  appliedRefinanceFactorDiscountPercent?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >
  boostedOfferDiscount?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >
  boostedOfferPendingProgress?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >
  boostedOfferStatus?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  gmvLoanSizeIncreaseAmount?: Resolver<
    Maybe<ResolversTypes['Money']>,
    ParentType,
    ContextType
  >
  increaseInOriginationAmount?: Resolver<
    Maybe<ResolversTypes['Money']>,
    ParentType,
    ContextType
  >
  increaseInOriginationAmountBaselineDate?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  isInMultiLoanTest?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  isMinimumPaymentEligible?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  minOriginationAmount?: Resolver<
    ResolversTypes['Money'],
    ParentType,
    ContextType
  >
  multiLoanOutstandingPrincipalBalance?: Resolver<
    ResolversTypes['Money'],
    ParentType,
    ContextType
  >
  preapprovedApplicationExpirationDate?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  preapprovedApplicationId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  preapprovedApplicationVersion?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >
  priceDropBaselineDate?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  priceDropPercent?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >
  promotionExpirationDateTime?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  requireCodatConnection?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  timeOnPlatformCategory?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PriceLevelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PriceLevel'] = ResolversParentTypes['PriceLevel']
> = {
  multiLocationId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  pricingStrategy?: Resolver<
    ResolversTypes['MenuItemPricingStrategy'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PriceLevelEntityResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PriceLevelEntity'] = ResolversParentTypes['PriceLevelEntity']
> = {
  __resolveReference?: ReferenceResolver<
    Maybe<ResolversTypes['PriceLevelEntity']>,
    { __typename: 'PriceLevelEntity' } & GraphQLRecursivePick<
      ParentType,
      { id: true }
    >,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  multiLocationId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  owner?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  ownerRestaurantSet?: Resolver<
    Maybe<ResolversTypes['RestaurantSet']>,
    ParentType,
    ContextType
  >
  pricing?: Resolver<
    Maybe<ResolversTypes['MenuItemPricing']>,
    ParentType,
    ContextType
  >
  target?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  targetRestaurantSet?: Resolver<
    ResolversTypes['RestaurantSet'],
    ParentType,
    ContextType
  >
  versionId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PrinterResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Printer'] = ResolversParentTypes['Printer']
> = {
  deviceDetails?: Resolver<
    Maybe<ResolversTypes['DeviceDetails']>,
    ParentType,
    ContextType
  >
  deviceType?: Resolver<ResolversTypes['DeviceType'], ParentType, ContextType>
  firmwareVersion?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  lastUpdate?: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >
  maxPrintWidth?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  model?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  modelName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  networks?: Resolver<
    Maybe<Array<ResolversTypes['DeviceNetworkDetails']>>,
    ParentType,
    ContextType
  >
  photo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  printerConfig?: Resolver<
    Maybe<ResolversTypes['PublishedPrinterConfig']>,
    ParentType,
    ContextType
  >
  restaurant?: Resolver<
    ResolversTypes['SavedRestaurantLocation'],
    ParentType,
    ContextType
  >
  serial?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ProcessLegacyJobResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProcessLegacyJob'] = ResolversParentTypes['ProcessLegacyJob']
> = {
  processed?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  status?: Resolver<ResolversTypes['BackfillStatus'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ProcessLegacyJobResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProcessLegacyJobResponse'] = ResolversParentTypes['ProcessLegacyJobResponse']
> = {
  __resolveType: TypeResolveFn<
    | 'InvalidCustomerUuidError'
    | 'InvalidRestaurantIDError'
    | 'JobError'
    | 'ProcessLegacyJob',
    ParentType,
    ContextType
  >
}

export type ProductSubscriptionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProductSubscription'] = ResolversParentTypes['ProductSubscription']
> = {
  billingFrequency?: Resolver<
    ResolversTypes['ProductSubscriptionBillingFrequency'],
    ParentType,
    ContextType
  >
  billingType?: Resolver<
    ResolversTypes['ProductSubscriptionBillingType'],
    ParentType,
    ContextType
  >
  contractId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  contractLength?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >
  customerPrice?: Resolver<ResolversTypes['Money'], ParentType, ContextType>
  endDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>
  listPrice?: Resolver<ResolversTypes['Money'], ParentType, ContextType>
  nextBillDate?: Resolver<
    Maybe<ResolversTypes['Date']>,
    ParentType,
    ContextType
  >
  nextSubscriptionDate?: Resolver<
    Maybe<ResolversTypes['Date']>,
    ParentType,
    ContextType
  >
  previousPurchases?: Resolver<
    Array<ResolversTypes['ProductSubscription']>,
    ParentType,
    ContextType
  >
  productDescription?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  productId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  productName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  productType?: Resolver<
    ResolversTypes['ProductSubscriptionProductType'],
    ParentType,
    ContextType
  >
  quantity?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  startDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>
  subscriptionSalesforceId?: Resolver<
    ResolversTypes['ID'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ProductSubscriptionBundleResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProductSubscriptionBundle'] = ResolversParentTypes['ProductSubscriptionBundle']
> = {
  billingFrequency?: Resolver<
    ResolversTypes['ProductSubscriptionBillingFrequency'],
    ParentType,
    ContextType
  >
  billingType?: Resolver<
    ResolversTypes['ProductSubscriptionBillingType'],
    ParentType,
    ContextType
  >
  bundledProducts?: Resolver<
    Array<ResolversTypes['ProductSubscriptionSalesforceProduct']>,
    ParentType,
    ContextType
  >
  contractId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  contractLength?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >
  customerPrice?: Resolver<ResolversTypes['Money'], ParentType, ContextType>
  endDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>
  listPrice?: Resolver<ResolversTypes['Money'], ParentType, ContextType>
  nextBillDate?: Resolver<
    Maybe<ResolversTypes['Date']>,
    ParentType,
    ContextType
  >
  nextSubscriptionDate?: Resolver<
    Maybe<ResolversTypes['Date']>,
    ParentType,
    ContextType
  >
  previousPurchases?: Resolver<
    Array<ResolversTypes['ProductSubscriptionBundle']>,
    ParentType,
    ContextType
  >
  productDescription?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  productId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  productName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  productType?: Resolver<
    ResolversTypes['ProductSubscriptionProductType'],
    ParentType,
    ContextType
  >
  quantity?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  startDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>
  subscriptionSalesforceId?: Resolver<
    ResolversTypes['ID'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ProductSubscriptionInterfaceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProductSubscriptionInterface'] = ResolversParentTypes['ProductSubscriptionInterface']
> = {
  __resolveType: TypeResolveFn<
    'ProductSubscription' | 'ProductSubscriptionBundle',
    ParentType,
    ContextType
  >
  billingFrequency?: Resolver<
    ResolversTypes['ProductSubscriptionBillingFrequency'],
    ParentType,
    ContextType
  >
  billingType?: Resolver<
    ResolversTypes['ProductSubscriptionBillingType'],
    ParentType,
    ContextType
  >
  contractId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  contractLength?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >
  customerPrice?: Resolver<ResolversTypes['Money'], ParentType, ContextType>
  endDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>
  listPrice?: Resolver<ResolversTypes['Money'], ParentType, ContextType>
  nextBillDate?: Resolver<
    Maybe<ResolversTypes['Date']>,
    ParentType,
    ContextType
  >
  nextSubscriptionDate?: Resolver<
    Maybe<ResolversTypes['Date']>,
    ParentType,
    ContextType
  >
  previousPurchases?: Resolver<
    Array<ResolversTypes['ProductSubscriptionInterface']>,
    ParentType,
    ContextType
  >
  productDescription?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  productId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  productName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  productType?: Resolver<
    ResolversTypes['ProductSubscriptionProductType'],
    ParentType,
    ContextType
  >
  quantity?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  startDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>
  subscriptionSalesforceId?: Resolver<
    ResolversTypes['ID'],
    ParentType,
    ContextType
  >
}

export type ProductSubscriptionSalesforceProductResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProductSubscriptionSalesforceProduct'] = ResolversParentTypes['ProductSubscriptionSalesforceProduct']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  productDescription?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ProductSubscriptionsMutationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProductSubscriptionsMutation'] = ResolversParentTypes['ProductSubscriptionsMutation']
> = {
  createCancellationRequest?: Resolver<
    Array<ResolversTypes['SubscriptionsCancellationRequest']>,
    ParentType,
    ContextType,
    RequireFields<
      ProductSubscriptionsMutationCreateCancellationRequestArgs,
      'request'
    >
  >
  updateSubscriptionAutoRenewal?: Resolver<
    ResolversTypes['SubscriptionsCancellationRequest'],
    ParentType,
    ContextType,
    RequireFields<
      ProductSubscriptionsMutationUpdateSubscriptionAutoRenewalArgs,
      'request'
    >
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ProductSubscriptionsQueryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProductSubscriptionsQuery'] = ResolversParentTypes['ProductSubscriptionsQuery']
> = {
  cancellationRequestsList?: Resolver<
    Array<ResolversTypes['SubscriptionsCancellationRequest']>,
    ParentType,
    ContextType,
    RequireFields<
      ProductSubscriptionsQueryCancellationRequestsListArgs,
      'filter'
    >
  >
  list?: Resolver<
    Array<ResolversTypes['ProductSubscriptionInterface']>,
    ParentType,
    ContextType
  >
  location?: Resolver<
    Maybe<ResolversTypes['SavedRestaurantLocation']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PublishJobResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PublishJob'] = ResolversParentTypes['PublishJob']
> = {
  author?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>
  date?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  entities?: Resolver<
    ResolversTypes['PublishedConfigConnection'],
    ParentType,
    ContextType,
    RequireFields<PublishJobEntitiesArgs, 'pagination'>
  >
  entityInformation?: Resolver<
    Array<ResolversTypes['PublishJobEntityInfo']>,
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  restaurant?: Resolver<
    ResolversTypes['SavedRestaurantLocation'],
    ParentType,
    ContextType
  >
  source?: Resolver<ResolversTypes['PublishJobSource'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['PublishJobType'], ParentType, ContextType>
  updateCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PublishJobConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PublishJobConnection'] = ResolversParentTypes['PublishJobConnection']
> = {
  edges?: Resolver<
    Array<ResolversTypes['PublishJobEdge']>,
    ParentType,
    ContextType
  >
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PublishJobEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PublishJobEdge'] = ResolversParentTypes['PublishJobEdge']
> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  node?: Resolver<ResolversTypes['PublishJob'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PublishJobEntityInfoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PublishJobEntityInfo'] = ResolversParentTypes['PublishJobEntityInfo']
> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  entityType?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PublishMutationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PublishMutation'] = ResolversParentTypes['PublishMutation']
> = {
  fullPublishRestaurants?: Resolver<
    ResolversTypes['PublishStatus'],
    ParentType,
    ContextType,
    RequireFields<PublishMutationFullPublishRestaurantsArgs, 'input'>
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PublishQueryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PublishQuery'] = ResolversParentTypes['PublishQuery']
> = {
  checkUnpublishedChanges?: Resolver<
    ResolversTypes['UnpublishedStatusConnection'],
    ParentType,
    ContextType,
    RequireFields<PublishQueryCheckUnpublishedChangesArgs, 'pagination'>
  >
  publishJob?: Resolver<
    ResolversTypes['PublishJob'],
    ParentType,
    ContextType,
    RequireFields<PublishQueryPublishJobArgs, 'id'>
  >
  publishJobs?: Resolver<
    ResolversTypes['PublishJobConnection'],
    ParentType,
    ContextType,
    RequireFields<PublishQueryPublishJobsArgs, 'filter' | 'pagination'>
  >
  publishedConfig?: Resolver<
    ResolversTypes['PublishedConfig'],
    ParentType,
    ContextType,
    RequireFields<
      PublishQueryPublishedConfigArgs,
      'entityType' | 'publishJobId'
    >
  >
  publishedConfigDiff?: Resolver<
    Maybe<ResolversTypes['PublishedConfigDiff']>,
    ParentType,
    ContextType,
    RequireFields<
      PublishQueryPublishedConfigDiffArgs,
      'currentPublishJobId' | 'entityType'
    >
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PublishRepositoryDependencyFailureResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PublishRepositoryDependencyFailure'] = ResolversParentTypes['PublishRepositoryDependencyFailure']
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PublishRestaurantStatusResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PublishRestaurantStatus'] = ResolversParentTypes['PublishRestaurantStatus']
> = {
  restaurantId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PublishStatusResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PublishStatus'] = ResolversParentTypes['PublishStatus']
> = {
  originID?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  publishType?: Resolver<
    ResolversTypes['PublishJobType'],
    ParentType,
    ContextType
  >
  restaurants?: Resolver<
    Array<ResolversTypes['PublishRestaurantStatus']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PublishedChannelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PublishedChannel'] = ResolversParentTypes['PublishedChannel']
> = {
  __resolveReference?: ReferenceResolver<
    Maybe<ResolversTypes['PublishedChannel']>,
    { __typename: 'PublishedChannel' } & GraphQLRecursivePick<
      ParentType,
      { id: true }
    >,
    ContextType
  >
  channelAvailability?: Resolver<
    Maybe<ResolversTypes['PublishedChannelAvailability']>,
    ParentType,
    ContextType
  >
  channelType?: Resolver<
    Maybe<ResolversTypes['ChannelType']>,
    ParentType,
    ContextType
  >
  channelTypeId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PublishedChannelAvailabilityResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PublishedChannelAvailability'] = ResolversParentTypes['PublishedChannelAvailability']
> = {
  enabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  multiLocationId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  snoozedUntil?: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PublishedConfigResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PublishedConfig'] = ResolversParentTypes['PublishedConfig']
> = {
  action?: Resolver<Maybe<ResolversTypes['Action']>, ParentType, ContextType>
  entityType?: Resolver<
    Maybe<ResolversTypes['ConfigType']>,
    ParentType,
    ContextType
  >
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  lastModifiedBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType
  >
  lastPublishJob?: Resolver<
    Maybe<ResolversTypes['PublishJob']>,
    ParentType,
    ContextType
  >
  lastSavedTime?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  managementSet?: Resolver<
    Maybe<ResolversTypes['ManagementGroup']>,
    ParentType,
    ContextType
  >
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  publishJob?: Resolver<
    Maybe<ResolversTypes['PublishJob']>,
    ParentType,
    ContextType
  >
  restaurant?: Resolver<
    Maybe<ResolversTypes['SavedRestaurantLocation']>,
    ParentType,
    ContextType
  >
  targets?: Resolver<
    Array<ResolversTypes['RestaurantSet']>,
    ParentType,
    ContextType
  >
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PublishedConfigConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PublishedConfigConnection'] = ResolversParentTypes['PublishedConfigConnection']
> = {
  edges?: Resolver<
    Array<ResolversTypes['PublishedConfigEdge']>,
    ParentType,
    ContextType
  >
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PublishedConfigDiffResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PublishedConfigDiff'] = ResolversParentTypes['PublishedConfigDiff']
> = {
  differences?: Resolver<
    Array<ResolversTypes['PublishedConfigFieldDiff']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PublishedConfigEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PublishedConfigEdge'] = ResolversParentTypes['PublishedConfigEdge']
> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  node?: Resolver<ResolversTypes['PublishedConfig'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PublishedConfigEntityIResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PublishedConfigEntityI'] = ResolversParentTypes['PublishedConfigEntityI']
> = {
  __resolveType: TypeResolveFn<
    'PublishedDataExtensionSet',
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  managementGroup?: Resolver<
    ResolversTypes['ManagementGroup'],
    ParentType,
    ContextType
  >
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  restaurantLocation?: Resolver<
    ResolversTypes['PublishedRestaurantLocation'],
    ParentType,
    ContextType
  >
}

export type PublishedConfigFieldDiffResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PublishedConfigFieldDiff'] = ResolversParentTypes['PublishedConfigFieldDiff']
> = {
  diff?: Resolver<ResolversTypes['ConfigValueDiff'], ParentType, ContextType>
  field?: Resolver<ResolversTypes['ConfigField'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PublishedDataExtensionSetResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PublishedDataExtensionSet'] = ResolversParentTypes['PublishedDataExtensionSet']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  managementGroup?: Resolver<
    ResolversTypes['ManagementGroup'],
    ParentType,
    ContextType
  >
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  payments?: Resolver<
    Array<ResolversTypes['PaymentDataExtension']>,
    ParentType,
    ContextType
  >
  restaurantLocation?: Resolver<
    ResolversTypes['PublishedRestaurantLocation'],
    ParentType,
    ContextType
  >
  revenueCenters?: Resolver<
    Array<ResolversTypes['RevenueCenterDataExtension']>,
    ParentType,
    ContextType
  >
  salesCategories?: Resolver<
    Array<ResolversTypes['SalesCategoryDataExtension']>,
    ParentType,
    ContextType
  >
  services?: Resolver<
    Array<ResolversTypes['ServiceDataExtension']>,
    ParentType,
    ContextType
  >
  taxRates?: Resolver<
    Array<ResolversTypes['TaxRateDataExtension']>,
    ParentType,
    ContextType
  >
  type?: Resolver<
    ResolversTypes['DataExtensionSetType'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PublishedGiftCardReceiptConfigResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PublishedGiftCardReceiptConfig'] = ResolversParentTypes['PublishedGiftCardReceiptConfig']
> = {
  balanceInquiryConfig?: Resolver<
    ResolversTypes['GiftReceiptPrintStrategy'],
    ParentType,
    ContextType
  >
  giftActivationConfig?: Resolver<
    ResolversTypes['GiftReceiptPrintStrategy'],
    ParentType,
    ContextType
  >
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  promoArea?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  restaurantLocation?: Resolver<
    ResolversTypes['PublishedRestaurantLocation'],
    ParentType,
    ContextType
  >
  revision?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PublishedItemFeedbackConfigResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PublishedItemFeedbackConfig'] = ResolversParentTypes['PublishedItemFeedbackConfig']
> = {
  itemFeedbackEnabled?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  notificationContacts?: Resolver<
    Array<Maybe<ResolversTypes['NotificationContact']>>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PublishedItemFeedbackConfigErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PublishedItemFeedbackConfigError'] = ResolversParentTypes['PublishedItemFeedbackConfigError']
> = {
  code?: Resolver<
    ResolversTypes['PublishedItemFeedbackConfigErrorCode'],
    ParentType,
    ContextType
  >
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PublishedItemFeedbackConfigResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PublishedItemFeedbackConfigResponse'] = ResolversParentTypes['PublishedItemFeedbackConfigResponse']
> = {
  __resolveType: TypeResolveFn<
    'PublishedItemFeedbackConfig' | 'PublishedItemFeedbackConfigError',
    ParentType,
    ContextType
  >
}

export type PublishedMenuResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PublishedMenu'] = ResolversParentTypes['PublishedMenu']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PublishedMenuEntityResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PublishedMenuEntity'] = ResolversParentTypes['PublishedMenuEntity']
> = {
  __resolveType: TypeResolveFn<
    'PublishedMenu' | 'PublishedMenuGroup' | 'PublishedMenuItem',
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
}

export type PublishedMenuGroupResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PublishedMenuGroup'] = ResolversParentTypes['PublishedMenuGroup']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PublishedMenuItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PublishedMenuItem'] = ResolversParentTypes['PublishedMenuItem']
> = {
  __resolveReference?: ReferenceResolver<
    Maybe<ResolversTypes['PublishedMenuItem']>,
    { __typename: 'PublishedMenuItem' } & GraphQLRecursivePick<
      ParentType,
      { id: true }
    >,
    ContextType
  >
  ancestors?: Resolver<
    Array<ResolversTypes['PublishedMenuItemAncestor']>,
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  modifierOnly?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  optionOnly?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  stock?: Resolver<ResolversTypes['Stock'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PublishedMenuItemAncestorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PublishedMenuItemAncestor'] = ResolversParentTypes['PublishedMenuItemAncestor']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  paths?: Resolver<
    ResolversTypes['PublishedMenuItemAncestorPath'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PublishedMenuItemAncestorPathResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PublishedMenuItemAncestorPath'] = ResolversParentTypes['PublishedMenuItemAncestorPath']
> = {
  ancestors?: Resolver<
    Array<ResolversTypes['PublishedMenuEntity']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PublishedPrinterConfigResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PublishedPrinterConfig'] = ResolversParentTypes['PublishedPrinterConfig']
> = {
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  ipAddress?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  modelName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  multiLocationId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  paperWidth?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  prepStations?: Resolver<
    Maybe<Array<ResolversTypes['DeviceInfoPublishedPrepStation']>>,
    ParentType,
    ContextType
  >
  serial?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PublishedQueriesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PublishedQueries'] = ResolversParentTypes['PublishedQueries']
> = {
  restaurantLocation?: Resolver<
    Maybe<ResolversTypes['PublishedRestaurantLocation']>,
    ParentType,
    ContextType,
    RequireFields<PublishedQueriesRestaurantLocationArgs, 'id'>
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PublishedRestaurantLocationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PublishedRestaurantLocation'] = ResolversParentTypes['PublishedRestaurantLocation']
> = {
  __resolveReference?: ReferenceResolver<
    Maybe<ResolversTypes['PublishedRestaurantLocation']>,
    { __typename: 'PublishedRestaurantLocation' } & GraphQLRecursivePick<
      ParentType,
      { id: true }
    >,
    ContextType
  >
  address?: Resolver<
    ResolversTypes['RestaurantLocationAddress'],
    ParentType,
    ContextType
  >
  closeoutHour?: Resolver<ResolversTypes['LocalTime'], ParentType, ContextType>
  currencyCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  description?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  images?: Resolver<
    ResolversTypes['RestaurantLocationImages'],
    ParentType,
    ContextType
  >
  isTestMode?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  language?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  locationCode?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  locationName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  operatingSchedule?: Resolver<
    ResolversTypes['OperatingSchedule'],
    ParentType,
    ContextType
  >
  phone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  restaurantName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  status?: Resolver<
    ResolversTypes['RestaurantLocationStatus'],
    ParentType,
    ContextType
  >
  timeZone?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PublishedToastPayConfigErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PublishedToastPayConfigError'] = ResolversParentTypes['PublishedToastPayConfigError']
> = {
  code?: Resolver<
    ResolversTypes['PublishedToastPayConfigErrorCode'],
    ParentType,
    ContextType
  >
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PublishedToastPayConfigResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PublishedToastPayConfigResponse'] = ResolversParentTypes['PublishedToastPayConfigResponse']
> = {
  __resolveType: TypeResolveFn<
    'PublishedToastPayConfigError' | 'ToastPayConfig',
    ParentType,
    ContextType
  >
}

export type QlOrderConfirmationScreenConfigResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['QLOrderConfirmationScreenConfig'] = ResolversParentTypes['QLOrderConfirmationScreenConfig']
> = {
  backgroundColor?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  fontPrimaryColor?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  fontSecondaryColor?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  fontSize?: Resolver<
    Maybe<ResolversTypes['OrderConfirmationScreenFontSize']>,
    ParentType,
    ContextType
  >
  fontUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  idleBackgroundImageDurationMs?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >
  idleBackgroundImageUrls?: Resolver<
    Maybe<Array<ResolversTypes['String']>>,
    ParentType,
    ContextType
  >
  orderInProgressBackgroundImageUrl?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  orderInProgressForegroundColor?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  thankYouMessage?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  thankYouTitle?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type QlStockDataConfigResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['QLStockDataConfig'] = ResolversParentTypes['QLStockDataConfig']
> = {
  stockdataEnabled?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type QlStockOutConfigResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['QLStockOutConfig'] = ResolversParentTypes['QLStockOutConfig']
> = {
  stockImageUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  stockoutEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type QueryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']
> = {
  accessibleRestaurantSets?: Resolver<
    Maybe<Array<ResolversTypes['AccessibleRestaurantSet']>>,
    ParentType,
    ContextType,
    RequireFields<QueryAccessibleRestaurantSetsArgs, 'input'>
  >
  allGroups?: Resolver<
    Array<ResolversTypes['RestaurantGroup']>,
    ParentType,
    ContextType,
    RequireFields<QueryAllGroupsArgs, 'filter'>
  >
  allLocations?: Resolver<
    Array<ResolversTypes['RestaurantLocation']>,
    ParentType,
    ContextType
  >
  allPermissionsForUser?: Resolver<
    Maybe<Array<ResolversTypes['RestaurantSet']>>,
    ParentType,
    ContextType
  >
  auditSavedMenusByChannel?: Resolver<
    Array<ResolversTypes['SavedMenuAuditSetting']>,
    ParentType,
    ContextType,
    RequireFields<
      QueryAuditSavedMenusByChannelArgs,
      'channelMultiLocationId' | 'settings'
    >
  >
  authConfiguration?: Resolver<
    ResolversTypes['AuthConfigurationQuery'],
    ParentType,
    ContextType
  >
  autoComplete?: Resolver<
    ResolversTypes['SearchResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryAutoCompleteArgs, 'q' | 'size' | 'sources'>
  >
  booking?: Resolver<ResolversTypes['BookingQuery'], ParentType, ContextType>
  capital?: Resolver<ResolversTypes['CapitalQuery'], ParentType, ContextType>
  capitalAllLocations?: Resolver<
    Array<ResolversTypes['SavedRestaurantLocation']>,
    ParentType,
    ContextType
  >
  cashDrawerConfig?: Resolver<
    ResolversTypes['CashDrawerConfig'],
    ParentType,
    ContextType
  >
  cashDrawers?: Resolver<
    Array<ResolversTypes['CashDrawer']>,
    ParentType,
    ContextType
  >
  categories?: Resolver<
    Array<ResolversTypes['Category']>,
    ParentType,
    ContextType,
    RequireFields<QueryCategoriesArgs, 'cacheKey' | 'restaurantGuid'>
  >
  channel?: Resolver<
    Maybe<ResolversTypes['ChannelQuery']>,
    ParentType,
    ContextType
  >
  channelMenuEntities?: Resolver<
    Array<ResolversTypes['ChannelMenuEntity']>,
    ParentType,
    ContextType,
    RequireFields<
      QueryChannelMenuEntitiesArgs,
      'channelMultiLocationId' | 'includeArchived'
    >
  >
  channelMenuEntitiesByMenu?: Resolver<
    Array<ResolversTypes['ChannelMenuEntity']>,
    ParentType,
    ContextType,
    RequireFields<
      QueryChannelMenuEntitiesByMenuArgs,
      'includeAllInManagementSet' | 'includeArchived' | 'menuMultiLocationId'
    >
  >
  channelMenuPriceAdjustment?: Resolver<
    Maybe<ResolversTypes['ChannelMenuPriceAdjustment']>,
    ParentType,
    ContextType,
    RequireFields<QueryChannelMenuPriceAdjustmentArgs, 'channelMultiLocationId'>
  >
  chat?: Resolver<
    Maybe<ResolversTypes['ChatResponse']>,
    ParentType,
    ContextType,
    RequireFields<QueryChatArgs, 'messages' | 'sessionId'>
  >
  closeOutReportingConfig?: Resolver<
    ResolversTypes['CloseOutReportingConfig'],
    ParentType,
    ContextType
  >
  closeOutReportingContent?: Resolver<
    ResolversTypes['CloseOutReportingContent'],
    ParentType,
    ContextType
  >
  configCopy?: Resolver<
    ResolversTypes['ConfigCopyQuery'],
    ParentType,
    ContextType
  >
  configs?: Resolver<ResolversTypes['ConfigQuery'], ParentType, ContextType>
  customerBilling?: Resolver<
    Maybe<ResolversTypes['CustomerBillingQuery']>,
    ParentType,
    ContextType
  >
  customerInvoices?: Resolver<
    ResolversTypes['CustomerInvoiceQuery'],
    ParentType,
    ContextType
  >
  customerTasks?: Resolver<
    ResolversTypes['CustomerTasksQuery'],
    ParentType,
    ContextType
  >
  customerUserAuthorization?: Resolver<
    Maybe<ResolversTypes['CustomerUserAuthorizationQuery']>,
    ParentType,
    ContextType
  >
  dataExtensions?: Resolver<
    ResolversTypes['DataExtensionQuery'],
    ParentType,
    ContextType
  >
  dateRangeMetrics?: Resolver<
    ResolversTypes['DateRangeMetrics'],
    ParentType,
    ContextType,
    RequireFields<
      QueryDateRangeMetricsArgs,
      'compareTo' | 'endOfRange' | 'restaurantGuid' | 'startOfRange'
    >
  >
  deviceHub?: Resolver<ResolversTypes['DeviceQueries'], ParentType, ContextType>
  driveThru?: Resolver<
    ResolversTypes['DriveThruQuery'],
    ParentType,
    ContextType
  >
  dsModel?: Resolver<Maybe<ResolversTypes['DSModel']>, ParentType, ContextType>
  dsModelHealthStatus?: Resolver<
    Maybe<ResolversTypes['DsModelHealthStatus']>,
    ParentType,
    ContextType
  >
  employee?: Resolver<
    ResolversTypes['Employee'],
    ParentType,
    ContextType,
    RequireFields<QueryEmployeeArgs, 'restaurantUserGuid'>
  >
  employeeById?: Resolver<
    Maybe<ResolversTypes['EmployeeV2']>,
    ParentType,
    ContextType,
    RequireFields<QueryEmployeeByIdArgs, 'id'>
  >
  employeeByRestaurantUserGuid?: Resolver<
    Maybe<ResolversTypes['EmployeeV2']>,
    ParentType,
    ContextType,
    RequireFields<QueryEmployeeByRestaurantUserGuidArgs, 'restaurantUserGuid'>
  >
  employees?: Resolver<
    Array<ResolversTypes['RestaurantUser']>,
    ParentType,
    ContextType
  >
  employeesByLocation?: Resolver<
    Array<ResolversTypes['Employee']>,
    ParentType,
    ContextType
  >
  employeesByRestaurants?: Resolver<
    Array<ResolversTypes['RestaurantUser']>,
    ParentType,
    ContextType,
    RequireFields<QueryEmployeesByRestaurantsArgs, 'restaurants'>
  >
  fetchCashFlowTransactions?: Resolver<
    ResolversTypes['FetchCashFlowTransactionsResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryFetchCashFlowTransactionsArgs, 'request'>
  >
  fetchLogbookCategories?: Resolver<
    Maybe<Array<ResolversTypes['LogbookCategory']>>,
    ParentType,
    ContextType,
    RequireFields<QueryFetchLogbookCategoriesArgs, 'managementSetId'>
  >
  fetchLogbookEntriesByBusinessDay?: Resolver<
    Maybe<Array<ResolversTypes['LogbookEntry']>>,
    ParentType,
    ContextType,
    RequireFields<
      QueryFetchLogbookEntriesByBusinessDayArgs,
      'businessDay' | 'locationID'
    >
  >
  fetchLogbookEntry?: Resolver<
    Maybe<ResolversTypes['LogbookEntry']>,
    ParentType,
    ContextType,
    RequireFields<QueryFetchLogbookEntryArgs, 'id' | 'locationID'>
  >
  financialInsights?: Resolver<
    ResolversTypes['FinancialInsightsQuery'],
    ParentType,
    ContextType
  >
  foodWaste?: Resolver<
    ResolversTypes['FoodWasteQuery'],
    ParentType,
    ContextType
  >
  generateIntercomUserHash?: Resolver<
    ResolversTypes['IntercomUserHashResult'],
    ParentType,
    ContextType,
    RequireFields<QueryGenerateIntercomUserHashArgs, 'clientType'>
  >
  getCashFlowAccounts?: Resolver<
    Array<ResolversTypes['CashFlowAccount']>,
    ParentType,
    ContextType
  >
  getCashFlowSummary?: Resolver<
    Maybe<ResolversTypes['CashFlowSummary']>,
    ParentType,
    ContextType
  >
  getDailyMetrics?: Resolver<
    Array<ResolversTypes['DailyMetrics']>,
    ParentType,
    ContextType,
    RequireFields<
      QueryGetDailyMetricsArgs,
      'businessDay' | 'compareTo' | 'cutoffTimestamp' | 'restaurantGuids'
    >
  >
  getParentMenuGroupAncestors?: Resolver<
    Array<ResolversTypes['EntityAncestorResponse']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetParentMenuGroupAncestorsArgs, 'query'>
  >
  getPnLReport?: Resolver<
    ResolversTypes['PnLReport'],
    ParentType,
    ContextType,
    RequireFields<QueryGetPnLReportArgs, 'request'>
  >
  getPnLReportAISummary?: Resolver<
    Maybe<ResolversTypes['PnLAISummary']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetPnLReportAiSummaryArgs, 'request'>
  >
  getRestaurantMetrics?: Resolver<
    ResolversTypes['RestaurantMetrics'],
    ParentType,
    ContextType,
    RequireFields<
      QueryGetRestaurantMetricsArgs,
      'businessDay' | 'compareTo' | 'restaurantGuid'
    >
  >
  getTopSellingItemMetrics?: Resolver<
    Maybe<ResolversTypes['TopSellingItemConnection']>,
    ParentType,
    ContextType,
    RequireFields<
      QueryGetTopSellingItemMetricsArgs,
      'after' | 'filter' | 'first'
    >
  >
  giftCardConfig?: Resolver<
    ResolversTypes['GiftCardQuery'],
    ParentType,
    ContextType
  >
  globalNavigationMenu?: Resolver<
    ResolversTypes['GlobalNavigationMenu'],
    ParentType,
    ContextType,
    RequireFields<QueryGlobalNavigationMenuArgs, 'cacheKey' | 'restaurantGuid'>
  >
  group?: Resolver<
    ResolversTypes['RestaurantGroup'],
    ParentType,
    ContextType,
    RequireFields<QueryGroupArgs, 'guid'>
  >
  guestFeedback?: Resolver<
    Maybe<ResolversTypes['GuestFeedbackQuery']>,
    ParentType,
    ContextType
  >
  guestQuoteTimes?: Resolver<
    Maybe<ResolversTypes['GuestQuoteTimes']>,
    ParentType,
    ContextType
  >
  idmUsers?: Resolver<
    Maybe<ResolversTypes['IdmUsersQuery']>,
    ParentType,
    ContextType
  >
  itemFeedbackConfig?: Resolver<
    ResolversTypes['ItemFeedbackConfigResponse'],
    ParentType,
    ContextType
  >
  jobV2?: Resolver<
    Maybe<ResolversTypes['JobResponse']>,
    ParentType,
    ContextType,
    RequireFields<QueryJobV2Args, 'jobRequest'>
  >
  jobs?: Resolver<Array<ResolversTypes['Job']>, ParentType, ContextType>
  jobsByRestaurants?: Resolver<
    Array<ResolversTypes['Job']>,
    ParentType,
    ContextType,
    RequireFields<QueryJobsByRestaurantsArgs, 'restaurants'>
  >
  jobsV2?: Resolver<
    Maybe<ResolversTypes['JobsResponse']>,
    ParentType,
    ContextType,
    Partial<QueryJobsV2Args>
  >
  jobsV2Batch?: Resolver<
    Maybe<ResolversTypes['JobsBatchResponse']>,
    ParentType,
    ContextType,
    RequireFields<QueryJobsV2BatchArgs, 'jobsBatchRequest'>
  >
  kiosk?: Resolver<Maybe<ResolversTypes['KioskQuery']>, ParentType, ContextType>
  kitchenCapacityHealthStatus?: Resolver<
    ResolversTypes['KitchenCapacityHealthStatus'],
    ParentType,
    ContextType
  >
  legacyJob?: Resolver<
    Maybe<ResolversTypes['JobResponse']>,
    ParentType,
    ContextType,
    RequireFields<QueryLegacyJobArgs, 'legacyJobRequest'>
  >
  legacyJobsBatch?: Resolver<
    Maybe<ResolversTypes['JobsLegacyBatchResponse']>,
    ParentType,
    ContextType,
    RequireFields<QueryLegacyJobsBatchArgs, 'legacyJobsBatchRequest'>
  >
  location?: Resolver<
    ResolversTypes['RestaurantLocation'],
    ParentType,
    ContextType,
    RequireFields<QueryLocationArgs, 'guid'>
  >
  logbook?: Resolver<ResolversTypes['LogbookQuery'], ParentType, ContextType>
  managementGroupAccess?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['ManagementGroup']>>>,
    ParentType,
    ContextType,
    RequireFields<
      QueryManagementGroupAccessArgs,
      'includeArchived' | 'includeInactive'
    >
  >
  marketing?: Resolver<
    ResolversTypes['MarketingQuery'],
    ParentType,
    ContextType
  >
  memberships?: Resolver<
    ResolversTypes['MembershipsQuery'],
    ParentType,
    ContextType
  >
  menuEntities?: Resolver<
    Array<ResolversTypes['MenuEntity']>,
    ParentType,
    ContextType,
    RequireFields<QueryMenuEntitiesArgs, 'options'>
  >
  menuEntityAncestors?: Resolver<
    Array<ResolversTypes['EntityAncestorResponse']>,
    ParentType,
    ContextType,
    RequireFields<QueryMenuEntityAncestorsArgs, 'query'>
  >
  menuGroups?: Resolver<
    Array<ResolversTypes['MenuGroup']>,
    ParentType,
    ContextType,
    RequireFields<QueryMenuGroupsArgs, 'query'>
  >
  menuItemAncestors?: Resolver<
    Array<ResolversTypes['EntityAncestorResponse']>,
    ParentType,
    ContextType,
    RequireFields<QueryMenuItemAncestorsArgs, 'query'>
  >
  menuItemByVersionId?: Resolver<
    ResolversTypes['MenuItem'],
    ParentType,
    ContextType,
    RequireFields<QueryMenuItemByVersionIdArgs, 'versionId'>
  >
  menuItemPlateCost?: Resolver<
    Maybe<ResolversTypes['MenuItemPlateCost']>,
    ParentType,
    ContextType,
    RequireFields<QueryMenuItemPlateCostArgs, 'id'>
  >
  menuItemPlateCostByItemMLID?: Resolver<
    Maybe<ResolversTypes['MenuItemPlateCost']>,
    ParentType,
    ContextType,
    RequireFields<QueryMenuItemPlateCostByItemMlidArgs, 'itemMLID' | 'source'>
  >
  menuItemPlateCostsByItemMLID?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['MenuItemPlateCost']>>>,
    ParentType,
    ContextType,
    RequireFields<QueryMenuItemPlateCostsByItemMlidArgs, 'itemMLID'>
  >
  menuUpsellConfig?: Resolver<
    Maybe<ResolversTypes['MenuUpsellConfigQuery']>,
    ParentType,
    ContextType
  >
  menus?: Resolver<
    Array<ResolversTypes['Menu']>,
    ParentType,
    ContextType,
    RequireFields<QueryMenusArgs, 'query'>
  >
  modifierAncestors?: Resolver<
    Array<ResolversTypes['EntityAncestorResponse']>,
    ParentType,
    ContextType,
    RequireFields<QueryModifierAncestorsArgs, 'query'>
  >
  modifierGroupByVersionId?: Resolver<
    Maybe<ResolversTypes['ModifierGroup']>,
    ParentType,
    ContextType,
    RequireFields<QueryModifierGroupByVersionIdArgs, 'versionId'>
  >
  modifierGroups?: Resolver<
    ResolversTypes['PagedModifierGroupsResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryModifierGroupsArgs, 'query'>
  >
  myEmployees?: Resolver<
    Array<ResolversTypes['Employee']>,
    ParentType,
    ContextType
  >
  navigation?: Resolver<
    ResolversTypes['NavigationQuery'],
    ParentType,
    ContextType
  >
  navigationConfigLocations?: Resolver<
    Array<ResolversTypes['NavigationConfigLocation']>,
    ParentType,
    ContextType
  >
  networks?: Resolver<
    Maybe<ResolversTypes['NetworkQueries']>,
    ParentType,
    ContextType
  >
  noSaleReasons?: Resolver<
    Array<ResolversTypes['OpenCashDrawerReason']>,
    ParentType,
    ContextType
  >
  onboarding?: Resolver<
    Maybe<ResolversTypes['OnboardingQuery']>,
    ParentType,
    ContextType
  >
  opt?: Resolver<ResolversTypes['OptQuery'], ParentType, ContextType>
  orderingSchedule?: Resolver<
    Maybe<ResolversTypes['OrderingSchedule']>,
    ParentType,
    ContextType
  >
  orders?: Resolver<ResolversTypes['OrdersQuery'], ParentType, ContextType>
  ordersConfig?: Resolver<
    Maybe<ResolversTypes['OrdersConfigQuery']>,
    ParentType,
    ContextType
  >
  outOfStockInventory?: Resolver<
    Maybe<ResolversTypes['MenuItemInventoryConnection']>,
    ParentType,
    ContextType,
    RequireFields<
      QueryOutOfStockInventoryArgs,
      'after' | 'first' | 'includeOptionOnly'
    >
  >
  packaging?: Resolver<
    ResolversTypes['PackagingQuery'],
    ParentType,
    ContextType
  >
  pagedMenuItems?: Resolver<
    ResolversTypes['PagedMenuItemResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryPagedMenuItemsArgs, 'query'>
  >
  pagedModifiers?: Resolver<
    ResolversTypes['PagedModifierResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryPagedModifiersArgs, 'query'>
  >
  pagedPriceLevels?: Resolver<
    ResolversTypes['PagedPriceLevelResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryPagedPriceLevelsArgs, 'query'>
  >
  partnerChannelConfig?: Resolver<
    Maybe<ResolversTypes['PartnerChannelConfig']>,
    ParentType,
    ContextType
  >
  partners?: Resolver<ResolversTypes['PartnerQuery'], ParentType, ContextType>
  paymentsConfig?: Resolver<
    ResolversTypes['PaymentsConfigQuery'],
    ParentType,
    ContextType
  >
  payoutReasons?: Resolver<
    Array<ResolversTypes['OpenCashDrawerReason']>,
    ParentType,
    ContextType
  >
  permissions?: Resolver<ResolversTypes['Permissions'], ParentType, ContextType>
  popular?: Resolver<
    ResolversTypes['PopularSearchResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryPopularArgs, 'size'>
  >
  productSubscriptions?: Resolver<
    ResolversTypes['ProductSubscriptionsQuery'],
    ParentType,
    ContextType
  >
  publish?: Resolver<
    Maybe<ResolversTypes['PublishQuery']>,
    ParentType,
    ContextType
  >
  published?: Resolver<
    ResolversTypes['PublishedQueries'],
    ParentType,
    ContextType
  >
  publishedItemFeedbackConfig?: Resolver<
    ResolversTypes['PublishedItemFeedbackConfigResponse'],
    ParentType,
    ContextType
  >
  publishedToastPayConfig?: Resolver<
    ResolversTypes['PublishedToastPayConfigResponse'],
    ParentType,
    ContextType
  >
  quickActions?: Resolver<
    Array<ResolversTypes['QuickAction']>,
    ParentType,
    ContextType
  >
  restaurant?: Resolver<ResolversTypes['Restaurant'], ParentType, ContextType>
  restaurantAccess?: Resolver<
    Array<ResolversTypes['RestaurantAccess']>,
    ParentType,
    ContextType,
    RequireFields<
      QueryRestaurantAccessArgs,
      'afterRestaurantID' | 'includeArchived' | 'includeInactive' | 'pageSize'
    >
  >
  restaurantLocations?: Resolver<
    ResolversTypes['RestaurantLocationsQuery'],
    ParentType,
    ContextType
  >
  restaurantLocationsDiff?: Resolver<
    ResolversTypes['RestaurantLocationsDiff'],
    ParentType,
    ContextType,
    RequireFields<QueryRestaurantLocationsDiffArgs, 'leftId' | 'rightId'>
  >
  restaurantSet?: Resolver<
    ResolversTypes['RestaurantSet'],
    ParentType,
    ContextType,
    RequireFields<QueryRestaurantSetArgs, 'id'>
  >
  restaurantSetByLegacyId?: Resolver<
    ResolversTypes['RestaurantSet'],
    ParentType,
    ContextType,
    RequireFields<QueryRestaurantSetByLegacyIdArgs, 'legacyId'>
  >
  salesCategories?: Resolver<
    Array<ResolversTypes['SavedSalesCategory']>,
    ParentType,
    ContextType,
    RequireFields<QuerySalesCategoriesArgs, 'search'>
  >
  saved?: Resolver<ResolversTypes['SavedQueries'], ParentType, ContextType>
  savedMenuItemTags?: Resolver<
    ResolversTypes['PagedSavedMenuItemTagsResponse'],
    ParentType,
    ContextType,
    RequireFields<QuerySavedMenuItemTagsArgs, 'input'>
  >
  savedPrepSequences?: Resolver<
    Array<ResolversTypes['SavedPrepSequence']>,
    ParentType,
    ContextType,
    RequireFields<QuerySavedPrepSequencesArgs, 'search'>
  >
  savedPrepStations?: Resolver<
    Array<ResolversTypes['SavedPrepStation']>,
    ParentType,
    ContextType,
    RequireFields<QuerySavedPrepStationsArgs, 'search'>
  >
  savedToastPayConfig?: Resolver<
    ResolversTypes['SavedToastPayConfigResponse'],
    ParentType,
    ContextType
  >
  search?: Resolver<
    ResolversTypes['SearchResponse'],
    ParentType,
    ContextType,
    RequireFields<
      QuerySearchArgs,
      'ignoreForMetrics' | 'page' | 'pageSize' | 'q' | 'sources' | 'startIndex'
    >
  >
  searchEmployees?: Resolver<
    Array<ResolversTypes['EmployeeV2']>,
    ParentType,
    ContextType,
    RequireFields<QuerySearchEmployeesArgs, 'employeeSearchInput'>
  >
  searchEmployeesPaginated?: Resolver<
    ResolversTypes['EmployeeConnection'],
    ParentType,
    ContextType,
    RequireFields<
      QuerySearchEmployeesPaginatedArgs,
      'employeeSearchInput' | 'orderByInput' | 'paginationInput'
    >
  >
  searchSuggestion?: Resolver<
    ResolversTypes['SuggestionResponse'],
    ParentType,
    ContextType,
    RequireFields<QuerySearchSuggestionArgs, 'q' | 'size'>
  >
  shiftReviewConfig?: Resolver<
    ResolversTypes['ShiftReviewConfig'],
    ParentType,
    ContextType
  >
  shiftsByRestaurant?: Resolver<
    Array<ResolversTypes['EmployeeShift']>,
    ParentType,
    ContextType,
    RequireFields<QueryShiftsByRestaurantArgs, 'filter' | 'restaurant'>
  >
  shiftsByRestaurantPaged?: Resolver<
    ResolversTypes['EmployeeShiftConnection'],
    ParentType,
    ContextType,
    RequireFields<
      QueryShiftsByRestaurantPagedArgs,
      'filter' | 'pagination' | 'restaurant'
    >
  >
  shiftsByRestaurants?: Resolver<
    Array<ResolversTypes['EmployeeShift']>,
    ParentType,
    ContextType,
    RequireFields<QueryShiftsByRestaurantsArgs, 'filter' | 'restaurants'>
  >
  shiftsByRestaurantsPaged?: Resolver<
    ResolversTypes['EmployeeShiftConnection'],
    ParentType,
    ContextType,
    RequireFields<
      QueryShiftsByRestaurantsPagedArgs,
      'filter' | 'pagination' | 'restaurants'
    >
  >
  shiftsPagedV2?: Resolver<
    ResolversTypes['EmployeeShiftConnectionV2'],
    ParentType,
    ContextType,
    RequireFields<
      QueryShiftsPagedV2Args,
      'filter' | 'orderBy' | 'pagination' | 'quickSearch'
    >
  >
  shiftsV2Employees?: Resolver<
    ResolversTypes['ShiftV2EmployeesResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryShiftsV2EmployeesArgs, 'name' | 'shiftFilter' | 'size'>
  >
  smartQuote?: Resolver<
    Maybe<ResolversTypes['SmartQuoteQuery']>,
    ParentType,
    ContextType
  >
  smartQuoteSimpleComparison?: Resolver<
    Maybe<ResolversTypes['SmartQuoteSimpleComparisonQuery']>,
    ParentType,
    ContextType
  >
  sousChef?: Resolver<ResolversTypes['SousChefQuery'], ParentType, ContextType>
  sousChefAdmin?: Resolver<
    ResolversTypes['SousChefAdminQuery'],
    ParentType,
    ContextType
  >
  stockForMenuItemByVersionId?: Resolver<
    Maybe<ResolversTypes['Stock']>,
    ParentType,
    ContextType,
    RequireFields<QueryStockForMenuItemByVersionIdArgs, 'versionId'>
  >
  support?: Resolver<ResolversTypes['SupportQuery'], ParentType, ContextType>
  takeoutDeliveryCategoryPage?: Resolver<
    Maybe<ResolversTypes['TakeoutDeliveryCategoryPage']>,
    ParentType,
    ContextType
  >
  takeoutDeliveryConfig?: Resolver<
    Maybe<ResolversTypes['TakeoutDeliveryConfig']>,
    ParentType,
    ContextType
  >
  taxRates?: Resolver<
    Array<ResolversTypes['SavedTaxRate']>,
    ParentType,
    ContextType,
    RequireFields<QueryTaxRatesArgs, 'search'>
  >
  thirdPartyMock?: Resolver<
    ResolversTypes['ThirdPartyMockQuery'],
    ParentType,
    ContextType
  >
  thirdPartyOrderingOnboarding?: Resolver<
    ResolversTypes['ThirdPartyOrderingOnboardingQuery'],
    ParentType,
    ContextType
  >
  tips?: Resolver<ResolversTypes['TipsQuery'], ParentType, ContextType>
  toastNowDeviceRegistry?: Resolver<
    ResolversTypes['ToastNowDeviceRegistryQuery'],
    ParentType,
    ContextType
  >
  toastPayRelatedConfiguration?: Resolver<
    ResolversTypes['ToastPayRelatedConfiguration'],
    ParentType,
    ContextType
  >
  toastSearch?: Resolver<ResolversTypes['SearchQuery'], ParentType, ContextType>
  topSellingModifiers?: Resolver<
    Maybe<ResolversTypes['TopSellingModifierConnection']>,
    ParentType,
    ContextType,
    RequireFields<
      QueryTopSellingModifiersArgs,
      | 'after'
      | 'businessDay'
      | 'first'
      | 'modifierFilters'
      | 'parentItemFilters'
      | 'restaurantGuid'
      | 'sortBy'
    >
  >
  trials?: Resolver<ResolversTypes['TrialsQuery'], ParentType, ContextType>
  uiOptions?: Resolver<
    Maybe<ResolversTypes['UIOptionsQuery']>,
    ParentType,
    ContextType
  >
  userPreferences?: Resolver<
    ResolversTypes['UserPreferencesQuery'],
    ParentType,
    ContextType
  >
  userRecentSearches?: Resolver<
    ResolversTypes['RecentSearchesResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryUserRecentSearchesArgs, 'size'>
  >
}

export type QuickActionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['QuickAction'] = ResolversParentTypes['QuickAction']
> = {
  hasPermission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  href?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  showAsNew?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type QuoteTimeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['QuoteTime'] = ResolversParentTypes['QuoteTime']
> = {
  deliveryHours?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >
  deliveryMinutes?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  price?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  takeoutHours?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  takeoutMinutes?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ReadFailureResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ReadFailure'] = ResolversParentTypes['ReadFailure']
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type RecentSearchesResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RecentSearchesResponse'] = ResolversParentTypes['RecentSearchesResponse']
> = {
  items?: Resolver<Array<ResolversTypes['UserSearch']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ReconciliationConfigResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ReconciliationConfig'] = ResolversParentTypes['ReconciliationConfig']
> = {
  isOrderOwner?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  shouldCollectCashInDrawer?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  shouldRequireReconciliation?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type RehiredEmploymentStatusResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RehiredEmploymentStatus'] = ResolversParentTypes['RehiredEmploymentStatus']
> = {
  adjustedHireDate?: Resolver<
    Maybe<ResolversTypes['Date']>,
    ParentType,
    ContextType
  >
  dismissalPeriod?: Resolver<
    Maybe<ResolversTypes['DateRange']>,
    ParentType,
    ContextType
  >
  hireDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  rehireDate?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type RenderedAuthorizationDecisionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RenderedAuthorizationDecision'] = ResolversParentTypes['RenderedAuthorizationDecision']
> = {
  decisions?: Resolver<
    Array<ResolversTypes['AuthorizationDecisionReason']>,
    ParentType,
    ContextType
  >
  result?: Resolver<
    ResolversTypes['AuthorizationDecision'],
    ParentType,
    ContextType
  >
  target?: Resolver<
    ResolversTypes['AuthorizationTarget'],
    ParentType,
    ContextType
  >
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type RepaymentMilestoneResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RepaymentMilestone'] = ResolversParentTypes['RepaymentMilestone']
> = {
  amount?: Resolver<ResolversTypes['Money'], ParentType, ContextType>
  daysFromStartDate?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ReportSectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ReportSection'] = ResolversParentTypes['ReportSection']
> = {
  isDisplayed?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  ordinal?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ResolvedConfigEntityIResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ResolvedConfigEntityI'] = ResolversParentTypes['ResolvedConfigEntityI']
> = {
  __resolveType: TypeResolveFn<
    'ResolvedDataExtensionSet',
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  managementGroup?: Resolver<
    ResolversTypes['ManagementGroup'],
    ParentType,
    ContextType
  >
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  restaurantLocation?: Resolver<
    ResolversTypes['SavedRestaurantLocation'],
    ParentType,
    ContextType
  >
}

export type ResolvedDataExtensionSetResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ResolvedDataExtensionSet'] = ResolversParentTypes['ResolvedDataExtensionSet']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isArchived?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  managementGroup?: Resolver<
    ResolversTypes['ManagementGroup'],
    ParentType,
    ContextType
  >
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  payments?: Resolver<
    Array<ResolversTypes['PaymentDataExtension']>,
    ParentType,
    ContextType
  >
  restaurantLocation?: Resolver<
    ResolversTypes['SavedRestaurantLocation'],
    ParentType,
    ContextType
  >
  revenueCenters?: Resolver<
    Array<ResolversTypes['RevenueCenterDataExtension']>,
    ParentType,
    ContextType
  >
  revision?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  salesCategories?: Resolver<
    Array<ResolversTypes['SalesCategoryDataExtension']>,
    ParentType,
    ContextType
  >
  services?: Resolver<
    Array<ResolversTypes['ServiceDataExtension']>,
    ParentType,
    ContextType
  >
  target?: Resolver<
    Maybe<ResolversTypes['RestaurantSet']>,
    ParentType,
    ContextType
  >
  taxRates?: Resolver<
    Array<ResolversTypes['TaxRateDataExtension']>,
    ParentType,
    ContextType
  >
  type?: Resolver<
    ResolversTypes['DataExtensionSetType'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Response'] = ResolversParentTypes['Response']
> = {
  response?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type RestaurantResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Restaurant'] = ResolversParentTypes['Restaurant']
> = {
  __resolveReference?: ReferenceResolver<
    Maybe<ResolversTypes['Restaurant']>,
    { __typename: 'Restaurant' } & GraphQLRecursivePick<
      ParentType,
      { id: true }
    >,
    ContextType
  >
  breakConfigs?: Resolver<
    Maybe<Array<ResolversTypes['RestaurantBreakConfig']>>,
    { __typename: 'Restaurant' } & GraphQLRecursivePick<
      ParentType,
      { id: true }
    >,
    ContextType
  >
  firstDayOfWeek?: Resolver<
    ResolversTypes['Int'],
    { __typename: 'Restaurant' } & GraphQLRecursivePick<
      ParentType,
      { id: true }
    >,
    ContextType
  >

  locationCode?: Resolver<
    Maybe<ResolversTypes['String']>,
    { __typename: 'Restaurant' } & GraphQLRecursivePick<
      ParentType,
      { id: true }
    >,
    ContextType
  >
  locationName?: Resolver<
    Maybe<ResolversTypes['String']>,
    { __typename: 'Restaurant' } & GraphQLRecursivePick<
      ParentType,
      { id: true }
    >,
    ContextType
  >
  name?: Resolver<
    Maybe<ResolversTypes['String']>,
    { __typename: 'Restaurant' } & GraphQLRecursivePick<
      ParentType,
      { id: true }
    >,
    ContextType
  >
  timeZone?: Resolver<
    ResolversTypes['String'],
    { __typename: 'Restaurant' } & GraphQLRecursivePick<
      ParentType,
      { id: true }
    >,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type RestaurantAccessResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RestaurantAccess'] = ResolversParentTypes['RestaurantAccess']
> = {
  restaurantLocation?: Resolver<
    ResolversTypes['AccessibleRestaurantLocation'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type RestaurantAvailabilityInfoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RestaurantAvailabilityInfo'] = ResolversParentTypes['RestaurantAvailabilityInfo']
> = {
  invalidPartySize?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  onlineReservationsDisabled?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  reservationsDisabled?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  slots?: Resolver<
    Array<ResolversTypes['SlotAvailabilityInfo']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type RestaurantBookingLimitResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RestaurantBookingLimit'] = ResolversParentTypes['RestaurantBookingLimit']
> = {
  dateExceededReservations?: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >
  dateExceededWaitlists?: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >
  hasExceededReservation?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  hasExceededWaitlist?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  monthlyReservations?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  monthlyWaitlists?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  plan?: Resolver<ResolversTypes['BookingPlan'], ParentType, ContextType>
  reservationLimit?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >
  waitlistLimit?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type RestaurantBreakConfigResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RestaurantBreakConfig'] = ResolversParentTypes['RestaurantBreakConfig']
> = {
  active?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isPaid?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type RestaurantGroupResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RestaurantGroup'] = ResolversParentTypes['RestaurantGroup']
> = {
  __resolveReference?: ReferenceResolver<
    Maybe<ResolversTypes['RestaurantGroup']>,
    { __typename: 'RestaurantGroup' } & GraphQLRecursivePick<
      ParentType,
      { id: true }
    >,
    ContextType
  >
  archived?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  createdBy?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>
  descendantLocations?: Resolver<
    Array<ResolversTypes['RestaurantLocation']>,
    ParentType,
    ContextType
  >
  descendantRestaurantLocations?: Resolver<
    ResolversTypes['SavedRestaurantLocationConnection'],
    ParentType,
    ContextType,
    RequireFields<
      RestaurantGroupDescendantRestaurantLocationsArgs,
      'after' | 'first'
    >
  >
  description?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  groups?: Resolver<
    Array<ResolversTypes['RestaurantGroup']>,
    ParentType,
    ContextType
  >
  guid?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  legacyId?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  locations?: Resolver<
    Array<ResolversTypes['RestaurantLocation']>,
    ParentType,
    ContextType
  >
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  parentGroups?: Resolver<
    Array<ResolversTypes['RestaurantGroup']>,
    ParentType,
    ContextType
  >
  permissionBits?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  permissionStatus?: Resolver<
    Maybe<ResolversTypes['RestaurantSetPermissionStatus']>,
    ParentType,
    ContextType
  >
  permissions?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['GroupType'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type RestaurantGroupOrLocationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RestaurantGroupOrLocation'] = ResolversParentTypes['RestaurantGroupOrLocation']
> = {
  __resolveType: TypeResolveFn<
    'RestaurantGroup' | 'RestaurantLocation' | 'SavedRestaurantLocation',
    ParentType,
    ContextType
  >
}

export type RestaurantInformationResponseSuccessResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RestaurantInformationResponseSuccess'] = ResolversParentTypes['RestaurantInformationResponseSuccess']
> = {
  response?: Resolver<
    ResolversTypes['MgmtRestaurantInformation'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type RestaurantLeafDataJobLastEvaluatedKeyResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RestaurantLeafDataJobLastEvaluatedKey'] = ResolversParentTypes['RestaurantLeafDataJobLastEvaluatedKey']
> = {
  partitionKey?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  sortKey?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type RestaurantLeafDataJobResultResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RestaurantLeafDataJobResult'] = ResolversParentTypes['RestaurantLeafDataJobResult']
> = {
  lastEvaluatedKey?: Resolver<
    Maybe<ResolversTypes['RestaurantLeafDataJobLastEvaluatedKey']>,
    ParentType,
    ContextType
  >
  pageSize?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type RestaurantLocationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RestaurantLocation'] = ResolversParentTypes['RestaurantLocation']
> = {
  __resolveReference?: ReferenceResolver<
    Maybe<ResolversTypes['RestaurantLocation']>,
    { __typename: 'RestaurantLocation' } & GraphQLRecursivePick<
      ParentType,
      { id: true }
    >,
    ContextType
  >
  description?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  guid?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  leafGuid?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  locationCode?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  locationName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  parentGroups?: Resolver<
    Array<ResolversTypes['RestaurantGroup']>,
    ParentType,
    ContextType
  >
  permissionBits?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  permissions?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  restaurantName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  restaurantSetId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type RestaurantLocationAddressResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RestaurantLocationAddress'] = ResolversParentTypes['RestaurantLocationAddress']
> = {
  address1?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  address2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  city?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  coordinates?: Resolver<
    Maybe<ResolversTypes['GeoCoordinates']>,
    ParentType,
    ContextType
  >
  country?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  state?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  zip?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type RestaurantLocationImagesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RestaurantLocationImages'] = ResolversParentTypes['RestaurantLocationImages']
> = {
  backgroundImageUrl?: Resolver<
    Maybe<ResolversTypes['Url']>,
    ParentType,
    ContextType
  >
  bannerImageUrl?: Resolver<
    Maybe<ResolversTypes['Url']>,
    ParentType,
    ContextType
  >
  imageUrl?: Resolver<Maybe<ResolversTypes['Url']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type RestaurantLocationInterfaceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RestaurantLocationInterface'] = ResolversParentTypes['RestaurantLocationInterface']
> = {
  __resolveType: TypeResolveFn<
    'PublishedRestaurantLocation' | 'SavedRestaurantLocation',
    ParentType,
    ContextType
  >
  address?: Resolver<
    ResolversTypes['RestaurantLocationAddress'],
    ParentType,
    ContextType
  >
  closeoutHour?: Resolver<ResolversTypes['LocalTime'], ParentType, ContextType>
  currencyCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  description?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  images?: Resolver<
    ResolversTypes['RestaurantLocationImages'],
    ParentType,
    ContextType
  >
  isTestMode?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  language?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  locationCode?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  locationName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  phone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  restaurantName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  status?: Resolver<
    ResolversTypes['RestaurantLocationStatus'],
    ParentType,
    ContextType
  >
  timeZone?: Resolver<ResolversTypes['String'], ParentType, ContextType>
}

export type RestaurantLocationsDiffResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RestaurantLocationsDiff'] = ResolversParentTypes['RestaurantLocationsDiff']
> = {
  intersection?: Resolver<
    Array<ResolversTypes['RestaurantLocation']>,
    ParentType,
    ContextType
  >
  left?: Resolver<
    ResolversTypes['RestaurantGroupOrLocation'],
    ParentType,
    ContextType
  >
  onlyLeft?: Resolver<
    Array<ResolversTypes['RestaurantLocation']>,
    ParentType,
    ContextType
  >
  onlyRight?: Resolver<
    Array<ResolversTypes['RestaurantLocation']>,
    ParentType,
    ContextType
  >
  right?: Resolver<
    ResolversTypes['RestaurantGroupOrLocation'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type RestaurantLocationsQueryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RestaurantLocationsQuery'] = ResolversParentTypes['RestaurantLocationsQuery']
> = {
  sessionLocation?: Resolver<
    ResolversTypes['SavedRestaurantLocation'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type RestaurantMenuItemEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RestaurantMenuItemEdge'] = ResolversParentTypes['RestaurantMenuItemEdge']
> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  node?: Resolver<ResolversTypes['PublishedMenuItem'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type RestaurantMetricsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RestaurantMetrics'] = ResolversParentTypes['RestaurantMetrics']
> = {
  businessDay?: Resolver<ResolversTypes['Date'], ParentType, ContextType>
  comparisonBusinessDay?: Resolver<
    Maybe<ResolversTypes['Date']>,
    ParentType,
    ContextType
  >
  laborMetrics?: Resolver<
    ResolversTypes['LaborMetrics'],
    ParentType,
    ContextType
  >
  orderMetrics?: Resolver<
    ResolversTypes['OrderMetrics'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type RestaurantNotFoundResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RestaurantNotFound'] = ResolversParentTypes['RestaurantNotFound']
> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  status?: Resolver<ResolversTypes['ErrorCode'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type RestaurantRepositoryDependencyFailureResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RestaurantRepositoryDependencyFailure'] = ResolversParentTypes['RestaurantRepositoryDependencyFailure']
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type RestaurantSetResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RestaurantSet'] = ResolversParentTypes['RestaurantSet']
> = {
  __resolveReference?: ReferenceResolver<
    Maybe<ResolversTypes['RestaurantSet']>,
    { __typename: 'RestaurantSet' } & GraphQLRecursivePick<
      ParentType,
      { id: true }
    >,
    ContextType
  >
  description?: Resolver<
    Maybe<ResolversTypes['String']>,
    { __typename: 'RestaurantSet' } & GraphQLRecursivePick<
      ParentType,
      { id: true }
    >,
    ContextType
  >
  groups?: Resolver<
    Maybe<Array<ResolversTypes['RestaurantGroup']>>,
    { __typename: 'RestaurantSet' } & GraphQLRecursivePick<
      ParentType,
      { id: true }
    >,
    ContextType
  >

  isLocation?: Resolver<
    ResolversTypes['Boolean'],
    { __typename: 'RestaurantSet' } & GraphQLRecursivePick<
      ParentType,
      { id: true }
    >,
    ContextType
  >
  legacyId?: Resolver<
    ResolversTypes['String'],
    { __typename: 'RestaurantSet' } & GraphQLRecursivePick<
      ParentType,
      { id: true }
    >,
    ContextType
  >
  locations?: Resolver<
    Maybe<Array<ResolversTypes['RestaurantLocation']>>,
    { __typename: 'RestaurantSet' } & GraphQLRecursivePick<
      ParentType,
      { id: true }
    >,
    ContextType
  >
  name?: Resolver<
    ResolversTypes['String'],
    { __typename: 'RestaurantSet' } & GraphQLRecursivePick<
      ParentType,
      { id: true }
    >,
    ContextType
  >
  permissions?: Resolver<
    Maybe<ResolversTypes['Permissions']>,
    { __typename: 'RestaurantSet' } & GraphQLRecursivePick<
      ParentType,
      { id: true }
    >,
    ContextType
  >
  set?: Resolver<
    ResolversTypes['RestaurantGroupOrLocation'],
    { __typename: 'RestaurantSet' } & GraphQLRecursivePick<
      ParentType,
      { id: true }
    >,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type RestaurantUserResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RestaurantUser'] = ResolversParentTypes['RestaurantUser']
> = {
  __resolveReference?: ReferenceResolver<
    Maybe<ResolversTypes['RestaurantUser']>,
    { __typename: 'RestaurantUser' } & GraphQLRecursivePick<
      ParentType,
      { id: true }
    >,
    ContextType
  >
  externalEmployeeId?: Resolver<
    Maybe<ResolversTypes['ID']>,
    { __typename: 'RestaurantUser' } & GraphQLRecursivePick<
      ParentType,
      { id: true }
    >,
    ContextType
  >

  isDeleted?: Resolver<
    ResolversTypes['Boolean'],
    { __typename: 'RestaurantUser' } & GraphQLRecursivePick<
      ParentType,
      { id: true }
    >,
    ContextType
  >
  jobs?: Resolver<
    Array<ResolversTypes['Job']>,
    { __typename: 'RestaurantUser' } & GraphQLRecursivePick<
      ParentType,
      { id: true }
    >,
    ContextType
  >

  user?: Resolver<
    Maybe<ResolversTypes['User']>,
    { __typename: 'RestaurantUser' } & GraphQLRecursivePick<
      ParentType,
      { id: true }
    >,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type RestoreMembershipResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RestoreMembershipResponse'] = ResolversParentTypes['RestoreMembershipResponse']
> = {
  code?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type RevenueCenterDataExtensionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RevenueCenterDataExtension'] = ResolversParentTypes['RevenueCenterDataExtension']
> = {
  revenueCenter?: Resolver<
    ResolversTypes['SavedRevenueCenter'],
    ParentType,
    ContextType
  >
  type?: Resolver<ResolversTypes['DataExtensionType'], ParentType, ContextType>
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SalesCategoryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SalesCategory'] = ResolversParentTypes['SalesCategory']
> = {
  guid?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SalesCategoryDataExtensionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SalesCategoryDataExtension'] = ResolversParentTypes['SalesCategoryDataExtension']
> = {
  salesCategory?: Resolver<
    ResolversTypes['SavedSalesCategory'],
    ParentType,
    ContextType
  >
  type?: Resolver<ResolversTypes['DataExtensionType'], ParentType, ContextType>
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SaveFailureResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SaveFailure'] = ResolversParentTypes['SaveFailure']
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SavePackagingConfigResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SavePackagingConfigResponse'] = ResolversParentTypes['SavePackagingConfigResponse']
> = {
  __resolveType: TypeResolveFn<
    | 'OutdatedPackagingConfigError'
    | 'PackagingComplianceError'
    | 'SavedPackagingConfig',
    ParentType,
    ContextType
  >
}

export type SavedAlternatePaymentTypeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SavedAlternatePaymentType'] = ResolversParentTypes['SavedAlternatePaymentType']
> = {
  __resolveReference?: ReferenceResolver<
    Maybe<ResolversTypes['SavedAlternatePaymentType']>,
    { __typename: 'SavedAlternatePaymentType' } & GraphQLRecursivePick<
      ParentType,
      { id: true }
    >,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  target?: Resolver<ResolversTypes['RestaurantSet'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SavedChannelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SavedChannel'] = ResolversParentTypes['SavedChannel']
> = {
  __resolveReference?: ReferenceResolver<
    Maybe<ResolversTypes['SavedChannel']>,
    { __typename: 'SavedChannel' } & GraphQLRecursivePick<
      ParentType,
      { multiLocationId: true }
    >,
    ContextType
  >
  channelAvailability?: Resolver<
    Maybe<ResolversTypes['SavedChannelAvailability']>,
    ParentType,
    ContextType
  >
  channelAvailabilityId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  channelMenuEntities?: Resolver<
    Maybe<Array<ResolversTypes['ChannelMenuEntity']>>,
    ParentType,
    ContextType
  >
  channelMenuPriceAdjustment?: Resolver<
    Maybe<ResolversTypes['ChannelMenuPriceAdjustment']>,
    ParentType,
    ContextType
  >
  channelType?: Resolver<
    Maybe<ResolversTypes['ChannelType']>,
    ParentType,
    ContextType
  >
  channelTypeGuid?: Resolver<
    Maybe<ResolversTypes['ID']>,
    ParentType,
    ContextType
  >
  deleted?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  idempotencyKey?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  lastModifiedUser?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType
  >
  lastModifiedUserGuid?: Resolver<
    Maybe<ResolversTypes['ID']>,
    ParentType,
    ContextType
  >
  multiLocationId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  owningRestaurantSetGuid?: Resolver<
    Maybe<ResolversTypes['ID']>,
    ParentType,
    ContextType
  >
  owningRestaurantSetId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  serviceAreaIds?: Resolver<
    Maybe<Array<ResolversTypes['String']>>,
    ParentType,
    ContextType
  >
  targetRestaurantSetGuid?: Resolver<
    Maybe<ResolversTypes['ID']>,
    ParentType,
    ContextType
  >
  targetRestaurantSetId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  versionId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SavedChannelAvailabilityResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SavedChannelAvailability'] = ResolversParentTypes['SavedChannelAvailability']
> = {
  __resolveReference?: ReferenceResolver<
    Maybe<ResolversTypes['SavedChannelAvailability']>,
    { __typename: 'SavedChannelAvailability' } & GraphQLRecursivePick<
      ParentType,
      { id: true }
    >,
    ContextType
  >
  deleted?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  enabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  lastModifiedUserGuid?: Resolver<
    Maybe<ResolversTypes['ID']>,
    ParentType,
    ContextType
  >
  multiLocationId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  owningRestaurantSetId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  snoozedUntil?: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >
  targetRestaurantSetId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  versionId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SavedConfigResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SavedConfig'] = ResolversParentTypes['SavedConfig']
> = {
  __resolveReference?: ReferenceResolver<
    Maybe<ResolversTypes['SavedConfig']>,
    { __typename: 'SavedConfig' } & GraphQLRecursivePick<
      ParentType,
      { id: true; configType: { id: true } }
    >,
    ContextType
  >
  configType?: Resolver<ResolversTypes['ConfigType'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SavedConfigEntityIResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SavedConfigEntityI'] = ResolversParentTypes['SavedConfigEntityI']
> = {
  __resolveType: TypeResolveFn<'SavedDataExtensionSet', ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isArchived?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  managementGroup?: Resolver<
    ResolversTypes['ManagementGroup'],
    ParentType,
    ContextType
  >
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  overrides?: Resolver<
    Array<ResolversTypes['SavedConfigOverrideI']>,
    ParentType,
    ContextType
  >
  revision?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  target?: Resolver<
    Maybe<ResolversTypes['RestaurantSet']>,
    ParentType,
    ContextType
  >
}

export type SavedConfigOverrideIResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SavedConfigOverrideI'] = ResolversParentTypes['SavedConfigOverrideI']
> = {
  __resolveType: TypeResolveFn<
    'DataExtensionSetOverride',
    ParentType,
    ContextType
  >
  fields?: Resolver<
    Array<ResolversTypes['ConfigField']>,
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  revision?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  target?: Resolver<ResolversTypes['RestaurantSet'], ParentType, ContextType>
}

export type SavedDataExtensionSetResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SavedDataExtensionSet'] = ResolversParentTypes['SavedDataExtensionSet']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isArchived?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  managementGroup?: Resolver<
    ResolversTypes['ManagementGroup'],
    ParentType,
    ContextType
  >
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  overrides?: Resolver<
    Array<ResolversTypes['DataExtensionSetOverride']>,
    ParentType,
    ContextType
  >
  payments?: Resolver<
    Array<ResolversTypes['PaymentDataExtension']>,
    ParentType,
    ContextType
  >
  revenueCenters?: Resolver<
    Array<ResolversTypes['RevenueCenterDataExtension']>,
    ParentType,
    ContextType
  >
  revision?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  salesCategories?: Resolver<
    Array<ResolversTypes['SalesCategoryDataExtension']>,
    ParentType,
    ContextType
  >
  services?: Resolver<
    Array<ResolversTypes['ServiceDataExtension']>,
    ParentType,
    ContextType
  >
  target?: Resolver<
    Maybe<ResolversTypes['RestaurantSet']>,
    ParentType,
    ContextType
  >
  taxRates?: Resolver<
    Array<ResolversTypes['TaxRateDataExtension']>,
    ParentType,
    ContextType
  >
  type?: Resolver<
    ResolversTypes['DataExtensionSetType'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SavedDiningOptionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SavedDiningOption'] = ResolversParentTypes['SavedDiningOption']
> = {
  __resolveReference?: ReferenceResolver<
    Maybe<ResolversTypes['SavedDiningOption']>,
    { __typename: 'SavedDiningOption' } & GraphQLRecursivePick<
      ParentType,
      { id: true }
    >,
    ContextType
  >
  archived?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  behavior?: Resolver<
    Maybe<ResolversTypes['OrderDiningOptionBehavior']>,
    ParentType,
    ContextType
  >
  copiedFromId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  curbside?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  editabilityLevel?: Resolver<
    Maybe<ResolversTypes['OrderConfigEditabilityLevel']>,
    ParentType,
    ContextType
  >
  editabilityReasonCode?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  glCodeId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  importedId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  isDefault?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  isEnabledForApi?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  multiLocationId?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  ordinal?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  ownerRestaurantSet?: Resolver<
    Maybe<ResolversTypes['RestaurantSet']>,
    ParentType,
    ContextType
  >
  requiresSchedule?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  targetRestaurantSet?: Resolver<
    Maybe<ResolversTypes['RestaurantSet']>,
    ParentType,
    ContextType
  >
  toastDeliveryServiceProvider?: Resolver<
    Maybe<ResolversTypes['OrderDeliveryServicesProvider']>,
    ParentType,
    ContextType
  >
  version?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SavedGiftCardReceiptConfigResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SavedGiftCardReceiptConfig'] = ResolversParentTypes['SavedGiftCardReceiptConfig']
> = {
  balanceInquiryConfig?: Resolver<
    ResolversTypes['GiftReceiptPrintStrategy'],
    ParentType,
    ContextType
  >
  giftActivationConfig?: Resolver<
    ResolversTypes['GiftReceiptPrintStrategy'],
    ParentType,
    ContextType
  >
  managementGroup?: Resolver<
    ResolversTypes['ManagementGroup'],
    ParentType,
    ContextType
  >
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  promoArea?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  revision?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  target?: Resolver<
    Maybe<ResolversTypes['RestaurantSet']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SavedMenuAuditSettingResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SavedMenuAuditSetting'] = ResolversParentTypes['SavedMenuAuditSetting']
> = {
  entityType?: Resolver<
    ResolversTypes['SavedMenuAuditEntityType'],
    ParentType,
    ContextType
  >
  multiLocationID?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  settingType?: Resolver<
    ResolversTypes['SavedMenuAuditSettingType'],
    ParentType,
    ContextType
  >
  versionId?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SavedMenuGroupPreparationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SavedMenuGroupPreparation'] = ResolversParentTypes['SavedMenuGroupPreparation']
> = {
  inheritPrepSequence?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  inheritPrepStations?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  prepSequenceMultiLocationId?: Resolver<
    Maybe<ResolversTypes['ID']>,
    ParentType,
    ContextType
  >
  prepStationMultiLocationIds?: Resolver<
    Array<ResolversTypes['ID']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SavedMenuItemPreparationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SavedMenuItemPreparation'] = ResolversParentTypes['SavedMenuItemPreparation']
> = {
  inheritPrepSequence?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  inheritPrepStations?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  orderInTicket?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >
  prepSequenceMultiLocationId?: Resolver<
    Maybe<ResolversTypes['ID']>,
    ParentType,
    ContextType
  >
  prepStationMultiLocationIds?: Resolver<
    Array<ResolversTypes['ID']>,
    ParentType,
    ContextType
  >
  prepTimeSeconds?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SavedMenuItemTagResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SavedMenuItemTag'] = ResolversParentTypes['SavedMenuItemTag']
> = {
  __resolveReference?: ReferenceResolver<
    Maybe<ResolversTypes['SavedMenuItemTag']>,
    { __typename: 'SavedMenuItemTag' } & GraphQLRecursivePick<
      ParentType,
      { id: true }
    >,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SavedMenuPreparationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SavedMenuPreparation'] = ResolversParentTypes['SavedMenuPreparation']
> = {
  prepSequenceMultiLocationId?: Resolver<
    Maybe<ResolversTypes['ID']>,
    ParentType,
    ContextType
  >
  prepStationMultiLocationIds?: Resolver<
    Array<ResolversTypes['ID']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SavedOrderConfirmationScreenConfigResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SavedOrderConfirmationScreenConfig'] = ResolversParentTypes['SavedOrderConfirmationScreenConfig']
> = {
  baseConfig?: Resolver<
    ResolversTypes['QLOrderConfirmationScreenConfig'],
    ParentType,
    ContextType
  >
  overrides?: Resolver<
    Maybe<Array<ResolversTypes['OrderConfirmationScreenConfigOverride']>>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SavedPackagingConfigResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SavedPackagingConfig'] = ResolversParentTypes['SavedPackagingConfig']
> = {
  disabledItemTypes?: Resolver<
    Array<ResolversTypes['PackagingItemType']>,
    ParentType,
    ContextType
  >
  enabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  guestMessage?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  items?: Resolver<
    Array<ResolversTypes['PackagingItem']>,
    ParentType,
    ContextType
  >
  overrides?: Resolver<
    Array<ResolversTypes['PackagingConfigOverride']>,
    ParentType,
    ContextType
  >
  posShowDefaults?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  revision?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SavedPrepSequenceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SavedPrepSequence'] = ResolversParentTypes['SavedPrepSequence']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  multiLocationId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  ordinal?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  ownerRestaurantSet?: Resolver<
    ResolversTypes['RestaurantSet'],
    ParentType,
    ContextType
  >
  targetRestaurantSet?: Resolver<
    ResolversTypes['RestaurantSet'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SavedPrepStationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SavedPrepStation'] = ResolversParentTypes['SavedPrepStation']
> = {
  deleted?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  multiLocationId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  ordinal?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  ownerRestaurantSet?: Resolver<
    ResolversTypes['RestaurantSet'],
    ParentType,
    ContextType
  >
  targetRestaurantSet?: Resolver<
    ResolversTypes['RestaurantSet'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SavedQueriesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SavedQueries'] = ResolversParentTypes['SavedQueries']
> = {
  operatingScheduleServices?: Resolver<
    Array<ResolversTypes['OperatingScheduleService']>,
    ParentType,
    ContextType,
    RequireFields<SavedQueriesOperatingScheduleServicesArgs, 'restaurantSetIds'>
  >
  restaurantLocation?: Resolver<
    Maybe<ResolversTypes['SavedRestaurantLocation']>,
    ParentType,
    ContextType,
    RequireFields<SavedQueriesRestaurantLocationArgs, 'id'>
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SavedRestaurantLocationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SavedRestaurantLocation'] = ResolversParentTypes['SavedRestaurantLocation']
> = {
  __resolveReference?: ReferenceResolver<
    Maybe<ResolversTypes['SavedRestaurantLocation']>,
    { __typename: 'SavedRestaurantLocation' } & GraphQLRecursivePick<
      ParentType,
      { id: true }
    >,
    ContextType
  >
  address?: Resolver<
    ResolversTypes['RestaurantLocationAddress'],
    { __typename: 'SavedRestaurantLocation' } & GraphQLRecursivePick<
      ParentType,
      { id: true }
    >,
    ContextType
  >
  closeoutHour?: Resolver<
    ResolversTypes['LocalTime'],
    { __typename: 'SavedRestaurantLocation' } & GraphQLRecursivePick<
      ParentType,
      { id: true }
    >,
    ContextType
  >
  connectedPartners?: Resolver<
    Maybe<Array<ResolversTypes['Partner']>>,
    { __typename: 'SavedRestaurantLocation' } & GraphQLRecursivePick<
      ParentType,
      { id: true }
    >,
    ContextType
  >
  currencyCode?: Resolver<
    ResolversTypes['String'],
    { __typename: 'SavedRestaurantLocation' } & GraphQLRecursivePick<
      ParentType,
      { id: true }
    >,
    ContextType
  >
  description?: Resolver<
    Maybe<ResolversTypes['String']>,
    { __typename: 'SavedRestaurantLocation' } & GraphQLRecursivePick<
      ParentType,
      { id: true }
    >,
    ContextType
  >
  enabledFeatures?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['String']>>>,
    { __typename: 'SavedRestaurantLocation' } & GraphQLRecursivePick<
      ParentType,
      { id: true }
    >,
    ContextType,
    RequireFields<SavedRestaurantLocationEnabledFeaturesArgs, 'features'>
  >
  fixedFeePQOs?: Resolver<
    Array<ResolversTypes['FixedFeePQO']>,
    { __typename: 'SavedRestaurantLocation' } & GraphQLRecursivePick<
      ParentType,
      { id: true }
    >,
    ContextType
  >

  images?: Resolver<
    ResolversTypes['RestaurantLocationImages'],
    { __typename: 'SavedRestaurantLocation' } & GraphQLRecursivePick<
      ParentType,
      { id: true }
    >,
    ContextType
  >
  isTestMode?: Resolver<
    ResolversTypes['Boolean'],
    { __typename: 'SavedRestaurantLocation' } & GraphQLRecursivePick<
      ParentType,
      { id: true }
    >,
    ContextType
  >
  language?: Resolver<
    Maybe<ResolversTypes['String']>,
    { __typename: 'SavedRestaurantLocation' } & GraphQLRecursivePick<
      ParentType,
      { id: true }
    >,
    ContextType
  >
  legacyRestaurantSetId?: Resolver<
    ResolversTypes['String'],
    { __typename: 'SavedRestaurantLocation' } & GraphQLRecursivePick<
      ParentType,
      { id: true }
    >,
    ContextType
  >
  locationCode?: Resolver<
    Maybe<ResolversTypes['String']>,
    { __typename: 'SavedRestaurantLocation' } & GraphQLRecursivePick<
      ParentType,
      { id: true }
    >,
    ContextType
  >
  locationName?: Resolver<
    Maybe<ResolversTypes['String']>,
    { __typename: 'SavedRestaurantLocation' } & GraphQLRecursivePick<
      ParentType,
      { id: true }
    >,
    ContextType
  >
  marketSegment?: Resolver<
    Maybe<ResolversTypes['MarketSegment']>,
    { __typename: 'SavedRestaurantLocation' } & GraphQLRecursivePick<
      ParentType,
      { id: true }
    >,
    ContextType
  >
  name?: Resolver<
    ResolversTypes['String'],
    { __typename: 'SavedRestaurantLocation' } & GraphQLRecursivePick<
      ParentType,
      { id: true }
    >,
    ContextType
  >
  operatingSchedule?: Resolver<
    ResolversTypes['OperatingSchedule'],
    { __typename: 'SavedRestaurantLocation' } & GraphQLRecursivePick<
      ParentType,
      { id: true }
    >,
    ContextType
  >
  parentGroups?: Resolver<
    Array<ResolversTypes['RestaurantGroup']>,
    { __typename: 'SavedRestaurantLocation' } & GraphQLRecursivePick<
      ParentType,
      { id: true }
    >,
    ContextType
  >
  permissionBits?: Resolver<
    ResolversTypes['String'],
    { __typename: 'SavedRestaurantLocation' } & GraphQLRecursivePick<
      ParentType,
      { id: true }
    >,
    ContextType
  >
  phone?: Resolver<
    Maybe<ResolversTypes['String']>,
    { __typename: 'SavedRestaurantLocation' } & GraphQLRecursivePick<
      ParentType,
      { id: true }
    >,
    ContextType
  >
  restaurantName?: Resolver<
    ResolversTypes['String'],
    { __typename: 'SavedRestaurantLocation' } & GraphQLRecursivePick<
      ParentType,
      { id: true }
    >,
    ContextType
  >
  restaurantSetId?: Resolver<
    ResolversTypes['ID'],
    { __typename: 'SavedRestaurantLocation' } & GraphQLRecursivePick<
      ParentType,
      { id: true }
    >,
    ContextType
  >
  serviceModel?: Resolver<
    ResolversTypes['ServiceModel'],
    { __typename: 'SavedRestaurantLocation' } & GraphQLRecursivePick<
      ParentType,
      { id: true }
    >,
    ContextType
  >
  status?: Resolver<
    ResolversTypes['RestaurantLocationStatus'],
    { __typename: 'SavedRestaurantLocation' } & GraphQLRecursivePick<
      ParentType,
      { id: true }
    >,
    ContextType
  >
  timeZone?: Resolver<
    ResolversTypes['String'],
    { __typename: 'SavedRestaurantLocation' } & GraphQLRecursivePick<
      ParentType,
      { id: true }
    >,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SavedRestaurantLocationConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SavedRestaurantLocationConnection'] = ResolversParentTypes['SavedRestaurantLocationConnection']
> = {
  edges?: Resolver<
    Array<ResolversTypes['SavedRestaurantLocationEdge']>,
    ParentType,
    ContextType
  >
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SavedRestaurantLocationEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SavedRestaurantLocationEdge'] = ResolversParentTypes['SavedRestaurantLocationEdge']
> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  node?: Resolver<
    ResolversTypes['SavedRestaurantLocation'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SavedRestaurantTableResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SavedRestaurantTable'] = ResolversParentTypes['SavedRestaurantTable']
> = {
  __resolveReference?: ReferenceResolver<
    Maybe<ResolversTypes['SavedRestaurantTable']>,
    { __typename: 'SavedRestaurantTable' } & GraphQLRecursivePick<
      ParentType,
      { id: true }
    >,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SavedRevenueCenterResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SavedRevenueCenter'] = ResolversParentTypes['SavedRevenueCenter']
> = {
  __resolveReference?: ReferenceResolver<
    Maybe<ResolversTypes['SavedRevenueCenter']>,
    { __typename: 'SavedRevenueCenter' } & GraphQLRecursivePick<
      ParentType,
      { id: true }
    >,
    ContextType
  >
  archived?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  description?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  multiLocationId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  ownerRestaurantSet?: Resolver<
    ResolversTypes['RestaurantSet'],
    ParentType,
    ContextType
  >
  targetRestaurantSet?: Resolver<
    ResolversTypes['RestaurantSet'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SavedSalesCategoryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SavedSalesCategory'] = ResolversParentTypes['SavedSalesCategory']
> = {
  __resolveReference?: ReferenceResolver<
    Maybe<ResolversTypes['SavedSalesCategory']>,
    { __typename: 'SavedSalesCategory' } & GraphQLRecursivePick<
      ParentType,
      { id: true }
    >,
    ContextType
  >
  archived?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  createdDate?: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >
  description?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  modifiedDate?: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >
  multiLocationId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  ordinal?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  ownerRestaurantSet?: Resolver<
    ResolversTypes['RestaurantSet'],
    ParentType,
    ContextType
  >
  revision?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  targetRestaurantSet?: Resolver<
    ResolversTypes['RestaurantSet'],
    ParentType,
    ContextType
  >
  versionId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SavedStockDataConfigResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SavedStockDataConfig'] = ResolversParentTypes['SavedStockDataConfig']
> = {
  baseConfig?: Resolver<
    ResolversTypes['QLStockDataConfig'],
    ParentType,
    ContextType
  >
  overrides?: Resolver<
    Maybe<Array<ResolversTypes['StockDataConfigOverride']>>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SavedStockOutConfigResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SavedStockOutConfig'] = ResolversParentTypes['SavedStockOutConfig']
> = {
  baseConfig?: Resolver<
    ResolversTypes['QLStockOutConfig'],
    ParentType,
    ContextType
  >
  overrides?: Resolver<
    Maybe<Array<ResolversTypes['StockOutConfigOverride']>>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SavedTaxRateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SavedTaxRate'] = ResolversParentTypes['SavedTaxRate']
> = {
  __resolveReference?: ReferenceResolver<
    Maybe<ResolversTypes['SavedTaxRate']>,
    { __typename: 'SavedTaxRate' } & GraphQLRecursivePick<
      ParentType,
      { id: true }
    >,
    ContextType
  >
  archived?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  default?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  multiLocationId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  ownerRestaurantSet?: Resolver<
    ResolversTypes['RestaurantSet'],
    ParentType,
    ContextType
  >
  rateFixed?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  ratePercent?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  rateType?: Resolver<ResolversTypes['TaxRateType'], ParentType, ContextType>
  targetRestaurantSet?: Resolver<
    ResolversTypes['RestaurantSet'],
    ParentType,
    ContextType
  >
  versionId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SavedToastPayConfigErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SavedToastPayConfigError'] = ResolversParentTypes['SavedToastPayConfigError']
> = {
  code?: Resolver<
    ResolversTypes['SavedToastPayConfigErrorCode'],
    ParentType,
    ContextType
  >
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SavedToastPayConfigResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SavedToastPayConfigResponse'] = ResolversParentTypes['SavedToastPayConfigResponse']
> = {
  __resolveType: TypeResolveFn<
    'SavedToastPayConfigError' | 'ToastPayConfig',
    ParentType,
    ContextType
  >
}

export type ScheduleResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Schedule'] = ResolversParentTypes['Schedule']
> = {
  dayPeriods?: Resolver<
    Array<ResolversTypes['DayPeriod']>,
    ParentType,
    ContextType
  >
  diningOptionBehavior?: Resolver<
    ResolversTypes['DiningOptionBehavior'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SearchEntityResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SearchEntity'] = ResolversParentTypes['SearchEntity']
> = {
  __resolveType: TypeResolveFn<
    'MenuItem' | 'ToastCentralArticle' | 'ToastNavigationConfig' | 'User',
    ParentType,
    ContextType
  >
}

export type SearchItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SearchItem'] = ResolversParentTypes['SearchItem']
> = {
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  displayText?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  entity?: Resolver<
    Maybe<ResolversTypes['SearchEntity']>,
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  keywords?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>
  path?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  score?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  searchDataSource?: Resolver<
    ResolversTypes['SearchDataSource'],
    ParentType,
    ContextType
  >
  subTopics?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  topic?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SearchMutationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SearchMutation'] = ResolversParentTypes['SearchMutation']
> = {
  createUserInteractions?: Resolver<
    ResolversTypes['UserIteractionMutationResponse'],
    ParentType,
    ContextType,
    RequireFields<
      SearchMutationCreateUserInteractionsArgs,
      'interactionType' | 'itemId' | 'searchDataSource' | 'searchTerm'
    >
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SearchQueryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SearchQuery'] = ResolversParentTypes['SearchQuery']
> = {
  autoComplete?: Resolver<
    ResolversTypes['SearchResponse'],
    ParentType,
    ContextType,
    RequireFields<SearchQueryAutoCompleteArgs, 'q' | 'size' | 'sources'>
  >
  popular?: Resolver<
    ResolversTypes['PopularSearchResponse'],
    ParentType,
    ContextType,
    RequireFields<SearchQueryPopularArgs, 'size'>
  >
  search?: Resolver<
    ResolversTypes['SearchResponse'],
    ParentType,
    ContextType,
    RequireFields<
      SearchQuerySearchArgs,
      'ignoreForMetrics' | 'page' | 'pageSize' | 'q' | 'sources' | 'startIndex'
    >
  >
  searchSuggestion?: Resolver<
    ResolversTypes['SuggestionResponse'],
    ParentType,
    ContextType,
    RequireFields<SearchQuerySearchSuggestionArgs, 'q' | 'size'>
  >
  userInteractions?: Resolver<
    ResolversTypes['UserInteractionResponse'],
    ParentType,
    ContextType,
    RequireFields<
      SearchQueryUserInteractionsArgs,
      'interactionType' | 'size' | 'sources'
    >
  >
  userRecentSearches?: Resolver<
    ResolversTypes['RecentSearchesResponse'],
    ParentType,
    ContextType,
    RequireFields<SearchQueryUserRecentSearchesArgs, 'size'>
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SearchResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SearchResponse'] = ResolversParentTypes['SearchResponse']
> = {
  items?: Resolver<Array<ResolversTypes['SearchItem']>, ParentType, ContextType>
  totalHits?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SearchSuggestionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SearchSuggestion'] = ResolversParentTypes['SearchSuggestion']
> = {
  score?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SeasonalEmploymentStatusResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SeasonalEmploymentStatus'] = ResolversParentTypes['SeasonalEmploymentStatus']
> = {
  adjustedHireDate?: Resolver<
    Maybe<ResolversTypes['Date']>,
    ParentType,
    ContextType
  >
  hireDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Section'] = ResolversParentTypes['Section']
> = {
  groups?: Resolver<Array<ResolversTypes['Group']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  sectionSpa?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ServerColorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ServerColor'] = ResolversParentTypes['ServerColor']
> = {
  serverColor?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  textColor?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ServiceAreaResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ServiceArea'] = ResolversParentTypes['ServiceArea']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  tables?: Resolver<Array<ResolversTypes['MgmtTable']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ServiceAreaAvailabilityInfoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ServiceAreaAvailabilityInfo'] = ResolversParentTypes['ServiceAreaAvailabilityInfo']
> = {
  deposit?: Resolver<
    Maybe<ResolversTypes['DepositAvailabilityInfo']>,
    ParentType,
    ContextType
  >
  externalServiceArea?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  onlineReservationsDisabled?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  percentBookableOnline?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType
  >
  percentBooked?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  serviceAreaGroupInfo?: Resolver<
    Maybe<ResolversTypes['ServiceAreaGroupInfo']>,
    ParentType,
    ContextType
  >
  slotEnd?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  tables?: Resolver<
    Array<ResolversTypes['TableAvailabilityInfo']>,
    ParentType,
    ContextType
  >
  turnTime?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  violatesPercentBookableOnline?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ServiceAreaGroupInfoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ServiceAreaGroupInfo'] = ResolversParentTypes['ServiceAreaGroupInfo']
> = {
  enabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ServiceAreaNotFoundResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ServiceAreaNotFound'] = ResolversParentTypes['ServiceAreaNotFound']
> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  status?: Resolver<ResolversTypes['ErrorCode'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ServiceAreaResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ServiceAreaResponse'] = ResolversParentTypes['ServiceAreaResponse']
> = {
  results?: Resolver<
    Array<ResolversTypes['ServiceArea']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ServiceDataExtensionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ServiceDataExtension'] = ResolversParentTypes['ServiceDataExtension']
> = {
  service?: Resolver<
    ResolversTypes['OperatingScheduleService'],
    ParentType,
    ContextType
  >
  type?: Resolver<ResolversTypes['DataExtensionType'], ParentType, ContextType>
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ShiftAlreadyClockedOutResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ShiftAlreadyClockedOut'] = ResolversParentTypes['ShiftAlreadyClockedOut']
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ShiftCheckedResultRepositoryDependencyFailureResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ShiftCheckedResultRepositoryDependencyFailure'] = ResolversParentTypes['ShiftCheckedResultRepositoryDependencyFailure']
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ShiftErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ShiftError'] = ResolversParentTypes['ShiftError']
> = {
  __resolveType: TypeResolveFn<
    | 'AuthenticationFailure'
    | 'BreakConfigDependencyFailure'
    | 'BuildShiftFailure'
    | 'ClockOutFailure'
    | 'EmployeeDeleted'
    | 'EmployeeRepositoryDependencyFailure'
    | 'EntitlementsRepositoryDependencyFailure'
    | 'InvalidBreakConfig'
    | 'InvalidBreaks'
    | 'InvalidJob'
    | 'InvalidUpdatedTime'
    | 'JobsRepositoryDependencyFailure'
    | 'LoadShiftsFailure'
    | 'LocateShiftFailure'
    | 'MissingJob'
    | 'MissingTimeClock'
    | 'OverlappingBreaks'
    | 'OverlappingShiftExists'
    | 'PublishRepositoryDependencyFailure'
    | 'ReadFailure'
    | 'RestaurantRepositoryDependencyFailure'
    | 'SaveFailure'
    | 'ShiftAlreadyClockedOut'
    | 'ShiftCheckedResultRepositoryDependencyFailure'
    | 'ShiftNotFound'
    | 'Unauthorized'
    | 'UnknownError'
    | 'UpdateShiftFailure',
    ParentType,
    ContextType
  >
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
}

export type ShiftNotFoundResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ShiftNotFound'] = ResolversParentTypes['ShiftNotFound']
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ShiftReviewConfigResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ShiftReviewConfig'] = ResolversParentTypes['ShiftReviewConfig']
> = {
  declareCashTipsConfig?: Resolver<
    ResolversTypes['DeclareCashTipsConfig'],
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  reconciliationConfig?: Resolver<
    ResolversTypes['ReconciliationConfig'],
    ParentType,
    ContextType
  >
  shouldRequireCashDrawerLockdown?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  shouldRequireShiftReview?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  tipOutConfig?: Resolver<
    ResolversTypes['TipOutConfig'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ShiftV2EmployeeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ShiftV2Employee'] = ResolversParentTypes['ShiftV2Employee']
> = {
  displayFullName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  restaurantUsers?: Resolver<
    Array<ResolversTypes['RestaurantUser']>,
    ParentType,
    ContextType
  >
  shiftCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ShiftV2EmployeesResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ShiftV2EmployeesResponse'] = ResolversParentTypes['ShiftV2EmployeesResponse']
> = {
  employees?: Resolver<
    Array<ResolversTypes['ShiftV2Employee']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ShiftsV2PageInfoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ShiftsV2PageInfo'] = ResolversParentTypes['ShiftsV2PageInfo']
> = {
  endCursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  first?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  hasNextPage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  hasPreviousPage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  offset?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  startCursor?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SizePriceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SizePrice'] = ResolversParentTypes['SizePrice']
> = {
  basePrice?: Resolver<Maybe<ResolversTypes['Money']>, ParentType, ContextType>
  multiLocationId?: Resolver<
    Maybe<ResolversTypes['ID']>,
    ParentType,
    ContextType
  >
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  pricing?: Resolver<
    Maybe<ResolversTypes['MenuItemPricing']>,
    ParentType,
    ContextType
  >
  pricingStrategy?: Resolver<
    ResolversTypes['MenuItemPricingStrategy'],
    ParentType,
    ContextType
  >
  versionId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SlotAvailabilityInfoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SlotAvailabilityInfo'] = ResolversParentTypes['SlotAvailabilityInfo']
> = {
  blockedReason?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  bookingMaxHoursInAdvance?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType
  >
  bookingMinHoursInAdvance?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType
  >
  currentCovers?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  datetime?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  hostReservationsBlocked?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  maxCoversPerTimeslot?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType
  >
  notInSlotSize?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  onlineReservationsBlocked?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  onlineReservationsDisabled?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  serviceAreas?: Resolver<
    Array<ResolversTypes['ServiceAreaAvailabilityInfo']>,
    ParentType,
    ContextType
  >
  servicePeriodName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  servicePeriodOverrideName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  slotSize?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  tooFar?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  tooNear?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  violatesCoverFlow?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SmartQuoteAutoChangeStrategyResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SmartQuoteAutoChangeStrategyResponse'] = ResolversParentTypes['SmartQuoteAutoChangeStrategyResponse']
> = {
  strategy?: Resolver<
    ResolversTypes['SmartQuoteAutoChangeStrategy'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SmartQuoteConfigResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SmartQuoteConfig'] = ResolversParentTypes['SmartQuoteConfig']
> = {
  autoChangeStrategy?: Resolver<
    ResolversTypes['SmartQuoteAutoChangeStrategy'],
    ParentType,
    ContextType
  >
  reasonCode?: Resolver<
    Maybe<ResolversTypes['SmartQuoteConfigReasonCode']>,
    ParentType,
    ContextType
  >
  statusCode?: Resolver<
    ResolversTypes['SmartQuoteConfigStatus'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SmartQuoteMutationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SmartQuoteMutation'] = ResolversParentTypes['SmartQuoteMutation']
> = {
  activate?: Resolver<
    ResolversTypes['SmartQuoteConfig'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SmartQuoteQueryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SmartQuoteQuery'] = ResolversParentTypes['SmartQuoteQuery']
> = {
  config?: Resolver<ResolversTypes['SmartQuoteConfig'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SmartQuoteSimpleComparisonResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SmartQuoteSimpleComparison'] = ResolversParentTypes['SmartQuoteSimpleComparison']
> = {
  dateFrom?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  dateTo?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  ordersOnTimeImprovement?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >
  percentageEarlyOrdersCurrentStrategy?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >
  percentageEarlyOrdersSmartQuote?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >
  percentageLateOrdersCurrentStrategy?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >
  percentageLateOrdersSmartQuote?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >
  percentageOrdersOnTimeCurrentStrategy?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >
  percentageOrdersOnTimeSmartQuote?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >
  serviceSpeedCurrentStrategy?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >
  serviceSpeedSmartQuote?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SmartQuoteSimpleComparisonQueryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SmartQuoteSimpleComparisonQuery'] = ResolversParentTypes['SmartQuoteSimpleComparisonQuery']
> = {
  comparison?: Resolver<
    Maybe<ResolversTypes['SmartQuoteSimpleComparison']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SousChefActionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SousChefAction'] = ResolversParentTypes['SousChefAction']
> = {
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['SousChefActionType'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SousChefActionPlanResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SousChefActionPlan'] = ResolversParentTypes['SousChefActionPlan']
> = {
  actions?: Resolver<
    Array<ResolversTypes['SousChefAction']>,
    ParentType,
    ContextType
  >
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SousChefAdminMutationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SousChefAdminMutation'] = ResolversParentTypes['SousChefAdminMutation']
> = {
  createWorkflow?: Resolver<
    ResolversTypes['SousChefWorkflowDefinition'],
    ParentType,
    ContextType,
    RequireFields<SousChefAdminMutationCreateWorkflowArgs, 'question'>
  >
  saveWorkflow?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<SousChefAdminMutationSaveWorkflowArgs, 'workflowId'>
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SousChefAdminQueryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SousChefAdminQuery'] = ResolversParentTypes['SousChefAdminQuery']
> = {
  allDataPanels?: Resolver<
    Maybe<Array<ResolversTypes['SousChefDataPanel']>>,
    ParentType,
    ContextType
  >
  allDataSources?: Resolver<
    Maybe<Array<ResolversTypes['SousChefDataSource']>>,
    ParentType,
    ContextType
  >
  allDefinitions?: Resolver<
    Maybe<Array<ResolversTypes['SousChefDefinition']>>,
    ParentType,
    ContextType
  >
  allKnowledge?: Resolver<
    Maybe<Array<ResolversTypes['SousChefKnowledge']>>,
    ParentType,
    ContextType
  >
  dataPanel?: Resolver<
    Maybe<ResolversTypes['SousChefDataPanel']>,
    ParentType,
    ContextType,
    RequireFields<SousChefAdminQueryDataPanelArgs, 'id'>
  >
  dataSource?: Resolver<
    Maybe<ResolversTypes['SousChefDataSource']>,
    ParentType,
    ContextType,
    RequireFields<SousChefAdminQueryDataSourceArgs, 'id'>
  >
  definition?: Resolver<
    Maybe<ResolversTypes['SousChefDefinition']>,
    ParentType,
    ContextType,
    RequireFields<SousChefAdminQueryDefinitionArgs, 'id'>
  >
  knowledge?: Resolver<
    Maybe<ResolversTypes['SousChefKnowledge']>,
    ParentType,
    ContextType,
    RequireFields<SousChefAdminQueryKnowledgeArgs, 'id'>
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SousChefAlertResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SousChefAlert'] = ResolversParentTypes['SousChefAlert']
> = {
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  shareableText?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  source?: Resolver<
    ResolversTypes['SousChefInsightSource'],
    ParentType,
    ContextType
  >
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  value?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SousChefAnalysisNodeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SousChefAnalysisNode'] = ResolversParentTypes['SousChefAnalysisNode']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  instruction?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SousChefAutoClockedOutResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SousChefAutoClockedOut'] = ResolversParentTypes['SousChefAutoClockedOut']
> = {
  employee?: Resolver<ResolversTypes['RestaurantUser'], ParentType, ContextType>
  startTime?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SousChefAutoClockedOutDataPointResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SousChefAutoClockedOutDataPoint'] = ResolversParentTypes['SousChefAutoClockedOutDataPoint']
> = {
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  items?: Resolver<
    Array<ResolversTypes['SousChefAutoClockedOut']>,
    ParentType,
    ContextType
  >
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  shareableText?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  source?: Resolver<
    ResolversTypes['SousChefInsightSource'],
    ParentType,
    ContextType
  >
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SousChefChecklistItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SousChefChecklistItem'] = ResolversParentTypes['SousChefChecklistItem']
> = {
  insight?: Resolver<ResolversTypes['SousChefInsight'], ParentType, ContextType>
  viewed?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  viewedAt?: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SousChefCompletedThreadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SousChefCompletedThread'] = ResolversParentTypes['SousChefCompletedThread']
> = {
  actionPlans?: Resolver<
    Array<ResolversTypes['SousChefActionPlan']>,
    ParentType,
    ContextType
  >
  analysis?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  dataSources?: Resolver<
    Array<ResolversTypes['SousChefInsightSource']>,
    ParentType,
    ContextType
  >
  filters?: Resolver<
    Array<ResolversTypes['SousChefFilter']>,
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  relatedQuestions?: Resolver<
    Maybe<Array<ResolversTypes['String']>>,
    ParentType,
    ContextType
  >
  sequence?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  summary?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  thought?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  workflow?: Resolver<
    Maybe<ResolversTypes['Workflow']>,
    ParentType,
    ContextType
  >
  workflowTasks?: Resolver<
    Maybe<Array<ResolversTypes['SousChefWorkflowTask']>>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SousChefDataNodeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SousChefDataNode'] = ResolversParentTypes['SousChefDataNode']
> = {
  dataSources?: Resolver<
    Array<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  instruction?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SousChefDataPanelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SousChefDataPanel'] = ResolversParentTypes['SousChefDataPanel']
> = {
  data?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  questions?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SousChefDataPointResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SousChefDataPoint'] = ResolversParentTypes['SousChefDataPoint']
> = {
  __resolveType: TypeResolveFn<
    | 'SousChefAlert'
    | 'SousChefAutoClockedOutDataPoint'
    | 'SousChefEmptyDataPoint'
    | 'SousChefFinancialDataPoint'
    | 'SousChefPercentageDataPoint'
    | 'SousChefStockDataPoint'
    | 'SousChefTextDataPoint'
    | 'SousChefTrendDataPoint',
    ParentType,
    ContextType
  >
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  shareableText?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  source?: Resolver<
    ResolversTypes['SousChefInsightSource'],
    ParentType,
    ContextType
  >
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
}

export type SousChefDataSourceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SousChefDataSource'] = ResolversParentTypes['SousChefDataSource']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  panels?: Resolver<
    Array<Maybe<ResolversTypes['SousChefDataPanel']>>,
    ParentType,
    ContextType
  >
  prompt?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SousChefDateRangeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SousChefDateRange'] = ResolversParentTypes['SousChefDateRange']
> = {
  end?: Resolver<ResolversTypes['Date'], ParentType, ContextType>
  start?: Resolver<ResolversTypes['Date'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SousChefDateRangeFilterResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SousChefDateRangeFilter'] = ResolversParentTypes['SousChefDateRangeFilter']
> = {
  endDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>
  startDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SousChefDateTimeRangeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SousChefDateTimeRange'] = ResolversParentTypes['SousChefDateTimeRange']
> = {
  end?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  start?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SousChefDayOfWeekFilterResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SousChefDayOfWeekFilter'] = ResolversParentTypes['SousChefDayOfWeekFilter']
> = {
  days?: Resolver<
    Array<ResolversTypes['SousChefDayOfWeek']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SousChefDayPartResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SousChefDayPart'] = ResolversParentTypes['SousChefDayPart']
> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SousChefDayPartFilterResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SousChefDayPartFilter'] = ResolversParentTypes['SousChefDayPartFilter']
> = {
  dayParts?: Resolver<
    Array<ResolversTypes['SousChefDayPart']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SousChefDefinitionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SousChefDefinition'] = ResolversParentTypes['SousChefDefinition']
> = {
  definition?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  instruction?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  questions?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>
  term?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SousChefDiningOptionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SousChefDiningOption'] = ResolversParentTypes['SousChefDiningOption']
> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SousChefDiningOptionFilterResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SousChefDiningOptionFilter'] = ResolversParentTypes['SousChefDiningOptionFilter']
> = {
  diningOptions?: Resolver<
    Array<ResolversTypes['SousChefDiningOption']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SousChefEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SousChefEdge'] = ResolversParentTypes['SousChefEdge']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  sourceNodeId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  targetNodeId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SousChefEmployeeFilterResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SousChefEmployeeFilter'] = ResolversParentTypes['SousChefEmployeeFilter']
> = {
  employees?: Resolver<
    ResolversTypes['RestaurantUser'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SousChefEmptyDataPointResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SousChefEmptyDataPoint'] = ResolversParentTypes['SousChefEmptyDataPoint']
> = {
  canRetry?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  shareableText?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  source?: Resolver<
    ResolversTypes['SousChefInsightSource'],
    ParentType,
    ContextType
  >
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SousChefFilterResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SousChefFilter'] = ResolversParentTypes['SousChefFilter']
> = {
  __resolveType: TypeResolveFn<
    | 'SousChefDateRangeFilter'
    | 'SousChefDayOfWeekFilter'
    | 'SousChefDayPartFilter'
    | 'SousChefDiningOptionFilter'
    | 'SousChefEmployeeFilter'
    | 'SousChefHoursOfDayFilter'
    | 'SousChefIntervalFilter'
    | 'SousChefMenuFilter'
    | 'SousChefMenuGroupFilter'
    | 'SousChefOrderSourceFilter'
    | 'SousChefRevenueCenterFilter'
    | 'SousChefSalesCategoryFilter'
    | 'SousChefServiceAreaFilter',
    ParentType,
    ContextType
  >
}

export type SousChefFinancialDataResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SousChefFinancialData'] = ResolversParentTypes['SousChefFinancialData']
> = {
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  value?: Resolver<ResolversTypes['Money'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SousChefFinancialDataPointResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SousChefFinancialDataPoint'] = ResolversParentTypes['SousChefFinancialDataPoint']
> = {
  data?: Resolver<
    Array<ResolversTypes['SousChefFinancialData']>,
    ParentType,
    ContextType
  >
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  shareableText?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  source?: Resolver<
    ResolversTypes['SousChefInsightSource'],
    ParentType,
    ContextType
  >
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SousChefFixedResponseNodeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SousChefFixedResponseNode'] = ResolversParentTypes['SousChefFixedResponseNode']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  response?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SousChefHoursOfDayFilterResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SousChefHoursOfDayFilter'] = ResolversParentTypes['SousChefHoursOfDayFilter']
> = {
  end?: Resolver<ResolversTypes['LocalTime'], ParentType, ContextType>
  start?: Resolver<ResolversTypes['LocalTime'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SousChefInsightResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SousChefInsight'] = ResolversParentTypes['SousChefInsight']
> = {
  canAskFollowUp?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  data?: Resolver<
    Array<ResolversTypes['SousChefDataPoint']>,
    ParentType,
    ContextType
  >
  dataPoint?: Resolver<
    ResolversTypes['SousChefDataPoint'],
    ParentType,
    ContextType
  >
  filter?: Resolver<
    Maybe<ResolversTypes['SousChefInsightFilter']>,
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  relatedInsights?: Resolver<
    Maybe<Array<ResolversTypes['SousChefInsight']>>,
    ParentType,
    ContextType
  >
  source?: Resolver<
    ResolversTypes['SousChefInsightSource'],
    ParentType,
    ContextType
  >
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SousChefInsightFilterResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SousChefInsightFilter'] = ResolversParentTypes['SousChefInsightFilter']
> = {
  dateRange?: Resolver<
    Maybe<ResolversTypes['SousChefDateRange']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SousChefIntervalFilterResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SousChefIntervalFilter'] = ResolversParentTypes['SousChefIntervalFilter']
> = {
  interval?: Resolver<
    ResolversTypes['SousChefInterval'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SousChefKnowledgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SousChefKnowledge'] = ResolversParentTypes['SousChefKnowledge']
> = {
  content?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  instruction?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  questions?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SousChefMenuResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SousChefMenu'] = ResolversParentTypes['SousChefMenu']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SousChefMenuFilterResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SousChefMenuFilter'] = ResolversParentTypes['SousChefMenuFilter']
> = {
  menus?: Resolver<
    Array<ResolversTypes['SousChefMenu']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SousChefMenuGroupResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SousChefMenuGroup'] = ResolversParentTypes['SousChefMenuGroup']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SousChefMenuGroupFilterResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SousChefMenuGroupFilter'] = ResolversParentTypes['SousChefMenuGroupFilter']
> = {
  menuGroups?: Resolver<
    Array<ResolversTypes['SousChefMenuGroup']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SousChefMutationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SousChefMutation'] = ResolversParentTypes['SousChefMutation']
> = {
  deleteWorkflow?: Resolver<
    ResolversTypes['ID'],
    ParentType,
    ContextType,
    RequireFields<SousChefMutationDeleteWorkflowArgs, 'workflowId'>
  >
  feedback?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType,
    RequireFields<SousChefMutationFeedbackArgs, 'feedback'>
  >
  saveWorkflow?: Resolver<
    ResolversTypes['ID'],
    ParentType,
    ContextType,
    RequireFields<
      SousChefMutationSaveWorkflowArgs,
      'threadId' | 'threadSessionId'
    >
  >
  threadFeedback?: Resolver<
    ResolversTypes['ID'],
    ParentType,
    ContextType,
    RequireFields<SousChefMutationThreadFeedbackArgs, 'feedback'>
  >
  updateWorkflow?: Resolver<
    ResolversTypes['ID'],
    ParentType,
    ContextType,
    RequireFields<SousChefMutationUpdateWorkflowArgs, 'workflowId'>
  >
  updateWorkflowFromFollowUp?: Resolver<
    ResolversTypes['ID'],
    ParentType,
    ContextType,
    RequireFields<
      SousChefMutationUpdateWorkflowFromFollowUpArgs,
      'threadId' | 'threadSessionId'
    >
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SousChefOrderSourceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SousChefOrderSource'] = ResolversParentTypes['SousChefOrderSource']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SousChefOrderSourceFilterResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SousChefOrderSourceFilter'] = ResolversParentTypes['SousChefOrderSourceFilter']
> = {
  orderSources?: Resolver<
    Array<ResolversTypes['SousChefOrderSource']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SousChefOutOfStockItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SousChefOutOfStockItem'] = ResolversParentTypes['SousChefOutOfStockItem']
> = {
  menuItem?: Resolver<
    ResolversTypes['PublishedMenuItem'],
    ParentType,
    ContextType
  >
  modifiedTime?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SousChefPercentageDataResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SousChefPercentageData'] = ResolversParentTypes['SousChefPercentageData']
> = {
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  value?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SousChefPercentageDataPointResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SousChefPercentageDataPoint'] = ResolversParentTypes['SousChefPercentageDataPoint']
> = {
  data?: Resolver<
    Array<ResolversTypes['SousChefPercentageData']>,
    ParentType,
    ContextType
  >
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  shareableText?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  source?: Resolver<
    ResolversTypes['SousChefInsightSource'],
    ParentType,
    ContextType
  >
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SousChefQueryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SousChefQuery'] = ResolversParentTypes['SousChefQuery']
> = {
  dummyEndpoint?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  insight?: Resolver<
    Maybe<ResolversTypes['SousChefInsight']>,
    ParentType,
    ContextType,
    RequireFields<SousChefQueryInsightArgs, 'id'>
  >
  threadSession?: Resolver<
    Maybe<ResolversTypes['SousChefThreadSession']>,
    ParentType,
    ContextType,
    RequireFields<SousChefQueryThreadSessionArgs, 'sessionId'>
  >
  threadSessions?: Resolver<
    Maybe<Array<ResolversTypes['SousChefThreadSession']>>,
    ParentType,
    ContextType
  >
  workflows?: Resolver<
    Array<ResolversTypes['Workflow']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SousChefRevenueCenterResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SousChefRevenueCenter'] = ResolversParentTypes['SousChefRevenueCenter']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SousChefRevenueCenterFilterResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SousChefRevenueCenterFilter'] = ResolversParentTypes['SousChefRevenueCenterFilter']
> = {
  revenueCenters?: Resolver<
    Array<ResolversTypes['SousChefRevenueCenter']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SousChefSalesCategoryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SousChefSalesCategory'] = ResolversParentTypes['SousChefSalesCategory']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SousChefSalesCategoryFilterResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SousChefSalesCategoryFilter'] = ResolversParentTypes['SousChefSalesCategoryFilter']
> = {
  salesCategories?: Resolver<
    Array<ResolversTypes['SousChefSalesCategory']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SousChefServiceAreaResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SousChefServiceArea'] = ResolversParentTypes['SousChefServiceArea']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SousChefServiceAreaFilterResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SousChefServiceAreaFilter'] = ResolversParentTypes['SousChefServiceAreaFilter']
> = {
  serviceAreas?: Resolver<
    Array<ResolversTypes['SousChefServiceArea']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SousChefStockDataPointResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SousChefStockDataPoint'] = ResolversParentTypes['SousChefStockDataPoint']
> = {
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  items?: Resolver<
    Array<ResolversTypes['SousChefOutOfStockItem']>,
    ParentType,
    ContextType
  >
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  shareableText?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  source?: Resolver<
    ResolversTypes['SousChefInsightSource'],
    ParentType,
    ContextType
  >
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SousChefSuggestedThreadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SousChefSuggestedThread'] = ResolversParentTypes['SousChefSuggestedThread']
> = {
  dataSources?: Resolver<
    Array<ResolversTypes['SousChefInsightSource']>,
    ParentType,
    ContextType
  >
  summary?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SousChefSummaryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SousChefSummary'] = ResolversParentTypes['SousChefSummary']
> = {
  checklist?: Resolver<
    Array<ResolversTypes['SousChefChecklistItem']>,
    ParentType,
    ContextType
  >
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  date?: Resolver<ResolversTypes['Date'], ParentType, ContextType>
  highlights?: Resolver<
    Array<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  insights?: Resolver<
    Array<ResolversTypes['SousChefInsight']>,
    ParentType,
    ContextType
  >
  threadGroups?: Resolver<
    Array<ResolversTypes['SousChefThreadGroup']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SousChefTaskDataResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SousChefTaskData'] = ResolversParentTypes['SousChefTaskData']
> = {
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SousChefTextDataPointResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SousChefTextDataPoint'] = ResolversParentTypes['SousChefTextDataPoint']
> = {
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  shareableText?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  source?: Resolver<
    ResolversTypes['SousChefInsightSource'],
    ParentType,
    ContextType
  >
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SousChefThreadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SousChefThread'] = ResolversParentTypes['SousChefThread']
> = {
  __resolveType: TypeResolveFn<
    'SousChefCompletedThread' | 'SousChefSuggestedThread',
    ParentType,
    ContextType
  >
  dataSources?: Resolver<
    Array<ResolversTypes['SousChefInsightSource']>,
    ParentType,
    ContextType
  >
  summary?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
}

export type SousChefThreadGroupResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SousChefThreadGroup'] = ResolversParentTypes['SousChefThreadGroup']
> = {
  threads?: Resolver<
    Array<ResolversTypes['SousChefThread']>,
    ParentType,
    ContextType
  >
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  type?: Resolver<
    ResolversTypes['SousChefThreadGroupType'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SousChefThreadSessionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SousChefThreadSession'] = ResolversParentTypes['SousChefThreadSession']
> = {
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  summary?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  threads?: Resolver<
    Array<ResolversTypes['SousChefCompletedThread']>,
    ParentType,
    ContextType
  >
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SousChefTrendResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SousChefTrend'] = ResolversParentTypes['SousChefTrend']
> = {
  direction?: Resolver<
    ResolversTypes['SousChefTrendDirection'],
    ParentType,
    ContextType
  >
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  sentiment?: Resolver<
    ResolversTypes['SousChefTrendSentiment'],
    ParentType,
    ContextType
  >
  value?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SousChefTrendDataPointResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SousChefTrendDataPoint'] = ResolversParentTypes['SousChefTrendDataPoint']
> = {
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  shareableText?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  source?: Resolver<
    ResolversTypes['SousChefInsightSource'],
    ParentType,
    ContextType
  >
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  trends?: Resolver<
    Array<ResolversTypes['SousChefTrend']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SousChefWorkflowDefinitionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SousChefWorkflowDefinition'] = ResolversParentTypes['SousChefWorkflowDefinition']
> = {
  edges?: Resolver<
    Array<ResolversTypes['SousChefEdge']>,
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  nodes?: Resolver<
    Array<ResolversTypes['SousChefWorkflowNode']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SousChefWorkflowNodeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SousChefWorkflowNode'] = ResolversParentTypes['SousChefWorkflowNode']
> = {
  __resolveType: TypeResolveFn<
    'SousChefAnalysisNode' | 'SousChefDataNode' | 'SousChefFixedResponseNode',
    ParentType,
    ContextType
  >
}

export type SousChefWorkflowTaskResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SousChefWorkflowTask'] = ResolversParentTypes['SousChefWorkflowTask']
> = {
  children?: Resolver<Array<ResolversTypes['ID']>, ParentType, ContextType>
  dataPanels?: Resolver<
    Maybe<Array<ResolversTypes['SousChefDataPanel']>>,
    ParentType,
    ContextType
  >
  dataSources?: Resolver<
    Maybe<Array<ResolversTypes['SousChefDataSource']>>,
    ParentType,
    ContextType
  >
  definitions?: Resolver<
    Array<ResolversTypes['SousChefDefinition']>,
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  input?: Resolver<
    Array<ResolversTypes['SousChefTaskData']>,
    ParentType,
    ContextType
  >
  knowledge?: Resolver<
    Array<ResolversTypes['SousChefKnowledge']>,
    ParentType,
    ContextType
  >
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  nodeId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  output?: Resolver<
    Array<ResolversTypes['SousChefTaskData']>,
    ParentType,
    ContextType
  >
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type StaticPaymentDataExtensionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StaticPaymentDataExtension'] = ResolversParentTypes['StaticPaymentDataExtension']
> = {
  paymentType?: Resolver<
    ResolversTypes['StaticPaymentType'],
    ParentType,
    ContextType
  >
  type?: Resolver<ResolversTypes['DataExtensionType'], ParentType, ContextType>
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type StepResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Step'] = ResolversParentTypes['Step']
> = {
  children?: Resolver<Array<ResolversTypes['ID']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type StockResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Stock'] = ResolversParentTypes['Stock']
> = {
  quantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  status?: Resolver<ResolversTypes['StockStatus'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type StockDataConfigOverrideResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StockDataConfigOverride'] = ResolversParentTypes['StockDataConfigOverride']
> = {
  fields?: Resolver<
    Array<ResolversTypes['ConfigField']>,
    ParentType,
    ContextType
  >
  stockdataEnabled?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  target?: Resolver<ResolversTypes['RestaurantSet'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type StockOutConfigOverrideResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StockOutConfigOverride'] = ResolversParentTypes['StockOutConfigOverride']
> = {
  fields?: Resolver<
    Array<ResolversTypes['ConfigField']>,
    ParentType,
    ContextType
  >
  stockImageUrl?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  stockoutEnabled?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  target?: Resolver<ResolversTypes['RestaurantSet'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SubscriptionsCancellationRequestResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SubscriptionsCancellationRequest'] = ResolversParentTypes['SubscriptionsCancellationRequest']
> = {
  comment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  location?: Resolver<
    Maybe<ResolversTypes['SavedRestaurantLocation']>,
    ParentType,
    ContextType
  >
  reason?: Resolver<
    ResolversTypes['SubscriptionsCancellationReason'],
    ParentType,
    ContextType
  >
  salesforceCaseNumber?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  state?: Resolver<
    ResolversTypes['CancellationRequestState'],
    ParentType,
    ContextType
  >
  subscriptionSalesforceId?: Resolver<
    ResolversTypes['ID'],
    ParentType,
    ContextType
  >
  type?: Resolver<
    ResolversTypes['CancellationRequestType'],
    ParentType,
    ContextType
  >
  user?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SuggestionResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SuggestionResponse'] = ResolversParentTypes['SuggestionResponse']
> = {
  items?: Resolver<
    Array<ResolversTypes['SearchSuggestion']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SupportQueryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SupportQuery'] = ResolversParentTypes['SupportQuery']
> = {
  chatTranscript?: Resolver<
    Maybe<ResolversTypes['IntercomConversation']>,
    ParentType,
    ContextType,
    RequireFields<SupportQueryChatTranscriptArgs, 'chatId'>
  >
  intercomOptions?: Resolver<
    Maybe<ResolversTypes['IntercomOptions']>,
    ParentType,
    ContextType,
    RequireFields<SupportQueryIntercomOptionsArgs, 'clientType'>
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SystemIdentityResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SystemIdentity'] = ResolversParentTypes['SystemIdentity']
> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  version?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TableAvailabilityInfoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TableAvailabilityInfo'] = ResolversParentTypes['TableAvailabilityInfo']
> = {
  componentTables?: Resolver<
    Array<ResolversTypes['ID']>,
    ParentType,
    ContextType
  >
  existingBookings?: Resolver<
    Array<ResolversTypes['ID']>,
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  invalidPartySize?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  isBlocked?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  maxCapacity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  minCapacity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  onlineReservationsDisabled?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TableBlockedResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TableBlocked'] = ResolversParentTypes['TableBlocked']
> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  status?: Resolver<ResolversTypes['ErrorCode'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TableNotFoundResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TableNotFound'] = ResolversParentTypes['TableNotFound']
> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  status?: Resolver<ResolversTypes['ErrorCode'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TakeoutDeliveryCategoryPageResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TakeoutDeliveryCategoryPage'] = ResolversParentTypes['TakeoutDeliveryCategoryPage']
> = {
  productRecommendations?: Resolver<
    Array<Maybe<ResolversTypes['ProductRecommendation']>>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TakeoutDeliveryConfigResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TakeoutDeliveryConfig'] = ResolversParentTypes['TakeoutDeliveryConfig']
> = {
  deliveryEnabled?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  deliveryMode?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  deliveryToggleUIConfiguration?: Resolver<
    Maybe<ResolversTypes['DeliveryToggleUIConfiguration']>,
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  onlineOrderingDeliveryDiningOptionSelected?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  onlineOrderingEnabled?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  onlineOrderingModuleEnabled?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  onlineOrderingStatusLabel?: Resolver<
    ResolversTypes['OnlineOrderingStatusLabel'],
    ParentType,
    ContextType
  >
  onlineOrderingTakeoutDiningOptionSelected?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  onlineOrderingUrl?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  ordersApiModuleEnabled?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  snoozeUntilTime?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  takeoutEnabled?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  tdsDeliveryEnabled?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  timeZone?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  toastShopAccess?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TaskResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Task'] = ResolversParentTypes['Task']
> = {
  availableActions?: Resolver<
    Array<ResolversTypes['TaskAction']>,
    ParentType,
    ContextType
  >
  badgeText?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  internalOnly?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  keywords?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>
  metadata?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  newTab?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  path?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  pendoId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  showBetaTag?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  taskType?: Resolver<ResolversTypes['TaskType'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TaskActionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TaskAction'] = ResolversParentTypes['TaskAction']
> = {
  action?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TaxRateDataExtensionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TaxRateDataExtension'] = ResolversParentTypes['TaxRateDataExtension']
> = {
  taxRate?: Resolver<ResolversTypes['SavedTaxRate'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['DataExtensionType'], ParentType, ContextType>
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TerminalHandheldResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TerminalHandheld'] = ResolversParentTypes['TerminalHandheld']
> = {
  androidOsLevel?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  deviceDetails?: Resolver<
    Maybe<ResolversTypes['DeviceDetails']>,
    ParentType,
    ContextType
  >
  deviceId?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  deviceType?: Resolver<ResolversTypes['DeviceType'], ParentType, ContextType>
  firmwareVersion?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  isAutoFirer?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  isPciCompliant?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  isToastUser?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  lastUpdate?: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >
  model?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  modelName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  networks?: Resolver<
    Maybe<Array<ResolversTypes['DeviceNetworkDetails']>>,
    ParentType,
    ContextType
  >
  pciNonComplianceReason?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  photo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  posAppVersion?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  primaryMode?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  restaurant?: Resolver<
    ResolversTypes['SavedRestaurantLocation'],
    ParentType,
    ContextType
  >
  securityPatchVersion?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  serial?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  sync?: Resolver<
    Maybe<ResolversTypes['DeviceSyncDetails']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TerminatedEmploymentStatusResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TerminatedEmploymentStatus'] = ResolversParentTypes['TerminatedEmploymentStatus']
> = {
  adjustedHireDate?: Resolver<
    Maybe<ResolversTypes['Date']>,
    ParentType,
    ContextType
  >
  hireDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  lastDayOfEmployment?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  lastDayToReceiveBenefits?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ThirdPartyMockMutationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ThirdPartyMockMutation'] = ResolversParentTypes['ThirdPartyMockMutation']
> = {
  clearEvents?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<ThirdPartyMockMutationClearEventsArgs, 'provider'>
  >
  logEvent?: Resolver<
    Array<ResolversTypes['ThirdPartyMockProviderEvent']>,
    ParentType,
    ContextType,
    RequireFields<ThirdPartyMockMutationLogEventArgs, 'event'>
  >
  placeThirdPartyMockOrder?: Resolver<
    ResolversTypes['PlaceThirdPartyMockOrderResponse'],
    ParentType,
    ContextType,
    RequireFields<
      ThirdPartyMockMutationPlaceThirdPartyMockOrderArgs,
      'channelGuid' | 'provider'
    >
  >
  updateDoorDashMockOnboardingConfiguration?: Resolver<
    ResolversTypes['DoorDashMockOnboardingConfiguration'],
    ParentType,
    ContextType,
    RequireFields<
      ThirdPartyMockMutationUpdateDoorDashMockOnboardingConfigurationArgs,
      'configuration'
    >
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ThirdPartyMockProviderEventResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ThirdPartyMockProviderEvent'] = ResolversParentTypes['ThirdPartyMockProviderEvent']
> = {
  data?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  eventType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  provider?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  restaurant?: Resolver<
    ResolversTypes['SavedRestaurantLocation'],
    ParentType,
    ContextType
  >
  timestamp?: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ThirdPartyMockQueryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ThirdPartyMockQuery'] = ResolversParentTypes['ThirdPartyMockQuery']
> = {
  getDoorDashMockOnboardingConfiguration?: Resolver<
    ResolversTypes['DoorDashMockOnboardingConfiguration'],
    ParentType,
    ContextType,
    Partial<ThirdPartyMockQueryGetDoorDashMockOnboardingConfigurationArgs>
  >
  getEventLog?: Resolver<
    Array<ResolversTypes['ThirdPartyMockProviderEvent']>,
    ParentType,
    ContextType,
    RequireFields<ThirdPartyMockQueryGetEventLogArgs, 'provider'>
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ThirdPartyOrderingOnboardingFlowResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ThirdPartyOrderingOnboardingFlow'] = ResolversParentTypes['ThirdPartyOrderingOnboardingFlow']
> = {
  provider?: Resolver<
    ResolversTypes['ThirdPartyOrderingOnboardingProvider'],
    ParentType,
    ContextType
  >
  restaurantGuid?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  tasks?: Resolver<
    Array<ResolversTypes['ThirdPartyOrderingOnboardingTask']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ThirdPartyOrderingOnboardingMutationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ThirdPartyOrderingOnboardingMutation'] = ResolversParentTypes['ThirdPartyOrderingOnboardingMutation']
> = {
  activateDoorDashIntegration?: Resolver<
    ResolversTypes['DoorDashOnboardingActivateIntegrationResponse'],
    ParentType,
    ContextType,
    RequireFields<
      ThirdPartyOrderingOnboardingMutationActivateDoorDashIntegrationArgs,
      'onboardingId'
    >
  >
  createThirdPartyOrderingOnboardingFlow?: Resolver<
    ResolversTypes['ThirdPartyOrderingOnboardingFlow'],
    ParentType,
    ContextType,
    RequireFields<
      ThirdPartyOrderingOnboardingMutationCreateThirdPartyOrderingOnboardingFlowArgs,
      'provider'
    >
  >
  fastTrackThirdPartyIntegration?: Resolver<
    Maybe<Array<ResolversTypes['ThirdPartyOrderingSavedMenuAuditSetting']>>,
    ParentType,
    ContextType,
    RequireFields<
      ThirdPartyOrderingOnboardingMutationFastTrackThirdPartyIntegrationArgs,
      'provider'
    >
  >
  fastTrackThirdPartyIntegrationList?: Resolver<
    Array<
      Maybe<Array<ResolversTypes['ThirdPartyOrderingSavedMenuAuditSetting']>>
    >,
    ParentType,
    ContextType,
    RequireFields<
      ThirdPartyOrderingOnboardingMutationFastTrackThirdPartyIntegrationListArgs,
      'fastTrackRequests'
    >
  >
  netNewDoorDashOnboarding?: Resolver<
    ResolversTypes['DoorDashNetNewOnboardingResponse'],
    ParentType,
    ContextType,
    RequireFields<
      ThirdPartyOrderingOnboardingMutationNetNewDoorDashOnboardingArgs,
      'netNewOnboardingRequest'
    >
  >
  resetTasksForContractResubmission?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<
      ThirdPartyOrderingOnboardingMutationResetTasksForContractResubmissionArgs,
      'provider'
    >
  >
  runThirdPartyOrderingMenuAudit?: Resolver<
    Maybe<Array<ResolversTypes['ThirdPartyOrderingSavedMenuAuditSetting']>>,
    ParentType,
    ContextType,
    RequireFields<
      ThirdPartyOrderingOnboardingMutationRunThirdPartyOrderingMenuAuditArgs,
      'provider' | 'shouldPublishChanges'
    >
  >
  setThirdPartyOrderingOnboardingTaskCompleted?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<
      ThirdPartyOrderingOnboardingMutationSetThirdPartyOrderingOnboardingTaskCompletedArgs,
      'provider' | 'taskId'
    >
  >
  setThirdPartyOrderingOnboardingTaskInProgress?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<
      ThirdPartyOrderingOnboardingMutationSetThirdPartyOrderingOnboardingTaskInProgressArgs,
      'provider' | 'taskId'
    >
  >
  startDoorDashOnboarding?: Resolver<
    ResolversTypes['DoorDashOnboardingIdResponse'],
    ParentType,
    ContextType,
    RequireFields<
      ThirdPartyOrderingOnboardingMutationStartDoorDashOnboardingArgs,
      'onboardingRequest'
    >
  >
  updateThirdPartyOrderingOnboardingFlow?: Resolver<
    ResolversTypes['ThirdPartyOrderingOnboardingFlow'],
    ParentType,
    ContextType,
    RequireFields<
      ThirdPartyOrderingOnboardingMutationUpdateThirdPartyOrderingOnboardingFlowArgs,
      'provider' | 'thirdPartyOrderingOnboardingFlowInput'
    >
  >
  updateThirdPartyOrderingOnboardingFlowTask?: Resolver<
    ResolversTypes['ThirdPartyOrderingOnboardingFlow'],
    ParentType,
    ContextType,
    RequireFields<
      ThirdPartyOrderingOnboardingMutationUpdateThirdPartyOrderingOnboardingFlowTaskArgs,
      'provider' | 'task'
    >
  >
  updateThirdPartyPartnerAccess?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<
      ThirdPartyOrderingOnboardingMutationUpdateThirdPartyPartnerAccessArgs,
      'provider'
    >
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ThirdPartyOrderingOnboardingQueryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ThirdPartyOrderingOnboardingQuery'] = ResolversParentTypes['ThirdPartyOrderingOnboardingQuery']
> = {
  doorDashOnboardingStatus?: Resolver<
    ResolversTypes['DoorDashOnboardingStatusResponse'],
    ParentType,
    ContextType,
    RequireFields<
      ThirdPartyOrderingOnboardingQueryDoorDashOnboardingStatusArgs,
      'onboardingId'
    >
  >
  doorDashOnboardingStores?: Resolver<
    ResolversTypes['DoorDashOnboardingStores'],
    ParentType,
    ContextType,
    RequireFields<
      ThirdPartyOrderingOnboardingQueryDoorDashOnboardingStoresArgs,
      'provider'
    >
  >
  thirdPartyOrderingOnboardingFlow?: Resolver<
    ResolversTypes['ThirdPartyOrderingOnboardingFlow'],
    ParentType,
    ContextType,
    RequireFields<
      ThirdPartyOrderingOnboardingQueryThirdPartyOrderingOnboardingFlowArgs,
      'provider'
    >
  >
  thirdPartyOrderingOnboardingTask?: Resolver<
    ResolversTypes['ThirdPartyOrderingOnboardingTaskResult'],
    ParentType,
    ContextType,
    RequireFields<
      ThirdPartyOrderingOnboardingQueryThirdPartyOrderingOnboardingTaskArgs,
      'provider' | 'taskId'
    >
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ThirdPartyOrderingOnboardingTaskResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ThirdPartyOrderingOnboardingTask'] = ResolversParentTypes['ThirdPartyOrderingOnboardingTask']
> = {
  data?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  lastUpdated?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  taskId?: Resolver<
    ResolversTypes['ThirdPartyOrderingOnboardingTaskId'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ThirdPartyOrderingOnboardingTaskResultResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ThirdPartyOrderingOnboardingTaskResult'] = ResolversParentTypes['ThirdPartyOrderingOnboardingTaskResult']
> = {
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  status?: Resolver<
    ResolversTypes['ThirdPartyOrderingOnboardingTaskResultStatus'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ThirdPartyOrderingSavedMenuAuditSettingResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ThirdPartyOrderingSavedMenuAuditSetting'] = ResolversParentTypes['ThirdPartyOrderingSavedMenuAuditSetting']
> = {
  entityType?: Resolver<
    ResolversTypes['ThirdPartyOrderingSavedMenuAuditEntityType'],
    ParentType,
    ContextType
  >
  multiLocationID?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  settingType?: Resolver<
    ResolversTypes['ThirdPartyOrderingSavedMenuAuditSettingType'],
    ParentType,
    ContextType
  >
  versionId?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TipDetailsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TipDetails'] = ResolversParentTypes['TipDetails']
> = {
  amount?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  date?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TipOutConfigResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TipOutConfig'] = ResolversParentTypes['TipOutConfig']
> = {
  isToastTipsManagerEnabled?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  shouldTipOutGratuity?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  shouldTipOutNonCash?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TipSharingConfigResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TipSharingConfig'] = ResolversParentTypes['TipSharingConfig']
> = {
  salesCategories?: Resolver<
    Array<ResolversTypes['SalesCategory']>,
    ParentType,
    ContextType
  >
  tipSharingPercentages?: Resolver<
    Array<ResolversTypes['TipSharingPercentage']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TipSharingPercentageResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TipSharingPercentage'] = ResolversParentTypes['TipSharingPercentage']
> = {
  description?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  percentage?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  salesCategories?: Resolver<
    Array<ResolversTypes['SalesCategory']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TipsQueryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TipsQuery'] = ResolversParentTypes['TipsQuery']
> = {
  tips?: Resolver<ResolversTypes['GetTipsResponse'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ToastCentralArticleResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ToastCentralArticle'] = ResolversParentTypes['ToastCentralArticle']
> = {
  __resolveReference?: ReferenceResolver<
    Maybe<ResolversTypes['ToastCentralArticle']>,
    { __typename: 'ToastCentralArticle' } & GraphQLRecursivePick<
      ParentType,
      { id: true }
    >,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ToastNavigationConfigResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ToastNavigationConfig'] = ResolversParentTypes['ToastNavigationConfig']
> = {
  __resolveReference?: ReferenceResolver<
    Maybe<ResolversTypes['ToastNavigationConfig']>,
    { __typename: 'ToastNavigationConfig' } & GraphQLRecursivePick<
      ParentType,
      { id: true }
    >,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ToastNowDeviceRegistryMutationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ToastNowDeviceRegistryMutation'] = ResolversParentTypes['ToastNowDeviceRegistryMutation']
> = {
  deleteDeviceToken?: Resolver<
    Maybe<ResolversTypes['ToastNowDeviceToken']>,
    ParentType,
    ContextType,
    RequireFields<
      ToastNowDeviceRegistryMutationDeleteDeviceTokenArgs,
      'deviceUUID'
    >
  >
  updateDeviceToken?: Resolver<
    ResolversTypes['ToastNowDeviceToken'],
    ParentType,
    ContextType,
    RequireFields<
      ToastNowDeviceRegistryMutationUpdateDeviceTokenArgs,
      'updateDeviceTokenInput'
    >
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ToastNowDeviceRegistryQueryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ToastNowDeviceRegistryQuery'] = ResolversParentTypes['ToastNowDeviceRegistryQuery']
> = {
  getDeviceTokens?: Resolver<
    Array<Maybe<ResolversTypes['ToastNowDeviceToken']>>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ToastNowDeviceTokenResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ToastNowDeviceToken'] = ResolversParentTypes['ToastNowDeviceToken']
> = {
  apnsToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  deviceUUID?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  fcmToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  platform?: Resolver<
    ResolversTypes['ToastNowDevicePlatform'],
    ParentType,
    ContextType
  >
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  userUUID?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ToastPayConfigResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ToastPayConfig'] = ResolversParentTypes['ToastPayConfig']
> = {
  qrCodeOnReceiptEnabled?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ToastPayPaymentNotificationConfigResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ToastPayPaymentNotificationConfig'] = ResolversParentTypes['ToastPayPaymentNotificationConfig']
> = {
  assignedServerNotifiedOnly?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  notificationsEnabled?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  paymentNotificationsExclusiveOnToastGo?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ToastPayPaymentNotificationConfigErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ToastPayPaymentNotificationConfigError'] = ResolversParentTypes['ToastPayPaymentNotificationConfigError']
> = {
  code?: Resolver<
    Maybe<ResolversTypes['ToastPayPaymentNotificationConfigErrorCode']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ToastPayPaymentNotificationConfigResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ToastPayPaymentNotificationConfigResponse'] = ResolversParentTypes['ToastPayPaymentNotificationConfigResponse']
> = {
  __resolveType: TypeResolveFn<
    | 'ToastPayPaymentNotificationConfig'
    | 'ToastPayPaymentNotificationConfigError',
    ParentType,
    ContextType
  >
}

export type ToastPayRelatedConfigurationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ToastPayRelatedConfiguration'] = ResolversParentTypes['ToastPayRelatedConfiguration']
> = {
  paymentNotificationsConfig?: Resolver<
    ResolversTypes['ToastPayPaymentNotificationConfigResponse'],
    ParentType,
    ContextType
  >
  restaurantLogo?: Resolver<
    ResolversTypes['ToastPayRestaurantLogoResponse'],
    ParentType,
    ContextType
  >
  tipSelectionsConfig?: Resolver<
    ResolversTypes['ToastPayTipSelectionsConfigResponse'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ToastPayRestaurantLogoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ToastPayRestaurantLogo'] = ResolversParentTypes['ToastPayRestaurantLogo']
> = {
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ToastPayRestaurantLogoErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ToastPayRestaurantLogoError'] = ResolversParentTypes['ToastPayRestaurantLogoError']
> = {
  code?: Resolver<
    ResolversTypes['ToastPayRestaurantLogoErrorCode'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ToastPayRestaurantLogoResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ToastPayRestaurantLogoResponse'] = ResolversParentTypes['ToastPayRestaurantLogoResponse']
> = {
  __resolveType: TypeResolveFn<
    'ToastPayRestaurantLogo' | 'ToastPayRestaurantLogoError',
    ParentType,
    ContextType
  >
}

export type ToastPayTipSelectionsConfigResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ToastPayTipSelectionsConfig'] = ResolversParentTypes['ToastPayTipSelectionsConfig']
> = {
  defaultPercent?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >
  percentages?: Resolver<
    Array<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ToastPayTipSelectionsConfigResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ToastPayTipSelectionsConfigResponse'] = ResolversParentTypes['ToastPayTipSelectionsConfigResponse']
> = {
  __resolveType: TypeResolveFn<
    'ToastPayTipSelectionsConfig' | 'ToastPayTipSelectionsError',
    ParentType,
    ContextType
  >
}

export type ToastPayTipSelectionsErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ToastPayTipSelectionsError'] = ResolversParentTypes['ToastPayTipSelectionsError']
> = {
  code?: Resolver<
    ResolversTypes['ToastPayTipSelectionsErrorCode'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ToastPermissionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ToastPermission'] = ResolversParentTypes['ToastPermission']
> = {
  bitmask?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  permission?: Resolver<ResolversTypes['Permission'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TopSellingItemConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TopSellingItemConnection'] = ResolversParentTypes['TopSellingItemConnection']
> = {
  edges?: Resolver<
    Array<ResolversTypes['TopSellingItemEdge']>,
    ParentType,
    ContextType
  >
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>
  totalItemCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TopSellingItemEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TopSellingItemEdge'] = ResolversParentTypes['TopSellingItemEdge']
> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  node?: Resolver<ResolversTypes['ItemMetrics'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TopSellingModifierConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TopSellingModifierConnection'] = ResolversParentTypes['TopSellingModifierConnection']
> = {
  edges?: Resolver<
    Array<ResolversTypes['TopSellingModifierEdge']>,
    ParentType,
    ContextType
  >
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>
  totalModifierCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TopSellingModifierEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TopSellingModifierEdge'] = ResolversParentTypes['TopSellingModifierEdge']
> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  node?: Resolver<ResolversTypes['ModifierMetrics'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TotalRefundAmountResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TotalRefundAmount'] = ResolversParentTypes['TotalRefundAmount']
> = {
  grouped?: Resolver<
    Maybe<Array<ResolversTypes['GroupedTotalRefundAmount']>>,
    ParentType,
    ContextType,
    RequireFields<TotalRefundAmountGroupedArgs, 'by'>
  >
  totals?: Resolver<ResolversTypes['MoneyMetric'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TrialEligibilityProbeResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TrialEligibilityProbeResponse'] = ResolversParentTypes['TrialEligibilityProbeResponse']
> = {
  status?: Resolver<
    ResolversTypes['TrialOperationStatus'],
    ParentType,
    ContextType
  >
  trialList?: Resolver<
    Maybe<Array<ResolversTypes['TrialProbe']>>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TrialEntityResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TrialEntity'] = ResolversParentTypes['TrialEntity']
> = {
  allotment?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  allotmentUsage?: Resolver<
    Array<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  auditLog?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>
  createdTime?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  ecommSku?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  entitledLocations?: Resolver<
    Array<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  entityId?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  entityType?: Resolver<
    ResolversTypes['TrialEntityType'],
    ParentType,
    ContextType
  >
  expirationTime?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  featureGroupId?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  state?: Resolver<ResolversTypes['SubscriptionState'], ParentType, ContextType>
  trialId?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  userGuid?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  version?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TrialFeedbackResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TrialFeedbackResponse'] = ResolversParentTypes['TrialFeedbackResponse']
> = {
  status?: Resolver<
    ResolversTypes['TrialOperationStatus'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TrialMutationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TrialMutation'] = ResolversParentTypes['TrialMutation']
> = {
  addTrialFeedback?: Resolver<
    ResolversTypes['TrialFeedbackResponse'],
    ParentType,
    ContextType,
    RequireFields<TrialMutationAddTrialFeedbackArgs, 'request'>
  >
  cancelTrial?: Resolver<
    ResolversTypes['TrialMutationResponse'],
    ParentType,
    ContextType,
    RequireFields<TrialMutationCancelTrialArgs, 'request'>
  >
  createTrial?: Resolver<
    ResolversTypes['TrialMutationResponse'],
    ParentType,
    ContextType,
    RequireFields<TrialMutationCreateTrialArgs, 'request'>
  >
  upsellTrial?: Resolver<
    ResolversTypes['TrialMutationResponse'],
    ParentType,
    ContextType,
    RequireFields<TrialMutationUpsellTrialArgs, 'request'>
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TrialMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TrialMutationResponse'] = ResolversParentTypes['TrialMutationResponse']
> = {
  status?: Resolver<
    ResolversTypes['TrialOperationStatus'],
    ParentType,
    ContextType
  >
  trial?: Resolver<
    Maybe<ResolversTypes['TrialEntity']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TrialProbeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TrialProbe'] = ResolversParentTypes['TrialProbe']
> = {
  allotment?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  ecommSku?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  featureGroupId?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  permission?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  qualifyingLocations?: Resolver<
    Maybe<Array<ResolversTypes['String']>>,
    ParentType,
    ContextType
  >
  trialDurationDays?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TrialsQueryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TrialsQuery'] = ResolversParentTypes['TrialsQuery']
> = {
  orderValidity?: Resolver<
    ResolversTypes['OrderValidityCheckResponse'],
    ParentType,
    ContextType,
    RequireFields<TrialsQueryOrderValidityArgs, 'featureGroupId'>
  >
  probe?: Resolver<
    ResolversTypes['TrialEligibilityProbeResponse'],
    ParentType,
    ContextType
  >
  trials?: Resolver<
    ResolversTypes['GetTrialsResponse'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UiOptionInvalidEntityResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UIOptionInvalidEntity'] = ResolversParentTypes['UIOptionInvalidEntity']
> = {
  __resolveType: TypeResolveFn<
    'Menu' | 'MenuGroup' | 'MenuItem' | 'SavedRevenueCenter',
    ParentType,
    ContextType
  >
}

export type UiOptionInvalidSmartTaxResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UIOptionInvalidSmartTax'] = ResolversParentTypes['UIOptionInvalidSmartTax']
> = {
  invalidEntities?: Resolver<
    Array<ResolversTypes['UIOptionInvalidEntity']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UiOptionsMutationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UIOptionsMutation'] = ResolversParentTypes['UIOptionsMutation']
> = {
  updateSavedUIOption?: Resolver<
    ResolversTypes['MlmUIOption'],
    ParentType,
    ContextType,
    RequireFields<UiOptionsMutationUpdateSavedUiOptionArgs, 'input'>
  >
  updateSavedUIOptionV2?: Resolver<
    ResolversTypes['UpdateSavedUIOptionResponse'],
    ParentType,
    ContextType,
    RequireFields<UiOptionsMutationUpdateSavedUiOptionV2Args, 'input'>
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UiOptionsQueryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UIOptionsQuery'] = ResolversParentTypes['UIOptionsQuery']
> = {
  savedUIOption?: Resolver<
    ResolversTypes['MlmUIOption'],
    ParentType,
    ContextType,
    Partial<UiOptionsQuerySavedUiOptionArgs>
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UiOptionsTaxOptionsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UIOptionsTaxOptions'] = ResolversParentTypes['UIOptionsTaxOptions']
> = {
  receiptTaxLabel?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  splitTaxRatesEnabled?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UiOptionsTipSetupResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UIOptionsTipSetup'] = ResolversParentTypes['UIOptionsTipSetup']
> = {
  amounts?: Resolver<
    Maybe<ResolversTypes['UIOptionsTippingAmounts']>,
    ParentType,
    ContextType
  >
  displayMode?: Resolver<
    Maybe<ResolversTypes['UIOptionsTippingDisplayMode']>,
    ParentType,
    ContextType
  >
  percentages?: Resolver<
    Maybe<ResolversTypes['UIOptionsTippingPercentages']>,
    ParentType,
    ContextType
  >
  thresholdAmount?: Resolver<
    Maybe<ResolversTypes['Money']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UiOptionsTippingAmountsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UIOptionsTippingAmounts'] = ResolversParentTypes['UIOptionsTippingAmounts']
> = {
  amount1?: Resolver<Maybe<ResolversTypes['Money']>, ParentType, ContextType>
  amount2?: Resolver<Maybe<ResolversTypes['Money']>, ParentType, ContextType>
  amount3?: Resolver<Maybe<ResolversTypes['Money']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UiOptionsTippingPercentagesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UIOptionsTippingPercentages'] = ResolversParentTypes['UIOptionsTippingPercentages']
> = {
  percentage1?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >
  percentage2?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >
  percentage3?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UiOptionsTippingRequireApprovalResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UIOptionsTippingRequireApproval'] = ResolversParentTypes['UIOptionsTippingRequireApproval']
> = {
  enabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  minimumAmount?: Resolver<
    Maybe<ResolversTypes['Money']>,
    ParentType,
    ContextType
  >
  minimumPercentage?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UkCustomerBillingInvoiceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UKCustomerBillingInvoice'] = ResolversParentTypes['UKCustomerBillingInvoice']
> = {
  __resolveReference?: ReferenceResolver<
    Maybe<ResolversTypes['UKCustomerBillingInvoice']>,
    { __typename: 'UKCustomerBillingInvoice' } & GraphQLRecursivePick<
      ParentType,
      { id: true; restaurant: { id: true } }
    >,
    ContextType
  >
  billingAddress?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  createdDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>
  deductions?: Resolver<
    ResolversTypes['CustomerBillingInvoiceDeductions'],
    ParentType,
    ContextType
  >
  dueDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  items?: Resolver<
    Array<ResolversTypes['UKCustomerBillingInvoiceItem']>,
    ParentType,
    ContextType
  >
  netsuiteId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  orderType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  restaurant?: Resolver<
    Maybe<ResolversTypes['Restaurant']>,
    ParentType,
    ContextType
  >
  salesforceId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  shipping?: Resolver<
    ResolversTypes['CustomerBillingInvoiceShipping'],
    ParentType,
    ContextType
  >
  shippingAddress?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  status?: Resolver<
    ResolversTypes['CustomerBillingInvoiceStatus'],
    ParentType,
    ContextType
  >
  terms?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  totals?: Resolver<
    ResolversTypes['CustomerBillingInvoiceTotals'],
    ParentType,
    ContextType
  >
  transactionDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>
  type?: Resolver<
    ResolversTypes['CustomerBillingInvoiceType'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UkCustomerBillingInvoiceItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UKCustomerBillingInvoiceItem'] = ResolversParentTypes['UKCustomerBillingInvoiceItem']
> = {
  amount?: Resolver<ResolversTypes['Money'], ParentType, ContextType>
  children?: Resolver<
    Array<ResolversTypes['UKCustomerBillingInvoiceItem']>,
    ParentType,
    ContextType
  >
  endDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  parentProduct?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  quantity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  rate?: Resolver<ResolversTypes['Money'], ParentType, ContextType>
  startDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>
  taxAmount?: Resolver<ResolversTypes['Money'], ParentType, ContextType>
  taxRate?: Resolver<ResolversTypes['Money'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UsCustomerBillingInvoiceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['USCustomerBillingInvoice'] = ResolversParentTypes['USCustomerBillingInvoice']
> = {
  __resolveReference?: ReferenceResolver<
    Maybe<ResolversTypes['USCustomerBillingInvoice']>,
    { __typename: 'USCustomerBillingInvoice' } & GraphQLRecursivePick<
      ParentType,
      { id: true; restaurant: { id: true } }
    >,
    ContextType
  >
  billingAddress?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  createdDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>
  deductions?: Resolver<
    ResolversTypes['CustomerBillingInvoiceDeductions'],
    ParentType,
    ContextType
  >
  dueDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  items?: Resolver<
    Array<ResolversTypes['USCustomerBillingInvoiceItem']>,
    ParentType,
    ContextType
  >
  netsuiteId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  orderType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  restaurant?: Resolver<
    Maybe<ResolversTypes['Restaurant']>,
    ParentType,
    ContextType
  >
  salesforceId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  shipping?: Resolver<
    ResolversTypes['CustomerBillingInvoiceShipping'],
    ParentType,
    ContextType
  >
  shippingAddress?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  status?: Resolver<
    ResolversTypes['CustomerBillingInvoiceStatus'],
    ParentType,
    ContextType
  >
  terms?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  totals?: Resolver<
    ResolversTypes['CustomerBillingInvoiceTotals'],
    ParentType,
    ContextType
  >
  transactionDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>
  type?: Resolver<
    ResolversTypes['CustomerBillingInvoiceType'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UsCustomerBillingInvoiceItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['USCustomerBillingInvoiceItem'] = ResolversParentTypes['USCustomerBillingInvoiceItem']
> = {
  amount?: Resolver<ResolversTypes['Money'], ParentType, ContextType>
  children?: Resolver<
    Array<ResolversTypes['USCustomerBillingInvoiceItem']>,
    ParentType,
    ContextType
  >
  endDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  parentProduct?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  quantity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  rate?: Resolver<ResolversTypes['Money'], ParentType, ContextType>
  startDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>
  taxAmount?: Resolver<ResolversTypes['Money'], ParentType, ContextType>
  taxRate?: Resolver<ResolversTypes['Money'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UnableToCreateDepositOrderResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UnableToCreateDepositOrder'] = ResolversParentTypes['UnableToCreateDepositOrder']
> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  status?: Resolver<ResolversTypes['ErrorCode'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UnableToCreateGuestResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UnableToCreateGuest'] = ResolversParentTypes['UnableToCreateGuest']
> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  status?: Resolver<ResolversTypes['ErrorCode'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UnableToFetchGuestsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UnableToFetchGuests'] = ResolversParentTypes['UnableToFetchGuests']
> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  status?: Resolver<ResolversTypes['ErrorCode'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UnableToSendSmsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UnableToSendSMS'] = ResolversParentTypes['UnableToSendSMS']
> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  status?: Resolver<ResolversTypes['ErrorCode'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UnableToUpdateGuestResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UnableToUpdateGuest'] = ResolversParentTypes['UnableToUpdateGuest']
> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  status?: Resolver<ResolversTypes['ErrorCode'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UnauthorizedResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Unauthorized'] = ResolversParentTypes['Unauthorized']
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UnauthorizedAccessErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UnauthorizedAccessError'] = ResolversParentTypes['UnauthorizedAccessError']
> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UnknownErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UnknownError'] = ResolversParentTypes['UnknownError']
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UnknownIdentityResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UnknownIdentity'] = ResolversParentTypes['UnknownIdentity']
> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UnpublishedConfigResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UnpublishedConfig'] = ResolversParentTypes['UnpublishedConfig']
> = {
  action?: Resolver<Maybe<ResolversTypes['Action']>, ParentType, ContextType>
  entityType?: Resolver<
    Maybe<ResolversTypes['ConfigType']>,
    ParentType,
    ContextType
  >
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  lastModifiedBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType
  >
  lastPublishJob?: Resolver<
    Maybe<ResolversTypes['PublishJob']>,
    ParentType,
    ContextType
  >
  lastSavedTime?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  managementSet?: Resolver<
    Maybe<ResolversTypes['ManagementGroup']>,
    ParentType,
    ContextType
  >
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  restaurant?: Resolver<
    Maybe<ResolversTypes['SavedRestaurantLocation']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UnpublishedConfigConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UnpublishedConfigConnection'] = ResolversParentTypes['UnpublishedConfigConnection']
> = {
  edges?: Resolver<
    Array<ResolversTypes['UnpublishedConfigEdge']>,
    ParentType,
    ContextType
  >
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UnpublishedConfigEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UnpublishedConfigEdge'] = ResolversParentTypes['UnpublishedConfigEdge']
> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  node?: Resolver<ResolversTypes['UnpublishedConfig'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UnpublishedStatusResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UnpublishedStatus'] = ResolversParentTypes['UnpublishedStatus']
> = {
  entities?: Resolver<
    ResolversTypes['UnpublishedConfigConnection'],
    ParentType,
    ContextType,
    RequireFields<UnpublishedStatusEntitiesArgs, 'pagination'>
  >
  entityCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  lastPublishedTime?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  restaurantId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UnpublishedStatusConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UnpublishedStatusConnection'] = ResolversParentTypes['UnpublishedStatusConnection']
> = {
  edges?: Resolver<
    Array<ResolversTypes['UnpublishedStatusEdge']>,
    ParentType,
    ContextType
  >
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UnpublishedStatusEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UnpublishedStatusEdge'] = ResolversParentTypes['UnpublishedStatusEdge']
> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  node?: Resolver<ResolversTypes['UnpublishedStatus'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UnsupportedTransitionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UnsupportedTransition'] = ResolversParentTypes['UnsupportedTransition']
> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  status?: Resolver<ResolversTypes['ErrorCode'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UpdateItemFeedbackConfigErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateItemFeedbackConfigError'] = ResolversParentTypes['UpdateItemFeedbackConfigError']
> = {
  code?: Resolver<
    ResolversTypes['UpdateItemFeedbackConfigErrorCode'],
    ParentType,
    ContextType
  >
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UpdateItemFeedbackConfigResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateItemFeedbackConfigResponse'] = ResolversParentTypes['UpdateItemFeedbackConfigResponse']
> = {
  __resolveType: TypeResolveFn<
    'ItemFeedbackConfig' | 'UpdateItemFeedbackConfigError',
    ParentType,
    ContextType
  >
}

export type UpdateMembershipResultResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateMembershipResult'] = ResolversParentTypes['UpdateMembershipResult']
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UpdateReservationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateReservationResponse'] = ResolversParentTypes['UpdateReservationResponse']
> = {
  __resolveType: TypeResolveFn<
    | 'BookingNotFound'
    | 'GuestNotFound'
    | 'InvalidEmail'
    | 'InvalidPhoneNumber'
    | 'RestaurantNotFound'
    | 'ServiceAreaNotFound'
    | 'TableBlocked'
    | 'TableNotFound'
    | 'UnableToCreateGuest'
    | 'UnableToSendSMS'
    | 'UpdateReservationResponseSuccess',
    ParentType,
    ContextType
  >
}

export type UpdateReservationResponseSuccessResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateReservationResponseSuccess'] = ResolversParentTypes['UpdateReservationResponseSuccess']
> = {
  booking?: Resolver<ResolversTypes['Booking'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UpdateSavedUiOptionResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateSavedUIOptionResponse'] = ResolversParentTypes['UpdateSavedUIOptionResponse']
> = {
  __resolveType: TypeResolveFn<
    'InvalidUIOption' | 'MlmUIOption',
    ParentType,
    ContextType
  >
}

export type UpdateShiftFailureResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateShiftFailure'] = ResolversParentTypes['UpdateShiftFailure']
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UpdateShiftResultResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateShiftResult'] = ResolversParentTypes['UpdateShiftResult']
> = {
  __resolveType: TypeResolveFn<
    | 'BreakConfigDependencyFailure'
    | 'BuildShiftFailure'
    | 'FinishedEmployeeShift'
    | 'InProgressEmployeeShift'
    | 'InvalidBreakConfig'
    | 'InvalidBreaks'
    | 'InvalidJob'
    | 'InvalidUpdatedTime'
    | 'JobsRepositoryDependencyFailure'
    | 'LoadShiftsFailure'
    | 'LocateShiftFailure'
    | 'MissingJob'
    | 'MissingTimeClock'
    | 'OverlappingBreaks'
    | 'OverlappingShiftExists'
    | 'ReadFailure'
    | 'RestaurantRepositoryDependencyFailure'
    | 'SaveFailure'
    | 'ShiftNotFound'
    | 'Unauthorized'
    | 'UnknownError',
    ParentType,
    ContextType
  >
}

export type UpdateShiftTimesResultResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateShiftTimesResult'] = ResolversParentTypes['UpdateShiftTimesResult']
> = {
  __resolveType: TypeResolveFn<
    | 'FinishedEmployeeShift'
    | 'InProgressEmployeeShift'
    | 'InvalidUpdatedTime'
    | 'LoadShiftsFailure'
    | 'LocateShiftFailure'
    | 'OverlappingShiftExists'
    | 'RestaurantRepositoryDependencyFailure'
    | 'SaveFailure'
    | 'ShiftNotFound'
    | 'Unauthorized',
    ParentType,
    ContextType
  >
}

export type UpdateToastPayConfigErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateToastPayConfigError'] = ResolversParentTypes['UpdateToastPayConfigError']
> = {
  code?: Resolver<
    ResolversTypes['UpdateToastPayConfigErrorCode'],
    ParentType,
    ContextType
  >
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UpdateToastPayConfigResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateToastPayConfigResponse'] = ResolversParentTypes['UpdateToastPayConfigResponse']
> = {
  __resolveType: TypeResolveFn<
    'ToastPayConfig' | 'UpdateToastPayConfigError',
    ParentType,
    ContextType
  >
}

export type UpdateUserPreferredLocalResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateUserPreferredLocalResponse'] = ResolversParentTypes['UpdateUserPreferredLocalResponse']
> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UpgradeItemFeedbackConfigResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpgradeItemFeedbackConfigResponse'] = ResolversParentTypes['UpgradeItemFeedbackConfigResponse']
> = {
  __resolveType: TypeResolveFn<
    | 'CreateItemFeedbackConfigError'
    | 'ItemFeedbackConfig'
    | 'ItemFeedbackConfigAlreadyExists',
    ParentType,
    ContextType
  >
}

export type UploadImageResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UploadImageResponse'] = ResolversParentTypes['UploadImageResponse']
> = {
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export interface UrlScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['Url'], any> {
  name: 'Url'
}

export type UserResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['User'] = ResolversParentTypes['User']
> = {
  __resolveReference?: ReferenceResolver<
    Maybe<ResolversTypes['User']>,
    { __typename: 'User' } & GraphQLRecursivePick<ParentType, { id: true }>,
    ContextType
  >
  chosenName?: Resolver<
    Maybe<ResolversTypes['String']>,
    { __typename: 'User' } & GraphQLRecursivePick<ParentType, { id: true }>,
    ContextType
  >
  email?: Resolver<
    Maybe<ResolversTypes['String']>,
    { __typename: 'User' } & GraphQLRecursivePick<ParentType, { id: true }>,
    ContextType
  >
  firstName?: Resolver<
    Maybe<ResolversTypes['String']>,
    { __typename: 'User' } & GraphQLRecursivePick<ParentType, { id: true }>,
    ContextType
  >
  hasBackendAccess?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    { __typename: 'User' } & GraphQLRecursivePick<ParentType, { id: true }>,
    ContextType
  >
  hasToastRole?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    { __typename: 'User' } & GraphQLRecursivePick<ParentType, { id: true }>,
    ContextType
  >

  imagePath?: Resolver<
    Maybe<ResolversTypes['String']>,
    { __typename: 'User' } & GraphQLRecursivePick<ParentType, { id: true }>,
    ContextType
  >
  invitationDate?: Resolver<
    Maybe<ResolversTypes['Date']>,
    { __typename: 'User' } & GraphQLRecursivePick<ParentType, { id: true }>,
    ContextType
  >
  lastName?: Resolver<
    Maybe<ResolversTypes['String']>,
    { __typename: 'User' } & GraphQLRecursivePick<ParentType, { id: true }>,
    ContextType
  >
  phoneNumber?: Resolver<
    Maybe<ResolversTypes['String']>,
    { __typename: 'User' } & GraphQLRecursivePick<ParentType, { id: true }>,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserFeatureOptInResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserFeatureOptIn'] = ResolversParentTypes['UserFeatureOptIn']
> = {
  deferToDate?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  featureFlag?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  updatedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  updatedWhen?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  value?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserFeatureOptInsResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserFeatureOptInsResponse'] = ResolversParentTypes['UserFeatureOptInsResponse']
> = {
  key?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  value?: Resolver<
    Maybe<ResolversTypes['UserFeatureOptIn']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserIdentityResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserIdentity'] = ResolversParentTypes['UserIdentity']
> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserInteractionResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserInteractionResponse'] = ResolversParentTypes['UserInteractionResponse']
> = {
  items?: Resolver<
    Array<ResolversTypes['UserIteraction']>,
    ParentType,
    ContextType
  >
  totalHits?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserInterfaceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserInterface'] = ResolversParentTypes['UserInterface']
> = {
  __resolveType: TypeResolveFn<
    | 'IdmAdminUser'
    | 'IdmCustomerUser'
    | 'IdmExternalDeveloperUser'
    | 'IdmGuestUser',
    ParentType,
    ContextType
  >
  createdDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>
  deletedDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  identities?: Resolver<
    Array<ResolversTypes['IdmIdentity']>,
    ParentType,
    ContextType
  >
  name?: Resolver<ResolversTypes['IdmName'], ParentType, ContextType>
  updatedDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>
  username?: Resolver<ResolversTypes['IdmUsername'], ParentType, ContextType>
  version?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
}

export type UserIteractionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserIteraction'] = ResolversParentTypes['UserIteraction']
> = {
  interactionType?: Resolver<
    ResolversTypes['InteractionType'],
    ParentType,
    ContextType
  >
  itemId?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  searchDataSource?: Resolver<
    ResolversTypes['SearchDataSource'],
    ParentType,
    ContextType
  >
  searchTerm?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  timestamp?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserIteractionMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserIteractionMutationResponse'] = ResolversParentTypes['UserIteractionMutationResponse']
> = {
  __resolveType: TypeResolveFn<
    'ErrorResponse' | 'UserIteraction',
    ParentType,
    ContextType
  >
}

export type UserPreferencesMutationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserPreferencesMutation'] = ResolversParentTypes['UserPreferencesMutation']
> = {
  acceptAiChatTermsOfUse?: Resolver<
    ResolversTypes['AiTermsOfUseDefaultResponse'],
    ParentType,
    ContextType
  >
  addUserQuickAction?: Resolver<
    ResolversTypes['UserQuickAction'],
    ParentType,
    ContextType,
    RequireFields<
      UserPreferencesMutationAddUserQuickActionArgs,
      'userQuickActionInput'
    >
  >
  bulkUpdateUserQuickActions?: Resolver<
    Array<ResolversTypes['UserQuickAction']>,
    ParentType,
    ContextType,
    RequireFields<
      UserPreferencesMutationBulkUpdateUserQuickActionsArgs,
      'toBeCreated' | 'toBeDeleted' | 'toBeUpdated'
    >
  >
  deferFeatureOptIn?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType,
    RequireFields<
      UserPreferencesMutationDeferFeatureOptInArgs,
      'deferToDate' | 'feature'
    >
  >
  deleteUserQuickAction?: Resolver<
    ResolversTypes['DeleteUserQuickActionResponse'],
    ParentType,
    ContextType,
    RequireFields<
      UserPreferencesMutationDeleteUserQuickActionArgs,
      'quickActionId'
    >
  >
  optInToFeature?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType,
    RequireFields<UserPreferencesMutationOptInToFeatureArgs, 'feature'>
  >
  optOutToFeature?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType,
    RequireFields<UserPreferencesMutationOptOutToFeatureArgs, 'feature'>
  >
  updateUserPreferredLocale?: Resolver<
    ResolversTypes['UpdateUserPreferredLocalResponse'],
    ParentType,
    ContextType,
    RequireFields<
      UserPreferencesMutationUpdateUserPreferredLocaleArgs,
      'locale'
    >
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserPreferencesQueryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserPreferencesQuery'] = ResolversParentTypes['UserPreferencesQuery']
> = {
  aiChatTermsOfUse?: Resolver<
    ResolversTypes['AiChatTermsOfUseResponse'],
    ParentType,
    ContextType
  >
  userFeatureOptIns?: Resolver<
    Array<ResolversTypes['UserFeatureOptInsResponse']>,
    ParentType,
    ContextType
  >
  userPreferredLocale?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >
  userQuickActions?: Resolver<
    Array<ResolversTypes['UserQuickAction']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserQuickActionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserQuickAction'] = ResolversParentTypes['UserQuickAction']
> = {
  entityId?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  entityType?: Resolver<
    ResolversTypes['UserQuickActionEntityType'],
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  navigationPage?: Resolver<
    Maybe<ResolversTypes['NavigationPage']>,
    ParentType,
    ContextType
  >
  order?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  params?: Resolver<
    Maybe<Array<ResolversTypes['UserQuickActionParam']>>,
    ParentType,
    ContextType
  >
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  updatedAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserQuickActionParamResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserQuickActionParam'] = ResolversParentTypes['UserQuickActionParam']
> = {
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserSearchResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserSearch'] = ResolversParentTypes['UserSearch']
> = {
  restaurantGuid?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  term?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  timestamp?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type VoidAmountResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['VoidAmount'] = ResolversParentTypes['VoidAmount']
> = {
  grouped?: Resolver<
    Maybe<Array<ResolversTypes['GroupedVoidAmount']>>,
    ParentType,
    ContextType,
    RequireFields<VoidAmountGroupedArgs, 'by'>
  >
  totals?: Resolver<ResolversTypes['MoneyMetric'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type VoidAmountAsPercentageOfNetSalesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['VoidAmountAsPercentageOfNetSales'] = ResolversParentTypes['VoidAmountAsPercentageOfNetSales']
> = {
  grouped?: Resolver<
    Maybe<Array<ResolversTypes['GroupedVoidAmountAsPercentageOfNetSales']>>,
    ParentType,
    ContextType,
    RequireFields<VoidAmountAsPercentageOfNetSalesGroupedArgs, 'by'>
  >
  totals?: Resolver<
    Maybe<ResolversTypes['PercentageMetric']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type VoidGroupEntityResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['VoidGroupEntity'] = ResolversParentTypes['VoidGroupEntity']
> = {
  __resolveType: TypeResolveFn<
    'Check' | 'MenuItem' | 'RestaurantUser' | 'VoidReason',
    ParentType,
    ContextType
  >
}

export type VoidReasonResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['VoidReason'] = ResolversParentTypes['VoidReason']
> = {
  __resolveReference?: ReferenceResolver<
    Maybe<ResolversTypes['VoidReason']>,
    { __typename: 'VoidReason' } & GraphQLRecursivePick<
      ParentType,
      { id: true }
    >,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type VoidedItemsCountResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['VoidedItemsCount'] = ResolversParentTypes['VoidedItemsCount']
> = {
  grouped?: Resolver<
    Array<ResolversTypes['GroupedVoidedItemsCount']>,
    ParentType,
    ContextType,
    RequireFields<VoidedItemsCountGroupedArgs, 'by'>
  >
  totals?: Resolver<ResolversTypes['DecimalMetric'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type WeekOperatingScheduleResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['WeekOperatingSchedule'] = ResolversParentTypes['WeekOperatingSchedule']
> = {
  friday?: Resolver<
    Maybe<ResolversTypes['DayOperatingSchedule']>,
    ParentType,
    ContextType
  >
  monday?: Resolver<
    Maybe<ResolversTypes['DayOperatingSchedule']>,
    ParentType,
    ContextType
  >
  saturday?: Resolver<
    Maybe<ResolversTypes['DayOperatingSchedule']>,
    ParentType,
    ContextType
  >
  sunday?: Resolver<
    Maybe<ResolversTypes['DayOperatingSchedule']>,
    ParentType,
    ContextType
  >
  thursday?: Resolver<
    Maybe<ResolversTypes['DayOperatingSchedule']>,
    ParentType,
    ContextType
  >
  tuesday?: Resolver<
    Maybe<ResolversTypes['DayOperatingSchedule']>,
    ParentType,
    ContextType
  >
  wednesday?: Resolver<
    Maybe<ResolversTypes['DayOperatingSchedule']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type WorkflowResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Workflow'] = ResolversParentTypes['Workflow']
> = {
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isSaved?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  steps?: Resolver<Array<ResolversTypes['Step']>, ParentType, ContextType>
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  workflowSource?: Resolver<
    Maybe<ResolversTypes['SousChefWorkflowSource']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type Resolvers<ContextType = any> = {
  AccessibleRestaurantLocation?: AccessibleRestaurantLocationResolvers<ContextType>
  AccessibleRestaurantSet?: AccessibleRestaurantSetResolvers<ContextType>
  ActiveEmploymentStatus?: ActiveEmploymentStatusResolvers<ContextType>
  AdditionalModifierGroups?: AdditionalModifierGroupsResolvers<ContextType>
  AdditionalModifierGroupsCourse?: AdditionalModifierGroupsCourseResolvers<ContextType>
  AdditionalModifierGroupsDiningOption?: AdditionalModifierGroupsDiningOptionResolvers<ContextType>
  AdditionalModifierGroupsSeatNumberQuickOrder?: AdditionalModifierGroupsSeatNumberQuickOrderResolvers<ContextType>
  AdditionalModifierGroupsSeatNumberTableService?: AdditionalModifierGroupsSeatNumberTableServiceResolvers<ContextType>
  AdditionalModifierGroupsSpecialRequest?: AdditionalModifierGroupsSpecialRequestResolvers<ContextType>
  AdditionalModifierGroupsSplit?: AdditionalModifierGroupsSplitResolvers<ContextType>
  AiChatTermsOfUseResponse?: AiChatTermsOfUseResponseResolvers<ContextType>
  AiTermsOfUseDefaultResponse?: AiTermsOfUseDefaultResponseResolvers<ContextType>
  AllBookingsResponse?: AllBookingsResponseResolvers<ContextType>
  AllBookingsResponseSuccess?: AllBookingsResponseSuccessResolvers<ContextType>
  AllEmploymentStatuses?: AllEmploymentStatusesResolvers<ContextType>
  AlternatePaymentDataExtension?: AlternatePaymentDataExtensionResolvers<ContextType>
  ApplicantEmploymentStatus?: ApplicantEmploymentStatusResolvers<ContextType>
  ApplicationMetadata?: ApplicationMetadataResolvers<ContextType>
  ArchiveMembershipResponse?: ArchiveMembershipResponseResolvers<ContextType>
  AuthConfigurationMutation?: AuthConfigurationMutationResolvers<ContextType>
  AuthConfigurationQuery?: AuthConfigurationQueryResolvers<ContextType>
  AuthenticationFailure?: AuthenticationFailureResolvers<ContextType>
  AuthorizationDecisionReason?: AuthorizationDecisionReasonResolvers<ContextType>
  AuthorizationPage?: AuthorizationPageResolvers<ContextType>
  AuthorizationTarget?: AuthorizationTargetResolvers<ContextType>
  AvailabilitiesResponse?: AvailabilitiesResponseResolvers<ContextType>
  AvailabilitiesResponseSuccess?: AvailabilitiesResponseSuccessResolvers<ContextType>
  BankingConnection?: BankingConnectionResolvers<ContextType>
  BlockConfig?: BlockConfigResolvers<ContextType>
  BlockConfigsResponse?: BlockConfigsResponseResolvers<ContextType>
  BlockConfigsSuccess?: BlockConfigsSuccessResolvers<ContextType>
  BlockedDate?: BlockedDateResolvers<ContextType>
  Booking?: BookingResolvers<ContextType>
  BookingMutation?: BookingMutationResolvers<ContextType>
  BookingNotFound?: BookingNotFoundResolvers<ContextType>
  BookingQuery?: BookingQueryResolvers<ContextType>
  BookingRequiresDepositData?: BookingRequiresDepositDataResolvers<ContextType>
  BookingServiceArea?: BookingServiceAreaResolvers<ContextType>
  BookingServiceAreaGroup?: BookingServiceAreaGroupResolvers<ContextType>
  BookingTable?: BookingTableResolvers<ContextType>
  BookingsEmployee?: BookingsEmployeeResolvers<ContextType>
  BreakConfig?: BreakConfigResolvers<ContextType>
  BreakConfigDependencyFailure?: BreakConfigDependencyFailureResolvers<ContextType>
  BuildShiftFailure?: BuildShiftFailureResolvers<ContextType>
  CANCustomerBillingInvoice?: CanCustomerBillingInvoiceResolvers<ContextType>
  CANCustomerBillingInvoiceItem?: CanCustomerBillingInvoiceItemResolvers<ContextType>
  CANCustomerBillingInvoiceTaxDetails?: CanCustomerBillingInvoiceTaxDetailsResolvers<ContextType>
  CancelReservationResponse?: CancelReservationResponseResolvers<ContextType>
  CancelReservationResponseSuccess?: CancelReservationResponseSuccessResolvers<ContextType>
  CapitalQuery?: CapitalQueryResolvers<ContextType>
  CashDrawer?: CashDrawerResolvers<ContextType>
  CashDrawerConfig?: CashDrawerConfigResolvers<ContextType>
  CashFlowAccount?: CashFlowAccountResolvers<ContextType>
  CashFlowAccountTransactions?: CashFlowAccountTransactionsResolvers<ContextType>
  CashFlowSummary?: CashFlowSummaryResolvers<ContextType>
  CashFlowTransaction?: CashFlowTransactionResolvers<ContextType>
  Category?: CategoryResolvers<ContextType>
  ChannelAvailability?: ChannelAvailabilityResolvers<ContextType>
  ChannelMenuEntity?: ChannelMenuEntityResolvers<ContextType>
  ChannelMenuPriceAdjustment?: ChannelMenuPriceAdjustmentResolvers<ContextType>
  ChannelMutation?: ChannelMutationResolvers<ContextType>
  ChannelQuery?: ChannelQueryResolvers<ContextType>
  ChannelType?: ChannelTypeResolvers<ContextType>
  ChatMessage?: ChatMessageResolvers<ContextType>
  ChatMessageFilters?: ChatMessageFiltersResolvers<ContextType>
  ChatMessageFiltersDateRange?: ChatMessageFiltersDateRangeResolvers<ContextType>
  ChatMessageFiltersDefinedHours?: ChatMessageFiltersDefinedHoursResolvers<ContextType>
  ChatResponse?: ChatResponseResolvers<ContextType>
  Check?: CheckResolvers<ContextType>
  ChecksWithRefundsCount?: ChecksWithRefundsCountResolvers<ContextType>
  ChecksWithVoidsCount?: ChecksWithVoidsCountResolvers<ContextType>
  ClockOutFailure?: ClockOutFailureResolvers<ContextType>
  CloseOutReportingConfig?: CloseOutReportingConfigResolvers<ContextType>
  CloseOutReportingContent?: CloseOutReportingContentResolvers<ContextType>
  Comparison?: ComparisonResolvers<ContextType>
  ConfigCopyQuery?: ConfigCopyQueryResolvers<ContextType>
  ConfigField?: ConfigFieldResolvers<ContextType>
  ConfigLocation?: ConfigLocationResolvers<ContextType>
  ConfigMutation?: ConfigMutationResolvers<ContextType>
  ConfigQuery?: ConfigQueryResolvers<ContextType>
  ConfigReferencesCount?: ConfigReferencesCountResolvers<ContextType>
  ConfigType?: ConfigTypeResolvers<ContextType>
  ConfigValueDiff?: ConfigValueDiffResolvers<ContextType>
  ConfigValueDiffInformation?: ConfigValueDiffInformationResolvers<ContextType>
  CopyConfigMutation?: CopyConfigMutationResolvers<ContextType>
  CopyConfigTask?: CopyConfigTaskResolvers<ContextType>
  CopyConfigTaskConnection?: CopyConfigTaskConnectionResolvers<ContextType>
  CopyConfigTaskEdge?: CopyConfigTaskEdgeResolvers<ContextType>
  CorruptPageTokenError?: CorruptPageTokenErrorResolvers<ContextType>
  Course?: CourseResolvers<ContextType>
  CreateBlockResponse?: CreateBlockResponseResolvers<ContextType>
  CreateBlockSuccess?: CreateBlockSuccessResolvers<ContextType>
  CreateItemFeedbackConfigError?: CreateItemFeedbackConfigErrorResolvers<ContextType>
  CreateItemFeedbackConfigResponse?: CreateItemFeedbackConfigResponseResolvers<ContextType>
  CreateMembershipResult?: CreateMembershipResultResolvers<ContextType>
  CreateReservationResponse?: CreateReservationResponseResolvers<ContextType>
  CreateReservationResponseSuccess?: CreateReservationResponseSuccessResolvers<ContextType>
  CreateShiftResult?: CreateShiftResultResolvers<ContextType>
  CreateToastPayConfigError?: CreateToastPayConfigErrorResolvers<ContextType>
  CreateToastPayConfigResponse?: CreateToastPayConfigResponseResolvers<ContextType>
  CurrentBookingUsageResponse?: CurrentBookingUsageResponseResolvers<ContextType>
  CurrentBookingUsageResponseSuccess?: CurrentBookingUsageResponseSuccessResolvers<ContextType>
  CustomMenuItemUpsell?: CustomMenuItemUpsellResolvers<ContextType>
  CustomerBillingCreditMemo?: CustomerBillingCreditMemoResolvers<ContextType>
  CustomerBillingCreditMemoApplication?: CustomerBillingCreditMemoApplicationResolvers<ContextType>
  CustomerBillingInvoice?: CustomerBillingInvoiceResolvers<ContextType>
  CustomerBillingInvoiceDeductions?: CustomerBillingInvoiceDeductionsResolvers<ContextType>
  CustomerBillingInvoiceItem?: CustomerBillingInvoiceItemResolvers<ContextType>
  CustomerBillingInvoiceShipping?: CustomerBillingInvoiceShippingResolvers<ContextType>
  CustomerBillingInvoiceTotals?: CustomerBillingInvoiceTotalsResolvers<ContextType>
  CustomerBillingInvoicesPaginatedResponse?: CustomerBillingInvoicesPaginatedResponseResolvers<ContextType>
  CustomerBillingPaginatedResponse?: CustomerBillingPaginatedResponseResolvers<ContextType>
  CustomerBillingQuery?: CustomerBillingQueryResolvers<ContextType>
  CustomerInvoiceDetailsReport?: CustomerInvoiceDetailsReportResolvers<ContextType>
  CustomerInvoiceDetailsReportCreditMemo?: CustomerInvoiceDetailsReportCreditMemoResolvers<ContextType>
  CustomerInvoiceDetailsReportServicePeriodItem?: CustomerInvoiceDetailsReportServicePeriodItemResolvers<ContextType>
  CustomerInvoiceDetailsReportServicePeriodItems?: CustomerInvoiceDetailsReportServicePeriodItemsResolvers<ContextType>
  CustomerInvoiceListReport?: CustomerInvoiceListReportResolvers<ContextType>
  CustomerInvoiceListReportItem?: CustomerInvoiceListReportItemResolvers<ContextType>
  CustomerInvoiceListTotalAmounts?: CustomerInvoiceListTotalAmountsResolvers<ContextType>
  CustomerInvoiceQuery?: CustomerInvoiceQueryResolvers<ContextType>
  CustomerTasksMutation?: CustomerTasksMutationResolvers<ContextType>
  CustomerTasksQuery?: CustomerTasksQueryResolvers<ContextType>
  CustomerTasksTask?: CustomerTasksTaskResolvers<ContextType>
  CustomerTasksTaskEvent?: CustomerTasksTaskEventResolvers<ContextType>
  CustomerTasksTaskTemplate?: CustomerTasksTaskTemplateResolvers<ContextType>
  CustomerTasksTaskTimeline?: CustomerTasksTaskTimelineResolvers<ContextType>
  CustomerTasksWorkflow?: CustomerTasksWorkflowResolvers<ContextType>
  CustomerTasksWorkflowTemplate?: CustomerTasksWorkflowTemplateResolvers<ContextType>
  CustomerUserAuthorizationQuery?: CustomerUserAuthorizationQueryResolvers<ContextType>
  DSModel?: DsModelResolvers<ContextType>
  DailyMetrics?: DailyMetricsResolvers<ContextType>
  DailyNetSalesMetric?: DailyNetSalesMetricResolvers<ContextType>
  DataExtension?: DataExtensionResolvers<ContextType>
  DataExtensionMutation?: DataExtensionMutationResolvers<ContextType>
  DataExtensionQuery?: DataExtensionQueryResolvers<ContextType>
  DataExtensionSetOverride?: DataExtensionSetOverrideResolvers<ContextType>
  Date?: GraphQLScalarType
  DateRange?: DateRangeResolvers<ContextType>
  DateRangeMetrics?: DateRangeMetricsResolvers<ContextType>
  DateTime?: GraphQLScalarType
  DayOperatingSchedule?: DayOperatingScheduleResolvers<ContextType>
  DayPeriod?: DayPeriodResolvers<ContextType>
  Decimal?: GraphQLScalarType
  DecimalMetric?: DecimalMetricResolvers<ContextType>
  DeclareCashTipsConfig?: DeclareCashTipsConfigResolvers<ContextType>
  DefaultRate?: DefaultRateResolvers<ContextType>
  DeleteUserQuickActionResponse?: DeleteUserQuickActionResponseResolvers<ContextType>
  DeletedEmploymentStatus?: DeletedEmploymentStatusResolvers<ContextType>
  DeletedShiftResult?: DeletedShiftResultResolvers<ContextType>
  DemoEmploymentStatus?: DemoEmploymentStatusResolvers<ContextType>
  DepositAmountChanged?: DepositAmountChangedResolvers<ContextType>
  DepositAvailabilityInfo?: DepositAvailabilityInfoResolvers<ContextType>
  DepositBookableConfigSnapshot?: DepositBookableConfigSnapshotResolvers<ContextType>
  DepositBookableConfigSnapshotStrategy?: DepositBookableConfigSnapshotStrategyResolvers<ContextType>
  DepositRuleNotMatching?: DepositRuleNotMatchingResolvers<ContextType>
  DepositStrategy?: DepositStrategyResolvers<ContextType>
  DepositStrategyAvailabilityInfo?: DepositStrategyAvailabilityInfoResolvers<ContextType>
  DepositStrategyAvailabilityInfoByBooking?: DepositStrategyAvailabilityInfoByBookingResolvers<ContextType>
  DepositStrategyAvailabilityInfoByPartySize?: DepositStrategyAvailabilityInfoByPartySizeResolvers<ContextType>
  DepositStrategyByBooking?: DepositStrategyByBookingResolvers<ContextType>
  DepositStrategyByPartySize?: DepositStrategyByPartySizeResolvers<ContextType>
  Device?: DeviceResolvers<ContextType>
  DeviceAlert?: DeviceAlertResolvers<ContextType>
  DeviceConnectivityEvent?: DeviceConnectivityEventResolvers<ContextType>
  DeviceConnectivityEventData?: DeviceConnectivityEventDataResolvers<ContextType>
  DeviceDetails?: DeviceDetailsResolvers<ContextType>
  DeviceInfoPublishedPrepStation?: DeviceInfoPublishedPrepStationResolvers<ContextType>
  DeviceLastSeenInfo?: DeviceLastSeenInfoResolvers<ContextType>
  DeviceNetworkDetails?: DeviceNetworkDetailsResolvers<ContextType>
  DeviceQueries?: DeviceQueriesResolvers<ContextType>
  DeviceSyncDetails?: DeviceSyncDetailsResolvers<ContextType>
  DiningOption?: DiningOptionResolvers<ContextType>
  DiscountAmount?: DiscountAmountResolvers<ContextType>
  DiscountCount?: DiscountCountResolvers<ContextType>
  DiscountGroupEntity?: DiscountGroupEntityResolvers<ContextType>
  DoorDashMockOnboardingConfiguration?: DoorDashMockOnboardingConfigurationResolvers<ContextType>
  DoorDashNetNewOnboardingResponse?: DoorDashNetNewOnboardingResponseResolvers<ContextType>
  DoorDashOnboardingActivateIntegrationResponse?: DoorDashOnboardingActivateIntegrationResponseResolvers<ContextType>
  DoorDashOnboardingIdResponse?: DoorDashOnboardingIdResponseResolvers<ContextType>
  DoorDashOnboardingMenu?: DoorDashOnboardingMenuResolvers<ContextType>
  DoorDashOnboardingMenuCandidate?: DoorDashOnboardingMenuCandidateResolvers<ContextType>
  DoorDashOnboardingStatusResponse?: DoorDashOnboardingStatusResponseResolvers<ContextType>
  DoorDashOnboardingStoreCandidate?: DoorDashOnboardingStoreCandidateResolvers<ContextType>
  DoorDashOnboardingStores?: DoorDashOnboardingStoresResolvers<ContextType>
  DoorDashStoreCandidate?: DoorDashStoreCandidateResolvers<ContextType>
  DriveThruMutation?: DriveThruMutationResolvers<ContextType>
  DriveThruQuery?: DriveThruQueryResolvers<ContextType>
  DsModelHealthStatus?: DsModelHealthStatusResolvers<ContextType>
  Duration?: GraphQLScalarType
  DurationBreakdown?: DurationBreakdownResolvers<ContextType>
  EPCustomerBillingInvoice?: EpCustomerBillingInvoiceResolvers<ContextType>
  EPCustomerBillingInvoiceDeductions?: EpCustomerBillingInvoiceDeductionsResolvers<ContextType>
  EPCustomerBillingInvoiceItem?: EpCustomerBillingInvoiceItemResolvers<ContextType>
  EmailMarketingBlastCampaign?: EmailMarketingBlastCampaignResolvers<ContextType>
  EmailMarketingCampaign?: EmailMarketingCampaignResolvers<ContextType>
  Employee?: EmployeeResolvers<ContextType>
  EmployeeAddress?: EmployeeAddressResolvers<ContextType>
  EmployeeConnection?: EmployeeConnectionResolvers<ContextType>
  EmployeeCountry?: EmployeeCountryResolvers<ContextType>
  EmployeeDeleted?: EmployeeDeletedResolvers<ContextType>
  EmployeeEdge?: EmployeeEdgeResolvers<ContextType>
  EmployeeEmail?: EmployeeEmailResolvers<ContextType>
  EmployeeEmployment?: EmployeeEmploymentResolvers<ContextType>
  EmployeeEmploymentPeriod?: EmployeeEmploymentPeriodResolvers<ContextType>
  EmployeeEmploymentPeriodInterface?: EmployeeEmploymentPeriodInterfaceResolvers<ContextType>
  EmployeeHistoricalEmploymentPeriod?: EmployeeHistoricalEmploymentPeriodResolvers<ContextType>
  EmployeeJobAssignment?: EmployeeJobAssignmentResolvers<ContextType>
  EmployeeLocationAssignments?: EmployeeLocationAssignmentsResolvers<ContextType>
  EmployeeMissedBreak?: EmployeeMissedBreakResolvers<ContextType>
  EmployeeName?: EmployeeNameResolvers<ContextType>
  EmployeeOrderTips?: EmployeeOrderTipsResolvers<ContextType>
  EmployeePageInfo?: EmployeePageInfoResolvers<ContextType>
  EmployeePay?: EmployeePayResolvers<ContextType>
  EmployeePhoneNumber?: EmployeePhoneNumberResolvers<ContextType>
  EmployeeRepositoryDependencyFailure?: EmployeeRepositoryDependencyFailureResolvers<ContextType>
  EmployeeSchedule?: EmployeeScheduleResolvers<ContextType>
  EmployeeScheduleV2?: EmployeeScheduleV2Resolvers<ContextType>
  EmployeeShift?: EmployeeShiftResolvers<ContextType>
  EmployeeShiftConnection?: EmployeeShiftConnectionResolvers<ContextType>
  EmployeeShiftConnectionV2?: EmployeeShiftConnectionV2Resolvers<ContextType>
  EmployeeShiftConnectionV2Edge?: EmployeeShiftConnectionV2EdgeResolvers<ContextType>
  EmployeeShiftTip?: EmployeeShiftTipResolvers<ContextType>
  EmployeeShiftV2?: EmployeeShiftV2Resolvers<ContextType>
  EmployeeTakenBreak?: EmployeeTakenBreakResolvers<ContextType>
  EmployeeTime?: EmployeeTimeResolvers<ContextType>
  EmployeeTips?: EmployeeTipsResolvers<ContextType>
  EmployeeTipsV2?: EmployeeTipsV2Resolvers<ContextType>
  EmployeeV2?: EmployeeV2Resolvers<ContextType>
  EmploymentStatus?: EmploymentStatusResolvers<ContextType>
  EmptyCustomerBillingInvoice?: EmptyCustomerBillingInvoiceResolvers<ContextType>
  EntitlementsRepositoryDependencyFailure?: EntitlementsRepositoryDependencyFailureResolvers<ContextType>
  EntityAncestorPath?: EntityAncestorPathResolvers<ContextType>
  EntityAncestorResponse?: EntityAncestorResponseResolvers<ContextType>
  Error?: ErrorResolvers<ContextType>
  ErrorRefundingDeposit?: ErrorRefundingDepositResolvers<ContextType>
  ErrorResponse?: ErrorResponseResolvers<ContextType>
  Errors?: ErrorsResolvers<ContextType>
  ExternalPartner?: ExternalPartnerResolvers<ContextType>
  ExternalPartnerToggle?: ExternalPartnerToggleResolvers<ContextType>
  FetchCashFlowTransactionsResponse?: FetchCashFlowTransactionsResponseResolvers<ContextType>
  FinancialInsightsMutation?: FinancialInsightsMutationResolvers<ContextType>
  FinancialInsightsQuery?: FinancialInsightsQueryResolvers<ContextType>
  FinancialProductForCustomer?: FinancialProductForCustomerResolvers<ContextType>
  FinishedEmployeeShift?: FinishedEmployeeShiftResolvers<ContextType>
  FinishedEmployeeShiftV2?: FinishedEmployeeShiftV2Resolvers<ContextType>
  FinishedEmployeeTakenBreak?: FinishedEmployeeTakenBreakResolvers<ContextType>
  FinishedEmployeeTime?: FinishedEmployeeTimeResolvers<ContextType>
  FixedFeeLoan?: FixedFeeLoanResolvers<ContextType>
  FixedFeePQO?: FixedFeePqoResolvers<ContextType>
  FixedFeePromotionalOffer?: FixedFeePromotionalOfferResolvers<ContextType>
  FoodWasteConfig?: FoodWasteConfigResolvers<ContextType>
  FoodWasteConfigOverride?: FoodWasteConfigOverrideResolvers<ContextType>
  FoodWasteEducationDashboardData?: FoodWasteEducationDashboardDataResolvers<ContextType>
  FoodWasteEstimationInputs?: FoodWasteEstimationInputsResolvers<ContextType>
  FoodWasteInsights?: FoodWasteInsightsResolvers<ContextType>
  FoodWasteLocalOrganizationDetails?: FoodWasteLocalOrganizationDetailsResolvers<ContextType>
  FoodWasteMutation?: FoodWasteMutationResolvers<ContextType>
  FoodWasteOpportunity?: FoodWasteOpportunityResolvers<ContextType>
  FoodWasteQuery?: FoodWasteQueryResolvers<ContextType>
  FundingPartner?: FundingPartnerResolvers<ContextType>
  GeneratedGuestFeedbackAIResponse?: GeneratedGuestFeedbackAiResponseResolvers<ContextType>
  GeoCoordinates?: GeoCoordinatesResolvers<ContextType>
  GetTipsResponse?: GetTipsResponseResolvers<ContextType>
  GetTrialsResponse?: GetTrialsResponseResolvers<ContextType>
  GfdAddOnStatus?: GfdAddOnStatusResolvers<ContextType>
  GfdConfig?: GfdConfigResolvers<ContextType>
  GfdImage?: GfdImageResolvers<ContextType>
  GiftCardMutation?: GiftCardMutationResolvers<ContextType>
  GiftCardQuery?: GiftCardQueryResolvers<ContextType>
  GlobalNavigationGroup?: GlobalNavigationGroupResolvers<ContextType>
  GlobalNavigationMenu?: GlobalNavigationMenuResolvers<ContextType>
  Group?: GroupResolvers<ContextType>
  GroupEntity?: GroupEntityResolvers<ContextType>
  GroupedChecksWithRefundsCount?: GroupedChecksWithRefundsCountResolvers<ContextType>
  GroupedChecksWithVoidsCount?: GroupedChecksWithVoidsCountResolvers<ContextType>
  GroupedDiscountAmount?: GroupedDiscountAmountResolvers<ContextType>
  GroupedDiscountCount?: GroupedDiscountCountResolvers<ContextType>
  GroupedLaborCost?: GroupedLaborCostResolvers<ContextType>
  GroupedNetSales?: GroupedNetSalesResolvers<ContextType>
  GroupedTotalRefundAmount?: GroupedTotalRefundAmountResolvers<ContextType>
  GroupedVoidAmount?: GroupedVoidAmountResolvers<ContextType>
  GroupedVoidAmountAsPercentageOfNetSales?: GroupedVoidAmountAsPercentageOfNetSalesResolvers<ContextType>
  GroupedVoidedItemsCount?: GroupedVoidedItemsCountResolvers<ContextType>
  Guest?: GuestResolvers<ContextType>
  GuestAlreadyExists?: GuestAlreadyExistsResolvers<ContextType>
  GuestCount?: GuestCountResolvers<ContextType>
  GuestCreationResponse?: GuestCreationResponseResolvers<ContextType>
  GuestCreationResponseSuccess?: GuestCreationResponseSuccessResolvers<ContextType>
  GuestFeedbackAiSummary?: GuestFeedbackAiSummaryResolvers<ContextType>
  GuestFeedbackContactInformation?: GuestFeedbackContactInformationResolvers<ContextType>
  GuestFeedbackCountByWeek?: GuestFeedbackCountByWeekResolvers<ContextType>
  GuestFeedbackGenerateResponse?: GuestFeedbackGenerateResponseResolvers<ContextType>
  GuestFeedbackGenerateResponseError?: GuestFeedbackGenerateResponseErrorResolvers<ContextType>
  GuestFeedbackInfo?: GuestFeedbackInfoResolvers<ContextType>
  GuestFeedbackMutation?: GuestFeedbackMutationResolvers<ContextType>
  GuestFeedbackOrderCheckInfo?: GuestFeedbackOrderCheckInfoResolvers<ContextType>
  GuestFeedbackQuery?: GuestFeedbackQueryResolvers<ContextType>
  GuestFeedbackResponse?: GuestFeedbackResponseResolvers<ContextType>
  GuestFeedbackSentimentByMenuItem?: GuestFeedbackSentimentByMenuItemResolvers<ContextType>
  GuestFeedbackSentimentOverview?: GuestFeedbackSentimentOverviewResolvers<ContextType>
  GuestFeedbackStatsSummaryResponse?: GuestFeedbackStatsSummaryResponseResolvers<ContextType>
  GuestListResponse?: GuestListResponseResolvers<ContextType>
  GuestListResponseSuccess?: GuestListResponseSuccessResolvers<ContextType>
  GuestNotFound?: GuestNotFoundResolvers<ContextType>
  GuestQuoteTimes?: GuestQuoteTimesResolvers<ContextType>
  GuestUpdateResponse?: GuestUpdateResponseResolvers<ContextType>
  GuestUpdateResponseSuccess?: GuestUpdateResponseSuccessResolvers<ContextType>
  HardwareItemOrdered?: HardwareItemOrderedResolvers<ContextType>
  HardwareOrder?: HardwareOrderResolvers<ContextType>
  HardwareOrderDeliveryAddress?: HardwareOrderDeliveryAddressResolvers<ContextType>
  HardwareOrderDeliveryInfo?: HardwareOrderDeliveryInfoResolvers<ContextType>
  HardwareOrderTrackingInfo?: HardwareOrderTrackingInfoResolvers<ContextType>
  IRLCustomerBillingInvoice?: IrlCustomerBillingInvoiceResolvers<ContextType>
  IRLCustomerBillingInvoiceItem?: IrlCustomerBillingInvoiceItemResolvers<ContextType>
  Identity?: IdentityResolvers<ContextType>
  IdentityInterface?: IdentityInterfaceResolvers<ContextType>
  IdmAdminUser?: IdmAdminUserResolvers<ContextType>
  IdmCustomerUser?: IdmCustomerUserResolvers<ContextType>
  IdmExternalDeveloperUser?: IdmExternalDeveloperUserResolvers<ContextType>
  IdmGuestAppMetadata?: IdmGuestAppMetadataResolvers<ContextType>
  IdmGuestUser?: IdmGuestUserResolvers<ContextType>
  IdmIdentity?: IdmIdentityResolvers<ContextType>
  IdmIdentityVerification?: IdmIdentityVerificationResolvers<ContextType>
  IdmName?: IdmNameResolvers<ContextType>
  IdmUsername?: IdmUsernameResolvers<ContextType>
  IdmUsersQuery?: IdmUsersQueryResolvers<ContextType>
  ImplementationEmploymentStatus?: ImplementationEmploymentStatusResolvers<ContextType>
  InProgressEmployeeShift?: InProgressEmployeeShiftResolvers<ContextType>
  InProgressEmployeeShiftV2?: InProgressEmployeeShiftV2Resolvers<ContextType>
  InProgressEmployeeTakenBreak?: InProgressEmployeeTakenBreakResolvers<ContextType>
  InProgressEmployeeTime?: InProgressEmployeeTimeResolvers<ContextType>
  IntegerMetric?: IntegerMetricResolvers<ContextType>
  IntercomConversation?: IntercomConversationResolvers<ContextType>
  IntercomConversationPart?: IntercomConversationPartResolvers<ContextType>
  IntercomConversationPartAuthor?: IntercomConversationPartAuthorResolvers<ContextType>
  IntercomOptions?: IntercomOptionsResolvers<ContextType>
  IntercomUserHashResult?: IntercomUserHashResultResolvers<ContextType>
  InternalError?: InternalErrorResolvers<ContextType>
  InvalidBreakConfig?: InvalidBreakConfigResolvers<ContextType>
  InvalidBreaks?: InvalidBreaksResolvers<ContextType>
  InvalidCustomerUuidError?: InvalidCustomerUuidErrorResolvers<ContextType>
  InvalidEmail?: InvalidEmailResolvers<ContextType>
  InvalidGuest?: InvalidGuestResolvers<ContextType>
  InvalidIdempotencyKeyError?: InvalidIdempotencyKeyErrorResolvers<ContextType>
  InvalidJob?: InvalidJobResolvers<ContextType>
  InvalidLocation?: InvalidLocationResolvers<ContextType>
  InvalidPhoneNumber?: InvalidPhoneNumberResolvers<ContextType>
  InvalidRestaurantIDError?: InvalidRestaurantIdErrorResolvers<ContextType>
  InvalidUIOption?: InvalidUiOptionResolvers<ContextType>
  InvalidUpdatedTime?: InvalidUpdatedTimeResolvers<ContextType>
  InvalidUuidError?: InvalidUuidErrorResolvers<ContextType>
  ItemFeedbackConfig?: ItemFeedbackConfigResolvers<ContextType>
  ItemFeedbackConfigAlreadyExists?: ItemFeedbackConfigAlreadyExistsResolvers<ContextType>
  ItemFeedbackConfigError?: ItemFeedbackConfigErrorResolvers<ContextType>
  ItemFeedbackConfigResponse?: ItemFeedbackConfigResponseResolvers<ContextType>
  ItemMetrics?: ItemMetricsResolvers<ContextType>
  ItemReference?: ItemReferenceResolvers<ContextType>
  JSON?: GraphQLScalarType
  Job?: JobResolvers<ContextType>
  JobAssignmentDefaults?: JobAssignmentDefaultsResolvers<ContextType>
  JobAssignmentDefaultsExtendedProperties?: JobAssignmentDefaultsExtendedPropertiesResolvers<ContextType>
  JobAssignmentInfo?: JobAssignmentInfoResolvers<ContextType>
  JobBasedAccessConfig?: JobBasedAccessConfigResolvers<ContextType>
  JobError?: JobErrorResolvers<ContextType>
  JobExtendedProperties?: JobExtendedPropertiesResolvers<ContextType>
  JobMapping?: JobMappingResolvers<ContextType>
  JobMappings?: JobMappingsResolvers<ContextType>
  JobNotFoundError?: JobNotFoundErrorResolvers<ContextType>
  JobResponse?: JobResponseResolvers<ContextType>
  JobTagAssignment?: JobTagAssignmentResolvers<ContextType>
  JobTagValue?: JobTagValueResolvers<ContextType>
  JobV2?: JobV2Resolvers<ContextType>
  JobsBatchResponse?: JobsBatchResponseResolvers<ContextType>
  JobsBatchSizeError?: JobsBatchSizeErrorResolvers<ContextType>
  JobsFilterMissingError?: JobsFilterMissingErrorResolvers<ContextType>
  JobsLegacyBatchResponse?: JobsLegacyBatchResponseResolvers<ContextType>
  JobsNotFoundBatchError?: JobsNotFoundBatchErrorResolvers<ContextType>
  JobsPaginatedResult?: JobsPaginatedResultResolvers<ContextType>
  JobsRepositoryDependencyFailure?: JobsRepositoryDependencyFailureResolvers<ContextType>
  JobsResponse?: JobsResponseResolvers<ContextType>
  JobsV2?: JobsV2Resolvers<ContextType>
  KioskConfig?: KioskConfigResolvers<ContextType>
  KioskConfigServiceCharge?: KioskConfigServiceChargeResolvers<ContextType>
  KioskCustomerMessageSettings?: KioskCustomerMessageSettingsResolvers<ContextType>
  KioskDiningOption?: KioskDiningOptionResolvers<ContextType>
  KioskLoyalty?: KioskLoyaltyResolvers<ContextType>
  KioskMutation?: KioskMutationResolvers<ContextType>
  KioskQuery?: KioskQueryResolvers<ContextType>
  KioskUpsellMenuItem?: KioskUpsellMenuItemResolvers<ContextType>
  KioskVoidReason?: KioskVoidReasonResolvers<ContextType>
  KitchenCapacityHealthStatus?: KitchenCapacityHealthStatusResolvers<ContextType>
  LaborCost?: LaborCostResolvers<ContextType>
  LaborCostAsPercentageOfNetSales?: LaborCostAsPercentageOfNetSalesResolvers<ContextType>
  LaborCostGroupEntity?: LaborCostGroupEntityResolvers<ContextType>
  LaborMetrics?: LaborMetricsResolvers<ContextType>
  LeaveOfAbsenceEmploymentStatus?: LeaveOfAbsenceEmploymentStatusResolvers<ContextType>
  LoadShiftsFailure?: LoadShiftsFailureResolvers<ContextType>
  LoanApplicationForCustomer?: LoanApplicationForCustomerResolvers<ContextType>
  LocalTime?: GraphQLScalarType
  LocalTimeRange?: LocalTimeRangeResolvers<ContextType>
  LocateShiftFailure?: LocateShiftFailureResolvers<ContextType>
  LocationGroup?: LocationGroupResolvers<ContextType>
  LocationSetupInformation?: LocationSetupInformationResolvers<ContextType>
  LocationSpecificPrice?: LocationSpecificPriceResolvers<ContextType>
  LogbookCategory?: LogbookCategoryResolvers<ContextType>
  LogbookContent?: LogbookContentResolvers<ContextType>
  LogbookContentBlock?: LogbookContentBlockResolvers<ContextType>
  LogbookContentTag?: LogbookContentTagResolvers<ContextType>
  LogbookEntry?: LogbookEntryResolvers<ContextType>
  LogbookEntryReply?: LogbookEntryReplyResolvers<ContextType>
  LogbookEntrySource?: LogbookEntrySourceResolvers<ContextType>
  LogbookMutation?: LogbookMutationResolvers<ContextType>
  LogbookQuery?: LogbookQueryResolvers<ContextType>
  LogbookTextContentBlock?: LogbookTextContentBlockResolvers<ContextType>
  LogbookUserContentBlock?: LogbookUserContentBlockResolvers<ContextType>
  LogbookUserTag?: LogbookUserTagResolvers<ContextType>
  Long?: GraphQLScalarType
  ManagementGroup?: ManagementGroupResolvers<ContextType>
  ManagementSet?: ManagementSetResolvers<ContextType>
  ManagerClockOutResult?: ManagerClockOutResultResolvers<ContextType>
  MarkdownText?: GraphQLScalarType
  MarketingCampaign?: MarketingCampaignResolvers<ContextType>
  MarketingCampaignCustomProperties?: MarketingCampaignCustomPropertiesResolvers<ContextType>
  MarketingCampaignTrigger?: MarketingCampaignTriggerResolvers<ContextType>
  MarketingQuery?: MarketingQueryResolvers<ContextType>
  MemberGuestBookData?: MemberGuestBookDataResolvers<ContextType>
  Membership?: MembershipResolvers<ContextType>
  MembershipAddress?: MembershipAddressResolvers<ContextType>
  MembershipInvoice?: MembershipInvoiceResolvers<ContextType>
  MembershipInvoicesResponse?: MembershipInvoicesResponseResolvers<ContextType>
  MembershipTransaction?: MembershipTransactionResolvers<ContextType>
  MembershipTransactionsResponse?: MembershipTransactionsResponseResolvers<ContextType>
  MembershipsMutation?: MembershipsMutationResolvers<ContextType>
  MembershipsQuery?: MembershipsQueryResolvers<ContextType>
  MembershipsResponse?: MembershipsResponseResolvers<ContextType>
  MembershipsSettings?: MembershipsSettingsResolvers<ContextType>
  MembershipsSettingsResponse?: MembershipsSettingsResponseResolvers<ContextType>
  MembershipsSummary?: MembershipsSummaryResolvers<ContextType>
  Menu?: MenuResolvers<ContextType>
  MenuEntity?: MenuEntityResolvers<ContextType>
  MenuEntityAlcohol?: MenuEntityAlcoholResolvers<ContextType>
  MenuEntityContentAdvisories?: MenuEntityContentAdvisoriesResolvers<ContextType>
  MenuEntityImage?: MenuEntityImageResolvers<ContextType>
  MenuEntityPointOfSale?: MenuEntityPointOfSaleResolvers<ContextType>
  MenuEntityReports?: MenuEntityReportsResolvers<ContextType>
  MenuEntityTaxes?: MenuEntityTaxesResolvers<ContextType>
  MenuEntityVisibility?: MenuEntityVisibilityResolvers<ContextType>
  MenuGroup?: MenuGroupResolvers<ContextType>
  MenuItem?: MenuItemResolvers<ContextType>
  MenuItemFeedback?: MenuItemFeedbackResolvers<ContextType>
  MenuItemInventoryConnection?: MenuItemInventoryConnectionResolvers<ContextType>
  MenuItemPlateCost?: MenuItemPlateCostResolvers<ContextType>
  MenuItemPricing?: MenuItemPricingResolvers<ContextType>
  MenuSpecificPrice?: MenuSpecificPriceResolvers<ContextType>
  MenuUpsellConfigMutation?: MenuUpsellConfigMutationResolvers<ContextType>
  MenuUpsellConfigQuery?: MenuUpsellConfigQueryResolvers<ContextType>
  MgmtDailySummaryConfig?: MgmtDailySummaryConfigResolvers<ContextType>
  MgmtDepositBookableConfig?: MgmtDepositBookableConfigResolvers<ContextType>
  MgmtEmailConfig?: MgmtEmailConfigResolvers<ContextType>
  MgmtEmailTemplate?: MgmtEmailTemplateResolvers<ContextType>
  MgmtRestaurantInformation?: MgmtRestaurantInformationResolvers<ContextType>
  MgmtRestaurantInformationResponse?: MgmtRestaurantInformationResponseResolvers<ContextType>
  MgmtServiceArea?: MgmtServiceAreaResolvers<ContextType>
  MgmtServiceAreaGroup?: MgmtServiceAreaGroupResolvers<ContextType>
  MgmtSmsConfig?: MgmtSmsConfigResolvers<ContextType>
  MgmtSmsTemplate?: MgmtSmsTemplateResolvers<ContextType>
  MgmtTable?: MgmtTableResolvers<ContextType>
  MgmtWaitlistAutoRemoveConfig?: MgmtWaitlistAutoRemoveConfigResolvers<ContextType>
  MinimumRepayment?: MinimumRepaymentResolvers<ContextType>
  MissingJob?: MissingJobResolvers<ContextType>
  MissingTimeClock?: MissingTimeClockResolvers<ContextType>
  MlmSavedConfig?: MlmSavedConfigResolvers<ContextType>
  MlmSavedConfigVariant?: MlmSavedConfigVariantResolvers<ContextType>
  MlmSavedVersion?: MlmSavedVersionResolvers<ContextType>
  MlmUIOption?: MlmUiOptionResolvers<ContextType>
  MlmUIOptionVariant?: MlmUiOptionVariantResolvers<ContextType>
  Modifier?: ModifierResolvers<ContextType>
  ModifierGroup?: ModifierGroupResolvers<ContextType>
  ModifierGroupPointOfSale?: ModifierGroupPointOfSaleResolvers<ContextType>
  ModifierGroupPricing?: ModifierGroupPricingResolvers<ContextType>
  ModifierGroupSelections?: ModifierGroupSelectionsResolvers<ContextType>
  ModifierMetrics?: ModifierMetricsResolvers<ContextType>
  Money?: MoneyResolvers<ContextType>
  MoneyMetric?: MoneyMetricResolvers<ContextType>
  MostRecentImportGuidResponse?: MostRecentImportGuidResponseResolvers<ContextType>
  MostRecentImportGuidResponseSuccess?: MostRecentImportGuidResponseSuccessResolvers<ContextType>
  Mutation?: MutationResolvers<ContextType>
  NavigationCategory?: NavigationCategoryResolvers<ContextType>
  NavigationConfigLocation?: NavigationConfigLocationResolvers<ContextType>
  NavigationGroup?: NavigationGroupResolvers<ContextType>
  NavigationHeader?: NavigationHeaderResolvers<ContextType>
  NavigationLink?: NavigationLinkResolvers<ContextType>
  NavigationMenuItem?: NavigationMenuItemResolvers<ContextType>
  NavigationPage?: NavigationPageResolvers<ContextType>
  NavigationProfileLink?: NavigationProfileLinkResolvers<ContextType>
  NavigationQuery?: NavigationQueryResolvers<ContextType>
  NavigationQuickAction?: NavigationQuickActionResolvers<ContextType>
  NavigationSection?: NavigationSectionResolvers<ContextType>
  NavigationTask?: NavigationTaskResolvers<ContextType>
  NavigationTaskAction?: NavigationTaskActionResolvers<ContextType>
  NetSales?: NetSalesResolvers<ContextType>
  NetSalesDateRangeMetrics?: NetSalesDateRangeMetricsResolvers<ContextType>
  NetSalesPerLaborHour?: NetSalesPerLaborHourResolvers<ContextType>
  NetworkDevice?: NetworkDeviceResolvers<ContextType>
  NetworkQueries?: NetworkQueriesResolvers<ContextType>
  NetworkStatusData?: NetworkStatusDataResolvers<ContextType>
  NetworkStatusError?: NetworkStatusErrorResolvers<ContextType>
  NetworkStatusResponse?: NetworkStatusResponseResolvers<ContextType>
  NeverEmployedEmploymentStatus?: NeverEmployedEmploymentStatusResolvers<ContextType>
  NoJobIdsProvidedBatchError?: NoJobIdsProvidedBatchErrorResolvers<ContextType>
  NoPreviousImports?: NoPreviousImportsResolvers<ContextType>
  NotificationContact?: NotificationContactResolvers<ContextType>
  OnboardingAction?: OnboardingActionResolvers<ContextType>
  OnboardingDefaultAction?: OnboardingDefaultActionResolvers<ContextType>
  OnboardingDefaultInfo?: OnboardingDefaultInfoResolvers<ContextType>
  OnboardingDefaultItem?: OnboardingDefaultItemResolvers<ContextType>
  OnboardingDefaultItemMetadataV2?: OnboardingDefaultItemMetadataV2Resolvers<ContextType>
  OnboardingDefaultItemV2?: OnboardingDefaultItemV2Resolvers<ContextType>
  OnboardingDefaultStepDetails?: OnboardingDefaultStepDetailsResolvers<ContextType>
  OnboardingDefaultStepInfo?: OnboardingDefaultStepInfoResolvers<ContextType>
  OnboardingImplementationStep?: OnboardingImplementationStepResolvers<ContextType>
  OnboardingImplementationStepV2?: OnboardingImplementationStepV2Resolvers<ContextType>
  OnboardingInfo?: OnboardingInfoResolvers<ContextType>
  OnboardingInfoStep?: OnboardingInfoStepResolvers<ContextType>
  OnboardingItem?: OnboardingItemResolvers<ContextType>
  OnboardingItemMetadata?: OnboardingItemMetadataResolvers<ContextType>
  OnboardingItemMetadataV2?: OnboardingItemMetadataV2Resolvers<ContextType>
  OnboardingItemV2?: OnboardingItemV2Resolvers<ContextType>
  OnboardingLegacyDynamicLinkAction?: OnboardingLegacyDynamicLinkActionResolvers<ContextType>
  OnboardingLegacyItem?: OnboardingLegacyItemResolvers<ContextType>
  OnboardingLegacyItemDetails?: OnboardingLegacyItemDetailsResolvers<ContextType>
  OnboardingLegacyStatusBadge?: OnboardingLegacyStatusBadgeResolvers<ContextType>
  OnboardingLinkAction?: OnboardingLinkActionResolvers<ContextType>
  OnboardingMeetingItem?: OnboardingMeetingItemResolvers<ContextType>
  OnboardingMeetingItemMetadata?: OnboardingMeetingItemMetadataResolvers<ContextType>
  OnboardingMeetingStep?: OnboardingMeetingStepResolvers<ContextType>
  OnboardingMeetingStepDetails?: OnboardingMeetingStepDetailsResolvers<ContextType>
  OnboardingMutation?: OnboardingMutationResolvers<ContextType>
  OnboardingNavigationAction?: OnboardingNavigationActionResolvers<ContextType>
  OnboardingQuery?: OnboardingQueryResolvers<ContextType>
  OnboardingScheduleAppointmentLinkAction?: OnboardingScheduleAppointmentLinkActionResolvers<ContextType>
  OnboardingSetupStep?: OnboardingSetupStepResolvers<ContextType>
  OnboardingSetupStepDetails?: OnboardingSetupStepDetailsResolvers<ContextType>
  OnboardingSetupStepV2?: OnboardingSetupStepV2Resolvers<ContextType>
  OnboardingShareAction?: OnboardingShareActionResolvers<ContextType>
  OnboardingStep?: OnboardingStepResolvers<ContextType>
  OnboardingStepAction?: OnboardingStepActionResolvers<ContextType>
  OnboardingStepDetails?: OnboardingStepDetailsResolvers<ContextType>
  OnboardingStepInfo?: OnboardingStepInfoResolvers<ContextType>
  OnlineOrderingStatusLabel?: OnlineOrderingStatusLabelResolvers<ContextType>
  OpenCashDrawerReason?: OpenCashDrawerReasonResolvers<ContextType>
  OperatingSchedule?: OperatingScheduleResolvers<ContextType>
  OperatingScheduleService?: OperatingScheduleServiceResolvers<ContextType>
  OperatingScheduleServicePeriod?: OperatingScheduleServicePeriodResolvers<ContextType>
  OptMenuConfig?: OptMenuConfigResolvers<ContextType>
  OptMenuConfigOverride?: OptMenuConfigOverrideResolvers<ContextType>
  OptMutation?: OptMutationResolvers<ContextType>
  OptOrderingConfig?: OptOrderingConfigResolvers<ContextType>
  OptOrderingConfigOverride?: OptOrderingConfigOverrideResolvers<ContextType>
  OptPaymentConfig?: OptPaymentConfigResolvers<ContextType>
  OptPaymentConfigOverride?: OptPaymentConfigOverrideResolvers<ContextType>
  OptQuery?: OptQueryResolvers<ContextType>
  OptSavedMenuConfig?: OptSavedMenuConfigResolvers<ContextType>
  OptSavedOrderingConfig?: OptSavedOrderingConfigResolvers<ContextType>
  OptSavedPaymentConfig?: OptSavedPaymentConfigResolvers<ContextType>
  OptSavedSpotlightConfig?: OptSavedSpotlightConfigResolvers<ContextType>
  OptSpotlightConfig?: OptSpotlightConfigResolvers<ContextType>
  OptSpotlightConfigOverride?: OptSpotlightConfigOverrideResolvers<ContextType>
  Order?: OrderResolvers<ContextType>
  OrderConfirmationScreenConfigOverride?: OrderConfirmationScreenConfigOverrideResolvers<ContextType>
  OrderCount?: OrderCountResolvers<ContextType>
  OrderMetrics?: OrderMetricsResolvers<ContextType>
  OrderValidityCheck?: OrderValidityCheckResolvers<ContextType>
  OrderValidityCheckResponse?: OrderValidityCheckResponseResolvers<ContextType>
  OrderingSchedule?: OrderingScheduleResolvers<ContextType>
  OrdersAppliedDiscount?: OrdersAppliedDiscountResolvers<ContextType>
  OrdersAppliedLoyaltyInfo?: OrdersAppliedLoyaltyInfoResolvers<ContextType>
  OrdersAppliedPreauthInfo?: OrdersAppliedPreauthInfoResolvers<ContextType>
  OrdersConfigMutation?: OrdersConfigMutationResolvers<ContextType>
  OrdersConfigQuery?: OrdersConfigQueryResolvers<ContextType>
  OrdersCurbsidePickupInfo?: OrdersCurbsidePickupInfoResolvers<ContextType>
  OrdersGiftCardInfo?: OrdersGiftCardInfoResolvers<ContextType>
  OrdersMutation?: OrdersMutationResolvers<ContextType>
  OrdersPayment?: OrdersPaymentResolvers<ContextType>
  OrdersQuery?: OrdersQueryResolvers<ContextType>
  OrdersRefund?: OrdersRefundResolvers<ContextType>
  OrdersRefundDetails?: OrdersRefundDetailsResolvers<ContextType>
  OrdersSelection?: OrdersSelectionResolvers<ContextType>
  OrdersSummary?: OrdersSummaryResolvers<ContextType>
  OutdatedPackagingConfigError?: OutdatedPackagingConfigErrorResolvers<ContextType>
  OverlappingBreaks?: OverlappingBreaksResolvers<ContextType>
  OverlappingShiftExists?: OverlappingShiftExistsResolvers<ContextType>
  OverrideDateRange?: OverrideDateRangeResolvers<ContextType>
  OverridePricing?: OverridePricingResolvers<ContextType>
  Overrides?: OverridesResolvers<ContextType>
  PackagingComplianceData?: PackagingComplianceDataResolvers<ContextType>
  PackagingComplianceDetails?: PackagingComplianceDetailsResolvers<ContextType>
  PackagingComplianceError?: PackagingComplianceErrorResolvers<ContextType>
  PackagingComplianceLocationError?: PackagingComplianceLocationErrorResolvers<ContextType>
  PackagingComplianceRegulationError?: PackagingComplianceRegulationErrorResolvers<ContextType>
  PackagingComplianceSummary?: PackagingComplianceSummaryResolvers<ContextType>
  PackagingConfigOverride?: PackagingConfigOverrideResolvers<ContextType>
  PackagingItem?: PackagingItemResolvers<ContextType>
  PackagingLocationCompliance?: PackagingLocationComplianceResolvers<ContextType>
  PackagingLocationRegulationCompliance?: PackagingLocationRegulationComplianceResolvers<ContextType>
  PackagingMutation?: PackagingMutationResolvers<ContextType>
  PackagingQuery?: PackagingQueryResolvers<ContextType>
  PackagingRegulation?: PackagingRegulationResolvers<ContextType>
  PackagingRestrictions?: PackagingRestrictionsResolvers<ContextType>
  PageInfo?: PageInfoResolvers<ContextType>
  PagedMenuItemResponse?: PagedMenuItemResponseResolvers<ContextType>
  PagedModifierGroupsResponse?: PagedModifierGroupsResponseResolvers<ContextType>
  PagedModifierResponse?: PagedModifierResponseResolvers<ContextType>
  PagedPriceLevelResponse?: PagedPriceLevelResponseResolvers<ContextType>
  PagedRenderedAuthorizationDecision?: PagedRenderedAuthorizationDecisionResolvers<ContextType>
  PagedSavedChannel?: PagedSavedChannelResolvers<ContextType>
  PagedSavedChannelAvailability?: PagedSavedChannelAvailabilityResolvers<ContextType>
  PagedSavedMenuItemTagsResponse?: PagedSavedMenuItemTagsResponseResolvers<ContextType>
  ParentResponse?: ParentResponseResolvers<ContextType>
  Partner?: PartnerResolvers<ContextType>
  PartnerApiAccess?: PartnerApiAccessResolvers<ContextType>
  PartnerCategory?: PartnerCategoryResolvers<ContextType>
  PartnerChannel?: PartnerChannelResolvers<ContextType>
  PartnerChannelConfig?: PartnerChannelConfigResolvers<ContextType>
  PartnerQuery?: PartnerQueryResolvers<ContextType>
  PartnerScope?: PartnerScopeResolvers<ContextType>
  PartnerStatusLabel?: PartnerStatusLabelResolvers<ContextType>
  PaymentDataExtension?: PaymentDataExtensionResolvers<ContextType>
  PaymentsConfigMutation?: PaymentsConfigMutationResolvers<ContextType>
  PaymentsConfigQuery?: PaymentsConfigQueryResolvers<ContextType>
  PayrollEmployeeInfo?: PayrollEmployeeInfoResolvers<ContextType>
  PendingEmploymentStatus?: PendingEmploymentStatusResolvers<ContextType>
  PercentageMetric?: PercentageMetricResolvers<ContextType>
  PermissionBit?: GraphQLScalarType
  Permissions?: PermissionsResolvers<ContextType>
  PlaceThirdPartyMockOrderResponse?: PlaceThirdPartyMockOrderResponseResolvers<ContextType>
  PnLAISummary?: PnLaiSummaryResolvers<ContextType>
  PnLLineItemMetrics?: PnLLineItemMetricsResolvers<ContextType>
  PnLNetProfit?: PnLNetProfitResolvers<ContextType>
  PnLReport?: PnLReportResolvers<ContextType>
  PnLSubCategory?: PnLSubCategoryResolvers<ContextType>
  PnLSubCategoryDetail?: PnLSubCategoryDetailResolvers<ContextType>
  PnLSubProfit?: PnLSubProfitResolvers<ContextType>
  PnLTopLevel?: PnLTopLevelResolvers<ContextType>
  PopularSearch?: PopularSearchResolvers<ContextType>
  PopularSearchResponse?: PopularSearchResponseResolvers<ContextType>
  PosEmployeeInfo?: PosEmployeeInfoResolvers<ContextType>
  PrequalifiedOfferForCustomer?: PrequalifiedOfferForCustomerResolvers<ContextType>
  PrequalifiedOfferMetadata?: PrequalifiedOfferMetadataResolvers<ContextType>
  PriceLevel?: PriceLevelResolvers<ContextType>
  PriceLevelEntity?: PriceLevelEntityResolvers<ContextType>
  Printer?: PrinterResolvers<ContextType>
  ProcessLegacyJob?: ProcessLegacyJobResolvers<ContextType>
  ProcessLegacyJobResponse?: ProcessLegacyJobResponseResolvers<ContextType>
  ProductSubscription?: ProductSubscriptionResolvers<ContextType>
  ProductSubscriptionBundle?: ProductSubscriptionBundleResolvers<ContextType>
  ProductSubscriptionInterface?: ProductSubscriptionInterfaceResolvers<ContextType>
  ProductSubscriptionSalesforceProduct?: ProductSubscriptionSalesforceProductResolvers<ContextType>
  ProductSubscriptionsMutation?: ProductSubscriptionsMutationResolvers<ContextType>
  ProductSubscriptionsQuery?: ProductSubscriptionsQueryResolvers<ContextType>
  PublishJob?: PublishJobResolvers<ContextType>
  PublishJobConnection?: PublishJobConnectionResolvers<ContextType>
  PublishJobEdge?: PublishJobEdgeResolvers<ContextType>
  PublishJobEntityInfo?: PublishJobEntityInfoResolvers<ContextType>
  PublishMutation?: PublishMutationResolvers<ContextType>
  PublishQuery?: PublishQueryResolvers<ContextType>
  PublishRepositoryDependencyFailure?: PublishRepositoryDependencyFailureResolvers<ContextType>
  PublishRestaurantStatus?: PublishRestaurantStatusResolvers<ContextType>
  PublishStatus?: PublishStatusResolvers<ContextType>
  PublishedChannel?: PublishedChannelResolvers<ContextType>
  PublishedChannelAvailability?: PublishedChannelAvailabilityResolvers<ContextType>
  PublishedConfig?: PublishedConfigResolvers<ContextType>
  PublishedConfigConnection?: PublishedConfigConnectionResolvers<ContextType>
  PublishedConfigDiff?: PublishedConfigDiffResolvers<ContextType>
  PublishedConfigEdge?: PublishedConfigEdgeResolvers<ContextType>
  PublishedConfigEntityI?: PublishedConfigEntityIResolvers<ContextType>
  PublishedConfigFieldDiff?: PublishedConfigFieldDiffResolvers<ContextType>
  PublishedDataExtensionSet?: PublishedDataExtensionSetResolvers<ContextType>
  PublishedGiftCardReceiptConfig?: PublishedGiftCardReceiptConfigResolvers<ContextType>
  PublishedItemFeedbackConfig?: PublishedItemFeedbackConfigResolvers<ContextType>
  PublishedItemFeedbackConfigError?: PublishedItemFeedbackConfigErrorResolvers<ContextType>
  PublishedItemFeedbackConfigResponse?: PublishedItemFeedbackConfigResponseResolvers<ContextType>
  PublishedMenu?: PublishedMenuResolvers<ContextType>
  PublishedMenuEntity?: PublishedMenuEntityResolvers<ContextType>
  PublishedMenuGroup?: PublishedMenuGroupResolvers<ContextType>
  PublishedMenuItem?: PublishedMenuItemResolvers<ContextType>
  PublishedMenuItemAncestor?: PublishedMenuItemAncestorResolvers<ContextType>
  PublishedMenuItemAncestorPath?: PublishedMenuItemAncestorPathResolvers<ContextType>
  PublishedPrinterConfig?: PublishedPrinterConfigResolvers<ContextType>
  PublishedQueries?: PublishedQueriesResolvers<ContextType>
  PublishedRestaurantLocation?: PublishedRestaurantLocationResolvers<ContextType>
  PublishedToastPayConfigError?: PublishedToastPayConfigErrorResolvers<ContextType>
  PublishedToastPayConfigResponse?: PublishedToastPayConfigResponseResolvers<ContextType>
  QLOrderConfirmationScreenConfig?: QlOrderConfirmationScreenConfigResolvers<ContextType>
  QLStockDataConfig?: QlStockDataConfigResolvers<ContextType>
  QLStockOutConfig?: QlStockOutConfigResolvers<ContextType>
  Query?: QueryResolvers<ContextType>
  QuickAction?: QuickActionResolvers<ContextType>
  QuoteTime?: QuoteTimeResolvers<ContextType>
  ReadFailure?: ReadFailureResolvers<ContextType>
  RecentSearchesResponse?: RecentSearchesResponseResolvers<ContextType>
  ReconciliationConfig?: ReconciliationConfigResolvers<ContextType>
  RehiredEmploymentStatus?: RehiredEmploymentStatusResolvers<ContextType>
  RenderedAuthorizationDecision?: RenderedAuthorizationDecisionResolvers<ContextType>
  RepaymentMilestone?: RepaymentMilestoneResolvers<ContextType>
  ReportSection?: ReportSectionResolvers<ContextType>
  ResolvedConfigEntityI?: ResolvedConfigEntityIResolvers<ContextType>
  ResolvedDataExtensionSet?: ResolvedDataExtensionSetResolvers<ContextType>
  Response?: ResponseResolvers<ContextType>
  Restaurant?: RestaurantResolvers<ContextType>
  RestaurantAccess?: RestaurantAccessResolvers<ContextType>
  RestaurantAvailabilityInfo?: RestaurantAvailabilityInfoResolvers<ContextType>
  RestaurantBookingLimit?: RestaurantBookingLimitResolvers<ContextType>
  RestaurantBreakConfig?: RestaurantBreakConfigResolvers<ContextType>
  RestaurantGroup?: RestaurantGroupResolvers<ContextType>
  RestaurantGroupOrLocation?: RestaurantGroupOrLocationResolvers<ContextType>
  RestaurantInformationResponseSuccess?: RestaurantInformationResponseSuccessResolvers<ContextType>
  RestaurantLeafDataJobLastEvaluatedKey?: RestaurantLeafDataJobLastEvaluatedKeyResolvers<ContextType>
  RestaurantLeafDataJobResult?: RestaurantLeafDataJobResultResolvers<ContextType>
  RestaurantLocation?: RestaurantLocationResolvers<ContextType>
  RestaurantLocationAddress?: RestaurantLocationAddressResolvers<ContextType>
  RestaurantLocationImages?: RestaurantLocationImagesResolvers<ContextType>
  RestaurantLocationInterface?: RestaurantLocationInterfaceResolvers<ContextType>
  RestaurantLocationsDiff?: RestaurantLocationsDiffResolvers<ContextType>
  RestaurantLocationsQuery?: RestaurantLocationsQueryResolvers<ContextType>
  RestaurantMenuItemEdge?: RestaurantMenuItemEdgeResolvers<ContextType>
  RestaurantMetrics?: RestaurantMetricsResolvers<ContextType>
  RestaurantNotFound?: RestaurantNotFoundResolvers<ContextType>
  RestaurantRepositoryDependencyFailure?: RestaurantRepositoryDependencyFailureResolvers<ContextType>
  RestaurantSet?: RestaurantSetResolvers<ContextType>
  RestaurantUser?: RestaurantUserResolvers<ContextType>
  RestoreMembershipResponse?: RestoreMembershipResponseResolvers<ContextType>
  RevenueCenterDataExtension?: RevenueCenterDataExtensionResolvers<ContextType>
  SalesCategory?: SalesCategoryResolvers<ContextType>
  SalesCategoryDataExtension?: SalesCategoryDataExtensionResolvers<ContextType>
  SaveFailure?: SaveFailureResolvers<ContextType>
  SavePackagingConfigResponse?: SavePackagingConfigResponseResolvers<ContextType>
  SavedAlternatePaymentType?: SavedAlternatePaymentTypeResolvers<ContextType>
  SavedChannel?: SavedChannelResolvers<ContextType>
  SavedChannelAvailability?: SavedChannelAvailabilityResolvers<ContextType>
  SavedConfig?: SavedConfigResolvers<ContextType>
  SavedConfigEntityI?: SavedConfigEntityIResolvers<ContextType>
  SavedConfigOverrideI?: SavedConfigOverrideIResolvers<ContextType>
  SavedDataExtensionSet?: SavedDataExtensionSetResolvers<ContextType>
  SavedDiningOption?: SavedDiningOptionResolvers<ContextType>
  SavedGiftCardReceiptConfig?: SavedGiftCardReceiptConfigResolvers<ContextType>
  SavedMenuAuditSetting?: SavedMenuAuditSettingResolvers<ContextType>
  SavedMenuGroupPreparation?: SavedMenuGroupPreparationResolvers<ContextType>
  SavedMenuItemPreparation?: SavedMenuItemPreparationResolvers<ContextType>
  SavedMenuItemTag?: SavedMenuItemTagResolvers<ContextType>
  SavedMenuPreparation?: SavedMenuPreparationResolvers<ContextType>
  SavedOrderConfirmationScreenConfig?: SavedOrderConfirmationScreenConfigResolvers<ContextType>
  SavedPackagingConfig?: SavedPackagingConfigResolvers<ContextType>
  SavedPrepSequence?: SavedPrepSequenceResolvers<ContextType>
  SavedPrepStation?: SavedPrepStationResolvers<ContextType>
  SavedQueries?: SavedQueriesResolvers<ContextType>
  SavedRestaurantLocation?: SavedRestaurantLocationResolvers<ContextType>
  SavedRestaurantLocationConnection?: SavedRestaurantLocationConnectionResolvers<ContextType>
  SavedRestaurantLocationEdge?: SavedRestaurantLocationEdgeResolvers<ContextType>
  SavedRestaurantTable?: SavedRestaurantTableResolvers<ContextType>
  SavedRevenueCenter?: SavedRevenueCenterResolvers<ContextType>
  SavedSalesCategory?: SavedSalesCategoryResolvers<ContextType>
  SavedStockDataConfig?: SavedStockDataConfigResolvers<ContextType>
  SavedStockOutConfig?: SavedStockOutConfigResolvers<ContextType>
  SavedTaxRate?: SavedTaxRateResolvers<ContextType>
  SavedToastPayConfigError?: SavedToastPayConfigErrorResolvers<ContextType>
  SavedToastPayConfigResponse?: SavedToastPayConfigResponseResolvers<ContextType>
  Schedule?: ScheduleResolvers<ContextType>
  SearchEntity?: SearchEntityResolvers<ContextType>
  SearchItem?: SearchItemResolvers<ContextType>
  SearchMutation?: SearchMutationResolvers<ContextType>
  SearchQuery?: SearchQueryResolvers<ContextType>
  SearchResponse?: SearchResponseResolvers<ContextType>
  SearchSuggestion?: SearchSuggestionResolvers<ContextType>
  SeasonalEmploymentStatus?: SeasonalEmploymentStatusResolvers<ContextType>
  Section?: SectionResolvers<ContextType>
  ServerColor?: ServerColorResolvers<ContextType>
  ServiceArea?: ServiceAreaResolvers<ContextType>
  ServiceAreaAvailabilityInfo?: ServiceAreaAvailabilityInfoResolvers<ContextType>
  ServiceAreaGroupInfo?: ServiceAreaGroupInfoResolvers<ContextType>
  ServiceAreaNotFound?: ServiceAreaNotFoundResolvers<ContextType>
  ServiceAreaResponse?: ServiceAreaResponseResolvers<ContextType>
  ServiceDataExtension?: ServiceDataExtensionResolvers<ContextType>
  ShiftAlreadyClockedOut?: ShiftAlreadyClockedOutResolvers<ContextType>
  ShiftCheckedResultRepositoryDependencyFailure?: ShiftCheckedResultRepositoryDependencyFailureResolvers<ContextType>
  ShiftError?: ShiftErrorResolvers<ContextType>
  ShiftNotFound?: ShiftNotFoundResolvers<ContextType>
  ShiftReviewConfig?: ShiftReviewConfigResolvers<ContextType>
  ShiftV2Employee?: ShiftV2EmployeeResolvers<ContextType>
  ShiftV2EmployeesResponse?: ShiftV2EmployeesResponseResolvers<ContextType>
  ShiftsV2PageInfo?: ShiftsV2PageInfoResolvers<ContextType>
  SizePrice?: SizePriceResolvers<ContextType>
  SlotAvailabilityInfo?: SlotAvailabilityInfoResolvers<ContextType>
  SmartQuoteAutoChangeStrategyResponse?: SmartQuoteAutoChangeStrategyResponseResolvers<ContextType>
  SmartQuoteConfig?: SmartQuoteConfigResolvers<ContextType>
  SmartQuoteMutation?: SmartQuoteMutationResolvers<ContextType>
  SmartQuoteQuery?: SmartQuoteQueryResolvers<ContextType>
  SmartQuoteSimpleComparison?: SmartQuoteSimpleComparisonResolvers<ContextType>
  SmartQuoteSimpleComparisonQuery?: SmartQuoteSimpleComparisonQueryResolvers<ContextType>
  SousChefAction?: SousChefActionResolvers<ContextType>
  SousChefActionPlan?: SousChefActionPlanResolvers<ContextType>
  SousChefAdminMutation?: SousChefAdminMutationResolvers<ContextType>
  SousChefAdminQuery?: SousChefAdminQueryResolvers<ContextType>
  SousChefAlert?: SousChefAlertResolvers<ContextType>
  SousChefAnalysisNode?: SousChefAnalysisNodeResolvers<ContextType>
  SousChefAutoClockedOut?: SousChefAutoClockedOutResolvers<ContextType>
  SousChefAutoClockedOutDataPoint?: SousChefAutoClockedOutDataPointResolvers<ContextType>
  SousChefChecklistItem?: SousChefChecklistItemResolvers<ContextType>
  SousChefCompletedThread?: SousChefCompletedThreadResolvers<ContextType>
  SousChefDataNode?: SousChefDataNodeResolvers<ContextType>
  SousChefDataPanel?: SousChefDataPanelResolvers<ContextType>
  SousChefDataPoint?: SousChefDataPointResolvers<ContextType>
  SousChefDataSource?: SousChefDataSourceResolvers<ContextType>
  SousChefDateRange?: SousChefDateRangeResolvers<ContextType>
  SousChefDateRangeFilter?: SousChefDateRangeFilterResolvers<ContextType>
  SousChefDateTimeRange?: SousChefDateTimeRangeResolvers<ContextType>
  SousChefDayOfWeekFilter?: SousChefDayOfWeekFilterResolvers<ContextType>
  SousChefDayPart?: SousChefDayPartResolvers<ContextType>
  SousChefDayPartFilter?: SousChefDayPartFilterResolvers<ContextType>
  SousChefDefinition?: SousChefDefinitionResolvers<ContextType>
  SousChefDiningOption?: SousChefDiningOptionResolvers<ContextType>
  SousChefDiningOptionFilter?: SousChefDiningOptionFilterResolvers<ContextType>
  SousChefEdge?: SousChefEdgeResolvers<ContextType>
  SousChefEmployeeFilter?: SousChefEmployeeFilterResolvers<ContextType>
  SousChefEmptyDataPoint?: SousChefEmptyDataPointResolvers<ContextType>
  SousChefFilter?: SousChefFilterResolvers<ContextType>
  SousChefFinancialData?: SousChefFinancialDataResolvers<ContextType>
  SousChefFinancialDataPoint?: SousChefFinancialDataPointResolvers<ContextType>
  SousChefFixedResponseNode?: SousChefFixedResponseNodeResolvers<ContextType>
  SousChefHoursOfDayFilter?: SousChefHoursOfDayFilterResolvers<ContextType>
  SousChefInsight?: SousChefInsightResolvers<ContextType>
  SousChefInsightFilter?: SousChefInsightFilterResolvers<ContextType>
  SousChefIntervalFilter?: SousChefIntervalFilterResolvers<ContextType>
  SousChefKnowledge?: SousChefKnowledgeResolvers<ContextType>
  SousChefMenu?: SousChefMenuResolvers<ContextType>
  SousChefMenuFilter?: SousChefMenuFilterResolvers<ContextType>
  SousChefMenuGroup?: SousChefMenuGroupResolvers<ContextType>
  SousChefMenuGroupFilter?: SousChefMenuGroupFilterResolvers<ContextType>
  SousChefMutation?: SousChefMutationResolvers<ContextType>
  SousChefOrderSource?: SousChefOrderSourceResolvers<ContextType>
  SousChefOrderSourceFilter?: SousChefOrderSourceFilterResolvers<ContextType>
  SousChefOutOfStockItem?: SousChefOutOfStockItemResolvers<ContextType>
  SousChefPercentageData?: SousChefPercentageDataResolvers<ContextType>
  SousChefPercentageDataPoint?: SousChefPercentageDataPointResolvers<ContextType>
  SousChefQuery?: SousChefQueryResolvers<ContextType>
  SousChefRevenueCenter?: SousChefRevenueCenterResolvers<ContextType>
  SousChefRevenueCenterFilter?: SousChefRevenueCenterFilterResolvers<ContextType>
  SousChefSalesCategory?: SousChefSalesCategoryResolvers<ContextType>
  SousChefSalesCategoryFilter?: SousChefSalesCategoryFilterResolvers<ContextType>
  SousChefServiceArea?: SousChefServiceAreaResolvers<ContextType>
  SousChefServiceAreaFilter?: SousChefServiceAreaFilterResolvers<ContextType>
  SousChefStockDataPoint?: SousChefStockDataPointResolvers<ContextType>
  SousChefSuggestedThread?: SousChefSuggestedThreadResolvers<ContextType>
  SousChefSummary?: SousChefSummaryResolvers<ContextType>
  SousChefTaskData?: SousChefTaskDataResolvers<ContextType>
  SousChefTextDataPoint?: SousChefTextDataPointResolvers<ContextType>
  SousChefThread?: SousChefThreadResolvers<ContextType>
  SousChefThreadGroup?: SousChefThreadGroupResolvers<ContextType>
  SousChefThreadSession?: SousChefThreadSessionResolvers<ContextType>
  SousChefTrend?: SousChefTrendResolvers<ContextType>
  SousChefTrendDataPoint?: SousChefTrendDataPointResolvers<ContextType>
  SousChefWorkflowDefinition?: SousChefWorkflowDefinitionResolvers<ContextType>
  SousChefWorkflowNode?: SousChefWorkflowNodeResolvers<ContextType>
  SousChefWorkflowTask?: SousChefWorkflowTaskResolvers<ContextType>
  StaticPaymentDataExtension?: StaticPaymentDataExtensionResolvers<ContextType>
  Step?: StepResolvers<ContextType>
  Stock?: StockResolvers<ContextType>
  StockDataConfigOverride?: StockDataConfigOverrideResolvers<ContextType>
  StockOutConfigOverride?: StockOutConfigOverrideResolvers<ContextType>
  SubscriptionsCancellationRequest?: SubscriptionsCancellationRequestResolvers<ContextType>
  SuggestionResponse?: SuggestionResponseResolvers<ContextType>
  SupportQuery?: SupportQueryResolvers<ContextType>
  SystemIdentity?: SystemIdentityResolvers<ContextType>
  TableAvailabilityInfo?: TableAvailabilityInfoResolvers<ContextType>
  TableBlocked?: TableBlockedResolvers<ContextType>
  TableNotFound?: TableNotFoundResolvers<ContextType>
  TakeoutDeliveryCategoryPage?: TakeoutDeliveryCategoryPageResolvers<ContextType>
  TakeoutDeliveryConfig?: TakeoutDeliveryConfigResolvers<ContextType>
  Task?: TaskResolvers<ContextType>
  TaskAction?: TaskActionResolvers<ContextType>
  TaxRateDataExtension?: TaxRateDataExtensionResolvers<ContextType>
  TerminalHandheld?: TerminalHandheldResolvers<ContextType>
  TerminatedEmploymentStatus?: TerminatedEmploymentStatusResolvers<ContextType>
  ThirdPartyMockMutation?: ThirdPartyMockMutationResolvers<ContextType>
  ThirdPartyMockProviderEvent?: ThirdPartyMockProviderEventResolvers<ContextType>
  ThirdPartyMockQuery?: ThirdPartyMockQueryResolvers<ContextType>
  ThirdPartyOrderingOnboardingFlow?: ThirdPartyOrderingOnboardingFlowResolvers<ContextType>
  ThirdPartyOrderingOnboardingMutation?: ThirdPartyOrderingOnboardingMutationResolvers<ContextType>
  ThirdPartyOrderingOnboardingQuery?: ThirdPartyOrderingOnboardingQueryResolvers<ContextType>
  ThirdPartyOrderingOnboardingTask?: ThirdPartyOrderingOnboardingTaskResolvers<ContextType>
  ThirdPartyOrderingOnboardingTaskResult?: ThirdPartyOrderingOnboardingTaskResultResolvers<ContextType>
  ThirdPartyOrderingSavedMenuAuditSetting?: ThirdPartyOrderingSavedMenuAuditSettingResolvers<ContextType>
  TipDetails?: TipDetailsResolvers<ContextType>
  TipOutConfig?: TipOutConfigResolvers<ContextType>
  TipSharingConfig?: TipSharingConfigResolvers<ContextType>
  TipSharingPercentage?: TipSharingPercentageResolvers<ContextType>
  TipsQuery?: TipsQueryResolvers<ContextType>
  ToastCentralArticle?: ToastCentralArticleResolvers<ContextType>
  ToastNavigationConfig?: ToastNavigationConfigResolvers<ContextType>
  ToastNowDeviceRegistryMutation?: ToastNowDeviceRegistryMutationResolvers<ContextType>
  ToastNowDeviceRegistryQuery?: ToastNowDeviceRegistryQueryResolvers<ContextType>
  ToastNowDeviceToken?: ToastNowDeviceTokenResolvers<ContextType>
  ToastPayConfig?: ToastPayConfigResolvers<ContextType>
  ToastPayPaymentNotificationConfig?: ToastPayPaymentNotificationConfigResolvers<ContextType>
  ToastPayPaymentNotificationConfigError?: ToastPayPaymentNotificationConfigErrorResolvers<ContextType>
  ToastPayPaymentNotificationConfigResponse?: ToastPayPaymentNotificationConfigResponseResolvers<ContextType>
  ToastPayRelatedConfiguration?: ToastPayRelatedConfigurationResolvers<ContextType>
  ToastPayRestaurantLogo?: ToastPayRestaurantLogoResolvers<ContextType>
  ToastPayRestaurantLogoError?: ToastPayRestaurantLogoErrorResolvers<ContextType>
  ToastPayRestaurantLogoResponse?: ToastPayRestaurantLogoResponseResolvers<ContextType>
  ToastPayTipSelectionsConfig?: ToastPayTipSelectionsConfigResolvers<ContextType>
  ToastPayTipSelectionsConfigResponse?: ToastPayTipSelectionsConfigResponseResolvers<ContextType>
  ToastPayTipSelectionsError?: ToastPayTipSelectionsErrorResolvers<ContextType>
  ToastPermission?: ToastPermissionResolvers<ContextType>
  TopSellingItemConnection?: TopSellingItemConnectionResolvers<ContextType>
  TopSellingItemEdge?: TopSellingItemEdgeResolvers<ContextType>
  TopSellingModifierConnection?: TopSellingModifierConnectionResolvers<ContextType>
  TopSellingModifierEdge?: TopSellingModifierEdgeResolvers<ContextType>
  TotalRefundAmount?: TotalRefundAmountResolvers<ContextType>
  TrialEligibilityProbeResponse?: TrialEligibilityProbeResponseResolvers<ContextType>
  TrialEntity?: TrialEntityResolvers<ContextType>
  TrialFeedbackResponse?: TrialFeedbackResponseResolvers<ContextType>
  TrialMutation?: TrialMutationResolvers<ContextType>
  TrialMutationResponse?: TrialMutationResponseResolvers<ContextType>
  TrialProbe?: TrialProbeResolvers<ContextType>
  TrialsQuery?: TrialsQueryResolvers<ContextType>
  UIOptionInvalidEntity?: UiOptionInvalidEntityResolvers<ContextType>
  UIOptionInvalidSmartTax?: UiOptionInvalidSmartTaxResolvers<ContextType>
  UIOptionsMutation?: UiOptionsMutationResolvers<ContextType>
  UIOptionsQuery?: UiOptionsQueryResolvers<ContextType>
  UIOptionsTaxOptions?: UiOptionsTaxOptionsResolvers<ContextType>
  UIOptionsTipSetup?: UiOptionsTipSetupResolvers<ContextType>
  UIOptionsTippingAmounts?: UiOptionsTippingAmountsResolvers<ContextType>
  UIOptionsTippingPercentages?: UiOptionsTippingPercentagesResolvers<ContextType>
  UIOptionsTippingRequireApproval?: UiOptionsTippingRequireApprovalResolvers<ContextType>
  UKCustomerBillingInvoice?: UkCustomerBillingInvoiceResolvers<ContextType>
  UKCustomerBillingInvoiceItem?: UkCustomerBillingInvoiceItemResolvers<ContextType>
  USCustomerBillingInvoice?: UsCustomerBillingInvoiceResolvers<ContextType>
  USCustomerBillingInvoiceItem?: UsCustomerBillingInvoiceItemResolvers<ContextType>
  UnableToCreateDepositOrder?: UnableToCreateDepositOrderResolvers<ContextType>
  UnableToCreateGuest?: UnableToCreateGuestResolvers<ContextType>
  UnableToFetchGuests?: UnableToFetchGuestsResolvers<ContextType>
  UnableToSendSMS?: UnableToSendSmsResolvers<ContextType>
  UnableToUpdateGuest?: UnableToUpdateGuestResolvers<ContextType>
  Unauthorized?: UnauthorizedResolvers<ContextType>
  UnauthorizedAccessError?: UnauthorizedAccessErrorResolvers<ContextType>
  UnknownError?: UnknownErrorResolvers<ContextType>
  UnknownIdentity?: UnknownIdentityResolvers<ContextType>
  UnpublishedConfig?: UnpublishedConfigResolvers<ContextType>
  UnpublishedConfigConnection?: UnpublishedConfigConnectionResolvers<ContextType>
  UnpublishedConfigEdge?: UnpublishedConfigEdgeResolvers<ContextType>
  UnpublishedStatus?: UnpublishedStatusResolvers<ContextType>
  UnpublishedStatusConnection?: UnpublishedStatusConnectionResolvers<ContextType>
  UnpublishedStatusEdge?: UnpublishedStatusEdgeResolvers<ContextType>
  UnsupportedTransition?: UnsupportedTransitionResolvers<ContextType>
  UpdateItemFeedbackConfigError?: UpdateItemFeedbackConfigErrorResolvers<ContextType>
  UpdateItemFeedbackConfigResponse?: UpdateItemFeedbackConfigResponseResolvers<ContextType>
  UpdateMembershipResult?: UpdateMembershipResultResolvers<ContextType>
  UpdateReservationResponse?: UpdateReservationResponseResolvers<ContextType>
  UpdateReservationResponseSuccess?: UpdateReservationResponseSuccessResolvers<ContextType>
  UpdateSavedUIOptionResponse?: UpdateSavedUiOptionResponseResolvers<ContextType>
  UpdateShiftFailure?: UpdateShiftFailureResolvers<ContextType>
  UpdateShiftResult?: UpdateShiftResultResolvers<ContextType>
  UpdateShiftTimesResult?: UpdateShiftTimesResultResolvers<ContextType>
  UpdateToastPayConfigError?: UpdateToastPayConfigErrorResolvers<ContextType>
  UpdateToastPayConfigResponse?: UpdateToastPayConfigResponseResolvers<ContextType>
  UpdateUserPreferredLocalResponse?: UpdateUserPreferredLocalResponseResolvers<ContextType>
  UpgradeItemFeedbackConfigResponse?: UpgradeItemFeedbackConfigResponseResolvers<ContextType>
  UploadImageResponse?: UploadImageResponseResolvers<ContextType>
  Url?: GraphQLScalarType
  User?: UserResolvers<ContextType>
  UserFeatureOptIn?: UserFeatureOptInResolvers<ContextType>
  UserFeatureOptInsResponse?: UserFeatureOptInsResponseResolvers<ContextType>
  UserIdentity?: UserIdentityResolvers<ContextType>
  UserInteractionResponse?: UserInteractionResponseResolvers<ContextType>
  UserInterface?: UserInterfaceResolvers<ContextType>
  UserIteraction?: UserIteractionResolvers<ContextType>
  UserIteractionMutationResponse?: UserIteractionMutationResponseResolvers<ContextType>
  UserPreferencesMutation?: UserPreferencesMutationResolvers<ContextType>
  UserPreferencesQuery?: UserPreferencesQueryResolvers<ContextType>
  UserQuickAction?: UserQuickActionResolvers<ContextType>
  UserQuickActionParam?: UserQuickActionParamResolvers<ContextType>
  UserSearch?: UserSearchResolvers<ContextType>
  VoidAmount?: VoidAmountResolvers<ContextType>
  VoidAmountAsPercentageOfNetSales?: VoidAmountAsPercentageOfNetSalesResolvers<ContextType>
  VoidGroupEntity?: VoidGroupEntityResolvers<ContextType>
  VoidReason?: VoidReasonResolvers<ContextType>
  VoidedItemsCount?: VoidedItemsCountResolvers<ContextType>
  WeekOperatingSchedule?: WeekOperatingScheduleResolvers<ContextType>
  Workflow?: WorkflowResolvers<ContextType>
}

export type DirectiveResolvers<ContextType = any> = {
  cacheControl?: CacheControlDirectiveResolver<any, any, ContextType>
  extends?: ExtendsDirectiveResolver<any, any, ContextType>
  link?: LinkDirectiveResolver<any, any, ContextType>
  shareable?: ShareableDirectiveResolver<any, any, ContextType>
  trace?: TraceDirectiveResolver<any, any, ContextType>
}
