import { registerApplication } from 'single-spa'
import { featureFlagsMatch, routeMatcher } from '@toasttab/banquet-match-utils'
import { hasRRASubnav, importSpa } from '../utils'
import { RegistrationFunc } from './registration'
import { isToastAdmin } from '../utils/isToastAdmin'
import {
  getCurrentEnvironment,
  ToastEnvironment
} from '@toasttab/current-environment'
import { isMenuPriceSpaPath, isPriceSpaServedFromWexRoot } from '.'
import { getIsReportsUsingGlobalSubNav } from '@local/feature-flag-utils'

const IS_CUSTOMERS = /\/customers\/shop/
const IS_SETTINGS = /^\/settings/
const IS_3PO_ONBOARDING = /onboarding\/ordering-integrations/
const IS_TDS_ONBOARDING = /onboarding\/toast-delivery-service/
const IS_RETAIL = /\/retail/
const IS_INTEGRATION = /\/restaurants\/admin\/integrations/
const IS_CUSTOMER_FACING_DISPLAY = /\/restaurants\/admin\/customerfacingdisplay/

export const restaurantAdmin: RegistrationFunc = (globalCustomProps) => {
  const currentEnv = getCurrentEnvironment()
  const hasAnyPermission =
    globalCustomProps?.auth?.userInfo.binaryCustomerPermissions.includes('1')
  const mountChecklist =
    hasAnyPermission && currentEnv !== ToastEnvironment.SANDBOX
  const isToastMobile =
    window.navigator.userAgent?.indexOf('ToastEmbedded') > -1

  // define feature flags - This should be handled globally here.
  const flagMatch = featureFlagsMatch(globalCustomProps?.featureFlags || {})

  // --------------------------------------
  // Define layout spa
  // --------------------------------------
  const restaurantAdminMatch = routeMatcher(['/restaurants/admin/*'])
  const restaurantAdminHomeMatch = routeMatcher(['/restaurants/admin/home'])

  const tipConfigMatch = (pathname: string) =>
    pathname.startsWith('/restaurants/admin/tipConfig')

  const isSitesMatch = (pathname: string) =>
    pathname.startsWith('/restaurants/admin/sites') ||
    pathname.startsWith('/restaurants/admin/branded-online-ordering') ||
    pathname.startsWith('/restaurants/admin/toast-local') ||
    pathname.startsWith('/restaurants/admin/order-with-google') ||
    pathname.startsWith('/restaurants/admin/google-management')

  const hasDataBanquetRestaurantAdmin = window.document.body.hasAttribute(
    'data-banquet-restaurant-admin'
  )

  const { ldClient } = globalCustomProps
  const preventLoadOnPriceSpaPath = (pathname: string) =>
    isPriceSpaServedFromWexRoot(ldClient) && isMenuPriceSpaPath(pathname)
  registerApplication({
    name: 'restaurant-admin-layout',
    activeWhen: ({ pathname }) => {
      return (
        hasDataBanquetRestaurantAdmin &&
        Boolean(restaurantAdminMatch(pathname)) &&
        Boolean(!restaurantAdminHomeMatch(pathname)) &&
        !tipConfigMatch(pathname) &&
        !isSitesMatch(pathname) &&
        // If the root serves price SPA paths, don't load RAL on those paths
        !preventLoadOnPriceSpaPath(pathname)
      )
    },
    customProps: {
      ...globalCustomProps,
      basename: 'restaurants/admin'
    },
    app: importSpa('restaurant-admin-layout')
  })

  // --------------------------------------
  // Define widget spa
  // --------------------------------------
  const isNavbarActive = ({ pathname }: { pathname: string }) => {
    return !(
      isToastAdmin(pathname) ||
      IS_CUSTOMERS.test(pathname) ||
      IS_3PO_ONBOARDING.test(pathname) ||
      IS_TDS_ONBOARDING.test(pathname) ||
      IS_SETTINGS.test(pathname)
    )
  }

  if (!isToastMobile) {
    registerApplication({
      name: 'navigation-layout-spa',
      activeWhen: isNavbarActive,
      customProps: {
        ...globalCustomProps,
        basename: '/restaurants/admin',
        isBanquetPage: hasDataBanquetRestaurantAdmin
      },
      app: importSpa('navigation-layout-spa')
    })
  }

  if (mountChecklist) {
    registerApplication({
      name: 'toast-onboarding-checklist-spa',
      activeWhen: isNavbarActive,
      customProps: () => {
        return {
          ...globalCustomProps
        }
      },
      app: importSpa('toast-onboarding-checklist-spa')
    })
  }

  const isReportsUsingGlobalSubNav = getIsReportsUsingGlobalSubNav(ldClient)
  // hide tta-subanv when glovbal subnav is enabled for report
  if (!isReportsUsingGlobalSubNav) {
    registerApplication({
      name: 'rra-subnav-spa',
      activeWhen: ({ pathname }) => hasRRASubnav(pathname),
      customProps: {
        ...globalCustomProps,
        basename: '/restaurants/admin',
        isBanquetPage: hasDataBanquetRestaurantAdmin
      },
      app: importSpa('rra-subnav-spa')
    })
  }

  registerApplication({
    name: 'popup-notifications-spa',
    activeWhen: isNavbarActive,
    customProps: {
      ...globalCustomProps,
      basename: '/restaurants/admin'
    },
    app: importSpa('popup-notifications-spa')
  })

  /**
   * wex-977 Footer spa
   */
  if (!isToastMobile) {
    registerApplication({
      name: 'restaurant-footer-spa',
      activeWhen: ({ pathname }) => {
        return (
          pathname.startsWith('/restaurants/admin') &&
          !IS_RETAIL.test(pathname) &&
          !pathname.startsWith('/restaurants/admin/configchangesv2')
        )
      },
      customProps() {
        return {
          ...globalCustomProps,
          basename: '/restaurants/admin'
        }
      },
      app: importSpa('restaurant-footer-spa')
    })
  }

  /**
   * Restaurant Select Dialog registration
   */
  registerApplication({
    name: 'restaurant-select-dialog-spa',
    activeWhen: [
      '/restaurants/admin/reports',
      '/restaurants/admin/reporting',
      '/restaurants/admin/menus',
      '/restaurants/admin/menus/price',
      '/restaurants/admin/capital',
      '/restaurants/admin/groups',
      '/restaurants/admin/settings-copy',
      (location) => IS_RETAIL.test(location.pathname),
      '/restaurants/admin/invoices',
      '/restaurants/admin/labor',
      '/restaurants/admin/employees',
      '/restaurants/admin/schedules',
      '/restaurants/admin/packaging-config',
      '/restaurants/admin/orderconfirmationscreen',
      '/restaurants/admin/digitalmenuboard',
      '/restaurants/admin/fundraising',
      '/restaurants/admin/publishing/history',
      '/restaurants/admin/data-extensions',
      '/restaurants/admin/rate-change',
      '/restaurants/admin/toast-account/pricing-updates',
      '/restaurants/admin/devices',
      '/restaurants/admin/ui-options',
      '/toast/admin/versions/posappversions',
      (location) => IS_CUSTOMER_FACING_DISPLAY.test(location.pathname),
      (location) => IS_INTEGRATION.test(location.pathname),
      (location) => IS_CUSTOMERS.test(location.pathname),
      // remove old route /restaurants/admin/guest-feedback with flag removal
      (location) =>
        flagMatch(['grm-guest-feedback-management-spa']) &&
        (location.pathname.startsWith('/restaurants/admin/guest-feedback') ||
          location.pathname.startsWith(
            '/restaurants/admin/marketing/guest-feedback'
          ))
    ],
    customProps() {
      return {
        ...globalCustomProps,
        basename: '/restaurants/admin'
      }
    },
    app: importSpa('restaurant-select-dialog-spa')
  })

  /**
   * Restaurant reporting runtime module registration
   */
  registerApplication({
    name: 'reporting-runtime',
    activeWhen: [
      '/restaurants/admin/reports',
      '/restaurants/admin/reporting',
      '/restaurants/admin/takeout-delivery-dashboard'
    ],
    customProps() {
      return {
        ...globalCustomProps,
        basename: '/restaurants/admin'
      }
    },
    app: importSpa('reporting-runtime')
  })

  /**
   * Tip pooling policy config spa
   */
  registerApplication({
    name: 'tip-config',
    activeWhen: ({ pathname }) => tipConfigMatch(pathname),
    customProps() {
      return {
        ...globalCustomProps,
        basename: '/restaurants/admin'
      }
    },
    app: importSpa('tip-config')
  })

  flagMatch(['mlx-advanced-properties-guardrails']) &&
    registerApplication({
      name: 'advanced-properties-spa',
      activeWhen: ({ pathname }) =>
        /advancedproperties\/bulkeditor/.test(pathname),
      customProps: {
        ...globalCustomProps,
        basename: '/restaurants/admin'
      },
      app: importSpa('advanced-properties-spa')
    })

  /**
   * WOO-672 port embeded legacy gmb-spa
   */
  registerApplication({
    name: 'gmb-spa',
    activeWhen: ({ pathname }) => {
      return /restaurants\/admin\/basicinfo/.test(pathname)
    },
    customProps: {
      ...globalCustomProps,
      basename: '/restaurants/admin'
    },
    app: importSpa('gmb-spa')
  })

  /**
   * ADVM-1108 embed scheduled banner spa
   */
  flagMatch(['advm-adv-props-in-price-editor']) &&
    registerApplication({
      name: 'scheduled-banner-spa',
      activeWhen: ({ pathname }) => /menus\/menuitem/.test(pathname),
      customProps: {
        ...globalCustomProps,
        basename: '/restaurants/admin'
      },
      app: importSpa('scheduled-banner-spa')
    })

  registerApplication({
    name: 'gfo-validation-spa',
    activeWhen: '/restaurants/admin/onlineordering',
    customProps: {
      ...globalCustomProps,
      basename: '/restaurants/admin'
    },
    app: importSpa('gfo-validation-spa')
  })

  registerApplication({
    name: 'break-adherence-spa',
    activeWhen: ({ pathname, hash }) =>
      /restaurants\/admin\/reports\/home/.test(pathname) &&
      hash === '#labor-break-adherence',
    customProps: {
      ...globalCustomProps,
      basename: '/restaurants/admin',
      deferredDomElement: '#labor-break-adherence'
    },
    app: importSpa('break-adherence-spa')
  })

  /**
   * MSG: Marketing Suite Widget Spa
   */
  const isMarketingSuiteWidgetSpaEnabled = flagMatch([
    'msg-marketing-suite-widget-spa'
  ])

  if (isMarketingSuiteWidgetSpaEnabled) {
    registerApplication({
      name: 'marketing-suite-widget-spa',
      activeWhen: [
        (location) => {
          const isHubRoute = [
            '/restaurants/admin/guest/homepage',
            '/restaurants/admin/marketing/assistant',
            '/restaurants/admin/marketing/automations',
            '/restaurants/admin/marketing/campaigns',
            '/restaurants/admin/sites/app'
          ].some((route) => location.pathname.startsWith(route))

          const isGuestbookHomepage =
            location.pathname === '/restaurants/admin/reports/guest'

          const isBuilderRoute = [
            '/restaurants/admin/marketing/one-time/',
            '/restaurants/admin/marketing/automated/edit',
            '/restaurants/admin/marketing/assistant?editing=true'
          ].some((route) => location.pathname.startsWith(route))

          const isSMSRoute = location.pathname.startsWith(
            '/restaurants/admin/sms-marketing'
          )

          return (
            isHubRoute || isGuestbookHomepage || isBuilderRoute || isSMSRoute
          )
        }
      ],
      customProps() {
        return {
          ...globalCustomProps,
          basename: '/restaurants/admin'
        }
      },
      app: importSpa('marketing-suite-widget-spa')
    })
  }
}
