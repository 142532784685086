// global
import { registerApplication } from 'single-spa'
// local
import { GlobalCustomProps } from '@local/global-custom-props'
import { importSpa } from '../utils/SystemHelpers/importSpa'
import { RegistrationFunc } from './registration'

const SITES_ADMIN_SPA = 'toast-sites-admin-spa'

const ONLINE_ORDERING_PRO_PATH = '/restaurants/admin/branded-online-ordering'
const WEBSITES_PATH = '/restaurants/admin/sites'
const TOAST_LOCAL_PATH = '/restaurants/admin/toast-local'
const ORDER_WITH_GOOGLE_PATH = '/restaurants/admin/order-with-google'
const GOOGLE_MANAGEMENT_PATH = '/restaurants/admin/google-management'

export const sites: RegistrationFunc = (customProps: GlobalCustomProps) => {
  // -------------------------------------------
  // Toast Websites and Online Ordering Pro SPA.
  // -------------------------------------------
  registerApplication({
    name: SITES_ADMIN_SPA,
    activeWhen: [
      WEBSITES_PATH,
      ONLINE_ORDERING_PRO_PATH,
      TOAST_LOCAL_PATH,
      ORDER_WITH_GOOGLE_PATH,
      GOOGLE_MANAGEMENT_PATH
    ],
    customProps,
    app: importSpa(SITES_ADMIN_SPA)
  })
}
